import React from "react";
import "./productNameTd.css";
import PropTypes from "prop-types";
import shoppingBag from '../../../assets/svgs/shoppingcart.svg'
import copyTextToClipboard from "../../../helper/common/copyToClipboard";
import { useGlobalContext } from "../../../context/global";
import { TOAST_POSITION_TOP_RIGHT,TOAST_SUCCESS } from "../../../constant/HardTypes";
import {toast} from 'react-toastify';

const ProductNameTd = ({ product_name, image_url, asin, dynamicWidth,noSliced,noImage }) => {

  const {showAlert} = useGlobalContext();

  // Presentational Component
  const slicedProductName = product_name && product_name.length >=75 ? product_name.slice(0, 75) : product_name;
  const slicedAsin = asin?.length >= 50 ? asin.slice(0, 50) : asin;

  // Method
  const handleCopyText = async (text) => {
    try {
      const result = await copyTextToClipboard(text);
      // showAlert(TOAST_POSITION_TOP_RIGHT,3000,{
      //   backgroundColor: TOAST_SUCCESS,
      //   title: "Success",
      //   description: "Product Name to Clipboard",
      // })
      toast.success('Product Name copied to clipboard')
    } catch (error) {
      console.log(error)
    }
  }
  return (
    // <td className="main-product-td-second">
      <div className={dynamicWidth ?  `main-table-product-container ${dynamicWidth}` : "main-table-product-container defaultWidthForProduct" }>
        <div className={noImage ? "hide" :"right-circle-image-container"}>
          {image_url ? (
            <img
              className="image-product-table-second-td"
              alt="Product"
              src={image_url}
            />
          ) : (
            <div className="image-product-table-default-image">
              <img src={shoppingBag} alt={"Product"}/>
            </div>
          )}
        </div>
        <div className="product-data-container">
          <span
            className="product-ads-name"
            onDoubleClick={() => handleCopyText(product_name)}
            title={!noSliced ? (product_name ? product_name + asin : asin) : product_name}
          >
            {
              !noSliced ? (slicedProductName ? slicedProductName + "..." : slicedAsin ) : product_name
            }
          </span>
          {/* <span className="product-ads-description">
          {asin ?? "-"}
        </span> */}
        </div>
      </div>
    // </td>
  );
};

ProductNameTd.propTypes = {
  product_name: PropTypes.string,
  image_url: PropTypes.string,
  asin: PropTypes.string,
};

export default ProductNameTd;
