import React from "react";
import { Form } from "react-bootstrap";

const CustomCheckbox = ({ label, checked, onChange }) => (
  <Form.Check
    type="checkbox"
    label={label}
    checked={checked}
    onChange={(e) => onChange(e.target.checked)}
  />
);

export default CustomCheckbox;
