const FLIPKART_URL = 'https://lookerstudio.google.com/embed/u/0/reporting/20f15630-7429-4aac-8cdd-581feac6f4e3/page/'
const MYNTRA_URL = 'https://lookerstudio.google.com/embed/u/0/reporting/20f15630-7429-4aac-8cdd-581feac6f4e3/page/'

export const PPM_CONFIG_CLIENT_WISE = {
    flipkart:{
      VISIBLE_CLIENTS: ['0Xp3Tv6Jls','WoHWAQxckc','3Bk8f4ZrVe','S3pGsBcZTS'],
      CLIENTS_REPORT_URLS:{
          '0Xp3Tv6Jls_SKU':`${FLIPKART_URL}p_wwxf9jhjid`,
          '0Xp3Tv6Jls_FSN':`${FLIPKART_URL}p_dq1r7pgjid`,
          'WoHWAQxckc_SKU':`${FLIPKART_URL}p_mt2xrnvjid`,
          'WoHWAQxckc_FSN':`${FLIPKART_URL}p_ni3zxsujid`,
          '3Bk8f4ZrVe_SKU':`${FLIPKART_URL}p_mt2xrnvjid`,
          '3Bk8f4ZrVe_FSN':`${FLIPKART_URL}p_cff0zawyid`,
          'S3pGsBcZTS_SKU':`${FLIPKART_URL}p_knlhlst8id`,
          'S3pGsBcZTS_FSN':`${FLIPKART_URL}p_cff0zawyid`
      }

    
    },
    myntra:{
      VISIBLE_CLIENTS : ['S3pGsBcZTS'],
      CLIENTS_REPORT_URLS:{
        'S3pGsBcZTS_product_analysis_ppm':`${MYNTRA_URL}p_atx1f47yid`,
        'S3pGsBcZTS_category_analysis_ppm':`${MYNTRA_URL}p_3kvpv2hkkd`
      }
    }
  }