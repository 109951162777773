import "./reportsDashboard.css";
import React from "react";
import { useSelector } from "react-redux";
import Overview from "../../components/overview";
import PieChart from "../../components/pieChart/pieChart";
import { Stack } from "react-bootstrap";
import CustomDropDown from "../../components/DropDown/CustomDropDown";
import dropdownIcon from "../../assets/icons/downArrowForSplitButton.svg";
import FilterLogicModal from "../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import TableRb from "../../components/Tables/tableRb";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRef, useEffect } from "react";
import CalenderComponent from "../../components/calender/Calender";
import closeIcon from "../../assets/icons/close_icon.png";
import { useGlobalContext } from "../../context/global";
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.svg";


const ReportsDashboard = (props) => {

    const {
        dateDifference,
    } = useGlobalContext();

    const navigate = useNavigate();

    const dashboardSideBarVisibiltyState = useSelector(
        (state) => state.dashboardData.dashboardSideBarVisibiltyState
    );
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [dataHeads, setdataHeads] = useState([]);

    const [showCustomDateBox, setShowCustomDateBox] = useState(false);
    const [targettingType, setTargettingType] = useState("All");


    const calenderIconRefForCampaignlist = useRef(null);

    const closeCalender = (e) => {
        if (
            calenderIconRefForCampaignlist.current &&
            showCustomDateBox &&
            !calenderIconRefForCampaignlist.current.contains(e.target)
        ) {
            setShowCustomDateBox(false);
        }
    };


    const dateInputClickHandler = (e) => {
        setShowCustomDateBox(!showCustomDateBox);
    };


    useEffect(() => {
        document.addEventListener("mousedown", closeCalender);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", closeCalender);
        };
    }, [showCustomDateBox]);




    const dummyChartData =
    {
        "date": "2023-04-09",
        "data": [98],
        "roas": 4.846436305432634,
        "spends": 849.68,
        "campaign_type": "Retarget"
    }



    // const dummyChartData =
    // {

    //     data: [30, 40, 20, 10],
    //     };


    const filterCampaigns = (e, type) => {
        // console.log("@$# 11  filterCampaigns", filterCampaigns)
    };


    const onDropdownImghandler = (type) => {
        // console.log("@$# onDropdownImghandler")
    };



    const dummySearchfilteredData = [
        {
            "id": "6400345c3c2cb56a78765866",
            "name": "Detail Page on - Amazon",
            "campaignFormat": "SP",
            "spends": "11468",
            "impressions": "1909441",
            "clicks": "5661",

            "ctr": "0.3",
            "cpc": "2.03",
            "orders": "36",
            "conv_rate": "0.64",
            "revenue": "18243.43",
            "aov": "507",
            "roas": "1.59",
        },
        {
            "id": "6400345c3c2dcb56a78765866",
            "name": "Other Placements",
            "campaignFormat": "SP",
            "spends": "1427",
            "impressions": "113382",
            "clicks": "763",
            "ctr": "0.67",
            "cpc": "1.87",
            "orders": "7",
            "conv_rate": "0.92",
            "revenue": "3248.32",
            "aov": "464",
            "roas": "2.28",
        },

        {
            "id": "6400345c3c2dcb56a78765866",
            "name": "Top of Search",
            "campaignFormat": "SP",
            "spends": "176",
            "impressions": "6674",
            "clicks": "90",
            "ctr": "1.35",
            "cpc": "1.96",
            "orders": "3",
            "conv_rate": "3.33",
            "revenue": "1190.18",
            "aov": "397",
            "roas": "6.76",
        },

    ];

    const dummyDataHeads = [

        {
            "fieldName": "Placement",
            "minRange": "-",
            "maxRange": "-",
            "id": "name",
            "isDefaultHead": true,
            "isHeadRightAlign": false,
            "isHeadWithIcon": false,
            "isAbbrivation": false
        },

        {
            "fieldName": "CAMPAIGN FORMAT",
            "minRange": "-",
            "maxRange": "-",
            "id": "name",
            "isDefaultHead": true,
            "isHeadRightAlign": false,
            "isHeadWithIcon": false,
            "isAbbrivation": false
        },

        {
            "fieldName": "SPENDS",
            "minRange": "-",
            "maxRange": "-",
            "id": "targetting",
            "isDefaultHead": true,
            "isHeadRightAlign": false,
            "isHeadWithIcon": false,
            "isAbbrivation": false
        },
        {
            "fieldName": "Impressions",
            "minRange": "-",
            "maxRange": "-",
            "id": "name",
            "isDefaultHead": true,
            "isHeadRightAlign": false,
            "isHeadWithIcon": false,
            "isAbbrivation": false
        },
        {
            "fieldName": "Clicks",
            "minRange": "-",
            "maxRange": "-",
            "id": "name",
            "isDefaultHead": true,
            "isHeadRightAlign": false,
            "isHeadWithIcon": false,
            "isAbbrivation": false
        },
        {
            "fieldName": "CTR",
            "minRange": "-",
            "maxRange": "-",
            "id": "portfolio",
            "isDefaultHead": true,
            "isHeadRightAlign": false,
            "isHeadWithIcon": false,
            "isAbbrivation": false
        },
        {
            "fieldName": "CPC",
            "minRange": "-",
            "maxRange": "-",
            "id": "budget",
            "isDefaultHead": true,
            "isHeadRightAlign": false,
            "isHeadWithIcon": false,
            "isAbbrivation": false
        },
        {
            "fieldName": "Orders (#)",
            "minRange": "-",
            "maxRange": "-",
            "id": "bid_staretegy",
            "isDefaultHead": true,
            "isHeadRightAlign": false,
            "isHeadWithIcon": false,
            "isAbbrivation": false
        },
        {
            "fieldName": "Conv Rate",
            "minRange": "-",
            "maxRange": "-",
            "id": "bid_staretegy",
            "isDefaultHead": true,
            "isHeadRightAlign": false,
            "isHeadWithIcon": false,
            "isAbbrivation": false
        },
        {
            "fieldName": "Revenue",
            "minRange": "-",
            "maxRange": "-",
            "id": "bid_staretegy",
            "isDefaultHead": true,
            "isHeadRightAlign": false,
            "isHeadWithIcon": false,
            "isAbbrivation": false
        },
        {
            "fieldName": "AOV",
            "minRange": "-",
            "maxRange": "-",
            "id": "bid_staretegy",
            "isDefaultHead": true,
            "isHeadRightAlign": false,
            "isHeadWithIcon": false,
            "isAbbrivation": false
        }
        ,
        {
            "fieldName": "ROAS",
            "minRange": "-",
            "maxRange": "-",
            "id": "bid_staretegy",
            "isDefaultHead": true,
            "isHeadRightAlign": false,
            "isHeadWithIcon": false,
            "isAbbrivation": false
        },
    ];


    const chartListData = [
        {
            id: 1,
            title: 'Campaign Format Report',
            url: '/campaign-format-report'
        },
        {
            id: 2,
            title: 'Campaign Report',
            url: '/campaign-report'
        },
        {
            id: 3,
            title: 'Search Terms Report',
            url: '/search-terms-report'
        },
        {
            id: 4,
            title: 'Targeting Report',
            url: '/targeting-report'
        },
        {
            id: 5,
            title: 'Category report SP & SD',
            url: '/detailed-category-report'
        },
        {
            id: 6,
            title: 'Category report SB',
            url: ''
        },
        {
            id: 7,
            title: 'Sub Category Report',
            url: '/product-report'
        }
    ]


    let tableBody = (
        <div className="main-campaignlist-table-body-container  lsit-table-scrollbar">
            {dummySearchfilteredData?.length ? (
                <table className="campaign-list-table">
                    <thead className="fixed-table">
                        <tr >
                            {dummyDataHeads?.map((th, mainIndex) => {
                                return (
                                    <th

                                        className={
                                            th.isHeadRightAlign
                                                ? "campaig-list-head cl-end-align"
                                                : th.isHeadWithIcon === "sort"
                                                    ? "campaig-list-head active-filter-colmun"
                                                    : "campaig-list-head "
                                        }

                                        style={{
                                            zIndex: mainIndex === 0 ? 99 : ''
                                        }}
                                    >
                                        {th.fieldName}
                                        {/* {th} */}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    {dummySearchfilteredData?.length ? (
                        <tbody className="another-tbody">
                            {dummySearchfilteredData?.map((data, index1) => {
                                data = JSON.stringify(data);
                                data = JSON.parse(data);

                                for (let i = 0; i < dataHeads?.length; i++) {
                                    if (!dataHeads[i]?.isDefaultHead) {
                                        data[dataHeads[i]?.id] = data[dataHeads[i]?.id]
                                            ? data[dataHeads[i]?.id]
                                            : "-";
                                    }
                                }
                                return (
                                    <React.Fragment key={index1 + 1}>
                                        <tr key={`${data?.id}`}>
                                            <td className="first-column-sw-btn">
                                                {data?.name}

                                            </td>

                                            <td className="  ">
                                                {/* <td> */}
                                                {data?.campaignFormat}</td>

                                            <td>{data?.spends}</td>
                                            <td>{data?.impressions}</td>
                                            <td>{data?.clicks}</td>
                                            <td>{parseFloat(data?.ctr).toFixed(2)}%</td>
                                            <td>{parseFloat(data?.cpc).toFixed(2)}</td>
                                            <td>{data?.orders}</td>
                                            <td>{parseFloat(data?.conv_rate).toFixed(2)}%</td>
                                            <td>{data?.revenue}</td>
                                            <td>{data?.aov}</td>
                                            <td>{data?.roas}</td>
                                        </tr>
                                    </React.Fragment>
                                );
                            })}
                            <tr>
                                <td></td>
                                <td style={{ fontWeight: 'bold' }}>TOTAL</td>
                                <td style={{ fontWeight: 'bold' }}>
                                    {dummySearchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.spends);
                                    }, 0).toFixed(0)}
                                </td>
                                <td style={{ fontWeight: 'bold' }}>
                                    {dummySearchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.impressions);
                                    }, 0).toFixed(0)}
                                </td>
                                <td style={{ fontWeight: 'bold' }}>
                                    {dummySearchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.clicks);
                                    }, 0).toFixed(0)}
                                </td>
                                <td style={{ fontWeight: 'bold' }}>
                                    {dummySearchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.ctr);
                                    }, 0).toFixed(2)}%
                                </td>
                                <td style={{ fontWeight: 'bold' }}>
                                    {dummySearchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.cpc);
                                    }, 0).toFixed(2)}
                                </td>

                                <td style={{ fontWeight: 'bold' }}>
                                    {dummySearchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.orders);
                                    }, 0).toFixed(0)}
                                </td>

                                <td style={{ fontWeight: 'bold' }}>
                                    {dummySearchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.conv_rate);
                                    }, 0).toFixed(2)}%
                                </td>


                                <td style={{ fontWeight: 'bold' }}>
                                    {dummySearchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.revenue);
                                    }, 0).toFixed(2)}
                                </td>


                                <td style={{ fontWeight: 'bold' }}>
                                    {dummySearchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.aov);
                                    }, 0).toFixed(0)}
                                </td>


                                <td style={{ fontWeight: 'bold' }}>
                                    {dummySearchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.roas);
                                    }, 0).toFixed(2)}
                                </td>

                            </tr>
                        </tbody>
                    ) : (
                        <div>
                            <p>NO DATA FOUND</p>
                        </div>
                    )}
                </table>
            ) : (
                <div>
                    <p>NO DATA FOUND</p>
                    {/* <Spinner size={"lg"} shape={"border"}/> */}
                </div>
            )}
        </div>
    );



    return (
        <div
            className={
                dashboardSideBarVisibiltyState
                    ? "main-content-container-onopen-mode"
                    : "main-content-container-oncolapse-mode"
            }
            id="main-box" >




            <div
                className="dropdownWrapper"
            // className="d-flex justify-content-end align-item-end"

            >

                <Stack gap={4}>
                    <CustomDropDown
                        // title={"Campaign Type"}
                        filterCampaigns={filterCampaigns}
                        targettingValue={targettingType}
                        onDropdownImghandler={onDropdownImghandler}
                        src={dropdownIcon}
                        type={"objective"}
                        // selectDropDown={selectDropDown}
                        // setSelectDropDown={setSelectDropDown}
                        data={["All", "SP", "SD", "SB"]}
                    />
                </Stack>


                <button
                    className="date-container date-btn-campaignlist d-none"
                    onClick={dateInputClickHandler}
                >
                    <div className="d-flex justify-conntent-center">
                        <span className="date-btn-text">
                            {dateDifference}
                        </span>
                    </div>
                    <button className="btn-calender-img-holder">
                        <span className="">
                            <img
                                className="dropdown-img-for-select"
                                src={selectSideIcon}
                                alt={"SelectSideIcon"}
                            ></img>

                            <img
                                className="calender-icon-for-campaignlist"
                                src={CalenderIconForCampaignList}
                                alt={"CalendarIcon"}
                            ></img>
                        </span>
                    </button>
                </button>
            </div>

            <div
                ref={calenderIconRefForCampaignlist}
                className={
                    showCustomDateBox
                        ? "calender-inclasslist-container"
                        : "hide"
                }
            >
                <CalenderComponent setShowCustomDateBox={setShowCustomDateBox}></CalenderComponent>
                <span className="calender-close-icon-campaignlist">
                    <img
                        onClick={() => setShowCustomDateBox(false)}
                        src={closeIcon}
                        alt={"CloseIcon"}
                    ></img>
                </span>
            </div>


            <div style={{ display: 'flex', marginBottom: '15px' }}>

                <Stack gap={4}>
                    <CustomDropDown
                        title={"Campaign Type"}
                        filterCampaigns={filterCampaigns}
                        targettingValue={targettingType}
                        onDropdownImghandler={onDropdownImghandler}
                        src={dropdownIcon}
                        type={"objective"}
                        // selectDropDown={selectDropDown}
                        // setSelectDropDown={setSelectDropDown}
                        data={["All", "SP", "SD", "SB"]}
                    />
                </Stack>
                <Stack gap={4}>
                    <CustomDropDown
                        title={"Campaign Format"}
                        filterCampaigns={filterCampaigns}
                        targettingValue={targettingType}
                        onDropdownImghandler={onDropdownImghandler}
                        src={dropdownIcon}
                        type={"objective"}
                        // selectDropDown={selectDropDown}
                        // setSelectDropDown={setSelectDropDown}
                        data={["All", "SP", "SD", "SB"]}
                    />
                </Stack>
                <Stack gap={4}>
                    <CustomDropDown
                        title={"Targetting Type"}
                        filterCampaigns={filterCampaigns}
                        targettingValue={targettingType}
                        onDropdownImghandler={onDropdownImghandler}
                        src={dropdownIcon}
                        type={"objective"}
                        // selectDropDown={selectDropDown}
                        // setSelectDropDown={setSelectDropDown}
                        data={["All", "SP", "SD", "SB"]}
                    />
                </Stack>
                <Stack gap={4}>
                    <CustomDropDown
                        title={"Product Set"}
                        filterCampaigns={filterCampaigns}
                        targettingValue={targettingType}
                        onDropdownImghandler={onDropdownImghandler}
                        src={dropdownIcon}
                        type={"objective"}
                        // selectDropDown={selectDropDown}
                        // setSelectDropDown={setSelectDropDown}
                        data={["All", "SP", "SD", "SB"]}
                    />
                </Stack>
                <Stack gap={4}>
                    <CustomDropDown
                        title={"Portfolio Name"}
                        filterCampaigns={filterCampaigns}
                        targettingValue={targettingType}
                        onDropdownImghandler={onDropdownImghandler}
                        src={dropdownIcon}
                        type={"objective"}
                        // selectDropDown={selectDropDown}
                        // setSelectDropDown={setSelectDropDown}
                        data={["All", "SP", "SD", "SB"]}
                    />
                </Stack>
                <Stack gap={4}>
                    <CustomDropDown
                        title={"Campaign Name"}
                        filterCampaigns={filterCampaigns}
                        targettingValue={targettingType}
                        onDropdownImghandler={onDropdownImghandler}
                        src={dropdownIcon}
                        type={"objective"}
                        // selectDropDown={selectDropDown}
                        // setSelectDropDown={setSelectDropDown}
                        data={["All", "SP", "SD", "SB"]}
                    />
                </Stack>
            </div>


            <p className="title">
                Reports
            </p>

            <div style={{ display: 'flex', marginBottom: '15px', flexWrap: 'wrap' }}>

                {
                    chartListData.map((data, index) =>
                        <div
                            key={data.id}
                            className="chartWrapper">
                            <PieChart
                                key={index}
                                showColors={true}
                                isShowSelect={false}
                                isShowLegend={true}
                                campaignData={data.title}
                                id={0}
                                rightLegend={true}
                                noMinHeight={true}
                                chartTitle={data.title}
                            />
                            <p
                                className="chartFooter"
                                onClick={() => navigate(data.url)}
                            >View Report</p>
                        </div>
                    )
                }
            </div>
         

             

            
        </div>
    )
}


export default ReportsDashboard;