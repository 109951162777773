import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import "./dashboardFilterSettingOptions.css";
import SplitDropdownBtn from "../Buttons/splitDropdownBtn";
import RadioButtonRb from "../Buttons/radioButtonRb/radioButtonRb";
import SectionTitle from "../sectionTitle";
import { dashboardActions } from "../../redux/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { masterApi } from "../../apis/masterApi";
import Dropdown from "react-bootstrap/Dropdown";
import SplitButton from "react-bootstrap/SplitButton";
import axios from "axios";

const DashboardFilterSettingOptions = () => {
  const [portfolios, setPortfolios] = useState([]);
  const dispatch = useDispatch();
  const [activeBtn, setActiveBtn] = useState("update");
  const onRestHandler = (e) => {
    setActiveBtn(e.target.id);
    // dispatch(dashboardActions.dashboardLayoutSequencedata(itemList))
    dispatch(dashboardActions?.dasboardLayoutSettingModalHide());
    // dispatch(dashboardActions.dashboardLayoutSequencedata(layoutOptiosAsDefaultSwquence))
  };
  let clientId = JSON.parse(localStorage.getItem("clientId"));
  const getPortfolios = () => {
    axios
      .get(
        `https://adyogiadsapi.adyogi.com/client/${clientId}/portfolios?source=amazon`
      )
      .then((res) => {
        setPortfolios(res.data.data);
      });
  };
  useEffect(() => {
    getPortfolios();
  }, []);

  const updateBtnHandler = (e) => {
    dispatch(dashboardActions?.dasboardLayoutSettingModalHide());
    setActiveBtn(e.target.id);
    // dispatch(dashboardActions.dashboardLayoutSequencedata(itemList))
  };

  return (
    <div className="dashboard-filter-container">
      <div className="setting-modal-inside-filter-main-content-body">
        <Stack gap={3} className="border-bottom">
          <SectionTitle sectionTitleHeading=" Overview"></SectionTitle>

          <Stack gap={3}>
            <Stack direction="horizontal" gap={3}>
              <SplitDropdownBtn
                btnTitle="All Portfolios"
                options={portfolios}
              ></SplitDropdownBtn>
            </Stack>
          </Stack>
        </Stack>
        <Stack gap={3} className="border-bottom">
          <SectionTitle sectionTitleHeading="  Trend Graph"></SectionTitle>
          <Stack gap={3}>
            <Stack direction="horizontal" gap={3}>
              <SplitDropdownBtn
                btnTitle="Spends"
                options={[
                  "Spends",
                  "Impressions",
                  "Clicks",
                  "Sale_Value",
                  "CTR",
                  "CVR",
                  "CPM",
                  "AOV",
                  "CPC",
                  "ROAS",
                  "ACOS",
                ]}
              ></SplitDropdownBtn>
              <SplitDropdownBtn
                btnTitle="Impression"
                options={[
                  "Spends",
                  "Impressions",
                  "Clicks",
                  "Sale_Value",
                  "CTR",
                  "CVR",
                  "CPM",
                  "AOV",
                  "CPC",
                  "ROAS",
                  "ACOS",
                ]}
              ></SplitDropdownBtn>
            </Stack>
          </Stack>
        </Stack>
        <Stack gap={3} className="border-bottom">
          <SectionTitle sectionTitleHeading="Select Range"></SectionTitle>
          <Stack gap={3}>
            <Stack
              direction="horizontal"
              gap={5}
              className="select-range-container"
            >
              <RadioButtonRb
                className="label-text-filter"
                name="select-range"
                label="Day"
              ></RadioButtonRb>
              <RadioButtonRb
                className="label-text-filter"
                name="select-range"
                label="Week"
              ></RadioButtonRb>
            </Stack>
            <Stack
              direction="horizontal"
              gap={5}
              className="select-range-container"
            >
              <RadioButtonRb
                className="label-text-filter"
                name="select-range"
                label="Month"
              ></RadioButtonRb>
              {/* <RadioButtonRb className="label-text-filter px-1" name="select-range" label="Year"></RadioButtonRb> */}
            </Stack>
          </Stack>
        </Stack>
        <Stack gap={3} className="border-bottom campaign-summary-container">
          <SectionTitle sectionTitleHeading="Campaign Summary"></SectionTitle>
          {/* <Stack > */}
          <Stack className="camp-sum-option-container">
            <Stack direction="horizontal" gap={5} className="cam-sum-label">
              <Stack>
                <div className="cam-sum-opt">Type</div>
              </Stack>

              <SplitDropdownBtn
                btnTitle="Spends"
                options={[
                  "Spends",
                  "Impressions",
                  "Clicks",
                  "Sale_Value",
                  "CTR",
                  "CVR",
                  "CPM",
                  "AOV",
                  "CPC",
                  "ROAS",
                  "ACOS",
                ]}
              ></SplitDropdownBtn>
            </Stack>
            {/* </Stack> */}
            {/* <Stack> */}
            <Stack direction="horizontal" gap={5}>
              <Stack>
                <div className="cam-sum-opt">Prospect</div>
              </Stack>

              <SplitDropdownBtn
                btnTitle="Spends"
                options={[
                  "Spends",
                  "Impressions",
                  "Clicks",
                  "Sale_Value",
                  "CTR",
                  "CVR",
                  "CPM",
                  "AOV",
                  "CPC",
                  "ROAS",
                  "ACOS",
                ]}
              ></SplitDropdownBtn>
            </Stack>

            {/* </Stack> */}
            {/* <Stack> */}
            <Stack direction="horizontal" gap={5}>
              <Stack>
                <div className="cam-sum-opt">Retargeting</div>
              </Stack>

              <SplitDropdownBtn
                btnTitle="Spends"
                options={[
                  "Spends",
                  "Impressions",
                  "Clicks",
                  "Sale_Value",
                  "CTR",
                  "CVR",
                  "CPM",
                  "AOV",
                  "CPC",
                  "ROAS",
                  "ACOS",
                ]}
              ></SplitDropdownBtn>

              {/* </Stack> */}
            </Stack>
          </Stack>
        </Stack>
        <Stack gap={3} className="border-bottom">
          <SectionTitle sectionTitleHeading=" Performance Summary"></SectionTitle>

          <Stack
            direction="horizontal"
            gap={5}
            className="d-flex justify-content-start align-item-start pb-2"
          >
            <RadioButtonRb
              className="label-text-filter"
              name=" Performance-Summary"
              label="Category"
            ></RadioButtonRb>
            <RadioButtonRb
              className="label-text-filter"
              name=" Performance-Summary"
              label="Product"
            ></RadioButtonRb>
          </Stack>
        </Stack>

        <Stack className="border-bottom targetting-sec-setting" gap={3}>
          <SectionTitle sectionTitleHeading="Targetting"></SectionTitle>
          {/* <Stack> */}
          <div className="d-flex flex-row select-view-container">
            <div className="cam-sum-opt2">Select View</div>
            <div
              direction="horizontal"
              gap={5}
              className="d-flex flex-col d-flex justify-content-start align-item-start"
            >
              <RadioButtonRb
                className="label-text-filter"
                name="view"
                label="Default"
              ></RadioButtonRb>
              <RadioButtonRb
                className="label-text-filter"
                name="view"
                label="Funnel"
              ></RadioButtonRb>
            </div>
          </div>
          {/* </Stack > */}
          <div className="d-flex flex-row select-view-container">
            <div className="cam-sum-opt2">Select View</div>
            <div
              direction="horizontal"
              gap={5}
              className="d-flex flex-col d-flex justify-content-end align-item-end"
            >
              <RadioButtonRb
                className="label-text-filter"
                name="view"
                label="Top 10"
              ></RadioButtonRb>
              <RadioButtonRb
                className="label-text-filter"
                name="view"
                label="Bottom 10"
              ></RadioButtonRb>
            </div>
          </div>
        </Stack>
        {/* <Stack gap={2} className="border-bottom">
                <SectionTitle
                    sectionTitleHeading="Low Performing Search Term">
                </SectionTitle>
                <Stack>
                    <RadioButtonRb className="label-text-filter" name="low-performing-term" label=" Current Week Vs Last Week" >   </RadioButtonRb>
                </Stack>

            </Stack> */}
        <Stack gap={3} className="border-bottom">
          <SectionTitle sectionTitleHeading=" Product Summary"></SectionTitle>

          <Stack direction="horizontal" gap={5}>
            <RadioButtonRb
              className="label-text-filter"
              name="product-summary"
              label="Low Stock"
            ></RadioButtonRb>
            <RadioButtonRb
              className="label-text-filter"
              name="product-summary"
              label="Zero Ad Spend"
            ></RadioButtonRb>
          </Stack>
        </Stack>
      </div>
      <Stack
        direction="horizontal"
        gap={3}
        className=" dash-layout-footer-btn "
      >
        <button
          className={
            activeBtn === "reset"
              ? "setting-dahs-primary-active-btn"
              : "setting-dash-primary-inactive-btn"
          }
          id="reset"
          onClick={onRestHandler}
        >
          Reset
        </button>
        <button
          className={
            activeBtn === "update"
              ? "setting-dahs-primary-active-btn"
              : "setting-dash-primary-inactive-btn"
          }
          id="update"
          onClick={updateBtnHandler}
        >
          Update
        </button>
      </Stack>
      {/* <Stack className="border-bottom">

            <h3 gap={3} >
                Key Insight
            </h3>
            <Stack gap={2}>
                <div className='d-flex justify-content-center flex-column'>
                    <div direction="horizontal" className='d-flex  justify-content-between  mr-5'>
                        <RadioButtonRb className="label-text-filter"  name="key-insight" label="Bid Change"></RadioButtonRb>
                        <div className="pe-3">
                            <RadioButtonRb className="label-text-filter"  name="key-insight" label="Budget Change" ></RadioButtonRb>
                        </div>
                    </div>
                    <div direction="horizontal" className='d-flex    justify-content-between  pr-4'>
                        <RadioButtonRb className="label-text-filter"  name="key-insight" label="Advertised Product"></RadioButtonRb>
                        <RadioButtonRb className="label-text-filter"  name="key-insight" label="Targeted Keyword"></RadioButtonRb>
                    </div>
                </div>
            </Stack>

        </Stack> */}
    </div>
  );
};

export default DashboardFilterSettingOptions;
