import { useNavigate, useLocation,Navigate } from "react-router-dom";
import useUserDetails from "../../helper/userData/useUserDetails";
import { UserRouteAccessSetting , PageVersionsAccessSetting } from "../../constant/settings/userRouteAccess";
import {useGlobalContext} from  '../../context/global'
import { TOAST_DANGER, TOAST_POSITION_TOP_RIGHT } from "../../constant/HardTypes";

// This function is there  to check the restricted User routes according to the user's role (Individual User or Multiuser)
export default function RouteAccessWrapper({ children }) {
  const [userDetails] = useUserDetails();
  const {showAlert} = useGlobalContext()
  const { restrictedPages } = UserRouteAccessSetting.singleuser;
  const {restrictedPages:betaRestrictedPages} = UserRouteAccessSetting.beta_users
  const {smart_ads} = PageVersionsAccessSetting?.beta
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  if (userDetails === null) {
    return children;
  } else {
    const { user } = userDetails;
    if (user.multiUser === "no") {
      // For Restricted Pages
      if (restrictedPages.includes(pathname)) {
        navigate(-1);
       return alert("Only admins allowed")
      }
      // This code is to restrict access to beta pages for smart ads
      if(betaRestrictedPages.includes(pathname) && !smart_ads?.includes(user?.email)) {
        navigate('./');
        alert("You Cannot Access This Page")
        return <Navigate to={"/"}/>
      }
    } else {
      if(betaRestrictedPages.includes(pathname) && !smart_ads?.includes(user?.email)) {
        navigate('./');
        alert("You Cannot Access This Page")
        return <Navigate to={"/"}/>
      }
      return children;
    }
  }
  return children;
}


