import "./editCampaign.css";
import "./searchTerm.css";
import TableRb from "../../components/Tables/tableRb";
import SwitchBtn from "../../components/Buttons/switchBtn";
import React, { useEffect, useState } from "react";
import SectionTittle from "../../components/sectionTitle";
import "./editCampaignMainPage.css";
import settingOptionIcon from "../../assets/icons/overviewSeiingIcon.svg";
import getAdsetData from "../../helper/CampaignList/editTargeting/AsynCalls/getAdsetData";
import {v4 as uuidv4} from 'uuid'
import SearchBarForCampaignListOnly from "../../components/searchBarForCampaignListOnly";
import Loader from "../../components/loader";
import { masterApi } from "../../apis/masterApi";
import { useSelector, useDispatch } from "react-redux";
import { roundFigureNum } from "../../constant/roundFigureNum";
import { onInputRangeChangeHandler } from "../../helper/CampaignList/filterByMetrices";
import FilterLogicModal from "../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import { campaignListActions } from "../../redux/slices/campaignList";
import { useGlobalContext } from "../../context/global";
import ascendingIconSvg from "../../assets/svgs/acendingIconSvg.svg";
import descendingIconSvg from "../../assets/svgs/descendingIcon.svg";
import { useAuthHeader } from "react-auth-kit";
import { useSearchParams } from "react-router-dom";
import { getCurrencySymbol } from "../../constant/defaultCurrencyConfigurations";
import { calculateACOS, calculateAOV, calculateCPC, calculateCPM, calculateCTR, calculateCVR } from "../../helper/CalculateMetrices/calculateMetrics";
import useHighlightList from '../../hooks/useHighlightList'
const SerachTerms = () => {
  const dispatch = useDispatch();
  const [minMaxFilterActivated, setMinMaxFilterActivated] = useState(false);
  const [searchTermStatMainData, setSearchTermStatMainData] = useState([]);
  const [searchTermDataForTable, setSearchTermDataForTable] = useState([]);
  const [handleHighlightList] = useHighlightList(searchTermDataForTable,setSearchTermDataForTable)
  const [searchTermDataForTableAsCopy, setsSarchTermDataForTableAsCopy] =
    useState([]);
  const authHeader = useAuthHeader();
  const token = authHeader();
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const { calendarDate, setCalendarDate,applyCalendar } = useGlobalContext();
  const [currentAdset, setCurrentAdset] = useState([]);
  const [dataHeads, setdataHeads] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(2);
  const [isSortBoxVisible, setIsSortBoxVisible] = useState(false);
  // If the user is coming from different page then extract param set initial text state
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const {search_term} = params
  const initialTextState = search_term ?? ""
  const [text, setText] = useState(initialTextState); 
  
  const editBtnclickedCampaignDetailsForEditTargetingTable = useSelector(
    (state) =>
      state.campaignListData.editBtnclickedCampaignDetailsForEditTargetingTable
  );
  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );
  const appliedFilterData = useSelector(
    (state) => state.campaignListData.appliedFilterData
  );
  const getUpdatedConfigHeadsForEditSearchTermListSection = useSelector(
    (state) =>
      state.campaignListData.getUpdatedConfigHeadsForEditSearchTermListSection
  );

  const [sortingActiveKey,setSortingActiveKey] = useState("spends")

  const currencySymbol = getCurrencySymbol();

  const client_id =
    JSON.parse(localStorage.getItem("clientId")) === null
      ? "aiekYz4hQy"
      : JSON.parse(localStorage.getItem("clientId"));

  useEffect(() => {
    setdataHeads(getUpdatedConfigHeadsForEditSearchTermListSection);
  }, [getUpdatedConfigHeadsForEditSearchTermListSection]);

  useEffect(() => {
    AdsetSearchTermStatsbyCampIdandAdsetId();
  }, [editBtnclickedCampaignDetailsForEditTargetingTable, applyCalendar]);//[editBtnclickedCampaignDetailsForEditTargetingTable, calendarDate]

  useEffect(() => {
    checkNegativeKeywordStatusInAdset();
  }, [searchTermStatMainData]);

  // useEffect(() => {
  //   onInputRangeChangeHandler();
  // }, [appliedFilterData]);

  useEffect(() => {
    formatDateFn(calendarDate[0].startDate);
  }, []);
  const formatDateFn = (date) => {
    const selectedDate = new Date(date);
    const formattedDate =
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear();
    return (
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear()
    );
  };
  useEffect(() => {
    const afterNegativeKeywordData = checkNegativeKeywordStatusInAdset(
      searchTermStatMainData
    );
    const dataAfterSearch = onSearch(text, afterNegativeKeywordData);
    const dataAfterFilters = onInputRangeChangeHandler(
      dataAfterSearch,
      appliedFilterData,
	  setMinMaxFilterActivated
    );
    const dataWithSummaryRow = dataWithSummary(afterNegativeKeywordData)
    const sortedData = onSortData(sortingActiveKey,dataAfterFilters)
    setSearchTermDataForTable([dataWithSummaryRow,...sortedData]);
  }, [searchTermStatMainData, text, appliedFilterData]);

  const dataWithSummary = (data) => {
    const calculateTotal = (data,key) => {
      return Number.isNaN(data.reduce((acc,cvv) => acc + cvv[key],0)) ? 0 :data.reduce((acc,cvv) => acc + cvv[key],0)
    }

    const totalClicks = calculateTotal(data,"clicks")
    const totalImpressions = calculateTotal(data,"impressions")
    const totalOrders = calculateTotal(data,"orders")
    const totalSpends = calculateTotal(data,"spends")
    const totalRevenue = calculateTotal(data,"sale_value")
    
    const totalSummaryData = {
      "clicks": calculateTotal(data,"clicks"),
      "impressions": calculateTotal(data,"impressions"),
      "orders": calculateTotal(data,"orders"),
      "cpm": calculateCPM(totalSpends,totalImpressions),
      "ctr": calculateCTR(totalClicks,totalImpressions),
      "cpc": calculateCPC(totalSpends,totalClicks),
      "cvr": calculateCVR(totalOrders,totalClicks),
      "roas": calculateTotal(data,"sale_value")/calculateTotal(data,"spends"),
      "spends": calculateTotal(data,"spends"),
      "sale_value": calculateTotal(data,"sale_value"),
      "aov":calculateAOV(totalRevenue,totalOrders),
      "acos":calculateACOS(totalSpends,totalRevenue),
      "campaign_id": uuidv4(),
      "adset_id": uuidv4(),
      "adset_name": "",
      "search_term": "",
      "isActionDisabled": false,
      "isSummaryRow":true
  }
  return totalSummaryData
  }

  const checkNegativeKeywordStatusInAdset = (dataPassed = []) => {
    let searchTermStatMainDataCopy = JSON.stringify(dataPassed);
    searchTermStatMainDataCopy = JSON.parse(searchTermStatMainDataCopy);
    for (let s = 0; s < searchTermStatMainDataCopy?.length; s++) {
      searchTermStatMainDataCopy[s].isActionDisabled = false;
      if (
        currentAdset?.adset?.amz_negative_keyword_targeting &&
        currentAdset?.adset?.amz_negative_keyword_targeting.length
      ) {
        let negativeTargetingInst =
          currentAdset?.adset?.amz_negative_keyword_targeting;
        for (let i = 0; i < negativeTargetingInst.length; i++) {
          if (
            negativeTargetingInst[i]?.name ==
            searchTermStatMainDataCopy[s]?.search_term
          ) {
            searchTermStatMainDataCopy[s].isActionDisabled = true;
          }
        }
      }
    }
    return searchTermStatMainDataCopy;
    // setSearchTermDataForTable(searchTermStatMainDataCopy);
    // setsSarchTermDataForTableAsCopy(searchTermStatMainDataCopy);
  };
  async function AdsetSearchTermStatsbyCampIdandAdsetId() {
    let filtering = `[{ "field_name": "campaign_id", "operator": "EQUALS", "value": ${params?.numericCampaignId} }, { "field_name": "adset_id", "operator": "IN", "values": [${params?.numericAdsetId}] }]`;

    // let dataForEncodeForFilter = `[{"field_name":"campaign_id","operator":"EQUALS","value":${editBtnclickedCampaignDetailsForEditTargetingTable.campaign_id}},{"field_name":"adset_id","operator":"IN","values":[${editBtnclickedCampaignDetailsForEditTargetingTable.adset_id}]}]`

    let dataForFiltering = encodeData(filtering);

    setIsLoaderVisible(true);
    try {
      const data = {
        level: "adset",
        platform: "amazon",
        // date_preset: "last_7d",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        fields:
          "campaign_id,adset_id,search_term,adset_name,spends,roas,clicks,sale_value,clicks,impressions,cpc,cvr,aov,acos,cpm,orders,ctr",
        group_by: "search_term",
        filtering: dataForFiltering,
      };
      const result = await masterApi(data, "searchTermStat");
      const adsetData = await getAdsetData(
        params?.campaignId,
        params?.adsetId,
        client_id
      );
      setCurrentAdset(adsetData);
      setSearchTermStatMainData(result.data.data);
      setIsLoaderVisible(false);
      return result;
    } catch (e) {
      setIsLoaderVisible(false);
    }
  }

  const updateNegativeKeywordTargetingOnServer = (adsetId, searchTerm) => {
    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    var raw = JSON.stringify({
      type: "NEGATIVE_KEYWORD",
      amz_negative_keyword_targeting: [
        {
          text: searchTerm,
          status: "ENABLED",
          match: "exact",
        },
      ],
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://adyogiadsapi.adyogi.com/client/${client_id}/campaigns/${params?.campaignId}/adsets/${params?.adsetId}:add-amazon-target`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log("result"))
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    setdataHeads(getUpdatedConfigHeadsForEditSearchTermListSection);
  }, [getUpdatedConfigHeadsForEditSearchTermListSection]);

  useEffect(() => {
    AdsetSearchTermStatsbyCampIdandAdsetId();
  }, [editBtnclickedCampaignDetailsForEditTargetingTable, applyCalendar]);//[editBtnclickedCampaignDetailsForEditTargetingTable, calendarDate]

  // useEffect(() => {
  //   checkNegativeKeywordStatusInAdset();
  // }, [searchTermStatMainData]);

  useEffect(() => {
    setIsFilterVisible(filterModalInCampaignListVisibilty);
  }, [filterModalInCampaignListVisibilty]);

  // useEffect(() => {
  //   onInputRangeChangeHandler();
  // }, [appliedFilterData]);

  useEffect(() => {
    formatDateFn(calendarDate[0].startDate);
  }, []);

  const encodeData = (data) => {
    let encodedData = encodeURIComponent(data);
    return encodedData;
  };

  const onToggleBtnHandler = (e, adsetId, searchTerm) => {
    if (e.target.checked) {
      updateNegativeKeywordTargetingOnServer(adsetId, searchTerm);
    }
  };

  const cancelIconInSearchInput = () => {
    setText("")
  }
  // const onInputRangeChangeHandler = (dataPassed=[]) => {
  //   // searchTermDataForTableAsCopy
  //   // We are applying filter on deep Copy state but not mutating it and updating the shallow copy state.
  //   let tempCampaigns = JSON.stringify(dataPassed);

  //   tempCampaigns = JSON.parse(tempCampaigns);

  //   let filteredCampaigns = [];
  //   let filterWithDashAsZero = [];

  //   for (let i = 0; i < appliedFilterData?.length; i++) {
  //     let temp = JSON.parse(JSON.stringify(appliedFilterData[i]));
  //     temp.minRange = isNaN(temp.minRange) ? 0 : Number(temp.minRange);
  //     temp.maxRange =
  //       temp.maxRange == "" || isNaN(temp.maxRange)
  //         ? Infinity
  //         : Number(temp.maxRange);

  //     if (
  //       (temp.minRange != 0 && temp.maxRange != Infinity) ||
  //       (temp.minRange == 0 && temp.maxRange != Infinity)
  //     ) {
  //       filterWithDashAsZero.push(temp);
  //     } else if (
  //       (temp.minRange != 0 && temp.maxRange == Infinity) ||
  //       (temp.minRange != 0 && temp.maxRange == "")
  //     ) {
  //       filterWithDashAsZero.push(temp);
  //     }
  //   }

  //   for (let r = 0; r < tempCampaigns.length; r++) {
  //     let shouldPass = true;

  //     for (let i = 0; i < filterWithDashAsZero.length; i++) {
  //       let currentFilter = filterWithDashAsZero[i];

  //       if (
  //         (currentFilter.minRange != 0 && currentFilter.maxRange != Infinity) ||
  //         (currentFilter.minRange == 0 && currentFilter.maxRange != Infinity) ||
  //         (currentFilter.minRange != 0 && currentFilter.maxRange == Infinity)
  //       ) {
  //         let currentCampaign = tempCampaigns[r];
  //         if (
  //           currentCampaign[currentFilter.id] != undefined &&
  //           currentCampaign[currentFilter.id] !== null
  //         ) {
  //           if (
  //             currentCampaign[currentFilter.id] < currentFilter.minRange ||
  //             (currentFilter.maxRange !== Infinity &&
  //               currentCampaign[currentFilter.id] > currentFilter.maxRange)
  //           ) {
  //             shouldPass = false;
  //           }
  //         } else {
  //           shouldPass = false;
  //         }
  //       } else {
  //         shouldPass = false;
  //       }
  //     }
  //     if (shouldPass) {
  //       filteredCampaigns.push(tempCampaigns[r]);
  //     }
  //   }
  //   return filteredCampaigns
  // };
  const onFilterClickHandler = () => {
    dispatch(campaignListActions.showFilterModalInCampaignList());
  };

  const onSpendFilterClickHandler = (value, index) => {
    let tempTargetStat = JSON.stringify(searchTermDataForTable);
    tempTargetStat = JSON.parse(tempTargetStat);
    const mainValue = value === "REVENUE" ? "sale_value" : value;

    tempTargetStat = tempTargetStat.sort((a, b) => {
      let x = a?.[mainValue.toLowerCase()] ?? 0;
      let y = b?.[mainValue.toLowerCase()] ?? 0;
      return isSortBoxVisible ? y - x : x - y;
    });
    setSearchTermDataForTable(tempTargetStat);
    setIsSortBoxVisible(!isSortBoxVisible);
    setCurrentIndex(index);
    setSortingActiveKey(mainValue?.toLowerCase())
  };

  const onSortData = (value,data) => {
    let tempTargetStat = JSON.stringify(data);
    tempTargetStat = JSON.parse(tempTargetStat);
      const mainValue = value === "REVENUE" ? "sale_value" : value;
      tempTargetStat = tempTargetStat?.sort((a, b) => {
        let x = a?.[mainValue.toLowerCase()] ?? 0;
        let y = b?.[mainValue.toLowerCase()] ?? 0;
        return y-x;
      });
    return tempTargetStat
  }

  const getHeadClass = (th,mainIndex) => {
    let clsName = "";
    if(th.isHeadRightAlign){
        clsName = "et-end-align  et-table-heads"
    }else{
        clsName = "et-text-left-align et-table-heads"
    }

    if(th.isHeadRightAlign && mainIndex === currentIndex){
        clsName += " selected"
    }
    return clsName;
}

  let editSerachTermtableBody = (
    <div className="search-term-main-table">
      {searchTermDataForTable.length ? (
        <table>
          <thead>
            <tr>
              {/* {dataHeads.map((th, mainIndex) => {
                // return <tr>
                {
                  return (
                    <th>
                      {th.fieldName === "SPENDS" ? (
                        <div className="d-flex flex-row align-item-center justify-content-between">
                          <span>{th.fieldName}</span>
                          <span
                            className="spends-icon"
                            onClick={() =>
                              onSpendFilterClickHandler(th.fieldName, mainIndex)
                            }
                          >
                            <img
                              src={
                                isSortBoxVisible && mainIndex === currentIndex
                                  ? ascendingIconSvg
                                  : descendingIconSvg
                              }
                              className={
                                mainIndex === currentIndex
                                  ? "spends-icon-activated"
                                  : ""
                              }
                            ></img>
                          </span>
                          <div
                            className={
                              isSortBoxVisible ? "sortBox-container" : "hide"
                            }
                          ></div>
                        </div>
                      ) : th.fieldName === "ORDERS" ? (
                        <div className="d-flex flex-row align-item-center justify-content-between">
                          <span>{th.fieldName}</span>
                          <span
                            className="spends-icon"
                            onClick={() =>
                              onSpendFilterClickHandler(th.fieldName, mainIndex)
                            }
                          >
                            <img
                              src={
                                isSortBoxVisible && mainIndex === currentIndex
                                  ? ascendingIconSvg
                                  : descendingIconSvg
                              }
                              className={
                                mainIndex === currentIndex
                                  ? "spends-icon-activated"
                                  : ""
                              }
                            ></img>
                          </span>
                          <div
                            className={
                              isSortBoxVisible ? "sortBox-container" : "hide"
                            }
                          ></div>
                        </div>
                      ) : th.fieldName === "REVENUE" ? (
                        <div className="d-flex flex-row align-item-center justify-content-between">
                          <span>{th.fieldName}</span>
                          <span
                            className="spends-icon"
                            onClick={() =>
                              onSpendFilterClickHandler(th.fieldName, mainIndex)
                            }
                          >
                            <img
                              src={
                                isSortBoxVisible && mainIndex === currentIndex
                                  ? ascendingIconSvg
                                  : descendingIconSvg
                              }
                              className={
                                mainIndex === currentIndex
                                  ? "spends-icon-activated"
                                  : ""
                              }
                            ></img>
                          </span>
                          <div
                            className={
                              isSortBoxVisible ? "sortBox-container" : "hide"
                            }
                          ></div>
                        </div>
                      ) : th.fieldName === "ROAS" ? (
                        <div className="d-flex flex-row align-item-center justify-content-between">
                          <span>{th.fieldName}</span>
                          <span
                            className="spends-icon"
                            onClick={() =>
                              onSpendFilterClickHandler(th.fieldName, mainIndex)
                            }
                          >
                            <img
                              src={
                                isSortBoxVisible && mainIndex === currentIndex
                                  ? ascendingIconSvg
                                  : descendingIconSvg
                              }
                              className={
                                mainIndex === currentIndex
                                  ? "spends-icon-activated"
                                  : ""
                              }
                            ></img>
                          </span>
                          <div
                            className={
                              isSortBoxVisible ? "sortBox-container" : "hide"
                            }
                          ></div>
                        </div>
                      ) : th.fieldName === "IMPRESSIONS" ? (
                        <div className="d-flex flex-row align-item-center justify-content-between">
                          <span>{th.fieldName}</span>
                          <span
                            className="spends-icon"
                            onClick={() =>
                              onSpendFilterClickHandler(th.fieldName, mainIndex)
                            }
                          >
                            <img
                              src={
                                isSortBoxVisible && mainIndex === currentIndex
                                  ? ascendingIconSvg
                                  : descendingIconSvg
                              }
                              className={
                                mainIndex === currentIndex
                                  ? "spends-icon-activated"
                                  : ""
                              }
                            ></img>
                          </span>
                          <div
                            className={
                              isSortBoxVisible ? "sortBox-container" : "hide"
                            }
                          ></div>
                        </div>
                      ) : th.fieldName === "CLICKS" ? (
                        <div className="d-flex flex-row align-item-center justify-content-between">
                          <span>{th.fieldName}</span>
                          <span
                            className="spends-icon"
                            onClick={() =>
                              onSpendFilterClickHandler(th.fieldName, mainIndex)
                            }
                          >
                            <img
                              src={
                                isSortBoxVisible && mainIndex === currentIndex
                                  ? ascendingIconSvg
                                  : descendingIconSvg
                              }
                              className={
                                mainIndex === currentIndex
                                  ? "spends-icon-activated"
                                  : ""
                              }
                            ></img>
                          </span>
                          <div
                            className={
                              isSortBoxVisible ? "sortBox-container" : "hide"
                            }
                          ></div>
                        </div>
                      ) : th.fieldName === "CPM" ? (
                        <div className="d-flex flex-row align-item-center justify-content-between">
                          <span>{th.fieldName}</span>
                          <span
                            className="spends-icon"
                            onClick={() =>
                              onSpendFilterClickHandler(th.fieldName, mainIndex)
                            }
                          >
                            <img
                              src={
                                isSortBoxVisible && mainIndex === currentIndex
                                  ? ascendingIconSvg
                                  : descendingIconSvg
                              }
                              className={
                                mainIndex === currentIndex
                                  ? "spends-icon-activated"
                                  : ""
                              }
                            ></img>
                          </span>
                          <div
                            className={
                              isSortBoxVisible ? "sortBox-container" : "hide"
                            }
                          ></div>
                        </div>
                      ) : th.fieldName === "CTR" ? (
                        <div className="d-flex flex-row align-item-center justify-content-between">
                          <span>{th.fieldName}</span>
                          <span
                            className="spends-icon"
                            onClick={() =>
                              onSpendFilterClickHandler(th.fieldName, mainIndex)
                            }
                          >
                            <img
                              src={
                                isSortBoxVisible && mainIndex === currentIndex
                                  ? ascendingIconSvg
                                  : descendingIconSvg
                              }
                              className={
                                mainIndex === currentIndex
                                  ? "spends-icon-activated"
                                  : ""
                              }
                            ></img>
                          </span>
                          <div
                            className={
                              isSortBoxVisible ? "sortBox-container" : "hide"
                            }
                          ></div>
                        </div>
                      ) : th.fieldName === "CPC" ? (
                        <div className="d-flex flex-row align-item-center justify-content-between">
                          <span>{th.fieldName}</span>
                          <span
                            className="spends-icon"
                            onClick={() =>
                              onSpendFilterClickHandler(th.fieldName, mainIndex)
                            }
                          >
                            <img
                              src={
                                isSortBoxVisible && mainIndex === currentIndex
                                  ? ascendingIconSvg
                                  : descendingIconSvg
                              }
                              className={
                                mainIndex === currentIndex
                                  ? "spends-icon-activated"
                                  : ""
                              }
                            ></img>
                          </span>
                          <div
                            className={
                              isSortBoxVisible ? "sortBox-container" : "hide"
                            }
                          ></div>
                        </div>
                      ) : th.fieldName === "CVR" ? (
                        <div className="d-flex flex-row align-item-center justify-content-between">
                          <span>{th.fieldName}</span>
                          <span
                            className="spends-icon"
                            onClick={() =>
                              onSpendFilterClickHandler(th.fieldName, mainIndex)
                            }
                          >
                            <img
                              src={
                                isSortBoxVisible && mainIndex === currentIndex
                                  ? ascendingIconSvg
                                  : descendingIconSvg
                              }
                              className={
                                mainIndex === currentIndex
                                  ? "spends-icon-activated"
                                  : ""
                              }
                            ></img>
                          </span>
                          <div
                            className={
                              isSortBoxVisible ? "sortBox-container" : "hide"
                            }
                          ></div>
                        </div>
                      ) : th.fieldName === "AOV" ? (
                        <div className="d-flex flex-row align-item-center justify-content-between">
                          <span>{th.fieldName}</span>
                          <span
                            className="spends-icon"
                            onClick={() =>
                              onSpendFilterClickHandler(th.fieldName, mainIndex)
                            }
                          >
                            <img
                              src={
                                isSortBoxVisible && mainIndex === currentIndex
                                  ? ascendingIconSvg
                                  : descendingIconSvg
                              }
                              className={
                                mainIndex === currentIndex
                                  ? "spends-icon-activated"
                                  : ""
                              }
                            ></img>
                          </span>
                          <div
                            className={
                              isSortBoxVisible ? "sortBox-container" : "hide"
                            }
                          ></div>
                        </div>
                      ) : th.fieldName === "ACOS" ? (
                        <div className="d-flex flex-row align-item-center justify-content-between">
                          <span>{th.fieldName}</span>
                          <span
                            className="spends-icon"
                            onClick={() =>
                              onSpendFilterClickHandler(th.fieldName, mainIndex)
                            }
                          >
                            <img
                              src={
                                isSortBoxVisible && mainIndex === currentIndex
                                  ? ascendingIconSvg
                                  : descendingIconSvg
                              }
                              className={
                                mainIndex === currentIndex
                                  ? "spends-icon-activated"
                                  : ""
                              }
                            ></img>
                          </span>
                          <div
                            className={
                              isSortBoxVisible ? "sortBox-container" : "hide"
                            }
                          ></div>
                        </div>
                      ) : (
                        <span>{th.fieldName}</span>
                      )}
                    </th>
                  );
                }
              })} */}
              {dataHeads.map((th, mainIndex) => {
                return (
                  <th
                  className={getHeadClass(th,mainIndex)}
                    // className={
                    //   th.isHeadRightAlign
                    //     ? "et-end-align  et-table-heads"
                    //     : "et-text-left-align et-table-heads"
                    // }
                  >
                    {th.isHeadWithIcon === "sort" ? (
                      <div
                        className={
                          !th.isAbbrivation
                            ? "d-flex flex-row align-item-center justify-content-end captital-first-letter"
                            : "d-flex flex-row align-item-center justify-content-end"
                        }
                      >
                        <span>
                          {!th.isAbbrivation
                            ? th.fieldName.toLowerCase()
                            : th.fieldName}
                        </span>
                        <span
                          className="spends-icon"
                          onClick={() =>
                            onSpendFilterClickHandler(th.fieldName, mainIndex)
                          }
                        >
                          <img
                            src={
                              isSortBoxVisible && mainIndex === currentIndex
                                ? ascendingIconSvg
                                : descendingIconSvg
                            }
                            className={
                              mainIndex === currentIndex
                                ? "spends-icon-activated"
                                : ""
                            }
                          ></img>
                        </span>
                        <div
                          className={
                            isSortBoxVisible ? "sortBox-container" : "hide"
                          }
                        ></div>
                      </div>
                    ) : (
                      <div
                        className={
                          th.fieldName === "NEGATIVE TARGETING"
                            ? "captital-first-letter st-heads-with-two-words"
                            : "captital-first-letter"
                        }
                      >
                        {th.fieldName.toLowerCase()}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>

          {/* <tbody> */}
          {searchTermDataForTable?.map((data , index) => {
            return (
              <tbody>
                {
                  data?.isSummaryRow && index===0 ? (
                    <>
                      <tr>
                        <td>-</td>
                        <td className="fw-bold">Overall Summary</td>
                        {dataHeads.map((currentDataHead) => {
                    if (!currentDataHead?.isDefaultHead) {
                      return (
                        <td
                          className={
                            currentDataHead.isHeadRightAlign
                              ? "st-end-align fw-bold"
                              : "st-text-left-align fw-bold"
                          }
                        >
                          {data[currentDataHead?.id] ||
                          data[currentDataHead?.id] == 0
                            ? currentDataHead?.id === "spends" ||
                              currentDataHead?.id === "sale_value"
                              ? `${currencySymbol}${Math.trunc(data[currentDataHead?.id])}`
                              : roundFigureNum(data[currentDataHead?.id])
                            : "-"}
                        </td>
                      );
                    }
                  })}
                      </tr>
                    </>
                  ) : (
                    <>
                  <tr onClick={(e) => {
                    e.stopPropagation();
                    handleHighlightList(index)
                  }} className={data?.isHighlighted ? "cursor-pointer highlight" : "cursor-pointer"}>
                  <td>
                    <SwitchBtn
                      disabled={data.isActionDisabled}
                      defaultChecked={data.isActionDisabled}
                      onChange={(e) => {
                        onToggleBtnHandler(e, data.adset_id, data.search_term);
                      }}
                    ></SwitchBtn>
                  </td>
                  <td>{data?.search_term}</td>
                  {dataHeads.map((currentDataHead) => {
                    if (!currentDataHead?.isDefaultHead) {
                      return (
                        <td
                          className={
                            currentDataHead.isHeadRightAlign
                              ? "st-end-align"
                              : "st-text-left-align "
                          }
                        >
                          {data[currentDataHead?.id] ||
                          data[currentDataHead?.id] == 0
                            ? currentDataHead?.id === "spends" ||
                              currentDataHead?.id === "sale_value"
                              ? `${currencySymbol}${Math.trunc(data[currentDataHead?.id])}`
                              : roundFigureNum(data[currentDataHead?.id])
                            : "-"}
                        </td>
                      );
                    }
                  })}
                </tr>
                    </>
                  )
                }
               
              </tbody>
            );
          })}
          {/* </tbody> */}
        </table>
      ) : (
        <p>NO DATA FOUND</p>
      )}
    </div>
  );

  // Search Functionality
  const onSearch = (text, dataToBeFiltered) => {
    const filteredData = dataToBeFiltered.filter((data) => {
      if (text === "") {
        return data;
      } else {
        return data?.search_term.toLowerCase().includes(text.toLowerCase());
      }
    });
    return filteredData;
  };
  return (
    <div className="seadch-term-card-container">
      <Loader visible={isLoaderVisible}></Loader>
      <div>
        {
          <FilterLogicModal
            filterFor="edit-search-term"
            show={isFilterVisible}
          ></FilterLogicModal>
        }
      </div>
      {/* <div>
        {
          <FilterLogicModal
            filterFor="edit-search-term"
            show={isFilterVisible}
          ></FilterLogicModal>
        }
      </div> */}
      <div className="search-term-card-header">
        <div className="d-flex flex-row align-items-center justify-content-start flex-grow-1">
          <SectionTittle sectionTitleHeading="Search Terms"></SectionTittle>
          <div
            className="filter-icon-container-main"
            onClick={onFilterClickHandler}
          >
             <div className="filter-icon-container-main">
            <div
              className={
                minMaxFilterActivated ? "activated-min-max-filter" : "hide"
              }
            ></div>
            <img
              src={settingOptionIcon}
              className="cursor-pointer-global"
              alt="SettingsIcon"
            ></img>
          </div>
          </div>
          
        </div>
        {/* <div className=" search-input-container ">
                    <div >
                        <SearchbarForEditCampaign placeholder="Search for Products or ASIN’s"></SearchbarForEditCampaign>
                    </div>
                </div> */}
        <div className="serch-and-button-container">
          <div>
            <SearchBarForCampaignListOnly
              onChange={(e) => setText(e.target.value)}
              value={text}
              placeholder="Search for Terms"
              onClickCancel = {cancelIconInSearchInput}
              id="search-trm-search-input"
              dynamicWidth="search-input-width-for-edit-campaignlist-section"
            ></SearchBarForCampaignListOnly>
          </div>
        </div>
      </div>
      <div className="search-term-table">
        <TableRb table={editSerachTermtableBody}></TableRb>
      </div>
    </div>
  );
};

export default SerachTerms;
