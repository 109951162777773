import React, { useEffect, useRef } from "react";
import { useState , useMemo } from "react";
import { Modal } from "react-bootstrap";
import "./populateProductAds.css";
import SecondaryBtnRb from "../../../Buttons/secondaryBtnRb/secondaryBtnRb";
import MultiSelect from "../../../DropDown/Multiselect";
import { IoMdClose } from "react-icons/io";


const PopulateProductAds = ({ show, onNoClick, onYesClick,defaultSelectedCampaigns , confirmationId,optionsForCampaigns,optionsForAdgroups,optionsForPortfolios,showMatchType,defaultSelectedPortfolios }) => {
  const renderRef = useRef(null);
  const [activeTab, setActiveTab] = useState("");
  const [keywordActiveTab,setKeywordActiveTab] = useState("Exact");
  const [options, setOptions] = useState([...optionsForCampaigns]);
  const [selectedCampaignsForAdgroups,setSelectedCampaignsForAdgroups] = useState([...defaultSelectedCampaigns])
  const [adgroupOptions,setAdgroupOptions] = useState([...optionsForAdgroups]);
  const [portfolioOptions,setPortfolioOptions] = useState([...optionsForPortfolios])
  const [selectedPortfolios,setSelectedPortfolios] = useState([...defaultSelectedPortfolios])
  const [selectedCategory, setSelectedCategory] = useState([...defaultSelectedCampaigns]);
  const [selectedAdgroups,setSelectedAdgroups] = useState([]);
  const dataForDropDown = options?.map((data) => data?.name);
  const dataForDropDownPortfolios = portfolioOptions?.map((data) => data?.name);
  const filteredPortfolios =  portfolioOptions?.filter((data) => {
    return selectedPortfolios?.includes(data?.name);
  })

  const dataForCampaigns = useMemo(() => {
   return getDataThroughReference(filteredPortfolios,optionsForCampaigns,"platform_id","amz_portfolio_id")
  },[selectedPortfolios])



  const mergedData = useMemo(() => {
    return mergeCampaignsAndAdgroups(options,adgroupOptions)
  },[options,adgroupOptions]) 


  const mergedDataOptionsForDropDown =  useMemo(() => {
    return getMergedDataOptionsForDropDown(selectedCategory,mergedData)
  },[mergedData,selectedPortfolios,selectedCategory,dataForCampaigns]) // Get Filtered Adgroups based on selected Campaigns

  

  function getDataThroughReference (dataSource1=[],dataSource2=[],keyName1,keyName2) {
    return dataSource2?.reduce((acc,curr) => {
      if(dataSource1.length)  {
        const foundedData = dataSource1?.find((data) => data[keyName1] === curr[keyName2])
        if(foundedData) {
          acc.push(curr)
        }
      } else {
        acc.push(curr)
      }
      return acc
    },[])
  }

  // Effects

  function mergeCampaignsAndAdgroups(campaignData,adgroupData) {
    const mergedData = adgroupData?.reduce((acc,curr) => {
      const foundedCampaign = campaignData?.find((data) => data?.id === curr?.campaign_id)
      if(foundedCampaign) {
        acc.push({name:`${curr?.name} (Camp. Name:- ${foundedCampaign?.name})`,id:curr?.id,campaign_name:foundedCampaign?.name,campaign_id:foundedCampaign?.id,type:foundedCampaign?.objective})
      }
      return acc
    },[])
    return mergedData
  }

  function getMergedDataOptionsForDropDown(selectedCampaigns,overAllMergedData) {
   return overAllMergedData?.reduce((acc,curr) => {
      if(selectedCampaigns?.length) {
        const foundedAdgroup = selectedCampaigns?.find((campaign) => campaign === curr?.campaign_name)
        if(foundedAdgroup) {
          acc.push(curr.name)
        }
      } else if(selectedPortfolios?.length === 0) {
        acc.push(curr.name)
      }
      return acc
    },[]) 
  }

  const renderItem = (item) => {
    const [itemName, campaignName] = item.split(' (Camp. Name:- ');
    const campaignNameModified = `(Camp. Name:- ${campaignName}`;
    const isSlicingRequired = campaignNameModified?.length > 60
    const slicedString = isSlicingRequired ? campaignNameModified?.slice(0,60) + "..." : campaignNameModified
    const itemNameModified = itemName?.length > 70 ? itemName?.slice(0,70) + "..." : itemName
    return <> <span title={itemName} className="m-0">{itemNameModified}</span>  <br className="m-0"/><p className="m-0" title={campaignNameModified}>{slicedString}</p> </>
  }

  const renderCampaignLevelItem = (item) => {
    const isSlicingRequired = item?.length > 70
    const slicedString = isSlicingRequired ? item?.slice(0,70) + "..." : item
    return <><p className="m-0" title={item}>{slicedString}</p> </>
  }



    useEffect(() => {
      if(renderRef.current && selectedPortfolios?.length) {
        const campaignNames = dataForCampaigns?.map((data) => data?.name)
        const filteredCampaigns = [...new Set(campaignNames)]
        setSelectedCategory(filteredCampaigns)
      }
      renderRef.current = true;
    },[selectedPortfolios])


    

    useEffect(() => {
      // If the selected campaigns has length then only get the adgroups
      if(renderRef.current && selectedCategory?.length) {
        const adgroups = getMergedDataOptionsForDropDown(selectedCategory,mergedData)
        const dataToSet = selectedCategory?.length === 0 ? [] : adgroups
        const filteredAdgroups = [...new Set(dataToSet)]
        setSelectedAdgroups(filteredAdgroups);
      }
      // If the selected campaigns is empty then set the adgroups to empty
      if(selectedCategory?.length === 0 && renderRef.current) {
        setSelectedAdgroups([])
      }
      renderRef.current = true;
    },[selectedCategory])


  // Methods
  const cancelHandler = () => {
    onNoClick();
  };

  const onupdateClickHandler = () => {
    const selectedAdsets = selectedAdgroups?.reduce((acc,curr) => {
      const foundedId = mergedData?.find((data) => data?.name === curr)
      if(foundedId) {
        acc.push(foundedId)
      }
      return acc
  },[])
    const details = {
      data:selectedAdsets
    }
    onYesClick(details)
  };

  const changeTab = (recievedTab,type) => {
    if(type==="Campaign") {
      setActiveTab(recievedTab);
    } else {
      setKeywordActiveTab(recievedTab)
    }
  };

  const handleApply = (e,type) => {
    if(type === "Campaign") {
      if(e.length===0) {
        setSelectedAdgroups([]);
      }
      setSelectedCategory(e);
    }

    if(type === "adgroup") {
      setSelectedAdgroups(e)
    }

    if(type === "Portfolio") {
      if(e.length === 0) {
        setSelectedAdgroups([]);
        setSelectedCategory([]);
      }
      setSelectedPortfolios(e)
    }
  };


  const searchLogic = (option,value) => {
    const [itemName, campaignName] = option.split(' (Camp. Name:- ');
    return itemName?.toLowerCase()?.includes(value);
  }



  const modalBody = (
    <>
      <div className="bid-config-modal-container">
        <div className="negative-targeting-modal-header px-4 py-3 align-items-center">
          <h5 className="m-0 fw-bold negative-targeting-modal-heading">Populate Product Ads</h5>
          <IoMdClose onClick={cancelHandler} size={20} className="cursor-pointer" />
        </div>
        <hr className="m-0" />
          <>
            {
              <>
                <div className="px-4 ">
                  <div className="px-1 d-flex flex-column gap-3">
                  <MultiSelect
                      title={"Choose Portfolios (Optional)"}
                      defaultSelected={selectedPortfolios}
                      options={dataForDropDownPortfolios}
                      renderItem={renderCampaignLevelItem}
                      onApply={(e) => handleApply(e, "Portfolio")}
                      isSearch={true}
                      showLimitedOptions={true}
                      limitedOptionsLimit={100}
                      placeHolder={"Search For Portfolios"}
                      classes={
                        "position-static mst-negative-modal-drop-down-height"
                      }
                    />
                    <MultiSelect
                      title={<span>Choose Campaigns (Required) <span className="text-danger">*</span></span>}
                      defaultSelected={selectedCategory}
                      options={dataForCampaigns?.map((data) => {
                        return data?.name
                      })}
                      showLimitedOptions={true}
                      limitedOptionsLimit={100}
                      renderItem={renderCampaignLevelItem}
                      onApply={(e) => handleApply(e, "Campaign")}
                      isSearch={true}
                      placeHolder={"Search For Campaigns"}
                      classes={
                        "position-static mst-negative-modal-drop-down-height"
                      }
                    />
                    <MultiSelect
                      title={<span>Choose Adgroup (Required) <span className="text-danger">*</span></span>}
                      defaultSelected={selectedAdgroups}
                      renderItem={renderItem}
                      showLimitedOptions={true}
                      limitedOptionsLimit={100}
                      searchLogic={searchLogic}
                      customSearch={true}
                      options={mergedDataOptionsForDropDown}
                      onApply={(e) => handleApply(e, "adgroup")}
                      isSearch={true}
                      placeHolder={"Search For Adgroup"}
                      classes={
                        "position-static mst-negative-modal-drop-down-height"
                      }
                    />
                  </div>
                </div>
              </>
            }
          </>
      </div>
    </>
  );

  const isSaveBtnDisabled = selectedAdgroups?.length===0 || selectedCategory?.length === 0

  return (
    <div className="filetr-modal-for-targeting-main-container">
      <Modal
        show={show}
        size={"lg"}
        scrollable="true"
        className="product-ads-populate-modal"
        backdropClassName="edit-bid-for-targetinng-backdrop"
        contentClassName="edit-bid-for-targetinng-conntennt"
        dialogClassName="negative-targeting-modal-dialog"
        
      >
        <Modal.Body>{modalBody}</Modal.Body>
        <Modal.Footer className="add-negative-targeting-modal-footer">  <div className="targeting-btn-container-for-bid mt-2 justify-content-end">
          <SecondaryBtnRb
            secondaryBtnText="Cancel"
            onClick={cancelHandler}
            className="primary-inactive-btn px-5 mx-3"
          ></SecondaryBtnRb>
          <SecondaryBtnRb
            secondaryBtnText="Save"
            id={confirmationId ?? "save"}
            disabled={isSaveBtnDisabled}
            onClick={onupdateClickHandler}
            className={`primary-active-btn px-5 ${isSaveBtnDisabled?'disable-pointer-events':''}`}
          ></SecondaryBtnRb>
        </div></Modal.Footer>
      </Modal>
    </div>
  );
};

export default PopulateProductAds;