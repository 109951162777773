
/**
 * This hook includes commonly used features on a table list checkbox select , select All
 * @params Pass the table params as state and your state updater 
 * @Note This is assumed that list will be an array of objects.
 */

export default function useTableListFeatures (stateUpdater) {

    // Hook Toggle Single Checkbox
    const singleCheckBoxHandler = (recievedIndex) => {
        stateUpdater((prev) => {
            return [...prev]?.map((listElement,index) => 
               recievedIndex === index ? {...listElement,isChecked:!listElement.isChecked}:listElement
            )
        })
    }

    // Hook To Toggle All Checkboxes at once
    const allCheckBoxHandler = (checked) => {
        stateUpdater((prev) => {
            return [...prev]?.map((listElement) => {
                return {...listElement,isChecked:checked}
            })
        })
    }

    // Hook To Change Values in Bulk Of Some List Items which are present in values array

    const changeListStatusInBulk = (keyToChange,value,valuesArray,keyForSearch) => {
        // Array of values to match against
        stateUpdater((prev) => {
            return [...prev]?.map((listElement) => {
                if(valuesArray?.includes(listElement[keyForSearch])) {
                    listElement[keyToChange] = value
                }
                return {...listElement}
            })
        })
    }
    
    return {
        singleCheckBoxHandler,
        allCheckBoxHandler,
        changeListStatusInBulk
    }
}