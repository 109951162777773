import React from 'react'

const InfoChip = ({text}) => {
  return (
    <span className='coming-txt'>
        {text}
    </span>
  )
}

export default InfoChip
