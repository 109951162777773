import fastforWardImage from '../../assets/svgs/fastForward.svg';
import fastBackwardImage from '../../assets/svgs/fastbackward.svg';
import pauseImage from '../../assets/svgs/pause.svg'

export const initialRulesConfigState = {
    increaseCase:{
        rules:[
            {
                isDisabled:false,
                filters:[],
                rule_duration:"3 Days",
                increase_bid:10,
                isMatchTypeSelected:false,
                match_type_values:{
                    "exact":"",
                    "broad":"",
                    "phrase":""
                }
            }
        ],
        maxRules:2,
        caseHeading:"Increase Case",
    },
    decreaseCase:{
        rules:[],
        maxRules:2,
        caseHeading:"Decrease Case",
        caseImage:fastBackwardImage
    },
    pauseCase:{
        rules:[],
        maxRules:1,
        caseHeading:"Pause Case",
        caseImage:pauseImage
    }
}

export const staticRulesConfig = {
    increaseCase:{
        caseImage:fastforWardImage,
        caseHeading:"Increase Case",
        caseBgColor:"#E2F1EA"
    },
    decreaseCase:{
        caseImage:fastBackwardImage,
        caseHeading:"Decrease Case",
        caseBgColor:"#F7C0C0"
    },
    pauseCase:{
        caseImage:pauseImage,
        caseHeading:"Pause Case",
        caseBgColor:"#DFE3E8"
    }
}

export const staticFiltersConfigs = {
    filterFields:{
        field_name:"-",
        operator:"-",
        value:""
    }
}

export const rulesActions = {
    ADD_RULE:"Add_Rule",
    DELETE_RULE:"Delete_Rule",
    TOGGLE_RULE:"toggle_rule",
    ADD_FILTER:"add_filter",
    REMOVE_FILTER:"remove_filter",
    UPDATE_FILTER_VALUE:"update_filter_value",
    UPDATE_RULE_VALUE:"update_rule_value",
    TOGGLE_MATCH_TYPE:"toggle_match_type",
    UPDATE_MATCH_TYPE_VALUE:"update_match_type_value",
    RESET_RULE:"reset_rule"
}

export const DEFAULT_OPERATOR_CONFIGS = {
    "LESS_THAN_EQUALS_TO":"<=",
    "GREATER_THAN":">",
    "LESS_THAN":"<",
    "EQUALS":"=",
    "GREATER_THAN_EQUALS_TO":">="
}