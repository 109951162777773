/**
 * @Note This function is used to fetch paginated data from the server. (get data in chunks)
 * @param {*} apiFunction 
 * @param {*} baseData 
 * @param {*} pageSize 
 * @param {*} maxPages 
 * @param {*} endpoint 
 * @param {*} shouldAuth 
 * @returns all data
 */

export async function fetchPaginatedData(apiFunction, baseData, pageSize, maxPages, endpoint, shouldAuth = false) {
    const allData = [];
    let currentPage = 0;
  
    while (currentPage <= maxPages) {
      const batchRequests = [];
      for (let i = 0; i <= maxPages && currentPage <= maxPages; i++, currentPage++) {
        const paginatedData = { ...baseData, limit: pageSize, page: currentPage };
        batchRequests.push(apiFunction(paginatedData, endpoint, shouldAuth));
      }
  
      const batchResults = await Promise.all(batchRequests);
      batchResults.forEach(result => {
        allData.push(...(result?.data?.data ?? []));
      });
    }
    return allData;
  }