
import styles from "./fallback.module.css";
import wrong from '../../assets/svgs/fallback.svg'
import tryAgain from '../../assets/svgs/tryAgain.svg'
import homeIcon from '../../assets/svgs/home-icon.svg'


import Button from 'react-bootstrap/Button';


const FallbackMain = () => {
    return (
            <div className={styles.contentWrapper}>
                <div className={styles.imageWrapper}>
                    <img alt="Something went wrong" src={wrong} className={styles.wrongImage}/>
                </div>
                <div className={styles.informationWrapper}>
                    <h1 className={styles.errorHeading}>Apologies, an unexpected error occurred.</h1>
                    <h4 className={styles.errorDescription}> Please refresh the page and try again.</h4>
                    {/* <p className={styles.errorDescription}>
                        <h6>
                            Please refresh the page and try again.
                        </h6> 
                        <h6 className={styles.textCenter}>
                            If you need immediate help.
                            <a href="#" className={styles.errorFollowupLink}>
                                please let us know.
                            </a>
                        </h6> 
                    </p> */}
                    <div className={styles.buttonWrapper}>
                        <Button onClick={() => window.location.reload(true)} variant="light"><img style={{height:"12px"}} alt="tryagain" src={tryAgain} /> &nbsp; Refresh</Button>
                        <Button onClick={() => window.location.href = '/' } variant="light"><img style={{height:"12px"}} alt="home" src={homeIcon} /> &nbsp; Go To Home</Button>
                    </div>
                </div>
            </div>
    );
};

export default FallbackMain;
