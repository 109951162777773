import FilterLogicModal from "../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRef } from "react";
import CalenderComponent from "../../components/calender/Calender";
import closeIcon from "../../assets/icons/close_icon.png";
import { useGlobalContext } from "../../context/global";
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.svg";
import Chart from "../../components/Reports/Chart";
import cubejs from "@cubejs-client/core";
import useToken from "../../helper/Reports/useToken";
import ReportFilters from "../../components/Reports/Filters";
import SectionTitle from "../../components/sectionTitle";
import previousDays from "../../helper/Reports/common";
import Loader from "../../components/loader";
import useInitialLoad from "../../hooks/useInitialLoad";
import Breadcrumb from "../../components/Breadcrumb/ReportsBreadCrumb";
import Canvas from "../../helper/Screenshot/canvas";
import downloadIcon from "../../assets/svgs/download.svg";
import {useSearchParams } from "react-router-dom";
import {formatPieChartData,createQueryURL,dataQueryParser,objectoFiltering,getSearchParamsObject} from "../../components/Reports/Components/utils";


const DetailedCategoryReport = (props) => {
  const [token, refresh] = useToken("");
  const [initialLoad, setInitialLoad] = useInitialLoad();
  const { cubeToken, setCubeToken } = useGlobalContext();
  const { clientAccountId } = useGlobalContext();
  const cubejsApi = cubejs(cubeToken, {
    apiUrl: process.env.REACT_APP_CUBE_JS_URL,
  });
  const [tableView, setTableView] = useState("table");
  const [showFilters, setShowFilters] = useState(true)
  const allParams = new URLSearchParams(window.location.search);
  const paramsObject = getSearchParamsObject(allParams);
  const previousFiltering = objectoFiltering(paramsObject,"VariantAnalysisTable")
  const viewType = allParams.get('viewtype') ?? 'Performance View';
  const [currentViewType, setCurrentViewType] = useState(viewType);
  const isComparisonView = currentViewType === 'Comparison View';
  const formatDate = (date) => {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split("T")[0].toString();
  };

  const {
    calendarDate,
    setCalendarDate,
    dateDifference,
    setDateDifference,
    applyCalendar,
    multiCalendarDate,
  } = useGlobalContext();
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );

  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [campaignType, setCampaignType] = useState([]);
  const [campaignFormat, setCampaignFormat] = useState([]);
  const [targetingType, setTargetingType] = useState([]);
  const [productSet, setProductSet] = useState([]);
  const [adStatus, setAdStatus] = useState([]);
  const [drrView, setDrrView] = useState("")
  const [searchParams,setSearchParams] = useSearchParams({});
  const [portfolioName, setPortfolioName] = useState([]);
  const enabledfilters = [
    "Campaign Type",
    "Campaign Format",
    "Targeting Type",
    "Product Set",
    "Ad Status",
    "Portfolio Name",
  ];
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [cubeQueryMeasure, setCubeQueryMeasure] = useState([
    "VariantAnalysisTable.cost",
    "VariantAnalysisTable.costDRR",
    "VariantAnalysisTable.impressions",
    "VariantAnalysisTable.impressionsDRR",
    "VariantAnalysisTable.clicks",
    "VariantAnalysisTable.clicksDRR",
    "VariantAnalysisTable.ctr",
    "VariantAnalysisTable.ctrDRR",
    "VariantAnalysisTable.cpc",
    "VariantAnalysisTable.cpcDRR",
    "VariantAnalysisTable.cpm",
    "VariantAnalysisTable.cpmDRR",
    "VariantAnalysisTable.conversions14d",
    "VariantAnalysisTable.conversions14dDRR",
    "VariantAnalysisTable.conversion_rate",
    "VariantAnalysisTable.conversion_rateDRR",
    "VariantAnalysisTable.sales14d",
    "VariantAnalysisTable.sales14dDRR",
    "VariantAnalysisTable.average_order_value",
    "VariantAnalysisTable.average_order_valueDRR",
    "VariantAnalysisTable.acos",
    "VariantAnalysisTable.acosDRR",
    "VariantAnalysisTable.roas",
    "VariantAnalysisTable.roasDRR",
  ]);

  const [cubeQueryOrder, setCubeQueryOrder] = useState({
    "VariantAnalysisTable.cost": "desc",
  });

  const [cubeQueryDimensions, setCubeQueryDimensions] = useState([
    "VariantAnalysisTable.category",
  ]);
  let startDate = formatDate(multiCalendarDate.selection1.startDate);
  let endDate = formatDate(multiCalendarDate.selection1.endDate);
  let startDatePrevious = formatDate(
        multiCalendarDate.selection2.startDate
      );
  let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
  const cubeQueryTimeDimensionsFormatted = !isComparisonView ? [{dimension:"VariantAnalysisTable.obDate",dateRange: [
    formatDate(calendarDate[0].startDate),
    formatDate(calendarDate[0].endDate),
  ]}] : [{dimension:"VariantAnalysisTable.obDate",compareDateRange:[
    [startDate, endDate],
    [startDatePrevious, endDatePrevious],
  ]}]
  const [cubeQueryTimeDimensions, setCubeQueryTimeDimensions] = useState(cubeQueryTimeDimensionsFormatted);
  const [cubeQueryFilters, setCubeQueryFilters] = useState([
    {
      member: "VariantAnalysisTable.profileId",
      operator: "equals",
      values: [clientAccountId],
    },
    ...previousFiltering
  ]);
  const [chartFilters, setChartFilters] = useState({
    spend:"cost",
    revenue:"sales14d"
  })

  const [resultSet, setResultSet] = useState({});
  var spendResultSet = formatPieChartData(resultSet, chartFilters.spend, "VariantAnalysisTable", "category");
  var revenueResultSet = formatPieChartData(resultSet, chartFilters.revenue, "VariantAnalysisTable", "category");
  const calenderIconRefForCampaignlist = useRef(null);

  const closeCalender = (e) => {
    if (
      calenderIconRefForCampaignlist.current &&
      showCustomDateBox &&
      !calenderIconRefForCampaignlist.current.contains(e.target)
    ) {
      setShowCustomDateBox(false);
    }
  };


  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("start") && queryParams.get("end")) {
      setDate(queryParams.get("start"), queryParams.get("end"));
    }
  }, []);

  const setDate = (currentDate, previousDate) => {
    const dates = {
      startDate: new Date(currentDate),
      endDate: new Date(previousDate),
      key: "selection",
    };
    setDateDifference("Custom");
    setCalendarDate([dates]);
    setCubeQueryTimeDimensions((prev) => {
      let data = [...prev];
      data[0]["dateRange"] = [
        formatDate(dates.startDate),
        formatDate(dates.endDate),
      ];
      return data;
    });
  };

  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeCalender);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeCalender);
    };
  }, [showCustomDateBox]);

  useEffect(() => {
    if (currentViewType !== "Comparison View") {
      const startDate = formatDate(calendarDate[0].startDate);
      const endDate = formatDate(calendarDate[0].endDate);
      if (
        startDate !== cubeQueryTimeDimensions[0]["dateRange"][0] ||
        endDate !== cubeQueryTimeDimensions[0]["dateRange"][1]
      ) {
        setIsLoaderVisible(true);
        setCubeQueryTimeDimensions((prev) => {
          let data = [...prev];
          data[0]["dateRange"] = [startDate, endDate];
          return data;
        });
      }
    }
  }, [applyCalendar]);

  useEffect(() => {
    if (currentViewType === "Comparison View") {
      let startDate = formatDate(multiCalendarDate.selection1.startDate);
      let endDate = formatDate(multiCalendarDate.selection1.endDate);
      let startDatePrevious = formatDate(
        multiCalendarDate.selection2.startDate
      );
      let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
      let apiDateRange = cubeQueryTimeDimensions[0]["compareDateRange"];
      // if (
      //   startDate !== apiDateRange[0][0] ||
      //   endDate !== apiDateRange[0][1] ||
      //   startDatePrevious !== apiDateRange[1][0] ||
      //   endDatePrevious !== apiDateRange[1][1]
      // ) {
        setIsLoaderVisible(true);
        setCubeQueryTimeDimensions((prev) => {
          let data = [...prev];
          data[0]["compareDateRange"] = [
            [startDate, endDate],
            [startDatePrevious, endDatePrevious],
          ];
          return data;
        });
      // }
    }
  }, [applyCalendar]);

  useEffect(() => {
    //table data
    if (cubeToken) {
      cubejsApi.load({
            measures: cubeQueryMeasure,
            order: cubeQueryOrder,
            dimensions: cubeQueryDimensions,
            filters: cubeQueryFilters,
            timeDimensions: cubeQueryTimeDimensions,
            limit: 5000,
          },{
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((resultSet) => {
          setResultSet(resultSet);
          if (currentViewType === "Comparison View") {
            setTableView("ComparisonTable");
          } else {
            setTableView("table");
          }
          setIsLoaderVisible(false);
          setInitialLoad(false);
        })
        .catch((error) => {
          if (error.message === "Invalid token") {
            setCubeToken(false);
            localStorage.setItem("cubeToken", null);
            refresh();
          }
        });
    }
  }, [cubeQueryFilters, cubeQueryTimeDimensions, cubeToken]);

  const filterCampaigns = (e, type , isArray) => {
    setIsLoaderVisible(true);
      const parsedQueryFilters = dataQueryParser(JSON.parse(JSON.stringify(cubeQueryFilters)),e,type,"VariantAnalysisTable");
      const parsedParams = createQueryURL(parsedQueryFilters);
      const previousParams = new URLSearchParams(window.location.search)
      if(previousParams.get('metricViewType')) {
        parsedParams['metricViewType'] = previousParams.get('metricViewType')
      }
      parsedParams["viewtype"] = currentViewType
      setCubeQueryFilters((prev) => {
        const parsedData = dataQueryParser(prev,e,type,"VariantAnalysisTable")
        return parsedData;
      });
      if (type === "campaignType") {
        setCampaignType(e);
      } else if (type === "campaignFormat") {
        setCampaignFormat(e);
      } else if (type === "targetingType") {
        setTargetingType(e);
      } else if (type === "productSet") {
        setProductSet(e);
      } else if (type === "adStatus") {
        setAdStatus(e);
      } else if (type === "portfolioName") {
        setPortfolioName(e);
      }
      setSearchParams(parsedParams)
    
   
  };

  useEffect(() => {
    setIsFilterVisible(filterModalInCampaignListVisibilty);
  }, [filterModalInCampaignListVisibilty]);

  const redirectChange = (category, type, index) => {
    let url = "/product-analytics";
    const queryParams = [
      ["category", category],
      ["start", formatDate(calendarDate[0].startDate)],
      ["end", formatDate(calendarDate[0].endDate)],
      ["campaignType", campaignType],
      ["campaignFormat", campaignFormat],
      ["targetingType", targetingType],
      ["productSet", productSet],
      ["adStatus", adStatus],
      ["portfolioName", portfolioName],
    ];
    const queryString = queryParams
      .filter(([key, value]) => value !== "All")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    url += `?${queryString}`;
    // Open URL in new tab
    window.open(url);
  };

  const handleComparisonView = (type) => {
    if (type === "Comparison View") {
      setCubeQueryTimeDimensions([
        {
          dimension: "VariantAnalysisTable.obDate",
          compareDateRange: [
            [
              startDate,
              endDate,
            ],
            [
              startDatePrevious,
              endDatePrevious,
            ],
          ],
        },
      ]);
      setIsLoaderVisible(true);
    } else if (
      type !== "Comparison View" &&
      currentViewType === "Comparison View"
    ) {
      setCubeQueryTimeDimensions([
        {
          dimension: "VariantAnalysisTable.obDate",
          dateRange: [
            formatDate(calendarDate[0].startDate),
            formatDate(calendarDate[0].endDate),
          ],
        },
      ]);
      setIsLoaderVisible(true);
    }
    setCurrentViewType(type);
  };


  function dataFilter(data) {
    setIsLoaderVisible(true);
    const table = "VariantAnalysisTable.";
    let tempFilters = [...cubeQueryFilters];
    Object.entries(data).forEach(([key, value]) => {
      tempFilters.forEach((x, i) => {
        if (x.member === `${table}${key}`) {
          tempFilters.splice(i, 1);
        }
      });
      if (value.min) {
        tempFilters.push({
          member: `${table}${key}`,
          operator: "gte",
          values: [parseFloat(value.min)],
        });
      }
      if (value.max) {
        tempFilters.push({
          member: `${table}${key}`,
          operator: "lte",
          values: [parseFloat(value.max)],
        });
      }
    });
    setCubeQueryFilters(tempFilters);
  }

  const handleDownload = () => {
    Canvas({ name: "detail-category-report" });
  };

  function handleSearch(data) {
    let prevData = [...cubeQueryFilters];
    prevData.forEach((x, i) => {
      if (x.member === cubeQueryDimensions[0]) {
        prevData.splice(i, 1);
      }
    });
    if (data.input) {
      prevData.push({
        member: cubeQueryDimensions[0],
        operator: data.select,
        values: [data.input],
      });
    }
    setShowFilters(data)
    setCubeQueryFilters(prevData);
  }

  const handleDrrView = (value, type) => {
    setDrrView(value)
    if(value !== 'drr') {
        setChartFilters({spend:"cost",revenue:"sales14d"})
    } else {
        setChartFilters({spend:"costDRR",revenue:"sales14dDRR"})
    }
  };

  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
      <Loader visible={initialLoad}></Loader>
      <div
        style={{
          marginLeft: "auto",
        }}
        // className="d-flex justify-content-end align-item-end"
      >
        <button
          className="date-container date-btn-campaignlist d-none"
          onClick={dateInputClickHandler}
        >
          <div className="d-flex justify-conntent-center">
            <span className="date-btn-text">{dateDifference}</span>
          </div>
          <button className="btn-calender-img-holder">
            <span className="">
              <img
                className="dropdown-img-for-select"
                src={selectSideIcon}
                alt={"SelectSideIcon"}
              ></img>

              <img
                className="calender-icon-for-campaignlist"
                src={CalenderIconForCampaignList}
                alt={"CalendarIcon"}
              ></img>
            </span>
          </button>
        </button>
      </div>
      <div
        ref={calenderIconRefForCampaignlist}
        className={
          showCustomDateBox ? "calender-inclasslist-container" : "hide"
        }
      >
        <CalenderComponent
          setShowCustomDateBox={setShowCustomDateBox}
        ></CalenderComponent>
        <span className="calender-close-icon-campaignlist">
          <img
            onClick={() => setShowCustomDateBox(false)}
            src={closeIcon}
            alt={"CloseIcon"}
          ></img>
        </span>
      </div>
      <Breadcrumb />
      <SectionTitle sectionTitleHeading="Detailed Category Report"></SectionTitle>
      <div
        className="d-flex justify-content-between mt-4 mb-4 gap-4 position-relative"
        id="downloadChart"
      >
        <div
          onClick={handleDownload}
          className="downloadChartWrapper pie"
          id="downloadChartWrapper"
        >
          <img
            src={downloadIcon}
            className="cursor-pointer-global DownloadIcon"
            alt="overview-settings-tile"
          />
        </div>
        <div className="w-100 min-h-300 pie-container">
          <h4 className="sub-heading-pie f-20 p-4">Spends</h4>
          <Chart
            type="apexpie"
            resultSet={spendResultSet}
            classes={"marketing-reports"}
            display={"spends"}
          />
        </div>
        <div className="w-100 min-h-300 pie-container">
          <h4 className="sub-heading-pie f-20 p-4">Revenue</h4>
          <Chart
            type="apexpie"
            resultSet={revenueResultSet}
            classes={"marketing-reports"}
            display={"spends"}
          />
        </div>
      </div>

      <ReportFilters
        handleDropdown={filterCampaigns}
        enabledfilters={enabledfilters}
      />

      <div>
        {
          <FilterLogicModal
            filterFor="campaignlist"
            show={isFilterVisible}
          ></FilterLogicModal>
        }
      </div>

      <div className="">
        <Chart
          type={tableView}
          resultSet={resultSet}
          classes={"marketing-reports"}
          handleChange={redirectChange}
          FunnelViews={[
            "Performance View",
            "Funnel View",
            "Comparison View",
            "Custom View",
          ]}
          handleComparisonView={handleComparisonView}
          CurrentViewType={currentViewType}
          page="category"
          dataFilter={dataFilter}
          shimmerEffect={isLoaderVisible}
          handleSearch={handleSearch}
          showFilter={showFilters}
          drrView={handleDrrView} drrViewType={drrView}
        />
      </div>
    </div>
  );
};

export default DetailedCategoryReport;
