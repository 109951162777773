import React from 'react';
import {Nav} from 'react-bootstrap';
import CampaignImage1 from '../../../assets/images/Campaign1.png';
import CampaignImage2 from '../../../assets/images/Campaign2.png';
import CampaignImage3 from '../../../assets/images/Campaign3.png';
import CampaignImage4 from '../../../assets/images/Campaign4.png';
import RightTick from '../../../assets/images/RightTick.png';
import { useGlobalContext } from '../../../context/global';



const CampaignType = (props) => {
    // State
    // Refs
    // Methods

    const {campaignData,
      setCampaignData,
      adGroupData,
      setAdGroupData,
      keywordsTobeAdded,
      sdSelectedSuggestedCategories,
      spSelectedSuggestedCategories,
      setSelectedCategory
      
    } = useGlobalContext();

    const handleCampaignData = (type,targetingType,objective) => {
      if(type === "SD-Product" || type === "SD-Audience") {
        setCampaignData((current) => {
          const copy = {...current}
            delete copy["bidding"]
            copy["type"] = type
            copy["amz_targeting_type"] = targetingType
            copy["objective"] = objective
            copy["name"] = ""
            copy["account_id"] = `${JSON.parse(localStorage.getItem('accountId'))}`
            return copy;
        })
        setAdGroupData({...adGroupData,"name":""})
      } else {
        setCampaignData({...campaignData,"type":type,"amz_targeting_type":targetingType, bidding: {
          bidding_strategy: "",
          bidding_value: 100,
        },"objective":objective,"name":"","account_id":`${JSON.parse(localStorage.getItem('accountId'))}`})
        setAdGroupData({...adGroupData,"name":""})
      }
      setSelectedCategory("");
    }

    const setActiveBox = (e) => {
        let closest = e.target.closest(".choose-box-wrap");
        let allBoxes = document.querySelectorAll('.choose-box-wrap');
        allBoxes.forEach((box) => {
          box.classList.remove("box-active");
        })
        closest.classList.add("box-active")
    }



  return (
    <Nav variant="pills" className="flex-row-cart-page">
                  <Nav.Item>
                    <Nav.Link eventKey="first" onClick={() => {
                      props.setCurrentPage("first");
                        if(props.campaignType === "prospect") {
                          handleCampaignData("SP-Auto","Automatic","SP");
                          setAdGroupData((current) => {
                            const copy = {...current};
                            if ("amz_product_targeting" in copy) {
                              delete copy["amz_product_targeting"]
                            }
                            if("amz_keyword_targeting" in copy) {
                             delete copy["amz_keyword_targeting"]
                            }
                            copy["name"] = ""
                            // If type is Automatic then change add amz_manual_bid
                            copy["amz_manual_bid"] = {
                              amz_close_match_bid_adyogi:"",
                              amz_loose_match_bid_adyogi:"",
                              amz_substitutes_bid_adyogi:"",
                              amz_complements_bid_adyogi:""
                            }
                            delete copy["amz_product_audience_targeting"]
                            return copy;
                          })
                          props.getCurrentType('spautomatic')
                        }
                        if(props.campaignType === "retargeting") {
                          handleCampaignData("SP-Keyword","Keyword","SP");
                          // setAdGroupData({...adGroupData,"amz_keyword_targeting":[]})
                          setAdGroupData((current) => {
                            const copy = {...current};
                            if ("amz_product_targeting" in copy) {
                              delete copy["amz_product_targeting"]
                            }
                            if("amz_keyword_targeting" in copy) {
                              copy["amz_keyword_targeting"] = []
                            } else {
                              copy["amz_keyword_targeting"] = []
                            }
                            copy["name"] = ""
                            delete copy["amz_product_audience_targeting"]
                            return copy;
                          })
                          props.getCurrentType('spbrandkeyword')
                        }
                      }}>
                      <div className="choose-box-wrap" onClick={setActiveBox}>
                      <div className={props.campaignType === "retargeting" && props.currentPage === "first"?"tick-box-container":"d-none"}></div>
                        <div className="img-box"><img src={CampaignImage2} alt="" className="img-box" /></div>
                        <div className="type-box-content">
                          <div className="type-heading">
                            <span>
                              Sponsored Product- {props.campaignType === "prospect"?<>
                              Automatic Targeting</>:<>Brand  Keyword</>}
                            </span>
                            <span>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g opacity="0.7">
                                  <mask
                                    id="mask0_2665_39799"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  >
                                    <rect
                                      width="24"
                                      height="24"
                                      fill="#D9D9D9"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_2665_39799)">
                                    <path
                                      d="M12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z"
                                      fill="#4D4184"
                                    />
                                    <path
                                      d="M11.968 16.7498C12.1846 16.7498 12.364 16.6791 12.506 16.5378C12.6473 16.3958 12.718 16.2165 12.718 15.9998V11.7248C12.718 11.5248 12.6473 11.3538 12.506 11.2118C12.364 11.0705 12.1846 10.9998 11.968 10.9998C11.7513 10.9998 11.5723 11.0705 11.431 11.2118C11.289 11.3538 11.218 11.5331 11.218 11.7498V16.0248C11.218 16.2248 11.289 16.3958 11.431 16.5378C11.5723 16.6791 11.7513 16.7498 11.968 16.7498ZM11.968 9.2998C12.2013 9.2998 12.393 9.22047 12.543 9.0618C12.693 8.9038 12.768 8.70814 12.768 8.4748C12.768 8.25814 12.693 8.07047 12.543 7.9118C12.393 7.7538 12.2013 7.6748 11.968 7.6748C11.7346 7.6748 11.543 7.7538 11.393 7.9118C11.243 8.07047 11.168 8.25814 11.168 8.4748C11.168 8.70814 11.243 8.9038 11.393 9.0618C11.543 9.22047 11.7346 9.2998 11.968 9.2998Z"
                                      fill="#4D4184"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div>
                            <p className="campaign-type-desc">
                                Sponsored Products are cost-per-click (CPC) ads that promote individual product listings on Amazon
                            </p>
                          </div>
                        </div>
                      </div>
                    </Nav.Link>
                    {/* <button >Tab 1</button> */}
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="second" onClick={() => {
                      props.setCurrentPage("second")
                      if(props.campaignType === "prospect") {
                        handleCampaignData("SP-Keyword","Keyword","SP");
                        setAdGroupData((current) => {
                          const copy = {...current};
                          if ("amz_product_targeting" in copy) {
                            delete copy["amz_product_targeting"]
                          }
                          if("amz_keyword_targeting" in copy) {
                            copy["amz_keyword_targeting"] = []
                          } else {
                            copy["amz_keyword_targeting"] = []
                          }
                          copy["name"] = ""
                          delete copy["amz_product_audience_targeting"]
                          return copy;
                        })
                        props.getCurrentType("spkeyword")
                      }
                      if(props.campaignType === "retargeting") {
                        handleCampaignData("SP-Product","Product","SP");
                        setAdGroupData((current) => {
                          const copy = {...current};
                        //  if ("amz_product_targeting" in copy) {
                          copy["amz_product_targeting"] = [];
                          // }
                          if("amz_keyword_targeting" in copy) {
                            delete copy["amz_keyword_targeting"]
                          }
                          copy["name"] = ""
                          return copy;
                        })
                        props.getCurrentType("spbrandproduct")
                      }
                      }}>
                      <div className="choose-box-wrap" onClick={setActiveBox}>
                      <div className={props.campaignType === "retargeting" && props.currentPage === "second"?"tick-box-container":"d-none"}></div>
                        <div className="img-box"><img src={CampaignImage1} className="img-box" alt="" /></div>
                        <div className="type-box-content">
                          <div className="type-heading">
                            <span>
                              Sponsored Product- {props.campaignType === "prospect"?<>
                              Keyword  Targeting</>:<>Brand Product</>} 
                            </span>
                            <span>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g opacity="0.7">
                                  <mask
                                    id="mask0_2665_39799"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  >
                                    <rect
                                      width="24"
                                      height="24"
                                      fill="#D9D9D9"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_2665_39799)">
                                    <path
                                      d="M12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z"
                                      fill="#4D4184"
                                    />
                                    <path
                                      d="M11.968 16.7498C12.1846 16.7498 12.364 16.6791 12.506 16.5378C12.6473 16.3958 12.718 16.2165 12.718 15.9998V11.7248C12.718 11.5248 12.6473 11.3538 12.506 11.2118C12.364 11.0705 12.1846 10.9998 11.968 10.9998C11.7513 10.9998 11.5723 11.0705 11.431 11.2118C11.289 11.3538 11.218 11.5331 11.218 11.7498V16.0248C11.218 16.2248 11.289 16.3958 11.431 16.5378C11.5723 16.6791 11.7513 16.7498 11.968 16.7498ZM11.968 9.2998C12.2013 9.2998 12.393 9.22047 12.543 9.0618C12.693 8.9038 12.768 8.70814 12.768 8.4748C12.768 8.25814 12.693 8.07047 12.543 7.9118C12.393 7.7538 12.2013 7.6748 11.968 7.6748C11.7346 7.6748 11.543 7.7538 11.393 7.9118C11.243 8.07047 11.168 8.25814 11.168 8.4748C11.168 8.70814 11.243 8.9038 11.393 9.0618C11.543 9.22047 11.7346 9.2998 11.968 9.2998Z"
                                      fill="#4D4184"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div>
                            <p className="campaign-type-desc">
                                Sponsored Products are cost-per-click (CPC) ads that promote individual product listings on Amazon
                            </p>
                          </div>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="third" onClick={() => {
                      props.setCurrentPage("third");
                      if(props.campaignType === "prospect") {
                        handleCampaignData("SP-Product","Product","SP");
                        setAdGroupData((current) => {
                          const copy = {...current};
                        //  if ("amz_product_targeting" in copy) {
                          copy["amz_product_targeting"] = [];
                          // }
                          if("amz_keyword_targeting" in copy) {
                            delete copy["amz_keyword_targeting"]
                          }
                          copy["name"] = ""
                          delete copy["amz_product_audience_targeting"]
                          return copy;
                        })
                        props.getCurrentType("spproduct")
                      }
                      if(props.campaignType === "retargeting") {
                        handleCampaignData("SD-Product","Product","SD");
                        setAdGroupData((current) => {
                          const copy = {...current};
                        //  if ("amz_product_targeting" in copy) {
                          copy["amz_product_targeting"] = [];
                          // }
                        if("amz_keyword_targeting" in copy) {
                          delete copy["amz_keyword_targeting"]
                        }
                        copy["name"] = ""
                        delete copy["amz_product_audience_targeting"]
                          return copy;
                        })
                        props.getCurrentType("sdbrandproduct")
                      }

                      }}>
                      <div className="choose-box-wrap" onClick={setActiveBox}>
                      <div className={props.campaignType === "retargeting" && props.currentPage === "third"?"tick-box-container":"d-none"}></div>
                        <div className="img-box"><img src={CampaignImage3} alt="" className="img-box" /></div>
                        <div className="type-box-content">
                          <div className="type-heading">
                            <span>
                            Sponsored {props.campaignType === "prospect"?"Product":"Display"}- {props.campaignType === "prospect"?<>
                              Product Targeting</>:<>Brand Product</>} 
                            </span>
                            <span>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g opacity="0.7">
                                  <mask
                                    id="mask0_2665_39799"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  >
                                    <rect
                                      width="24"
                                      height="24"
                                      fill="#D9D9D9"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_2665_39799)">
                                    <path
                                      d="M12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z"
                                      fill="#4D4184"
                                    />
                                    <path
                                      d="M11.968 16.7498C12.1846 16.7498 12.364 16.6791 12.506 16.5378C12.6473 16.3958 12.718 16.2165 12.718 15.9998V11.7248C12.718 11.5248 12.6473 11.3538 12.506 11.2118C12.364 11.0705 12.1846 10.9998 11.968 10.9998C11.7513 10.9998 11.5723 11.0705 11.431 11.2118C11.289 11.3538 11.218 11.5331 11.218 11.7498V16.0248C11.218 16.2248 11.289 16.3958 11.431 16.5378C11.5723 16.6791 11.7513 16.7498 11.968 16.7498ZM11.968 9.2998C12.2013 9.2998 12.393 9.22047 12.543 9.0618C12.693 8.9038 12.768 8.70814 12.768 8.4748C12.768 8.25814 12.693 8.07047 12.543 7.9118C12.393 7.7538 12.2013 7.6748 11.968 7.6748C11.7346 7.6748 11.543 7.7538 11.393 7.9118C11.243 8.07047 11.168 8.25814 11.168 8.4748C11.168 8.70814 11.243 8.9038 11.393 9.0618C11.543 9.22047 11.7346 9.2998 11.968 9.2998Z"
                                      fill="#4D4184"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div>
                            <p className="campaign-type-desc">
                            Sponsored Products are cost-per-click (CPC) ads that promote individual product listings on Amazon
                            </p>
                          </div>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fourth" onClick={() => {
                      props.setCurrentPage("fourth")
                      if(props.campaignType === "prospect") {
                        handleCampaignData("SD-Product","Product","SD");
                        setAdGroupData((current) => {
                          const copy = {...current};
                        //  if ("amz_product_targeting" in copy) {
                          copy["amz_product_targeting"] = [];
                          // }
                          delete copy["amz_product_audience_targeting"]
                          copy["name"] = ""
                          return copy;
                        })
                        props.getCurrentType("sdproduct")
                      }
                      if(props.campaignType === "retargeting") {
                        handleCampaignData("SD-Audience","Audience","SD");
                        setAdGroupData((current) => {
                          const copy = {...current} 
                          delete copy["bidding"]
                          if("amz_product_targeting" in copy) {
                            delete copy["amz_product_targeting"];
                          }
                          if("amz_keyword_targeting" in copy) {
                            delete copy["amz_keyword_targeting"]
                          }
                          copy["amz_product_audience_targeting"] = []
                          copy["name"] = ""
                          return copy;
                        })
                        props.getCurrentType("sdbrandaudience")
                      }
                      }}>
                      <div className="choose-box-wrap" onClick={setActiveBox}>
                      <div className={props.campaignType === "retargeting" && props.currentPage === "fourth"?"tick-box-container":"d-none"}></div>
                        <div className="img-box"><img src={CampaignImage4} alt="" className="img-box" /></div>
                        <div className="type-box-content">
                          <div className="type-heading">
                            <span>
                            Sponsored {props.campaignType === "prospect"?"Display":"Display"}- {props.campaignType === "prospect"?<>
                              Product  Targeting</>:<>Brand  Audience</>}
                            </span>
                            <span>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g opacity="0.7">
                                  <mask
                                    id="mask0_2665_39799"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  >
                                    <rect
                                      width="24"
                                      height="24"
                                      fill="#D9D9D9"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_2665_39799)">
                                    <path
                                      d="M12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z"
                                      fill="#4D4184"
                                    />
                                    <path
                                      d="M11.968 16.7498C12.1846 16.7498 12.364 16.6791 12.506 16.5378C12.6473 16.3958 12.718 16.2165 12.718 15.9998V11.7248C12.718 11.5248 12.6473 11.3538 12.506 11.2118C12.364 11.0705 12.1846 10.9998 11.968 10.9998C11.7513 10.9998 11.5723 11.0705 11.431 11.2118C11.289 11.3538 11.218 11.5331 11.218 11.7498V16.0248C11.218 16.2248 11.289 16.3958 11.431 16.5378C11.5723 16.6791 11.7513 16.7498 11.968 16.7498ZM11.968 9.2998C12.2013 9.2998 12.393 9.22047 12.543 9.0618C12.693 8.9038 12.768 8.70814 12.768 8.4748C12.768 8.25814 12.693 8.07047 12.543 7.9118C12.393 7.7538 12.2013 7.6748 11.968 7.6748C11.7346 7.6748 11.543 7.7538 11.393 7.9118C11.243 8.07047 11.168 8.25814 11.168 8.4748C11.168 8.70814 11.243 8.9038 11.393 9.0618C11.543 9.22047 11.7346 9.2998 11.968 9.2998Z"
                                      fill="#4D4184"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div>
                            <p className="campaign-type-desc">
                            Sponsored display ads give advertisers a better opportunity to target their audience
                            </p>
                          </div>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
  )
}

export default CampaignType
