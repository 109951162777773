import "./editCampaign.css";
import { Stack } from "react-bootstrap";
import SectionTitle from "../../components/sectionTitle";
import "./campaignList.css";
import moment from "moment/moment";
import React, { useEffect, useState, useRef } from "react";
import SectionTittle from "../../components/sectionTitle";
import SecondaryBtnRb from "../../components/Buttons/secondaryBtnRb/secondaryBtnRb";
import "./editCampaignMainPage.css";
import EditProductOnEditAdBtn from "./editProductOnEditAdBtn";
import EditCampaign from "./editCampaign";
import CalenderComponent from "../../components/calender/Calender";
import calendarIcon from "../../assets/icons/dahboard_calender_icon.png";
import SerachTerms from "./searchTerms";

import { useDispatch, useSelector } from "react-redux";
import { campaignListActions } from "../../redux/slices/campaignList";
import { useGlobalContext } from "../../context/global";
import { Link,useNavigate, useSearchParams } from "react-router-dom";
import closeIcon from "../../assets/icons/close_icon.png";
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.svg";

const EditCampaignMainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [goBackBtnHandlerclicked, setgoBackBtnHandlerclicked] = useState(false);
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const { dateDifference, setDateDifference } = useGlobalContext();
  const { calendarDate, setCalendarDate } = useGlobalContext();
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const calenderIconRef = useRef(null);
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const { name } = params;
  const { campName } = params;
  const initialPageState = params?.editadspage?"edit-ads-btn":params?.editsearchtermpage?"search-terms-btn":"edit-targetting-btn"
  const [selectedBtn, setselectedBtn] = useState(initialPageState);
  useEffect(() => {
    document.addEventListener("mousedown", closeCalender);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeCalender);
    };
  }, [showCustomDateBox]);
  const editBtnHandler = (e) => {
    setselectedBtn(e.target.id);
    dispatch(
      campaignListActions.editAdGroupSectionCurrentselectedBtnName(e.target.id)
    );
    dispatch(campaignListActions.appliedFilterData([]));
  };
  const editBtnclickedCampaignDetailsForEditTargetingTable = useSelector(
    (state) =>
      state.campaignListData.editBtnclickedCampaignDetailsForEditTargetingTable
  );

  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  const closeCalender = (e) => {
    if (
      calenderIconRef.current &&
      showCustomDateBox &&
      !calenderIconRef.current.contains(e.target)
    ) {
      setShowCustomDateBox(false);
    }
  };

  const headerCampaignNameClickHandler = () => {
    navigate("/campaign-list");
  }
  // const dateConverter = (startDate, timeEnd) => {
  //   const currentDate = moment().date(); // This will return current date in number format
  //   const currentMonth = moment().month();
  //   const currentYear = moment().year();
  //   const newStartDate = new Date(startDate);
  //   const newStartDateY = moment(newStartDate).date();
  //   const newStartMonthY = moment(newStartDate).month();
  //   const newStartYearY = moment(newStartDate).year();
  //   const newEndDate = new Date(timeEnd);
  //   const newEndDateY = moment(timeEnd).date();
  //   const newEndMonthY = moment(timeEnd).month();
  //   const newEndYearY = moment(timeEnd).year();
  //   let result = moment(newStartDate).diff(newEndDate, "days");
  //   const min = Math.min(newStartDateY, newEndDateY);
  //   const max = Math.max(newStartDateY, newEndDateY);
  //   console.log(
  //     result,
  //     "Result Printed Out with minimum and maximum",
  //     min,
  //     max
  //   );
  //   if (currentDate === newStartDateY && newEndDateY && result === 0) {
  //     setDateDifference("Today");
  //     return true;
  //   }

  //   if (newStartDateY && newEndDateY === currentDate - 1 && result === 0) {
  //     setDateDifference("Yesterday");
  //     return true;
  //   }
  //   if (result === 0 && newStartDateY == newEndDateY && min == max) {
  //     setDateDifference("Custom");
  //     return true;
  //   }
  //   if (
  //     newEndDateY > newStartDateY &&
  //     newStartMonthY === currentMonth &&
  //     result === -30
  //   ) {
  //     setDateDifference("This Month");
  //     return true;
  //   }

  //   if (
  //     (newEndDateY > newStartDateY && newStartMonthY < currentMonth) ||
  //     (currentYear > newEndYearY && result === -30)
  //   ) {
  //     setDateDifference("Last Month");
  //     return true;
  //   }

  //   if (currentDate >= min || currentDate <= max) {
  //     if (currentDate === newStartDateY && newEndDateY && result === 0) {
  //       setDateDifference("Today");
  //       return true;
  //     }

  //     if (newStartDateY && newEndDateY === currentDate - 1 && result === 0) {
  //       setDateDifference("Yesterday");
  //       return true;
  //     }

  //     if (
  //       newEndDateY > newStartDateY &&
  //       newStartMonthY === currentMonth &&
  //       result === -30
  //     ) {
  //       setDateDifference("This Month");
  //       return true;
  //     }

  //     if (
  //       (newEndDateY > newStartDateY && newStartMonthY < currentMonth) ||
  //       (currentYear > newEndYearY && result === -30)
  //     ) {
  //       setDateDifference("Last Month");
  //       return true;
  //     }

  //     if (currentDate - min <= 6 && currentDate - max < 0 && result == -6) {
  //       setDateDifference("This Week");
  //       return true;
  //     }
  //     if (
  //       currentDate - min >= 7 &&
  //       currentDate - max >= 1 &&
  //       currentDate - max <= 7 &&
  //       result == -6
  //     ) {
  //       setDateDifference("Last Week");
  //       return true;
  //     } else {
  //       setDateDifference("Custom");
  //       return true;
  //     }
  //   }

  //   // if(newEndDateY-newStartDateY <= currentDate && result === -6) {
  //   //     console.log(newStartDateY,"newStartDateY")
  //   //     console.log(newEndDateY,"newEndDateY")
  //   //     console.log(currentDate,"currentDate")
  //   //     console.log(result,"result")
  //   //     setDateDifference("This week")
  //   //     return
  //   // }

  //   // console.log(result,'result');
  //   // setDateDifference(result);
  //   localStorage.setItem("datecount", JSON.stringify(result));
  //   return result;
  // };

  // useEffect(() => {
  //   dateConverter(calendarDate[0].startDate, calendarDate[0].endDate);
  // }, [calendarDate]);

  const goBackBtnHandler = () => {
    navigate("/campaign-list");
    dispatch(
      campaignListActions.editBtnclickedCampaignDetailsForEditTargetingTable([])
    );
    dispatch(campaignListActions?.hideEditCmapignMainPageOnGobackBtnClikced());
    dispatch(campaignListActions.actionEditCollectiveBidForMasterTargeting({}));
  };
  // className={selectedBtn === "edit-ads-btn" ? "search-terms-btn" : ""}
  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
      <div className="edit-campaign-main-container">
        <div className="d-flex et-pading-bottom">
          {/* <SectionTittle sectionTitleHeading={`Campaign:(${campaignName} > ${name})`}></SectionTittle> */}
          <div className="d-flex">
            <Link to={"/campaign-list"}><span className="campaign-hard-text color-for-highlight-campaignlist-text">Campaign List</span></Link>
            <span className="campaign-hard-text px-2">{">"}</span>
            <span className="campaign-hard-text">Campaign:</span>
            <span className="campaign-name-inedit" onClick={headerCampaignNameClickHandler}>{`${campName}`}</span>
            <span className="campaign-hard-text px-2">{">"}</span>
            <span className="campaign-hard-text"> Adgroup:</span>
            <span className="adgroup-name-inedit">{name}</span>
          </div>
        </div>
        <div className="ec-btns-container ">
          <div className="d-flex flex-row align-items-center justify-content-start flex-grow-1">
            <div className="pe-3">
              <SecondaryBtnRb
                onClick={editBtnHandler}
                className={
                  selectedBtn === "edit-targetting-btn"
                    ? "primary-active-btn "
                    : "primary-inactive-btn"
                }
                secondaryBtnText="Edit Targeting"
                id="edit-targetting-btn"
              ></SecondaryBtnRb>
            </div>
            <div className="pe-3">
              <SecondaryBtnRb
                onClick={editBtnHandler}
                className={
                  selectedBtn === "edit-ads-btn"
                    ? "primary-active-btn "
                    : "primary-inactive-btn"
                }
                secondaryBtnText="Edit Ads"
                id="edit-ads-btn"
              ></SecondaryBtnRb>
            </div>
            <div className="pe-3">
              <SecondaryBtnRb
                onClick={editBtnHandler}
                className={
                  selectedBtn === "search-terms-btn"
                    ? "primary-active-btn "
                    : "primary-inactive-btn"
                }
                secondaryBtnText="Search Terms"
                id="search-terms-btn"
              ></SecondaryBtnRb>
            </div>
          </div>

          <button
            className="date-container date-btn-campaignlist d-none"
            onClick={dateInputClickHandler}
          >
            <div className="d-flex justify-conntent-center">
              <span className="date-btn-text">{dateDifference}</span>
            </div>
            <button className="btn-calender-img-holder">
              <span className="">
                <img
                  className="dropdown-img-for-select"
                  src={selectSideIcon}
                ></img>

                <img
                  className="calender-icon-for-campaignlist"
                  src={CalenderIconForCampaignList}
                ></img>
              </span>
            </button>
          </button>
        </div>
        {showCustomDateBox && (
          <div ref={calenderIconRef} className="calender-inclasslist-container">
            <CalenderComponent setShowCustomDateBox={setShowCustomDateBox}></CalenderComponent>
            <span className="calender-close-icon-campaignlist">
              <img
                onClick={() => setShowCustomDateBox(false)}
                src={closeIcon}
              ></img>
            </span>
          </div>
        )}
        <div>
          {selectedBtn === "edit-targetting-btn" ? (
            <EditCampaign></EditCampaign>
          ) : selectedBtn === "edit-ads-btn" ? (
            <EditProductOnEditAdBtn></EditProductOnEditAdBtn>
          ) : selectedBtn === "search-terms-btn" ? (
            <SerachTerms></SerachTerms>
          ) : null}
        </div>
        <div className="footer-btn-container">
          <button className="primary-outline-btn btn btn-outline-primary " onClick={goBackBtnHandler}>
            Go Back
          </button>
          <button className="primary-btn btn btn-primary">Update</button>
        </div>
      </div>
    </div>
  );
};

export default EditCampaignMainPage;
