// import React from "react";
// import { createPortal } from "react-dom";
import {ThreeDots} from "react-loader-spinner";
// import LoaderImage from '../assets/images/output-onlinegiftools.gif'
import LoaderImageBrand from '../assets/images/brand-gif.gif';
import LoaderImage from '../assets/images/loader_gif.gif';
import { useGlobalContext } from "../context/global";
import "./loader.css";
import ReactDOM from "react-dom";
export default function Loader(props) {
  const {config} = useGlobalContext();
  return props.visible
    ? ReactDOM.createPortal(
        <div className="loader-main">
            {config && config.name === "Adyogi" ? (
              <img src={LoaderImageBrand} alt="" height={120} />
            ) : (
              <img src={LoaderImage} alt="" height={120} width={120} />
            )}          
            <p className="loader-text" id="loader-parent-text">
            WORTHWHILE THINGS ARE{" "}
            <p className="loader-text-appended-loader">
               DESERVING OF PATIENCE{" "}
              <ThreeDots
                height={25}
                width={25}
                color="#07A9D2"
                radius={9}
                visible={true}
                wrapperStyle={{}}
                wrapperClassName=""
                ariaLabel="three-dots-loading"
              />
            </p>{" "}
          </p>
        </div>,
        document.getElementById("portal-loader")
      )
    : null;
}
