import FilterLogicModal from "../../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import CustomDropDown from "../../../components/DropDown/CustomDropDown";
import dropdownIcon from "../../../assets/icons/downArrowForSplitButton.svg";
import settingIcon from "../../../assets/icons/overviewSeiingIcon.svg";
import "./AdGroupComparison.css";
import ascendingIconSvg from "../../../assets/svgs/acendingIconSvg.svg";
import descendingIconSvg from "../../../assets/svgs/descendingIcon.svg";
import { Stack } from "react-bootstrap";
import TableRb from "../../../components/Tables/tableRb";
import React, { useEffect, useState, } from "react";
import { useSelector, useDispatch } from "react-redux";
import Overview from "../../../components/overview";
import PieChart from "../../../components/pieChart/pieChart";

import Spinner from "../../../components/spinners/spinner";
import { useRef } from "react";
import CalenderComponent from "../../../components/calender/Calender";
import closeIcon from "../../../assets/icons/close_icon.png";
import { useGlobalContext } from "../../../context/global";
import selectSideIcon from "../../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../../assets/icons/calender-icon-for-campaignlist.svg";
import { campaignListActions } from "../../../redux/slices/campaignList";
import { getCurrencySymbol } from "../../../constant/defaultCurrencyConfigurations";


const AdGroupComparison = (props) => {

    const dispatch = useDispatch();

    const {
        dateDifference,
    } = useGlobalContext();



    const dashboardSideBarVisibiltyState = useSelector(
        (state) => state.dashboardData.dashboardSideBarVisibiltyState
    );


    const filterModalInCampaignListVisibilty = useSelector(
        (state) => state.campaignListData.filterModalInCampaignListVisibilty
    );

    const metricesWithTwoDecimalPlaces = [
        "cpm",
        "cpc",
        "roas",
        "acos",
        "ctr",
        "cvr",
    ];

    const [allFetchLoaders, setAllFetchLoaders] = useState({
        mainPageLoader: false,
        campaignStatsLoader: false,
        campaignAdsetsLoader: false,
        campaignAdsetStatsLoader: false,
    });

    const [showCustomDateBox, setShowCustomDateBox] = useState(false);
    const [dataHeads, setdataHeads] = useState([

        {
            "fieldName": "Ad Group Name",
            "minRange": "-",
            "maxRange": "-",
            "id": "ad_group_name",
            "isDefaultHead": true,
            "isHeadRightAlign": false,
            "isHeadWithIcon": false,
            "isAbbrivation": false
        },
        {
            "fieldName": "Bid",
            "minRange": "-",
            "maxRange": "-",
            "id": "bid",
            // "isDefaultHead": true,
            "isHeadRightAlign": true,
            "isHeadWithIcon": "sort",
            "isAbbrivation": false
        },
        {
            "fieldName": "SPENDS",
            "minRange": "0",
            "maxRange": "0",
            "id": "spends",
            // "isDefaultHead": true,
            "isHeadRightAlign": true,
            "isHeadWithIcon": "sort",
            "isAbbrivation": false,
            "isFindTaskActive": false,
            "status": false,
            "isSortFunctionalityNeededHere": true

        },
        {
            "fieldName": "% Δ",
            "minRange": "-",
            "maxRange": "-",
            "id": "spends_delta",
            // "isDefaultHead": true,
            "isHeadRightAlign": true,
            "isHeadWithIcon": false,
            "isAbbrivation": false,
            "isFindTaskActive": false,
            "status": false,
            "isSortFunctionalityNeededHere": true

        },
        {
            "fieldName": "Revenue",
            "minRange": "-",
            "maxRange": "-",
            "id": "revenue",
            // "isDefaultHead": true,
            "isHeadRightAlign": true,
            "isHeadWithIcon": "sort",
            "isAbbrivation": false,
            "isSortFunctionalityNeededHere": true

        },
        {
            "fieldName": "% Δ",
            "minRange": "-",
            "maxRange": "-",
            "id": "revenue_delta",
            // "isDefaultHead": true,
            "isHeadRightAlign": true,
            "isHeadWithIcon": false,
            "isAbbrivation": false,
            "isSortFunctionalityNeededHere": true

        },
        {
            "fieldName": "ROAS",
            "minRange": "-",
            "maxRange": "-",
            "id": "roas",
            // "isDefaultHead": true,
            "isHeadRightAlign": true,
            "isHeadWithIcon": "sort",
            "isAbbrivation": true,
            "isSortFunctionalityNeededHere": true

        },
        {
            "fieldName": "% Δ",
            "minRange": "-",
            "maxRange": "-",
            "id": "roas_delta",
            // "isDefaultHead": true,
            "isHeadRightAlign": true,
            "isHeadWithIcon": false,
            "isAbbrivation": false,
            "isSortFunctionalityNeededHere": true
        },

    ]);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [targettingType, setTargettingType] = useState("All");
    const currencySymbol = getCurrencySymbol();

    const [currentIndex, setCurrentIndex] = useState(0);


    const [minMaxFilterActivated, setMinMaxFilterActivated] = useState(false);
    const [searchfilteredData, setsearchfilteredData] = useState([
        {
            "id": "6400345c3c2cb56a78765866",
            "ad_group_name": "ADYG_Women",
            "spends": "200",
            "spends_delta": "10",
            "revenue": "101",
            "revenue_delta": "10",
            "bid": 2321,

            "roas": "21",
            "roas_delta": "10",
            "campaignStat":
                [
                    {
                        "clicks": 6674,
                        "ad_group_name": "ADYG_Women",
                        "impressions": 2030308,
                        "orders": 47,
                        "bid": 2321,
                        "cpm": 6.315174840467556,
                        "ctr": 0.3287185983604458,
                        "cpc": 1.9211492358405757,
                        "cvr": 0.7042253521126761,
                        "aov": 453.31702127659577,
                        "roas": 1.6616998459648642,
                        "revenue": 60.17933999502486,
                        "spends": 12821.750000000002,
                        "sale_value": 21305.9,
                        "campaign_id": "228155303657618",
                        "spends_delta": "10",
                        "revenue_delta": "10",
                        "roas_delta": "20"
                    }
                ]
        },
        {
            "id": "6400345c3c2cb56a78765866",
            "ad_group_name": "ADYG_Women",
            "bid": 8321,

            "spends": "50",
            "spends_delta": "5",
            "revenue": "12",
            "revenue_delta": "5",
            "roas": "22",
            "roas_delta": "5",
            "campaignStat": [
                {
                    "clicks": 295,
                    "impressions": 84295,
                    "bid": 8321,
                    "orders": 10,
                    "ad_group_name": "ADYG_Women",
                    "cpm": 7.383949225932736,
                    "ctr": 0.34996144492555903,
                    "cpc": 2.1099322033898305,
                    "cvr": 3.389830508474576,
                    "aov": 440.2030000000001,
                    "roas": 7.072329418569158,
                    "revenue": 14.139612860430297,
                    "spends": 622.43,
                    "sale_value": 4402.030000000001,
                    "campaign_id": "22704114208694",
                    "spends_delta": "5",
                    "revenue_delta": "5",
                    "roas_delta": "15"
                }
            ]
        },


    ]);
    const [isSortBoxVisible, setIsSortBoxVisible] = useState(false);

    const calenderIconRefForCampaignlist = useRef(null);

    const closeCalender = (e) => {
        if (
            calenderIconRefForCampaignlist.current &&
            showCustomDateBox &&
            !calenderIconRefForCampaignlist.current.contains(e.target)
        ) {
            setShowCustomDateBox(false);
        }
    };

    const dateInputClickHandler = (e) => {
        setShowCustomDateBox(!showCustomDateBox);
    };


    const onFilterIconhandler = () => {
        dispatch(campaignListActions.showFilterModalInCampaignList());
    };


    useEffect(() => {
        document.addEventListener("mousedown", closeCalender);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", closeCalender);
        };
    }, [showCustomDateBox]);

    // let piechartData = [
    //     {
    //         "date": "2023-04-09",
    //         "roas": 4.846436305432634,
    //         "spends": 849.68,
    //         "campaign_type": "Retarget"
    //     },

    // ]

    const dummyChartData =
    {
        "date": "2023-04-09",
        "roas": 4.846436305432634,
        "spends": 849.68,
        "campaign_type": "Retarget"
    }

    const onSpendFilterClickHandler = (value, index) => {
        let tempTargetStat = JSON.stringify(searchfilteredData);
        tempTargetStat = JSON.parse(tempTargetStat);
        const mainValue = value === "REVENUE" ? "sale_value" : value;
        tempTargetStat = tempTargetStat.sort((a, b) => {
            let x = a?.campaignStat[0]?.[mainValue.toLowerCase()] ?? 0;
            let y = b?.campaignStat[0]?.[mainValue.toLowerCase()] ?? 0;
            return isSortBoxVisible ? y - x : x - y;
        });
        setsearchfilteredData(tempTargetStat);
        setIsSortBoxVisible(!isSortBoxVisible);
        setCurrentIndex(index);
    };


    useEffect(() => {
        if (searchfilteredData !== null) { onSpendFilterClickHandler('spends', 1) }
    }, [])

    const onDropdownImghandler = (type) => {
        // console.log("@$# onDropdownImghandler")
    };


    const filterCampaigns = (e, type) => {
        // console.log("@$# 11  filterCampaigns", filterCampaigns)
    };




    const dummySearchfilteredData = [
        {
            "id": "6400345c3c2cb56a78765866",
            "portfolio_name": "ADYG_Women",
            "spends": "10",
            "spends_delta": "5",
            "revenue": "10",
            "revenue_delta": "5",
            "roas": "10",
            "roas_delta": "5"
        },
    ];



    useEffect(() => {
        if (searchfilteredData !== null) { onSpendFilterClickHandler('spends', 1) }
    }, [])




    let tableBody = (
        <div className="main-campaignlist-table-body-container  lsit-table-scrollbar">
            {searchfilteredData?.length ? (
                <table className="campaign-list-table">
                    <thead className="fixed-table">
                        <tr className="main-table-row-fixed">
                            {dataHeads?.map((th, mainIndex) => {
                                return (
                                    <th

                                        className={
                                            th.isHeadRightAlign
                                                ? "campaig-list-head cl-end-align"
                                                : th.isHeadWithIcon === "sort"
                                                    ? "campaig-list-head active-filter-colmun"
                                                    : "campaig-list-head "
                                        }

                                        style={{
                                            zIndex: mainIndex === 0 ? 99 : ''
                                        }}
                                    >
                                        {th.isHeadWithIcon === "sort" ? (
                                            <div
                                                className={
                                                    !th.isAbbrivation
                                                        ? "d-flex flex-row align-item-center justify-content-end captital-first-letter"
                                                        : "d-flex flex-row align-item-center justify-content-end"
                                                }
                                            >
                                                <span>
                                                    {!th.isAbbrivation
                                                        ? th.fieldName.toLowerCase()
                                                        : th.fieldName}
                                                </span>
                                                <span
                                                    className="spends-icon"
                                                    onClick={() =>
                                                        onSpendFilterClickHandler(th.id, mainIndex)
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            isSortBoxVisible && mainIndex === currentIndex
                                                                ? ascendingIconSvg
                                                                : descendingIconSvg
                                                        }
                                                        className={
                                                            mainIndex === currentIndex
                                                                ? "mt-spends-icon-activated"
                                                                : ""
                                                        }
                                                        alt={"FilterIcon"}
                                                    ></img>
                                                </span>
                                                <div
                                                    className={
                                                        isSortBoxVisible ? "sortBox-container" : "hide"
                                                    }
                                                ></div>
                                            </div>
                                        ) : (
                                            <span className="captital-first-letter">
                                                {th.fieldName.toLowerCase()}
                                            </span>
                                        )}

                                        {/* {th.fieldName} */}
                                        {/* {th} */}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    {searchfilteredData?.length ? (
                        <tbody className="another-tbody">
                            {searchfilteredData?.map((data, index1) => {
                                data = JSON.stringify(data);
                                data = JSON.parse(data);

                                for (let i = 0; i < dataHeads?.length; i++) {
                                    if (!dataHeads[i]?.isDefaultHead) {
                                        data[dataHeads[i]?.id] = data[dataHeads[i]?.id]
                                            ? data[dataHeads[i]?.id]
                                            : "-";
                                    }
                                }
                                return (
                                    <React.Fragment key={index1 + 1}>
                                        <tr key={`${data?.id}`}>
                                            <td className="first-column-sw-btn">
                                                {data?.ad_group_name}
                                            </td>

                                            {data?.campaignStat?.length
                                                ? data["campaignStat"].map((campStatInst, indo) => {
                                                    // if (data.campaignStat) {

                                                    for (let i = 0; i < dataHeads.length; i++) {
                                                        if (!dataHeads[i].isDefaultHead) {
                                                            campStatInst[dataHeads[i].id] = campStatInst[
                                                                dataHeads[i].id
                                                            ]
                                                                ? dataHeads[i].id === "spends" || 
                                                                // dataHeads[i].id === "spends" ||
                                                                    dataHeads[i].id === "sale_value"
                                                                    ? ` ${currencySymbol}${Math.round(
                                                                        campStatInst[dataHeads[i].id]
                                                                    )}`
                                                                    : metricesWithTwoDecimalPlaces.includes(
                                                                        dataHeads[i].id
                                                                    )
                                                                        ? `${Number(
                                                                            campStatInst[dataHeads[i].id]
                                                                        ).toFixed(2)}`
                                                                        : Math.round(campStatInst[dataHeads[i].id])
                                                                : "-";
                                                        }
                                                    }

                                                    {
                                                        return dataHeads.map((currentDataHead, i) => {
                                                            if (!currentDataHead.isDefaultHead) {
                                                                return (
                                                                    // <td key={i + 2} className="td-with-loader" >
                                                                    <td
                                                                        key={i + 2}
                                                                        className={
                                                                            currentDataHead.isHeadRightAlign
                                                                                ? "cl-end-align td-with-loader"
                                                                                : " td-with-loader"
                                                                        }
                                                                    >
                                                                        {allFetchLoaders.campaignStatsLoader ? (
                                                                            <Spinner shimmer={true} />
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {campStatInst
                                                                            ? campStatInst[currentDataHead.id]
                                                                            : "-"}
                                                                    </td>
                                                                );
                                                            }
                                                        });
                                                    }
                                                })
                                                : dataHeads.map((currentDataHead, i) => {
                                                    if (!currentDataHead.isDefaultHead) {
                                                        return (
                                                            <td
                                                                key={i + 3}
                                                                className={
                                                                    currentDataHead.isHeadRightAlign
                                                                        ? "cl-end-align td-with-loader"
                                                                        : " td-with-loader"
                                                                }
                                                            >
                                                                {allFetchLoaders.campaignStatsLoader ? (
                                                                    <Spinner shimmer={true} />
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </td>
                                                        );
                                                    }
                                                })}


                                            {/* <td>{data?.spends}</td>
                                            <td>{parseFloat(data?.spends_delta).toFixed(2)}%</td>
                                            <td>{data?.revenue}</td>
                                            <td>{parseFloat(data?.revenue_delta).toFixed(2)}</td>
                                            <td>{data?.roas}</td>
                                            <td>{parseFloat(data?.roas_delta).toFixed(2)}%</td> */}
                                        </tr>

                                        {/* <tr key={`${data?.id}`}>
                                            <td className="first-column-sw-btn">
                                                {data?.portfolio_name}
                                            </td>
                                            <td>{data?.spends}</td>
                                            <td>{parseFloat(data?.spends_delta).toFixed(2)}%</td>
                                            <td>{data?.revenue}</td>
                                            <td>{parseFloat(data?.revenue_delta).toFixed(2)}</td>
                                            <td>{data?.roas}</td>
                                            <td>{parseFloat(data?.roas_delta).toFixed(2)}%</td>
                                        </tr> */}
                                    </React.Fragment>
                                );
                            })}
                            <tr>

                                <td>TOTAL</td>
                                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                    {searchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.campaignStat[0].bid);
                                    }, 0).toFixed(0)}
                                </td>
                                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                    {searchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.campaignStat[0].spends);
                                    }, 0).toFixed(0)}
                                </td>
                                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                    {searchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.campaignStat[0].spends_delta);
                                    }, 0).toFixed(0)}%
                                </td>
                                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                    {searchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.campaignStat[0].revenue);
                                    }, 0).toFixed(0)}
                                </td>
                                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                    {searchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.campaignStat[0].revenue_delta);
                                    }, 0).toFixed(0)}%
                                </td>
                                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                    {searchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.campaignStat[0].roas);
                                    }, 0).toFixed(2)}
                                </td>

                                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                    {searchfilteredData.reduce((acc, obj) => {
                                        return acc + parseFloat(obj.campaignStat[0].roas_delta);
                                    }, 0).toFixed(0)}%
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <div>
                            <p>NO DATA FOUND</p>
                        </div>
                    )}
                </table>
            ) : (
                <div>
                    <p>NO DATA FOUND</p>
                    {/* <Spinner size={"lg"} shape={"border"}/> */}
                </div>
            )}
        </div>
    );


    useEffect(() => {
        setIsFilterVisible(filterModalInCampaignListVisibilty);
    }, [filterModalInCampaignListVisibilty]);

    return (
        <div
            className={
                dashboardSideBarVisibiltyState
                    ? "main-content-container-onopen-mode"
                    : "main-content-container-oncolapse-mode"
            }
            id="main-box" >
            <div
                style={{
                    marginLeft: "auto"
                }}
            // className="d-flex justify-content-end align-item-end"

            >
                <button
                    className="date-container date-btn-campaignlist d-none"
                    onClick={dateInputClickHandler}
                >
                    <div className="d-flex justify-conntent-center">
                        <span className="date-btn-text">
                            {dateDifference}
                        </span>
                    </div>
                    <button className="btn-calender-img-holder">
                        <span className="">
                            <img
                                className="dropdown-img-for-select"
                                src={selectSideIcon}
                                alt={"SelectSideIcon"}
                            ></img>

                            <img
                                className="calender-icon-for-campaignlist"
                                src={CalenderIconForCampaignList}
                                alt={"CalendarIcon"}
                            ></img>
                        </span>
                    </button>
                </button>
            </div>

            <div
                ref={calenderIconRefForCampaignlist}
                className={
                    showCustomDateBox
                        ? "calender-inclasslist-container"
                        : "hide"
                }
            >
                <CalenderComponent setShowCustomDateBox={setShowCustomDateBox}></CalenderComponent>
                <span className="calender-close-icon-campaignlist">
                    <img
                        onClick={() => setShowCustomDateBox(false)}
                        src={closeIcon}
                        alt={"CloseIcon"}
                    ></img>
                </span>
            </div>

            <Overview
                noTopMargin={true}
                showTitleAs={"AdGroup Report"}
                showCustomDateBox={showCustomDateBox}
                setShowCustomDateBox={setShowCustomDateBox}
                defaultCards={[
                    {
                        displayValue: "SPENDS",
                        idValue: "spends",
                    },
                    {
                        displayValue: "IMPRESSIONS",
                        idValue: "impressions",
                    },
                    {
                        displayValue: "CLICKS",
                        idValue: "clicks",
                    },
                    {
                        displayValue: "ROAS",
                        idValue: "roas",
                    },
                    {
                        displayValue: "ORDERS",
                        idValue: "orders",
                    },
                   
                ]}
            ></Overview>


            <div style={{ display: 'flex', margin: '16px 0px', }}>
                {/* <div style={{ width: '48%' }}> */}
                    <PieChart
                        showColors={true}
                        isShowSelect={false}
                        isShowLegend={true}
                        campaignData={dummyChartData}
                        id={"index"}
                        chartTitle={"Campaign Type"}
                        rightLegend={true}
                    />
                {/* </div> */}

            </div>

            <div className="dropdownContentWrapper"
            style={{marginBottom:'0px'}}>

                <Stack gap={4}>
                    <CustomDropDown
                        title={"Campaign Type"}
                        filterCampaigns={filterCampaigns}
                        targettingValue={targettingType}
                        onDropdownImghandler={onDropdownImghandler}
                        src={dropdownIcon}
                        type={"objective"}
                        autoWidth={true}
                        btnBgColor="#DBD9E6"
                        borderRadius="0px 3px 3px 0px"
                        menuZindex={100}
                        // selectDropDown={selectDropDown}
                        // setSelectDropDown={setSelectDropDown}
                        data={["All", "SP", "SD", "SB"]}
                    />
                </Stack>
                <Stack gap={4}>
                    <CustomDropDown
                        title={"Campaign Format"}
                        filterCampaigns={filterCampaigns}
                        targettingValue={targettingType}
                        onDropdownImghandler={onDropdownImghandler}
                        src={dropdownIcon}
                        type={"objective"}
                        autoWidth={true}
                        btnBgColor="#DBD9E6"
                        borderRadius="0px 3px 3px 0px"
                        menuZindex={100}
                        // selectDropDown={selectDropDown}
                        // setSelectDropDown={setSelectDropDown}
                        data={["All", "SP", "SD", "SB"]}
                    />
                </Stack>
                <Stack gap={4}>
                    <CustomDropDown
                        title={"Targetting Type"}
                        filterCampaigns={filterCampaigns}
                        targettingValue={targettingType}
                        onDropdownImghandler={onDropdownImghandler}
                        src={dropdownIcon}
                        type={"objective"}
                        autoWidth={true}
                        btnBgColor="#DBD9E6"
                        borderRadius="0px 3px 3px 0px"
                        menuZindex={100}
                        // selectDropDown={selectDropDown}
                        // setSelectDropDown={setSelectDropDown}
                        data={["All", "SP", "SD", "SB"]}
                    />
                </Stack>
                <Stack gap={4}>
                    <CustomDropDown
                        title={"Product Set"}
                        filterCampaigns={filterCampaigns}
                        targettingValue={targettingType}
                        onDropdownImghandler={onDropdownImghandler}
                        src={dropdownIcon}
                        type={"objective"}
                        autoWidth={true}
                        btnBgColor="#DBD9E6"
                        borderRadius="0px 3px 3px 0px"
                        menuZindex={100}
                        // selectDropDown={selectDropDown}
                        // setSelectDropDown={setSelectDropDown}
                        data={["All", "SP", "SD", "SB"]}
                    />
                </Stack>
                <Stack gap={4}>
                    <CustomDropDown
                        title={"AdGroup Status"}
                        filterCampaigns={filterCampaigns}
                        targettingValue={targettingType}
                        onDropdownImghandler={onDropdownImghandler}
                        src={dropdownIcon}
                        type={"objective"}
                        autoWidth={true}
                        btnBgColor="#DBD9E6"
                        borderRadius="0px 3px 3px 0px"
                        menuZindex={100}
                        // selectDropDown={selectDropDown}
                        // setSelectDropDown={setSelectDropDown}
                        data={["All", "SP", "SD", "SB"]}
                    />
                </Stack>
                <Stack gap={4}>
                    <CustomDropDown
                        title={"Portfolio Name"}
                        filterCampaigns={filterCampaigns}
                        targettingValue={targettingType}
                        onDropdownImghandler={onDropdownImghandler}
                        src={dropdownIcon}
                        type={"objective"}
                        autoWidth={true}
                        btnBgColor="#DBD9E6"
                        borderRadius="0px 3px 3px 0px"
                        menuZindex={100}
                        // selectDropDown={selectDropDown}
                        // setSelectDropDown={setSelectDropDown}
                        data={["All", "SP", "SD", "SB"]}
                    />
                </Stack>

                <div className="filter-icon-container-main">
                    <div
                        className={
                            minMaxFilterActivated
                                ? "activated-min-max-filter"
                                : "hide"
                        }
                    ></div>
                    {/* <div className="filter-icon-container-main"> */}
                    <img
                        src={settingIcon}
                        onClick={onFilterIconhandler}
                        style={{ cursor: "pointer" }}
                        alt={"settingsIcon"}
                        className={"settings-icon"}
                    ></img>
                    {/* </div> */}
                </div>
            </div>

            <div>
                {
                    <FilterLogicModal
                        filterFor="campaignlist"
                        show={isFilterVisible}
                    ></FilterLogicModal>
                }
            </div>

            <div className="campaign-list-table-data">
                <Stack className="campaign-list-main-table">
                    <TableRb table={tableBody}></TableRb>
                </Stack>
            </div>
        </div>
    )
}

export default AdGroupComparison; 