const handleCss = () => {
    // Changing the Table body height and making all of them as same

    // document.querySelectorAll('.generic-main-table-body-container')[1].style.height = "694px";
    // document.querySelectorAll('.generic-main-table-body-container')[2].style.height = "694px";
    // document.querySelectorAll('.generic-main-table-body-container')[3].style.height = "694px";
    // document.querySelectorAll('.generic-main-table-body-container')[4].style.height = "694px";


    // Changing the buttons as Dynamically
    // let firstBtn = document.querySelectorAll('.remove-all-btn')[0];
    // firstBtn.classList.add('add-all-btn');
    // firstBtn.innerText = "Remove All"
    // let secondBtn = document.querySelectorAll('.remove-all-btn')[1];
    // secondBtn.innerText = "Remove All"

    // table-upper

    const containers = document.querySelectorAll('.generic-main-table-body-container');
    if (containers.length === 3) {
      // containers[0].style.height = "694px";
      // containers[1].style.height = "694px";
      // containers[2].style.height = "694px"; 
      containers[1].style.height = "710px";
      containers[2].style.height = "710px"; /*MS-258 table alignment point 11 */
    }

    let tableUpper = document.querySelectorAll('.float-right')[4]
    // tableUpper.style.display = "none"

    // menu icons in product targeting 

    // let menuIcon = document.querySelectorAll('.menu-icon-table')[1];
    // menuIcon.style.display = "none"


    // Text changing in the second table 

    // let secondTableFirstHeaderText = document.querySelectorAll('.table-header-text')[2];
    // secondTableFirstHeaderText.innerText = "CATEGORIES AND PRODUCTS"


    // Pie Chart Heading changin

    let allLabels = document.querySelectorAll('.apexcharts-legend-text');
    // console.log(allLabels)


}

export default handleCss;