import uploadMultipartFile from './uploadMultipartFile'
export const uploadFile = async (file, fName = "test.jpg", fType = "image/jpg") => {
    try {

        //Validations
        if (file === undefined) { throw "Please select file to upload!" }
        
        //Custom File Name
        var filename = file.name ? file.name.replaceAll(/[^a-zA-Z0-9.-_]+/g, "") : fName;
        var fileType = file.type ? file.type : fType;

        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        filename = uuid + '/' + filename;

        // ===============================================
        // This fetch obtains an uploadId generated in the backend
        // server by the AWS S3 SDK. This uploadId will be used subsequently for uploading
        // the individual chunks of the selectedFile.
        // ===============================================
        const res = await fetch(`${process.env.REACT_APP_LOGIN_API}/api/aws/start-upload?fileName=${filename}&fileType=${fileType}`);
        const uploadIdData = await res.json();

        if('uploadId' in uploadIdData) {
            const uploadId = uploadIdData.uploadId;
            const uploadResponse = await uploadMultipartFile(file, filename,fileType , uploadId);
            return uploadResponse;
        } else if('error' in uploadIdData) {
            throw uploadIdData['error'];
        }
    } catch (err) {
        return 'ERR:' + err;
    }
}