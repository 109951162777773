
export const onRowExpandHandler = (
  asin,
  sentIndex,
  currentState,
  isGlobalView
) => {
  const updatedState = currentState.map((parentAd,paIndex) => {
    if(parentAd?.asin === asin && isGlobalView) {
      const updatedChildAds  = parentAd?.childRows?.map((childAd,index) => {
        if(index === sentIndex) {
          childAd.isTargetChecked = !childAd.isTargetChecked
        }
        return childAd;
      })
      parentAd.childRows = updatedChildAds
    }
    if(parentAd.asin === asin && !isGlobalView && paIndex === sentIndex) {
      parentAd.isTargetChecked = !parentAd.isTargetChecked
    }
    return {...parentAd}
  })
  return updatedState
};
