import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import MultiSelect from "../../components/DropDown/Multiselect";
import { useGlobalContext } from "../../context/global";
import { useAuthHeader } from "react-auth-kit";
import axios from "axios";
const OptimizerFilters = (props) => {
  const authHeader = useAuthHeader();
  let token = authHeader();
  if (token.startsWith("Bearer ")) {
    token = token.slice(7);
  } 
  const { clientId } = useGlobalContext();
  const [filterKeys, setFilterskeys] = useState({})
  const replacements = {
    clientId: clientId
  }
  useEffect(()=>{
    let keys = {}
    props.filtersData.map((filter) => { return keys[filter.key] = filter.default})
    setFilterskeys(keys)
  },[props])
  const handleApply = (e, type) => {
    filterKeys[type] = e
    setFilterskeys(filterKeys)
    props.handleDropdown(e, filterKeys,type);
  };
  useEffect(()=>{
    props.filtersData.map((filter,index) => {
      // Check if there is already data present in options array then no need to call api
        if('api' in filter && filter.options.length === 0) {
            const url = dynamicReplace(filter.api.url,replacements)
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: url,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
              };
          axios.request(config).then((response) => {
            let result = response.data.data
                    .filter((item) => item && item[filter.api.key] !== undefined)
                    .map((item) => item[filter.api.key]);
            props.filtersData[index].options = result;
          });
        }
    })
  },[])

  const dynamicReplace = (str, replacements) => {
    return str.replace(/\${(.*?)}/g, (match, group) => {
      const replacementValue = replacements[group];
      return replacementValue !== undefined ? replacementValue : match;
    });
  };
  return (
    <>
      <div className="dropdownContentWrapper" style={{ marginBottom: "8px" }}>
        <div className={`d-flex ${props?.classNames ? props?.classNames : ""} gap-3 w-100 max-w-100`}>
          {props.filtersData.map((filter, index) => {
            return <div className={`filterItem ${props?.disabledFilters?.includes(filter.name) ? "disable-pointer-events" : ""}`} key={filter.name} id={filter?.id ? filter?.id : ""}>
            <Stack gap={4}>
              <MultiSelect
                title={filter.name}
                options={filter.options}
                defaultSelected={filter.default}
                onApply={(e) => handleApply(e, `${filter.key}`)}
                label={filter.name}
                isSearch={true}
                id={filter.id}
                type="single"
                placeHolder={`Search For ${filter.name}`}
                classes={" mst-negative-modal-drop-down-height"}
              />
            </Stack>
          </div>
          })
          }
        </div>
      </div>
    </>
  );
};
export default OptimizerFilters;