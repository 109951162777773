
export default function useToggleFilterModal (state) {
 function toggleModalItem (key,status) {
    const parsedData = JSON.parse(JSON.stringify(state)); 
    const updatedData = parsedData?.map((dataInstance) => {
        const newData = {...dataInstance}
        if(key.includes(newData?.key)) {
            newData.isEnabled = !status
            
        }
        return newData
    })
    return updatedData;
 }

 function inputValueChange(data) {
    const {key,value,valueKey} = data
    const parsedData = JSON.parse(JSON.stringify(state));
    return parsedData?.map((data) => {
        if(key === data?.key) {
            data[valueKey] = value
        }
        return data
    })
 }

 function toggleAll (toggleState,allData,viewType) {
    const parsedData = JSON.parse(JSON.stringify(state));
    return parsedData?.map((data) => {
        const newData = {...data}
        // const foundedObject = allData?.find((dataInstance) => dataInstance.key === data.key);
        // if(foundedObject) {
            if(newData?.isMetric) {
              newData.isEnabled = !toggleState
            }
        // }
        return newData
    })
 }
 
 return {
    toggleModalItem,
    inputValueChange,
    toggleAll
 }
}