import React, { useEffect, useState } from "react";
import "./SearchWithSelect.css"; // Import a CSS file for styling
import { Search } from "react-bootstrap-icons";

function SearchWithSelect(props) {
  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("contains");
  const [eventKey, setEventKey] = useState() 

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    props.Value({input:searchValue,select:e.target.value})
  };

  function submit() {
    props.Value({ input: searchValue, select: selectedOption });
  }

  function handleKeyDown(event) {
    setEventKey(event.keyCode)
    if (event.key === "Enter") {
      submit();
    }
  }

  useEffect(() => {
    if(eventKey === 8 && searchValue === "") {
      submit();
    }
  },[searchValue, eventKey])

  useEffect(() => {
    if (props.default && typeof props.default === "object") {
      setSearchValue(props?.default?.input);
      setSelectedOption(props?.default?.select);
    }
  }, [props]);

  const hoverMsg = selectedOption?.toLowerCase() === 'contains' ? 'retrieves results containing the search query' : 'retrieves results matching the exact search query'

  return (
    <div className={`serch-bar-container sm flex-grow-1 ${props.classNames ? props.classNames : ""}`}>
      <div className="form-group has-search w-100 position-relative">
        <span className="form-control-icon sm" onClick={submit}>
          <Search color="#4D4184" />
        </span>
        <input
          size="lg"
          type="text"
          className="form-control sm"
          placeholder="Search Here"
          value={searchValue}
          id={props?.id ? props?.id : ""}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown} // Use onKeyDown instead of onKeyPress
        />
        <span className="form-control-select sm" title={ props?.showHoverTxt ?  hoverMsg : null}>
          <select
            value={selectedOption}
            onChange={handleSelectChange}
            className="form-control sm form-control-sm select"
          >
            <option value="contains" title="retrieves results containing the search query">Contains</option>
            <option value="equals" title="retrieves results matching the exact search query">Exact</option>
          </select>
        </span>
      </div>
    </div>
  );
}

export default SearchWithSelect;
