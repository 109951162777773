import React from "react";
import { useNavigate } from "react-router-dom";
import  NotFoundImage from  '../../assets/images/NotFound.jpg'
 
const NotFound = () => {
    const navigate =  useNavigate();
    let pageImageSrc = NotFoundImage
    let pageMsg = 'This is a dead end!!!';
    let imgW = 600;
    let imgH = 400;
    
  return (
    <div className="bgimg" id="notFoundId">
      <style>{`
        .bgimg {
            /* Full-screen */
            height: 100vh;
            /* Center the background image */
            background-position: center;
            /* Scale and zoom in the image */
            background-size: cover;
            /* Add position: relative to enable absolutely positioned elements inside the image (place text) */
            position: relative;
            /* Add a white text color to all elements inside the .bgimg container */
            color: #9c9798;
            /* Add a font */
            font-family: "Courier New", Courier, monospace;
            /* Set the font-size to 25 pixels */
            font-size: 25px;
          }
          /* Position text in the middle */
          .middle {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
          }
          
          /* Style the <hr> element */
          hr {
            width: 100%;
          }
    `}</style>
      <div className="middle">
        <h1>Page Not Found</h1>
        <h1> {pageMsg}</h1>
        <hr />
        <button className="btn  btn-secondary" onClick={() => navigate(-1)}>BACK</button>
        <hr />
        <img
          src={pageImageSrc}
          width={imgW}
          height={imgH}
          alt="Page not found"
        />
      </div>
    </div>
  ); 
};

export default NotFound;
