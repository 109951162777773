import { useState, useEffect } from "react";
import SwitchBtn from "../../../Buttons/switchBtn.js";
import "./filterLogic.css";
import { useDispatch, useSelector } from "react-redux";
import { campaignListActions } from "../../../../redux/slices/campaignList.js";
import Modal from "react-bootstrap/Modal";
import closeIcon from "../../../../assets/svgs/close.svg";
export default function FilterLogicModal(props) {
  const dispatch = useDispatch();
  let defaultRangeDataForResetInputField = [
    {
      fieldName: "SPENDS",
      minRange: "",
      maxRange: "",
      id: "spends",
      status: true,
      isTwoDecimalPlacesNeeded: false,
      isRsSymbolNeeded: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "IMPRESSIONS",
      minRange: "",
      maxRange: "",
      id: "impressions",
      status: false,
      isTwoDecimalPlacesNeeded: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "CLICKS",
      minRange: "",
      maxRange: "",
      id: "clicks",
      status: false,
      isTwoDecimalPlacesNeeded: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "ORDERS",
      minRange: "",
      maxRange: "",
      id: "orders",
      status: true,
      isTwoDecimalPlacesNeeded: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "REVENUE",
      minRange: "",
      maxRange: "",
      id: "sale_value",
      status: true,
      isTwoDecimalPlacesNeeded: false,
      isRsSymbolNeeded: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "CPM",
      minRange: "",
      maxRange: "",
      id: "cpm",
      status: false,
      isTwoDecimalPlacesNeeded: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "CTR",
      minRange: "",
      maxRange: "",
      id: "ctr",
      status: false,
      isTwoDecimalPlacesNeeded: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "CPC",
      minRange: "",
      maxRange: "",
      id: "cpc",
      status: false,
      isTwoDecimalPlacesNeeded: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "CVR",
      minRange: "",
      maxRange: "",
      id: "cvr",
      status: false,
      isTwoDecimalPlacesNeeded: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "AOV",
      minRange: "",
      maxRange: "",
      id: "aov",
      status: false,
      isTwoDecimalPlacesNeeded: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "ROAS",
      minRange: "",
      maxRange: "",
      id: "roas",
      status: true,
      isTwoDecimalPlacesNeeded: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "ACOS",
      minRange: "",
      maxRange: "",
      id: "acos",
      status: false,
      isTwoDecimalPlacesNeeded: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
  ];
  let dataHead = ["Action", "Metric", "Min Range", "Max Range"];

  let defaultPermanantHeads = [
    {
      fieldName: "STATUS",
      minRange: "-",
      maxRange: "-",
      id: "status",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
    {
      fieldName: "NAME",
      minRange: "-",
      maxRange: "-",
      id: "name",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
    {
      fieldName: "Categories",
      minRange: "-",
      maxRange: "-",
      id: "campaign_categories",  
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
    {
      fieldName: "TARGETING",
      minRange: "-",
      maxRange: "-",
      id: "targetting",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
    {
      fieldName: "PORTFOLIO",
      minRange: "-",
      maxRange: "-",
      id: "portfolio",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
    {
      fieldName: "BUDGET",
      minRange: "-",
      maxRange: "-",
      id: "budget",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
    {
      fieldName: "BID STRATEGY",
      minRange: "-",
      maxRange: "-",
      id: "bid_staretegy",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
  ];

  let defaultPermanantHeadsFortarget = [
    {
      fieldName: "ACTION",
      minRange: "-",
      maxRange: "-",
      id: "status",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
    {
      fieldName: "TARGETING",
      minRange: "-",
      maxRange: "-",
      id: "name",
      isDefaultHead: true,
    },
    {
      fieldName: "ASIN",
      minRange: "-",
      maxRange: "-",
      id: "asian",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: true,
    },
    {
      fieldName: "SUGGESTED BID",
      minRange: "-",
      maxRange: "-",
      id: "suggested_bid",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
    {
      fieldName: "CURRENT BID",
      minRange: "-",
      maxRange: "-",
      id: "current-bid",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
  ];

  let defaultPermanantHeadsForProductTarget = [
    {
      fieldName: "ACTION",
      minRange: "-",
      maxRange: "-",
      id: "status",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
    {
      fieldName: "PRODUCT",
      minRange: "-",
      maxRange: "-",
      id: "name",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
    {
      fieldName: "ASIN",
      minRange: "-",
      maxRange: "-",
      id: "asian",
      isDefaultHead: true,
      isAbbrivation: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
    {
      fieldName: "SUGGESTED BID",
      minRange: "-",
      maxRange: "-",
      id: "suggested_bid",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
    {
      fieldName: "CURRENT BID",
      minRange: "-",
      maxRange: "-",
      id: "current-bid",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
  ];

  let defaultPermanantHeadsForEditAds = [
    {
      fieldName: "ACTION",
      minRange: "-",
      maxRange: "-",
      id: "status",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
    {
      fieldName: "PRODUCT TARGETING",
      minRange: "-",
      maxRange: "-",
      id: "name",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
    {
      fieldName: "ASIN's",
      minRange: "-",
      maxRange: "-",
      id: "targetting",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
  ];

  let defaultPermanantHeadsForEditSearchTerm = [
    {
      fieldName: "NEGATIVE TARGETING",
      minRange: "-",
      maxRange: "-",
      id: "status",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
    {
      fieldName: "TARGETING",
      minRange: "-",
      maxRange: "-",
      id: "name",
      isDefaultHead: true,
      isHeadRightAlign: false,
      isHeadWithIcon: false,
      isAbbrivation: false,
    },
    // {
    // fieldName: "Match Type",
    // minRange: '-',
    // maxRange: '-',
    // id: "targetting",
    // isDefaultHead: true
    // },
  ];

  let defaultPermanantHeadsForMatserTargeting = [
    {
      fieldName: "ACTION",
      minRange: "-",
      maxRange: "-",
      id: "status",
      isDefaultHead: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "TARGETING SELECTED",
      minRange: "-",
      maxRange: "-",
      id: "name",
      isDefaultHead: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "TARGETING TYPE",
      minRange: "-",
      maxRange: "-",
      id: "targeting_type",
      isDefaultHead: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "PORTFOLIO",
      minRange: "-",
      maxRange: "-",
      id: "mt_portfolio",
      isDefaultHead: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "CAMPAIGNS",
      minRange: "-",
      maxRange: "-",
      id: "mt_campaign_name",
      isDefaultHead: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "AD GROUP",
      minRange: "-",
      maxRange: "-",
      id: "mt_adgroup_name",
      isDefaultHead: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "SUGG BID",
      minRange: "-",
      maxRange: "-",
      id: "mt_sugg_bid",
      isDefaultHead: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "CURRENT BID",
      minRange: "-",
      maxRange: "-",
      id: "mmt_currennt_bid",
      isDefaultHead: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
  ];

  let defaultPermanantHeadsForMasterAds = [
    {
      fieldName: "ACTION",
      minRange: "-",
      maxRange: "-",
      id: "status",
      isDefaultHead: true,
      isSortingClicked: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Product",
      minRange: "-",
      maxRange: "-",
      id: "product_name",
      isDefaultHead: true,
      isSortingClicked: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "ASIN",
      minRange: "-",
      maxRange: "-",
      id: "asin",
      isDefaultHead: true,
      isSortingClicked: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Portfolio",
      minRange: "-",
      maxRange: "-",
      id: "portfolio",
      isDefaultHead: true,
      isSortingClicked: false,
      isFindTaskActive: false,
      isSearchNeededHere: true,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Campaign",
      minRange: "-",
      maxRange: "-",
      id: "mt_campaign_name",
      isDefaultHead: true,
      isSortingClicked: null,
      isFindTaskActive: false,
      isSearchNeededHere: true,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Ad Group",
      minRange: "-",
      maxRange: "-",
      isDefaultHead: true,
      id: "mt_adgroup_name",
      isSortingClicked: null,
      isFindTaskActive: false,
      isSearchNeededHere: true,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Category",
      minRange: "-",
      maxRange: "-",
      isDefaultHead: true,
      id: "mt_category_name",
      isSortingClicked: null,
      isFindTaskActive: false,
      isSearchNeededHere: true,
      isSortFunctionalityNeededHere: false,
    },
  ];

  let defaultPermanentHeadsForMasterSearchTerm = [
    {
      fieldName: "Search Term",
      minRange: "-",
      maxRange: "-",
      id: "search_term",
      isDefaultHead: true,
      isSortingClicked : false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Targeting",
      minRange: "-",
      maxRange: "-",
      id: "text",
      isDefaultHead: true,
      isSortingClicked : false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Targeting Type",
      minRange: "-",
      maxRange: "-",
      id: "targeting",
      isDefaultHead: true,
      isSortingClicked : false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Match Type",
      minRange: "-",
      maxRange: "-",
      id: "match_type",
      isDefaultHead: true,
      isSortingClicked : false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Portfolio",
      minRange: "-",
      maxRange: "-",
      id: "portfolio",
      isDefaultHead: true,
      isSortingClicked : false,
      isFindTaskActive: false,
      isSearchNeededHere:true,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Campaign",
      minRange: "-",
      maxRange: "-",
      id: "mt_campaign_name",
      isDefaultHead: true,
      isSortingClicked : null,
      isFindTaskActive: false,
      isSearchNeededHere:true,
      isSortFunctionalityNeededHere: false,

    },
    {
      fieldName: "Ad Group",
      minRange: "-",
      maxRange: "-",
      isDefaultHead: true,
      id: "mt_adgroup_name",
      isSortingClicked : null,
      isFindTaskActive: false,
      isSearchNeededHere:true,
      isSortFunctionalityNeededHere: false,
      
    },
  ]

  let defaultPermanentHeadsForSmartAdsList = [
    {
      "fieldName": "Product Set",
      "minRange": "-",
      "maxRange": "-",
      "id": "product_set",
      "isDefaultHead": true,
      "isHeadRightAlign": false,
      "isHeadWithIcon": false,
      "isAbbrivation": false
  },
  // {
  //     "fieldName": "Active Campaigns",
  //     "minRange": "-",
  //     "maxRange": "-",
  //     "id": "active_campaigns",
  //     // "isDefaultHead": true,
  //     "isHeadRightAlign": false,
  //     "isHeadWithIcon": "sort",
  //     "isAbbrivation": false,
  //     "isFindTaskActive": false,
  //     "status": false,
  //     "isSortFunctionalityNeededHere": true

  // },
  // {
  //     "fieldName": "Active AdGroups",
  //     "minRange": "-",
  //     "maxRange": "-",
  //     "id": "active_adgroups",
  //     // "isDefaultHead": true,
  //     "isHeadRightAlign": false,
  //     "isHeadWithIcon": "sort",
  //     "isAbbrivation": false,
  //     "isFindTaskActive": false,
  //     "status": false,
  //     "isSortFunctionalityNeededHere": true

  // },
  {
      "fieldName": "Refresh Frequency",
      "minRange": "-",
      "maxRange": "-",
      "id": "refresh_frequency",
      // "isDefaultHead": true,
      "isHeadRightAlign": false,
      "isHeadWithIcon": false,
      "isAbbrivation": false,
      "isFindTaskActive": false,
      "status": false,
      "isSortFunctionalityNeededHere": true

  },
  {
      "fieldName": "Type",
      "minRange": "-",
      "maxRange": "-",
      "id": "type",
      // "isDefaultHead": true,
      "isHeadRightAlign": false,
      "isHeadWithIcon": false,
      "isAbbrivation": false,
      "isSortFunctionalityNeededHere": false

  },
  ]

  const dbrMetricesForCL = [
    {
      fieldName: "DBR",
      minRange: 0,
      maxRange: 0,
      id: "dbr",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "Avg. BR",
      minRange: 0,
      maxRange: 0,
      id: "campaign_burn_rate",
      status: false,
      isHeadRightAlign : true,
      isHeadWithIcon : "sort",
      isAbbrivation: true,
    },
  ]

  const [updateCampaignListFilter, setUpdatedCampaignListFilter] = useState([
    // {
    //   fieldName: "SPENDS",
    //   minRange: "",
    //   maxRange: "",
    //   id: "spends",
    //   status: true,
    {
      fieldName: "SPENDS",
      minRange: 0,
      maxRange: 0,
      id: "spends",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "ORDERS",
      minRange: 0,
      maxRange: 0,
      id: "orders",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "REVENUE",
      minRange: 0,
      maxRange: 0,
      id: "sale_value",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },

    {
      fieldName: "ROAS",
      minRange: 0,
      maxRange: 0,
      id: "roas",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
  ]);
  // const [updateCampaignListFilterForTarget, setUpdateCampaignListFilterForTarget] = useState([{
  // fieldName: "SPENDS",
  // minRange: '-',
  // maxRange: '-',
  // id: "spends",
  // status: true,
  // },
  // {
  // fieldName: "ORDERS",
  // minRange: '-',
  // maxRange: '-',
  // id: "orders",
  // status: true,
  // }, {
  // fieldName: "REVENUE",
  // minRange: '-',
  // maxRange: '-',
  // id: "sale_value",
  // status: true,
  // }, {
  // fieldName: "ROAS",
  // minRange: '-',
  // maxRange: '-',
  // id: "roas",
  // status: true,
  // },]);
  const [isTogglebtndisableText, setIsTogglebtndisableText] = useState(false);
  const [
    defaultCampaignListFilterCopyForReset,
    setDefaultCampaignListFilterCopyForReset,
  ] = useState([
    {
      fieldName: "SPENDS",
      minRange: "-",
      maxRange: "-",
      id: "spends",
      status: true,
      isTwoDecimalPlacesNeeded: false,
      isRsSymbolNeeded: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "ORDERS",
      minRange: "-",
      maxRange: "-",
      id: "orders",
      status: true,
      isTwoDecimalPlacesNeeded: false,
      isRsSymbolNeeded: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadWithIcon: "sort",
      isHeadRightAlign: true,
      isAbbrivation: false,
    },
    {
      fieldName: "REVENUE",
      minRange: "-",
      maxRange: "-",
      id: "sale_value",
      status: true,
      isTwoDecimalPlacesNeeded: false,
      isRsSymbolNeeded: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "ROAS",
      minRange: "-",
      maxRange: "-",
      id: "roas",
      status: true,
      isTwoDecimalPlacesNeeded: true,
      isRsSymbolNeeded: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
  ]);
  const getUpdatedConfigHeadsForCampaignListSection = useSelector(
    (state) =>
      state.campaignListData.getUpdatedConfigHeadsForCampaignListSection
  );
  const getUpdatedConfigHeadsForEditAdsetTargetListSection = useSelector(
    (state) =>
      state.campaignListData.getUpdatedConfigHeadsForEditAdsetTargetListSection
  );
  const getUpdatedConfigHeadsForEditAdsetProductTargetListSection = useSelector(
    (state) =>
      state.campaignListData
        .getUpdatedConfigHeadsForEditAdsetProductTargetListSection
  );
  const getUpdatedConfigHeadsForEditAdsSection = useSelector(
    (state) => state.campaignListData.getUpdatedConfigHeadsForEditAdsSection
  );
  const getUpdatedConfigHeadsForEditSearchTermListSection = useSelector(
    (state) =>
      state.campaignListData.getUpdatedConfigHeadsForEditSearchTermListSection
  );
  const getUpdatedConfigHeadsForMasterTargetingListSection = useSelector(
    (state) =>
      state.campaignListData.getUpdatedConfigHeadsForMasterTargetingListSection
  );
  const getUpdatedConfigHeadsForMasterAdsSection = useSelector(
    (state) => state.campaignListData.getUpdatedConfigHeadsForMasterAdsSection
  );
  const getUpdatedConfigHeadsForMasterSearchTermSection = useSelector((state) => state.campaignListData.getUpdatedConfigHeadsForMasterSearchTermSection)
  const getUpdatedConfigHeadsForSmartAdsList = useSelector(
    (state) =>
      state.campaignListData.getUpdatedConfigHeadsForSmartAdsList
  );
  const appliedFilterData = useSelector(
    (state) => state.campaignListData.appliedFilterData
  );
  const [isNumberInputFieldDisable, setisNumberInputFieldDisable] = useState(
    []
  );
  const [dataForTable, setDataForTable] = useState([]);
  const [
    selectedFilterForDefineSwitchState,
    setselectedFilterForDefineSwitchState,
  ] = useState([]);
  const targetingTypeForEditTargetSection = useSelector(
    (state) => state.campaignListData.targetingTypeForEditTargetSection
  );

  const [rangeDataForTable, setRangeDataForTable] = useState([
    {
      fieldName: "SPENDS",
      minRange: "",
      maxRange: "",
      id: "spends",
      status: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "IMPRESSIONS",
      minRange: "",
      maxRange: "",
      id: "impressions",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "CLICKS",
      minRange: "",
      maxRange: "",
      id: "clicks",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "ORDERS",
      minRange: "",
      maxRange: "",
      id: "orders",
      status: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "REVENUE",
      minRange: "",
      maxRange: "",
      id: "sale_value",
      status: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "CPM",
      minRange: "",
      maxRange: "",
      id: "cpm",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "CTR",
      minRange: "",
      maxRange: "",
      id: "ctr",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "CPC",
      minRange: "",
      maxRange: "",
      id: "cpc",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "CVR",
      minRange: "",
      maxRange: "",
      id: "cvr",
      status: false,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
      isHeadRightAlign: true,
    },
    {
      fieldName: "AOV",
      minRange: "",
      maxRange: "",
      id: "aov",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "ROAS",
      minRange: "",
      maxRange: "",
      id: "roas",
      status: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "ACOS",
      minRange: "",
      maxRange: "",
      id: "acos",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
  ]);

  const [rangeDataForTargetTable, setRangeDataForTargetTable] = useState([
    {
      fieldName: "SPENDS",
      minRange: "",
      maxRange: "",
      id: "spends",
      status: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "IMPRESSIONS",
      minRange: "",
      maxRange: "",
      id: "impressions",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "CLICKS",
      minRange: "",
      maxRange: "",
      id: "clicks",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "ORDERS",
      minRange: "",
      maxRange: "",
      id: "orders",
      status: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "REVENUE",
      minRange: "",
      maxRange: "",
      id: "sale_value",
      status: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "CPM",
      minRange: "",
      maxRange: "",
      id: "cpm",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "CTR",
      minRange: "",
      maxRange: "",
      id: "ctr",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "CPC",
      minRange: "",
      maxRange: "",
      id: "cpc",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "CVR",
      minRange: "",
      maxRange: "",
      id: "cvr",
      status: false,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
      isHeadRightAlign: true,
    },
    {
      fieldName: "AOV",
      minRange: "",
      maxRange: "",
      id: "aov",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "ROAS",
      minRange: "",
      maxRange: "",
      id: "roas",
      status: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "ACOS",
      minRange: "",
      maxRange: "",
      id: "acos",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
  ]);
  const [rangeDataForEditAdsTable, setRangeDataForEditAdsTable] = useState([
    {
      fieldName: "SPENDS",
      minRange: "",
      maxRange: "",
      id: "spends",
      status: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "IMPRESSIONS",
      minRange: "",
      maxRange: "",
      id: "impressions",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "CLICKS",
      minRange: "",
      maxRange: "",
      id: "clicks",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "ORDERS",
      minRange: "",
      maxRange: "",
      id: "orders",
      status: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "REVENUE",
      minRange: "",
      maxRange: "",
      id: "sale_value",
      status: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "CPM",
      minRange: "",
      maxRange: "",
      id: "cpm",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "CTR",
      minRange: "",
      maxRange: "",
      id: "ctr",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "CPC",
      minRange: "",
      maxRange: "",
      id: "cpc",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "CVR",
      minRange: "",
      maxRange: "",
      id: "cvr",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "AOV",
      minRange: "",
      maxRange: "",
      id: "aov",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "ROAS",
      minRange: "",
      maxRange: "",
      id: "roas",
      status: true,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "ACOS",
      minRange: "",
      maxRange: "",
      id: "acos",
      status: false,
      isHeadRightAlign: true,
      isHeadWithIcon: "sort",
      isAbbrivation: true,
    },
  ]);

  const [rangeDataForEditSearchTermTable, setRangeDataForEditSearchTermTable] =
    useState([
      {
        fieldName: "SPENDS",
        minRange: "",
        maxRange: "",
        id: "spends",
        status: true,
        isHeadRightAlign: true,
        isHeadWithIcon: "sort",
        isAbbrivation: false,
      },
      {
        fieldName: "IMPRESSIONS",
        minRange: "",
        maxRange: "",
        id: "impressions",
        status: false,
        isHeadRightAlign: true,
        isHeadWithIcon: "sort",
        isAbbrivation: false,
      },
      {
        fieldName: "CLICKS",
        minRange: "",
        maxRange: "",
        id: "clicks",
        status: false,
        isHeadRightAlign: true,
        isHeadWithIcon: "sort",
        isAbbrivation: false,
      },
      {
        fieldName: "ORDERS",
        minRange: "",
        maxRange: "",
        id: "orders",
        status: true,
        isHeadRightAlign: true,
        isHeadWithIcon: "sort",
        isAbbrivation: false,
      },
      {
        fieldName: "REVENUE",
        minRange: "",
        maxRange: "",
        id: "sale_value",
        status: true,
        isHeadRightAlign: true,
        isHeadWithIcon: "sort",
        isAbbrivation: false,
      },
      {
        fieldName: "CPM",
        minRange: "",
        maxRange: "",
        id: "cpm",
        status: false,
        isHeadRightAlign: true,
        isHeadWithIcon: "sort",
        isAbbrivation: true,
      },
      {
        fieldName: "CTR",
        minRange: "",
        maxRange: "",
        id: "ctr",
        status: false,
        isHeadRightAlign: true,
        isHeadWithIcon: "sort",
        isAbbrivation: true,
      },
      {
        fieldName: "CPC",
        minRange: "",
        maxRange: "",
        id: "cpc",
        status: false,
        isHeadRightAlign: true,
        isHeadWithIcon: "sort",
        isAbbrivation: true,
      },
      {
        fieldName: "CVR",
        minRange: "",
        maxRange: "",
        id: "cvr",
        status: false,
        isHeadWithIcon: "sort",
        isAbbrivation: true,
        isHeadRightAlign: true,
      },
      {
        fieldName: "AOV",
        minRange: "",
        maxRange: "",
        id: "aov",
        status: false,
        isHeadRightAlign: true,
        isHeadWithIcon: "sort",
        isAbbrivation: true,
      },
      {
        fieldName: "ROAS",
        minRange: "",
        maxRange: "",
        id: "roas",
        status: true,
        isHeadRightAlign: true,
        isHeadWithIcon: "sort",
        isAbbrivation: true,
      },
      {
        fieldName: "ACOS",
        minRange: "",
        maxRange: "",
        id: "acos",
        status: false,
        isHeadRightAlign: true,
        isHeadWithIcon: "sort",
        isAbbrivation: true,
      },
    ]);

  const [rangeDataForMasterTargetingTable, setRangeDataMasterTargetingTable] =
    useState([
      {
        fieldName: "SPENDS",
        minRange: "",
        maxRange: "",
        id: "spends",
        status: true,
        isFindTaskActive: false,
        isSortFunctionalityNeededHere: true,
        isTwoDecimalPlacesNeeded: false,
        isRsSymbolNeeded: true,
        isAbbrivation: false,
      },
      {
        fieldName: "IMPRESSIONS",
        minRange: "",
        maxRange: "",
        id: "impressions",
        status: false,
        isFindTaskActive: false,
        isSortFunctionalityNeededHere: true,
        isTwoDecimalPlacesNeeded: false,
        isAbbrivation: false,
      },
      {
        fieldName: "CLICKS",
        minRange: "",
        maxRange: "",
        id: "clicks",
        status: false,
        isFindTaskActive: false,
        isSortFunctionalityNeededHere: true,
        isTwoDecimalPlacesNeeded: false,
        isAbbrivation: false,
      },
      {
        fieldName: "ORDERS",
        minRange: "",
        maxRange: "",
        id: "orders",
        status: true,
        isFindTaskActive: false,
        isSortFunctionalityNeededHere: true,
        isTwoDecimalPlacesNeeded: false,
        isAbbrivation: false,
      },
      {
        fieldName: "REVENUE",
        minRange: "",
        maxRange: "",
        id: "sale_value",
        status: true,
        isFindTaskActive: false,
        isSortFunctionalityNeededHere: true,
        isTwoDecimalPlacesNeeded: false,
        isRsSymbolNeeded: true,
        isAbbrivation: false,
      },
      {
        fieldName: "CPM",
        minRange: "",
        maxRange: "",
        id: "cpm",
        status: false,
        isFindTaskActive: false,
        isSortFunctionalityNeededHere: true,
        isTwoDecimalPlacesNeeded: true,
        isAbbrivation: true,
      },
      {
        fieldName: "CTR",
        minRange: "",
        maxRange: "",
        id: "ctr",
        status: false,
        isFindTaskActive: false,
        isSortFunctionalityNeededHere: true,
        isTwoDecimalPlacesNeeded: true,
        isAbbrivation: true,
      },
      {
        fieldName: "CPC",
        minRange: "",
        maxRange: "",
        id: "cpc",
        status: false,
        isFindTaskActive: false,
        isSortFunctionalityNeededHere: true,
        isTwoDecimalPlacesNeeded: true,
        isAbbrivation: true,
      },
      {
        fieldName: "CVR",
        minRange: "",
        maxRange: "",
        id: "cvr",
        status: false,
        isFindTaskActive: false,
        isHeadRightAlign: true,
        isSortFunctionalityNeededHere: true,
        isTwoDecimalPlacesNeeded: true,
        isAbbrivation: true,
      },
      {
        fieldName: "AOV",
        minRange: "",
        maxRange: "",
        id: "aov",
        status: false,
        isFindTaskActive: false,
        isSortFunctionalityNeededHere: true,
        isTwoDecimalPlacesNeeded: false,
        isAbbrivation: true,
      },
      {
        fieldName: "ROAS",
        minRange: "",
        maxRange: "",
        id: "roas",
        status: true,
        isFindTaskActive: false,
        isSortFunctionalityNeededHere: true,
        isTwoDecimalPlacesNeeded: true,
        isAbbrivation: true,
      },
      {
        fieldName: "ACOS",
        minRange: "",
        maxRange: "",
        id: "acos",
        status: false,
        isFindTaskActive: false,
        isSortFunctionalityNeededHere: true,
        isTwoDecimalPlacesNeeded: true,
        isAbbrivation: true,
      },
    ]);

  const additionalDataForMasterAds = [   
    {
    fieldName: "MRP",
    minRange: "",
    maxRange: "",
    id: "maximum_retail_price",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: true,
  },
  {
    fieldName: "SP",
    minRange: "",
    maxRange: "",
    id: "sale_price",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: true,
  },
  {
    fieldName: "DISCOUNT %",
    minRange: "",
    maxRange: "",
    id: "discount_percentage",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: true,
  },
  {
    fieldName: "Total units sold",
    minRange: "",
    maxRange: "",
    id: "total_units_sold",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: true,
  },
  {
    fieldName: "ASP",
    minRange: "",
    maxRange: "",
    id: "total_asp",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: true,
  },
  // {
  //   fieldName: "Organic Sales %",
  //   minRange: "",
  //   maxRange: "",
  //   id: "organic_sales_percentage",
  //   status: false,
  //   isFindTaskActive: false,
  //   isSortFunctionalityNeededHere: true,
  //   isTwoDecimalPlacesNeeded: true,
  // },
  {
    fieldName: "TROAS",
    minRange: "",
    maxRange: "",
    id: "troas",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: true,
  },
  {
    fieldName: "TACOS",
    minRange: "",
    maxRange: "",
    id: "tacos",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: true,
  },
  {
    fieldName: "Total Revenue",
    minRange: "",
    maxRange: "",
    id: "total_revenue",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: true,
  },
  {
    fieldName: "Available Inventory",
    minRange: "",
    maxRange: "",
    id: "quantity",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: true,
  },

]

const initialRangeDataForMasterAds = [
  {
    fieldName: "SPENDS",
    minRange: "",
    maxRange: "",
    id: "spends",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: false,
    isRsSymbolNeeded: true,
  },
  {
    fieldName: "IMPRESSIONS",
    minRange: "",
    maxRange: "",
    id: "impressions",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: false,
  },
  {
    fieldName: "CLICKS",
    minRange: "",
    maxRange: "",
    id: "clicks",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: false,
  },
  {
    fieldName: "ORDERS",
    minRange: "",
    maxRange: "",
    id: "orders",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: false,
  },
  {
    fieldName: "REVENUE",
    minRange: "",
    maxRange: "",
    id: "sale_value",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: false,
    isRsSymbolNeeded: true,
  },
  {
    fieldName: "CPM",
    minRange: "",
    maxRange: "",
    id: "cpm",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: true,
  },
  {
    fieldName: "CTR",
    minRange: "",
    maxRange: "",
    id: "ctr",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: true,
  },
  {
    fieldName: "CPC",
    minRange: "",
    maxRange: "",
    id: "cpc",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: true,
  },
  {
    fieldName: "CVR",
    minRange: "",
    maxRange: "",
    id: "cvr",
    status: true,
    isFindTaskActive: false,
    isHeadRightAlign: true,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: true,
  },
  {
    fieldName: "AOV",
    minRange: "",
    maxRange: "",
    id: "aov",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: false,
  },
  {
    fieldName: "ROAS",
    minRange: "",
    maxRange: "",
    id: "roas",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: true,
  },
  {
    fieldName: "ACOS",
    minRange: "",
    maxRange: "",
    id: "acos",
    status: true,
    isFindTaskActive: false,
    isSortFunctionalityNeededHere: true,
    isTwoDecimalPlacesNeeded: true,
  },
]

  const [rangeDataForMasterAds, setRangeDataForMasterAds] = useState([
    ...initialRangeDataForMasterAds,
    ...additionalDataForMasterAds
  ]);

  const [rangeDataForMasterSearchTerm,setRangeDataForMasterSearchTerm] = useState([
    {
      fieldName: "SPENDS",
      minRange: "",
      maxRange: "",
      id: "spends",
      status: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: false,
      isRsSymbolNeeded: true,
    },
    {
      fieldName: "IMPRESSIONS",
      minRange: "",
      maxRange: "",
      id: "impressions",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: false,
    },
    {
      fieldName: "CLICKS",
      minRange: "",
      maxRange: "",
      id: "clicks",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: false,
    },
    {
      fieldName: "ORDERS",
      minRange: "",
      maxRange: "",
      id: "orders",
      status: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: false,
    },
    {
      fieldName: "REVENUE",
      minRange: "",
      maxRange: "",
      id: "sale_value",
      status: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: false,
      isRsSymbolNeeded: true,
    },
    {
      fieldName: "CPM",
      minRange: "",
      maxRange: "",
      id: "cpm",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: true,
    },
    {
      fieldName: "CTR",
      minRange: "",
      maxRange: "",
      id: "ctr",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: true,
    },
    {
      fieldName: "CPC",
      minRange: "",
      maxRange: "",
      id: "cpc",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: true,
    },
    {
      fieldName: "CVR",
      minRange: "",
      maxRange: "",
      id: "cvr",
      status: false,
      isFindTaskActive: false,
      isHeadRightAlign: true,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: true,
    },
    {
      fieldName: "AOV",
      minRange: "",
      maxRange: "",
      id: "aov",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: false,
    },
    {
      fieldName: "ROAS",
      minRange: "",
      maxRange: "",
      id: "roas",
      status: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: true,
    },
    {
      fieldName: "ACOS",
      minRange: "",
      maxRange: "",
      id: "acos",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: true,
    },
  ])
  const [rangeDataForSmartAdsList,setRangeDataForSmartAdsList] = useState([
    {
      fieldName: "SPENDS",
      minRange: "",
      maxRange: "",
      id: "spends",
      status: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: false,
      isRsSymbolNeeded: true,
    },
    {
      fieldName: "IMPRESSIONS",
      minRange: "",
      maxRange: "",
      id: "impressions",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: false,
    },
    {
      fieldName: "CLICKS",
      minRange: "",
      maxRange: "",
      id: "clicks",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: false,
    },
    {
      fieldName: "ORDERS",
      minRange: "",
      maxRange: "",
      id: "orders",
      status: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: false,
    },
    {
      fieldName: "REVENUE",
      minRange: "",
      maxRange: "",
      id: "sale_value",
      status: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: false,
      isRsSymbolNeeded: true,
    },
    {
      fieldName: "CPM",
      minRange: "",
      maxRange: "",
      id: "cpm",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: true,
    },
    {
      fieldName: "CTR",
      minRange: "",
      maxRange: "",
      id: "ctr",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: true,
    },
    {
      fieldName: "CPC",
      minRange: "",
      maxRange: "",
      id: "cpc",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: true,
    },
    {
      fieldName: "CVR",
      minRange: "",
      maxRange: "",
      id: "cvr",
      status: false,
      isFindTaskActive: false,
      isHeadRightAlign: true,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: true,
    },
    {
      fieldName: "AOV",
      minRange: "",
      maxRange: "",
      id: "aov",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: false,
    },
    {
      fieldName: "ROAS",
      minRange: "",
      maxRange: "",
      id: "roas",
      status: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: true,
    },
    {
      fieldName: "ACOS",
      minRange: "",
      maxRange: "",
      id: "acos",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: true,
    },
  ])

  useEffect(() => {
    if (props.filterFor === "campaignlist") {
      setselectedFilterForDefineSwitchState(
        getUpdatedConfigHeadsForCampaignListSection
      );
    } else if (props.filterFor === "edit-targetting") {
      if (targetingTypeForEditTargetSection === "amz_keyword_targeting") {
        setselectedFilterForDefineSwitchState(
          getUpdatedConfigHeadsForEditAdsetTargetListSection
        );
      } else if (
        targetingTypeForEditTargetSection === "amz_product_targeting"
      ) {
        setselectedFilterForDefineSwitchState(
          getUpdatedConfigHeadsForEditAdsetProductTargetListSection
        );
      }
    } else if (props.filterFor === "edit-ads") {
      setselectedFilterForDefineSwitchState(
        getUpdatedConfigHeadsForEditAdsSection
      );
    } else if (props.filterFor === "edit-search-term") {
      setselectedFilterForDefineSwitchState(
        getUpdatedConfigHeadsForEditSearchTermListSection
      );
    } else if (props.filterFor === "master-targeting-list") {
      setselectedFilterForDefineSwitchState(
        getUpdatedConfigHeadsForMasterTargetingListSection
      );
    } else if (props.filterFor === "master-ads-list") {
      setselectedFilterForDefineSwitchState(
        getUpdatedConfigHeadsForMasterAdsSection
      );
    } else if(props.filterFor === "master-search-term") {
      setselectedFilterForDefineSwitchState(getUpdatedConfigHeadsForMasterSearchTermSection)
    } else if(props.filterFor === "smartAdsList") {
      setselectedFilterForDefineSwitchState(getUpdatedConfigHeadsForSmartAdsList)
    }
  }, [
    getUpdatedConfigHeadsForCampaignListSection,
    getUpdatedConfigHeadsForEditAdsetTargetListSection,
    getUpdatedConfigHeadsForEditAdsetProductTargetListSection,
    targetingTypeForEditTargetSection,
    getUpdatedConfigHeadsForEditAdsSection,
    getUpdatedConfigHeadsForEditSearchTermListSection,
    getUpdatedConfigHeadsForMasterTargetingListSection,
    getUpdatedConfigHeadsForMasterAdsSection,
    getUpdatedConfigHeadsForMasterSearchTermSection,
    getUpdatedConfigHeadsForSmartAdsList
  ]);

  useEffect(() => {
    let copyupdateCampaignListFilter = JSON.stringify(updateCampaignListFilter);
    copyupdateCampaignListFilter = JSON.parse(copyupdateCampaignListFilter);
    let cancelSpecifiNumberFilter = props.cancelSpecifiNumberFilter;
    let currentObject;
    if (
      cancelSpecifiNumberFilter !== "" &&
      cancelSpecifiNumberFilter !== null
    ) {
      let dataForTableCopy = dataForTable.map((a) => {
        return { ...a };
      });
      if (cancelSpecifiNumberFilter === "RemoveAllRanges") {
        dataForTableCopy.map((currentObj) => {
          currentObj.minRange = "";
          currentObj.maxRange = "";
        });
        setUpdatedCampaignListFilter([]);
        dispatch(campaignListActions.appliedFilterData([]));
      } else if(cancelSpecifiNumberFilter === "RemoveSpecificFilters" || cancelSpecifiNumberFilter === "RemoveSpecialFilters") {
        const {specificFiltersKeys} = props
        dataForTableCopy.map((currObj) => {
          const specificFilter = specificFiltersKeys.includes(currObj.id)
          if(specificFilter) {
            currObj.minRange = "";
            currObj.maxRange = "";
          }
          return currObj
        })
        copyupdateCampaignListFilter = copyupdateCampaignListFilter?.filter((data) => {
          return !specificFiltersKeys.includes(data?.id)
        })
        // dispatch(
        //   campaignListActions.appliedFilterData(copyupdateCampaignListFilter)
        // );
      } else {
        dataForTableCopy.map((currentObj) => {
          if (currentObj.id === cancelSpecifiNumberFilter) {
            currentObj.minRange = "";
            currentObj.maxRange = "";
            // currentObject = currentObj
          }
        });
        for (let u = 0; u < copyupdateCampaignListFilter.length; u++) {
          if (
            copyupdateCampaignListFilter[u].id &&
            copyupdateCampaignListFilter[u].id == cancelSpecifiNumberFilter
          ) {
            // copyupdateCampaignListFilter.splice(u, 1);
            copyupdateCampaignListFilter[u].minRange = "";
            copyupdateCampaignListFilter[u].maxRange = "";
       
          }
        }
        setUpdatedCampaignListFilter(copyupdateCampaignListFilter);
        dispatch(
          campaignListActions.appliedFilterData(copyupdateCampaignListFilter)
        );
      }
      setDataForTable(dataForTableCopy);
    }
  }, [props.cancelSpecifiNumberFilter]);

  useEffect(() => {
    if (props.filterFor === "campaignlist") {
      setDataForTable(rangeDataForTable);
    }
    if (props.filterFor === "edit-targetting") {
      setDataForTable(rangeDataForTargetTable);
    }
    if (props.filterFor === "edit-ads") {
      setDataForTable(rangeDataForEditAdsTable);
    }
    if (props.filterFor === "edit-search-term") {
      setDataForTable(rangeDataForEditSearchTermTable);
    }
    if (props.filterFor === "master-targeting-list") {
      setDataForTable(rangeDataForMasterTargetingTable);
    }
    if (props.filterFor === "master-ads-list") {
      setDataForTable(rangeDataForMasterAds);
    }
    if(props.filterFor === "master-search-term") {
      setDataForTable(rangeDataForMasterSearchTerm)
    }
    if(props.filterFor === "smartAdsList") {
      setDataForTable(rangeDataForSmartAdsList)
    }
  }, [
    rangeDataForTable,
    rangeDataForTargetTable,
    rangeDataForEditAdsTable,
    rangeDataForEditSearchTermTable,
    rangeDataForMasterTargetingTable,
    rangeDataForMasterAds,
    rangeDataForMasterSearchTerm,
    rangeDataForSmartAdsList
  ]);
  const updateHandler = () => {
    if (props.filterFor === "campaignlist") {
      let finalHeadsToShow = [
        ...defaultPermanantHeads,
        ...dbrMetricesForCL,
        ...updateCampaignListFilter,
      ];
      dispatch(
        campaignListActions.getUpdatedConfigHeadsForCampaignListSection(
          finalHeadsToShow
        )
      );
      dispatch(campaignListActions.appliedFilterData(finalHeadsToShow));
    }
    if (props.filterFor === "edit-targetting") {
      let finalHeadsToShowForTarget;

      if (targetingTypeForEditTargetSection === "amz_keyword_targeting") {
        finalHeadsToShowForTarget = [
          ...defaultPermanantHeadsFortarget,
          ...updateCampaignListFilter,
        ];

        dispatch(
          campaignListActions.getUpdatedConfigHeadsForEditAdsetTargetListSection(
            finalHeadsToShowForTarget
          )
        );
        dispatch(
          campaignListActions.appliedFilterData(updateCampaignListFilter)
        );
      }
      if (targetingTypeForEditTargetSection === "amz_product_targeting") {
        finalHeadsToShowForTarget = [
          ...defaultPermanantHeadsForProductTarget,
          ...updateCampaignListFilter,
        ];
        dispatch(
          campaignListActions.getUpdatedConfigHeadsForEditAdsetProductTargetListSection(
            finalHeadsToShowForTarget
          )
        );
        dispatch(
          campaignListActions.appliedFilterData(updateCampaignListFilter)
        );
      }
    }
    if (props.filterFor === "edit-ads") {
      let finalHeadsToShowForEditAds = [
        ...defaultPermanantHeadsForEditAds,
        ...updateCampaignListFilter,
      ];

      dispatch(
        campaignListActions.getUpdatedConfigHeadsForEditAdsSection(
          finalHeadsToShowForEditAds
        )
      );
      dispatch(campaignListActions.appliedFilterData(updateCampaignListFilter));
    } else if (props.filterFor === "edit-search-term") {
      let finalHeadsToShowForEditSearchTerm = [
        ...defaultPermanantHeadsForEditSearchTerm,
        ...updateCampaignListFilter,
      ];
      dispatch(
        campaignListActions.getUpdatedConfigHeadsForEditSearchTermListSection(
          finalHeadsToShowForEditSearchTerm
        )
      );
      dispatch(campaignListActions.appliedFilterData(updateCampaignListFilter));
    } else if (props.filterFor === "master-targeting-list") {
      let finalHeadsToShowForMasterTargeting = [
        ...defaultPermanantHeadsForMatserTargeting,
        ...updateCampaignListFilter,
      ];
      dispatch(
        campaignListActions.getUpdatedConfigHeadsForMasterTargetingListSection(
          finalHeadsToShowForMasterTargeting
        )
      );
      dispatch(campaignListActions.appliedFilterData(updateCampaignListFilter));
    } else if (props.filterFor === "master-ads-list") {
      let finalHeadsToShowForEditSearchTerm = [
        ...defaultPermanantHeadsForMasterAds,
        ...updateCampaignListFilter,
      ];
      dispatch(
        campaignListActions.getUpdatedConfigHeadsForMasterAdsSection(
          finalHeadsToShowForEditSearchTerm
        )
      );
      dispatch(campaignListActions.appliedFilterData(updateCampaignListFilter));
    } else if(props.filterFor === "master-search-term") {
      let finalHeadsToShow = [
        ...defaultPermanentHeadsForMasterSearchTerm,
        ...updateCampaignListFilter
      ]
      const heads = dataForTable?.filter((data) => data?.status === true);
      let mainHeads = [
        ...defaultPermanentHeadsForMasterSearchTerm,
        ...heads
      ]

      dispatch(campaignListActions.getUpdatedConfigHeadsForMasterSearchTermSection(
        mainHeads
      ))
      dispatch(campaignListActions.appliedFilterData(finalHeadsToShow))
    } else if(props.filterFor === "smartAdsList") {
      let finalHeadsToShow = [
        ...defaultPermanentHeadsForSmartAdsList,
        ...updateCampaignListFilter
      ]
      dispatch(campaignListActions.getUpdatedConfigHeadsForSmartAdsList(finalHeadsToShow))
      dispatch(campaignListActions.appliedFilterData(finalHeadsToShow))
    }
    dispatch(campaignListActions.hideFilterModalInCampaignList());
  };
  const handleClose = () => {
    dispatch(campaignListActions.hideFilterModalInCampaignList());
  };

  useEffect(() => {
    if (props.filterFor === "campaignlist") {
      setRangeDataForTable(defaultRangeDataForResetInputField);
      let finalHeadsToShow = [
        ...defaultPermanantHeads,
        ...dbrMetricesForCL,
        ...defaultCampaignListFilterCopyForReset,
      ];
      dispatch(
        campaignListActions.getUpdatedConfigHeadsForCampaignListSection(
          finalHeadsToShow
        )
      );
      dispatch(
        campaignListActions.appliedFilterData(
          defaultCampaignListFilterCopyForReset
        )
      );

      // setDataForTable(defaultRangeDataForResetInputField)
    } else if (props.filterFor === "edit-targetting") {
      let finalHeadsToShowForTarget;

      if (targetingTypeForEditTargetSection === "amz_keyword_targeting") {
        finalHeadsToShowForTarget = [
          ...defaultPermanantHeadsFortarget,
          ...defaultCampaignListFilterCopyForReset,
        ];
        setRangeDataForTargetTable(defaultRangeDataForResetInputField);

        dispatch(
          campaignListActions.getUpdatedConfigHeadsForEditAdsetTargetListSection(
            finalHeadsToShowForTarget
          )
        );
        dispatch(
          campaignListActions.appliedFilterData(
            defaultCampaignListFilterCopyForReset
          )
        );
      }
      if (targetingTypeForEditTargetSection === "amz_product_targeting") {
        finalHeadsToShowForTarget = [
          ...defaultPermanantHeadsForProductTarget,
          ...defaultCampaignListFilterCopyForReset,
        ];
        dispatch(
          campaignListActions.getUpdatedConfigHeadsForEditAdsetProductTargetListSection(
            finalHeadsToShowForTarget
          )
        );
        dispatch(
          campaignListActions.appliedFilterData(
            defaultCampaignListFilterCopyForReset
          )
        );
      }
      setRangeDataForTargetTable(defaultRangeDataForResetInputField);
    } else if (props.filterFor === "edit-ads") {
      let finalHeadsToShowForEditAds = [
        ...defaultPermanantHeadsForEditAds,
        ...defaultCampaignListFilterCopyForReset,
      ];

      dispatch(
        campaignListActions.getUpdatedConfigHeadsForEditAdsSection(
          finalHeadsToShowForEditAds
        )
      );
      dispatch(
        campaignListActions.appliedFilterData(
          defaultCampaignListFilterCopyForReset
        )
      );
      setRangeDataForEditAdsTable(defaultRangeDataForResetInputField);
    } else if (props.filterFor === "edit-search-term") {
      let finalHeadsToShowForEditSearchTerm = [
        ...defaultPermanantHeadsForEditSearchTerm,
        ...defaultCampaignListFilterCopyForReset,
      ];
      dispatch(
        campaignListActions.getUpdatedConfigHeadsForEditSearchTermListSection(
          finalHeadsToShowForEditSearchTerm
        )
      );
      dispatch(
        campaignListActions.appliedFilterData(
          defaultCampaignListFilterCopyForReset
        )
      );
      setRangeDataForEditSearchTermTable(defaultRangeDataForResetInputField);
    } else if (props.filterFor === "master-targeting-list") {
      let finalHeadsToShowForEditSearchTerm = [
        ...defaultPermanantHeadsForMatserTargeting,
        ...defaultCampaignListFilterCopyForReset,
      ];
      dispatch(
        campaignListActions.getUpdatedConfigHeadsForMasterTargetingListSection(
          finalHeadsToShowForEditSearchTerm
        )
      );
      dispatch(
        campaignListActions.appliedFilterData(
          defaultCampaignListFilterCopyForReset
        )
      );
      setRangeDataMasterTargetingTable(defaultRangeDataForResetInputField);
    } else if (props.filterFor === "master-ads-list") {
      let finalHeadsToShowForEditSearchTerm = [
        ...defaultPermanantHeadsForMasterAds,
        ...initialRangeDataForMasterAds,
        ...additionalDataForMasterAds
      ];
      dispatch(
        campaignListActions.getUpdatedConfigHeadsForMasterAdsSection(
          finalHeadsToShowForEditSearchTerm
        )
      );
      dispatch(
        campaignListActions.appliedFilterData(
          defaultCampaignListFilterCopyForReset
        )
      );
      setRangeDataForMasterAds(rangeDataForMasterAds);
      setUpdatedCampaignListFilter(rangeDataForMasterAds);
    } else if(props.filterFor === "master-search-term") {
      let finalHeadsToShow = [
        ...defaultPermanentHeadsForMasterSearchTerm,
        ...defaultCampaignListFilterCopyForReset
      ]
      dispatch(campaignListActions.getUpdatedConfigHeadsForMasterSearchTermSection(
        finalHeadsToShow
      ))
      dispatch(campaignListActions.appliedFilterData(
        defaultCampaignListFilterCopyForReset
      ))
      setRangeDataForMasterSearchTerm(defaultRangeDataForResetInputField)
    } else if(props.filterFor === "smartAdsList") {
      let finalHeadsToShow = [
        ...defaultPermanentHeadsForSmartAdsList,
        ...defaultCampaignListFilterCopyForReset
      ]
      dispatch(campaignListActions.getUpdatedConfigHeadsForSmartAdsList(
        finalHeadsToShow
      ))
      dispatch(campaignListActions.appliedFilterData(
        defaultCampaignListFilterCopyForReset
      ))
      setRangeDataForSmartAdsList(defaultRangeDataForResetInputField)
    }
  },[])

  useEffect(() => {
    // This Effect will run whenever the preEnabled filters will change and it will populate the table with preEnabled Filters Data
   if(props?.preEnabledFilters?.length && dataForTable?.length) {
    const {preEnabledFilters} = props
    const currentData = JSON.parse(JSON.stringify(dataForTable));
    const currentFilters = JSON.parse(JSON.stringify(updateCampaignListFilter));
    let foundedObjects = []
    const updatedData = currentData?.map((data) => {
      preEnabledFilters.forEach((filter) => {
        if(filter.field_key === data.id) {
          data.minRange = filter.minValue
          data.maxRange = filter.maxValue
          data.status = true
          data.isDisabled = false
        }
      })
      return data
    })
    setDataForTable(updatedData);

    // This data will be sent in the API
    const updatedFilters = preEnabledFilters?.forEach((data) => {
        const dataFounded = {
          fieldName:data?.field_name,
          id:data?.field_key,
          minRange:data?.minValue,
          maxRange:data?.maxValue,
          isTwoDecimalPlacesNeeded: false,
          isRsSymbolNeeded: true,
          isFindTaskActive: false,
          isSortFunctionalityNeededHere: true,
          isHeadRightAlign: true,
          isHeadWithIcon: "sort",
          isAbbrivation: false,
        }
        foundedObjects.push(dataFounded)
    })
    setUpdatedCampaignListFilter(foundedObjects)

    // Alternatively if you want for more you can add your conditions or you can create a mapping Object
    if(props.filterFor === "master-search-term") {
      let finalHeadsToShow = [
        ...foundedObjects
      ]
      const heads = currentData?.filter((data) => data?.status)
      let headsForTable = [
        ...defaultPermanentHeadsForMasterSearchTerm,
        ...heads
      ]
      dispatch(campaignListActions.getUpdatedConfigHeadsForMasterSearchTermSection(
        headsForTable
      ))
      dispatch(campaignListActions.appliedFilterData(finalHeadsToShow))
    }
   }
  },[props?.preEnabledFilters])

  const resetHandler = () => {
    dispatch(campaignListActions.hideFilterModalInCampaignList());
    setUpdatedCampaignListFilter(defaultCampaignListFilterCopyForReset);
    if (props.filterFor === "campaignlist") {
      setRangeDataForTable(defaultRangeDataForResetInputField);
      let finalHeadsToShow = [
        ...defaultPermanantHeads,
        ...dbrMetricesForCL,
        ...defaultCampaignListFilterCopyForReset,
      ];
      dispatch(
        campaignListActions.getUpdatedConfigHeadsForCampaignListSection(
          finalHeadsToShow
        )
      );
      dispatch(
        campaignListActions.appliedFilterData(
          defaultCampaignListFilterCopyForReset
        )
      );

      // setDataForTable(defaultRangeDataForResetInputField)
    } else if (props.filterFor === "edit-targetting") {
      let finalHeadsToShowForTarget;

      if (targetingTypeForEditTargetSection === "amz_keyword_targeting") {
        finalHeadsToShowForTarget = [
          ...defaultPermanantHeadsFortarget,
          ...defaultCampaignListFilterCopyForReset,
        ];
        setRangeDataForTargetTable(defaultRangeDataForResetInputField);

        dispatch(
          campaignListActions.getUpdatedConfigHeadsForEditAdsetTargetListSection(
            finalHeadsToShowForTarget
          )
        );
        dispatch(
          campaignListActions.appliedFilterData(
            defaultCampaignListFilterCopyForReset
          )
        );
      }
      if (targetingTypeForEditTargetSection === "amz_product_targeting") {
        finalHeadsToShowForTarget = [
          ...defaultPermanantHeadsForProductTarget,
          ...defaultCampaignListFilterCopyForReset,
        ];
        dispatch(
          campaignListActions.getUpdatedConfigHeadsForEditAdsetProductTargetListSection(
            finalHeadsToShowForTarget
          )
        );
        dispatch(
          campaignListActions.appliedFilterData(
            defaultCampaignListFilterCopyForReset
          )
        );
      }
      setRangeDataForTargetTable(defaultRangeDataForResetInputField);
    } else if (props.filterFor === "edit-ads") {
      let finalHeadsToShowForEditAds = [
        ...defaultPermanantHeadsForEditAds,
        ...defaultCampaignListFilterCopyForReset,
      ];

      dispatch(
        campaignListActions.getUpdatedConfigHeadsForEditAdsSection(
          finalHeadsToShowForEditAds
        )
      );
      dispatch(
        campaignListActions.appliedFilterData(
          defaultCampaignListFilterCopyForReset
        )
      );
      setRangeDataForEditAdsTable(defaultRangeDataForResetInputField);
    } else if (props.filterFor === "edit-search-term") {
      let finalHeadsToShowForEditSearchTerm = [
        ...defaultPermanantHeadsForEditSearchTerm,
        ...defaultCampaignListFilterCopyForReset,
      ];
      dispatch(
        campaignListActions.getUpdatedConfigHeadsForEditSearchTermListSection(
          finalHeadsToShowForEditSearchTerm
        )
      );
      dispatch(
        campaignListActions.appliedFilterData(
          defaultCampaignListFilterCopyForReset
        )
      );
      setRangeDataForEditSearchTermTable(defaultRangeDataForResetInputField);
    } else if (props.filterFor === "master-targeting-list") {
      let finalHeadsToShowForEditSearchTerm = [
        ...defaultPermanantHeadsForMatserTargeting,
        ...defaultCampaignListFilterCopyForReset,
      ];
      dispatch(
        campaignListActions.getUpdatedConfigHeadsForMasterTargetingListSection(
          finalHeadsToShowForEditSearchTerm
        )
      );
      dispatch(
        campaignListActions.appliedFilterData(
          defaultCampaignListFilterCopyForReset
        )
      );
      setRangeDataMasterTargetingTable(defaultRangeDataForResetInputField);
    } else if (props.filterFor === "master-ads-list") {
      let finalHeadsToShowForEditSearchTerm = [
        ...defaultPermanantHeadsForMasterAds,
        ...initialRangeDataForMasterAds,
        ...additionalDataForMasterAds
      ];
      dispatch(
        campaignListActions.getUpdatedConfigHeadsForMasterAdsSection(
          finalHeadsToShowForEditSearchTerm
        )
      );
      dispatch(
        campaignListActions.appliedFilterData(
          defaultCampaignListFilterCopyForReset
        )
      );
      setRangeDataForMasterAds([...initialRangeDataForMasterAds,...additionalDataForMasterAds]);
      setUpdatedCampaignListFilter([...initialRangeDataForMasterAds,...additionalDataForMasterAds]);
    } else if(props.filterFor === "master-search-term") {
      let finalHeadsToShow = [
        ...defaultPermanentHeadsForMasterSearchTerm,
        ...defaultCampaignListFilterCopyForReset
      ]
      dispatch(
        campaignListActions.getUpdatedConfigHeadsForMasterSearchTermSection(
          finalHeadsToShow
        )
      )
      dispatch(
        campaignListActions.appliedFilterData(
          defaultCampaignListFilterCopyForReset
        )
      )
      setRangeDataForMasterSearchTerm(defaultRangeDataForResetInputField)
    } else if(props.filterFor === "smartAdsList") {
      let finalHeadsToShow = [
        ...defaultPermanentHeadsForSmartAdsList,
        ...defaultCampaignListFilterCopyForReset
      ]
      dispatch(
        campaignListActions.getUpdatedConfigHeadsForSmartAdsList(
          finalHeadsToShow
        )
      )
      dispatch(
        campaignListActions.appliedFilterData(
          defaultCampaignListFilterCopyForReset
        )
      )
    }
  };

  const onSwicthBtnToggleHandle = (e, id, fieldName, dataObj) => {
    //this will loop through filter list data & handle toggle visibilty
    let rangeCurrentTableDataCopy = JSON.stringify(dataForTable);
    rangeCurrentTableDataCopy = JSON.parse(rangeCurrentTableDataCopy);
    for (let p = 0; p < rangeCurrentTableDataCopy.length; p++) {
      if (e.target.checked) {
        if (rangeCurrentTableDataCopy[p].id === id) {
          rangeCurrentTableDataCopy[p].status = true;
        }
      } else {
        if (rangeCurrentTableDataCopy[p].id === id) {
          rangeCurrentTableDataCopy[p].status = false;
        }
      }
    }

    //this  will update mainn campaignlist fileter array and add  or remove toggled metric on array to show in table
    if (e.target.checked) {
      setUpdatedCampaignListFilter((updateCampaignListFilter) => [
        ...updateCampaignListFilter,
        dataObj,
      ]);
      setIsTogglebtndisableText(true);
    } else {
      for (let u = 0; u < updateCampaignListFilter.length; u++) {
        let copiedUpdatedCampaignListFilterForTarget = JSON.stringify(
          updateCampaignListFilter
        );
        copiedUpdatedCampaignListFilterForTarget = JSON.parse(
          copiedUpdatedCampaignListFilterForTarget
        );

        if (
          copiedUpdatedCampaignListFilterForTarget[u].fieldName &&
          copiedUpdatedCampaignListFilterForTarget[u].fieldName == fieldName
        ) {
          copiedUpdatedCampaignListFilterForTarget.splice(u, 1);
          setUpdatedCampaignListFilter(
            copiedUpdatedCampaignListFilterForTarget
          );
        }
      }
    }
    setDataForTable(rangeCurrentTableDataCopy);
  };

  const onRangeChageHandler = (e, id, fieldName, differObj, type) => {
    let currenntObject;
    let dataObj = {...differObj}
    let rangeCurrentTableDataCopy = JSON.stringify(dataForTable);
    rangeCurrentTableDataCopy = JSON.parse(rangeCurrentTableDataCopy);
    for (let index = 0; index < rangeCurrentTableDataCopy.length; index++) {
      // if ( ) {
      if (rangeCurrentTableDataCopy[index].id === id) {
        if (type === "minRange") {
          rangeCurrentTableDataCopy[index].minRange = e.target.value;
        }
        if (type === "maxRange") {
          rangeCurrentTableDataCopy[index].maxRange = e.target.value;
        }
      }
    }
    let copiedUpdatedCampaignListFilter = JSON.stringify(
      updateCampaignListFilter
    );
    copiedUpdatedCampaignListFilter = JSON.parse(
      copiedUpdatedCampaignListFilter
    );
    if (dataObj.status === true) {
      let currenntObject;
      if (
        updateCampaignListFilter &&
        updateCampaignListFilter.length &&
        copiedUpdatedCampaignListFilter.filter((obj) => obj?.id === id).length >
          0
      ) {
        //here if id exist in array then only we change its value on  basis of input
        for (let i = 0; i < copiedUpdatedCampaignListFilter.length; i++) {
          if (copiedUpdatedCampaignListFilter[i].id === id) {
            if (type === "minRange") {
              copiedUpdatedCampaignListFilter[i].minRange = e.target.value;
            } else if (type === "maxRange") {
              copiedUpdatedCampaignListFilter[i].maxRange = e.target.value;
            }
            setUpdatedCampaignListFilter(copiedUpdatedCampaignListFilter);
          }
        }
      } else {
        if (type === "minRange") {
          dataObj.minRange = e.target.value;
        }
        if (type === "maxRange") {
          dataObj.maxRange = e.target.value;
        }
        currenntObject = dataObj;

        setUpdatedCampaignListFilter((updateCampaignListFilter) => [
          ...updateCampaignListFilter,
          currenntObject,
        ]);
      }
    }
    setDataForTable(rangeCurrentTableDataCopy);
  };

  const selectAllHandler = () => {
    let rangeCurrentTableDataCopy = JSON.stringify(dataForTable);
    rangeCurrentTableDataCopy = JSON.parse(rangeCurrentTableDataCopy);
    for (let p = 0; p < rangeCurrentTableDataCopy.length; p++) {
      rangeCurrentTableDataCopy[p].status = true;
    }
   
    setDataForTable(rangeCurrentTableDataCopy);
    setUpdatedCampaignListFilter([]);
    setUpdatedCampaignListFilter(rangeCurrentTableDataCopy);
    setIsTogglebtndisableText(true);
  };

const tableBody1Limit = props?.filterFor === 'master-ads-list' ? 11 : 5
const tableBody2Limit = props?.filterFor === 'master-ads-list' ? 12 : 6

  let tableBody2 = (
    <>
      <div>
        <div className="d-flex justify-content-end ">
          <img className="cursor-pointer" onClick={handleClose} src={closeIcon}></img>
        </div>
        <center>
          {" "}
          <h4 style={{ marginTop: "30px" }}>Filter Logic </h4>
        </center>

        <div>
          <div className=" filter-modal-two-box-container overflow-y">
            <div className="f-table-body-container filter-table filter-table1">
              <table className="filter-logic-main-table">
                <thead className="filter-logic-main-table-thead">
                  <tr>
                    {dataHead.map((th) => {
                      return (
                        <th key={th.id} className="filter-logic-main-table-tr">
                          {th}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {dataForTable.map((dataObj, i) => {
                    if (i < tableBody2Limit) {
                      return (
                        <tr className={dataObj?.isDisabled ? "pointer-disable-global filter-logic-main-table-inside-tr" :"filter-logic-main-table-inside-tr"}>
                          <td>
                            <div className="d-flex justify-content-center">
                              <SwitchBtn
                                onChange={(e) =>
                                  onSwicthBtnToggleHandle(
                                    e,
                                    dataObj.id,
                                    dataObj.fieldName,
                                    dataObj,
                                    dataForTable
                                  )
                                }
                                checked={dataObj.status == true ? true : false}

                                // defaultChecked={
                                //   selectedFilterForDefineSwitchState.find(
                                //     (e) => e.fieldName === dataObj.fieldName
                                //   )
                                //     ? true
                                //     : false
                                // }
                              ></SwitchBtn>
                            </div>
                          </td>
                          <td className="tabledata-td filter-logic-main-table-inside-td">
                            <div className="d-flex justify-content-evenly align-items-center">
                              {dataObj.fieldName}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-evenly align-items-center budget-td-container div-in-logic">
                              <input
                                placeholder={!dataObj.status ? "-" : ""}
                                onChange={(e) =>
                                  onRangeChageHandler(
                                    e,
                                    dataObj.id,
                                    dataObj.fieldName,
                                    dataObj,
                                    "minRange"
                                  )
                                }
                                type="number"
                                className={
                                  dataObj.status
                                    ? "budget-no w-100 input-clickable"
                                    : "input-disable budget-no w-100"
                                }
                                defaultValue={dataObj.minRange}
                                disabled={!dataObj.status}
                              ></input>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-evenly align-items-center budget-td-container div-in-logic">
                              <input
                                onChange={(e) =>
                                  onRangeChageHandler(
                                    e,
                                    dataObj.id,
                                    dataObj.fieldName,
                                    dataObj,
                                    "maxRange"
                                  )
                                }
                                placeholder={!dataObj.status ? "-" : ""}
                                type="number"
                                className={
                                  dataObj.status
                                    ? "budget-no w-100 input-clickable"
                                    : "input-disable budget-no w-100"
                                }
                                defaultValue={dataObj.maxRange}
                                disabled={!dataObj.status}
                              ></input>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>

            <div className="f-table-body-container filter-table filter-table2 overflow-y">
              <table className="filter-logic-main-table">
                <thead className="filter-logic-main-table-thead">
                  <tr>
                    {dataHead.map((th) => {
                      return (
                        <th key={th.id} className="filter-logic-main-table-tr">
                          {th}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {dataForTable.map((dataObj, i) => {
                    if (i > tableBody1Limit) {
                      return (
                        <tr className={dataObj?.isDisabled ? "pointer-disable-global filter-logic-main-table-inside-tr" :"filter-logic-main-table-inside-tr"}>
                          <td>
                            <div className="d-flex justify-content-center">
                              <SwitchBtn
                                onChange={(e) =>
                                  onSwicthBtnToggleHandle(
                                    e,
                                    dataObj.id,
                                    dataObj.fieldName,
                                    dataObj
                                  )
                                }
                                checked={dataObj.status == true ? true : false}
                                // defaultChecked={
                                //   selectedFilterForDefineSwitchState.find(
                                //     (e) => e.fieldName === dataObj.fieldName
                                //   )
                                //     ? true
                                //     : false
                                // }
                              ></SwitchBtn>
                            </div>
                          </td>
                          <td className="tabledata-td filter-logic-main-table-inside-td">
                            <div className="d-flex justify-content-evenly align-items-center">
                              {dataObj.fieldName}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-evenly align-items-center budget-td-container div-in-logic">
                              <input
                                placeholder={!dataObj.status ? "-" : ""}
                                onChange={(e) =>
                                  onRangeChageHandler(
                                    e,
                                    dataObj.id,
                                    dataObj.fieldName,
                                    dataObj,
                                    "minRange"
                                  )
                                }
                                type="number"
                                className={
                                  dataObj.status
                                    ? "budget-no w-100 input-clickable"
                                    : "input-disable budget-no w-100"
                                }
                                defaultValue={dataObj.minRange}
                                disabled={!dataObj.status}
                              ></input>
                              {/* <span className="edit-budget-icon-container"><img src={editIcon}></img></span> */}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-evenly align-items-center budget-td-container div-in-logic">
                              <input
                                placeholder={!dataObj.status ? "-" : ""}
                                onChange={(e) =>
                                  onRangeChageHandler(
                                    e,
                                    dataObj.id,
                                    dataObj.fieldName,
                                    dataObj,
                                    "maxRange"
                                  )
                                }
                                type="number"
                                className={
                                  dataObj.status
                                    ? "budget-no w-100 input-clickable"
                                    : "input-disable budget-no w-100"
                                }
                                defaultValue={dataObj.maxRange}
                                disabled={!dataObj.status}
                              ></input>
                              {/* <span className="edit-budget-icon-container"><img src={editIcon}></img></span> */}
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="d-flex justify-content-between gap-10">
            <div className="d-flex">
              <button
                type="button"
                class="primary-btn btn btn-primary px-4"
                onClick={selectAllHandler}
              >
                Select All
              </button>
            </div>
            <div className="d-flex">
              <button
                type="button"
                onClick={resetHandler}
                class="primary-outline-btn btn btn-outline-primary px-5 mx-2"
              >
                Reset
              </button>
              <button
                type="button"
                class="primary-btn btn btn-primary px-5"
                onClick={updateHandler}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className={`filetr-modal-main-container`}>
      <Modal
        show={props.show}
        // dialogClassName="modal-90w"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // contentClassName="filter-content-class"
        contentClassName={`main-modal-className-for-filter ${props?.filterFor ? props?.filterFor : ''}`}
        bsPrefix="modal"
        dialogClassName="filter-dialog"
      >
        <Modal.Body>{tableBody2}</Modal.Body>
      </Modal>
    </div>
  );
}
