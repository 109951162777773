/**
 * This function will take filters and will return filtered Array 
 * @param {*} productAttribFilters
 * @param {*} performanceMetricFilters
 * @returns Object With API Filtering Params {attributeFilters,performanceFilters}
 */
// Need to work on this function for AND and IN  Operators *** Scale ***
export default function productSetDataFiltersParser(
  productAttribFilters,
  performanceMetricFilters
) {
  // Filter out all the values which are not part of the mapping then map filtered with filterMapper
  const attributeFilters = [...productAttribFilters]
    .filter((filter) => {
      const { field_name, operator, value } = filter;
      return field_name.length > 1 && operator !== "-" && value.length >= 1 
    })
    ?.map((filter) => {
      return mapProductAttribFilter(filter);
    });
  const performanceFilter = [...performanceMetricFilters]
    .filter((filter) => {
      const { field_name, operator, value } = filter;
      return field_name.length > 1 && operator !== "-" && value.length >= 1;
    })
    ?.map((filter) => {
      return mapPerformanceFilter(filter);
    });
  return { attributeFilters, performanceFilter };
}

function mapProductAttribFilter(filter) {
  switch (filter.field_name) {
    case "Product MRP":
      return {
        field_name: "maximum_retail_price",
        operator: operatorMapper(filter.operator),
        value: filter.value,
      };
    case "Product SP":
      return {
        field_name: "sale_price",
        operator: operatorMapper(filter.operator),
        value: parseInt(filter.value),
      };
    case "Product Discount":
      return {
        field_name: "discount_percent",
        operator: operatorMapper(filter.operator),
        value: filter.value,
      };
    case "Product Star rating":
      return {
        field_name: "star_rating",
        operator: operatorMapper(filter.operator),
        value: filter.value,
      };
    case "Product Reviews":
      return {
        field_name: "reviews",
        operator: operatorMapper(filter.operator),
        value: filter.value,
      };
    case "Product Name":
      return {
        field_name: "name",
        operator: operatorMapper(filter.operator),
        value: filter.value,
      };
    case "Product - Size":
      return {
        field_name: "size",
        operator: operatorMapper(filter.operator),
        value: filter.value,
      };
    case "Product - Color":
      return {
        field_name: "color",
        operator: operatorMapper(filter.operator),
        value: filter.value,
      };
    case "Launched Date":
      return {
        field_name: "launch_date",
        operator: operatorMapper(filter.operator),
        value: filter.value,
      };
    case "Gender":
      return {
        field_name: "gender",
        operator: operatorMapper(filter.operator),
        value: filter.value,
      };
    case "Products not advertised":
      return {
        field_name: "ad_status",
        operator: "EQUALS",
        value: "paused",
      };
    case "BSR":
      return {
        field_name: "best_selling_rank",
        operator: operatorMapper(filter.operator),
        value: filter.value,
      }
    case "Top BSR":
      return {
        field_name: "top_best_sellers_rank",
        operator: operatorMapper(filter.operator),
        value: filter.value,
      }
    case "Cut Size *":
      return {
        field_name: "cut_size_percent",
        operator: "LESS_THAN",
        value: filter.value,
      }
    default:
      return {
        field_name: filter.field_name,
        operator: operatorMapper(filter.operator),
        value: filter.value,
      }; // Return the filter as is if no mapping is found
  }
}

function mapPerformanceFilter(filter) {
  const modifiedFieldName =
    // filter.field_name === "Revenue"
    //   ? "sale_value"
    //   : filter.field_name.toLowerCase();
    PerformanceFieldMapper(filter.field_name)
  return {
    field_name: modifiedFieldName,
    operator: operatorMapper(filter.operator),
    value: parseFloat(filter.value),
  };
}

function PerformanceFieldMapper (fieldName) {
  switch (fieldName) {
    case "Revenue":
      return "sale_value"
    case "Top Selling":
      return "top_sale_value"
    case "Top Conversion":
      return "top_conversion_rate"
    case "Top ROAS":
      return "top_roas"
    case "Conversion Rate":
      return "conversion_rate"
    case "Total Units Sold":
      return "total_units_sold"
    case "ASP":
      return "total_asp"
    case "TROAS":
      return "troas"
    case "TACOS":
      return "tacos"
    case "Total Revenue":
      return "total_revenue"
    case "Available Inventory":
      return "quantity"
    default:
     return fieldName?.toLowerCase()
  }
}

function operatorMapper(key) {
  switch (key) {
    case ">":
      return "GREATER_THAN";
    case "<":
      return "LESS_THAN";
    case "=":
      return "EQUALS";
    case "contains":
      return "CONTAINS";
    case "not contains":
      return "NOT_CONTAINS";
    default:
      return key;
  }
}
