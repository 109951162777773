import React from "react";
import TableRb from "../../../Tables/tableRb";
import ProductImage1 from "../../../../assets/images/SP_ProductImage/Product1.png";
import { Form } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { useGlobalContext } from "../../../../context/global";
import {useDispatch,useSelector} from 'react-redux'
import "./AddProductTable.css";
import { campaignListActions  } from "../../../../redux/slices/campaignList";
import modifyValueByPercentage from '../../../../helper/common/modifyValueByPercentage'
import EditBidModalForTargeting from "../../editBidModalForTargeting";
import { useEffect } from "react";
import { useState } from "react";

const AddProductTable1 = () => {
  const {
    page,
    spSelectedSuggestedCategories,
    setSpSelectedSuggestedCategories,
    setSpWholeCategories,
    setAdGroupData,
    createCampaignErrors
  } = useGlobalContext();
  const dispatch = useDispatch()
  const actiontoEditCollectivebid = useSelector(
    (state) => state.campaignListData.actionEditCollectiveBidForMasterTargeting
  );
  const bulkEditBidModalVisibilty = useSelector(
    (state) => state.campaignListData.bulkEditBidModalVisibilty
  );

  const [bidModal,setBidModal] = useState(false)



  const handleDeleteProduct = (id) => {
    setSpSelectedSuggestedCategories((current) => {
      return current.filter((product) => {
        return product.expression[0]?.value !== id;
      });
    });
    setSpWholeCategories((current) => {
      const copy = current;
      let updatedArray = copy.filter((product) => {
        return product.expression[0]?.value !== id;
      });
      return updatedArray;
    });
    setAdGroupData((current) => {
      const copy = { ...current };
      let updatedArray = copy["amz_product_targeting"].filter((product) => {
        return product.expression[0]?.value !== id;
      });
      copy["amz_product_targeting"] = updatedArray;
      return copy;
    });
  };

  const deleteAllProducts = () => {
    setSpSelectedSuggestedCategories([]);
    setSpWholeCategories((current) => {
      return current.filter((category) => {
        return category.expression[0].type !== "category";
      });
    });
    setAdGroupData((current) => {
      const copy = { ...current };
      let updatedArray = copy["amz_product_targeting"].filter((product) => {
        return product.expression[0].type !== "category";
      });
      copy["amz_product_targeting"] = updatedArray;
      return copy;
    });
  };

  const bidChange = (input,product) => {
    setSpSelectedSuggestedCategories((prev)=>{
      let data = [...prev].map((selectedProduct) => {
        if(selectedProduct.category_name?.toLowerCase() === product.category_name.toLowerCase()) {
          selectedProduct.bid = parseFloat(input.target.value)
        }
        return selectedProduct
      })
      return data 
    })
    setAdGroupData((prev) => {
      let data = {...prev}
      let updatedData = data.amz_product_targeting.map((selectedProduct) => {
        if(selectedProduct?.expression[0]?.value === product?.category_id) {
          selectedProduct.bid = parseFloat(input.target.value)
        }
        return selectedProduct
      })
      data.amz_product_targeting = updatedData
      return data
    })
  }

  const editBidHandler = () => {
    dispatch(campaignListActions.showBulkEditBidModal())
    setBidModal(true)
  }

  const cancelHandler = () => {
    setBidModal(false)
  }
 
  // useEffect(() => {
  //   if(Object.keys(actiontoEditCollectivebid).length) {
  //     const {setAsValue,selectedAction,valuePercentage} = actiontoEditCollectivebid
  //     setSpSelectedSuggestedCategories((prev) => {
  //       const copy = [...prev].map((product) => {
  //         product.bid = selectedAction === "set_bid_as" ? setAsValue : modifyValueByPercentage(selectedAction,valuePercentage,Number(product.bid))
  //         return product
  //       })
  //       return copy
  //     })

  //     setAdGroupData((prev) => {
  //       let data = {...prev}
  //       let updatedData = data.amz_product_targeting.map((selectedProduct) => {
  //         const addedProduct = spSelectedSuggestedCategories.find((addedProduct) => selectedProduct?.expression[0]?.value === addedProduct?.category_id);
  //         if(addedProduct) {
  //           selectedProduct.bid = addedProduct.bid
  //         }
  //         return selectedProduct
  //       })
  //       data.amz_product_targeting = updatedData
  //       return data
  //     })
  //   }
  // },[actiontoEditCollectivebid])

  const getData = (data) => {
    const {setAsValue,selectedAction,valuePercentage} = data
      setSpSelectedSuggestedCategories((prev) => {
        const copy = [...prev].map((product) => {
          product.bid = selectedAction === "set_bid_as" ? setAsValue : modifyValueByPercentage(selectedAction,valuePercentage,Number(product.bid))
          return product
        })
        return copy
      })

      setAdGroupData((prev) => {
        let data = {...prev}
        let updatedData = data.amz_product_targeting.map((selectedProduct) => {
          const addedProduct = spSelectedSuggestedCategories.find((addedProduct) => selectedProduct?.expression[0]?.value === addedProduct?.category_id);
          if(addedProduct) {
            selectedProduct.bid = addedProduct?.bid
          }
          return selectedProduct
        })
        data.amz_product_targeting = updatedData
        return data
      })
  }

  const tableBody = (
    <>
      <div className={`${createCampaignErrors?.targeting ? "border border-danger" : ""} generic-main-table-body-container`}>
        <table className="sp-product-targetting-table table-bordered">
          <thead className="main-table-head">
            <tr className="table-heading-style">
              <td colspan={3} className="table-upper">
                <div className="d-flex justify-content-between align-items-center">
                <div className="float-left p-3 text-black">{`${spSelectedSuggestedCategories.length} Added`}</div>
                {
                  spSelectedSuggestedCategories.length > 0 ? (
                    <div>
                  <button
                  className="bid-manipulate-box"
                  onClick={editBidHandler}
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                >
                  Adjust Bid
                   </button>
                    </div>
                  )
                  :""
                }
                <div className="float-right">
                  <button
                    type="button"
                    className={
                      spSelectedSuggestedCategories.length > 0
                        ? "remove-all-btn-dark"
                        : "remove-all-btn"
                    }
                    onClick={deleteAllProducts}
                  >
                    Remove All
                  </button>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <th className="border border-right-white product-table-first">
                <span className="table-header-text mx-3">All Categories</span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_1178_14756"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                  >
                    <rect width="20" height="20" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_1178_14756)">
                    <path
                      d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                      fill="black"
                    />
                    <path
                      d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                      fill="black"
                    />
                  </g>
                </svg>
              </th>
              <th id="product-table-header" className="border border-right-white">
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="table-header-text mx-3">
                      Suggested Bid
                    </span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_1178_14756"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                      >
                        <rect width="20" height="20" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_1178_14756)">
                        <path
                          d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                          fill="black"
                        />
                        <path
                          d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </span>
                  {/* <svg
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="menu-icon-table"
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    <path
                      d="M1.36328 11.625C1.14661 11.625 0.967615 11.5543 0.826281 11.413C0.684281 11.271 0.613281 11.0917 0.613281 10.875C0.613281 10.675 0.684281 10.5 0.826281 10.35C0.967615 10.2 1.14661 10.125 1.36328 10.125H17.3633C17.5799 10.125 17.7593 10.2 17.9013 10.35C18.0426 10.5 18.1133 10.675 18.1133 10.875C18.1133 11.0917 18.0426 11.271 17.9013 11.413C17.7593 11.5543 17.5799 11.625 17.3633 11.625H1.36328ZM1.36328 6.75C1.14661 6.75 0.967615 6.679 0.826281 6.537C0.684281 6.39567 0.613281 6.21667 0.613281 6C0.613281 5.78333 0.684281 5.604 0.826281 5.462C0.967615 5.32067 1.14661 5.25 1.36328 5.25H17.3633C17.5799 5.25 17.7593 5.32067 17.9013 5.462C18.0426 5.604 18.1133 5.78333 18.1133 6C18.1133 6.21667 18.0426 6.39567 17.9013 6.537C17.7593 6.679 17.5799 6.75 17.3633 6.75H1.36328ZM1.36328 1.875C1.14661 1.875 0.967615 1.8 0.826281 1.65C0.684281 1.5 0.613281 1.325 0.613281 1.125C0.613281 0.908333 0.684281 0.729333 0.826281 0.588C0.967615 0.446 1.14661 0.375 1.36328 0.375H17.3633C17.5799 0.375 17.7593 0.446 17.9013 0.588C18.0426 0.729333 18.1133 0.908333 18.1133 1.125C18.1133 1.325 18.0426 1.5 17.9013 1.65C17.7593 1.8 17.5799 1.875 17.3633 1.875H1.36328Z"
                      fill="black"
                    />
                  </svg> */}
                </div>
              </th>
              <th>
                  Edit Bid
              </th>
            </tr>
          </thead>
          <tbody className="table-body main-table-body">
            {spSelectedSuggestedCategories &&
              spSelectedSuggestedCategories.map((product) => {
                return (
                  <>
                    <tr key={product?.category_id} id="correct">
                      <td className="tabledata-tb">
                        <Product
                          image={ProductImage1}
                          Name={product?.category_name}
                          Desc={product?.Desc}
                        />
                      </td>
                      <td className="tabledata-td">
                        {/* <div className="d-flex flex-column w-max align-items-center">
                        <div>₹{product?.bid}</div>
                        <div className="text-muted">
                        <div className="fs-10"><small className="">
                            (₹{product?.bid} - ₹{product?.bid})
                          </small>
                        </div>
                        </div>
                      </div> */}
                      -
                    </td>
                      <td className="tabledata-td text-center">
                      <div className="d-flex gap-1 justify-content-between align-items-center" style={{width:'75px'}}>
                        <div className="w-100">
                            <Form.Control
                              size="sm"
                              className="w-100"
                              type="number"
                              step="0.1"
                              value={product.bid}
                              defaultValue={0}
                              onChange={(e) => bidChange(e,product)}
                              placeholder="Enter your bid"
                            />
                        </div>
                        <div>
                          <span
                            className="text-left cursor-pointer-global d-block selected-icon-hover" 
                            // onClick={() => onDelete(product)}
                          >
                            <Trash
                              className="text-danger remove-item-btn"
                              height="12px"
                              width="12px" 
                              onClick={() =>
                                handleDeleteProduct(product?.category_id)
                              }

                            />
                          </span>
                        </div>
                      </div>
                    </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );

  return (
    <div className="sp-product-targettig-outer-container">
{ bulkEditBidModalVisibilty && bidModal && <EditBidModalForTargeting show={bulkEditBidModalVisibilty} getData={getData} cancel={cancelHandler} setBidAs={true}/>}
      <TableRb className="" table={tableBody} page={page}></TableRb>
    </div>
  );
};

const Product = ({ image, Name, Desc }) => {
  return (
    <>
      <div className="product-container">
        <div className="product-img-container">
          <img src={image} alt="product" />
        </div>
        <div className="product-description-container">
          <span className="category-heading">Category</span>
          <span className="product-name">{Name && Name}</span>
          <span className="product-desc">Product:{Desc}</span>
        </div>
      </div>
    </>
  );
};

export default AddProductTable1;
