export default async function updateFileUrl (url) {
    const accoundIdNumeric = JSON.parse(localStorage.getItem('accountIdNumeric'));
    if(url) {
        try {
            const apiUrl = `${process.env.REACT_APP_ADS_API}/accounts/${accoundIdNumeric}?type=amazon_ad_account`;
            const options = {
                method:"PATCH",
                headers:{
                    'Content-Type':"application/merge-patch+json"
                },
                body:JSON.stringify({"vendor_catalog_csv_url":`${url}`})
            }
            const response = await fetch(apiUrl,options);       
            const result = await response.json();
            return result
        } catch (error) {
            return error
        }
    }   
}