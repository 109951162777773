import React from 'react'

const CampaignListTab = (props) => {
  return (
      <button className={`bid-manipulate-box-selective ` + props?.classes} onClick={props.onClickHandler}>
        <img src={props.imageSrc} className='mx-1' alt="" />
        <span>{props.imageText}</span>
      </button>
  )
}

export default CampaignListTab
