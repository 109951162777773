export function getCurrentDateTimeString() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${year}${month}${day}${hours}${minutes}${seconds}`;
}

export const catalogRulesFieldData = [
  {
    title: "New Rules",
    caption: "Filter on metrics",
    heading: "Save Rule",
    ruleName: "Save Rule name as",
    Featuretitle: "New Rules",
    Featurecaption: "Filter on Attributes",
  },
];

export const filterOperators = [
  {
    id: 1,
    label: "AND",
    value: "AND",
  },
  {
    id: 2,
    label: "OR",
    value: "OR",
  },
];
