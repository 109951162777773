/**
 * @Note This function checks whether a string exists in a array or not
 * @param string 
 * @param array
 * @returns boolean
 */

export default function checkDuplicateStringInArray (stringToCheck,arrayToBeCheckedIn) {
    const data = arrayToBeCheckedIn.some((el) => el?.toLowerCase() === stringToCheck?.toLowerCase())
    return data
}