import React from "react";
import { Container, Row, Col, Form, Button, Card , Modal ,InputGroup } from "react-bootstrap";
import './SpKeywordTargeting.css';
import './filterkeywordModal.css';
import { useState } from "react";
import { useGlobalContext } from "../../../../context/global";
import closeIcon from '../../../../assets/icons/close_icon.png'

const FilterkeywordModal = ({isVisible,setIsVisible,page,isPortfolioVisible,setIsPortfolioVisible}) => {

  // Methods to update the state and change the form input and generate form fields dynamically

  const {formValues,setFormValues} = useGlobalContext();
  const {isApplied,setIsApplied} = useGlobalContext();

  const [keywordFilter,setKeywordFilter] = useState([{"field_name":"keyword","operator":"CONTAINS","value":""}])

    const handleChange = (e,index) => {
      setFormValues((current) => {
       return current.map((formValue,i) => {
          if(i === index) {
            return {...formValue,[e.target.name]:`${e.target.value}`,[e.target.name]:`${e.target.value}`}
          }
          return formValue;
        })
      })
    }

    const addFormFields = () => {
      if(formValues.length === 4) {
        return;
      }
      setFormValues([...formValues, {"field_name":"keyword","operator":"CONTAINS","value":""}])
      }

    const removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    const handleClick = () => {
      setIsApplied(!isApplied);
      setIsVisible(!isVisible);
    }

  return (
    <>{isVisible && page!=="third"?
    <Card className="position">
    <Container>
      <h6 className="filter-heading">Filters</h6>
      {formValues.map((element,index) => {
          return (
            <>
            <div key={index}>
            <Row className="d-flex align-items-center justify-content-space-between mt-3">
            <Col xs={6}>
              <h6 className="text-left filter-subs">Keyword</h6>
            </Col>
            <Col xs={6} className="filter-dropdown">
              <div className="select-drop">
                <select className="form-select" aria-label="Default select example" name="operator" onChange={(e) => handleChange(e,index)}>
                  <option value="NOT_CONTAINS">Not Contains</option>
                  <option value="CONTAINS" selected>Contains</option>
                  <option value="EQUALS">Equals</option>
                  {/* <option value="3">IsEmpty</option>
                  <option value="3">Is not Empty</option> */}
                </select>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Form.Group className="keyword-placeholder-container">
              <Form.Control type="text" placeholder="kurta" name="value" value={element["value"]} onChange={(e) => handleChange(e,index)}/>
              <img src={closeIcon} alt="closing-Icon" className={index === 0?"d-none":"keyword-close"} onClick={() => removeFormFields(index)}></img>
            </Form.Group>
          </Row>
          </div>
          </>
          )
      })}
     
      <div className={formValues.length!==4?"d-flex align-items-center gap-3":"d-none"} onClick={() => addFormFields()}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mt-3"
      >
        <path
          d="M7 13.75C6.78333 13.75 6.60433 13.6793 6.463 13.538C6.321 13.396 6.25 13.2167 6.25 13V7.75H1C0.783333 7.75 0.604333 7.679 0.463 7.537C0.321 7.39567 0.25 7.21667 0.25 7C0.25 6.78333 0.321 6.604 0.463 6.462C0.604333 6.32067 0.783333 6.25 1 6.25H6.25V1C6.25 0.783333 6.321 0.604333 6.463 0.463C6.60433 0.321 6.78333 0.25 7 0.25C7.21667 0.25 7.396 0.321 7.538 0.463C7.67933 0.604333 7.75 0.783333 7.75 1V6.25H13C13.2167 6.25 13.396 6.32067 13.538 6.462C13.6793 6.604 13.75 6.78333 13.75 7C13.75 7.21667 13.6793 7.39567 13.538 7.537C13.396 7.679 13.2167 7.75 13 7.75H7.75V13C7.75 13.2167 7.67933 13.396 7.538 13.538C7.396 13.6793 7.21667 13.75 7 13.75Z"
          fill="#047857"
        />
      </svg>
      <span className="add-filter-text mt-3">Add Filter</span>
      </div>
    </Container>
    <Container className="d-flex gap-2 align-items-end justify-content-center btns-grp  mt-5">
      <Button className="btns btn-greyish" variant="outline-success" onClick={() => {setIsVisible(!isVisible)
      }
      }>Cancel</Button>
      <Button className="btns" style={{backgroundColor:"#4D4184",color:"#fff"}} onClick={handleClick}>Apply</Button>
    </Container>
  </Card>: isVisible && page === "third"?
  <Container>
    <Card style={{height:"12rem"}}>
      <Container className="mt-3">
          <h6>Price Range</h6>
        <Row className="mt-3">
          <InputGroup className="mb-3" as={Col}>
            <InputGroup.Text className="input-group-symbol" id="basic-addon1">₹</InputGroup.Text>
            <Form.Control
              placeholder="No min"
              aria-label="Username"
              aria-describedby="basic-addon1"
              />
        </InputGroup>
        
          <InputGroup className="mb-3" as={Col}>
            <InputGroup.Text id="basic-addon1" className="input-group-symbol">₹</InputGroup.Text>
            <Form.Control
              placeholder="No max"
              aria-label="Username"
              aria-describedby="basic-addon1"
              />
        </InputGroup>
         
        </Row>
      </Container>
      <Container className="d-flex gap-2 align-items-end justify-content-center btns-grp mt-5">
      <Button className="btns btn-greyish" variant="outline-success" onClick={() => setIsVisible(!isVisible)}>Cancel</Button>
      <Button className="btns" style={{backgroundColor:"#4D4184",color:"#fff"}}>Apply</Button>
    </Container>
    </Card>
  </Container>
  :""
}
    </>
  );
};

export default FilterkeywordModal;


// About Component:- Modal is for add filter keywords.