import React from 'react';
import searchIcon from '../../assets/svgs/searchIcon.svg'
import dataNotFoundIcon from '../../assets/svgs/NotFoundIcon.svg'

const DataNotFound = ({description}) => {
  return (
    <div className='d-flex justify-content-center flex-column gap-4 py-4 no-data-found-container'>
        <div className="d-flex justify-content-center align-items-center">
            <img alt='search' src={searchIcon}/>
            <p className='my-0'>No Data Found</p>
        </div>
        <div className="desc-container">
            {description ? <p className='my-0 text-muted'>{description}</p>:null}
        </div>
        <div className="not-found-icon-container">
            <img src={dataNotFoundIcon} alt={"Not Found"}/>
        </div>
    </div>
  )
}

export default DataNotFound
