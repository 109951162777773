import React from 'react';
import Breadcrumb from './breadcrumb';
import menus from "./menuList.json";
 function ReportsBreadCrumb() {
  
  function setStatusFalse(json) {
    json.forEach((item) => {
      if (item.hasOwnProperty("status")) {
        item.status = false;
      }
      if (item.hasOwnProperty("pages") && Array.isArray(item.pages)) {
        setStatusFalse(item.pages);
      }
    });
  }

  function updateStatus(json, url) {
    for (let i = 0; i < json.length; i++) {
      const item = json[i];
      if ((url !== "/" && url !== "/campaign-list" && url !== "/account-overview") && item.href === url) {
        item.status = true;
        return true;
      } else if (item.pages && item.pages.length > 0) {
        const found = updateStatus(item.pages, url);
        item.status = found;
        if (found) {
          return true;
        }
      } else if ((url === "/" || url === "/campaign-list" || url === "/account-overview") && item.href === url) {
        item.status = true;
        return true;
      }
    }
    return false;
  }
    const url = window.location.pathname;
    setStatusFalse(menus)
    updateStatus(menus, url);
   return (
    <div>
      <Breadcrumb items={menus} />
    </div>
  );
}
 export default ReportsBreadCrumb;