import { Modal } from "react-bootstrap";
import "./masterTargetingActionnAlertModal.css";
import infoIcon from "../../../assets/icons/infoOnHoverIcon.png";
import SecondaryBtnRb from "../../Buttons/secondaryBtnRb/secondaryBtnRb";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import campaignListSlice, {
  campaignListActions,
} from "../../../redux/slices/campaignList";

const MasterTagetinngActionAlertMModal = (props) => {
  const dispatch = useDispatch();
  const actionAlertModalVisibilty = useSelector(
    (state) => state.campaignListData.actionAlertModalVisibilty
  );
  const onYesClickHandler = () => {
    dispatch(campaignListActions.hideActionAlertModal());
    props.onYesClick();
  };

  const onNoClickHandler = () => {
    dispatch(campaignListActions.hideActionAlertModal());
    if(props.onNoClick) {
      props.onNoClick()
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      //   show={props.show}
      show={actionAlertModalVisibilty}
      // backdropClassName="targeting-action-alert-backdrop"
      dialogClassName="targeting-action-alert-dialog-box"

      //   state={actionAlertModalShow}
    >
      <Modal.Body>
        <div className="targeting-cation-aler-modal-connntainnr">
          <div>
            <img src={infoIcon}></img>
          </div>
          <div className="mx-3">{props.alertText}</div>
        </div>
        {props.alertInfo && props.alertInfo!== "" && <div className="alert-info">{props.alertInfo}</div>}
        <div className="btnn-conntainer-targeting-alert-mmodal">
          <SecondaryBtnRb
            className="alert-op-btn primary-inactive-btn"
            secondaryBtnText="NO"
            // onClick={props.noclickhandler}
            onClick={onNoClickHandler}
          ></SecondaryBtnRb>

          <SecondaryBtnRb
            className="alert-op-btn primary-active-btn"
            // onClick={props.yesclickhandler}
            id={props?.confirmationId}
            onClick={onYesClickHandler}
            secondaryBtnText="YES"
          ></SecondaryBtnRb>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default MasterTagetinngActionAlertMModal;
