import React from "react";
import { Form } from "react-bootstrap";

const CustomTextField = ({ placeholder, value, error, onChange }) => (
  <Form.Control
    type="text"
    size="lg"
    placeholder={placeholder}
    value={value}
    isInvalid={error}
    onChange={(e) => onChange(e.target.value)}
  />
);

export default CustomTextField;
