const LegendComponent = ({ legendColor, legendText }) => {
    return (
      <div className="pie-legend-sub">
        <span
          className="legends-dot"
          style={{ backgroundColor: legendColor }}
        ></span>
        <span className="legends-text">{legendText}</span>
      </div>
    );
  };

  export default LegendComponent;