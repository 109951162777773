import React from 'react';
import './customToast.css';

const CustomToast = ({closeToast,toastProps,toastDetails,toastType}) => {
  return (
    <div className='position-relative custom-toast-container'>
        <ul>
            {
              toastDetails?.length ?  toastDetails?.map((detail) => {
                    return <li className='toast-list-item' key={detail}>{detail}</li>
                }) : null
            }
        </ul>
    </div>
  )
}

export default CustomToast
