import axios from "axios";
const uploadMultipartFile = async (file, filename,fileType, uploadId) => {
    try {
        const fileSize = file.size;
        var NUM_CHUNKS = 1;

        if(fileSize > (40 * 1024 * 1024)){
            NUM_CHUNKS = 6;
        } else if(fileSize > (15 * 1024 * 1024)){
            NUM_CHUNKS = 3;
        } else if(fileSize > (10 * 1024 * 1024)) {
            NUM_CHUNKS  = 2;
        }

        const FILE_CHUNK_SIZE = (fileSize / NUM_CHUNKS);
        
        let promisesArray = []
        let start, end, blob

        for (let index = 1; index < NUM_CHUNKS + 1; index++) {
            start = (index - 1) * FILE_CHUNK_SIZE
            end = (index) * FILE_CHUNK_SIZE
            blob = (index < NUM_CHUNKS) ? file.slice(start, end) : file.slice(start)

            // (1) Generate presigned URL for each part
            let getUploadUrlResp = await fetch(`${process.env.REACT_APP_LOGIN_API}/api/aws/get-upload-url?fileName=${filename}&partNumber=${index}&uploadId=${uploadId}`);
            let presignedUrl = await getUploadUrlResp.json();

            let uploadResp = axios.put(
                presignedUrl,
                blob,
                { headers: { 'Content-Type': fileType } }
            )
            promisesArray.push(uploadResp)
        }

        let resolvedArray = await Promise.all(promisesArray)

        let uploadPartsArray = []
        resolvedArray.forEach((resolvedPromise, index) => {
            uploadPartsArray.push({
                ETag: resolvedPromise.headers.etag,
                PartNumber: index + 1
            })
        });

        // (3) Calls the CompleteMultipartUpload endpoint in the backend server

        let completeUploadResp = await fetch(`${process.env.REACT_APP_LOGIN_API}/api/aws/complete-upload`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fileName: filename,
                parts: uploadPartsArray,
                uploadId: uploadId
            })
        });
        let reposnse = await completeUploadResp.json();

        if('Location' in reposnse) {
            return reposnse.Location
        } else {
            throw "Something went wrong!"
        }
        // return completeUploadResp;

    } catch (err) {
        return 'ERR:' + err;
    }
}

export default uploadMultipartFile