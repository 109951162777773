import React, { useEffect, useState } from "react";
import ModalReactB from "../modal/ModalReactB";
import SwitchBtn from "../Buttons/switchBtn";
import "./settingDashboardLayout.css";
import PrimaryBtnRB from "../Buttons/primaryBtnRB.js/primaryBtnRB";
import SecondaryBtnRb from "../Buttons/secondaryBtnRb/secondaryBtnRb";
import { Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayoutSettingOpt from "../dashboardLayoutSettingOpt/dashboardLayoutSettingOpt";
import DashboardFilterSettingOptions from "../dashboardLayoutSettingOpt/dashboardFilterSettingOptions";
import closeIcon from "../../assets/icons/close_dashlayout_icon.png";
import { dashboardActions } from "../../redux/slices/dashboardSlice";
import SectionTitle from "../sectionTitle";
const SettingDashboardLayout = (props) => {
  const dispatch = useDispatch();
  const [activeBtn, setActiveBtn] = useState("layout");

  const dashboardLayoutSequenceReduxdata = useSelector(
    (state) => state.dashboardData.dashboardLayoutSequencedata
  );
  //sending drag data to dashboard to render layout accordingly
  useEffect(() => {
    props.dashboardLayoutSequencedata(dashboardLayoutSequenceReduxdata);
  }, [dashboardLayoutSequenceReduxdata]);

  const onButtonClickHandler = (e) => {
    setActiveBtn(e.target.id);
  };

  const onDashboardLayoutCloseIconHandler = () => {
    dispatch(dashboardActions?.dasboardLayoutSettingModalHide());
  };

  const modalHeading = (
    <div className="dashboard-layout-settings-head">
      <div className="d-flex flex-row dashboard-layout-setting-content-container">
        <div>
          <SectionTitle sectionTitleHeading="Set Dashboard Layout"></SectionTitle>
          <p className="small-grey-text">
            This will be the default layout when you login
          </p>
        </div>
        <div className="dash-layout-close-icon-container">
          <img
            src={closeIcon}
            onClick={onDashboardLayoutCloseIconHandler}
          ></img>
        </div>
      </div>
      <Stack direction="horizontal" gap={3}>
        <SecondaryBtnRb
          secondaryBtnText="Layout"
          className={
            activeBtn === "layout"
              ? "primary-active-btn"
              : "primary-inactive-btn"
          }
          id="layout"
          onClick={onButtonClickHandler}
        ></SecondaryBtnRb>
        <SecondaryBtnRb
          secondaryBtnText="Filters"
          className={
            activeBtn === "filter"
              ? "primary-active-btn"
              : "primary-inactive-btn"
          }
          id="filter"
          onClick={onButtonClickHandler}
        ></SecondaryBtnRb>
      </Stack>
    </div>
  );

  const mdalBodyJSX = (
    <div className="dash-set-modal-body">
      {activeBtn === "layout" ? (
        <DashboardLayoutSettingOpt></DashboardLayoutSettingOpt>
      ) : activeBtn === "filter" ? (
        <DashboardFilterSettingOptions></DashboardFilterSettingOptions>
      ) : (
        <DashboardLayoutSettingOpt></DashboardLayoutSettingOpt>
      )}
    </div>
  );

  return (
    <div
      className="col-md-4 modal-card  dashboard-setting-modal-container"
      onClick={() => props.dashboardLayoutSequence}
    >
      <ModalReactB
        show={props.show}
        heading={modalHeading}
        modalBodyRight={mdalBodyJSX}
        bsPrefix="modal"
        backdropClassName="backdrop"
        dialogClassName="dialogClassName"
        contentClassName="content"
      />
    </div>
  );
};

export default SettingDashboardLayout;
