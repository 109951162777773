import moment from "moment";

function convertDateFormatWithMoment(inputDate) {
  // Parse the input date string in "YYYY-MM-DD" format
  const parsedDate = moment(inputDate, 'YYYY-MM-DD');
 
  // Convert moment object to Date object
  const dateObject = parsedDate.toDate();

  return dateObject;
}

export default convertDateFormatWithMoment;