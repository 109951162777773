export const UserRouteAccessSetting = {
    "multiuser":{
        restrictedPages:[]
    },
    "singleuser":{
        restrictedPages:["/client-details","/history-logs"]
    },
    "beta_users":{
        restrictedPages:[]
    }
}

export const PageVersionsAccessSetting = {
    beta:{
        smart_ads:[
          ]
    },
    alpha:{

    }
}