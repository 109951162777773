import React, { forwardRef, useState } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
// import  dropdownbtnRB from "react-bootstrap/Dropdown";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import "./dropdownbutton.css";

function Dropdownbutton(props) {

  const optionClickHandler = (e) => {
    // console.log(e);
  }
  return (
    <div className="dashboard-container">
      <Dropdown >
        <Dropdown.Toggle

          id="dropdown-basic"
          size="sm"
          variant="outline-secondary"
          className={props.className? props.className + " dropdown-btn" : "dropdown-btn"}
        // style={{
        //   backgroundColor: "white",
        //   borderRadius: "24px",

        //   color: "#4C4F6A",
        // }}
        >
          {props.name}

        </Dropdown.Toggle>

        <Dropdown.Menu>{

          props.item ?
            props.item.map((el) => {
              return <DropdownItem onClick={optionClickHandler} value={props.value}>{el}</DropdownItem>
            })


            : null

        }  </Dropdown.Menu>

        {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
          <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
          <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
        </Dropdown.Menu> */}
      </Dropdown>
    </div>
  );
}

export default Dropdownbutton;
