import React from 'react';
import selectedImage from '../../assets/svgs/rightTick.svg'
import './tickbar.css';

const TickBar = ({handleClick,text,isSelected}) => {
  return (
    <button className={isSelected ? `tickbar-btn-selected tickbar-btn` : 'tickbar-btn'} value={text} onClick={ () =>  handleClick(text)}>
        {isSelected ? <div> <img src={selectedImage} alt='selected'/></div>:null}
        {text}
    </button>
  )
}

export default TickBar
