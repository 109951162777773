import { createSlice } from "@reduxjs/toolkit";
import ProductImage1 from '../../assets/images/SP_ProductImage/Product1.png'
import ProductImage2 from '../../assets/images/SP_ProductImage/Product2.png'

const initialCampaignCreationState = {
    SPProductTargetingProductData:[
        {
          Name:"Men’s sweatshirt & hoodies",
          Desc:"128-124",
          image:ProductImage1,
          bid:"₹4.75",
          bidRange:"₹2.19 - ₹8.36"
        },
        {
          Name:"Men’s sweatshirt & hoodies",
          Desc:"128-124",
          image:ProductImage2,
          bid:"₹4.75",
          bidRange:"₹2.19 - ₹8.36"
        },
        {
          Name:"Men’s sweatshirt & hoodies",
          Desc:"128-124",
          image:ProductImage2,
          bid:"₹4.75",
          bidRange:"₹2.19 - ₹8.36"
        },
        {
          Name:"Men’s sweatshirt & hoodies",
          Desc:"128-124",
          image:ProductImage2,
          bid:"₹4.75",
          bidRange:"₹2.19 - ₹8.36"
        },
        {
          Name:"Men’s sweatshirt & hoodies",
          Desc:"128-124",
          image:ProductImage2,
          bid:"₹4.75",
          bidRange:"₹2.19 - ₹8.36"
        },
        {
          Name:"Men’s sweatshirt & hoodies",
          Desc:"128-124",
          image:ProductImage2,
          bid:"₹4.75",
          bidRange:"₹2.19 - ₹8.36"
        },
       
      ],
      getPortfolio : [],
}
const CampaignCreationSlice = createSlice({
    name: "campaigncreationslice",
    initialState: initialCampaignCreationState,


    reducers: {
        // dasboardLayoutSettingModalShow(state) {
        //     state.dasboardLayoutSettingModal = true
        // },
        spProductsData(state,actions) {
            state.SPProductTargetingProductData = actions.payload;
        },
        getPortfolio(state,actions){
          state.getPortfolio = actions.payload;
        }
        // dasboardLayoutSettingModalHide(state) {
        //     state.dasboardLayoutSettingModal = false
        // },
        // dasboardOverviewCardSettingModalHide(state) {
        //     state.dasboardOverviewCardSettingModal = false
        // },
        // dasboardOverviewCardSettingModalShow(state) {
        //     state.dasboardOverviewCardSettingModal = true
        // },
        // dashboardLayoutSequencedata(state, actions) {
        //     state.dashboardLayoutSequencedata = actions.payload;
        // },
        // dashboardSideBarVisibiltyStateShow(state) {
        //     state.dashboardSideBarVisibiltyState = true
        // },
        // dashboardSideBarVisibiltyStateHide(state) {
        //     state.dashboardSideBarVisibiltyState = false

        // }

        // uploadedDocsData(state, action) {
        //     state.poaDocumentName = action.payload.poaDocumentName;
        //     state.poiImage = action.payload.poiImage;
        //     state.poaFrontImage = action.payload.poaFrontImage;
        //     state.poaBackImage = action.payload.poaBackImage;
        // },

    },
});

//For Actions
export const CampaignCreationActions = CampaignCreationSlice.actions;

export default CampaignCreationSlice;


// const manualUploadedPoiImgae = useSelector((state) => state.poiDetailsData.poiImage);
// dispatch(poaDetailsDataAction.poaFrontImageData({
//     poaFrontImage: uploadedPoaFrontImg
//   }))