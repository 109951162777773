import { createSlice } from "@reduxjs/toolkit";
import dashboardSlice from "./dashboardSlice";

const initialEditTargetingState = {
    amazon_product_targeting:[],
    amazon_keyword_targeting:[],
}

const editTargetingSlice = createSlice({
    name:"editTargeting",
    initialState:initialEditTargetingState,

    reducers: {
        addProduct(state,action) {
            state.push(action.payload)
        },
        addKeyword(state,action) {
            state.push(action.payload)
        },
        deleteProduct(state,action) {
            state.filter((product) => product.id !== action.payload)
        },
        deleteKeyword(state,action) {
            state.filter((product) => product.id !== action.payload)
        }
    }
})


export const editTargetingActions = editTargetingSlice.actions;

export default editTargetingSlice