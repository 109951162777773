import { useState,useMemo,useCallback,useRef } from "react";
import { Button, Spinner, Stack } from "react-bootstrap";
import { uploadFile } from "../../helper/uploaders/fileUploader";
import { useGlobalContext } from "../../context/global";
import pullUploadData from "../../helper/uploaders/pullUploadData";
import updateFileUrl from "../../helper/uploaders/updateFileUrl.js";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../loader";
import {
  TOAST_POSITION_TOP_RIGHT,
  TOAST_SUCCESS,
  TOAST_DANGER,
} from "../../constant/HardTypes";
import "./uploader.css";
import uploadImage from "../../assets/svgs/upload.svg";
import getClientData from "../../helper/clientData/getClientData";
import { Crop, Download, Upload } from "react-bootstrap-icons";
import { useEffect } from "react";
import axios from "axios";
import TableWithLazyLoading from "./TableWithLazyLoading";
import SearchBarForCampaignListOnly from "../../components/searchBarForCampaignListOnly";
import dropdownIcon from "../../assets/icons/downArrowForSplitButton.svg";
import MultiSelect from "../../components/DropDown/Multiselect";
import CustomDropDown from "../../components/DropDown/CustomDropDown";
import FilterChips from "../../components/FilterChips/FilterChips";
import DynamicFilterModal from "../../components/modal/DynamicFilterModal";
import LazyLoadSpinner from '../spinners/LazyLoadSpinner';
import { encodeData } from "../../helper/common/encodeData";
import { masterApi } from "../../apis/masterApi";
import TableRb from "../../components/Tables/tableRb";
import Breadcrumb from '../../components/Breadcrumb/ReportsBreadCrumb';
import useInitialLoad from '../../hooks/useInitialLoad';
import {toast} from 'react-toastify';
import useUserDetails from "../../helper/userData/useUserDetails.js";


function Uploader(props) {
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const clientId = JSON.parse(localStorage.getItem("clientId"));
  const clientAccountType = JSON.parse(localStorage.getItem("client_account_type"));
  const vendor_csv_catalog_url = JSON.parse(localStorage.getItem("csvUrl"));
  const { showAlert } = useGlobalContext();
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [uploadButtonStatus, setUploadButtonStatus] = useState(false);
  const [lastFetchedData, setLastFetchedData] = useState(false);
  const downloadCsvResource = `${process.env.REACT_APP_CATALOG_API}//client/${clientId}/products/download?source=amazon`;
  const downloadSampleCSV = `${process.env.REACT_APP_S3_API}/d30f6fb8-0e83-40fa-ae86-c625b97aa267/623777e6e75c4232871ab6f78a246b6d_sample.csv`;
  const downloadUnSyncedAsins = `${process.env.REACT_APP_ADS_API}/client/${clientId}/cursor:unsynced-data?source=amazon&cursor_purpose=AMAZON_CATALOG`

  const headerData = [{"fieldName":"ASIN","id":"asin"},{"fieldName":"Name","id":"name"},{"fieldName":"Brand","id":"brand"},{"fieldName":"SKU","id":"sku"},{"fieldName":"Parent Asin","id":"parent_asin"},{"fieldName":"Category","id":"category"},{"fieldName":"Gender","id":"gender"},{"fieldName":"Size","id":"size"},{"fieldName":"Color","id":"color"},{"fieldName":"Seller Id","id":"seller_id"},{"fieldName":"Parent Category","id":"parent_category"},{"fieldName":"Quantity","id":"quantity"},{"fieldName":"Availability","id":"availability"},{"fieldName":"Instock Variant","id":"instock_variants"},{"fieldName":"Price","id":"sale_price"},{"fieldName":"MRP","id":"maximum_retail_price"}];
  // Variables For DropDowns
  //const [categoryDropdownData,setCategoryDropdownData] = useState([])

  const [allLoaders, setAllLoaders] = useState({ mainLoader: false });
  const [initialLoad,setInitialLoad] = useInitialLoad()
  const [dataSource,setDataSource] = useState("Not Found")
  const dataSourceMapper = {
    "sheet":"Google Sheet",
    "job":"Amazon"
  }

  //pagination data
  const [paginationAction, setPaginationAction] = useState({
    currentPage: 1,
    currentOffSet: 0,
  });
  const pageLimit = 50;
  const [displayedData, setDisplayedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const tableRef = useRef(null);
  const [userDetails]  = useUserDetails()
  const isMultiuser = userDetails?.user?.multiUser === "yes"

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_ADS_API}/client/${clientId}/cursor?source=amazon&cursor_purpose=AMAZON_CATALOG`
      )
      .then((response) => {
        if (response?.data?.data) {
          setLastFetchedData(response?.data?.data[0]);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const CSVToArray = (csvString) => {
    // Split the CSV string into individual values
    const rows = csvString.split('\n');
    const array = [];

    rows.forEach((row) => {
      // Split the row into individual values, accounting for quoted values
      const values = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
      // Remove surrounding quotes from each value
      const sanitizedValues = values.map((value) => value.replace(/^"(.*)"$/, '$1'));
      // Push the sanitized values to the array
      array.push(sanitizedValues);
    });

    return array;
  };

  const uploadFileFn = async (e) => {
    if (e.target.files && e.target.files[0]) {
      setInitialLoad(true);
      setUploadButtonStatus(true);
      let fileUrl = await uploadFile(e.target.files[0]);
      //Check for error
      if (fileUrl.includes("ERR:")) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: "Error in While Uploading File!",
        // });
        toast.error('Error while uploading File')
      } else {
        const result = await updateFileUrl(fileUrl);
        if (result?.success) {
          const result = await pullUploadData(clientId);
          if (result) {
            // If All  API's returns  Success
            // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
            //   backgroundColor: TOAST_SUCCESS,
            //   title: "Success",
            //   description: "CSV Uploaded!",
            // });
            toast.success('CSV uploaded!')
            setInitialLoad(false);
            const result = await getClientData(clientId);
            setUploadButtonStatus(false);
          } else {
            // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
            //   backgroundColor: TOAST_DANGER,
            //   title: "Error",
            //   description: "Error in Uploading File!",
            // });
            toast.error('Error while uploading file!')
            setInitialLoad(false);
            setUploadButtonStatus(false);
          }
        }
      }
    } else {
      toast.error('No File Selected')
    }
  };

  const openFileLoader = (e) => {
    if (clientAccountType !== 'seller') {
      let el = document.getElementById("fileUploader");
      el.click();
    } else {
      toast.error('Only For vendor Accounts')
    }
  };

  const unSyncedClick = () => {
    if (clientAccountType !== 'seller') {
      window.location.href = downloadUnSyncedAsins;
    } else {
      alert("only for vendor accounts.")
    }
  }

  const sampleCSVClick = () => {
    if (clientAccountType !== 'seller') {
      window.location.href = downloadSampleCSV;
    } else {
      alert("only for vendor accounts.")
    }
  }

  const downloadClick = () => {
    window.location.href = downloadCsvResource
  }

  //All filter data 
  const [statFilters, setStatFilters] = useState([]);
  const [filters, setFilters] = useState({
    targeting: "",
  });

  const [searchFiltersState, setSearchFiltersState] = useState({
    category: [],
    parent_category: [{id:"All",name:"All",platform_id:"All"}, {id:"Down",name:"Down",platform_id:"Down"},{id:"Up",name:"Up",platform_id:"Up"}],
    availability: "All",
    search_type:"ASIN",
  });

  const [selectedDropDownFilters, setSelectedDropDownFilters] = useState({
    category: [],
    parent_category: [],
    availability: "",
    search_type:"ASIN"
  });

  const onSearchBarHandler = (e) => {
    if (e.target.value === "" || e.key === "Enter") {
      setFilters({ ...filters, targeting: e.target.value });
    }
  };

  const onSearchBarOnchange = (e) => {
    
  };

  // ********** Methods For All DropDown Filters Change *********
  const handleDropDownsFilterChange = (e, type) => {
    setSelectedDropDownFilters({ ...selectedDropDownFilters, [type]: e });
  };

  const [isTypeChange,setIsTypeChange] = useState(false);
  const filterCampaigns = (e, type) => {
    if((type === 'search_type' && selectedDropDownFilters.search_type !== e.target.value) || (type === 'availability' && selectedDropDownFilters.availability !== e.target.value)){
      setSelectedDropDownFilters({
        ...selectedDropDownFilters,
        [type]: e.target.value,
      });

      if(type === 'search_type'){

        setFilters({ ...filters, targeting:"" });
        document.getElementById("ma-global-targeting-list").value = "";
        setIsTypeChange(true);
      }
    }
  };

  useEffect(() => {
    // Getting All the categories to display in the dropdown
    getAllCategories();
  }, []);

  const getAllCategories = async () => {
    try {
      const response = await fetch(
        `https://catalogapi.adyogi.com/client/${clientId}/categories?source=amazon&fields=name,id&limit=1000`
      );
      const result = await response.json();
      if (result?.data) {
        const { data } = result;
        // const modifiedData = data.map((dataName) => dataName.name);
       
        // const cData = data?.map((category) => category?.name);
        //setCategoryDropdownData(cData);
        setSearchFiltersState({
          ...searchFiltersState,
          category: data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const categoryDropdownData =
  searchFiltersState &&
  searchFiltersState?.category?.map((category) => category?.name);

  const parentCategoryData =
  searchFiltersState &&
  searchFiltersState?.parent_category?.map((pcategory) => pcategory?.name);

  function keyMapper(id) {
    switch (id) {
      case "category":
        return "category";
      case "parent_category":
        return "parent_category";
      case "availability":
        return "availability";
      default:
        return id;
    }
  }

  const generateFilteringArray = (selectedFilters, filters) => {
    /**
     * This function will generate the API Filtering Array
     * @{*} Params:- selectedFilters,AllAvailable Filters Data , Both should be object
     * @{*} Returns:- returns Filtering Array For API Call
     */

    const inOperatorKeys = [
      "category",
      "parent_category",
    ];
    const emptyArrayCases = ["All"];
    const modificationArraysKeys = ["category", "parent_category"];
    const FilteringArray = Object.entries(selectedFilters).flatMap(
      ([key, value]) => {
        if (value?.length < 1 || emptyArrayCases.includes(value) || key === "search_type") return [];
        const keyToBeSent = keyMapper(key);
        const operator = inOperatorKeys.includes(keyToBeSent) ? "IN" : "EQUALS";
        const valueToBeSentInAPI = modificationArraysKeys.includes(key)
          ? value
          : key === "availability"
          ? value === "Out of Stock"
            ? "Out of Stock"
            : "Instock"
          :
          key === "campaign_objective" ? value?.map((data) => {
            if(data === "Retargeting") return "Retarget"
            return data
          })
          : value;
        return [
          {
            field_name: keyToBeSent,
            operator: operator,
            [inOperatorKeys.includes(keyToBeSent) ? "values" : "value"]:
              valueToBeSentInAPI,
          },
        ];
      }
    );
    return FilteringArray;
  };

  async function getOptimiseBidsRows(offSet) {
    
    let filtering = generateFilteringArray(
      selectedDropDownFilters,
      searchFiltersState
    );
   
    if (filters?.targeting !== "" && !isTypeChange) {
      filtering = filtering.concat([
        {
          field_name: selectedDropDownFilters.search_type.toLowerCase(),
          operator: "EQUALS",
          value: filters?.targeting?.trim(),
        },
      ]);
    }

    if (statFilters?.length > 0) {
      filtering = filtering.concat([...statFilters]);
    }
    try {
      setAllLoaders({ ...allLoaders, mainLoader: true });
      const dataForAPI = {
        source: "amazon",
        limit: pageLimit,
        page: offSet,
        filtering: encodeData(filtering),
      };
 
      let result = await axios.get(
        `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/products?source=amazon&limit=${pageLimit}&filtering=${encodeData(filtering)}`
      );
      //const result = await masterApi(dataForAPI, "getProducts", true);
      if (result?.status !== 200) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: `1Some Unknown Error Occurred while Fetching Global Bids`,
        // });
        toast.error('Some Unknown Error Occurred while Fetching Global Bids')
      }
      if (result?.data?.data) {
        const { data } = result?.data;
        const source = dataSourceMapper[data[0]?.data_source?.toLowerCase()] ?? "Unknown*"
        setDataSource(source) 
        setDisplayedData([...data]);
      }
      setAllLoaders({ ...allLoaders, mainLoader: false });
      setInitialLoad(false)
      setIsTypeChange(false)
    } catch (error) {
      setAllLoaders({ ...allLoaders, mainLoader: false });
      setIsTypeChange(false)
      setInitialLoad(false)
    }
  }
  



  useEffect(() => {
    getOptimiseBidsRows(0);
   }, [selectedDropDownFilters, filters,statFilters]);//[applyCalendar, selectedDropDownFilters, filters]);

   useEffect(() => {
    setPaginationAction({...paginationAction,currentOffSet:0,currentPage:1})
    },[selectedDropDownFilters,filters,statFilters])

  // Chip Filters Variable
  let dataForChips = useMemo(() => generateChipFilters(selectedDropDownFilters),[selectedDropDownFilters]) ;

   // *************** Function to generate Chip Filters ***************

   function generateChipFilters(filters) {
    return Object.entries(filters).flatMap(([key, value]) => {
      if (value.length < 1 || value === "All" || key === 'search_type') return [];
      return {
        fieldNameOfSelectedBtn: key,
        field_name: key,
        checkedValue: !Array.isArray(value) ? [value] : value,
      };
    });
  }

  const onCancelClickRemoveFilteringHandlerr = useCallback(function (type) {
    setSelectedDropDownFilters({
      ...selectedDropDownFilters,
      [type]: type === "availability" ? "All" : type === "search_type" ? "ASIN" : [],
    });
    if(statFilters?.length) {
      setActiveCancelStatFilterField(type)
      setStatFilters((prev) => {
        return [...prev].filter((data) => data.field_name !== type)
      })
    }
  },[selectedDropDownFilters,statFilters]) 

const  onResetButtonClick = useCallback(function () {
  setSelectedDropDownFilters({
    ...{
      category: [],
      parent_category: [],
      availability: "",
      search_type: "ASIN"
    },
  });
  setFilters((prev) => {
    return {...prev,targeting:""}
  })
  document.getElementById("ma-global-targeting-list").value = "";
  setStatFilters([]);
  setActiveCancelStatFilterField("reset")
},[selectedDropDownFilters,filters]) 

  // FilterModal

  // Filter Modal Methods

  const columns = [
    {
      field_name: "Price",
      field_key: "sale_price",
      minValue: "-",
      maxValue: "-",
    },
    
  ];
  const [activeCancelStatFilterField,setActiveCancelStatFilterField] = useState("")

  const getAppliedFilters = (appliedFilters) => {
    const dataWithFilters = appliedFilters?.normalFilters?.concat(
      appliedFilters?.percentageFilters
    );
    const data = transformData(dataWithFilters);
    setStatFilters([...data]);
  };

  function transformData(input) {
    const output = [];
    for (let i = 0; i < input?.length; i++) {
      const obj = input[i];

      // Check if the object has non-empty and non-zero values for minRange or maxRange
      if (
        (obj.minValue !== "" && obj.minValue !== 0 && obj.minValue !== "-") ||
        (obj.maxValue !== "" && obj.maxValue !== 0 && obj.maxValue !== "-")
      ) {
        // Add a new object to the output array with "GREATER_THAN" operator and the "minRange" value, if minRange is not empty or zero
        if (obj.minValue !== "" && obj.minValue !== 0 && obj.minValue !== "-") {
          output.push({
            field_name: obj?.field_key.toLowerCase(),
            operator: "GREATER_THAN_EQUAL_TO",
            value: obj.minValue.toString(),
          });
        }

        // Add a new object to the output array with "LESSER_THAN" operator and the "maxRange" value, if maxRange is not empty or zero
        if (obj.maxValue !== "" && obj.maxValue !== 0 && obj.maxValue !== "-") {
          output.push({
            field_name: obj?.field_key.toLowerCase(),
            operator: "LESS_THAN_EQUAL_TO",
            value: obj.maxValue.toString(),
          });
        }
      }
    }
    return output;
  }

  //Lazyloading area
  const handleScroll = async () => {
    const container = tableRef.current;
    let { currentPage, currentOffSet } = paginationAction;
    // Keep track of last scroll position
    const lastScrollTop = container.getAttribute("data-scroll-top") || 0;

    // Determine scroll direction
    const scrollDirection = container.scrollTop > lastScrollTop ? "down" : "up";

    // Update last scroll position
    container.setAttribute("data-scroll-top", container.scrollTop);
    if (
      scrollDirection === "down" &&
      Math.ceil(container.scrollTop + container.clientHeight) >=
      container.scrollHeight
      && displayedData?.length >= (pageLimit-1)
    ) {
      currentOffSet = currentOffSet + pageLimit;
      currentPage = currentOffSet / pageLimit + 1;
      if (!isLoading) {
        loadMoreData(currentPage*pageLimit);
        setPaginationAction({
          ...paginationAction,
          currentPage,
          currentOffSet,
        });
      }
    }
  };

  const loadMoreData = async (currentPage) => {
    setIsLoading(true)
    let filtering = generateFilteringArray(
      selectedDropDownFilters,
      searchFiltersState
    );
   
    if (filters?.targeting !== "" && !isTypeChange) {
      filtering = filtering.concat([
        {
          field_name: selectedDropDownFilters.search_type.toLowerCase(),
          operator: "EQUALS",
          value: filters?.targeting,
        },
      ]);
    }

    if (statFilters?.length > 0) {
      filtering = filtering.concat([...statFilters]);
    }
    try {
      let result = await axios.get(
        `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/products?source=amazon&limit=${currentPage}&filtering=${encodeData(filtering)}`
      );
      
      if (result?.data?.data) {
        const { data } = result?.data;
        setDisplayedData([...data]);
        setDisplayedData([...displayedData, ...data]);
      } else {
          // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //   backgroundColor: TOAST_DANGER,
          //   title: "Error",
          //   description: `Some Unknown Error Occurred while Fetching Global Bids`,
          // });
          toast.error('Some Unknown Error Occurred while Fetching Global Bids')
      }
      setIsTypeChange(false)
      setIsLoading(false)
    } catch (error) {
      setIsTypeChange(false)
      setIsLoading(false)
    }
  };

  let tableBody = (
    <>
            <div className="main-campaignlist-table-body-container master-ads-main-table-wrapper setup-table" ref={tableRef} onScroll={handleScroll}>
                    {displayedData.length > 0 ? (<table className="master-ads-table setup-table reports">
                      {/* Render table header */}
                      <thead className="fixed-table">
                        <tr className="main-table-row-fixed">
                          {headerData.map((header, index) => (
                            <th key={index} className="targeting-table-heads">{header.fieldName}</th>
                          ))}
                        </tr>
                      </thead>
                      {/* Render table body */}
                      <tbody className='another-tbody'>
                        {displayedData.map((row, index) => (
                          <tr key={index}>
                            {headerData.map((data, index) => (
                              <td key={index}>
                                {row[data.id]}
                              </td>
                             ))} 
                          </tr>
                        ))}
                      </tbody>
                    </table>) : (<p>No data available</p>)}
                    {isLoading && displayedData.length > 1 && <div className="lazy-loading-container d-flex p-5 justify-content-center align-items-center w-100">
                      <LazyLoadSpinner text={"Loading "+pageLimit+" More data..."} />
                    </div>}
                  </div>
    </>
  );
  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box-master-ads"
    >

      <div className="campaign-list-container">
        <input
          id="fileUploader"
          style={{ display: "none" }}
          onChange={uploadFileFn}
          type="file"
          accept="text/CSV, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
        <Loader visible={initialLoad}></Loader>
        <div className="vstack minBreakpoint-xs">
          <Breadcrumb />
          <div className="d-flex align-item-center  hstack minBreakpoint-xs mb-3"><div className="section-title-container"><h3 className="title">Setup</h3></div></div>
          <div className="mb-4 d-flex align-items-center justify-content-between">
            <div>
            <button type="button" className="download-button btn btn-outline-dark mr-3" onClick={downloadClick}>
              <a download="">Download CSV</a>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor"><path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path><path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path></svg>
            </button>

            <button type="button" className="dowload-button btn btn-outline-success mr-3" onClick={openFileLoader}>
              Upload CSV
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor"><path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path><path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"></path></svg>
            </button>

            <button type="button" className="btn btn-outline-danger mr-3" onClick={unSyncedClick}>
              <a download="">Get Un-Synced Asins</a>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor"><path d="M3.5.5A.5.5 0 0 1 4 1v13h13a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2H3.5a.5.5 0 0 1-.5-.5V4H1a.5.5 0 0 1 0-1h2V1a.5.5 0 0 1 .5-.5zm2.5 3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4H6.5a.5.5 0 0 1-.5-.5z"></path></svg>
            </button>
            <button type="button" className="btn btn-outline-info" onClick={sampleCSVClick}>
              <a download="">Download Sample CSV</a>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor"><path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path><path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path></svg>
            </button>
            </div>
            <div className="setup-info-container d-flex flex-column align-items-end  justify-content-end">
           {  isMultiuser ?  <small className="m-0">Data source:- {dataSource}</small> : null}
            <small>
              Last Fetch Status:-{" "}
              {lastFetchedData?.last_fetch_status === "PENDING" ? (
                <>
                  Pending... ({lastFetchedData?.last_fetched_row}/
                  {lastFetchedData?.total_rows}) Rows Fetched
                </>
              ) : lastFetchedData?.last_fetch_status === "SUCCESS" ? (
                <>
                  Success ({lastFetchedData?.last_fetched_row}/
                  {lastFetchedData?.total_rows}) Rows Fetched
                </>
              ) : (
                <>
                  Unable to fetch
                </>
              )}
            </small>
          </div>
          </div>
          <div className="mt-config-row bottom-margin">
            <Stack className="mt-config-btns-container flex-wrap">
              <Stack direction="horizontal"  className="list-pages-search-input-container mb-1">
                <Stack direction="vertical" className="justify-content-end align-item-end">
                  <SearchBarForCampaignListOnly
                    placeholder={"Search for "+selectedDropDownFilters?.search_type}
                    value={filters?.campaign}
                    searchBarForListPage={true}
                    onChange={onSearchBarOnchange}
                    onKeyUp={onSearchBarHandler}
                    isCancelIconNotVisible={true}
                    id="ma-global-targeting-list"
                    className="mt-custom-search-bar"
                    dynamicWidth="search-input-width-for-listing-pages"
                  ></SearchBarForCampaignListOnly>
                </Stack>
              </Stack>
              <Stack direction="horizontal" className="d-flex justify-content-center flex-wrap">
              <Stack className="mt-filter-gap mb-1" direction="horizontal">
                <CustomDropDown
                  title={"Search type"}
                  data={["Size", "Color","ASIN","Name"]}
                  filterCampaigns={filterCampaigns}
                  targettingValue={selectedDropDownFilters?.search_type}
                  type={"search_type"}
                  src={dropdownIcon}
                />
                </Stack>
                <Stack className="mt-filter-gap mb-1 setup-dropdown" direction="horizontal">
                  <MultiSelect
                    title={"Category"}
                    options={categoryDropdownData}
                    defaultSelected={selectedDropDownFilters?.category}
                    isSearch={true}
                    placeHolder={"Search Category"}
                    onApply={(e) => {
                      handleDropDownsFilterChange(e, "category");
                    }}
                    classes={"setup-filter"}
                  />
                </Stack>
                {/* <Stack className="mt-filter-gap mb-1 setup-dropdown" direction="horizontal">
                  <MultiSelect
                    title={"Parent Category"}
                    options={parentCategoryData}
                    defaultSelected={selectedDropDownFilters?.parent_category}
                    isSearch={true}
                    placeHolder={"Search Parent Category"}
                    onApply={(e) => {
                      handleDropDownsFilterChange(e, "parent_category");
                    }}
                    classes={"setup-filter"}
                  />
                </Stack> */}
                <Stack className="mt-filter-gap mb-1" direction="horizontal">
                <CustomDropDown
                  title={"Availability"}
                  data={["All", "In Stock", "Out of Stock"]}
                  filterCampaigns={filterCampaigns}
                  targettingValue={selectedDropDownFilters?.availability}
                  type={"availability"}
                  src={dropdownIcon}
                />
                </Stack>
              </Stack>
            </Stack>
          </div>
          <div className="mt-2">
            <DynamicFilterModal
              columns={columns}
              percentageColumnRequired={false}
              percentageColumns={[]}
              cancelFilterFor={activeCancelStatFilterField}
              getAppliedFilters={getAppliedFilters}
              alignment={"left"}
            />
          </div>
          <div>
          <Stack className={ dataForChips.length ? "pt-3 d-flex flex-row" : "hide"}>
          <FilterChips
              filterChipList={dataForChips}
              onCancelClickRemoveFilteringHandler={
                onCancelClickRemoveFilteringHandlerr
              }
              isResetButtonNeeded={true}
              onResetButtonClick={onResetButtonClick}
            ></FilterChips>
            </Stack>
          </div>

          <div className="optimise-bids-table-wrapper setup-table">
            <Stack className="optimise-bids-main-table setup-table">
              <TableRb tableHeads={headerData} shimmer={allLoaders?.mainLoader} table={tableBody}></TableRb>
            </Stack>
          </div>

          {/* <Stack className="d-flex flex-row gap-5 main-container-setup justify-content-center align-items-center">
            <input
              id="fileUploader"
              style={{ display: "none" }}
              onChange={uploadFileFn}
              type="file"
              accept="text/CSV, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            <div className="card-container-for-setup">
              <div className="card-body-container d-flex flex-column">
                <div className="setup-info-container mt-2 mb-2">
                  <h5 style={{ fontSize: "16px" }}>
                    Last Fetch Status:-{" "}
                    {lastFetchedData?.last_fetch_status === "PENDING" ? (
                      <>
                        Pending... ({lastFetchedData?.last_fetched_row}/
                        {lastFetchedData?.total_rows}) Rows Fetched
                      </>
                    ) : lastFetchedData?.last_fetch_status === "SUCCESS" ? (
                      <>
                        Success ({lastFetchedData?.last_fetched_row}/
                        {lastFetchedData?.total_rows}) Rows Fetched
                      </>
                    ) : (
                      <>
                        Unable to fetch
                      </>
                    )}
                  </h5>
                </div>
                <div className="setup-buttons-container d-flex justify-content-between mb-3">
                  <Button className="download-button" variant="outline-dark" onClick={downloadHandle}>
                    {" "}
                    <a href={downloadCsvResource} download>
                      Download CSV
                    </a>{" "}
                    <Download />
                  </Button>
                  <Button
                    className="dowload-button"
                    disabled={uploadButtonStatus}
                    variant="outline-success"
                    onClick={openFileLoader}
                  >
                    {" "}
                    {uploadButtonStatus ? "Uploading..." : "Upload CSV"}
                    {uploadButtonStatus ? (
                      <Spinner size="small" variant="grow" />
                    ) : (
                      <Upload />
                    )}{" "}
                  </Button>
                  <Button variant="outline-danger">
                    <a href={downloadUnSyncedAsins} download>
                      Get Un-Synced Asins
                    </a>{" "}
                    <Crop />
                  </Button>
                </div>
                <Button variant="outline-info">
                  <a href={downloadSampleCSV} download>
                    Download Sample CSV
                  </a>{" "}
                  <Download />
                </Button>
              </div>
            </div>
          </Stack> */}
        </div>
      </div>



    </div>

  );
}

export default Uploader;
