import { useEffect, useState, useRef } from "react";
import { Dropdown, Nav, Tab } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import CheckboxRb from "../../../Buttons/checkbox/checkbox";
import Dropdownbutton from "../../../Buttons/dropdownbutton";
import RadioButtonRb from "../../../Buttons/radioButtonRb/radioButtonRb";
import SearchbarForEditCampaign from "../../../searchbarForEditCampaign";
import SwitchBtn from "../../../Buttons/switchBtn";
import TableRb from "../../../Tables/tableRb";
import "./SpKeywordTargeting.css";
import FilterkeywordModal from "./FilterkeywordModal";
import { initialAdgroupCreationState,initialCreativesCreationState,initialCampaignCreationState } from "../../../../constant/campaignCreationDefaultConfigs";
import { masterApi } from "../../../../apis/masterApi";
import SpAutomatic from "../../../../pages/SP_Automatic/SpAutomatic";
import handleCss from "../../../../constant/handleCss";
import SpKeywordTargeting from "../../CampaignCreationPages/SpKeywordTargeting";
import PrimaryBtnRB from "../../../Buttons/primaryBtnRB.js/primaryBtnRB";
import SPProductTargeting from "../../CampaignCreationPages/SP_Product_Targeting/SPProductTargeting";
import SDProductTargeting from "../../CampaignCreationPages/SD_Product_Targeting/SDProductTargeting";
import CampaignType from "../../CampaignCreationPages/CampaignType";
import SpBrandKeyword from "../../CampaignCreationPages/SP_Brand_keyword/SPBrandKeyword";
import AdDetails from "../../CampaignCreationPages/AdDetails";
import SPBrandProduct from "../../CampaignCreationPages/SP_Brand_Product/SPBrandProduct";
import SDBrandProduct from "../../CampaignCreationPages/SD_Brand_Product/SDBrandProduct";
import PortfoliCreationModal from "../PortfoliCreationModal";
import SDBrandAudience from "../../CampaignCreationPages/SD_Brand_Audience/SDBrandAudience";
import { useGlobalContext } from "../../../../context/global";
import { useSelector } from "react-redux";
import Loader from "../../../loader";
import { useNavigate } from "react-router-dom";
import Breadcrumb from '../../../Breadcrumb/ReportsBreadCrumb';

export default function KeywordTargeting(props) {
  // let dataHead = ["KEYWORDS", "Match Type ", "SUGG.BID"];
  // let dummyData = [[1,2,3],[4,5,6]];
  // State to update the modal
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const [keywords, setKeywords] = useState(
    JSON.parse(localStorage.getItem("keywords"))
  );
  const [successContainer, setSuccessContainer] = useState({
    success: false,
    msg: "",
  });
  const [currentType, setCurrentType] = useState("");
  const { campaignType, setCampaignType, isLoaderVisible,setAdGroupData,adGroupData,setSelectedCategory,setCampaigns,campaigns,dispatchErrors,setAdSetData } = useGlobalContext();
  const [show, setShow] = useState(false);
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );

  const { success, msg } = successContainer;
  const nameRef = useRef();

  const onDelete = (id) => {
    let filteredKeywords = keywords.filter((keyword) => id !== keyword.id);
    setKeywords(filteredKeywords);
    localStorage.setItem("keywords", JSON.stringify(filteredKeywords));
  };

  // UseRef to add event Listener
  const modalRefFirst = useRef();
  const modalRefSecond = useRef();

  // State to set different pages
  const [page, setPage] = useState("");
  const [dynamicHeaders, setDynamicHeaders] = useState([
    "Keywords",
    "Match Type",
    "Sugg Bidd",
  ]);
  const [makeDefault, setMakeDefault] = useState(false);
  const setTableHeaders = () => {
    if (page === "third") {
      setDynamicHeaders(["All Categories", "Suggested Bid |||"]);
    } else {
      setDynamicHeaders(["Keywords", "Match Type", , "Sugg Bidd"]);
    }
  };

  // useEffect(() => {
  //   let modalHandler = (e) => {
  //     //console.log(e.target)
  //     if (!modalRefFirst.current.contains(e.target)) {
  //       setIsVisible(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", modalHandler);
  //   return () => {
  //     document.removeEventListener("mousedown", modalHandler);
  //   };
  // }, []);

  useEffect(() => {
    setTableHeaders();
    // handleCss();
  }, [page,currentType]);

  useEffect(() => {
    let firstRadioButton = document.querySelectorAll(".redioButton")[0];
    firstRadioButton.classList.add("active");
    firstRadioButton.children[0].children[0].children[0].checked = true;
    setCampaignType("prospect");
  }, []);

  useEffect(() => {
    if(currentType !=="") {
      if(campaignType === "prospect") {
        if(currentType==="spbrandkeyword") {
          setCurrentType("spautomatic")
        } else if (currentType === "spbrandproduct") {
          setCurrentType("spkeyword")
        } else if (currentType === "sdbrandproduct")  {
          setCurrentType("spproduct")
        } else {
          setCurrentType("sdproduct")
        }
      } 
  
      if(campaignType === "retargeting") {
        if(currentType==="spautomatic") {
          setCurrentType("spbrandkeyword")
        } else if (currentType === "spkeyword") {
          setCurrentType("spbrandproduct")
        } else if (currentType === "spproduct") {
          setCurrentType("sdbrandproduct")
        } else {
          setCurrentType("sdbrandaudience")
        }
      }
    }
  
   },[campaignType])

   useEffect(() => {
    if(campaigns.length === 0) {
      getCampaignList();
    }
    return () => {
      setCampaigns([])
    }
  },[])

  useEffect(() => {
    setAdGroupData(initialAdgroupCreationState);
    setCampaignData(initialCampaignCreationState);
    setAdSetData(initialCreativesCreationState)
  },[])

  async function getCampaignList() {
    try {
      const dataForCampaignList = {
        source: "amazon",
        limit: 5000,
        fields:"name"
      };
      const result = await masterApi(
        dataForCampaignList,
        "campaignList",
        false

      );
      if(result?.data?.data) {
        setCampaigns(result?.data?.data?.map((campaign) => campaign?.name?.toLowerCase()) ?? []);
      }
    } catch (error) {
      console.log(error);
    }
  }

   // Whenever the currentTypeChanges Set Adgroup and campaign Data with effects

   const handleCampaignData = (type,targetingType,objective) => {
    if(type === "SD-Product" || type === "SD-Audience") {
      setCampaignData((current) => {
        const copy = {...current}
          delete copy["bidding"]
          copy["type"] = type
          copy["amz_targeting_type"] = targetingType
          copy["objective"] = objective
          copy["name"] = ""
          copy["account_id"] = `${JSON.parse(localStorage.getItem('accountId'))}`
          return copy;
      })
      setAdGroupData({...adGroupData,"name":""})
    } else {
      setCampaignData({...campaignData,bidding: {
        bidding_strategy: "",
        bidding_value: 100,
      },"type":type,"amz_targeting_type":targetingType,"objective":objective,"name":"","account_id":`${JSON.parse(localStorage.getItem('accountId'))}`})
      setAdGroupData({...adGroupData,"name":""})
    }
    setSelectedCategory("");
  }

   useEffect(() => {
    // This will Set the global state data as per the current Campaign Type
    if(currentType!=="") {
      if(currentType==="spautomatic") {
        handleCampaignData("SP-Auto","Automatic","SP");
        setAdGroupData((current) => {
          const copy = {...current};
          if ("amz_product_targeting" in copy) {
            delete copy["amz_product_targeting"]
          }
          if("amz_keyword_targeting" in copy) {
          delete copy["amz_keyword_targeting"]
          }
          copy["name"] = ""
          copy["amz_manual_bid"] = {
            amz_close_match_bid_adyogi:"",
            amz_loose_match_bid_adyogi:"",
            amz_substitutes_bid_adyogi:"",
            amz_complements_bid_adyogi:""
          }
          delete copy["amz_product_audience_targeting"]
          return copy;
        })
      } else if(currentType==="spbrandkeyword") {
        handleCampaignData("SP-Keyword","Keyword","SP");
        setAdGroupData((current) => {
          const copy = {...current};
          if ("amz_product_targeting" in copy) {
            delete copy["amz_product_targeting"]
          }
          if("amz_keyword_targeting" in copy) {
            copy["amz_keyword_targeting"] = []
          } else {
            copy["amz_keyword_targeting"] = []
          }
          copy["name"] = ""
          delete copy["amz_product_audience_targeting"]
          return copy;
        })
      } else if(currentType==="spkeyword") {
        handleCampaignData("SP-Keyword","Keyword","SP");
        setAdGroupData((current) => {
          const copy = {...current};
          if ("amz_product_targeting" in copy) {
            delete copy["amz_product_targeting"]
          }
          if("amz_keyword_targeting" in copy) {
            copy["amz_keyword_targeting"] = []
          } else {
            copy["amz_keyword_targeting"] = []
          }
          copy["name"] = ""
          delete copy["amz_product_audience_targeting"]
          return copy;
        })
      } else if(currentType==="spbrandproduct") {
        handleCampaignData("SP-Product","Product","SP");
        setAdGroupData((current) => {
          const copy = {...current};
        //  if ("amz_product_targeting" in copy) {
          copy["amz_product_targeting"] = [];
          // }
          if("amz_keyword_targeting" in copy) {
            delete copy["amz_keyword_targeting"]
          }
          copy["name"] = ""
          return copy;
        })
      } else if(currentType==="spproduct") {
        handleCampaignData("SP-Product","Product","SP");
        setAdGroupData((current) => {
          const copy = {...current};
        //  if ("amz_product_targeting" in copy) {
          copy["amz_product_targeting"] = [];
          // }
          if("amz_keyword_targeting" in copy) {
            delete copy["amz_keyword_targeting"]
          }
          copy["name"] = ""
          delete copy["amz_product_audience_targeting"]
          return copy;
        })
      } else if(currentType==="sdbrandproduct") {
        handleCampaignData("SD-Product","Product","SD");
        setAdGroupData((current) => {
          const copy = {...current};
        //  if ("amz_product_targeting" in copy) {
          copy["amz_product_targeting"] = [];
          // }
        if("amz_keyword_targeting" in copy) {
          delete copy["amz_keyword_targeting"]
        }
        copy["name"] = ""
        delete copy["amz_product_audience_targeting"]
          return copy;
        })
      } else if(currentType==="sdproduct") {
        handleCampaignData("SD-Product","Product","SD");
        setAdGroupData((current) => {
          const copy = {...current};
        //  if ("amz_product_targeting" in copy) {
          copy["amz_product_targeting"] = [];
          // }
          delete copy["amz_product_audience_targeting"]
          copy["name"] = ""
          return copy;
        })
      } else {
        handleCampaignData("SD-Audience","Audience","SD");
        setAdGroupData((current) => {
          const copy = {...current} 
          delete copy["bidding"]
          if("amz_product_targeting" in copy) {
            delete copy["amz_product_targeting"];
          }
          if("amz_keyword_targeting" in copy) {
            delete copy["amz_keyword_targeting"]
          }
          copy["amz_product_audience_targeting"] = []
          copy["name"] = ""
          return copy;
        })
      }
    }
    //Clear Existing Errors when current Campaign Type changes
    dispatchErrors({action:"resetEveryError"});
   },[currentType])


  const { campaignData, setCampaignData } = useGlobalContext();

  const radioChange = (e, id) => {
    const mainBtn = e.target.closest(".redioButton-box");
    const allBtn = mainBtn.querySelectorAll(".redioButton");
    allBtn.forEach((item) => {
      item.classList.remove("active");
    });
    const add = e.target.closest(".redioButton");
    add.classList.add("active");
    add.children[0].children[0].children[0].checked = true;
    setCampaignData({ ...campaignData, targeting_objective: id });
  };

  const getCurrentType = (type) => {
    setCurrentType(type);
  };

  return (
    <>
      <div
        className={
          dashboardSideBarVisibiltyState
            ? "main-content-container-onopen-mode"
            : "main-content-container-oncolapse-mode"
        }
        id="main-box"
      >
        <Breadcrumb />
        <div className="main-container">
          <PortfoliCreationModal show={show} setShow={setShow} />
          <Loader visible={isLoaderVisible} />
          <div>
            <span className="heading-font">Choose Objective</span>
          </div>
          <div className="redioButton-box">
            <div className="row">
              <div className="col-lg-6 leavle-hover" id="prospect">
                <div
                  className="redioButton leavle-hover"
                  onClick={(e) => {
                    radioChange(e, "Prospect");
                    setCampaignType("prospect");
                  }}
                >
                  <RadioButtonRb
                    name="select-Campaign"
                    label={
                      <Leavle
                        heading="Prospect Campaign"
                        para="Prospect campaigns are targeted to customers who have not interacted with your brand before"
                      />
                    }
                  ></RadioButtonRb>
                </div>
              </div>
              <div className="col-lg-6 leavle-hover" id="retargeting">
                <div
                  className="redioButton leavle-hover"
                  id="Retargeting"
                  onClick={(e) => {
                    radioChange(e, "Retargeting");
                    setCampaignType("retargeting");
                  }}
                >
                  <RadioButtonRb
                    name="select-Campaign"
                    label={
                      <Leavle
                        heading="Re Targeting Campaign"
                        para="Retargeting campaigns  are targeted to customers who have already interacted with your brand"
                      />
                    }
                  ></RadioButtonRb>
                </div>
              </div>
            </div>
          </div>
          <div className="campaign-type-box">
            <span className="heading-font">Choose Campaign Type</span>

            <div className="type-wrapper">
              <div className="tab-wrap">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <CampaignType
                    currentPage={page}
                    campaignType={campaignType}
                    setCampaignType={setCampaignType}
                    setCurrentPage={setPage}
                    getCurrentType={getCurrentType}
                  />

                  {
                    currentType!=="" &&   <Tab.Content>
                    {/* // Conditional Rendering based upon Pages and Campaign Types */}
                    <Tab.Pane
                      eventKey="first"
                      className={page === "" ? "d-none" : ""}
                    >
                      {campaignType === "prospect" ? (
                        currentType === "spautomatic" && <SpAutomatic />
                      ) : (
                        <>
                          {currentType === "spbrandkeyword" && (
                            <SpBrandKeyword />
                          )}
                        </>
                      )}
                    </Tab.Pane>

                    <Tab.Pane
                      eventKey="second"
                      className={page === "" ? "d-none" : ""}
                    >
                      {campaignType === "prospect" ? (
                        currentType === "spkeyword" && <SpKeywordTargeting />
                      ) : (
                        <>
                          {currentType === "spbrandproduct" && (
                            <SPBrandProduct />
                          )}
                        </>
                      )}
                    </Tab.Pane>

                    <Tab.Pane
                      eventKey="third"
                      className={page === "" ? "d-none" : ""}
                    >
                      {campaignType === "prospect" ? (
                        currentType === "spproduct" && <SPProductTargeting />
                      ) : (
                        <>
                          {currentType === "sdbrandproduct" && (
                            <SDBrandProduct />
                          )}
                        </>
                      )}
                    </Tab.Pane>

                    <Tab.Pane
                      eventKey="fourth"
                      className={page === "" ? "d-none" : ""}
                    >
                      {campaignType === "prospect" ? (
                        currentType === "sdproduct" && <SDProductTargeting />
                      ) : (
                        <>
                          {currentType === "sdbrandaudience" && ( 
                            <SDBrandAudience />
                          )}
                        </>
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                  }
                  
                
                </Tab.Container>
              </div>
            </div>
          </div>
        {
          currentType !=="" &&  <div className="ad-details-container">
          <div
            className={
              page === ""
                ? "d-none"
                : page === ""
                ? "d-none"
                : "sponsored-product-keyword-targeting"
            }
          >
            <AdDetails key={currentType} />
          </div>
        </div>
        }
         

          <div
            className={
              page === ""
                ? "d-none"
                : "d-flex justify-content-end gap-10 keyword-targeting"
            }
            style={{ marginTop: "35px" }}
          >
            <button
              type="button"
              className="primary-outline-btn btn btn-outline-primary px-5"
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
            <PrimaryBtnRB
              insideBtnText="Create Campaign"
              id={"create-campaign-btn"}
              className="width-100"
              page={page}
              campaignType={campaignType}
            ></PrimaryBtnRB>
          </div>
        </div>
      </div>
    </>
  );
}

const Leavle = ({ heading, para }) => {
  return (
    <>
      <span className="leavle-hover leavle-heading">{heading}</span>
      <br />
      {para && <span className="leavle-hover leavle-para">{para}</span>}
    </>
  );
};
