import React, { useState } from 'react';
import { ModalHeader } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./ModalRb.css";
import {IoCloseSharp} from 'react-icons/io5'
function ModalRb(props) {
    const [show, setShow] = useState(true);

    return (
        <div className="modal-rb-container">
            <Modal
                show={props.show ? props.show : ""}
                onHide={props.onHide}
                dialogClassName={props.dialogClassName}
                aria-labelledby="example-custom-modal-styling-title"
                // centered="centered"
                contentClassName={props.contentClassName}
            >
                <Modal.Header bsPrefix={props.bsPrefix} className='position-relative'>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100 text-center'>
                        {props.Modalheading}
                    </Modal.Title>
                  { !props?.hideCloseIcon && <IoCloseSharp onClick={props.onHide} size={30} cursor={'pointer'} className='modal-rb-close-icon'/>}
                </Modal.Header>
                <Modal.Body className={props.customiseMainModalClass} bsPrefix={props.modalbody}>
                    {props.ModalMainBody}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ModalRb;