export default function capitalizeFirstLetterInArray(arr) {
  // Check if the input is an array
  if (!Array.isArray(arr)) {
    return []
  }

  // Capitalize the first letter of each element
  const capitalizedArray = arr.map((element) => {
    if (typeof element !== "string") {
      // Skip non-string elements
      return element;
    }

    return element.charAt(0).toUpperCase() + element.slice(1);
  });

  return capitalizedArray;
}
