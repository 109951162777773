function deepCompare(obj1, obj2) {
    for(const key in obj1) {
      if (compareSpecialKey(obj1, obj2, key)) {
        return true;
      }
    }
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
          const nestedArrayChanges = deepArrayCompare(obj1[key], obj2[key]);
          if (nestedArrayChanges) {
            return true;
          }
        } else if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
          const nestedChanges = deepCompare(obj1[key], obj2[key]);
          if (nestedChanges) {
            return true;
          }
        } else if (obj1[key] !== obj2[key]) {
          return true;
        }
      }
    }
    return false;
  }

  // This function will recursively iterate through array and will check for nested array and objects comparison
  function deepArrayCompare(firstArray, secondArray) {
    if (firstArray.length !== secondArray.length) {
      return true;
    }
  
    for (let i = 0; i < firstArray.length; i++) {
      if (Array.isArray(firstArray[i]) && Array.isArray(secondArray[i])) {
        const nestedArrayChanges = deepArrayCompare(firstArray[i], secondArray[i]);
        if (nestedArrayChanges) {
          return true;
        }
      } else if (typeof firstArray[i] === 'object' && typeof secondArray[i] === 'object' ) {
        const nestedChanges = deepCompare(firstArray[i], secondArray[i]);
        if (nestedChanges) {
          return true;
        }
      } else if (firstArray[i] !== secondArray[i]) {
        return true;
      }
    }
  
    return false;
  }

  function compareSpecialKey(obj1, obj2, key) {
    const special_keys = ['entity_type_wise_percentage_change_and_threshold','exact','broad','phrase']
    if(!special_keys.includes(key)) return false
    const obj1Value = obj1[key];
    const obj2Value = obj2[key];

    if (!obj1Value && obj2Value) {
        return true
    }

    if (obj1Value && !obj2Value) {
        return true
    }

    if (deepCompare(obj1Value, obj2Value)) {
        return true;
    }
}
  
 export default  function automationDataChangesChecker(oldObject, newObject) {
    const changes = {};
  
    for (const key in oldObject) {
      if (oldObject.hasOwnProperty(key) && newObject.hasOwnProperty(key)) {
        if (Array.isArray(oldObject[key]) && Array.isArray(newObject[key])) {
          const nestedArrayChanges = deepArrayCompare(oldObject[key], newObject[key]);
          if (nestedArrayChanges) {
            changes[key] = newObject[key]
          }
        } else if (typeof oldObject[key] === 'object' && typeof newObject[key] === 'object') {
          const nestedChanges = deepCompare(oldObject[key], newObject[key]);
          if (nestedChanges) {
            changes[key] = newObject[key]
          }
        } else if (oldObject[key] !== newObject[key]) {
          changes[key] = newObject[key]
        }
      }
    }


    // Check for campaigns selection changes
    const oldCampaignListArray = oldObject.entity_filter.entity_filter_rule.filters[0]
    const newCampaignListArray = newObject?.entity_filter?.entity_filter_rule?.filters[0]?.value

    if(oldCampaignListArray.length !== newCampaignListArray.length) {
      changes["entity_filter"] = newObject?.entity_filter
    }

    if(oldCampaignListArray.length === newCampaignListArray.length) {
      const isSame = newCampaignListArray?.every((data) => {
        return oldCampaignListArray.find((oldCampaign) => oldCampaign?.id === data)
      });

      if(!isSame) {
        changes["entity_filter"] = newObject?.entity_filter
      }
    }
  
    return changes;
  }
  