const customDropDownWithCheckboxDefaultDataArray = [
  {
    name: "Exact",
    isDataChecked: false,
    buttonName: "Targeting Type",
    apiFieldNameForFilteringRequest: "adyogi_targeting_type",
    fieldName : "mt-targeting-type",
    valueToSnedInApiCallForFilter : "Keyword Exact Targeting"
  },
  {
    name: "Broad",
    isDataChecked: false,
    buttonName: "Targeting Type",
    apiFieldNameForFilteringRequest: "adyogi_targeting_type",
    fieldName : "mt-targeting-type",
    valueToSnedInApiCallForFilter : "Keyword Broad Targeting"
  },
  {
    name: "Phrase",
    isDataChecked: false,
    buttonName: "Targeting Type",
    apiFieldNameForFilteringRequest: "adyogi_targeting_type",
    fieldName : "mt-targeting-type",
    valueToSnedInApiCallForFilter : "Keyword Phrase Targeting"
  },
  {
    name: "Automatic",
    isDataChecked: false,
    buttonName: "Targeting Type",
    apiFieldNameForFilteringRequest: "adyogi_targeting_type",
    fieldName : "mt-targeting-type",
    valueToSnedInApiCallForFilter : "Automatic",
    
  },
  {
    name: "Audience",
    isDataChecked: false,
    buttonName: "Targeting Type",
    apiFieldNameForFilteringRequest: "adyogi_targeting_type",
    fieldName : "mt-targeting-type",
    valueToSnedInApiCallForFilter : "Audience Targeting"
  },
  {
    name: "Product",
    isDataChecked: false,
    buttonName: "Targeting Type",
    apiFieldNameForFilteringRequest: "adyogi_targeting_type",
    fieldName : "mt-targeting-type",
    valueToSnedInApiCallForFilter : "Product Targeting"
  },
  {
    name: "SP",
    isDataChecked: false,
    buttonName: "Campaign Type",
    apiFieldNameForFilteringRequest: "campaign_type",
    fieldName : "mt-campaign_type"
  },
  {
    name: "SD",
    isDataChecked: false,
    buttonName: "Campaign Type",
    apiFieldNameForFilteringRequest: "campaign_type",
    fieldName : "mt-campaign_type"
  },
  {
    name: "SB",
    isDataChecked: false,
    buttonName: "Campaign Type",
    apiFieldNameForFilteringRequest: "campaign_type",
    fieldName : "mt-campaign_type"
  },

  {
    name: "Prospect",
    isDataChecked: false,
    buttonName: "Objective",
    apiFieldNameForFilteringRequest: "campaign_objective",
    fieldName : "mt-campaign_objective"
  },
  {
    name: "Retarget",
    isDataChecked: false,
    buttonName: "Objective",
    apiFieldNameForFilteringRequest: "campaign_objective",
    fieldName : "mt-campaign_objective"
  },
  {
    name: "Prospect",
    isDataChecked: false,
    buttonName: "Objective",
    apiFieldNameForFilteringRequest: "targeting_objective",
    fieldName : "cl-campaign_objective"
  },
  {
    name: "Retargeting",
    isDataChecked: false,
    buttonName: "Objective",
    apiFieldNameForFilteringRequest: "targeting_objective",
    fieldName : "cl-campaign_objective"
  },


  {
    name: "Automatic",
    isDataChecked: false,
    buttonName: "Targeting Type",
    apiFieldNameForFilteringRequest: "amz_targeting_type",
    fieldName : "cl-targeting-type-filed"
  },
  {
    name: "Audience",
    isDataChecked: false,
    buttonName: "Targeting Type",
    apiFieldNameForFilteringRequest: "amz_targeting_type",
    fieldName : "cl-targeting-type-filed"
  },
  {
    name: "Product",
    isDataChecked: false,
    buttonName: "Targeting Type",
    apiFieldNameForFilteringRequest: "amz_targeting_type",
    fieldName : "cl-targeting-type-filed"
  },
  {
    name: "Keyword",
    isDataChecked: false,
    buttonName: "Targeting Type",
    apiFieldNameForFilteringRequest: "amz_targeting_type",
    fieldName : "cl-targeting-type-filed"
  },
  {
    name: "SP",
    isDataChecked: false,
    buttonName: "Campaign Type",
    apiFieldNameForFilteringRequest: "objective",
    fieldName : "cl-campaign_type"
  },
  {
    name: "SD",
    isDataChecked: false,
    buttonName: "Campaign Type",
    apiFieldNameForFilteringRequest: "objective",
    fieldName : "cl-campaign_type"
  },
  {
    name: "SB",
    isDataChecked: false,
    buttonName: "Campaign Type",
    apiFieldNameForFilteringRequest: "objective",
    fieldName : "cl-campaign_type"
  },

  {
    name: "Exact",
    isDataChecked: false,
    buttonName: "Targeting Type",
    apiFieldNameForFilteringRequest: "adyogi_targeting_type",
    fieldName : "mt-match_type",
    valueToSnedInApiCallForFilter : "EXACT"
  },
  {
    name: "Broad",
    isDataChecked: false,
    buttonName: "Targeting Type",
    apiFieldNameForFilteringRequest: "adyogi_targeting_type",
    fieldName : "mt-match_type",
    valueToSnedInApiCallForFilter : "BROAD"
  },
  {
    name: "Phrase",
    isDataChecked: false,
    buttonName: "Targeting Type",
    apiFieldNameForFilteringRequest: "adyogi_targeting_type",
    fieldName : "mt-match_type",
    valueToSnedInApiCallForFilter : "PHRASE"
  },
  {
    name: "Automatic",
    isDataChecked: false,
    buttonName: "Targeting Type",
    apiFieldNameForFilteringRequest: "adyogi_targeting_type",
    fieldName : "mt-match_type",
    valueToSnedInApiCallForFilter : "Automatic",
    
  },
  {
    name: "Product",
    isDataChecked: false,
    buttonName: "Targeting Type",
    apiFieldNameForFilteringRequest: "adyogi_targeting_type",
    fieldName : "mt-match_type",
    valueToSnedInApiCallForFilter : "Product",
  },
];

export default customDropDownWithCheckboxDefaultDataArray;
