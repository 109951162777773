// These JS File is for all the hardcoded types that we might need to use

// Toast  Notification hardcoded Types

// Position
const TOAST_POSITION_TOP_RIGHT = "top-right";
const TOAST_POSITION_TOP_LEFT = "top-left";
const TOAST_POSITION_BOTTOM_LEFT = "bottom-left";
const TOAST_POSITION_BOTTOM_RIGHT = "bottom-right";

// Background Color
const TOAST_SUCCESS = "#5cb85c";
const TOAST_DANGER = "#d9534f";
const TOAST_INFO = "#5bc0de";
const TOAST_WARNING = "#f0ad4e";

  
export {  TOAST_INFO,
  TOAST_SUCCESS,
  TOAST_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_POSITION_BOTTOM_RIGHT,
  TOAST_POSITION_BOTTOM_LEFT,
  TOAST_POSITION_TOP_LEFT,
  TOAST_DANGER
}

