import React, { useEffect, useState , useRef } from "react";
import { masterApi } from "../../apis/masterApi";
import ReactApexChart from "react-apexcharts";
import "./pieChart.css";
import { useNavigate } from "react-router-dom";
import redirect from '../../constant/Redirect';
import arrowdown from '../../assets/icons/down_arrow.svg'
import { useGlobalContext } from '../../context/global';
import {encodeData} from '../../helper/common/encodeData';

export default function PieChart(
  {
    // chartData,
    // onSelectChange,
    isShowSelect,
    // selectOptionArray,
    isShowLegend,
    campaignData,
    id,
    portfolios
    // type
  }
) {
  const {calendarDate,setCalendarDate,applyCalendar} = useGlobalContext();
  let selectHeading = ["Spends", "Impressions", "Clicks", "Sale_Value","Orders","CTR", "CVR", "CPM", "AOV", "CPC", "ROAS", "ACOS"];
  const [chartDataState, setchartDataState] = useState();
      
  const [apiResult, setApiResult] = useState();
  const [apiResult2,setApiResult2] = useState();
  const [legendColors, setLegendColors] = useState(["#64F3EB", "#BEB1F9", "#2C91FF", "yellow", "blue", "green"]);
  const [legendMapedColors, setLegendMapedColors] = useState([]);
  const [selectOptionArray, setSelectOptionArray] = useState([...selectHeading]);
  const [selectedOption, setSelectedOption] = useState("Spends");
  const [allLabels,setAlllLabels] = useState([]);
  const [dropDown,setDropDown] = useState(false);
  const closeRef = useRef();
  const [Brandoption, setBrandoption] = useState(false);
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const [campaignType,setCampaignType] = useState({labels:"",data:""});
  const navigate = useNavigate();


  

  const changeLabelText = () => {
    let allLabels = document.querySelectorAll('.apexcharts-legend-text');
    allLabels.forEach((label) => {
      if(label.innerText.toLowerCase().includes(".product")) {
        label.innerText = "Product"
      }
    })
  }
  const formatDateFn = (date) => {
    const selectedDate = new Date(date)
    const formattedDate = selectedDate.getDate() + "-"+ parseInt(selectedDate.getMonth()+1) +"-"+ selectedDate.getFullYear();
    return selectedDate.getDate() + "-"+ parseInt(selectedDate.getMonth()+1) +"-"+ selectedDate.getFullYear();
  }
  

  const filterCampaignTypeDataForPieChart = (data) => {
    let filteredData = data.filter((item) => item.campaign_type!=="Null");
    const filteredLabels = filteredData.map((item) => item.campaign_type);
    const filteredCategoryData = filteredData.map((item) => item[selectedOption.toLowerCase()])
    
    const updatedCategoryData = filteredCategoryData.map((item) => Number(Number(item).toFixed(2)))
    setCampaignType({labels:filteredLabels,data:updatedCategoryData})
  }

  const additionalFiltering = portfolios?.length ? [{field_name:'portfolio_name',operator:"IN",values:portfolios}] : [];

  const filteringForChartData = [{field_name:'campaign_type',operator:"IN",values:[campaignData?.campaign_type]},...additionalFiltering]

  async function fetchChartData() {
    const data = {
      level: "account",
      platform: "amazon",
      start_date:`${formatDateFn(calendarDate[0].startDate)}`,
      end_date:`${formatDateFn(calendarDate[0].endDate)}`,
      // date_preset: "last_7d",
      fields: `${selectedOption.toLowerCase()},targeting_type`,
      group_by: "targeting_type",
      filtering: encodeData(filteringForChartData),
    }
    const result = await masterApi(data);
    if(redirect(result)) return navigate('/login')
    setApiResult(result?.data);
    
    // let anotherArray = result?.data?.data.filter((item) => item?.targeting_type.toLowerCase().includes(".keyword"));
    changeLabelText()
    return result;
  }

  async function fetchCampaignData() {
    const data = {
      level: "account",
      platform: "amazon",
      start_date: `${formatDateFn(calendarDate[0].startDate)}`,
      end_date:`${formatDateFn(calendarDate[0].endDate)}`,
      // date_preset: "last_7d",
      fields: `${selectedOption.toLowerCase()},campaign_type,roas,date`,  
      order_by:"date",
      group_by: "campaign_type",
      ...(portfolios.length && {filtering:encodeData(additionalFiltering)})
      // filtering: window.encodeURI(`[{"field_name":"campaign_type","operator":"IN","values":["${campaignData?.campaign_type}"]}]`),
    }
    const result = await masterApi(data);
    if(redirect(result)) return navigate('/login')
    setApiResult2(result.data)
  }

  useEffect(() => {
     if (apiResult !== undefined && apiResult?.data?.length > 0) {

      /* Heavy Calculation
        @Data Filtered from API.
        @Then filtered all the indexes which includes .keyword as key
        @Then aggregated all the values with reduce.
        @Then Pushed aggregated value of all .keyword as keywords
      */
        changeLabelText()
      let filteredData = apiResult?.data?.filter((item) => { return item?.targeting_type });
      let anotherArray = filteredData.filter((item) => {return item?.targeting_type.toLowerCase().includes(".keyword")});
      let oneMoreArray = anotherArray.map((item) => item[selectedOption.toLowerCase()]);
      let checkArr = oneMoreArray.length > 0 ? oneMoreArray.reduce((a,b) => a+b):[];
      filteredData.unshift({[selectedOption.toLowerCase()]:checkArr,targeting_type:"Keywords"})
      filteredData = filteredData.filter(val => !anotherArray.includes(val));
      const colors = [...legendColors]
      const filterColors = filteredData.map((item, index) => {
        return { color: colors[index], targeting_type: item?.targeting_type }
      })
      setLegendMapedColors(filterColors);
      let finalData = [];
      filteredData.map((item) => {return item[selectedOption.toLowerCase()]!==undefined?finalData.push(item[selectedOption.toLowerCase()]):[]})
      let finalData1 = finalData.map((item) => Number(Number(item).toFixed(2)))
      const data1 = {
        labels: filteredData.map((item) => { return item?.targeting_type }),
        datasets: [
          {
            // label: "# of Votes",
            data: finalData1,
            backgroundColor: legendColors,
            borderColor: ["white"],
            borderWidth: 3,
            // hoverOffset: 4
          },
        ],
      };
      setchartDataState(data1);
      let newArray = [];
      const labelsArray = apiResult?.data?.filter((item) => {return item?.targeting_type})
      newArray = filteredData.map((item) => { return item.targeting_type});
      setAlllLabels(newArray)

      // Changing the CSS Dynamically      
    }

    if (apiResult2 !== undefined && apiResult2?.data?.length>0) {
      filterCampaignTypeDataForPieChart(apiResult2?.data)  
    }
  }, [apiResult,apiResult2,applyCalendar])//[apiResult,apiResult2,calendarDate]

  useEffect(() => {
    id===0?fetchCampaignData():fetchChartData();
  }, [selectedOption,applyCalendar,portfolios]);//[selectedOption,calendarDate]

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (dropDown && closeRef.current && !closeRef.current.contains(e.target)) {
        setDropDown(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }

  },[dropDown]);
  
  return (
    <>
       {
        <div className="col-lg-2 pie-container">
          <div className="header-pie">
            <span className="sub-heading-pie">{campaignData?.campaign_type==="Null"?"Campaign Type":campaignData?.campaign_type.toLowerCase() === "retarget"?"Retargeting":campaignData?.campaign_type}</span>
            {isShowSelect ? (
              selectOptionArray ? (
                // <select
                //   onChange={(event) => {
                //     setSelectedOption(event.target.value);
                //   }}
                //   className="select-pie-type dropdown-content"
                // >
                //   {selectOptionArray.map((element, index) => {
                //     return (
                //       <option key={"element" + index} className="dropdown-item" value={element.toLowerCase()}>
                //         {element==="Sale_Value"?"Revenue":element}
                //       </option>
                //     );
                //   })}
                // </select>
                <>
                  <div className="dash-drodown-btn-container">
          <div className="dropdown" id="dropdown" ref={closeRef}>
            <div
              className="dropdown-btn"
              id="dropdown-btn"
              onClick={(e) => {
                setDropDown(!dropDown)
                setBrandoption(false);
                setShowCustomDateBox(false);
              }}
            >
              {selectedOption.toLowerCase()==="sale_value"?"Revenue":selectedOption}
              <span style={{ paddingLeft: "20px" }}>
                {" "}
                <img src={arrowdown}></img>
              </span>
            </div>
            {dropDown && (
              <div className="dropdown-content">
                {selectOptionArray.map((ele,index) => (
                  <>
                  <div
                    className="dropdown-item"
                    onClick={(e) => {
                      setSelectedOption(ele);
                      setDropDown(false)
                    }}
                    key={ele+index}
                  >
                    {ele==="Sale_Value"?"Revenue":ele}
                  </div>
                  </>
                ))}
              </div>
            )}
          </div>
          </div>
                
                </>
              ) : (
                "Please provide array option for select"
              )
            ) : (
              ""
            )}
          </div>
          <div className="main-chart-holder">
            {
              id===0?<ReactApexChart
              type="pie"
              height="300px"
              series={campaignType?.data ? campaignType?.data:[ ]}
              options={{
                labels:campaignType?.labels,
                chart:{
                  fontFamily:"Inter"
                },
                // fill:{
                //   colors:['#6FC8FB', '#00A0FA', '#BEB1F9', '#FF9800'],
                //   opacity:0.8,
                // },
                // colors:['#7078CC', '#70BDCC'],
                colors:['#1D216E', '#50A5B5'],
                dataLabels: {
                  enabled:true,
                  style:{
                    colors:["#fff"],
                    fontWeight:400,
                  },
                  dropShadow:{
                    enabled:false
                  }
                 
                },
                states:{
                  hover:{
                    filter:"none",
                    value:0
,                  }
                },
                tooltip:{
                  enabled:true
                },
                noData:{text:"No Data to display"},
                legend:{
                  position:"bottom",
                  fontFamily:"Inter",
                  fontWeight: 500,
                  labels:{
                    colors:['#6FC8FB', '#00A0FA', '#BEB1F9', '#FF9800'],
                  },
                  itemMargin: {
                    horizontal: 5,
                    vertical: 0
                }
                }
              }}
              
              />:<ReactApexChart
              type="pie"
              height="300px"
              series={chartDataState?.datasets[0]?.data ? chartDataState.datasets[0].data:[ ]}
              options={{
                labels:allLabels,
                chart:{
                  fontFamily:"Inter"
                },
                // fill:{
                //   colors:['#6FC8FB', '#00A0FA', '#BEB1F9', '#FF9800'],
                //   opacity:0.8,
                // },
                // colors:['#7078CC', '#70BDCC', '#E5DB7E', '#9CCC70'],
                colors:['#1D216E', '#50A5B5', '#DEC46A', '#81B550'],
                // plotOptions:{
                //   states:{
                //     hover: {
                //       filter: {
                //         type: 'none',
                //         value: 0
                //       }
                //     }
                //   }
                // },
                states:{
                  hover:{
                    filter:"none",
                    value:0
,                  }
                },
                dataLabels: {
                  enabled:true,
                  style:{
                    colors:["#fff"],
                    fontWeight:400,
                  },
                  dropShadow:{
                    enabled:false
                  }
                },
                tooltip:{
                  enabled:true,
                },
                noData:{text:"No Data to display"},
                legend:{
                  position:"bottom",
                  fontFamily:"Inter",
                  fontWeight: 500,
                  labels:{
                    // colors:['#7078CC', '#70BDCC', '#E5DB7E', '#9CCC70'],
                    colors:['#1D216E', '#50A5B5', '#DEC46A', '#81B550'],
                  },
                  itemMargin: {
                    horizontal: 5,
                    vertical: 0
                }
                }
              }}
            />
            }
          

            {/* <center>
              {chartDataState ? (
                <Pie
                  className="pie-main"
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },

                    },
                    maintainAspectRatio: true,
                  }}
                  data={chartDataState}
                  plugins={[{
                    afterDraw: function (chart) {
                      if (chart.data.datasets[0].data.reduce((x, y) => { return x + y }) === 0) {
                        // No data is present
                        var ctx = chart.ctx;
                        var width = chart.width;
                        var height = chart.height
                        chart.clear();

                        ctx.save();
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        ctx.font = "20px 'Helvetica'";
                        ctx.fillText('No data to display', width / 2, height / 2);
                        ctx.restore();
                      }
                    }
                  }]}
                />
              ) : (
                "Loading"
              )}
            </center> */}
          </div>
          

          {/* <div className="pie-legend">
            {isShowLegend ?
              legendMapedColors.map((item, index) => {
                return <>
                  {item?.targeting_type ?
                    <LegendComponent
                      legendColor={item?.color}
                      legendText={item?.targeting_type}
                    ></LegendComponent>
                    : ""}
                </>
              })
              : (
                ""
              )}
          </div> */}
        </div>
        }  
      
        {/* <ReactApexChart
          type="pie"
          width={180}
          height={372}
          series={[]}
          options={{
            labels:allLabels,

            title:{text:`${campaignData?.campaign_type==="Null" ? "Type" : 
            campaignData?.campaign_type!=="Null"?campaignData.campaign_type:""}`},

            noData:{text:"No Data to display"},
            legend:{
              position:"bottom"
            }
          }}
        /> */}
    </>
  );
}
