import React from "react";
import "./templateCard.css";

const TemplateCard = ({mainImage,chip1Image,chip1Text,chip2Text,ruleHeading,ruleDesc,chip1Color,chip2Color,cardDisabled,chooseTemplateClick}) => {
  return (
    <div className="template-card-container" aria-disabled={true}>
      <div className="main-template-info-container">
        <div className="template-info-container">
          <div className="template-image-container">
            <img src={mainImage} alt={"increaseBid"} />
          </div>
          <div className="template-details-container">
            <div className="template-chip-container">
              <div className="d-flex gap-3">
                <TemplateChip
                  isIconRequired={true}
                  icon={chip1Image}
                  chipColor={chip1Color}
                  text={chip1Text}
                />
                <TemplateChip
                  chipColor={chip2Color}
                  text={chip2Text}
                />
              </div>
            </div>
            <span className="template-rule-heading">{ruleHeading}</span>
            <p className="template-rule-desc">
             {ruleDesc}
            </p>
          </div>
        </div>
        <div className="template-cta-container">
          <button className="btn-without-bg" onClick={() => chooseTemplateClick(ruleHeading)}>Use Template</button>
          <button
            className="btn-without-bg"
            style={{ backgroundColor: "#F6F6F7" }}
          >
            View Details
          </button>
        </div>
      </div>
    </div>
  );
};

const TemplateChip = ({ isIconRequired, icon, text, chipColor }) => {
  return (
    <>
      <div className="template-chip" style={{ backgroundColor: chipColor }}>
        {isIconRequired && <img alt="chip" src={icon} />}
        <span style={{ color:  isIconRequired ? "white":"black" }}>{text}</span>
      </div>
    </>
  );
};

export default TemplateCard;
