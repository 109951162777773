
import React, { useState, useEffect, useRef } from "react";
import "./Calender.css";
import { multiCalendarPages } from "../../constant/calendarConfigs";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar, DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { DefinedRange } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import { multiCalendarDateRange } from "../../constant/defaultMultiRangeDate";
import defaultDateRangesWithLables from "../../constant/calendarDefaultConfigurations";
import { useGlobalContext } from "../../context/global";
import { useLocation } from "react-router-dom";
import { calenderDate } from "../../components/Reports/Components/utils";

const CalenderComponent = (props) => {
  const {
    calendarDate,
    setCalendarDate,
    dateDifference,
    setDateDifference,
    oldDateDifference,
    setOldDateDifference,
    oldCalendarData,
    setOldCalendarData,
    applyCalendar,
    setApplyCalendar,
    multiCalendarDate,
    setMultiCalendarDate,
    reportsTime,
    reportsFunnelType,
    setReportsTime,
    calendarFreeze,
    setReportsFunnelType,
  } = useGlobalContext();
  const [multiRange, setMultiRange] = useState(false)
  const location = useLocation();
  const pathName = location.pathname;
  const divRef = useRef(null);

  const formatDateFn = (date) => {
    const selectedDate = new Date(date);
    return (
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear()
    );
  };

  useEffect(() => {
    if (!multiCalendarPages?.includes(pathName)) {
      setReportsFunnelType(null);
      setDateDifference(oldDateDifference);
      setMultiRange(false);
    }
  }, [pathName]);

  useEffect(() => {
    if (props.multiRange) {
      setMultiRange(true);
    }
  }, [props.multiRange]);

  useEffect(() => {
    if (reportsFunnelType !== undefined && reportsTime !== undefined) {
      if (reportsFunnelType === "Comparison View") {
        setMultiRange(true);
        setMultiCalendarDate({
          selection1: {
            startDate: calenderDate(reportsTime[0][0]),
            endDate: calenderDate(reportsTime[0][1]),
            key: "selection1",
          },
          selection2: {
            startDate: calenderDate(reportsTime[1][0]),
            endDate: calenderDate(reportsTime[1][1]),
            key: "selection2",
          },
        });
      } else {
        if (props.multiRange) {
          setMultiRange(true);
        } else {
          setMultiRange(false);
        }
        setCalendarDate([
          {
            startDate: calenderDate(reportsTime[0][0]),
            endDate: calenderDate(reportsTime[0][1]),
            key: "selection",
          },
        ]);
      }
    }
  }, [reportsTime]);

  useEffect(() => {
    formatDateFn(calendarDate);
  }, [calendarDate]);

  const rangeHandler = () => {
    setCalendarDate(oldCalendarData);
    props.setShowCustomDateBox(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        divRef.current &&
        !divRef.current.contains(event.target) &&
        !event.target.closest(".calender-class")
      ) {
        rangeHandler();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [oldCalendarData]);

  const getSpecificDataFormat = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear().toString().slice(-2);
    return `${day} ${month} ${year}`;
  };

  const getSelectedPresetLabel = (range) => {
    for (let i = 0; i < defaultDateRangesWithLables.length; i++) {
      const staticRange = defaultDateRangesWithLables[i].range();
      if (
        range.startDate.toString() === staticRange.startDate.toString() &&
        range.endDate.toString() === staticRange.endDate.toString()
      ) {
        return { date: defaultDateRangesWithLables[i].label, isCustom: false };
      }
    }

    const formattedStartDate = getSpecificDataFormat(range.startDate);
    const formattedEndDate = getSpecificDataFormat(range.endDate);
    return {
      date: formattedStartDate !== formattedEndDate
        ? formattedStartDate + " - " + formattedEndDate
        : formattedStartDate,
      isCustom: true,
    };
  };

  const applyHandler = () => {
    if (multiRange) {
      setApplyCalendar(applyCalendar + 1);
      props.setShowCustomDateBox(false);
    } else {
      if (calendarDate !== oldCalendarData) {
        setOldDateDifference(dateDifference);
        setOldCalendarData(calendarDate);
        setApplyCalendar(applyCalendar + 1);
      }
      props.setShowCustomDateBox(false);
    }
  };

  const handleDateRangeChange = (range) => {
    const dateLabel = getSelectedPresetLabel(range.selection);
    window.sessionStorage.getItem('dateDiff',dateLabel)
    setDateDifference(dateLabel.date);
    setCalendarDate([range.selection]);

    if (!dateLabel.isCustom) {
      setOldDateDifference(dateLabel.date);
      setOldCalendarData([range.selection]);
      setApplyCalendar(applyCalendar + 1);
      props.setShowCustomDateBox(false);
    }
  };

  const onMultiRangeChange = (range) => {
    setDateDifference("Custom");
    window.sessionStorage.getItem('dateDiff',"Custom")
    setMultiCalendarDate({ ...multiCalendarDate, ...range });
  };

  const singleDatePicker = {
    date:props.date,
    onChange:props.onSingleDateChange,
    maxDate:addDays(new Date(),props?.maxDays)
  }
  const classNameForCalendar = multiRange ? 'multicalendar-dates' : props?.className
  return (
    <>
      <div className={`calender-class ${calendarFreeze ? "disable-pointer-events" : ""}`} ref={divRef}>
        {
          props.singleDatePicker ? (<Calendar selectionType={"multiple"} showSelectionPreview={true} minDate={addDays(new Date(), 0)}
          maxDate={addDays(new Date(), 30)} {...singleDatePicker}/> )
          :  
          (<> <DateRangePicker
          ref={props.ref}
          className={classNameForCalendar}
          onChange={ !multiRange ? handleDateRangeChange : onMultiRangeChange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          key={reportsFunnelType} // Applying this key to remount the component if user has selected multirange previous range and switching back to normal calendar so focus should be on main range.
          ranges={multiRange ?  [multiCalendarDate.selection1,multiCalendarDate.selection2] : calendarDate}
          direction="horizontal"
          calendarFocus="backwards"
          preventSnapRefocus={true}
          classNames={"multidate-range-dates"}
          // rangeColors={["rgba(255, 131, 100, 0.861)"]}
          rangeColors={["#07A9D2"]}
          // showDateDisplay={false}
          showMonthAndYearPickers={true}
          // hasCustomRendering={true}
          renderStaticRangeLabel={rangeHandler}
          staticRanges={defaultDateRangesWithLables}
        />
        {/* If Multirange then add labels */}
      { multiRange ? <div  className="position-absolute calendar-label-multirange">
          <small>Current Period</small>
          <small>Previous Period</small>
        </div> : null}
        <div className="d-flex btn-absualte" style={{justifyContent:'right'}}>
          <button
            type="button"
            onClick={rangeHandler}
            class="primary-outline-btn btn btn-outline-primary px-5 mx-2"
          >
            Cancel
          </button>
          <button
            type="button"
            class="primary-btn btn btn-primary px-5"
            onClick={applyHandler}
          >
            Apply
          </button>
        </div>
        </>)
        }
       
      </div>
    </>


  );
};
export default CalenderComponent;

