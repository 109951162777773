
export default function sendUserInfoToGainsight(userInfo = {}, clientInfo = {}) {
    try {
        var userData = {
            "id": userInfo?.email ?? "",
            "email": userInfo?.email ?? "",
            "firstName": "",
            "signUpDate": 0,
            "plan": "",
        };
        var clientData = {
            "id": clientInfo?.clientId ?? '',
            "name": clientInfo?.website ?? '',
            "website": clientInfo?.website ?? '',
            "createDate" : "",
        }
        //Pushing Data to GainInsight
        window?.aptrinsic("identify", userData, clientData);
    } catch (err) {
        console.log('ERR-GainsightFn', err);
    }
}