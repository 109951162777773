

export default function sendUserInfoToClarity(userInfo = {}, clientInfo = {}, accountDetail = {}) {
    try {
        var userData = {
            "id": userInfo?.email ?? "",
            "email": userInfo?.email ?? "",
            "firstName": "",
            "signUpDate": 0,
            "plan": "",
        };
        var clientData = {
            "id": clientInfo?.clientId ?? '',
            "name": clientInfo?.website ?? '',
            "website": clientInfo?.website ?? '',
            "createDate" : "",
        }
        //Pushing Data to Clarity
        window?.clarity("identify", userData.email);
        window?.clarity("set", "account_plan", "");
        window?.clarity("set", "account_website_name", clientData?.website);
        window.clarity ("set", "Account", accountDetail?.name)
        window.clarity ("set", "User", userInfo?.email ?? "")
    } catch (err) {
        console.log('ERR-clarityFn', err);
    }
}
