import { configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "../slices/dashboardSlice";
import CampaignCreation from '../slices/CampaignCreation'
import campaignListSlice from "../slices/campaignList";
import genralDataSlice from "../slices/generalData";
import editTargetingSlice from '../slices/edittargeting';


const store = configureStore({
    reducer: {
        dashboardData: dashboardSlice.reducer,
        editTargetingData: editTargetingSlice.reducer,
        CampaignCreationData: CampaignCreation.reducer,
        campaignListData: campaignListSlice.reducer,
        genralDataData: genralDataSlice.reducer,
    },
});
export default store;


