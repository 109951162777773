import romanConverter from "./romanConverter";

export default function dataParser(data) {
    const filteredData = data.filter(
      (mainData) => mainData?.campaign_type !== "Null"
    );
    const prospectSpends = filteredData
      .filter((mainData) => mainData?.campaign_type === "Prospect")
      .map((data) => Math.round((data?.spends).toFixed(2)));
    const retargetingSpends = filteredData
      .filter((mainData) => mainData?.campaign_type === "Retarget")
      .map((data) => Math.round((data?.spends)));
    let datesData = [
      ...new Set(filteredData.map((mainData) => mainData?.week)),
    ].map((date,index) => {
      let updatedIndex = index+1
    return  `Week ${romanConverter(updatedIndex)}`
    })  
    const roasObject = getROASByWeek(filteredData);
    const mainData = {
      retargetingSpends,
      prospectSpends,
      datesData,
      totalRoasArray:roasObject?.totalRoasArray,
      prospectRoasArray:roasObject?.prospectROASArray,
      retargetRoasArray:roasObject?.retargetROASArray
    };
    return mainData;
  }

  function getROASByWeek(campaigns) {
    const weeklyROAS = {};
    const prospectROAS = {};
    const retargetROAS = {};
    
    for (const campaign of campaigns) {
      if (!weeklyROAS[campaign.week]) {
        weeklyROAS[campaign.week] = {
          totalSpends: 0,
          totalSaleValue: 0,
        };
        prospectROAS[campaign.week] = {
          totalSpends: 0,
          totalSaleValue: 0,
        };
        retargetROAS[campaign.week] = {
          totalSpends: 0,
          totalSaleValue: 0,
        };
      }
      
      weeklyROAS[campaign.week].totalSpends += campaign.spends;
      weeklyROAS[campaign.week].totalSaleValue += campaign.sale_value;
      
      if (campaign.campaign_type === "Prospect") {
        prospectROAS[campaign.week].totalSpends += campaign.spends;
        prospectROAS[campaign.week].totalSaleValue += campaign.sale_value;
      } else {
        retargetROAS[campaign.week].totalSpends += campaign.spends;
        retargetROAS[campaign.week].totalSaleValue += campaign.sale_value;
      }
    }
    const result = {
      totalRoasArray:[],
      prospectROASArray:[],
      retargetROASArray:[]
    }
    for (const week in weeklyROAS) {
      const totalROAS = Number((weeklyROAS[week].totalSaleValue / weeklyROAS[week].totalSpends).toFixed(1));
      const prospectROASRatio = Number((prospectROAS[week].totalSaleValue / prospectROAS[week].totalSpends).toFixed(1));
      const retargetROASRatio = Number((retargetROAS[week].totalSaleValue / retargetROAS[week].totalSpends).toFixed(1));
      const prospectROASValue = Number((totalROAS * prospectROASRatio).toFixed(1));
      const retargetROASValue = Number((totalROAS * retargetROASRatio).toFixed(1));
      result.totalRoasArray.push(totalROAS)
      result.retargetROASArray.push(retargetROASRatio);
      result.prospectROASArray.push(prospectROASRatio)
    }
    return result;
  }
  