/**
 * @Note use this function when you want to filter some data heads or columns in a table
 * @param MainArray 
 * @param Arraywithkeystoremove
 * @returns filtered Array
 */

export default function getCustomDataHeads (arrayOfMainData,arrayOfSecondData) {
    const result = arrayOfMainData.filter((data) => {
        const mainData = data?.id
        return !arrayOfSecondData.includes(mainData)
      })
      return result
}