import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import moment from "moment";
import PrimaryBtnRB from "../Buttons/primaryBtnRB.js/primaryBtnRB";
import SecondaryBtnRb from "../Buttons/secondaryBtnRb/secondaryBtnRb";
import SectionTitle from "../sectionTitle";
import DataTable from "../Tables/table";
import RadioInputGroup from "../radioInputGroup/radioInputGroup";
import TableRb from "../Tables/tableRb";
import Loader from "../loader";
import ProductNameTd from '../Tables/TableComponents/productNameTd'
import { masterApi } from "../../apis/masterApi";
import "./targettingSection.css";
import { useGlobalContext } from "../../context/global";
import redirect from "../../constant/Redirect";
import { useNavigate } from "react-router-dom";
import { getCurrencySymbol } from "../../constant/defaultCurrencyConfigurations";
import { encodeData } from "../../helper/common/encodeData";
const TargettingSection = ({portfolios}) => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [targettingDefaultTopTen, setTargettingDefaultTopTen] = useState([]);
  const [targettingFunnelTopTen, setTargettingFunnelTopTen] = useState([]);
  const [targettingDefaultLowTen, setTargettingDefaultLowTen] = useState([]);
  const [targettingFunnelLowTen, setTargettingFunnelLowTen] = useState([]);
  const [targettingActiveBtn, setTargettingActiveBtn] = useState(
    "targetting-top-10-btn"
  );
  const [targettingValue, seTtargettingValue] = useState("Default");
  // Derived Variables From State
  const sorting_orderForAPIs = targettingActiveBtn === "targetting-low-10-btn" ? "asc":"desc"
  const targetingValue = targettingValue === "Default" ? "Default" : "Funnel"
  const dataSetForAPI = (sorting_orderForAPIs === "asc" && targetingValue === "Default") ? setTargettingDefaultTopTen : (sorting_orderForAPIs === "desc" && targetingValue === "Funnel") ? setTargettingFunnelTopTen :  (sorting_orderForAPIs === "desc" && targetingValue === "Default") ? setTargettingDefaultLowTen:setTargettingFunnelLowTen
  const navigate = useNavigate();
  const currencySymbol = getCurrencySymbol();
  const { calendarDate,applyCalendar } =
    useGlobalContext();
  const targettingTop10TableHead = [
    "Targeting",
    "Product Name",
    "Match Type",
    "Spends",
    "Clicks",
    "Orders",
    "Revenue",
    "ROAS"
  ];

  const targettingFunnelTopTenTableHead = [
    "Targeting",
    "Match Type",
    "Spends",
    "Orders",
    "ROAS",
    "CVR",
    "CPC"
  ];

  const targettingFunnelLowTenTableHead = [
    "Targeting",
    "Product Name",
    "Match Type",
    "Spends",
    "Orders",
    "ROAS",
    "CVR",
    "CPC"
  ];

  const targetingDefaultLowTenTableHead = [
    "Targeting",
    "Product Name",
    "Match Type",
    "Spends",
    "Orders",
    "ROAS",
    "CVR",
    "CPC" 
  ]

    const formatDateFn = (date) => {
        const selectedDate = new Date(date)
        const formattedDate = selectedDate.getDate() + "-"+ parseInt(selectedDate.getMonth()+1) +"-"+ selectedDate.getFullYear();
        // console.log(formattedDate,"Formmated date");
        return selectedDate.getDate() + "-"+ parseInt(selectedDate.getMonth()+1) +"-"+ selectedDate.getFullYear();
      }
    //   const dateConverter = (startDate, timeEnd) => {
    //     const currentDate = moment().date() // This will return current date in number format
    //     const currentMonth = moment().month()
    //     const currentYear = moment().year();
    //     const newStartDate= new Date(startDate);
    //     const newStartDateY = moment(newStartDate).date()
    //     const newStartMonthY = moment(newStartDate).month()
    //     const newStartYearY = moment(newStartDate).year()
    //     const newEndDate=new Date(timeEnd);
    //     const newEndDateY=moment(timeEnd).date();
    //     const newEndMonthY=moment(timeEnd).month();
    //     const newEndYearY=moment(timeEnd).year();
    //     let result=moment(newStartDate).diff(newEndDate,'days')
    //     const min = Math.min(newStartDateY,newEndDateY)
    //     const max = Math.max(newStartDateY,newEndDateY)
    //     console.log(result,"Result Printed Out with minimum and maximum" , min,max);
    //     console.log(newEndDateY,newStartDateY,newStartMonthY,currentMonth,result) 
    //     if(currentDate === newStartDateY && newEndDateY && result===0) {
    //         setDateDifference("Today");
    //          return true
    //     }

    // useEffect(() => {
    //     formatDateFn(calendarDate[0].startDate);
    //     // console.log(calendarDate[0].startDate);
    //     // dateConverter(calendarDate[0].startDate,calendarDate[0].endDate)
    // },[calendarDate])
    const onButtonClickHandler = (e) => {
        setTargettingActiveBtn(e.target.id);
    }

  async function targettingDefaultTopTenAPI() {
    setIsLoaderVisible(true);
    const filtering = [{field_name:"portfolio_name",operator:"IN",values:portfolios}]
    try {
      const data = {
        level: "account",
        platform: "amazon",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        // date_preset: "last_7d",
        fields:
          "target,match_type,spends,clicks,orders,sale_value,roas,cvr,cpc,image_url,item_name",
        group_by: "target",
        order_by: "spends",
        limit: 10,
        sorting_order: sorting_orderForAPIs,
        ...(portfolios?.length && {filtering:encodeData(filtering)})
      };
      const result = await masterApi(data);
      if (redirect(result)) return navigate("/login");
      dataSetForAPI(result?.data?.data);
      setIsLoaderVisible(false);
      return result;
    } catch (e) {
      setIsLoaderVisible(false);
    }
  }

  // async function targettingDefaultLowTenAPI() {
  //   setIsLoaderVisible(true);
  //   try {
  //     const data = {
  //       level: "account",
  //       platform: "amazon",
  //       start_date: `${formatDateFn(calendarDate[0].startDate)}`,
  //       end_date: `${formatDateFn(calendarDate[0].endDate)}`,
  //       // date_preset: "last_7d",
  //       fields:
  //         "target,match_type,spends,clicks,orders,sale_value,roas,cvr,cpc,image_url,item_name",
  //       group_by: "target",
  //       order_by: "spends",
  //       limit: 10,
  //       sorting_order: "desc",
  //     };
  //     const result = await masterApi(data);
  //     console.log(result);
  //     if (redirect(result)) return navigate("/login");
  //     setTargettingDefaultLowTen(result.data.data);
  //     // console.log(result.data.data,"default lowdata")
  //     setIsLoaderVisible(false);
  //     return result;
  //   } catch (e) {
  //     // console.warn("Error in  Targetting Default Low 10 Api :-", e);
  //     setIsLoaderVisible(false);
  //   }
  // }

  // async function targettingFunnelTopTenAPI() {
  //   setIsLoaderVisible(true);
  //   try {
  //     const data = {
  //       level: "account",
  //       platform: "amazon",
  //       start_date: `${formatDateFn(calendarDate[0].startDate)}`,
  //       end_date: `${formatDateFn(calendarDate[0].endDate)}`,
  //       // date_preset: "last_7d",
  //       fields: "target,match_type,spends,cpc,orders,roas,cvr,image_url,item_name",
  //       group_by: "target",
  //       order_by: "spends",
  //       limit: 10,
  //       sorting_order: "desc",
  //     };
  //     const result = await masterApi(data);
  //     console.log(result,"top10")
  //     if (redirect(result)) return navigate("/login");
  //     setTargettingFunnelTopTen(result.data.data);
  //     // console.log(result.data.data,"funnel topdata")
  //     setIsLoaderVisible(false);
  //     return result;
  //   } catch (e) {
  //     // console.warn("Error in  Targetting Funnel Top 10 Api :-", e);
  //     setIsLoaderVisible(false);
  //   }
  // }

  // async function targettingFunnelLowTenAPI() {
  //   setIsLoaderVisible(true);
  //   try {
  //     const data = {
  //       level: "account",
  //       platform: "amazon",
  //       start_date: `${formatDateFn(calendarDate[0].startDate)}`,
  //       end_date: `${formatDateFn(calendarDate[0].endDate)}`,
  //       // date_preset: "last_7d",
  //       fields: "target,match_type,spends,cpc,orders,roas,cvr,image_url,item_name",
  //       group_by: "target",
  //       order_by: "spends",
  //       limit: 10,
  //       sorting_order: "asc",
  //     };
  //     const result = await masterApi(data);
  //     console.log(result,"top10")
  //     if (redirect(result)) return navigate("/login");
  //     setTargettingFunnelLowTen(result?.data?.data);
  //     // console.log(result?.data?.data,"funnel lowdata")
  //     setIsLoaderVisible(false);
  //     return result;
  //   } catch (e) {
  //     // console.warn("Error in  Targetting Funnel Low 10 Api :-", e);
  //     setIsLoaderVisible(false);
  //   }
  // }

  useEffect(() => {
    targettingDefaultTopTenAPI();
    // targettingDefaultLowTenAPI();
    // targettingFunnelTopTenAPI();
    // targettingFunnelLowTenAPI();
  }, [targettingActiveBtn, targettingValue, applyCalendar , portfolios]);//[targettingActiveBtn, targettingValue, calendarDate]

  let targettingDafaultTop10Low10TableData = (
    <table className="w-100 targetting-sec-table">
      <thead>
        <tr className="colspan-head">
          {targettingTop10TableHead.map((th,index) => {
            if(targettingActiveBtn==="targetting-top-10-btn" && index===1) return false
            return <th>{th}</th>; 
          })}
        </tr>
      </thead>

      <tbody>
        {targettingActiveBtn === "targetting-top-10-btn"
          ? targettingDefaultLowTen?.map((data) => {
              return (
                <tr>
                  <td>{data?.target}</td>
                  <td>{data.match_type ? data.match_type : "-"}</td>
                  <td>{currencySymbol}{data?.spends.toFixed(2)}</td>
                  <td>{data?.clicks}</td>
                  <td>{data?.orders}</td>
                  <td>{currencySymbol}{data?.sale_value?.toFixed(2)}</td>
                  <td>{data?.roas?.toFixed(2)}</td>
                </tr>
              );
            })
          : targettingActiveBtn === "targetting-low-10-btn"
          ? targettingDefaultTopTen?.map((data) => {
              return (
                <tr>
                  <td>{data?.target}</td>
                  <td>
                  <ProductNameTd product_name={data?.item_name} image_url={data?.image_url} asin={data?.target}/>
                  </td>
                  <td>{data?.match_type ? data.match_type : "-"}</td>
                  <td>{currencySymbol}{data?.spends?.toFixed(2)}</td>
                  <td>{data?.clicks}</td>
                  <td>{data?.orders}</td>
                  <td>{currencySymbol}{data?.sale_value?.toFixed(2)}</td>
                  <td>{data.roas ? data?.roas : "-"}</td>
                </tr>
              );
            })
          : null}
      </tbody>
    </table>
  );

  let targettingFunnelTopLowTableData = (
    <table className="table">
      <thead>
        <tr className="colspan-head">
          {targettingValue === "Funnel" &&
          targettingActiveBtn === "targetting-low-10-btn"
            ? targettingFunnelLowTenTableHead.map((th) => {
                return <th>{th} </th>;
              })
            : targettingValue === "Funnel" &&
              targettingActiveBtn === "targetting-top-10-btn"
            ? targettingFunnelTopTenTableHead.map((th) => {
                return <th>{th} </th>;
              })
            : targettingFunnelTopTenTableHead.map((th) => {
                return <th>{th} </th>;
              })}
        </tr>
      </thead>

      <tbody>
        {targettingValue === "Funnel" &&
        targettingActiveBtn === "targetting-top-10-btn"
          ? targettingFunnelTopTen?.map((data) => {
              return (
                <tr>
                  <td className="target-keyword-col">{data.target}</td>
                  <td>{data.match_type ? data.match_type : "-"}</td>
                  <td>{currencySymbol}{data?.spends?.toFixed(2)}</td>
                  <td>{data?.orders}</td>
                  <td>{data?.roas?.toFixed(2)}</td>
                  <td>{data?.cvr?.toFixed(2)}</td>
                  <td>{data?.cpc?.toFixed(2)}</td>
                </tr>
              );
            })
          : targettingValue === "Funnel" &&
            targettingActiveBtn === "targetting-low-10-btn"
          ? targettingFunnelLowTen?.map((data) => {
              return (
                <tr>
                  <td>{data?.target}</td>
                  <td>
                  <ProductNameTd product_name={data?.item_name} image_url={data?.image_url} asin={data?.target}/>
                  </td>
                  <td>{data?.match_type ? data.match_type : "-"}</td>
                  <td>{currencySymbol}{data?.spends?.toFixed(2)}</td>
                  <td>{data?.orders}</td>
                  <td>{data.roas ? data?.roas?.toFixed(2) : "-"}</td>
                  <td>{data.cvr ? data?.cvr?.toFixed(2) : "-"}</td>
                  <td>{data.cpc ? data?.cpc?.toFixed(2) : "-"}</td>
                </tr>
              );
            })
          : null}
      </tbody>
    </table>
  );

  return (
    <div className="section-main-body">
      <Loader visible={isLoaderVisible}></Loader>
      <Stack>
        <Stack direction="horizontal" gap={3} className="d-flex">
          <div className=" d-flex flex-grow-1">
            <SectionTitle sectionTitleHeading="Targeting"></SectionTitle>
          </div>
          <div>
            <RadioInputGroup
              state={targettingValue}
              setState={seTtargettingValue}
              name="targetting_type"
              // selected={targettingValue}

              // selected={"Default"}
              //  label="Marital status"
              radios={["Default", "Funnel"]}
              required
            ></RadioInputGroup>
          </div>
          {/* <DataTable></DataTable> */}
        </Stack>
        <Stack direction="vertical">
          <Stack
            direction="horizontal"
            gap={3}
            className="targetting-table-btn-container"
          >
            {/* <PrimaryBtnRB insideBtnText="Top 10"></PrimaryBtnRB>
                    <SecondaryBtnRb secondaryBtnText="Low 10"></SecondaryBtnRb> */}
            <SecondaryBtnRb
              secondaryBtnText="Top 10"
              className={
                targettingActiveBtn === "targetting-top-10-btn"
                  ? "primary-active-btn"
                  : "primary-inactive-btn"
              }
              id="targetting-top-10-btn"
              onClick={onButtonClickHandler}
            ></SecondaryBtnRb>
            <SecondaryBtnRb
              secondaryBtnText="Bottom 10"
              className={
                targettingActiveBtn === "targetting-low-10-btn"
                  ? "primary-active-btn"
                  : "primary-inactive-btn"
              }
              id="targetting-low-10-btn"
              onClick={onButtonClickHandler}
            ></SecondaryBtnRb>
          </Stack>
          <Stack direction="vertical">
            <div className="targetting-sec-table">
              {/* <DataTable tableHeadRow={targettingTableHead} TableData={targettingTableData}></DataTable> */}
              <TableRb
                table={
                  targettingValue === "Default"
                    ? targettingDafaultTop10Low10TableData
                    : targettingValue === "Funnel"
                    ? targettingFunnelTopLowTableData
                    : targettingDafaultTop10Low10TableData
                }
              ></TableRb>
            </div>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default TargettingSection;
