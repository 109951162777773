import FilterLogicModal from "../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import React, { useEffect, useState, } from "react";
import { useSelector } from "react-redux";
import { useRef } from "react";
import CalenderComponent from "../../components/calender/Calender";
import closeIcon from "../../assets/icons/close_icon.png";
import { useGlobalContext } from "../../context/global";
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.svg";
import Chart from "../../components/Reports/Chart";
import cubejs from "@cubejs-client/core";
import useToken  from "../../helper/Reports/useToken";
import RedirectPopup from '../../components/modal/redirectPopup';
import ReportFilters from "../../components/Reports/Filters";
import SectionTitle from "../../components/sectionTitle";
import previousDays from "../../helper/Reports/common";
import Loader from "../../components/loader";
import useInitialLoad from "../../hooks/useInitialLoad";
import Breadcrumb from '../../components/Breadcrumb/ReportsBreadCrumb';
import Canvas from '../../helper/Screenshot/canvas'
import downloadIcon from "../../assets/svgs/download.svg";
import {formatPieChartDataComplete,dataQueryParser,createQueryURL , getSearchParamsObject, objectoFiltering} from "../../components/Reports/Components/utils";
import {useSearchParams} from "react-router-dom";

const PortfolioReport = (props) => {
    const [token, refresh] = useToken("");
    const [initialLoad,setInitialLoad] = useInitialLoad();
    const { cubeToken, setCubeToken } = useGlobalContext();
    const [showPopup, setShowPopup] = useState(false);
    const [selectedRow, setSelectedRow] = useState(false);
    const { clientAccountId } = useGlobalContext();
    const [searchParams,setSearchParams] = useSearchParams({});
    const cubejsApi = cubejs(cubeToken,{apiUrl: process.env.REACT_APP_CUBE_JS_URL});
    const formatDate = (date) => {
        const offset = date.getTimezoneOffset()
        date = new Date(date.getTime() - (offset*60*1000))
        return date.toISOString().split('T')[0].toString()
    };
    const { calendarDate, setCalendarDate, setDateDifference,applyCalendar, dateDifference, multiCalendarDate } = useGlobalContext();
    const [tableView, setTableView] = useState("table");
    const [showFilters, setShowFilters] = useState(true);
    const allParams = new URLSearchParams(window.location.search);
    const paramsObject = getSearchParamsObject(allParams);
    const previousFiltering = objectoFiltering(paramsObject,"ClientDailyCampaignAmazonTable")
    const viewType = allParams.get('viewtype') ?? 'Performance View';
    const [currentViewType, setCurrentViewType] = useState(viewType);
    const isComparisonView = currentViewType === 'Comparison View';
    let startDate = formatDate(multiCalendarDate.selection1.startDate);
    let endDate = formatDate(multiCalendarDate.selection1.endDate);
    let startDatePrevious = formatDate(
          multiCalendarDate.selection2.startDate
        );
    let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
    const cubeQueryTimeDimensionsFormatted = !isComparisonView ? [{dimension:"ClientDailyCampaignAmazonTable.obDate",dateRange: [
      formatDate(calendarDate[0].startDate),
      formatDate(calendarDate[0].endDate),
    ]}] : [{dimension:"ClientDailyCampaignAmazonTable.obDate",compareDateRange:[
      [startDate, endDate],
      [startDatePrevious, endDatePrevious],
    ]}]
    const dashboardSideBarVisibiltyState = useSelector(
        (state) => state.dashboardData.dashboardSideBarVisibiltyState
    );
    const filterModalInCampaignListVisibilty = useSelector(
        (state) => state.campaignListData.filterModalInCampaignListVisibilty
    );
    const [drrView, setDrrView] = useState("")
    const [showCustomDateBox, setShowCustomDateBox] = useState(false);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [campaignType, setCampaignType] = useState([]);
    const [campaignFormat, setCampaignFormat] = useState([]);
    const [targetingType, setTargetingType] = useState([]);
    const [productSet, setProductSet] = useState([]);
    const [campaignStatus, setCampaignStatus] = useState([]);
    const [portfolioName, setPortfolioName] = useState([]);
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);
    const [cubeQueryMeasure, setCubeQueryMeasure] = useState([
        "ClientDailyCampaignAmazonTable.cost",
        "ClientDailyCampaignAmazonTable.costDRR",
        "ClientDailyCampaignAmazonTable.impressions",
        "ClientDailyCampaignAmazonTable.impressionsDRR",
        "ClientDailyCampaignAmazonTable.clicks",
        "ClientDailyCampaignAmazonTable.clicksDRR",
        "ClientDailyCampaignAmazonTable.ctr",
        "ClientDailyCampaignAmazonTable.ctrDRR",
        "ClientDailyCampaignAmazonTable.cpc",
        "ClientDailyCampaignAmazonTable.cpcDRR",
        "ClientDailyCampaignAmazonTable.cpm",
        "ClientDailyCampaignAmazonTable.cpmDRR",
        "ClientDailyCampaignAmazonTable.conversions14d",
        "ClientDailyCampaignAmazonTable.conversions14dDRR",
        "ClientDailyCampaignAmazonTable.conversion_rate",
        "ClientDailyCampaignAmazonTable.conversion_rateDRR",
        "ClientDailyCampaignAmazonTable.sales14d",
        "ClientDailyCampaignAmazonTable.sales14dDRR",
        "ClientDailyCampaignAmazonTable.average_order_value",
        "ClientDailyCampaignAmazonTable.average_order_valueDRR",
        "ClientDailyCampaignAmazonTable.acos",
        "ClientDailyCampaignAmazonTable.acosDRR",
        "ClientDailyCampaignAmazonTable.roas",
        "ClientDailyCampaignAmazonTable.roasDRR",
      ]);
    const [cubeQueryOrder, setCubeQueryOrder] = useState([
        [
          "ClientDailyCampaignAmazonTable.cost",
          "desc"
        ]
      ]);

    const [cubeQueryDimensions, setCubeQueryDimensions] = useState([
        "ClientDailyCampaignAmazonTable.targetingType",
      ]);
    const [cubeQueryTimeDimensions, setCubeQueryTimeDimensions] = useState(cubeQueryTimeDimensionsFormatted);
    const [cubeQueryFilters, setCubeQueryFilters] = useState([
        {
          member: "ClientDailyCampaignAmazonTable.profileId",
          operator: "equals",
          values: [clientAccountId],
        },
        ...previousFiltering
      ]);
    const [resultSet, setResultSet] = useState({});
    const [chartFilters, setChartFilters] = useState({
      spend:"cost",
      revenue:"sales14d"
    })
    var spendResultSet = formatPieChartDataComplete(resultSet, chartFilters.spend,"ClientDailyCampaignAmazonTable", "targetingType");
    var revenueResultSet = formatPieChartDataComplete(resultSet, chartFilters.revenue,"ClientDailyCampaignAmazonTable", "targetingType");
    const enabledfilters = ["Campaign Type","Campaign Format","Targeting Type","Product Set","Campaign Status","Portfolio Name"]
    const calenderIconRefForCampaignlist = useRef(null);
    const closeCalender = (e) => {
        if (
            calenderIconRefForCampaignlist.current &&
            showCustomDateBox &&
            !calenderIconRefForCampaignlist.current.contains(e.target)
        ) {
            setShowCustomDateBox(false);
        }
    };


    const dateInputClickHandler = (e) => {
        setShowCustomDateBox(!showCustomDateBox);
    };

    useEffect(() => {
        document.addEventListener("mousedown", closeCalender);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", closeCalender);
        };
    }, [showCustomDateBox]);



    useEffect(() => {
      if(currentViewType !== "Comparison View") {
          const startDate = formatDate(calendarDate[0].startDate);
          const endDate = formatDate(calendarDate[0].endDate);
          if (startDate !== cubeQueryTimeDimensions[0]["dateRange"][0] || endDate !== cubeQueryTimeDimensions[0]["dateRange"][1]) {
              setIsLoaderVisible(true)
              setCubeQueryTimeDimensions((prev) => {
                  let data = [...prev];
                  data[0]["dateRange"] = [startDate, endDate];
                  return data;
              });
          }
      } 
  }, [applyCalendar]);
  
  useEffect(() => {
      if(currentViewType === "Comparison View") {
          let startDate = formatDate(multiCalendarDate.selection1.startDate);
          let endDate = formatDate(multiCalendarDate.selection1.endDate);
          let startDatePrevious = formatDate(multiCalendarDate.selection2.startDate);
          let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
          let apiDateRange = cubeQueryTimeDimensions[0]["compareDateRange"]
          if (startDate !== apiDateRange[0][0] || endDate !== apiDateRange[0][1] || startDatePrevious !== apiDateRange[1][0] || endDatePrevious !== apiDateRange[1][1]) {
              setIsLoaderVisible(true)
              setCubeQueryTimeDimensions((prev) => {
                  let data = [...prev];
                  data[0]["compareDateRange"] = [[startDate, endDate], [startDatePrevious, endDatePrevious]];
                  return data;
              });
          } 
      }
  },[applyCalendar])

    useEffect(()=>{
      if(cubeToken) {
        cubejsApi.load({
            measures: cubeQueryMeasure,
            order: cubeQueryOrder,
            dimensions: cubeQueryDimensions,
            filters: cubeQueryFilters,
            timeDimensions: cubeQueryTimeDimensions,
            limit: 5000,
          },{
            headers: {
              'Access-Control-Allow-Origin': true
            }
          }).then((resultSet) => {
            setResultSet(resultSet);
            setIsLoaderVisible(false)
            setInitialLoad(false)
            if(currentViewType === 'Comparison View') {
              setTableView("ComparisonTable")
            } else {
                setTableView("table")
            }
          }).catch((error) => {
            if(error.message === 'Invalid token') {
                setCubeToken(false)
                localStorage.setItem("cubeToken", null);
                refresh()
            }
          });
        }
    },[cubeQueryFilters,cubeQueryTimeDimensions, cubeToken, drrView])

    const filterCampaigns = (e, type) => {
        setIsLoaderVisible(true)
        const parsedQueryFilters = dataQueryParser(JSON.parse(JSON.stringify(cubeQueryFilters)),e,type,"ClientDailyCampaignAmazonTable");
        const parsedParams = createQueryURL(parsedQueryFilters);
        parsedParams['viewtype'] = currentViewType
        const previousParams = new URLSearchParams(window.location.search)
        if(previousParams.get('metricViewType')) {
          parsedParams['metricViewType'] = previousParams.get('metricViewType')
        }
        setCubeQueryFilters((prev) => {
          const parsedData = dataQueryParser(prev,e,type,"ClientDailyCampaignAmazonTable")
          return parsedData
        });
        setSearchParams(parsedParams)
         if (type === "campaignType") {
            setCampaignType(e);
          } else if (type === "campaignFormat") {
            setCampaignFormat(e);
          } else if (type === "targetingType") {
            setTargetingType(e);
          } else if (type === "productSet") {
            setProductSet(e);
          } else if (type === "campaignStatus") {
            setCampaignStatus(e);
          } else if (type === "portfolioName") {
            setPortfolioName(e);
          }
    };

    const expendChange = (data, type, index) => {
      setShowPopup(true);
      setSelectedRow(data)
  
    };

    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      if(queryParams.get('start') &&  queryParams.get('end')) {
          setDate(queryParams.get('start'), queryParams.get('end'));
      }
      // const stateSetters = ['campaignFormat','targetingType','productSet','portfolioName','campaignStatus','campaignType'];
      // stateSetters.forEach(value => {
      //     if(queryParams.get(value)) {
      //         filterCampaigns(queryParams.get(value).split(","), value)
      //     }
      //   });
    }, []);

    const setDate = (currentDate, previousDate) => {
      const dates = {
        startDate: new Date(currentDate),
        endDate: new Date(previousDate),
        key: "selection",
      };
      setDateDifference("Custom");
      setCalendarDate([dates]);
      setCubeQueryTimeDimensions((prev) => {
        let data = [...prev];
        data[0]["dateRange"] = [
          formatDate(dates.startDate),
          formatDate(dates.endDate),
        ];
        return data;
      });
    };

    useEffect(() => {
        setIsFilterVisible(filterModalInCampaignListVisibilty);
    }, [filterModalInCampaignListVisibilty]);
    const handleClosePopup = (value) => {
      setShowPopup(false);
    };
    const handleRedirect = (type) => {
      if(type==="Targeting Report") {
        var url ="/targeting-report";
      } else if (type==="Campaign Report") {
        var url ="/campaign-report";
      } else if (type==="Category Report") {
        var url ="/detailed-category-report";
      } else {
        return '';
      }
      setShowPopup(false);
      const queryParams = [
        ['start', formatDate(calendarDate[0].startDate)],
        ['end', formatDate(calendarDate[0].endDate)],
        ['campaignType', campaignType],
        ['campaignFormat', campaignFormat],
        ['targetingType', selectedRow],
        ['productSet', productSet],
        ['portfolioName', portfolioName]
      ];
      const queryString = queryParams
        .filter(([key, value]) => value.length>0)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');
      url += `?${queryString}`;
      // Open URL in new tab
      window.open(url);
    }
    useEffect(() => {
      setIsFilterVisible(filterModalInCampaignListVisibilty);
    }, [filterModalInCampaignListVisibilty]);

    const handleComparisonView = (type) => {
      if(type === 'Comparison View') {
          setCubeQueryTimeDimensions([{
                dimension: "ClientDailyCampaignAmazonTable.obDate",
                compareDateRange: [[startDate, endDate], [startDatePrevious, endDatePrevious]],
              },
            ]);
            setIsLoaderVisible(true)
      } else if(type !== 'Comparison View' && currentViewType === 'Comparison View') {
          setCubeQueryTimeDimensions([{
              dimension: "ClientDailyCampaignAmazonTable.obDate",
              dateRange: [formatDate(calendarDate[0].startDate), formatDate(calendarDate[0].endDate)],
            },
          ]);
          setIsLoaderVisible(true)
      }
      setCurrentViewType(type)
    }

    
   

    function dataFilter(data) {
      setIsLoaderVisible(true)
      const table = "ClientDailyCampaignAmazonTable.";
      let tempFilters = [...cubeQueryFilters];
      Object.entries(data).forEach(([key, value]) => {
        tempFilters.forEach((x, i) => {
          if (x.member === `${table}${key}`) {
            tempFilters.splice(i, 1);
          }
        });
        if (value.min) {
          tempFilters.push({
            member: `${table}${key}`,
            operator: 'gte',
            values: [parseFloat(value.min)]
          });
        }
        if (value.max) {
          tempFilters.push({
            member: `${table}${key}`,
            operator: 'lte',
            values: [parseFloat(value.max)]
          });
        }
      })
      setCubeQueryFilters(tempFilters);
    }

    const handleDownload = () => {
      Canvas({ name: 'targeting-format-report' });
    };
    const handleDrrView = (value, type) => {
      setDrrView(value)
      if(value !== 'drr') {
          setChartFilters({spend:"cost",revenue:"sales14d"})
      } else {
          setChartFilters({spend:"costDRR",revenue:"sales14dDRR"})
      }
    };

    function handleSearch(data) {
      let prevData = [...cubeQueryFilters]
        prevData.forEach((x, i) => {
          if (x.member === cubeQueryDimensions[0]) {
            prevData.splice(i, 1);
          }
        });
        if(data.input) {
          prevData.push({
              member: cubeQueryDimensions[0],
              operator: data.select,
              values: [data.input],
            });
        }
        setShowFilters(data)
        setCubeQueryFilters(prevData);
    }
    return (
        <div
            className={
                dashboardSideBarVisibiltyState
                    ? "main-content-container-onopen-mode"
                    : "main-content-container-oncolapse-mode"
            }
            id="main-box" >
            <Loader visible={initialLoad}></Loader>
            <RedirectPopup show={showPopup} onClose={handleClosePopup} handleRedirect={handleRedirect} pages={["Targeting Report", "Campaign Report", "Category Report"]} />
            <div style={{marginLeft: "auto"}}>
                <button className="date-container date-btn-campaignlist d-none"
                    onClick={dateInputClickHandler} >
                    <div className="d-flex justify-conntent-center">
                        <span className="date-btn-text">
                            {dateDifference}
                        </span>
                    </div>
                    <button className="btn-calender-img-holder">
                        <span className="">
                            <img
                                className="dropdown-img-for-select"
                                src={selectSideIcon}
                                alt={"SelectSideIcon"}
                            ></img>

                            <img
                                className="calender-icon-for-campaignlist"
                                src={CalenderIconForCampaignList}
                                alt={"CalendarIcon"}
                            ></img>
                        </span>
                    </button>
                </button>
            </div>

            <div
                ref={calenderIconRefForCampaignlist}
                className={
                    showCustomDateBox
                        ? "calender-inclasslist-container"
                        : "hide"
                }
            >
                <CalenderComponent setShowCustomDateBox={setShowCustomDateBox}></CalenderComponent>
                <span className="calender-close-icon-campaignlist">
                    <img
                        onClick={() => setShowCustomDateBox(false)}
                        src={closeIcon}
                        alt={"CloseIcon"}
                    ></img>
                </span>
            </div>

            <Breadcrumb />
            <SectionTitle sectionTitleHeading='Targeting Group Report'></SectionTitle>
            <div className="d-flex justify-content-between mt-4 mb-4 gap-4 position-relative" id="downloadChart" >
            <div onClick={handleDownload} className="downloadChartWrapper pie" id="downloadChartWrapper">
                  <img src={downloadIcon} className="cursor-pointer-global DownloadIcon" alt="overview-settings-tile" />
            </div>
                <div  className="w-100 min-h-300 pie-container">
                <h4 className="sub-heading-pie f-20 p-4">Spends</h4>
                    <Chart type="apexpie" resultSet={spendResultSet} classes={'marketing-reports'} />
         
                </div>
                <div className="w-100 min-h-300 pie-container">
                    <h4 className="sub-heading-pie f-20 p-4">Revenue</h4>
                    <Chart type="apexpie" resultSet={revenueResultSet} classes={'marketing-reports'} />
                </div>

            </div>

            <ReportFilters handleDropdown={filterCampaigns} enabledfilters={enabledfilters} />

            <div>
                {
                    <FilterLogicModal
                        filterFor="campaignlist"
                        show={isFilterVisible}
                    ></FilterLogicModal>
                }
            </div>

            <div className="">
                    <Chart shimmerEffect={isLoaderVisible} type={tableView} resultSet={resultSet} classes={'marketing-reports'} handleChange={expendChange} FunnelViews={["Performance View","Funnel View","Comparison View","Custom View"]} handleComparisonView={handleComparisonView} CurrentViewType={currentViewType} page="expendable" dataFilter={dataFilter} drrView={handleDrrView} drrViewType={drrView} handleSearch={handleSearch}
            showFilter={showFilters}/>
            </div>
        </div>
    )
}

export default PortfolioReport;