export const defaultCurrencyMapper = [
    {
        currency_code:"INR",
        currency_symbol:"₹"
    },
    {
        currency_code:"CAD",
        currency_symbol:"$"
    },
    {
        currency_code:"USD",
        currency_symbol:"$"
    }
]

export const currencySymbolPicker = (currency_code) => {
    return defaultCurrencyMapper.filter((data) => data?.currency_code === currency_code)[0]?.currency_symbol ?? "₹"
}

export const getCurrencySymbol = () => {
    return JSON.parse(localStorage.getItem("currency_symbol")) ?? "₹"
}