import { useEffect, useState } from "react";


/**
 * @Note Use this hook when you want to do action on initial Load of the page and don't forget to setInitialLoad to false in your page first useEffect
 * @returns Initial State and Updater Function to update the state
 */

export default function useInitialLoad() {
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
   setInitialLoad(true)
  }, []);

  return [initialLoad,setInitialLoad]
}
