export const initialDataHeadsForBidsAdjustment = [
  {
    fieldName: "Campaign Name",
    id: "campaignName",
    isSortingRequired: true,
  },
  {
    fieldName: "Placement",
    id: "placement",
    isSortingRequired: true,
  },
  {
    fieldName: "Bid Adjustment Value",
    id: "bidvalue",
  },
  {
    fieldName: "Bid Strategy",
    id: "stratagy",
  },{
    fieldName: "Spends",
    id: "cost",
    isSortingRequired: true,
  },{
    fieldName: "Revenue",
    id: "sales14d",
    isSortingRequired: true,
  },{
    fieldName: "Orders",
    id: "conversions14d",
    isSortingRequired: true,
  },{
    fieldName: "ROAS",
    id: "roas",
    isSortingRequired: true,
  },{
    fieldName: "CTR",
    id: "ctr",
    isSortingRequired: true,
  },{
    fieldName: "CPC",
    id: "cpc",
    isSortingRequired: true,
  },{
    fieldName: "DBR",
    id: "dbr",
  },{
    fieldName: "Avg BR",
    id: "burnrate",
  },
];
