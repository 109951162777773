export const initialCampaignCreationState = {
    name: "",
    tenant_id: `${JSON.parse(localStorage.getItem("clientId"))}`,
    daily_budget: 0,
    status: "ENABLED",
    platform_status: "ENABLED",
    type: "SP-Auto",
    objective: "SP",
    targeting_objective: "Prospect",
    source: "amazon",
    bidding: {
      bidding_strategy: "",
      bidding_value: 100,
    },
    account_id: `${JSON.parse(localStorage.getItem("accountId"))}`,
    amz_portfolio_id: "null",
    amz_targeting_type: "Automatic",
    amz_bidding_premium_bid_adjust: true,
}

export const initialAdgroupCreationState = {
    campaign_id: "",
    name: "",
    status: "ENABLED",
    source: "amazon",
    type: "default",
    platform_status: "ENABLED",
    account_id: `${JSON.parse(localStorage.getItem("accountId"))}`,
    amz_default_bid: `${JSON.parse(localStorage.getItem("defaultBid"))}` ?? "1" ,
    amz_manual_bid:{
      amz_loose_match_bid_adyogi:"",
      amz_close_match_bid_adyogi:"",
      amz_substitutes_bid_adyogi:"",
      amz_complements_bid_adyogi:""
    }
}

export const initialCreativesCreationState = {
        name: "",
        type: "",
        source: "amazon",
        product_set_type: "product_set_type1",
        product_set: "Product_Set",
        status: "ENABLED",
        platform_status: "ENABLED",
        tenant_id: `${JSON.parse(localStorage.getItem("clientId"))}`,
        adset_id: "",
}