import FilterLogicModal from "../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRef } from "react";
import CalenderComponent from "../../components/calender/Calender";
import closeIcon from "../../assets/icons/close_icon.png";
import { useGlobalContext } from "../../context/global";
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.svg";
import Chart from "../../components/Reports/Chart";
import cubejs from "@cubejs-client/core";
import useToken from "../../helper/Reports/useToken";
import ReportFilters from "../../components/Reports/Filters";
import SectionTitle from "../../components/sectionTitle";
import previousDays from "../../helper/Reports/common";
import Loader from "../../components/loader";
import useInitialLoad from "../../hooks/useInitialLoad";
import Breadcrumb from '../../components/Breadcrumb/ReportsBreadCrumb';
import Canvas from '../../helper/Screenshot/canvas'
import downloadIcon from "../../assets/svgs/download.svg";
import {formatPieChartData,createQueryURL,dataQueryParser,objectoFiltering,getSearchParamsObject} from "../../components/Reports/Components/utils";
import {useSearchParams} from "react-router-dom";

const ProductReport = (props) => {
  const { calendarDate, setCalendarDate, setDateDifference,applyCalendar, dateDifference,multiCalendarDate } = useGlobalContext();
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const [initialLoad,setInitialLoad] = useInitialLoad();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [token, refresh] = useToken("");
  const [searchParams,setSearchParams] = useSearchParams({});
  const { cubeToken, setCubeToken } = useGlobalContext();
  const { clientAccountId } = useGlobalContext();
  const [tableView, setTableView] = useState("table");
  const [showFilters, setShowFilters] = useState(true);
  const allParams = new URLSearchParams(window.location.search);
  const paramsObject = getSearchParamsObject(allParams);
  const previousFiltering = objectoFiltering(paramsObject,"ClientDailyPlacementAmazonTable")
  const viewType = allParams.get('viewtype') ?? 'Performance View';
  const [currentViewType, setCurrentViewType] = useState(viewType);
  const isComparisonView = currentViewType === 'Comparison View';
  const cubejsApi = cubejs(cubeToken, {apiUrl: process.env.REACT_APP_CUBE_JS_URL});
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const formatDate = (date) => {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split("T")[0].toString();
  };
  let startDate = formatDate(multiCalendarDate.selection1.startDate);
  let endDate = formatDate(multiCalendarDate.selection1.endDate);
  let startDatePrevious = formatDate(
        multiCalendarDate.selection2.startDate
      );
  let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
  const cubeQueryTimeDimensionsFormatted = !isComparisonView ? [{dimension:"ClientDailyPlacementAmazonTable.date",dateRange: [
    formatDate(calendarDate[0].startDate),
    formatDate(calendarDate[0].endDate),
  ]}] : [{dimension:"ClientDailyPlacementAmazonTable.date",compareDateRange:[
    [startDate, endDate],
    [startDatePrevious, endDatePrevious],
  ]}]
  const [campaignType, setCampaignType] = useState([]);
  const [campaignFormat, setCampaignFormat] = useState([]);
  const [targetingType, setTargetingType] = useState([]);
  const [placement, setPlacement] = useState([]);
  const [campaignStatus, setCampaignStatus] = useState([]);
  const [portfolioName, setPortfolioName] = useState([]);
  const [childrenOne, setChildrenOne] = useState({});
  const [childDataIndex, setChildDataIndex] = useState(0);
  const [cubeQueryMeasure, setCubeQueryMeasure] = useState([
    "ClientDailyPlacementAmazonTable.cost",
    "ClientDailyPlacementAmazonTable.costDRR",
    "ClientDailyPlacementAmazonTable.impressions",
    "ClientDailyPlacementAmazonTable.impressionsDRR",
    "ClientDailyPlacementAmazonTable.clicks",
    "ClientDailyPlacementAmazonTable.clicksDRR",
    "ClientDailyPlacementAmazonTable.ctr",
    "ClientDailyPlacementAmazonTable.ctrDRR",
    "ClientDailyPlacementAmazonTable.cpc",
    "ClientDailyPlacementAmazonTable.cpcDRR",
    "ClientDailyPlacementAmazonTable.cpm",
    "ClientDailyPlacementAmazonTable.cpmDRR",
    "ClientDailyPlacementAmazonTable.conversions14d",
    "ClientDailyPlacementAmazonTable.conversions14dDRR",
    "ClientDailyPlacementAmazonTable.conversion_rate",
    "ClientDailyPlacementAmazonTable.conversion_rateDRR",
    "ClientDailyPlacementAmazonTable.sales14d",
    "ClientDailyPlacementAmazonTable.sales14dDRR",
    "ClientDailyPlacementAmazonTable.average_order_value",
    "ClientDailyPlacementAmazonTable.average_order_valueDRR",
    "ClientDailyPlacementAmazonTable.acos",
    "ClientDailyPlacementAmazonTable.acosDRR",
    "ClientDailyPlacementAmazonTable.roas",
    "ClientDailyPlacementAmazonTable.roasDRR",
  ]);
  
  const [cubeQueryOrder, setCubeQueryOrder] = useState([
    ["ClientDailyPlacementAmazonTable.cost", "desc"],
    ["ClientDailyPlacementAmazonTable.campaignFormat", "desc"],
  ]);

  const [cubeChildRowQueryOrder, setCubeChildRowQueryOrder] = useState([
    ["ClientDailyPlacementAmazonTable.cost", "desc"],
  ]);

  const [cubeQueryDimensions, setCubeQueryDimensions] = useState([
    "ClientDailyPlacementAmazonTable.placement",
  ]);

  const [cubeChildRowsQueryDimensions, setCubeChildRowsQueryDimensions] = useState([
    "ClientDailyPlacementAmazonTable.placement",
    "ClientDailyPlacementAmazonTable.campaignFormat"
  ]);
  const [drrView, setDrrView] = useState("")

  const [cubeQueryTimeDimensions, setCubeQueryTimeDimensions] = useState(cubeQueryTimeDimensionsFormatted);
  const [cubeQueryFilters, setCubeQueryFilters] = useState([
    {
      member: "ClientDailyPlacementAmazonTable.profileId",
      operator: "equals",
      values: [clientAccountId],
    },
    ...previousFiltering
  ]);
  const [cubeChildRowsQueryFilters, setCubeChildRowsQueryFilters] = useState([
    {
      member: "ClientDailyPlacementAmazonTable.profileId",
      operator: "equals",
      values: [clientAccountId],
    },
    ...previousFiltering
  ]);
  const enabledfilters = ["Campaign Type","Campaign Format","Targeting Type","Placement","Campaign Status","Portfolio Name"]
  const [resultSet, setResultSet] = useState({});
  const [chartFilters, setChartFilters] = useState({
    spend:"cost",
    revenue:"sales14d"
  })
  var spendResultSet = formatPieChartData(resultSet, chartFilters.spend,"ClientDailyPlacementAmazonTable", "placement");
  var revenueResultSet = formatPieChartData(resultSet, chartFilters.revenue,"ClientDailyPlacementAmazonTable", "placement");

  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );


  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if(queryParams.get('start') &&  queryParams.get('end')) {
        setDate(queryParams.get('start'), queryParams.get('end'));
    }
    // const stateSetters = ['campaignFormat','targetingType','productSet','portfolioName','campaignStatus','campaignType'];
    // stateSetters.forEach(value => {
    //     if(queryParams.get(value)) {
    //         filterCampaigns(queryParams.get(value).split(","), value)
    //     }
    //   });
  }, []);

  const setDate = (currentDate, previousDate) => {
    const dates = {
      startDate: new Date(currentDate),
      endDate: new Date(previousDate),
      key: "selection",
    };
    setDateDifference("Custom");
    setCalendarDate([dates]);
    setCubeQueryTimeDimensions((prev) => {
      let data = [...prev];
      data[0]["dateRange"] = [
        formatDate(dates.startDate),
        formatDate(dates.endDate),
      ];
      return data;
    });
  };

  useEffect(() => {
    if(currentViewType !== "Comparison View") {
        const startDate = formatDate(calendarDate[0].startDate);
        const endDate = formatDate(calendarDate[0].endDate);
        if (startDate !== cubeQueryTimeDimensions[0]["dateRange"][0] || endDate !== cubeQueryTimeDimensions[0]["dateRange"][1]) {
            setIsLoaderVisible(true)
            setCubeQueryTimeDimensions((prev) => {
                let data = [...prev];
                data[0]["dateRange"] = [startDate, endDate];
                return data;
            });
        }
    } 
}, [applyCalendar]);

useEffect(() => {
    if(currentViewType === "Comparison View") {
        let startDate = formatDate(multiCalendarDate.selection1.startDate);
        let endDate = formatDate(multiCalendarDate.selection1.endDate);
        let startDatePrevious = formatDate(multiCalendarDate.selection2.startDate);
        let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
        let apiDateRange = cubeQueryTimeDimensions[0]["compareDateRange"]
        if (startDate !== apiDateRange[0][0] || endDate !== apiDateRange[0][1] || startDatePrevious !== apiDateRange[1][0] || endDatePrevious !== apiDateRange[1][1]) {
            setIsLoaderVisible(true)
            setCubeQueryTimeDimensions((prev) => {
                let data = [...prev];
                data[0]["compareDateRange"] = [[startDate, endDate], [startDatePrevious, endDatePrevious]];
                return data;
            });
        } 
    }
},[applyCalendar])

  useEffect(() => {
    //table data
    if (cubeToken) {
      cubejsApi.load({
            measures: cubeQueryMeasure,
            order: cubeQueryOrder,
            dimensions: cubeQueryDimensions,
            filters: cubeQueryFilters,
            timeDimensions: cubeQueryTimeDimensions,
            limit: 5000,
          },{
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        ).then((resultSet) => {
          setResultSet(resultSet);
          setIsLoaderVisible(false)
          setInitialLoad(false)
          if(currentViewType === 'Comparison View') {
            setTableView("ComparisonTable")
          } else {
              setTableView("table")
          }
        }).catch((error) => {
          if(error.message === 'Invalid token') {
              setCubeToken(false)
              localStorage.setItem("cubeToken", null);
              refresh()
          }
        });
    }
  }, [cubeQueryFilters,cubeQueryTimeDimensions, cubeToken]);


  const filterCampaigns = (e, type) => {
    setChildrenOne({})
    setIsLoaderVisible(true)
    const parsedQueryFilters = dataQueryParser(JSON.parse(JSON.stringify(cubeQueryFilters)),e,type,"ClientDailyPlacementAmazonTable");
    const parsedParams = createQueryURL(parsedQueryFilters);
    parsedParams['viewtype'] = currentViewType
    const previousParams = new URLSearchParams(window.location.search)
    if(previousParams.get('metricViewType')) {
      parsedParams['metricViewType'] = previousParams.get('metricViewType')
    }
    setSearchParams(parsedParams)
    setCubeQueryFilters((prev) => {
      let data = [...prev];
      data.forEach((x, i) => {
        if (x.member === "ClientDailyPlacementAmazonTable."+type) {
          data.splice(i, 1);
        }
      });
      if (e.length>0) {
        data.push({
          member: "ClientDailyPlacementAmazonTable."+type,
          operator: "equals",
          values: e,
        });
      }
      return data;
    });
    setCubeChildRowsQueryFilters((prev) => {
      let data = [...prev];
      data.forEach((x, i) => {
        if (x.member === "ClientDailyPlacementAmazonTable."+type) {
          data.splice(i, 1);
        }
      });
      if (e.length>0) {
        data.push({
          member: "ClientDailyPlacementAmazonTable."+type,
          operator: "equals",
          values: e,
        });
      }
      return data;
    });
    if (type === "campaignType") {
      setCampaignType(e);
    } else if (type === "campaignFormat") {
      setCampaignFormat(e);
    } else if (type === "targetingType") {
      setTargetingType(e);
    } else if (type === "placement") {
      setPlacement(e);
    } else if (type === "campaignStatus") {
      setCampaignStatus(e);
    } else if (type === "portfolioName") {
      setPortfolioName(e);
    }
  };

  const expendChange = (data, type, index) => {
      let prevData = [...cubeChildRowsQueryFilters]
      prevData.forEach((x, i) => {
        if (x.member === 'ClientDailyPlacementAmazonTable.placement') {
          prevData.splice(i, 1);
        }
      });
      prevData.push({
          member: 'ClientDailyPlacementAmazonTable.placement',
          operator: "equals",
          values: [data],
        });
      
      setChildDataIndex(data)
      setCubeChildRowsQueryFilters(prevData);
  };

  function dataFilter(data) {
    setChildrenOne({})
    setIsLoaderVisible(true)
    const table = "ClientDailyPlacementAmazonTable.";
    let tempFilters = [...cubeQueryFilters];
    Object.entries(data).forEach(([key, value]) => {
      tempFilters.forEach((x, i) => {
        if (x.member === `${table}${key}`) {
          tempFilters.splice(i, 1);
        }
      });
      if (value.min) {
        tempFilters.push({
          member: `${table}${key}`,
          operator: 'gte',
          values: [parseFloat(value.min)]
        });
      }
      if (value.max) {
        tempFilters.push({
          member: `${table}${key}`,
          operator: 'lte',
          values: [parseFloat(value.max)]
        });
      }
    })
    setCubeQueryFilters(tempFilters);
    setCubeChildRowsQueryFilters(tempFilters);
  }

  useEffect(() => {
    let getdata = false
    let prevData = [...cubeChildRowsQueryFilters]
    prevData.forEach((x, i) => {
        if (x.member === 'ClientDailyPlacementAmazonTable.placement') {
            getdata = true
        }
      });
      if(getdata) {
        cubejsApi.load({
            measures: cubeQueryMeasure,
            order: cubeChildRowQueryOrder,
            dimensions: cubeChildRowsQueryDimensions,
            filters: cubeChildRowsQueryFilters,
            timeDimensions: cubeQueryTimeDimensions,
            limit: 5000,
          },{
            headers: {"Access-Control-Allow-Origin": true,},
          }
        ).then((childResultSet) => {
          setChildrenOne((prev) => { return {...prev,[childDataIndex]: childResultSet}})
        }).catch((error) => {
          if(error.message === 'Invalid token') {
              setCubeToken(false)
              localStorage.setItem("cubeToken", null);
              refresh()
          }
        });
      }
    
  },[cubeChildRowsQueryFilters])

  const calenderIconRefForCampaignlist = useRef(null);

  const closeCalender = (e) => {
    if (
      calenderIconRefForCampaignlist.current &&
      showCustomDateBox &&
      !calenderIconRefForCampaignlist.current.contains(e.target)
    ) {
      setShowCustomDateBox(false);
    }
  };

  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeCalender);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeCalender);
    };
  }, [showCustomDateBox]);

  const handleComparisonView = (type) => {
    setChildrenOne({})
    if(type === 'Comparison View') {
        setCubeQueryTimeDimensions([{
              dimension: "ClientDailyPlacementAmazonTable.date",
              compareDateRange: [[startDate, endDate], [startDatePrevious, endDatePrevious]],
            },
          ]);
          setIsLoaderVisible(true)
    } else if(type !== 'Comparison View' && currentViewType === 'Comparison View') {
        setCubeQueryTimeDimensions([{
            dimension: "ClientDailyPlacementAmazonTable.date",
            dateRange: [formatDate(calendarDate[0].startDate), formatDate(calendarDate[0].endDate)],
          },
        ]);
        setIsLoaderVisible(true)
    }
    setCurrentViewType(type)
  }

  
 

  useEffect(() => {
    setIsFilterVisible(filterModalInCampaignListVisibilty);
  }, [filterModalInCampaignListVisibilty]);

  const handleDownload = () => {
    Canvas({ name: 'placement-format-report' });
  };
  const handleDrrView = (value, type) => {
    setDrrView(value)
    setChildrenOne({})
    if(value !== 'drr') {
        setChartFilters({spend:"cost",revenue:"sales14d"})
    } else {
        setChartFilters({spend:"costDRR",revenue:"sales14dDRR"})
    }
  };
  function handleSearch(data) {
    let prevData = [...cubeQueryFilters]
      prevData.forEach((x, i) => {
        if (x.member === cubeQueryDimensions[0]) {
          prevData.splice(i, 1);
        }
      });
      if(data.input) {
        prevData.push({
            member: cubeQueryDimensions[0],
            operator: data.select,
            values: [data.input],
          });
      }
      setShowFilters(data)
      setCubeQueryFilters(prevData);
      setCubeChildRowsQueryFilters(prevData);
  }
  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
      <Loader visible={initialLoad}></Loader>
      <div style={{marginLeft: "auto"}}>
        <button
          className="date-container date-btn-campaignlist d-none"
          onClick={dateInputClickHandler}
        >
          <div className="d-flex justify-conntent-center">
            <span className="date-btn-text">{dateDifference}</span>
          </div>
          <button className="btn-calender-img-holder">
            <span className="">
              <img
                className="dropdown-img-for-select"
                src={selectSideIcon}
                alt={"SelectSideIcon"}
              ></img>

              <img
                className="calender-icon-for-campaignlist"
                src={CalenderIconForCampaignList}
                alt={"CalendarIcon"}
              ></img>
            </span>
          </button>
        </button>
      </div>

      <div
        ref={calenderIconRefForCampaignlist}
        className={
          showCustomDateBox ? "calender-inclasslist-container" : "hide"
        }
      >
        <CalenderComponent  setShowCustomDateBox={setShowCustomDateBox}></CalenderComponent>
        <span className="calender-close-icon-campaignlist">
          <img
            onClick={() => setShowCustomDateBox(false)}
            src={closeIcon}
            alt={"CloseIcon"}
          ></img>
        </span>
      </div>

      <Breadcrumb />
      <SectionTitle sectionTitleHeading='Placement Format Report'></SectionTitle>

      <div className="d-flex justify-content-between mt-4 mb-4 gap-4 position-relative" id="downloadChart" >
      <div onClick={handleDownload} className="downloadChartWrapper pie" id="downloadChartWrapper">
            <img src={downloadIcon} className="cursor-pointer-global DownloadIcon" alt="overview-settings-tile" />
      </div>
        <div className="w-100 min-h-300 pie-container">
          <h4 className="sub-heading-pie f-20 p-4">Spends</h4>
          <Chart
            type="apexpie"
            resultSet={spendResultSet}
            classes={"marketing-reports"}
            display={"spends"}
          />
        </div>
        <div className="w-100 min-h-300 pie-container">
          <h4 className="sub-heading-pie f-20 p-4">Revenue</h4>
          <Chart
            type="apexpie"
            resultSet={revenueResultSet}
            classes={"marketing-reports"}
            display={"spends"}
          />
        </div>
      </div>

      <ReportFilters handleDropdown={filterCampaigns} enabledfilters={enabledfilters} />

      <div>
        {
          <FilterLogicModal
            filterFor="campaignlist"
            show={isFilterVisible}
          ></FilterLogicModal>
        }
      </div>

      <div className="">
          <Chart
            type={tableView}
            resultSet={resultSet}
            classes={"marketing-reports"}
            display={'campaign_format'}
            handleChange={expendChange}
            childrenOneData={childrenOne}
            childrenOneHeader={'Campaign Format'}
            FunnelViews={["Performance View","Funnel View","Comparison View","Custom View"]} handleComparisonView={handleComparisonView} CurrentViewType={currentViewType} page="expendable"
            dataFilter={dataFilter}
            shimmerEffect={isLoaderVisible}
            drrView={handleDrrView} drrViewType={drrView}
            handleSearch={handleSearch}
            showFilter={showFilters}
          />
      </div>
    </div>
  );
};

export default ProductReport;
