import { COLORS_SERIES, commonOptions, useDrilldownCallback } from "./utils";
import { Pie } from "react-chartjs-2";

export const PieChartRenderer = ({
    resultSet,
    pivotConfig,
    onDrilldownRequested,
    displayType,
  }) => {
    const data = {
      labels: resultSet.categories(pivotConfig).map((c) => c.x),
      datasets: resultSet.series(pivotConfig).map((s) => ({
        label: s.title,
        data: s.series.map((r) => new Date(r.value).toJSON().slice(0, 10)),
        yValues: [s.key],
        backgroundColor: COLORS_SERIES,
        hoverBackgroundColor: COLORS_SERIES,
      })),
    };
    const getElementAtEvent = useDrilldownCallback({
      datasets: data.datasets,
      labels: data.labels,
      pivotConfig,
      onDrilldownRequested,
    });
    return (
      <Pie
        type="pie"
        data={data}
        options={commonOptions}
        getElementAtEvent={getElementAtEvent}
      />
    );
  };
  