
import {v4 as uuidv4} from 'uuid'
import { getCurrencySymbol } from '../../constant/defaultCurrencyConfigurations';
import { calculateAOV,calculateACOS,calculateCPC,calculateCPM,calculateCTR,calculateCVR,calculateROAS } from '../CalculateMetrices/calculateMetrics';




export default function  consolidateData (campaigns, adsets, adsetStats, campaignStats,campName,todayCampaignStats) {
    const currencySymbol = getCurrencySymbol()
    const consolidatedData = campaigns
    ?.filter((campaign) => campaign?.status !== "ARCHIVED")
    ?.map((campaign) => {
      const filteredAdsets = adsets?.filter(
        (adset) => adset.campaign_id === campaign.id
      );
      const campaignStat = campaignStats.filter(
        (stat) => stat.campaign_id === campaign.platform_id
      );
      
      const todaySpendStat = todayCampaignStats?.find((stat) => stat.campaign_id === campaign.platform_id)
      const dailyBurnRate = todaySpendStat?.campaign_burn_rate

      if(campaignStat && campaignStat[0]) {
        campaignStat[0]["dbr"] = dailyBurnRate
      }


      return {
        ...campaign,
        campaignNameCopy: campaign.name,
        dailyBudgetCopy: campaign.daily_budget,
        biddingStategyCopy: campaign.bidding,

        adsets: filteredAdsets.map((adset) => {
          const adsetStat = adsetStats.find(
            (stat) => stat.adset_id === adset.platform_id
          );
          return {
            ...adset,
            adsetNameCopy: adset.name,
            adsetStats: [adsetStat],
          };
        }),
        campaignStat: campaignStat,     
        totalSpends: campaignStat[0]?.spends ?? 0,
        totalRoas: campaignStat[0]?.roas ?? 0,
        totalOrders: campaignStat[0]?.orders ?? 0,
        totalRevenue: campaignStat[0]?.sale_value ?? 0,
        totalImpressions: campaignStat[0]?.impressions ?? 0,
        totalClicks: campaignStat[0]?.clicks ?? 0,
        totalCpm: campaignStat[0]?.cpm ?? 0,
        totalCvr: campaignStat[0]?.cvr ?? 0,
        totalAov: campaignStat[0]?.aov ?? 0,
        totalCpc: campaignStat[0]?.cpc ?? 0,
        totalCtr: campaignStat[0]?.ctr ?? 0,
        totalAcos: campaignStat[0]?.acos ?? 0,
        campaign_categories:campaignStat[0]?.campaign_categories,
        daily_budget: campaign.daily_budget + " "  +currencySymbol,
        isArchived: campaign.status === "ARCHIVED",
        iseditBudgetValueDisabled: true,
        isEditCampaignNameDiabled: true,
        isCampaignDisabled: true,
        isBidStartegyOptionDisabled: true,
        iseditAdsetStatusisDisabled: true,
        isPlusIconDisabled:
          campName &&
          campName !== "" &&
          campName !== null &&
          campName !== undefined
            ? false
            : true,
      };
    });

  const totalSpends = consolidatedData?.reduce(
    (sum, campaign) => sum + campaign.totalSpends,
    0
  )
  const totalClicks = consolidatedData?.reduce(
    (sum, campaign) => sum + campaign?.totalClicks,
    0
  )
  const totalImpressions = consolidatedData?.reduce(
    (sum, campaign) => sum + campaign?.totalImpressions,
    0
  )
  const totalRevenue = consolidatedData?.reduce(
    (sum, campaign) => sum + campaign?.totalRevenue,
    0
  )
  const totalOrders = consolidatedData?.reduce(
    (sum, campaign) => sum + campaign?.totalOrders,
    0
  )
  const totalSummaryObject = {
    id: uuidv4(), // Generate a random id for this object.
    adsets: [],
    ad_platform_status: "",
    // daily_budget: 0 + " INR",
    isArchived: "",
    iseditBudgetValueDisabled: true,
    isEditCampaignNameDiabled: true,
    isCampaignDisabled: true,
    isBidStartegyOptionDisabled: true,
    iseditAdsetStatusisDisabled: true,
    isSummaryRow: true,
    campaignStat: [
      {
        spends: consolidatedData?.reduce(
          (sum, campaign) => sum + campaign.totalSpends,
          0
        ),
        orders: consolidatedData?.reduce(
          (sum, campaign) => sum + campaign.totalOrders,
          0
        ),
        roas:calculateROAS(totalRevenue,totalSpends),
        sale_value: consolidatedData?.reduce(
          (sum, campaign) => sum + campaign?.totalRevenue,
          0
        ),
        impressions: consolidatedData?.reduce(
          (sum, campaign) => sum + campaign?.totalImpressions,
          0
        ),
        clicks: consolidatedData?.reduce(
          (sum, campaign) => sum + campaign?.totalClicks,
          0
        ),
        cpm: calculateCPM(totalSpends,totalImpressions),
        aov: calculateAOV(totalRevenue,totalOrders),
        cvr: calculateCVR(totalOrders,totalClicks),
        cpc: calculateCPC(totalSpends,totalClicks),
        ctr: calculateCTR(totalClicks,totalImpressions),
        acos: calculateACOS(totalSpends,totalRevenue)
      },
    ],
  };

  return [totalSummaryObject, ...consolidatedData];
}
