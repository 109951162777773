import React from "react";
import { Tab, Nav } from "react-bootstrap";
import "./Spautomatic.css";
import RadioButtonRb from "../../components/Buttons/radioButtonRb/radioButtonRb";
import CampaignImage3 from "../../assets/images/Campaign3.png";
import CampaignImage4 from "../../assets/images/Campaign4.png";
import CampaignImage2 from "../../assets/images/Campaign2.png";
import CampaignImage1 from "../../assets/images/Campaign1.png";
import SearchbarForEditCampaign from "../../components/searchbarForEditCampaign";
import { useRef } from "react";
import FilterkeywordModal from "../../components/modal/campaignCreation/SpKeywordTargeting/FilterkeywordModal";
import { useState } from "react";
import CheckboxRb from "../../components/Buttons/checkbox/checkbox";
import TableRb from "../../components/Tables/tableRb";
import "../../components/modal/campaignCreation/SpKeywordTargeting/SpKeywordTargeting.css";
import {useDispatch,useSelector} from 'react-redux'
import searchIcon from '../../assets/icons/search-icon.png';
// images Import
import Rectangle1 from "../../assets/images/SpAutomaticImage/Rectangle1.png";
import Rectangle2 from "../../assets/images/SpAutomaticImage/Rectangle2.png";
import Rectangle3 from "../../assets/images/SpAutomaticImage/Rectangle3.png";
import Rectangle4 from "../../assets/images/SpAutomaticImage/Rectangle4.png";
import Rectangle5 from "../../assets/images/SpAutomaticImage/Rectangle5.png";
import Rectangle6 from "../../assets/images/SpAutomaticImage/Rectangle6.png";
import Rectangle7 from "../../assets/images/SpAutomaticImage/Rectangle7.png";
import Rectangle8 from "../../assets/images/SpAutomaticImage/Rectangle8.png";
import Rectangle9 from "../../assets/images/SpAutomaticImage/Rectangle9.png";
import ChoosePortfolio from "../../components/modal/CampaignCreationPages/ChoosePortfolio";
import e from "cors";
import { useGlobalContext } from "../../context/global";
import CreateCampaign from "../../components/modal/CampaignCreationPages/CreateCampaign";
import CreateAdGroup from "../../components/modal/CampaignCreationPages/CreateAdGroup";

let imagedata = [
  {
    image: Rectangle1,
    name: "Polo Tshirt",
  },
  {
    image: Rectangle2,
    name: "Shirts",
  },
  {
    image: Rectangle3,
    name: "Jeans",
  },
  {
    image: Rectangle4,
    name: "Jackets",
  },
  {
    image: Rectangle5,
    name: "Sweatshirts",
  },
  {
    image: Rectangle6,
    name: "Track Pants",
  },
  {
    image: Rectangle7,
    name: "Kurtas",
  },
  {
    image: Rectangle8,
    name: "Rain Jackets",
  },
  {
    image: Rectangle9,
    name: "Blazers and coats ",
  },
  {
    image: Rectangle9,
    name: "Blazers and coats ",
  },
  {
    image: Rectangle9,
    name: "Blazers and coats ",
  },
  {
    image: Rectangle9,
    name: "Blazers and coats ",
  },
  {
    image: Rectangle9,
    name: "Blazers and coats ",
  },
  {
    image: Rectangle9,
    name: "Blazers and coats ",
  },
  {
    image: Rectangle9,
    name: "Blazers and coats ",
  },
];

const SpAutomatic = ({
  setShow,
  show
}) => {
  const [page, setPage] = useState("");
  const dashboardSideBarVisibiltyState = useSelector((state) => state.dashboardData.dashboardSideBarVisibiltyState);
  const [dynamicHeaders, setDynamicHeaders] = useState([
    "Keywords",
    "Match Type",
    "Sugg Bidd",
  ]);
  const modalRef = useRef();
  const [isVisible, setIsVisible] = useState(false);
  let dummyData = [];
  const {campaignData,setCampaignData} = useGlobalContext();
 

  const handleChange = (e) => {
    setCampaignData({...campaignData,[e.target.name]:e.target.value})
  }

  const setTableHeaders = () => {
    if (page === "third") {
      setDynamicHeaders(["All Categories", "Suggested Bid |||"]);
    } else {
      setDynamicHeaders(["Keywords", "Match Type", , "Sugg Bidd"]);
    }
  };

  const radioChange = (e) => {
    const mainBtn = e.target.closest(".redioButton-box");
    const allBtn = mainBtn.querySelectorAll(".redioButton");
    allBtn.forEach((item) => {
      item.classList.remove("active");
    });
    const add = e.target.closest(".redioButton");
    add.classList.add("active");
    add.children[0].children[0].children[0].checked = true;
    setPage(e.target.closest(".redioButton").id);
  };

  return (
    <>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                     <div id="sp"> 
                       <div>
                        <span className="heading-font">
                          Sponsored Product Automatic Targeting 
                        </span>
                      </div>
                    <CreateCampaign show={show} setShow={setShow}/>
                    </div> 
                    <div className="sponsored-product-keyword-targeting" id="create-ad-group">
                      <CreateAdGroup automatic={true}/>
                      
                      </div>
                      {/* <div className="key-wrap">
                        <div
                          className="sponsored-product-keyword-targeting" id="sp-keyword">
                          <span className="common-head">Ad Details</span>
                          <div className="key-wrap smart-logic">
                            <div className="redioButton-box">
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="redioButton" id="first" onClick={radioChange}>
                                    <RadioButtonRb
                                      name="select-Campaign-addetails"
                                      label={
                                        <Leavle heading="Smart Product Selection" />
                                      }
                                    ></RadioButtonRb>
                                  </div>
                                </div>

                                <div className="col-lg-4">
                                  <div className="redioButton" id="second" onClick={radioChange}>  
                                    <RadioButtonRb
                                      name="select-Campaign-addetails"
                                      label={
                                        <Leavle heading="Manual Product Selection" />
                                      }
                                    ></RadioButtonRb>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="redioButton" id="third" onClick={radioChange}>
                                    <RadioButtonRb
                                      name="select-Campaign-addetails"
                                      label={
                                        <Leavle heading="Enter ASIN" />
                                      }
                                    ></RadioButtonRb>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {/* {First Page} */}
                            <div className={page==="first"?"d-block":"d-none"}>
                          <span className="common-head">Select smart Logics</span>
                        <div className="redioButton-box">
                          <div className="row g-">
                            <div className="col-lg-6">
                              <div className="redioButton" onClick={radioChange}>
                                <RadioButtonRb
                                  name="select-Campaign-Logics"
                                  label={
                                    <Leavle
                                      heading="Top selling from Chosen Category"
                                      para="Sponsored Products are cost-per-click ads that promote..."
                                    />
                                  }
                                ></RadioButtonRb>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="redioButton" onClick={radioChange}>
                                <RadioButtonRb
                                  name="select-Campaign-Logics"
                                  label={
                                    <Leavle
                                      heading="Instock products from Chosen Category"
                                      para="Sponsored Products are cost-per-click ads that promote..."
                                    />
                                  }
                                ></RadioButtonRb>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="redioButton" onClick={radioChange}>
                                <RadioButtonRb
                                  name="select-Campaign-Logics"
                                  label={
                                    <Leavle
                                      heading="Discounted products from Chosen Category"
                                      para="Sponsored Products are cost-per-click ads that promote..."
                                    />
                                  }
                                ></RadioButtonRb>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="redioButton" onClick={radioChange}>
                                <RadioButtonRb
                                  name="select-Campaign-Logics"
                                  label={
                                    <Leavle
                                      heading="New Arrivals from Chosen Category"
                                      para="Sponsored Products are cost-per-click ads that promote..."
                                    />
                                  }
                                ></RadioButtonRb>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                                  {/* // Second Page */}
                          <div className={page==="second"?"d-block":"d-none"}>
                          <span className="common-head my-3" id="select-products">
                            Select Products
                            
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <mask
                                id="mask0_1178_14743"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="21"
                                height="20"
                              >
                                <rect
                                  x="0.585693"
                                  y="0.0175781"
                                  width="20"
                                  height="19.9643"
                                  fill="#D9D9D9"
                                />
                              </mask>
                              <g mask="url(#mask0_1178_14743)">
                                <path
                                  d="M10.5856 17.9022C9.48839 17.9022 8.45728 17.6943 7.49228 17.2783C6.52672 16.8624 5.68978 16.3009 4.98145 15.5939C4.27311 14.8868 3.71061 14.0513 3.29395 13.0875C2.87728 12.1242 2.66895 11.095 2.66895 9.9997C2.66895 8.90443 2.87728 7.87489 3.29395 6.91106C3.71061 5.94778 4.27311 5.11261 4.98145 4.40554C5.68978 3.69847 6.52672 3.13697 7.49228 2.72105C8.45728 2.30513 9.48839 2.09717 10.5856 2.09717C11.6828 2.09717 12.7142 2.30513 13.6798 2.72105C14.6448 3.13697 15.4814 3.69847 16.1898 4.40554C16.8981 5.11261 17.4606 5.94778 17.8773 6.91106C18.2939 7.87489 18.5023 8.90443 18.5023 9.9997C18.5023 11.095 18.2939 12.1242 17.8773 13.0875C17.4606 14.0513 16.8981 14.8868 16.1898 15.5939C15.4814 16.3009 14.6448 16.8624 13.6798 17.2783C12.7142 17.6943 11.6828 17.9022 10.5856 17.9022ZM10.5856 16.6545C12.4328 16.6545 14.0059 16.0065 15.3048 14.7104C16.6031 13.4139 17.2523 11.8436 17.2523 9.9997C17.2523 8.15577 16.6031 6.58553 15.3048 5.28896C14.0059 3.99294 12.4328 3.34494 10.5856 3.34494C8.73839 3.34494 7.16561 3.99294 5.86728 5.28896C4.56839 6.58553 3.91895 8.15577 3.91895 9.9997C3.91895 11.8436 4.56839 13.4139 5.86728 14.7104C7.16561 16.0065 8.73839 16.6545 10.5856 16.6545Z"
                                  fill="#4D4184"
                                />
                                <path
                                  d="M10.559 13.9509C10.7396 13.9509 10.889 13.8921 11.0073 13.7745C11.1251 13.6564 11.184 13.5072 11.184 13.327V9.77083C11.184 9.60446 11.1251 9.46221 11.0073 9.34409C10.889 9.22652 10.7396 9.16774 10.559 9.16774C10.3784 9.16774 10.2293 9.22652 10.1115 9.34409C9.99317 9.46221 9.934 9.61139 9.934 9.79162V13.3478C9.934 13.5141 9.99317 13.6564 10.1115 13.7745C10.2293 13.8921 10.3784 13.9509 10.559 13.9509ZM10.559 7.7536C10.7534 7.7536 10.9132 7.68761 11.0382 7.55562C11.1632 7.42419 11.2257 7.26143 11.2257 7.06733C11.2257 6.8871 11.1632 6.73099 11.0382 6.599C10.9132 6.46757 10.7534 6.40186 10.559 6.40186C10.3646 6.40186 10.2048 6.46757 10.0798 6.599C9.95483 6.73099 9.89233 6.8871 9.89233 7.06733C9.89233 7.26143 9.95483 7.42419 10.0798 7.55562C10.2048 7.68761 10.3646 7.7536 10.559 7.7536Z"
                                  fill="#4D4184"
                                />
                              </g>
                            </svg>
                          </span>


                          <div id="displayproduct" >
                            <div className="displayproduct-div-1">
                              <span className="displayproduct-div-1-span">
                                Product Catalog
                              </span>

                              <div id="serach-dispaly-product">
                              <div>
                              <div className="search-bar-container2">
                                  <span className=" search-icon-container">
                                      <img src={searchIcon}></img>
                                      {/* <Search color="#4D4184" className="search-icon" /> */}
                                  </span>
                                  <span className="seacrh-input-container">
                                      <input type="text" className="seacrh-input-field " placeholder="Search Products" />
                                  </span>
                              </div>
                          </div>
                              </div>
                              {/* input select all*/}

                              <div className="SelectAll-input">
                                <input type="checkbox" className="aba"  />
                             
                                <label htmlFor="">Select All</label>
                              </div>

                              <div className={!dashboardSideBarVisibiltyState?"dispaly-product-images":"dispaly-product-images-open"}>
                                {imagedata.map((item, index) => (
                                  <div className="dispaly-product-images-card">
                                     
                                    <div>
                                    
                                      <img src={item.image} alt="" />
                                      <input type="checkbox" className={!dashboardSideBarVisibiltyState?"auomatic-inputs":"automatic-inputs-open"} />
                                     
                                    </div>

                                    <span className="display-auto-span">{item.name}</span>
                                  </div>
                                ))}
                              </div>
                            </div>

                            {/* second div of image select */}
                            <div className="displayproduct-div-1">
                              <span className="displayproduct-div-1-span">
                                Selected Products
                              </span>

                              <p>Select min 3 products</p>

                              {/* input select all*/}

                              <div className="SelectAll-input">
                                <input type="checkbox" />
                                <label htmlFor="">RemoveAll</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div className={page==="third"?"d-block":"d-none"}>
                     <span className="common-head my-3">
                            Enter ASIN's
                            
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <mask
                                id="mask0_1178_14743"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="21"
                                height="20"
                              >
                                <rect
                                  x="0.585693"
                                  y="0.0175781"
                                  width="20"
                                  height="19.9643"
                                  fill="#D9D9D9"
                                />
                              </mask>
                              <g mask="url(#mask0_1178_14743)">
                                <path
                                  d="M10.5856 17.9022C9.48839 17.9022 8.45728 17.6943 7.49228 17.2783C6.52672 16.8624 5.68978 16.3009 4.98145 15.5939C4.27311 14.8868 3.71061 14.0513 3.29395 13.0875C2.87728 12.1242 2.66895 11.095 2.66895 9.9997C2.66895 8.90443 2.87728 7.87489 3.29395 6.91106C3.71061 5.94778 4.27311 5.11261 4.98145 4.40554C5.68978 3.69847 6.52672 3.13697 7.49228 2.72105C8.45728 2.30513 9.48839 2.09717 10.5856 2.09717C11.6828 2.09717 12.7142 2.30513 13.6798 2.72105C14.6448 3.13697 15.4814 3.69847 16.1898 4.40554C16.8981 5.11261 17.4606 5.94778 17.8773 6.91106C18.2939 7.87489 18.5023 8.90443 18.5023 9.9997C18.5023 11.095 18.2939 12.1242 17.8773 13.0875C17.4606 14.0513 16.8981 14.8868 16.1898 15.5939C15.4814 16.3009 14.6448 16.8624 13.6798 17.2783C12.7142 17.6943 11.6828 17.9022 10.5856 17.9022ZM10.5856 16.6545C12.4328 16.6545 14.0059 16.0065 15.3048 14.7104C16.6031 13.4139 17.2523 11.8436 17.2523 9.9997C17.2523 8.15577 16.6031 6.58553 15.3048 5.28896C14.0059 3.99294 12.4328 3.34494 10.5856 3.34494C8.73839 3.34494 7.16561 3.99294 5.86728 5.28896C4.56839 6.58553 3.91895 8.15577 3.91895 9.9997C3.91895 11.8436 4.56839 13.4139 5.86728 14.7104C7.16561 16.0065 8.73839 16.6545 10.5856 16.6545Z"
                                  fill="#4D4184"
                                />
                                <path
                                  d="M10.559 13.9509C10.7396 13.9509 10.889 13.8921 11.0073 13.7745C11.1251 13.6564 11.184 13.5072 11.184 13.327V9.77083C11.184 9.60446 11.1251 9.46221 11.0073 9.34409C10.889 9.22652 10.7396 9.16774 10.559 9.16774C10.3784 9.16774 10.2293 9.22652 10.1115 9.34409C9.99317 9.46221 9.934 9.61139 9.934 9.79162V13.3478C9.934 13.5141 9.99317 13.6564 10.1115 13.7745C10.2293 13.8921 10.3784 13.9509 10.559 13.9509ZM10.559 7.7536C10.7534 7.7536 10.9132 7.68761 11.0382 7.55562C11.1632 7.42419 11.2257 7.26143 11.2257 7.06733C11.2257 6.8871 11.1632 6.73099 11.0382 6.599C10.9132 6.46757 10.7534 6.40186 10.559 6.40186C10.3646 6.40186 10.2048 6.46757 10.0798 6.599C9.95483 6.73099 9.89233 6.8871 9.89233 7.06733C9.89233 7.26143 9.95483 7.42419 10.0798 7.55562C10.2048 7.68761 10.3646 7.7536 10.559 7.7536Z"
                                  fill="#4D4184"
                                />
                              </g>
                            </svg>
                          </span>
                          <div className="row">
                            <div className="col-lg-6 asin-div">
                            <textarea
                              rows="18"
                              cols="25"
                              className="w-100 mt-2 textarea-add-targeting p-2"
                              placeholder="Enter your list and separate each item with a comma ,"
                            ></textarea>
                            </div>
                            <div className="col-lg-6 asin-div">
                            <textarea
                              rows="18"
                              cols="25"
                              className="w-100 mt-2 textarea-add-targeting p-2"
                              placeholder="Enter your list and separate each item with a comma ,"
                            ></textarea>
                            </div>
                          </div>
                     </div>
                      {/* </div> */}
                      {/* // </div> */}
                     
                    
              </Tab.Container>
              </>
    
  );
};

const Leavle = ({ heading, para }) => {
  return (
    <>
      <span>{heading}</span>
      <br />
      {para && <span>{para}</span>}
    </>
  );
};

export default SpAutomatic;


  // <div>
    //   <div className="main-container" >
       
    //     <div className="campaign-type-box">
    //       <div className="type-wrapper">
    //         <div className="tab-wrap">
//         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

{/* <Nav variant="pills" className="flex-row-cart-page">
<Nav.Item>
  <Nav.Link eventKey="first" onClick={() => setPage("first")}>
    <div className="choose-box-wrap">
      <div className="img-box">
        <img
          src={CampaignImage3}
          alt=""
          className="img-box"
        />
      </div>
      <div className="type-box-content">
        <div className="type-heading">
          <span>
            Sponsored Product-Automatic <br /> Targeting
          </span>
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.7">
                <mask
                  id="mask0_2665_39799"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect
                    width="24"
                    height="24"
                    fill="#D9D9D9"
                  />
                </mask>
                <g mask="url(#mask0_2665_39799)">
                  <path
                    d="M12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z"
                    fill="#4D4184"
                  />
                  <path
                    d="M11.968 16.7498C12.1846 16.7498 12.364 16.6791 12.506 16.5378C12.6473 16.3958 12.718 16.2165 12.718 15.9998V11.7248C12.718 11.5248 12.6473 11.3538 12.506 11.2118C12.364 11.0705 12.1846 10.9998 11.968 10.9998C11.7513 10.9998 11.5723 11.0705 11.431 11.2118C11.289 11.3538 11.218 11.5331 11.218 11.7498V16.0248C11.218 16.2248 11.289 16.3958 11.431 16.5378C11.5723 16.6791 11.7513 16.7498 11.968 16.7498ZM11.968 9.2998C12.2013 9.2998 12.393 9.22047 12.543 9.0618C12.693 8.9038 12.768 8.70814 12.768 8.4748C12.768 8.25814 12.693 8.07047 12.543 7.9118C12.393 7.7538 12.2013 7.6748 11.968 7.6748C11.7346 7.6748 11.543 7.7538 11.393 7.9118C11.243 8.07047 11.168 8.25814 11.168 8.4748C11.168 8.70814 11.243 8.9038 11.393 9.0618C11.543 9.22047 11.7346 9.2998 11.968 9.2998Z"
                    fill="#4D4184"
                  />
                </g>
              </g>
            </svg>
          </span>
        </div>
        <div>
          <p>
            Sponsored Products are cost-per-click ads that
            promote...
          </p>
        </div>
      </div>
    </div>
  </Nav.Link>
  {/* <button >Tab 1</button> */}
// </Nav.Item>

// <Nav.Item>
//   <Nav.Link
//     eventKey="second"
//     onClick={() => setPage("second")}
//   >
//     <div className="choose-box-wrap">
//       <div className="img-box">
//         <img
//           src={CampaignImage1}
//           className="img-box"
//           alt=""
//         />
//       </div>
//       <div className="type-box-content">
//         <div className="type-heading">
//           <span>
//             Sponsored Product-Keyword <br /> Targeting
//           </span>
//           <span>
//             <svg
//               width="24"
//               height="24"
//               viewBox="0 0 24 24"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <g opacity="0.7">
//                 <mask
//                   id="mask0_2665_39799"
//                   maskUnits="userSpaceOnUse"
//                   x="0"
//                   y="0"
//                   width="24"
//                   height="24"
//                 >
//                   <rect
//                     width="24"
//                     height="24"
//                     fill="#D9D9D9"
//                   />
//                 </mask>
//                 <g mask="url(#mask0_2665_39799)">
//                   <path
//                     d="M12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z"
//                     fill="#4D4184"
//                   />
//                   <path
//                     d="M11.968 16.7498C12.1846 16.7498 12.364 16.6791 12.506 16.5378C12.6473 16.3958 12.718 16.2165 12.718 15.9998V11.7248C12.718 11.5248 12.6473 11.3538 12.506 11.2118C12.364 11.0705 12.1846 10.9998 11.968 10.9998C11.7513 10.9998 11.5723 11.0705 11.431 11.2118C11.289 11.3538 11.218 11.5331 11.218 11.7498V16.0248C11.218 16.2248 11.289 16.3958 11.431 16.5378C11.5723 16.6791 11.7513 16.7498 11.968 16.7498ZM11.968 9.2998C12.2013 9.2998 12.393 9.22047 12.543 9.0618C12.693 8.9038 12.768 8.70814 12.768 8.4748C12.768 8.25814 12.693 8.07047 12.543 7.9118C12.393 7.7538 12.2013 7.6748 11.968 7.6748C11.7346 7.6748 11.543 7.7538 11.393 7.9118C11.243 8.07047 11.168 8.25814 11.168 8.4748C11.168 8.70814 11.243 8.9038 11.393 9.0618C11.543 9.22047 11.7346 9.2998 11.968 9.2998Z"
//                     fill="#4D4184"
//                   />
//                 </g>
//               </g>
//             </svg>
//           </span>
//         </div>
//         <div>
//           <p>
//             Sponsored Products are cost-per-click ads that
//             promote...
//           </p>
//         </div>
//       </div>
//     </div>
//   </Nav.Link>
// </Nav.Item>

{/* <Nav.Item>
  <Nav.Link eventKey="third" onClick={() => setPage("third")}>
    <div className="choose-box-wrap">
      <div className="img-box">
        <img
          src={CampaignImage2}
          alt=""
          className="img-box"
        />
      </div>
      <div className="type-box-content">
        <div className="type-heading">
          <span>
            Sponsored Product-Product <br /> Targeting
          </span>
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.7">
                <mask
                  id="mask0_2665_39799"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect
                    width="24"
                    height="24"
                    fill="#D9D9D9"
                  />
                </mask>
                <g mask="url(#mask0_2665_39799)">
                  <path
                    d="M12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z"
                    fill="#4D4184"
                  />
                  <path
                    d="M11.968 16.7498C12.1846 16.7498 12.364 16.6791 12.506 16.5378C12.6473 16.3958 12.718 16.2165 12.718 15.9998V11.7248C12.718 11.5248 12.6473 11.3538 12.506 11.2118C12.364 11.0705 12.1846 10.9998 11.968 10.9998C11.7513 10.9998 11.5723 11.0705 11.431 11.2118C11.289 11.3538 11.218 11.5331 11.218 11.7498V16.0248C11.218 16.2248 11.289 16.3958 11.431 16.5378C11.5723 16.6791 11.7513 16.7498 11.968 16.7498ZM11.968 9.2998C12.2013 9.2998 12.393 9.22047 12.543 9.0618C12.693 8.9038 12.768 8.70814 12.768 8.4748C12.768 8.25814 12.693 8.07047 12.543 7.9118C12.393 7.7538 12.2013 7.6748 11.968 7.6748C11.7346 7.6748 11.543 7.7538 11.393 7.9118C11.243 8.07047 11.168 8.25814 11.168 8.4748C11.168 8.70814 11.243 8.9038 11.393 9.0618C11.543 9.22047 11.7346 9.2998 11.968 9.2998Z"
                    fill="#4D4184"
                  />
                </g>
              </g>
            </svg>
          </span>
        </div>
        <div>
          <p>
            Sponsored Products are cost-per-click ads that
            promote...
          </p>
        </div>
      </div>
    </div>
  </Nav.Link>
  {/* <button >Tab 1</button> */}
// </Nav.Item> 

{/* <Nav.Item>
  <Nav.Link
    eventKey="fourth"
    onClick={() => setPage("fourth")}
  >
    <div className="choose-box-wrap">
      <div className="img-box">
        <img
          src={CampaignImage4}
          alt=""
          className="img-box"
        />
      </div>
      <div className="type-box-content">
        <div className="type-heading">
          <span>
            Sponsored Display-Product <br /> Targeting
          </span>
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.7">
                <mask
                  id="mask0_2665_39799"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect
                    width="24"
                    height="24"
                    fill="#D9D9D9"
                  />
                </mask>
                <g mask="url(#mask0_2665_39799)">
                  <path
                    d="M12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z"
                    fill="#4D4184"
                  />
                  <path
                    d="M11.968 16.7498C12.1846 16.7498 12.364 16.6791 12.506 16.5378C12.6473 16.3958 12.718 16.2165 12.718 15.9998V11.7248C12.718 11.5248 12.6473 11.3538 12.506 11.2118C12.364 11.0705 12.1846 10.9998 11.968 10.9998C11.7513 10.9998 11.5723 11.0705 11.431 11.2118C11.289 11.3538 11.218 11.5331 11.218 11.7498V16.0248C11.218 16.2248 11.289 16.3958 11.431 16.5378C11.5723 16.6791 11.7513 16.7498 11.968 16.7498ZM11.968 9.2998C12.2013 9.2998 12.393 9.22047 12.543 9.0618C12.693 8.9038 12.768 8.70814 12.768 8.4748C12.768 8.25814 12.693 8.07047 12.543 7.9118C12.393 7.7538 12.2013 7.6748 11.968 7.6748C11.7346 7.6748 11.543 7.7538 11.393 7.9118C11.243 8.07047 11.168 8.25814 11.168 8.4748C11.168 8.70814 11.243 8.9038 11.393 9.0618C11.543 9.22047 11.7346 9.2998 11.968 9.2998Z"
                    fill="#4D4184"
                  />
                </g>
              </g>
            </svg>
          </span>
        </div>
        <div>
          <p>
            Sponsored Products are cost-per-click ads that
            promote...
          </p>
        </div>
      </div>
    </div>
  </Nav.Link>
  {/* <button >Tab 1</button> */}
// </Nav.Item>
// </Nav> */} */}