import React, { useEffect, useState, useRef } from "react";
import CheckboxRb from "../../Buttons/checkbox/checkbox";
import TableRb from "../../Tables/tableRb";
import { Spinner, Form } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { useGlobalContext } from "../../../context/global";
import { campaignListActions } from "../../../redux/slices/campaignList";
import {useDispatch,useSelector} from 'react-redux';
import { useAuthHeader } from "react-auth-kit";
import csvIcon from '../../../assets/icons/csv-upload.svg'
import Papa from "papaparse";
import { TOAST_DANGER, TOAST_POSITION_TOP_RIGHT } from "../../../constant/HardTypes";
import removeDuplicateObjectsFromArray from "../../../helper/validators/removeDuplicateObjectsFromArray";
import MasterTagetinngActionAlertMModal from "../masterTargetinngActionnAlertMModal/masterTargetingActionnAlertModal";
import EditBidModalForTargeting from '../editBidModalForTargeting'
import modifyValueByPercentage from "../../../helper/common/modifyValueByPercentage";
import { getCurrencySymbol } from "../../../constant/defaultCurrencyConfigurations";
import {COMMA_NEWLINE_SPLITTER} from '../../../constant/regex-utils';
import {toast} from 'react-toastify';

const AddSpKeyword = (props) => {
  const [page, setPage] = useState("");
  const [isText, setIsText] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const authHeader = useAuthHeader();
  const token = authHeader();
  const [newArr, setNewArr] = useState(new Array());
  const [dynamicHeaders, setDynamicHeaders] = useState([
    "Keywords",
    "Match Type",
    "Sugg Bidd",
    "Edit Bid",
  ]);
  const [keywords, setKeywords] = useState([]);
  const [brandKeywordsLocal, setBrandKeywordsLocal] = useState([]);
  const [actionAlertModalVisibility,setActionAlertModalVisibility] = useState(false)
  const [successContainer, setSuccessContainer] = useState({
    success: false,
    msg: "",
  });
  const { success, msg } = successContainer;
  const {
    adGroupData,
    setAdGroupData,
    keywordsTobeAdded,
    brandKeywordsToBeAdded,
    setBrandKeywordsToBeAdded,
    manualMatchTypes,
    setManualMatchTypes,
    manualBrandMatchTypes,
    setmanualBrandMatchTypes,
    selectedCategory,
    editManualMatchTypes,
    setEditManualMatchTypes,
    editWholeKeywords,
    setEditWholeKeywords,
    editKeywordsToBeAdded,
    setKeywordsTobeAdded,
    setEditKeywordsToBeAdded,
    editKeywordsLocal,

    setEditKeywordsLocal,
    setButtonStatus,
    buttonStatus,
    showAlert,
    createCampaignErrors,
    dispatchErrors
  } = useGlobalContext();

  const {amz_default_bid} = adGroupData
  const clientId = JSON.parse(localStorage.getItem("clientId"));
  const default_bid = JSON.parse(localStorage.getItem("defaultBid"));
  const dispatch = useDispatch();
  const actiontoEditCollectivebid = useSelector(
    (state) => state.campaignListData.actionEditCollectiveBidForMasterTargeting
  );
  const bulkEditBidModalVisibilty = useSelector(
    (state) => state.campaignListData.bulkEditBidModalVisibilty
  );
  const alertText = "Are you sure you want to align suggested bid with added keywords?"
  const [editBidModal,setEditBidModal] = useState(false)
  const currencySymbol = getCurrencySymbol();
  

  useEffect(() => {
    setTableHeaders();
  }, [page]);

  useEffect(() => {
    setManualMatchTypes(["Exact"])
    setmanualBrandMatchTypes(["Exact"])
    setEditManualMatchTypes(["Exact"])
  },[])

  // Methods
  

  const deleteAll = () => {
    if (props.Brand) {
      const brandKeywordsIds = brandKeywordsLocal.map((keyword) => {
        return { match: keyword.match.toLowerCase(), text: keyword.text };
      });

      setAdGroupData((current) => {
        const copy = { ...current };
        let updatedArray = copy["amz_keyword_targeting"].filter((keyword) => {
          const keyValue = `${keyword.match}-${keyword.value}`;
          for (let i = 0; i < brandKeywordsIds.length; i++) {
            const matchValue = `${brandKeywordsIds[i].match}-${brandKeywordsIds[i].value}`;
            if (keyValue === matchValue) {
              return false;
            }
          }
          return true;
        });
        copy["amz_keyword_targeting"] = updatedArray;
        return copy;
      });
      setBrandKeywordsToBeAdded([]);
    } else if (props.editkeywords) {
      // const keywordsIds = editKeywordsLocal.map((keyword) => {
      //   return { match: keyword.match, text: keyword.text };
      // });
      // setEditWholeKeywords((current) => {
      //   return current.filter((keyword) => {
      //     const keyValue = `${keyword.match}-${keyword.value}`;
      //     return !keywordsIds.includes(keyValue);
      //   });
      // });
      setEditKeywordsToBeAdded([]);
    } else {
      const keywordsIds = keywords.map((keyword) => {
        return { match: keyword.match, text: keyword.text };
      });
      setAdGroupData((current) => {
        const copy = { ...current };
        let updatedArray = copy["amz_keyword_targeting"].filter((keyword) => {
          const keyValue = `${keyword.match}-${keyword.value}`;
          for (let i = 0; i < keywordsIds.length; i++) {
            const matchValue = `${keywordsIds[i].match}-${keywordsIds[i].value}`;
            if (keyValue === matchValue) {
              return false;
            }
          }
          return true;
        });
        copy["amz_keyword_targeting"] = updatedArray;
        return copy;
      });
      setKeywordsTobeAdded([]);
    }
  };


  const bidChange = (input, word) => {
    if (props.editkeywords) {
      setEditKeywordsToBeAdded((prev) => {
        let data = [...prev].map((keyword) => {
          if (
            keyword.text.toLowerCase() === word.text.toLowerCase() &&
            keyword.match.toLowerCase() === word.match.toLowerCase()
          ) {
            keyword.userBid = parseFloat(input.target.value);
          }
          return keyword;
        });
        return data;
      });
      // setEditWholeKeywords((prev) => {
      //   let data = [...prev].map((keyword) => {
      //     if (
      //       keyword.text.toLowerCase() === word.text.toLowerCase() &&
      //       keyword.match.toLowerCase() === word.match.toLowerCase()
      //     ) {
      //       keyword.userBid = parseFloat(input.target.value);
      //     }
      //     return keyword;
      //   });
      //   return data;
      // });
    } else if (props.brand) {
      setBrandKeywordsToBeAdded((prev) => {
        let data = [...prev].map((keyword) => {
          if (
            keyword.text.toLowerCase() === word.text.toLowerCase() &&
            keyword.match.toLowerCase() === word.match.toLowerCase()
          ) {
            keyword.userBid = parseFloat(input.target.value);
          }
          return keyword;
        });
        return data;
      });
      setAdGroupData((prev) => {
        let data = { ...prev };
        let updatedData = data.amz_keyword_targeting.map((keyword) => {
          if (
            keyword.text.toLowerCase() === word.text.toLowerCase() &&
            keyword.match.toLowerCase() === word.match.toLowerCase()
          ) {
            keyword.userBid = parseFloat(input.target.value);
          }
          return keyword;
        });
        data.amz_keyword_targeting = updatedData;
        return data;
      });
    } else {
      setKeywordsTobeAdded((prev) => {
        let data = [...prev].map((keyword) => {
          if (
            keyword.text.toLowerCase() === word.text.toLowerCase() &&
            keyword.match.toLowerCase() === word.match.toLowerCase()
          ) {
            keyword.userBid = parseFloat(input.target.value);
          }
          return keyword;
        });
        return data;
      });
      setAdGroupData((prev) => {
        let data = { ...prev };
        let updatedData = data.amz_keyword_targeting.map((keyword) => {
          if (
            keyword.text.toLowerCase() === word.text.toLowerCase() &&
            keyword.match.toLowerCase() === word.match.toLowerCase()
          ) {
            keyword.userBid = parseFloat(input.target.value);
          }
          return keyword;
        });
        data.amz_keyword_targeting = updatedData;
        return data;
      });
    }
  };

  const alignWithSuggestedBidhandler = () => {
    dispatch(campaignListActions.showActionAlertModal())
    setActionAlertModalVisibility(true)
  }

  const editBidHandler = () => {
    dispatch(campaignListActions.showBulkEditBidModal())
    setEditBidModal(true)
  }



  let tableBody = (
    <>
      <div className={`${createCampaignErrors?.targeting ? "border border-danger":""} generic-main-table-body-container keyword-targeting-modal-asin`}>
        <table className="sp-product-targetting-table">
          <thead className="main-table-head">
            <tr className="table-heading-style">
              <td colspan={4} className="table-upper">
                <div className="d-flex justify-content-between align-items-center">
                <small className="text-dark text-small p-3 text-nowrap">
                  {props.Brand
                    ? brandKeywordsToBeAdded && brandKeywordsToBeAdded.length
                    : props.editkeywords
                    ? editKeywordsToBeAdded && editKeywordsToBeAdded.length
                    : keywordsTobeAdded && keywordsTobeAdded.length} Added
                </small>
                {
                  (brandKeywordsToBeAdded?.length || editKeywordsToBeAdded?.length || keywordsTobeAdded.length) ?  (<div className="d-flex table-tab">
                  <button
                  className="alignn-with-sug-bid-box"
                  onClick={alignWithSuggestedBidhandler}
                >
                  Align with Suggested Bid
                  </button>
                  <button
                  className="bid-manipulate-box"
                  onClick={editBidHandler}
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                >
                  Adjust Bid
                   </button>
                </div>)
                :""
                }
                <div className="">
                  <button
                    type="button"
                    onClick={deleteAll}
                    className={
                      props.Brand
                        ? brandKeywordsToBeAdded && brandKeywordsToBeAdded.length !== 0
                          ? "remove-all-btn-dark text-nowrap"
                          : "remove-all-btn text-nowrap"
                        : props.editkeywords
                        ? editKeywordsToBeAdded && editKeywordsToBeAdded.length !== 0
                          ? "remove-all-btn-dark text-nowrap"
                          : "remove-all-btn text-nowrap"
                        : keywordsTobeAdded && keywordsTobeAdded.length !== 0
                        ? "remove-all-btn-dark text-nowrap"
                        : "remove-all-btn text-nowrap"
                    }
                  >
                    {buttonStatus.status &&
                    buttonStatus.text === "Adding-keywords" ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      ""
                    )}
                    {buttonStatus.status &&
                    buttonStatus.text === "Adding-keywords"
                      ? "Adding..."
                      : "Remove All"}
                  </button>
                </div>
                </div>
               
              </td>
            </tr>
            <tr>
              {dynamicHeaders.map((th, index) => {
                if (index === dynamicHeaders.length - 1) {
                  return (
                    <th
                      className="border border-right-white"
                      onClick={() => {
                        setIsVisible(true);
                      }}
                    >
                      {th}
                    </th>
                  );
                }
                return <th className="border border-right-white">{th}</th>;
              })}
            </tr>
          </thead>
          <tbody className="table-body main-table-body">
            {props.Brand
              ? brandKeywordsToBeAdded &&
                brandKeywordsToBeAdded?.map((word, index) => {
                  return (
                    <tr key={word.id} className="row-height">
                      <td className="tabledata-td">{word.text}</td>
                      <td className="tabledata-td">{word.match}</td>
                      <td className="tabledata-td">
                        <div className="d-flex flex-column w-max align-items-center">
                          <div>{currencySymbol}{word.bid}</div>
                          <div className="text-muted">
                            <div className="fs-10">
                              <small className="">
                                ({currencySymbol}{word.lowest} - {currencySymbol}{word.heighest})
                              </small>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="tabledata-td text-center">
                        <div
                          className="d-flex gap-1 justify-content-between align-items-center"
                          style={{ width: "75px" }}
                        >
                          <div className="w-100">
                            <Form.Control
                              size="sm"
                              className="w-100"
                              type="number"
                              step="0.1"
                              value={word.userBid}
                              onChange={(e) => bidChange(e, word)}
                              placeholder="Enter your bid"
                            />
                          </div>
                          <div>
                            <span
                              className="text-left cursor-pointer-global d-block selected-icon-hover"
                              onClick={() => onDelete(word)}
                            >
                              <Trash
                                className="text-danger remove-item-btn"
                                height="12px"
                                width="12px"
                              />
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : props.editkeywords
              ? editKeywordsToBeAdded &&
              editKeywordsToBeAdded?.map((word, index) => {
                  return (
                    <tr key={word.id} className="row-height">
                      <td className="tabledata-td">{word.text}</td>
                      <td className="tabledata-td">{word.match}</td>
                      <td className="tabledata-td">
                        <div className="d-flex flex-column w-max align-items-center">
                          <div>{currencySymbol}{word?.bid}</div>
                          <div className="text-muted">
                            <div className="fs-10">
                              <small className="">
                                ({currencySymbol}{word?.lowest} - {currencySymbol}{word?.heighest})
                              </small>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="tabledata-td text-center">
                        <div
                          className="d-flex gap-1 justify-content-between align-items-center"
                          style={{ width: "75px" }}
                        >
                          <div className="w-100">
                            <Form.Control
                              size="sm"
                              className="w-100"
                              type="number"
                              step="0.1"
                              value={word.userBid}
                              onChange={(e) => bidChange(e, word)}
                              placeholder="Enter your bid"
                            />
                          </div>
                          <div>
                            <span
                              className="text-left cursor-pointer-global d-block selected-icon-hover"
                              onClick={() => onDelete(word)}
                            >
                              <Trash
                                className="text-danger remove-item-btn"
                                height="12px"
                                width="12px"
                              />
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : keywordsTobeAdded &&
                keywordsTobeAdded.map((word, index) => {
                  return (
                    <tr key={word.id} className="row-height">
                      <td className="tabledata-td">{word.text}</td>
                      <td className="tabledata-td">{word.match}</td>
                      <td className="tabledata-td">
                        <div className="d-flex flex-column w-max align-items-center">
                          <div>{currencySymbol}{word?.bid}</div>
                          <div className="text-muted">
                            <div className="fs-10">
                              <small className="">
                                ({currencySymbol}{word?.lowest} - {currencySymbol}{word?.heighest})
                              </small>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="tabledata-td text-center">
                        <div
                          className="d-flex gap-1 justify-content-between align-items-center"
                          style={{ width: "75px" }}
                        >
                          <div className="w-100">
                            <Form.Control
                              size="sm"
                              className="w-100"
                              type="number"
                              step="0.1"
                              value={word.userBid}
                              onChange={(e) => bidChange(e, word)}
                              placeholder="Enter your bid"
                            />
                          </div>
                          <div>
                            <span
                              className="text-left cursor-pointer-global d-block selected-icon-hover"
                              onClick={() => onDelete(word)}
                            >
                              <Trash
                                className="text-danger remove-item-btn"
                                height="12px"
                                width="12px"
                              />
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    </>
  );



  const getData = (data) => {
    const { setAsValue, selectedAction, valuePercentage } = data;
    const currentStateUpdater = props.Brand
      ? setBrandKeywordsToBeAdded
      : props.editkeywords
      ? setEditKeywordsToBeAdded
      : setKeywordsTobeAdded;
    const currentState = props.Brand
      ? brandKeywordsToBeAdded
      : props.editkeywords
      ? editKeywordsToBeAdded
      : keywordsTobeAdded;
  
    currentStateUpdater((prev) => {
      const copy = [...prev].map((keyword) => {
        keyword.userBid =
          selectedAction === "set_bid_as"
            ? setAsValue
            : modifyValueByPercentage(
                selectedAction,
                valuePercentage,
                Number(keyword.userBid)
              );
        return keyword;
      });
      return copy;
    });
  
    setAdGroupData((prev) => {
      return {
        ...prev,
        amz_keyword_targeting: prev.amz_keyword_targeting?.map((keyword) => {
          const addedKeyword = currentState?.find(
            (addedKeyword) => (keyword.text === addedKeyword.text && keyword.match?.toLowerCase() === addedKeyword.match?.toLowerCase())
          );
          if (addedKeyword) {
            const updatedUserBid = addedKeyword?.userBid
            return {
              ...keyword,
              userBid: updatedUserBid,
            };
          }
          return keyword;
        }),
      };
    });
  };
  

  const setTableHeaders = () => {
    if (page === "third") {
      setDynamicHeaders(["All Categories", "Suggested Bid |||"]);
    } else {
      setDynamicHeaders(["Keywords", "Match Type", "Sugg Bidd", "Edit Bid"]);
    }
  };


  const checkBoxesStates = props?.Brand ? manualBrandMatchTypes : props?.editkeywords ? editManualMatchTypes : manualMatchTypes


  const handleChange = (e) => {
    const value = e.target.value
    const stateUpdaterFunc = props?.Brand ? setmanualBrandMatchTypes : props?.editkeywords ? setEditManualMatchTypes : setManualMatchTypes
    stateUpdaterFunc((prev) => {
      return prev.includes(value)
          ? prev.filter((row) => value !== row)
          : [...prev, value]
      })
  };

  const handleName = (e) => {
    setIsText(e.target.value);
  };

  const onDelete = (id) => {
    const { match, text } = id;
    const keyToCheck = `${text}-${match}`;
    if (props.Brand) {
      let filteredKeywords = brandKeywordsToBeAdded.filter((keyword) => {
        const keyToReplace = `${keyword.text}-${keyword.match}`;
        return keyToCheck !== keyToReplace;
      });
      setBrandKeywordsToBeAdded(filteredKeywords);
      // Remove the keyword from AdGroup data as well.
      setAdGroupData((current) => {
        const copy = { ...current };
        const updatedArray = copy["amz_keyword_targeting"].filter((keyword) => {
          const keyToReplace = `${keyword.text}-${keyword.match}`;
          return keyToCheck !== keyToReplace;
        });
        copy["amz_keyword_targeting"] = updatedArray;
        return copy;
      });
      localStorage.setItem("brandkeywords", JSON.stringify(filteredKeywords));
    } else if (props.editkeywords) {
      let filteredKeywords = editKeywordsLocal.filter((keyword) => {
        const keyToReplace = `${keyword.text}-${keyword.match}`;
        return keyToCheck !== keyToReplace;
      });
      setEditKeywordsToBeAdded(filteredKeywords);
      // setEditWholeKeywords((current) => {
      //   return current.filter((keyword) => {
      //     const keyToReplace = `${keyword.text}-${keyword.match}`;
      //     return keyToCheck !== keyToReplace;
      //   });
      // });
    } else {
      let filteredKeywords = keywordsTobeAdded.filter((keyword) => {
        const keyToReplace = `${keyword.text}-${keyword.match}`;
        return keyToCheck !== keyToReplace;
      });
      setKeywordsTobeAdded(filteredKeywords);
      // Remove the keyword from AdGroup data as well.
      setAdGroupData((current) => {
        const copy = { ...current };
        const updatedArray = copy["amz_keyword_targeting"].filter((keyword) => {
          const keyToReplace = `${keyword.text}-${keyword.match}`;
          return keyToCheck !== keyToReplace;
        });
        copy["amz_keyword_targeting"] = updatedArray;
        return copy;
      });
      localStorage.setItem("keywords", JSON.stringify(filteredKeywords));
    }
  };

  const getSuggestedBidForKeywords = async (dataPassed) => {
    const url = `https://adyogiadsapi.adyogi.com/client/${clientId}:fetch-bid-recommendation?source=amazon`;
    const data = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(dataPassed),
    };
    try {
      const response = await fetch(url, data);
      const result = await response.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const generateKeywordsWithType = (currentWords, currentTypes) => {
    let oneMoreArray = [];
    let newArray = currentWords.map((word) => {
      return currentTypes.map((type) => {
        oneMoreArray.push({ type: `${type.toUpperCase()}`, value: word });
      });
    });
    return oneMoreArray;
  };

  const handleSubmitKeywords = async () => {
    const keywordsArray = isText.split(COMMA_NEWLINE_SPLITTER)?.map(item => item.replace(/\n\s*/g, ' ').trim());
    const arrayOfFormattedKeywords = generateKeywordsWithType(
      keywordsArray,
      props.Brand
        ? manualBrandMatchTypes
        : props.editkeywords
        ? editManualMatchTypes
        : manualMatchTypes
    );

    const keywordsToBeSent = removeDuplicateObjectsFromArray(arrayOfFormattedKeywords, props.Brand ? brandKeywordsToBeAdded : props.editkeywords ? editKeywordsLocal : keywordsTobeAdded)

    if(keywordsToBeSent.length) {
       // Data for API Call
       const key = props?.editkeywords ?  'adset_id' : 'categories'
       const valueForKey = props?.editkeywords ? props?.adsetId : selectedCategory
    const keywordsPosted = {
      type: "SP_KEYWORD",
      [key]: valueForKey,
      keywords: keywordsToBeSent,
      bidding_strategy: "LEGACY_FOR_SALES",
    };

    // API Call will be made here
    setButtonStatus({ status: true, text: "Adding-keywords" });
    const result = await getSuggestedBidForKeywords(keywordsPosted);
    if(result?.data === undefined) {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_DANGER,
      //   title: "Error",
      //   description: `${result?.message}`,
      // });
      toast.error(`${result?.message}`)
      setButtonStatus({status:false,text:""})
    }
 
    const {
      recommended_suggested_bid,
      lower_suggested_bid,
      upper_suggested_bid,
    } = result?.data[0];

    // API Result

    let arrayToBeSet = [];
    arrayOfFormattedKeywords.map((keyword) => {
      for (let i = 0; i < result?.data.length; i++) {
        const keyToMatch = `${keyword.value.toLowerCase()}-${keyword.type.toLowerCase()}`;
        const keyToPair = `${result?.data[
          i
        ].keyword.toLowerCase()}-${result?.data[i].keyword_type.toLowerCase()}`;
        if (keyToMatch === keyToPair) {
          arrayToBeSet.push({
            match: keyword.type.toLowerCase(),
            bid: result?.data[i].recommended_suggested_bid ?? default_bid,
            userBid: amz_default_bid ?? default_bid,
            lowest: result?.data[i].lower_suggested_bid ?? 0.0,
            heighest: result?.data[i].upper_suggested_bid ?? 0.0,
            text: keyword.value,
            status: "ENABLED",
          });
        }
      }
    });
    // Final Data After removing the Duplication.
    const finalData = arrayToBeSet.filter(
      (thing, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.text === thing.text &&
            t.match === thing.match &&
            t.bid === thing.bid
        )
    );
    setButtonStatus({ status: false, text: "" });

    if (props.Brand) {
      setBrandKeywordsToBeAdded((current) => {
        if (current.length !== 0) {
          return current.concat(finalData);
        } else {
          return finalData;
        }
      });
      setIsText("");
      brandKeywordsToBeAdded.length !== 0
        ? setAdGroupData({
            ...adGroupData,
            amz_keyword_targeting: [
              ...brandKeywordsToBeAdded.concat(finalData),
              // ...brandKeywordsLocal.concat(finalData),
            ],
          })
        : setAdGroupData({
            ...adGroupData,
            amz_keyword_targeting: [...brandKeywordsToBeAdded, ...finalData],
          });
    } else if (props.editkeywords) {
      setEditKeywordsToBeAdded((current) => {
        if (current.length !== 0) {
          return current.concat(finalData);
        } else {
          return finalData;
        }
      });
      // setEditWholeKeywords([...editWholeKeywords, ...finalData]);
      setIsText("");
    } else {
      setKeywordsTobeAdded((current) => {
        if (current.length !== 0) {
          return current.concat(finalData);
        } else {
          return finalData;
        }
      });
      setIsText("");
      keywordsTobeAdded.length !== 0
        ? setAdGroupData({
            ...adGroupData,
            amz_keyword_targeting: [
              ...keywordsTobeAdded.concat(finalData),
              // ...keywords.concat(finalData),
            ],
          })
        : setAdGroupData({
            ...adGroupData,
            amz_keyword_targeting: [...keywordsTobeAdded, ...finalData],
          });
    }
    }
    
   
  };


  const changeHandler = (event) => {
   
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        let asinValues = results.data.map((data, index) => {
          return Object.values(data)[0]
        });
        setIsText(asinValues.toString())
      },
    });
  }

  const openUploader = () => {
    const input = document.querySelector('#keyword-upload-input');
    // Make the value null everytime input will open so if user uploads same file he should be able  to upload it again
    input.value = null
    input.click()
  }
  
  const onYesClickHandler = (e) => {
      const currentStateUpdater = props.Brand ? setBrandKeywordsToBeAdded : props.editkeywords ? setEditKeywordsToBeAdded : setKeywordsTobeAdded
      const currentState = props.brand ? brandKeywordsToBeAdded : props.editkeywords ? editKeywordsToBeAdded : keywordsTobeAdded
      currentStateUpdater((prev) => {
        const copy = [...prev].map((keyword) => {
          keyword.userBid = keyword.bid
          return keyword
        })
        return copy
      })
      setActionAlertModalVisibility(false)
      setAdGroupData((prev) => {
        const copy = {...prev}
        copy["amz_keyword_targeting"] = copy["amz_keyword_targeting"]?.map((keyword) => {
          currentState.forEach((word) => {
            if(keyword.text === word.text) {
              keyword.userBid = keyword.bid
            }
          })
          return keyword
        })
        return copy
      })
  }

  

  const onNoClickHandler = (e) => {
    setActionAlertModalVisibility(false)
  }

  const cancelBidModal = () => {
    setEditBidModal(false)
  }


  return (
    <>
    {bulkEditBidModalVisibilty && editBidModal &&  <EditBidModalForTargeting cancel={cancelBidModal} show={bulkEditBidModalVisibilty} key={"For-Add"} setBidAs={true} getData={getData}/>}
   {actionAlertModalVisibility && <MasterTagetinngActionAlertMModal onNoClick={onNoClickHandler} onYesClick={onYesClickHandler}  alertText={alertText}/>}
    <div className="row">
      <div className="col-lg-6">
        <div className="keyword-targeting-tab-box">
          <div className="search-product">
            <span
              className={"right-icon"}
              onClick={openUploader}
            >
               <input
                      type="file"
                      name="file"
                      accept=".csv"
                      style={{ display: "none" }}
                      onChange={changeHandler}
                      id={"keyword-upload-input"}
                    />
              {/* <svg
                width="22"
                height="16"
                viewBox="0 0 22 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={isVisible ? "white-color-active" : ""}
              >
                <path
                  d="M12.8541 12.9987L12.8532 12.9979C12.6483 12.7875 12.387 12.6843 12.0886 12.6843H9.91169C9.61326 12.6843 9.35202 12.7875 9.1477 12.9983C8.94341 13.209 8.84537 13.4749 8.84537 13.7764C8.84537 14.0778 8.94341 14.3437 9.1477 14.5545C9.35202 14.7652 9.61326 14.8685 9.91169 14.8685H12.0886C12.387 14.8685 12.6483 14.7653 12.8532 14.5549L12.8541 14.554C13.0574 14.3432 13.1549 14.0775 13.1549 13.7764C13.1549 13.4753 13.0574 13.2095 12.8541 12.9987ZM20.4774 2.88577L20.4778 2.88534C20.673 2.67165 20.7739 2.4153 20.7739 2.12726C20.7739 1.82618 20.6765 1.56053 20.4727 1.35031C20.2679 1.13903 20.0064 1.03516 19.7076 1.03516H2.29264C1.99384 1.03516 1.73247 1.13906 1.52824 1.35073L1.70816 1.52431L1.52906 1.34988C1.32438 1.56004 1.22632 1.82583 1.22632 2.12726C1.22632 2.41555 1.32785 2.67197 1.52376 2.88555C1.72672 3.10765 1.98938 3.21937 2.29264 3.21937H19.7076C20.011 3.21937 20.2738 3.10761 20.4774 2.88577ZM17.2125 7.17971L17.2121 7.17928C17.0085 6.95744 16.7456 6.84568 16.4423 6.84568H5.55795C5.25471 6.84568 4.99207 6.95738 4.78912 7.17944C4.59318 7.39303 4.49162 7.64947 4.49162 7.93779C4.49162 8.23902 4.58955 8.50465 4.79396 8.71474C4.99813 8.92613 5.25934 9.02989 5.55795 9.02989H16.4423C16.7411 9.02989 17.0026 8.92602 17.2074 8.71474C17.4112 8.50452 17.5086 8.23887 17.5086 7.93779C17.5086 7.64975 17.4077 7.3934 17.2125 7.17971Z"
                  fill="#07A9D2"
                  stroke="#07A9D2"
                  stroke-width="0.5"
                />
              </svg> */}
              <img alt="upload-keywords" className="cursor-pointer-global" src={csvIcon} height={20} width={20}/>
            </span>
          </div>
          <div>
            <span className="match-head">
              Match Type
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_1178_14809"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <rect width="20" height="20" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_1178_14809)">
                  <path
                    d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                    fill="#4D4184"
                  />
                  <path
                    d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                    fill="#4D4184"
                  />
                </g>
              </svg>
            </span>
          </div>
          <div className="checkbox-box">
            <CheckboxRb
              label={"Broad"}
              value={"Broad"}
              checked={checkBoxesStates?.includes("Broad")}
              onChange={handleChange}
            ></CheckboxRb>
            <CheckboxRb
              label={"Phrase"}
              value={"Phrase"}
              checked={checkBoxesStates?.includes("Phrase")}
              onChange={handleChange}
            ></CheckboxRb>
            <CheckboxRb
              label={"Exact"}
              value={"Exact"}
              checked={checkBoxesStates?.includes("Exact")}
              onChange={handleChange}
            ></CheckboxRb>
            <div className="float-right">
              <button
                className={
                  isText !== "" && manualMatchTypes.length !== 0
                    ? "add-all-btn-dark text-nowrap"
                    : isText !== "" && manualBrandMatchTypes.length !== 0
                    ? "add-all-btn-dark text-nowrap"
                    : "add-all-btn text-nowrap"
                }
                onClick={handleSubmitKeywords}
              >
                Add Keywords
              </button>
            </div>
          </div>

          <textarea
            rows="23.5"
            cols="30"
            className="w-100 mt-2 textarea-add-targeting textarea-scroll p-2"
            placeholder="Enter your list and separate each item with a comma"
            value={isText}
            onChange={handleName}
          ></textarea>
          <div className="float-left mt-2">
            <span className={success ? `text-success` : "text-danger"}>
              {msg && msg}
            </span>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="keyword-targeting-tab-box ">
          <div className="sp-product-targettig-outer-container">
            <TableRb table={tableBody}></TableRb>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default AddSpKeyword;
