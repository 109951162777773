import "../campaignList.css";
import "./masterAdsMain.css";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGlobalContext } from "../../../context/global";
import Loader from "../../../components/loader";
import FilterLogicModal from "../../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import CSVUploadModal from "../../../components/modal/campaignListModals/csvUploadModal/csvUploadModal";
import MasterTagetinngActionAlertMModal from "../../../components/modal/masterTargetinngActionnAlertMModal/masterTargetingActionnAlertModal";
import {
  TOAST_POSITION_TOP_RIGHT,
  TOAST_SUCCESS,
  TOAST_DANGER,
} from "../../../constant/HardTypes";
import { roundFigureNum } from "../../../constant/roundFigureNum";
import { Link,useNavigate,useSearchParams } from "react-router-dom";
import dashIcon from "../../../assets/icons/dash-icon.png";
import { Pagination, Stack } from "react-bootstrap";
import SectionTitle from "../../../components/sectionTitle";
import settingIcon from "../../../assets/icons/overviewSeiingIcon.svg";
import dropdownIcon from "../../../assets/icons/downArrowForSplitButton.svg";
import CalenderIconForCampaignList from "../../../assets/icons/calender-icon-for-campaignlist.svg";
import selectSideIcon from "../../../assets/icons/select-btn-side-grey-icon.png";
import closeIcon from "../../../assets/icons/close_icon.png";
import pauseIcon from "../../../assets/icons/pause_circle.svg";
import resumeIcon from "../../../assets/icons/play_circle.png";
import CalenderComponent from "../../../components/calender/Calender";
import TableRb from "../../../components/Tables/tableRb";
import SwitchBtn from "../../../components/Buttons/switchBtn";
import CheckboxRb from "../../../components/Buttons/checkbox/checkbox";
import Spinner from "../../../components/spinners/spinner";
import SecondaryBtnRb from "../../../components/Buttons/secondaryBtnRb/secondaryBtnRb";
import { Search } from "react-bootstrap-icons";
import CustomDropDown from "../../../components/DropDown/CustomDropDown";
import CampaignListTab from "../../../components/Tabs/CampaignListTab";
import MasterTargetingSearchSelectModal from "../../../components/modal/masterTargetinngActionnAlertMModal/search&select/MasterTargetingSearchSelectModal";
import dateConverter from "../../../helper/common/dateConverter";
import sortData from "../../../helper/CampaignList/sortData";
import ascendingIconSvg from "../../../assets/svgs/acendingIconSvg.svg";
import descendingIconSvg from "../../../assets/svgs/descendingIcon.svg";
import whiteFindIcon from "../../../assets/icons/white_magnnifineIcon.png";
import FilterChips from "../../../components/FilterChips/FilterChips";
import AddIcon from "../../../assets/svgs/plus_box.svg";
import MinusIcon from "../../../assets/svgs/minus_box.svg";
import { campaignListActions } from "../../../redux/slices/campaignList";
import ProductNameTd from "../../../components/Tables/TableComponents/productNameTd";
import { onRowExpandHandler } from "../../../helper/CampaignList/masterAds/rowExpandHandler";
import { masterApi } from "../../../apis/masterApi";
import { parentRowCheckBoxHandler } from "../../../helper/CampaignList/masterAds/parentRowCheckBoxHandler";
import { selectAllcheckBoxes } from "../../../helper/CampaignList/masterAds/selectAllcheckBoxes";
import { generateFiltering } from "../../../helper/CampaignList/masterAds/generateFiltering";
import { encodeData } from "../../../helper/common/encodeData";
import { enablePauseSingleChildAd } from "../../../helper/CampaignList/masterAds/enablePauseSingle";
import { enablePauseBulkChildAd } from "../../../helper/CampaignList/masterAds/enablePauseBulk";
import MultiSelectCustomDropDown from "../../../components/DropDown/MultiSelectCustomDropDown";
import LazyLoadSpinner from "../../../components/spinners/LazyLoadSpinner";
import SearchBarForCampaignListOnly from "../../../components/searchBarForCampaignListOnly";
import { useAuthUser } from "react-auth-kit";
import csvIcon from "../../../assets/icons/csv-upload.svg";
import Papa from "papaparse";
import { AiOutlineSearch, AiOutlinePause, AiOutlinePlay } from 'react-icons/ai';
import { FaDownload, FaEllipsisV } from 'react-icons/fa';
import useInitialLoad from "../../../hooks/useInitialLoad";
import {defaultMetrices, EXTRA_METRICES_FOR_PRODUCT_ADS} from '../../../constant/marketingConstant'
import Breadcrumb from '../../../components/Breadcrumb/ReportsBreadCrumb';
import {toast} from 'react-toastify';
import { allConfirmationButtonsIds } from "../../../constant/confirmActions";
import TableRowWithPagination from "../../../components/Tables/TableRowWithPagination";
import { Tooltip as ReactTooltip }  from "react-tooltip";
import uploadIcon from '../../../assets/svgs/folderwithgear.svg'
import PopulateProductAds from "../../../components/modal/campaignListModals/populateProductAds/PopulateProductAds";
import { fetchPaginatedData } from "../../../helper/common/fetchPaginatedData";
const MasterAdsMain = () => {
  const [isBulkUploadOptionOpen, setIsBulkUploadOptionOpen] = useState(false);
  const kababRef = useRef(null);

  // States || Global Contexts || Redux Imports || Global Variables
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const [cancelSpecifiNumberFilter, setCancelSpecifiNumberFilter] =
    useState(null);

  const getUpdatedConfigHeadsForMasterAdsSection = useSelector(
    (state) => state.campaignListData.getUpdatedConfigHeadsForMasterAdsSection
  );

  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );

  const csvUploadModalInGlobalAd = useSelector(
    (state) => state.campaignListData.csvUploadModalInGlobalAd
  );

  const appliedFilterData = useSelector(
    (state) => state.campaignListData.appliedFilterData
  );

  const [initialLoad,setInitialLoad] = useInitialLoad()
  const auth = useAuthUser()
  const userDetails = auth().user;
  const isMultiuser = userDetails?.multiUser === "yes";

  const dispatch = useDispatch();

  const { calendarDate, showAlert, dateDifference,applyCalendar } = useGlobalContext();
  const [allLoaders, setAllLoaders] = useState({
    mainLoader: true,
    childRowLoader: false,
  });
  const [allFiltersVisible, setAllFilterVisible] = useState({
    mainFilterLogicModal: false,
    ConfirmAlertModal: false,
    searchSortModal: false,
    csvUploadModal: false,
    populateAds:false
  });

  const [filters, setFilters] = useState({
    product_name: "",
    campaign_type: "All",
    campaign_objective: "All",
    category: "",
    portfolio_id: "",
    campaign_id: "",
    adgroup_id: "",
    status: "",
    asin: "",
    ad_id:"All"
  });
  const [statFilters, setStatFilters] = useState([]);
  const [searchFiltersState, setSearchFiltersState] = useState({
    portfolios: [],
    campaigns: [],
    adGroups: [],
    categories: [],
  });
  const [minMaxFilterActivated, setMinMaxFilterActivated] = useState(false);
  const [categories, setCategories] = useState([]);
  // const [dateDifference, setDateDifference] = useState("");
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState("all-btn");
  const [dataHeads, setDataHeads] = useState([]);
  const [mainIndex, setMainIndex] = useState(0);
  const [actionAlertModalText, setActionAlertModalText] = useState("");
  const confirmationId = allConfirmationButtonsIds['product-ads'][actionAlertModalText] ?? 'product-ads-confirmation'
  const [parentRowsData, setParentRowsData] = useState([]);
  const [childRowsData, setChildRowsData] = useState([]);
  const [columnName, setColumnName] = useState("");
  const [paginationAction, setPaginationAction] = useState({
    currentPage: 1,
    currentOffSet: 0,
  });
  const [sortBy, setSortBy] = useState({ sort_head: "spends", order: "desc" });
  const [selectedAds, setSelectedAds] = useState([]);
  const [lazyLoading, setLazyLoading] = useState(false);
  const [overAllAdsCount, setOverAllAdsCount] = useState({});
  const [campaignsIds, setCampaignsIds] = useState("");
  const [currentAsin, setCurrentAsin] = useState("");
  const [searchValues, setSearchValues] = useState("");
  const dataForMultiSelectDropDown = [
    {
      name: "SP",
      isDataChecked: false,
      buttonName: "Campaign Type",
      apiFieldNameForFilteringRequest: "campaign_type",
      fieldName: "mt-campaign_type",
    },
    {
      name: "SD",
      isDataChecked: false,
      buttonName: "Campaign Type",
      apiFieldNameForFilteringRequest: "campaign_type",
      fieldName: "mt-campaign_type",
    },
  ];
  const [DropDownDataList, setDropDownDataList] = useState(
    dataForMultiSelectDropDown
  );
  const [checkedDropDownFilterButtonData, setCheckedDropDownFilterButtonData] =
    useState([]);
  const tableRef = useRef(null);
  
  // Main UI Layer
  const [tableDataToShow, setTableDataToShow] = useState([1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [summaryData,setSummaryData] = useState([]);

  const [viewType,setViewType] = useState('Granular');
  const isGlobalView = viewType === 'Global';

  const isNonAdvertisingActive = filters?.ad_id === "Non Advertised";

  // Custom Variables
  const clientId = JSON.parse(localStorage.getItem("clientId"));
  const accountId = JSON.parse(localStorage.getItem("accountId"));
  const limit = 3000;
  const metricesWithNoDecimal = ["spends", "sale_value", "aov","maximum_retail_price","sale_price","total_units_sold","total_asp","total_revenue","quantity"];
  const metricesWithTwoDecimal = ["cpm", "ctr", "cpc", "cvr", "roas", "acos","discount_percentage","organic_sales_percentage","troas","tacos",];
  const upperCaseValues = ["cpm", "ctr", "cpc", "cvr", "roas", "acos", "aov","mrp","sp","asp","troas","tacos"]
  const dataForChips = generateChipFilters(filters, statFilters);

  // Derived Variables From State
  const isCampaignFilterActive = filters?.campaign_id?.length > 0;
  const isPortfolioFilterActive = filters?.portfolio_id?.length > 0;
  const adGroupFilterActive = filters?.adgroup_id?.length > 0;
  const categoryFilterActive = filters?.category?.length > 0;

  const [paginationOffset,setPaginationOffset] = useState(0)
 

  // Refs
  const masterAdsCalendarIconRef = useRef(null);
  const hiddenFileInput = useRef(null);

  //redirect data
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const { asinId } = params;
  const [asinIdLinkFromProductReport, setAsinIdLinkFromProductReport] =
  useState(asinId ? asinId : null);
  const navigate = useNavigate();
  const inputHandler = (searchValue) => {
    if (asinId) {
      searchParams.delete("asinId");
      navigate("/master-ads");
      setAsinIdLinkFromProductReport(null);
    }
    setSearchValues(searchValue);
  };

  // Effects

  const getAllCategories = async () => {
    try {
      const response = await fetch(
        `https://catalogapi.adyogi.com/client/${clientId}/categories?source=amazon&fields=name,id&limit=1000`
      );
      const result = await response.json();
      if (result?.data) {
        const { data } = result;
        // const modifiedData = data.map((dataName) => dataName.name);
        setCategories(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCampaigns = async () => {
    const campaignFilteringBasedOnPortfolio = generateFiltering({
      amz_portfolio_id: mapValues(filters, "platform_id")?.portfolio_id,
    });
    const adGroupFilteringBasedonCampaign = generateFiltering({
      campaign_id: campaignsIds,
    });
    const dataForCampaign = {
      source: "amazon",
      fields: "id,name,platform_id,status,amz_portfolio_id,objective",
      filtering: encodeData(campaignFilteringBasedOnPortfolio),
      limit:5000
    };
    const dataForAdgroup = {
      source: "amazon",
      fields: "id,name,platform_id,campaign_id",
      filtering: encodeData(adGroupFilteringBasedonCampaign),
      limit:5000
    };
    const dataForPortfolio = {
      source: "amazon",
      fields: "id,name,platform_id",
    };
    try {
      //  Call all the APIs concurrently to reduce the response time.
      const [campaignsResponse, adgroupResponse, portfolioResponse] = await Promise.all([
        fetchPaginatedData(masterApi, dataForCampaign, 5000, 3, "campaignList", false),
        fetchPaginatedData(masterApi, dataForAdgroup, 5000, 12, "adsetList", false),
        masterApi(dataForPortfolio, "getPortfolios", false)
      ]);
      
      const campaigns = campaignsResponse?.filter((data) => {
        return !data?.objective?.includes('SB')
      }) ?? [] // Remove SB campaigns
      const adgroups = adgroupResponse ?? []
      const portfolios = portfolioResponse?.data?.data ?? []
      setSearchFiltersState({
        ...searchFiltersState,
        campaigns: campaigns,
        adGroups: adgroups,
        portfolios,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const loadMoreData = async (currentOffset) => {
    try {
      setLazyLoading(true);
      const mappedData = mapValues(filters, "platform_id");
      const filtering = generateFiltering(mappedData);
      let mainFiltering = filtering.concat(statFilters);
      mainFiltering.forEach(function (value, i) {
        if (value.field_name === 'asin') {
          if (mainFiltering[i].value.length > 0) {
            let object = {}
            object.field_name = "asin"
            object.operator = "IN"
            object.values = mainFiltering[i].value
            mainFiltering = []
            mainFiltering.push(object)
          } else {
            mainFiltering = []
          }
        }
      });
      const parentRowData = {
        source: "amazon",
        master_row: isGlobalView,
        order_by: sortBy?.sort_head,
        sorting_order: sortBy.order,
        limit: limit,
        offset: currentOffset,
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        filtering: encodeData(mainFiltering),
      };
      const result = await masterApi(parentRowData, "masterAds", false);
      setLazyLoading(false);
      return result?.data?.data;
    } catch (error) {
      console.log(error);
    }
  };

  function modifyData(parentData = [], targetChecked=false) {
    const modifiedData = parentData?.map((eachObject) => {
      return {
        ...eachObject,
        isPlusIconDisabled: true,
        isTargetChecked: targetChecked,
        childRows: [],
      };
    });
    return modifiedData;
  }
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        masterAdsCalendarIconRef &&
        masterAdsCalendarIconRef.current &&
        !masterAdsCalendarIconRef.current.contains(e.target)
      ) {
        setShowCustomDateBox(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showCustomDateBox]);
  const rowsCountRef = useRef(null);
  useEffect(() => {
    rowsCountRef.current += 1;
    const requestId = rowsCountRef.current
    getParentsRowsData(false,requestId); // For Normal Rows
    getParentsRowsData(true,requestId); // For Summary Row
    setPaginationAction({
      ...paginationAction,
      currentOffSet: 0,
      currentPage: 1,
    });
    setIsAllSelected(false); // Remove bulk selector
  }, [filters, sortBy, statFilters, applyCalendar,viewType]);//[filters, sortBy, statFilters, calendarDate]

  useEffect(() => {
    setAllFilterVisible({
      ...allFiltersVisible,
      mainFilterLogicModal: filterModalInCampaignListVisibilty,
    });
  }, [filterModalInCampaignListVisibilty]);

  useEffect(() => {
    setAllFilterVisible({
      ...allFiltersVisible,
      csvUploadModal: csvUploadModalInGlobalAd,
    });
  }, [csvUploadModalInGlobalAd]);

  const handleClickOutside = (event) => {
    if (isBulkUploadOptionOpen && kababRef.current && !kababRef.current.contains(event.target)) {
      setIsBulkUploadOptionOpen(false);
    }
  };

  useEffect(() => {
    // dispatch(campaignListActions.appliedFilterData([]));

    // const handleClickOutside = (event) => {
    //   if (isBulkUploadOptionOpen && kababRef.current && !kababRef.current.contains(event.target)) {
    //     setIsBulkUploadOptionOpen(false);
    //   }
    // };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isBulkUploadOptionOpen]);


  // Effects


  useEffect(() => {
    if (appliedFilterData?.length) {
      const statFiltering = transformData(appliedFilterData);
      if (
        statFiltering.length > 0 ||
        (appliedFilterData?.length === 4 && statFilters.length > 0) ||
        statFilters.length > 0
      ) {
        setStatFilters(statFiltering);
      }
    } else {
      if(tableRef.current) { // Skip the first render
        setStatFilters([]);
        setMinMaxFilterActivated(false);
      }
    }
    setCancelSpecifiNumberFilter("")
  }, [appliedFilterData]);

  function transformData(input) {
    const output = [];
    setMinMaxFilterActivated(false);
    for (let i = 0; i < input?.length; i++) {
      const obj = input[i];

      // Check if the object has non-empty and non-zero values for minRange or maxRange
      if (
        (obj.minRange !== "" && obj.minRange !== 0 && obj.minRange !== "-") ||
        (obj.maxRange !== "" && obj.maxRange !== 0 && obj.maxRange !== "-")
      ) {
        // Add a new object to the output array with "GREATER_THAN" operator and the "minRange" value, if minRange is not empty or zero
        if (obj.minRange !== "" && obj.minRange !== 0 && obj.minRange !== "-") {
          setMinMaxFilterActivated(true);
          output.push({
            field_name: obj?.id.toLowerCase(),
            operator: "GREATER_THAN",
            value: obj.minRange.toString(),
          });
        }

        // Add a new object to the output array with "LESSER_THAN" operator and the "maxRange" value, if maxRange is not empty or zero
        if (obj.maxRange !== "" && obj.maxRange !== 0 && obj.maxRange !== "-") {
          setMinMaxFilterActivated(true);
          output.push({
            field_name: obj?.id.toLowerCase(),
            operator: "LESS_THAN",
            value: obj.maxRange.toString(),
          });
        }
      }
    }
    return output;
  }

  useEffect(() => {
    setDataHeads(getUpdatedConfigHeadsForMasterAdsSection);
  }, [getUpdatedConfigHeadsForMasterAdsSection]);

  useEffect(() => {
    // Getting All the categories to display in the dropdown
    getAllCategories();
    getAllCampaigns();
  }, [clientId, filters]);

  useEffect(() => {
    const count = getUpdatedCount(tableDataToShow,isGlobalView);
    setSelectedAds([...count]);
  }, [tableDataToShow]);

  useEffect(() => {
    getParentRowsCount();
  }, [viewType,filters,statFilters,applyCalendar]);
  // API Calls Methods
  async function getParentsRowsData(isSummaryRow = false,requestId) {
    setAllLoaders({ ...allLoaders, mainLoader: true });
    setCurrentPage(1)
    setPaginationOffset(0)
    try {
      const filteredData = mapValues(filters, "platform_id");
      const filtering = generateFiltering(filteredData);
      let mainFiltering = filtering.concat(statFilters);
      //hack now for filtering will fix this in tech debt task
      mainFiltering.forEach(function (value, i) {
        if (value.field_name === 'asin') {
          if (mainFiltering[i].value.length > 0) {
            let object = {}
            object.field_name = "asin"
            object.operator = "IN"
            object.values = mainFiltering[i].value
            mainFiltering = []
            mainFiltering.push(object)
          } else {
            mainFiltering = []
          }
        }
      });
      let filteringAfterDuplicateRemoval = mainFiltering
      if (mainFiltering?.length === 0 || mainFiltering.length === 1) {
        // Removing Filtering Which has key as value and array // Removing array of asins in with EQUALS OPERATOR
        filteringAfterDuplicateRemoval = [...mainFiltering.concat(filtering).concat(statFilters)].filter((filters) => {
            return !Array.isArray(filters?.value)
        })
      }
      const finalFiltering = removeDuplicateFiltering(filteringAfterDuplicateRemoval)
      const parentRowData = {
        source: "amazon",
        master_row: isSummaryRow ? true : isGlobalView,
        order_by: sortBy?.sort_head,
        sorting_order: sortBy.order,
        limit: limit,
        offset: 0,
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        filtering: encodeData(finalFiltering),
      };
      if(isSummaryRow) {
        parentRowData['summary_data'] = true;
      }
      const result = await masterApi(parentRowData, "masterAds", false);
      if (result && result?.data?.data && requestId === rowsCountRef.current) {
        if(isSummaryRow) {
          setSummaryData(result?.data?.data[0]);
        }
        if(!isSummaryRow) {
          const returnedData = [...modifyData(result?.data?.data)];
          setTableDataToShow(returnedData);
          setAllLoaders({ ...allLoaders, mainLoader: false });
        }
      } else if(result?.statusCode!==200) {
        toast.error(`Unknown Error Occurred with statusCode${result?.statusCode}`)
        setAllLoaders({ ...allLoaders, mainLoader: false });
      }
      setInitialLoad(false)
    } catch (error) {
      setAllLoaders({ ...allLoaders, mainLoader: false });
      setInitialLoad(false)
      console.log(error);
    }
  }

  async function getParentRowsCount() {

    try {
      let mainFiltering = [
      ];
      const filteredData = mapValues(filters, "platform_id");
      const filtering = generateFiltering(filteredData);
       mainFiltering = filtering.concat(statFilters);
      //hack now for filtering will fix this in tech debt task
      mainFiltering.forEach(function (value, i) {
        if (value.field_name === 'asin') {
          if (mainFiltering[i].value.length > 0) {
            let object = {}
            object.field_name = "asin"
            object.operator = "IN"
            object.values = mainFiltering[i].value
            mainFiltering = []
            mainFiltering.push(object)
          } else {
            mainFiltering = []
          }
        }
      });
      let filteringAfterDuplicateRemoval = mainFiltering
      if (mainFiltering?.length === 0 || mainFiltering.length === 1) {
        // Removing Filtering Which has key as value and array // Removing array of asins in with EQUALS OPERATOR
        filteringAfterDuplicateRemoval = [...mainFiltering.concat(filtering).concat(statFilters)].filter((filters) => {
            return !Array.isArray(filters?.value)
        })
      }
      filteringAfterDuplicateRemoval.push({ field_name: "status_count", operator: "EQUALS", value: "count" }     
      )
      filteringAfterDuplicateRemoval = filteringAfterDuplicateRemoval?.filter((filter) => filter.field_name !== "status")
      const finalFiltering = removeDuplicateFiltering(filteringAfterDuplicateRemoval)
      const parentRowData = {
        source: "amazon",
        master_row: isGlobalView,
        order_by: "spends",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        filtering: encodeData(finalFiltering),
      };
      
      const result = await masterApi(parentRowData, "masterAds", false);
      if (result && result?.data?.data) {
        setOverAllAdsCount(result?.data?.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Methods

  // For Chip Filters

  const onCancelClickRemoveFilteringHandlerr = (fieldName) => {
    const isPresent = [...defaultMetrices,...EXTRA_METRICES_FOR_PRODUCT_ADS]?.includes(fieldName?.toLowerCase());
    if(isPresent) {
      setCancelSpecifiNumberFilter(fieldName);
    } else {
      setFilters({ ...filters, [fieldName]: "" });
      if (fieldName === "campaign_id") {
        setCampaignsIds("");
      }
    }
  };

  function formatString(str) {
    // Remove underscores and split the string into words
    let words = str.split('_');
    
    // Capitalize the first letter of each word and join them back together
    let formattedString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    return formattedString;
}
  
  function generateChipFilters(data, dataForStatFilters) {
    const filteredData = mapValues(data, "name");
    const keys = Object.keys(filteredData);
    const transformedData = keys
      .filter(
        (key) =>
          filteredData[key] !== undefined &&
          filteredData[key] !== null &&
          filteredData[key] !== "" &&
          filteredData[key] !== "All" &&
          filteredData[key] !== "Both" &&
          key !== "product_name" &&
          key !== "asin" &&
          key !== "status"
      )
      .map((key) => {
        const fieldName = key.includes("portfolio")
          ? "Portfolio"
          : key.includes("campaign")
            ? formatString(key)
            : key.includes("category")
              ? "Category"
              : key?.includes('ad_id') ? "Asin Type"
              : "Adgroup";
        return {
          fieldNameOfSelectedBtn: fieldName,
          field_name: key,
          checkedValue:
            typeof filteredData[key] !== "object" &&
              !Array.isArray(filteredData[key])
              ? [filteredData[key]]
              : filteredData[key],
        };
      });

    //this will create array witch merged duplicate key in single onject
    const newArray = [];
    dataForStatFilters.forEach((obj) => {
      let newObj = {
        fieldNameOfSelectedBtn: obj.fieldNameOfSelectedBtn,
        checkedValue: obj.value,
        operator: obj?.operator,
      };
      let foundIndex = newArray.findIndex(
        (x) => x.field_name === obj.field_name
      );
      if (foundIndex === -1) {
        newArray.push({
          field_name: obj.field_name,
          values: [newObj],
          fieldNameOfSelectedBtn: obj.fieldNameOfSelectedBtn,
        });
      } else {
        newArray[foundIndex].values.push(newObj);
      }
    });

    //thiw will loop through new array and create data for min max filter chip
    let listForChip = [];
    let nonnAbbrivaationFieldName = [
      "spends",
      "impressions",
      "clicks",
      "orders",
      "sale_value",
    ];
    newArray.map((currObj) => {
      let minValueForCurrentObj = 0;
      let maxValueForCurrentObj = "Infinity";

      currObj.values.map((currentDaata) => {
        if (currentDaata?.operator === "LESS_THAN") {
          maxValueForCurrentObj = currentDaata.checkedValue;
        }
        if (currentDaata?.operator === "GREATER_THAN") {
          minValueForCurrentObj = currentDaata.checkedValue;
        }
      });

      listForChip.push({
        field_name: currObj.field_name.toLowerCase(),
        fieldNameOfSelectedBtn:
          currObj.field_name === "sale_value"
            ? "Revenue "
            : nonnAbbrivaationFieldName.includes(currObj.field_name)
              ? currObj.field_name.replace(/^\w/, (c) => c.toUpperCase())
              : currObj.field_name.toUpperCase(),
        minValue: minValueForCurrentObj,
        maxValue: maxValueForCurrentObj,
      });
    });

    const allChipFiltersData = transformedData.concat(listForChip);
    return allChipFiltersData;
  }

  // Function to convert Filters Values into Single Array

  function mapValues(obj, filterKey) {
    const keysArray = ["campaign_id", "portfolio_id", "adgroup_id", "category"];
    const newObj = { ...obj };
    for (const key in newObj) {
      if (typeof newObj[key] === "object" || Array.isArray(newObj[key])) {
        if (keysArray.includes(key) && newObj[key].length > 0) {
          newObj[key] = newObj[key].map((item) =>
            key === "category" ? item["name"] : item[filterKey]
          );
        }
      }
    }
    return newObj;
  }
  // Custom SelectDrop Down Methods

  const onOptionCheckedHandlerInDropDownFilter = (
    e,
    checkedValue,
    fieldNameForAPIFilter,
    fieldNameOfSelectedBtn,
    fieldName
    // DropDownDataList
  ) => {
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    currentList.map((list) => {
      if (list.name === checkedValue && list.fieldName === fieldName) {
        if (e.target.checked) {
          list.isDataChecked = true;
          setCheckedDropDownFilterButtonData(
            (checkedDropDownFilterButtonData) => [
              ...checkedDropDownFilterButtonData,
              {
                value: checkedValue,
                fieldNameOfSelectedBtn: fieldNameOfSelectedBtn,
                fieldNameForAPIFilter: fieldNameForAPIFilter,
              },
            ]
          );
        } else {
          list.isDataChecked = false;
          if (
            checkedDropDownFilterButtonData &&
            checkedDropDownFilterButtonData.length
          ) {
            for (
              let index = 0;
              index < checkedDropDownFilterButtonData.length;
              index++
            ) {
              if (
                checkedDropDownFilterButtonData[index] &&
                checkedDropDownFilterButtonData[index].value === checkedValue
              ) {
                checkedDropDownFilterButtonData.splice(index, 1);
              }
            }
            setCheckedDropDownFilterButtonData(
              (checkedDropDownFilterButtonData) => [
                ...checkedDropDownFilterButtonData,
              ]
            );
          }
        }
      }
    });
    setDropDownDataList(currentList);
  };

  const onApplyBtnhandler = () => {
    setFilters({
      ...filters,
      campaign_type: checkedDropDownFilterButtonData?.map(
        (value) => value?.value
      ),
    });
  };

  const onDropdownImghandler = () => { };

  const allSeletHandler = (
    e,
    displayedBtnName,
    BtnNameUniqueField,
    fieldNameForAPIFilter
  ) => {
    setCheckedDropDownFilterButtonData([]);
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    currentList.map((list) => {
      if (
        list.buttonName === displayedBtnName &&
        list.fieldName === BtnNameUniqueField
      ) {
        if (e.target.checked) {
          list.isDataChecked = true;
          setCheckedDropDownFilterButtonData(
            (checkedDropDownFilterButtonData) => [
              ...checkedDropDownFilterButtonData,
              {
                value: list.name,
                fieldNameOfSelectedBtn: displayedBtnName,
                fieldNameForAPIFilter: fieldNameForAPIFilter,
              },
            ]
          );
        }
      }
    });
    setDropDownDataList(currentList);
  };

  const onDashIconHandlerToremoveAllCheckedOption = (
    e,
    targettingValue,
    fieldName,
    uniqueFieldName,
    fieldNameAsSendInApi
  ) => {
    checkedDropDownFilterButtonData.map((checkedData, index) => {
      if (checkedData.fieldNameOfSelectedBtn === targettingValue) {
        // if (index > -1) {
        if (index > 0) {
          checkedDropDownFilterButtonData.splice(index, 1);
        } else {
          checkedDropDownFilterButtonData.splice(index);
        }

        // }
      }
    });
    setCheckedDropDownFilterButtonData((checkedDropDownFilterButtonData) => [
      ...checkedDropDownFilterButtonData,
    ]);
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    currentList.map((list) => {
      if (
        list.buttonName === targettingValue &&
        list.fieldName === uniqueFieldName
      ) {
        // if (e.target.checked) {
        list.isDataChecked = false;
        // fieldNameForAPIFilter: "adyogi_targeting_type";
        // fieldNameOfSelectedBtn: "Targeting Type";
        // value: "Exact";

        // removePreviosSameFilterObject(
        //   targetingFiltering,
        //   list.apiFieldNameForFilteringRequest
        // );
      }
    });

    setDropDownDataList(currentList);
  };

  const onCloseOutSideDropDownList = () => { };

  const showCheckedAppliedFilteringWhenOPen = () => { };

  const formatDateFn = (date) => {
    const selectedDate = new Date(date);
    const formattedDate =
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear();
    return (
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear()
    );
  };

  const onFilterIconhandler = () => {
    dispatch(campaignListActions.showFilterModalInCampaignList());
  };

  const onCSVIconClick = () => {
    setIsBulkUploadOptionOpen(!isBulkUploadOptionOpen)
    dispatch(campaignListActions.showUploadInGlobalAd());
  };

  const getUpdatedDrowDownFilters = (value, key) => {
    const dataToBeSet = value;
    // (value !=="") ? value?.map((data) => {
    //   return key === "category" ? data[filterKey] : parseInt(data[filterKey]);
    // }):""
    if (key === "campaign_id") {
      const campaignIdsData =
        value !== ""
          ? value?.map((data) => {
            return data?.id;
          })
          : "";
      setCampaignsIds(campaignIdsData);
    }
    setFilters({ ...filters, [key]: dataToBeSet });
  };

  const onAdsSearchHandler = (event) => {
    if (event.key === "Enter" || event.target.value === "") {
      if ('product_name' in filters) {
        delete filters.product_name
      }
      if ('asin' in filters) {
        delete filters.asin
      }
      if (!(/,/.test(event.target.value)) && !(/^[A-Z0-9]+$/.test(event.target.value))) {
        setFilters({ ...filters, product_name: event.target.value });
      } else {
        setFilters({ ...filters, asin: event.target.value.split(','), });
      }
    }
  };

  useEffect(()=>{
    if(asinId){
      if ('product_name' in filters) {
        delete filters.product_name
      }
      if ('asin' in filters) {
        delete filters.asin
      }
      setFilters({ ...filters, asin: [asinId], });
      setSearchValues(asinId)
    }
  },[asinId])

  const dateInputClickHandler = () => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  const filterCampaigns = (e, type) => {
    setFilters({ ...filters, [type]: e.target.value });
  };

  const onViewChange = (e,type) => {
    if(e.target.value !== viewType) {
      setViewType(e.target.value);
      setTableDataToShow([]);
      if(e.target.value === 'Global') {
        setFilters((prev) => {
          return {...prev,ad_id:"All"}
        })
      }
    }
  }

  const enablePauseHandler = (status) => {
    dispatch(campaignListActions.showActionAlertModal());
    setAllFilterVisible({ ...allFiltersVisible, ConfirmAlertModal: true });
    setActionAlertModalText(
      `Are you sure you want to ${status} the selected Ads?`
    );
  };

  const onSelectedBtnChangeHandler = (event, status, type) => {
    setSelectedBtn(event.target.id);
    setFilters({ ...filters, status: type.toLowerCase() });
  };
  const [isAllSelected,setIsAllSelected] = useState(false)
  const onAllAdSelection = (e) => {
    setIsAllSelected(e.target.checked)
    const updatedState = selectAllcheckBoxes(tableDataToShow, e.target.checked,isGlobalView,isNonAdvertisingActive);
    setTableDataToShow([...updatedState]);
  };

  const onSearchModalHandler = (input) => {
    setAllFilterVisible({ ...allFiltersVisible, searchSortModal: true });
    setColumnName(input);
  };

  const onSortHandler = (
    initialData,
    condition,
    status,
    type,
    currentIndex
  ) => {
    // // FilterData Sorting.
    // const filteredData = sortData(initialData, condition, status, type);
    // // Set the Heads Flag to true or false
    let convertedData = JSON.parse(JSON.stringify(dataHeads));
    convertedData = convertedData.map((head) => {
      if (head.id === status) {
        head.isSortingClicked = !head.isSortingClicked;
      } else {
        head.isSortingClicked = false;
      }
      return head;
    });
    setSortBy({
      ...sortBy,
      sort_head: status,
      order: condition ? "desc" : "asc",
    });
    // // Update Necessary States
    setDataHeads(convertedData);
    // setMainCurrentIndex(currentIndex);
    // setTableDataToShow([...filteredData]);
  };

  const onParentRowCheckBoxHandler = async (sentAsin) => {
    setCurrentAsin(sentAsin);
    setAllLoaders({ ...allLoaders, childRowLoader: true });
    const asinForFiltering = generateFiltering({ asin: sentAsin });
    const filtersMappedValue = mapValues(filters, "platform_id");
    const mainFilters = generateFiltering(filtersMappedValue);
    const mainFiltering = asinForFiltering
      .concat(statFilters)
      .concat(mainFilters);
    const filteringAfterDuplicateRemoval = mainFiltering.filter((filters) => {
      return !Array.isArray(filters?.value)
    })
    // let tempFilters = []
    // let object = {}
    // object.field_name = "asin"
    // object.operator = "EQUALS"  
    // object.value = sentAsin
    // tempFilters.push(object)
    const finalFiltering = removeDuplicateFiltering(filteringAfterDuplicateRemoval)
    const childRowData = {
      source: "amazon",
      master_row: "false",
      order_by: "spends",
      // limit: "50",
      // offset: 0,
      start_date: `${formatDateFn(calendarDate[0].startDate)}`,
      end_date: `${formatDateFn(calendarDate[0].endDate)}`,
      filtering: encodeData(finalFiltering),
    };
    const checkedData = await parentRowCheckBoxHandler(
      tableDataToShow,
      sentAsin,
      childRowData
    );
    if (checkedData?.error) {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_DANGER,
      //   title: "Error",
      //   description: `${checkedData?.error}`,
      // });
      toast.error(`${checkedData?.error}`)
    } else {
      setTableDataToShow([...checkedData]);
    }
    setAllLoaders({ ...allLoaders, childRowLoader: false });
  };

  const onChildRowBoxHandler = (asin, childIndex , e) => {
    e.stopPropagation()
    const updatedState = onRowExpandHandler(asin, childIndex, tableDataToShow,isGlobalView);
    setTableDataToShow([...updatedState]);
  };

  const expandRow = async (recievedAsin) => {
    setCurrentAsin(recievedAsin);
    setAllLoaders({ ...allLoaders, childRowLoader: true });

    const asinForFiltering = generateFiltering({ asin: recievedAsin });
    const mappedFilters = mapValues(filters, "platform_id");
    const mainFilters = generateFiltering(mappedFilters);
    const mainFiltering = [...asinForFiltering.concat(statFilters)].concat(
      mainFilters
    );
    // Remove the Filtering which has array but key is value // Removal of asin Array.
    const filteringAfterDuplicateRemoval = mainFiltering.filter((filters) => {
      return !Array.isArray(filters?.value)
    })
    const finalFiltering = removeDuplicateFiltering(filteringAfterDuplicateRemoval)
    const childRowData = {
      source: "amazon",
      master_row: "false",
      order_by: "spends",
      // limit: limit,
      // offset: 0,
      start_date: `${formatDateFn(calendarDate[0].startDate)}`,
      end_date: `${formatDateFn(calendarDate[0].endDate)}`,
      filtering: encodeData(finalFiltering),
    };
    // const recieveData = await onRowExpandHandler(sentIndex,tableDataToShow,childRowData);
    // setTableDataToShow([...recieveData]);
    let currentTableStateCopy = JSON.parse(JSON.stringify(tableDataToShow));
    const selectedAd = currentTableStateCopy.filter(
      (ad) => ad?.asin === recievedAsin
    )[0];
    if (selectedAd?.childRows.length > 0) {
      currentTableStateCopy = currentTableStateCopy.map((ad) => {
        if (ad?.asin === recievedAsin) {
          ad.isPlusIconDisabled = !ad?.isPlusIconDisabled;
        }
        return ad;
      });
    } else {
      const childRowsProducts = await masterApi(
        childRowData,
        "masterAds",
        false
      );
      if (childRowsProducts.statusCode !== 200) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: `Unknown Error Occured with statusCode ${childRowsProducts?.statusCode}`,
        // });
        toast.error(`Unknown Error Occured with statusCode ${childRowsProducts?.statusCode}`)
      }
      const updatedChildRowsData = childRowsProducts?.data?.data?.map(
        (childAd) => {
          childAd.isTargetChecked = false;
          return childAd;
        }
      );
      currentTableStateCopy = currentTableStateCopy =
        currentTableStateCopy?.map((ad) => {
          if (ad?.asin === recievedAsin) {
            ad.isPlusIconDisabled = false;
            ad.childRows = updatedChildRowsData;
          }
          return ad;
        });
    }
    setAllLoaders({ ...allLoaders, childRowLoader: false });
    setTableDataToShow(currentTableStateCopy);
  };

  const getUpdatedCount = (currentState,isGlobalView) => {
    let updatedCount = [];
    currentState?.forEach((Ad) => {
      if(isGlobalView) {
        Ad?.childRows?.forEach((childAd) => {
          if (childAd.isTargetChecked) {
            updatedCount.push(childAd);
          }
        });
      }
      if(!isGlobalView && Ad.isTargetChecked) { 
        updatedCount.push(Ad);
      }
    });
    return updatedCount;
  };

  const removeUpdatedCount = () => {
    setIsAllSelected(false)
    const currentState = JSON.parse(JSON.stringify(tableDataToShow));
    let updatedCount = currentState.map((ad) => {
      if(isGlobalView) {
        ad.childRows?.map((childAd) => {
          if (childAd.isTargetChecked) {
            childAd.isTargetChecked = false;
          }
          return childAd;
        });
      }

      if(!isGlobalView) {
        ad.isTargetChecked = false
      }
    
      return ad;
    });
    setTableDataToShow([...updatedCount]);
    setSelectedAds([]);
  };

  const noclickhandler = () => {
    setAllFilterVisible({ ...allFiltersVisible, ConfirmAlertModal: false });
  };
  const yesclickhandler = () => {
    setAllFilterVisible({ ...allFiltersVisible, ConfirmAlertModal: false });
  };

  const handleScroll = async () => {
    const container = tableRef.current;
    let { currentPage, currentOffSet } = paginationAction;
    // Keep track of last scroll position
    const lastScrollTop = container.getAttribute("data-scroll-top") || 0;

    // Determine scroll direction
    const scrollDirection = container.scrollTop > lastScrollTop ? "down" : "up";

    // Update last scroll position
    container.setAttribute("data-scroll-top", container.scrollTop);
    if (
      scrollDirection === "down" &&
      Math.ceil(container.scrollTop + container.clientHeight) >=
      container.scrollHeight - 10
      && tableDataToShow?.length >= 50
    ) {
      currentOffSet = currentOffSet + limit;
      currentPage = currentOffSet / limit + 1;
      if (!lazyLoading) {
        const moreData = await loadMoreData(currentOffSet);
        const returnedData = [...modifyData(moreData)];
        setTableDataToShow((prev) => [...prev,...returnedData]);
        setPaginationAction({
          ...paginationAction,
          currentPage,
          currentOffSet,
        });
      }
    }
  };

  const onYesClick = async () => {
    const status = actionAlertModalText.includes("Pause")
      ? "paused"
      : "enabled";
    const statusToSent = status.toUpperCase();
    const adsToBeUpdated = selectedAds?.map((data) => {
      return { id: data?.id, status };
    })?.filter((data) => data?.id);
    const mainResult = await updateBulkAds(adsToBeUpdated);
    if (mainResult?.statusCode !== 200) {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_DANGER,
      //   title: "Error",
      //   description: `Unknown Error Occurred with statusCode${mainResult?.statusCode}`,
      // });
      toast.error(`Unknown Error Occurred with statusCode${mainResult?.statusCode}`)
    } else {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_SUCCESS,
      //   title: "Success",
      //   description: `Selected Ads has been ${status} Successfully `,
      // });
      toast.success(`Selected Ads has been ${status} Successfully `)
      const updatedState = enablePauseBulkChildAd(
        tableDataToShow,
        statusToSent,
        isGlobalView
      );
      setTableDataToShow([...updatedState]);
    }
  };

  const updateBulkAds = async (dataForUpdate) => {
    try {
      setAllLoaders({ ...allLoaders, mainLoader: true });
      const url = `https://adyogiadsapi.adyogi.com/client/${clientId}:update-bulk-ads-status?source=amazon`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataForUpdate),
      };
      const response = await fetch(url, options);
      setAllLoaders({ ...allLoaders, mainLoader: false });
      return { statusCode: response.status };
    } catch (error) {
      console.log(error);
    }
  };

  const enablePauseSingleAd = async (recievedAd) => {
    const { status, id } = recievedAd;
    if(id) {
      const statusToBeUpdate = status?.toUpperCase() === "ENABLED" ? "paused" : "enabled";
      const dataForUpdate = [{ status: statusToBeUpdate, id }];
      const mainResult = await updateBulkAds(dataForUpdate);
      if (mainResult?.statusCode !== 200) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: `Unknown Error Occurred with statusCode${mainResult?.statusCode}`,
        // });
        toast.error(`Unknown Error Occurred with statusCode${mainResult?.statusCode}`)
      } else {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_SUCCESS,
        //   title: "Success",
        //   description: `Ad has been ${statusToBeUpdate} Successfully `,
        // });
        toast.success(`Ad has been ${statusToBeUpdate} Successfully `)
        const updatedState = enablePauseSingleChildAd(
          tableDataToShow,
          id,
          statusToBeUpdate.toUpperCase(),
          isGlobalView
        );
        setTableDataToShow([...updatedState]);
      }
    }
   
  };

  const redirectToCampaignList = (campName) => {
    if (campName) {
      window.open(
        `/campaign-list?campName=${campName}`,
        "_blank",
        "noreferrer"
      );
    }
  }

  const handleClick = event => {
    setIsBulkUploadOptionOpen(!isBulkUploadOptionOpen)
    hiddenFileInput.current.click();
  };

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        let asinValues = results.data.map((data, index) => {
          return Object.values(data)[0]
        });
        setSearchValues(asinValues.toString())
      },
    });
  };

  const redirectToAdgroupName = (childData) => {
    const { campaign_id, ad_group_id, ad_group_name, campaign_name, asin } = childData
    const campaignIdAlphanumeric = searchFiltersState.campaigns.find((campaign) => parseInt(campaign.platform_id) === campaign_id);
    const adgroupIdAlphanumeric = searchFiltersState.adGroups.find((adgroup) => parseInt(adgroup?.platform_id) === ad_group_id)
    if (campaignIdAlphanumeric && adgroupIdAlphanumeric) {
      window.open(
        `/edit-adset-targeting?campaignId=${campaignIdAlphanumeric?.id}&adsetId=${adgroupIdAlphanumeric?.id}&numericCampaignId=${campaign_id}&numericAdsetId=${ad_group_id}&name=${ad_group_name}&adsetPlatformId=${ad_group_id}&campName=${campaign_name}&editadspage=${true}&asin=${asin}`,
        "_blank",
        "noreferrer"
      );
    }
  }
  const redirectToAmazon = (asin) => {
    window.open(` https://www.amazon.in/dp/${asin.toUpperCase()}`);
  };

  function getStatusWiseValue(status, val) {
    return val === 0 || val === "" ? (status?.toUpperCase() === 'ENABLED' ? 0 : '-') : val;
  }

  const bindParentValueColumns = (parentAd={}, currentDataHead) => {
    const value = parentAd[currentDataHead?.id] ?? '';

    if (value) {
      if (typeof value === "number" && metricesWithNoDecimal.includes(currentDataHead?.id)) {
        return (value === 0 || value === "") ? getStatusWiseValue(parentAd?.status, value) : Math.round(value);
      } else if (typeof value === "number" && metricesWithTwoDecimal.includes(currentDataHead?.id)) {
        return (value === 0 || value === "") ? getStatusWiseValue(parentAd?.status, value) : value.toFixed(2);
      } else {
        return (value === 0 || value === "") ? getStatusWiseValue(parentAd?.status, value) : value;
      }
    } else {
      return getStatusWiseValue(parentAd?.status, 0);
    }
  }

  const bindChildValueColumns = (parentAd, childAd, currentDataHead) => {
    const childRowsLength = parentAd?.childRows?.length || !isGlobalView
    const childAdValue = childAd[currentDataHead?.id];

    if (childRowsLength && childAdValue) {
      if (typeof childAdValue === "number" && metricesWithNoDecimal.includes(currentDataHead?.id)) {
        return (childAdValue === 0 || childAdValue === "") ? getStatusWiseValue(childAd.status, childAdValue) : Math.round(childAdValue);
      } else if (typeof childAdValue === "number" && metricesWithTwoDecimal.includes(currentDataHead?.id)) {
        return (childAdValue === 0 || childAdValue === "") ? getStatusWiseValue(childAd.status, childAdValue) : childAdValue.toFixed(2);
      } else {
        return (childAdValue === 0 || childAdValue === "") ? getStatusWiseValue(childAd.status, childAdValue) : childAdValue;
      }
    } else {
      return getStatusWiseValue(childAd.status, 0)
    }
  }

  const [selectedChildRows, setSelectedChildRows] = useState([]);
  const handleCellClick = (rowIndex) => {
    setSelectedChildRows((prevSelectedRows) =>
      prevSelectedRows.includes(rowIndex)
        ? prevSelectedRows.filter((row) => row !== rowIndex)
        : [...prevSelectedRows, rowIndex]
    );
  };

  const onNullHandler = () => {
    const fileUploader = document.querySelector("#hiddenFileUploader")
    fileUploader.value = null
  }

  const renderChildRow = (childAd, mainIndex, parentAd, currentDataHead, childIndex) => {
    return (
      <tr
      key={mainIndex + Math.random() * 213}
      onClick={() => handleCellClick(mainIndex + "subrow")}
      className={parentAd.isPlusIconDisabled ? "hide"
          : selectedChildRows.includes(mainIndex + "subrow")
          ? `${isGlobalView ? 'ad-campaign-details' : ''} cursor-pointer highlight`
          : `${isGlobalView ? 'ad-campaign-details' : ''} cursor-pointer`
      }
      >
      <td className="ads-td-first">
        <div className="classlist-switch-btn child-switch-btn-container"  onClick={() => handleCellClick(mainIndex + "subrow")}>
          {
            <SwitchBtn
              checked={
                childAd?.status?.toUpperCase() === "ENABLED"
                  ? true
                  : false
              }
              disabled={!childAd?.id}
              title={!childAd?.id ? "Ad is not back-synced" : ""}
              onChange={() => {
                enablePauseSingleAd(childAd);
              }}
            />
          }
        </div>
      </td>
      <td className="ads-td-second">
        <div className="main-child-table-product-containerr d-flex">
          <div className="checkbox-container child-checkbox-containet-master-ad"  onClick={() => handleCellClick(mainIndex + "subrow")}>
            <CheckboxRb
              className="targeting-checkbox"
              disabled={isNonAdvertisingActive?false:!childAd?.id}
              title={!childAd?.id ? "Ad is not back-synced" : ""}
              onChange={(e) =>
                onChildRowBoxHandler(
                  parentAd?.asin ?? childAd?.asin,
                  mainIndex,
                  e
                )
              }
              checked={
                childAd.isTargetChecked ? true : false
              }
            ></CheckboxRb>
          </div>
          <ProductNameTd
            asin={childAd?.asin ?? "-"}
            product_name={childAd?.product_name}
            image_url={childAd?.imageurl}
            dynamicWidth="product-name-dyanamic-width-foe-master-ad"
          />
          </div>
        </td>
        <td>
          <span
            className="cursor-pointer-global adgroup-clickable-text-color"
            onClick={() => redirectToAmazon(childAd?.asin)}
          >
            {childAd?.asin ?? "-"}
          </span>
        </td>
        <td>{childAd?.portfolio_name ?? "-"}</td>
        <td>
          <div className="chid-row-campaign cursor-pointer-global adgroup-clickable-text-color" onClick={() => redirectToCampaignList(childAd?.campaign_name)}>
            {childAd?.campaign_name ?? "-"}
          </div>
        </td>
        <td>
          <div className="chid-row-campaign cursor-pointer-global adgroup-clickable-text-color" onClick={() => redirectToAdgroupName(childAd)}>
            {childAd?.ad_group_name ?? "-"}
          </div>{" "}
        </td>
        <td>{childAd?.category ?? "-"}</td>
        {dataHeads?.map((currentDataHead, i) => {
          if (!currentDataHead.isDefaultHead) {
            return (
              <td
                key={i + 13}
                className="stats-data-col"
              >
                {bindChildValueColumns(parentAd, childAd, currentDataHead)}
              </td>
            );
          }
        })}
      </tr>
    );
  }

  const renderChildRows = (parentAd, currentDataHead, childAd, childIndex) => { 
   const childRows = parentAd["childRows"]?.length ? parentAd["childRows"].map((childAd, mainIndex) => {
        return renderChildRow(childAd, mainIndex, parentAd, currentDataHead, childIndex)
    }) : []
    return childRows
  }

  const renderParentRow = (parentAd, paIndex) => { 
    const childRows = renderChildRows(parentAd,dataHeads)
    return (
      <React.Fragment key={parentAd?.asin}>
        <tr  onClick={() => handleCellClick(paIndex + "mainrow")}
          className={
            selectedChildRows.includes(paIndex + "mainrow")
              ? "cursor-pointer highlight"
              : "cursor-pointer"
          }>
          <td className="first-column-sw-btn ads-td-first">
            <div className="classlist-switch-btn">
              {
                // <SwitchBtn
                //   checked={
                //     parentAd?.status === "ENABLED" ? true : false
                //   }
                //   onChange={() => console.log("Yes")}
                // ></SwitchBtn>
                <div className="mt-status-dot-container">
                  <div
                    className={
                      parentAd?.status === "enabled"
                        ? "enabled-status-dots-mt"
                        : "paused-status-dots-mt"
                    }
                  ></div>
                </div>
              }
            </div>
          </td>
          <td className="ads-td-second">
            <div className="main-parent-table-product-container d-flex  justify-content-between align-item-center" >
              <div className="d-flex">
                <div className="checkbox-container" onClick={() => handleCellClick(paIndex + "mainrow")}>
                  <CheckboxRb
                    className="targeting-checkbox"
                    onChange={() =>
                      onParentRowCheckBoxHandler(parentAd?.asin)
                    }
                    checked={parentAd.isTargetChecked}
                  ></CheckboxRb>
                </div>
                <ProductNameTd
                  product_name={parentAd?.product_name}
                  asin={parentAd?.asin}
                  image_url={parentAd?.imageurl}
                  dynamicWidth="product-name-dyanamic-width-foe-master-ad"
                />
              </div>
              <div
                className={
                  parentAd.isPlusIconDisabled && !isGlobalView
                    ? "plus-icon-sec-container d-flex align-items-center d-none"
                    : "plus-icon-sec-container-when-clicked d-flex align-items-center"
                }
              >
                <div className="product-child-row-button cursor-pointer-global d-flex justify-content-end">
                  <div className="td-with-loader d-flex align-items-center" onClick={() => handleCellClick(paIndex + "mainrow")}>
                    {allLoaders.childRowLoader &&
                      currentAsin === parentAd?.asin ? (
                      <Spinner
                        shimmer={true}
                        shimmerShape={"box"}
                      />
                    ) : (
                      <img
                        src={
                          parentAd.isPlusIconDisabled
                            ? AddIcon
                            : MinusIcon
                        }
                        alt=""
                        onClick={() => {
                          expandRow(parentAd?.asin);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td>
            <span
              className="cursor-pointer-global adgroup-clickable-text-color"
              onClick={() => redirectToAmazon(parentAd?.asin)}
            >
              {parentAd?.asin}
            </span>
          </td>
          <td className="portfolioContainer">
            {parentAd?.portfolio ?? "-"}
          </td>
          <td>
            <div className="d-flex flex-row  ">
              <div className="campaign-count-circle ">
                {parentAd?.campaign_count ?? "-"}
              </div>
              <span>{"Campaigns"}</span>
            </div>
          </td>
          <td>
            <div className="d-flex flex-row  ">
              <div className="campaign-count-circle">
                {parentAd?.adgroup_count ?? "-"}
              </div>
              <span>{"Ad Groups"}</span>
            </div>
          </td>
          <td>
            <div className="d-flex flex-row  ">
              {/* <div className="campaign-count-circle me-3">
              {parentAd?.category ?? "-"}
            </div> */}
              <span className="text-start">
                {parentAd?.category ?? "-"}
              </span>
            </div>
          </td>
          {dataHeads?.map((currentDataHead, i) => {
            if (!currentDataHead.isDefaultHead) {
              return (
                <td key={i + 11} className="stats-data-col">
                  {bindParentValueColumns(parentAd, currentDataHead)}
                </td>
              );
            }
          })}
        </tr>
        {allLoaders.childRowLoader &&
          currentAsin === parentAd.asin ? (
          <>
            {Array.from(Array(3)).map((dumyEl) => {
              return (
                <tr>
                  {dataHeads.map((el) => {
                    return (
                      <td className="ma-loader">
                        {/* <div className="ma-loader"> */}
                        <div className="td-with-loader">
                          <Spinner shimmer={true} />
                          {/* </div> */}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </>
        ) : ( 
          (parentAd["childRows"]?.length && !parentAd?.isPlusIconDisabled) ? <TableRowWithPagination rows={childRows} limit={20} colSpanLength={dataHeads?.length ?? 0}/>: null
        )}
      </React.Fragment>
    );
  }

 const rows = tableDataToShow?.map((ad, paIndex) => {
    return isGlobalView ?  renderParentRow(ad,paIndex) : renderChildRow(ad,paIndex,{})
  })

  const frontEndPaginationLimit = 50;
  const indexOfLastRow = currentPage * frontEndPaginationLimit;
  const indexOfFirstRow = indexOfLastRow - frontEndPaginationLimit;
  const currentRows = rows?.slice(indexOfFirstRow, indexOfLastRow) ?? []
  const count = selectedBtn === 'all-btn' ? overAllAdsCount?.total_count : selectedBtn === "enabled-btn" ? overAllAdsCount?.enabled_count : overAllAdsCount?.paused_count
  const {isNext,isPrev,totalPages} = getPaginationInfo(count ?? 0,frontEndPaginationLimit,currentPage)
  // const {totalPages:totalPagesMain} = getPaginationInfo(overAllAdsCount?.total_count ?? 0,frontEndPaginationLimit,currentPage)
  function getPaginationInfo(totalNumber, limit, pageNumber) {
    // Calculate total number of pages
    const totalPages = Math.ceil(totalNumber / limit);
    // Determine if there's a next page
    const isNext = pageNumber < totalPages;
    // Determine if there's a previous page
    const isPrev = pageNumber > 1;
    return {
        isNext: isNext,
        isPrev: isPrev,
        totalPages: totalPages
    };
}
  const handlePagination = async (type) => {
    if (type === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (
      type === "next" &&
      currentPage < Math.ceil(overAllAdsCount?.total_count / frontEndPaginationLimit)
    ) {
      const temproraryCurrentPage = currentPage + 1;
      const tempLastIndex = temproraryCurrentPage * frontEndPaginationLimit;
      const tempFirstIndex = tempLastIndex - frontEndPaginationLimit;
      const nextRows = rows?.slice(tempFirstIndex, tempLastIndex) ?? []
      if(nextRows?.length === 0 && temproraryCurrentPage < totalPages) {
        const currentOffset = paginationOffset + limit
        const moreData = await loadMoreData(currentOffset);
        let returnedData = []
        if(tableDataToShow?.every((data) => data?.isTargetChecked)) {
           returnedData = [...modifyData(moreData,true)];
        } else {
          returnedData = [...modifyData(moreData)]
        }
        setTableDataToShow((prev) =>  [...prev,...returnedData]);
        setPaginationOffset(currentOffset)
      }
      setCurrentPage(temproraryCurrentPage);
      tableRef.current.scrollTop = 0
    }
  };

 

const renderSummaryRow = (data) => {
  return (
    // Adding Blank Td for empty cells
    <tr key={'summaryRow'} className="summaryrow-master-ads">
      <td className="first-column-sw-btn ads-td-first">
                  {'Summary'}
      </td>
      <td className="ads-td-second">
       {'-'}
      </td>
      <td >
       {'-'}
      </td>
      <td >
       {'-'}
      </td>
      <td >
       {'-'}
      </td>
      <td >
       {'-'}
      </td>
      <td >
       {'-'}
      </td>
      {dataHeads?.map((currentDataHead, i) => {
            if (!currentDataHead.isDefaultHead) {
              return (
                <td key={i + 11} className="stats-data-col">
                  {bindParentValueColumns(data, currentDataHead)}
                </td>
              );
            }
          })}
    </tr>
  )
}

const summaryRow = renderSummaryRow(summaryData)

  let tableBody = (
    <>
      <div
        className={ lazyLoading ? "main-campaignlist-table-body-container master-ads-main-table-wrapper" :"main-campaignlist-table-body-container master-ads-main-table-wrapper"}
        ref={tableRef}
      >
        {tableDataToShow?.length ? (
          <>
            <table className="master-ads-table reports">
              <thead className="fixed-table">
                <tr className="main-table-row-fixed">
                  {dataHeads?.map((th, thIndex) => {
                    // th means tableHead
                    return (
                      <th
                        key={th.fieldName + thIndex + "12"}
                        className={
                          (th.id === sortBy.sort_head) ||
                            (isCampaignFilterActive && th.fieldName === "Campaign") ||
                            (isPortfolioFilterActive && th.fieldName === "Portfolio") ||
                            (adGroupFilterActive && th.fieldName === "Ad Group") ||
                            (categoryFilterActive && th.fieldName === "Category")
                            ? "targeting-table-heads selected text-nowrap"
                            : "targeting-table-heads text-nowrap"
                          // th.isSortFunctionalityNeededHere ? "stats-heads targeting-table-heads"
                          // : "targeting-table-heads"
                          // stats-heads
                        }
                      >
                        {th.isSortFunctionalityNeededHere ? (
                          <div className="d-flex flex-row align-items-center justify-content-end ">
                            <div className="d-flex align-items-center">
                              <span
                                className={
                                  th.id === sortBy.sort_head
                                    ? "stat-heads-with-icon active-filter-higlighted-text"
                                    : "stat-heads-with-icon"
                                }
                              >
                                <span
                                  className={
                                    upperCaseValues?.includes(
                                      th.fieldName.toLowerCase()
                                    )
                                      ? "text-uppercase"
                                      : "text-capitalize"
                                  }
                                >
                                  {th.fieldName === "Campaign"
                                    ? "Campaigns"
                                    : !upperCaseValues?.includes(
                                      th.fieldName.toLowerCase()
                                    )
                                      ? th.fieldName.toLowerCase()
                                      : th.fieldName === "aov"
                                        ? "AOV"
                                        : th.fieldName}
                                </span>
                              </span>
                            </div>
                            <span className="sort-icon cursor-pointer-global">
                              <img
                                className={
                                  th.id === sortBy.sort_head
                                    ? " d-flex align-items-end mt-spends-icon-activated "
                                    : " d-flex align-items-end"
                                }
                                src={
                                  !th.isSortingClicked
                                    ? descendingIconSvg
                                    : ascendingIconSvg
                                }
                                onClick={() =>
                                  onSortHandler(
                                    tableDataToShow,
                                    th.isSortingClicked,
                                    th.id,
                                    "masterAds",
                                    thIndex
                                  )
                                }
                                alt={"HeadsWithSorting"}
                              ></img>
                            </span>
                          </div>
                        ) : th.fieldName === "Product" ? (
                          <>
                            <div className="targeting-selected-head">
                              <span className="">
                                <div className="targeting-checkbox d-flex justify-content-start">
                                  <CheckboxRb
                                    className="check-colum-head"
                                    disabled={isGlobalView}
                                    checked={isAllSelected}
                                    onChange={(e) => onAllAdSelection(e)}
                                    label={th.fieldName}
                                  // checked={allRowsSelected}
                                  ></CheckboxRb>
                                </div>
                              </span>
                              {/* <span className="stat-heads-with-icon">{th.fieldName}</span> */}
                            </div>
                          </>
                        ) : th.fieldName === "Portfolio" ||
                          th.fieldName === "Campaign" ||
                          th.fieldName === "Category" ||
                          th.fieldName === "Ad Group" ? (
                          <>
                            <div
                              className={`fieldnamme-with-search-iconn justify-content-start`}
                            >
                              <span className="d-flex align-items-center me-2">
                                <img
                                  // className="find-icon cursor-pointer-global"
                                  className={
                                    (isCampaignFilterActive &&
                                      th.fieldName === "Campaign") ||
                                      (isPortfolioFilterActive &&
                                        th.fieldName === "Portfolio") ||
                                      (adGroupFilterActive &&
                                        th.fieldName === "Ad Group") ||
                                      (categoryFilterActive &&
                                        th.fieldName === "Category")
                                      ? "find-icon cursor-pointer-global mt-find-icon-activated"
                                      : "find-icon cursor-pointer-global"
                                  }
                                  src={whiteFindIcon}
                                  onClick={() =>
                                    onSearchModalHandler(th.fieldName)
                                  }
                                  alt={"whiteFindIcon"}
                                ></img>
                              </span>
                              <div className="d-flex align-items-center test">
                                <span
                                  className={
                                    (isCampaignFilterActive &&
                                      th.fieldName === "Campaign") ||
                                      (isPortfolioFilterActive &&
                                        th.fieldName === "Portfolio") ||
                                      (adGroupFilterActive &&
                                        th.fieldName === "Ad Group") ||
                                      (categoryFilterActive &&
                                        th.fieldName === "Category")
                                      ? "active-filter-higlighted-text"
                                      : "stat-heads-with-icon "
                                  }
                                >
                                  {th.fieldName === "Campaign"
                                    ? "Campaigns"
                                    : th.fieldName}
                                </span>
                              </div>
                              {columnName === th.fieldName &&
                                allFiltersVisible.searchSortModal && (
                                  <MasterTargetingSearchSelectModal
                                    findClickedColumnName={columnName}
                                    showLimitedOptions={true}
                                    limitedOptionsLimit={100}
                                    keyToBeSet={
                                      th.fieldName === "Campaign"
                                        ? "campaign_id"
                                        : th.fieldName === "Ad Group"
                                          ? "adgroup_id"
                                          : th.fieldName === "Portfolio"
                                            ? "portfolio_id"
                                            : "category"
                                    }
                                    getUpdatedDrowDownFilters={
                                      getUpdatedDrowDownFilters
                                    }
                                    isVisible={
                                      allFiltersVisible.searchSortModal
                                    }
                                    initialData={
                                      th.fieldName === "Campaign"
                                        ? searchFiltersState.campaigns
                                        : th.fieldName === "Ad Group"
                                          ? searchFiltersState.adGroups
                                          : th.fieldName === "Portfolio"
                                            ? searchFiltersState.portfolios
                                            : categories
                                    }
                                    cancelFilter={setAllFilterVisible}
                                    currentFilters={allFiltersVisible}
                                  />
                                )}
                            </div>
                          </>
                        ) : (
                          <>
                            <span>
                              {th.fieldName === "ACTION"
                                ? "Action"
                                : th.fieldName}
                            </span>
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              {tableDataToShow.length ? (
                <tbody className="another-body">
                  {summaryRow}
                  {currentRows}
                </tbody>
              ) : 
                
                  !allLoaders.mainLoader  &&  <p>No Data Found</p>
                
               
              }
            </table>
          </>
        ) : (
          <>
         { (!allLoaders?.mainLoader && !tableDataToShow?.length) &&  <p>NO Data Found</p>}
          </>
        )}
      </div>
    </>
  );

  async function handleDownloadReport() { 
    try {
      const filteredData = mapValues(filters, "platform_id");
      const filtering = generateFiltering(filteredData);
      let mainFiltering = filtering.concat(statFilters);
      //hack now for filtering will fix this in tech debt task
      mainFiltering.forEach(function (value, i) {
        if (value.field_name === 'asin') {
          if (mainFiltering[i].value.length > 0) {
            let object = {}
            object.field_name = "asin"
            object.operator = "IN"
            object.values = mainFiltering[i].value
            mainFiltering = []
            mainFiltering.push(object)
          } else {
            mainFiltering = []
          }
        }
      });
      let filteringAfterDuplicateRemoval = mainFiltering
      if (mainFiltering?.length === 0 || mainFiltering.length === 1) {
        // Removing Filtering Which has key as value and array // Removing array of asins in with EQUALS OPERATOR
        filteringAfterDuplicateRemoval = [...mainFiltering.concat(filtering).concat(statFilters)].filter((filters) => {
            return !Array.isArray(filters?.value)
        })
      }
      const finalFiltering = removeDuplicateFiltering(filteringAfterDuplicateRemoval)
      const downloadAPI =  `${process.env.REACT_APP_ADS_API}/client/${clientId}:download-amazon-master-ads?source=amazon&master_row=${isGlobalView}&order_by=${sortBy?.sort_head}&limit=${5000}&offset=0&start_date=${formatDateFn(calendarDate[0].startDate)}&end_date=${formatDateFn(calendarDate[0].endDate)}&sorting_order=${sortBy.order}&filtering=${encodeData(finalFiltering)}`
      window.location.href = downloadAPI
    } catch (error) {
      toast.error(`Unknown Error Occurred with statusCode${error?.statusCode}`)
      console.log(error)
    }
  }

  const handlePopulateAdsModal =() => {
    setAllFilterVisible({...allFiltersVisible,populateAds:!allFiltersVisible?.populateAds})
  }

  
  function removeDuplicateFiltering(array) {
    const seen = new Set();
    return array?.filter(item => {
      const keyValue = JSON.stringify(item);
      if (seen?.has(keyValue)) {
        return false;
      } else {
        seen?.add(keyValue);
        return true;
      }
    }) ?? [];
  }


  function removeDuplicates(array, key) {
    const seen = new Set();
    return array.filter(item => {
      const keyValue = item[key];
      if (seen.has(keyValue)) {
        return false;
      } else {
        seen.add(keyValue);
        return true;
      }
    });
  }

  const populateAdsHandler = async (details) => {
    setInitialLoad(true);
    setAllFilterVisible({...allFiltersVisible,populateAds:false})
    const {data:campaignsandadgroups} = details;
    const API_PAYLOAD_ARRAY = [];
    // Only Unique ASINs
    const filteredSelectedAds = removeDuplicates(selectedAds,'asin')
    filteredSelectedAds.forEach((ad) => {
      campaignsandadgroups?.forEach((data) => {
        const {id,campaign_id,type} = data;
        const API_PAYLOAD_OBJ = {
          account_id:accountId,
          client_id:clientId,
          campaign_id:campaign_id,
          adset_id:id,
          source:"amazon",
          status:"ENABLED",
          type:type,
          asin:ad?.asin?.toUpperCase(),
          ...(ad?.sku && {sku:ad?.sku})
        }
        API_PAYLOAD_ARRAY.push(API_PAYLOAD_OBJ)
      })
    })

    const response = await masterApi(API_PAYLOAD_ARRAY,'populateAdsInBulk',false,'post')
    if(response?.statusCode!==200) {
      toast.error(`Unknown Error Occurred with statusCode${response?.statusCode}`)
    }

    if(response?.statusCode===200) {
      toast.success(`Selected Ads has been Populated Successfully into choosen campaigns and adgroups`)
    }

    setInitialLoad(false);
  }

  const disableEnablePauseBtnClass = isNonAdvertisingActive ? 'disable-pointer-events' : ''
  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box-master-ads" onClick={handleClickOutside}
    >
        <ReactTooltip 
            id="download-tooltip"
            style={{ zIndex: "999999", fontSize: "12px", padding: "8px" }}
            effect="solid"
            place="top"
            variant="dark"
        />
      <Breadcrumb />
      <div className="campaign-list-container">
        <Loader visible={initialLoad} />
        <div>
          <FilterLogicModal
            show={allFiltersVisible?.mainFilterLogicModal}
            filterFor={"master-ads-list"}
            cancelSpecifiNumberFilter={cancelSpecifiNumberFilter}
          />
        </div>

     {allFiltersVisible?.populateAds &&  <PopulateProductAds
              show={allFiltersVisible?.populateAds}
              onYesClick={populateAdsHandler}
              defaultSelectedCampaigns={[]}
              optionsForCampaigns={searchFiltersState?.campaigns}
              optionsForAdgroups={searchFiltersState?.adGroups}
              optionsForPortfolios={searchFiltersState?.portfolios}
              defaultSelectedPortfolios={[]}
              showMatchType={[]}
              confirmationId={"add-to-negative-targeting-confirm"}
              onNoClick={handlePopulateAdsModal}
            />}
        <div>
          <CSVUploadModal
            show={allFiltersVisible?.csvUploadModal}
            filterFor={"master-ads-list"}
            confirmationId={'bulk-enable-pause-csv-confirm'}
            cancelSpecifiNumberFilter={cancelSpecifiNumberFilter}
          />
        </div>

        <div>
          {actionAlertModalText !== "" &&
            <MasterTagetinngActionAlertMModal
              alertText={actionAlertModalText}
              noclickhandler={noclickhandler}
              yesclickhandler={yesclickhandler}
              onYesClick={onYesClick}
              confirmationId={confirmationId}
            />
          }
        </div>
        <div
          className={
            selectedAds.length ? "et-filter-options-for-master-ads alignment-top-master-ads" : "hide"
          }
        >
          <button className="selected-row-count mx-2">
            <img
              className="dash-icon"
              src={MinusIcon}
              alt={"selectedIcon"}
              onClick={removeUpdatedCount}
            ></img>
            <span className="selected-text-with-count">
              {selectedAds && `${selectedAds?.length}  Selected`}
            </span>
          </button>
          <CampaignListTab
            onClickHandler={() => handlePopulateAdsModal()}
            imageSrc={uploadIcon}
            imageText={"Populate"}
          />
          <CampaignListTab
            onClickHandler={() => enablePauseHandler("Enable")}
            classes={disableEnablePauseBtnClass}
            imageSrc={pauseIcon}
            imageText={"Enable"}
          />
          <CampaignListTab
            onClickHandler={() => enablePauseHandler("Pause")}
            imageSrc={resumeIcon}
            classes={disableEnablePauseBtnClass + ' mad-tab-border'}
            imageText={"Pause"}
          />
        </div>
        <Stack>
          <Stack direction="horizontal" className="d-flex align-item-center justify-content-between">
            <SectionTitle sectionTitleHeading={"Product Ads"} />
            <button
                  className={
                    `primary-inactive-btn apply-filter d-flex align-items-center optimizer-btn-icon`
                  }
                  data-tooltip-content={`${ "Download Report (Max 5000 Ads)"}`}
                  data-tooltip-id="download-tooltip"
                  id="download-report-btn"
                  onClick={handleDownloadReport}
                >
                  <div className="d-flex gap-2 align-items-center">
                    <FaDownload height={40} />
                  </div>
                </button>
          </Stack>
          <div className="mt-config-row bottom-margin">
            <Stack className="mt-configs-btn-container d-flex flex-row">
              <Stack
                direction="horizontal"
                className="list-pages-search-input-container mb-1"
              >
                <Stack
                  direction="vertical"
                  className="justify-content-end align-item-end"
                >
                  <SearchBarForCampaignListOnly
                    placeholder="Search for Ads"
                    value={searchValues}
                    // onClickCancel={cancelIconInSearchInput}
                    searchBarForListPage={true}
                    // onChange={(e) => setSearchValues(e.target.value)}
                    onChange={(e) => inputHandler(e.target.value)}
                    onKeyUp={onAdsSearchHandler}
                    isCancelIconNotVisible={true}
                    // onCancelIconClick={cancelIconInSearchInput}
                    // onKeyDown={onSearchBarEnterClicked}
                    id="ma-global-targeting-list"
                    className="mt-custom-search-bar"
                    dynamicWidth="search-input-width-for-listing-pages"
                    defaultValue={
                      asinIdLinkFromProductReport
                        ? asinIdLinkFromProductReport
                        : ""}
                  ></SearchBarForCampaignListOnly>
                </Stack>
              </Stack>
              <Stack
                direction="horizontal"
                className="d-flex justify-content-center flex-wrap"
              >
                
             <Stack
                  className="mt-filter-gap mb-1 mt-filter-gap mb-1"
                  direction="horizontal"
                >
                  <CustomDropDown
                    title={"View Type"}
                    data={["Global", "Granular"]}
                    filterCampaigns={onViewChange}
                    targettingValue={viewType}
                    type={"viewType"}
                    src={dropdownIcon}
                  // data
                  />

                </Stack>
                <Stack
                  className="mt-filter-gap mb-1 mt-filter-gap mb-1"
                  direction="horizontal"
                >
                  <CustomDropDown
                    title={"Campaign Objective"}
                    data={["All", "Prospect","Retarget"]}
                    filterCampaigns={filterCampaigns}
                    targettingValue={filters.campaign_objective}
                    type={"campaign_objective"}
                    src={dropdownIcon}
                  // data
                  />

                </Stack>
                <Stack
                  className="mt-filter-gap mb-1 mt-filter-gap mb-1"
                  direction="horizontal"
                >
                  <CustomDropDown
                    title={"Campaign Type"}
                    data={["All", "SP", "SD"]}
                    filterCampaigns={filterCampaigns}
                    targettingValue={filters.campaign_type}
                    type={"campaign_type"}
                    src={dropdownIcon}
                  // data
                  />

                </Stack>
              {!isGlobalView ?  <Stack
                  className="mt-filter-gap mb-1 mt-filter-gap mb-1"
                  direction="horizontal"
                >
                  <CustomDropDown
                    title={"Asin Type"}
                    data={["All","Advertised", "Non Advertised"]}
                    filterCampaigns={filterCampaigns}
                    targettingValue={filters.ad_id}
                    type={"ad_id"}
                    src={dropdownIcon}
                  // data
                  />

                </Stack> : null}
                <Stack
                  className="master-ads-calendar-container mb-1"
                  direction="horizontal"
                >
                  <Stack className="d-flex justify-content-end align-item-end d-none">
                    <button
                      className="date-container date-btn-campaignlist"
                      onClick={dateInputClickHandler}
                    >
                      <div className="d-flex justify-conntent-center">
                        <span className="date-btn-text">{dateDifference}</span>
                      </div>
                      <div className="btn-calender-img-holder">
                        <span className="">
                          <img
                            className="dropdown-img-for-select"
                            src={selectSideIcon}
                            alt={"SelecteSideIcon"}
                          ></img>

                          <img
                            className="calender-icon-for-campaignlist"
                            src={CalenderIconForCampaignList}
                            alt={"CalendarIconForCampaignList"}
                          ></img>
                        </span>
                      </div>
                    </button>
                  </Stack>
                  <div
                    ref={masterAdsCalendarIconRef}
                    className={
                      showCustomDateBox
                        ? "calender-masterads-container"
                        : "hide"
                    }
                  >
                    {showCustomDateBox && (
                      <CalenderComponent setShowCustomDateBox={setShowCustomDateBox}></CalenderComponent>
                    )}
                    <span className="calender-close-icon-campaignlist">
                      <img
                        onClick={() => setShowCustomDateBox(false)}
                        src={closeIcon}
                        alt={"closeIcon"}
                      ></img>
                    </span>
                  </div>
                </Stack>
                <Stack>
                  <div className="d-flex align-items-end h-100 px-2 py-1">
                    <div className="">
                      <div className="bulk-upload-option">
                        <div className="toggle-button" ref={kababRef} onClick={() => { setIsBulkUploadOptionOpen(!isBulkUploadOptionOpen) }}>
                          <FaEllipsisV className="toggle-icon" />
                        </div>
                        {isBulkUploadOptionOpen && (
                          <div className="options">
                            <div className="option">
                              <img onClick={handleClick} src={csvIcon} alt={"upload"} style={{ height: "16px", width: "16px", marginRight: "10px" }} />
                              <span className="option-label" onClick={handleClick}>Bulk Search</span>
                            </div>
                            <div className="option">
                              <img onClick={onCSVIconClick} src={csvIcon} alt={"upload"} style={{ height: "16px", width: "16px", marginRight: "10px" }} />
                              <span className="option-label" onClick={onCSVIconClick}>Bulk Enable/Pause</span>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* <button type="button" class="btn btn-outline-light" style={{ borderColor: "rgba(77, 65, 132,0.3)" }}>
                        <img  onClick={handleClick} src={csvIcon} alt={"upload"} style={{height:"26px",width:"26px"}} />
                        <img onClick={onCSVIconClick} src={csvIcon} alt={"upload"} style={{ height: "26px", width: "26px" }} />
                      </button> */}
                    </div>
                    <input
                      type="file"
                      name="file"
                      accept=".csv"
                      ref={hiddenFileInput}
                      style={{ display: "none" }}
                      onInput={changeHandler}
                      id={"hiddenFileUploader"}
                      onClick={onNullHandler}
                    />
                  </div>

                </Stack>
              </Stack>
              {/* </Stack> */}
            </Stack>
          </div>
          <Stack direction="horizontal">
            <Stack className="d-flex flex-row align-items-center">
              <div>
                <SecondaryBtnRb
                  secondaryBtnText={`All (${overAllAdsCount?.total_count})`}
                  className={
                    selectedBtn === "all-btn"
                      ? "primary-active-btn  ennable-paused-btns-height "
                      : "primary-inactive-btn ennable-paused-btns-height "
                  }
                  id="all-btn"
                  onClick={(e, type, value) =>
                    onSelectedBtnChangeHandler(e, "status", "All")
                  }
                ></SecondaryBtnRb>
              </div>
              <div>
                <SecondaryBtnRb
                  secondaryBtnText={`Enabled (${overAllAdsCount?.enabled_count ?? 0})`}
                  className={
                    selectedBtn === "enabled-btn"
                      ? "primary-active-btn ennable-paused-btns-height "
                      : "primary-inactive-btn ennable-paused-btns-height "
                  }
                  id="enabled-btn"
                  onClick={(e, type, value) =>
                    onSelectedBtnChangeHandler(e, "status", "ENABLED")
                  }
                ></SecondaryBtnRb>
              </div>
              <div>
                <SecondaryBtnRb
                  secondaryBtnText={`Paused (${overAllAdsCount?.paused_count ?? 0})`}
                  className={
                    selectedBtn === "PAUSED"
                      ? "primary-active-btn ennable-paused-btns-height "
                      : "primary-inactive-btn ennable-paused-btns-height "
                  }
                  id="PAUSED"
                  value="PAUSED"
                  onClick={(e, type, value) =>
                    onSelectedBtnChangeHandler(e, "status", "PAUSED")
                  }
                ></SecondaryBtnRb>
              </div>
              <div className="filter-icon-container-mainmt-filter-gap filter-icon-container-main margin-left-mt-filter-icon-container">
                <div
                  className={
                    minMaxFilterActivated ? "activated-min-max-filter" : "hide"
                  }
                ></div>
                <img
                  src={settingIcon}
                  onClick={onFilterIconhandler}
                  className="cursor-pointer-global"
                  alt="FilterIcon"
                ></img>

                {/* <div className="filter-icon-container-mainmt-filter-gap filter-icon-container-main ">
                <div
                  className={
                    minMaxFilterActivated ? "activated-min-max-filter" : "hide"
                  }
                ></div>
                <img src={settingIcon} onClick={onFilterIconhandler}></img>
              </div> */}
              </div>
            </Stack>
          </Stack>
          <Stack className={dataForChips.length ? "pt-3 " : "hide"}>
            <FilterChips
              filterChipList={dataForChips}
              pageName={"master-ads"}
              onCancelClickRemoveFilteringHandler={(fieldNameForAPIFilter) =>
                onCancelClickRemoveFilteringHandlerr(fieldNameForAPIFilter)
              }
            ></FilterChips>
          </Stack>
          <div className="d-flex flex-column align-items-end w-100 mt-2">
                <Link to={"#"} className={"text-muted"} style={{fontSize:"12px"}}>* SB Ads Data Not Present 
                </Link>
                <Link to={"#"} className={"text-muted"} style={{fontSize:"12px"}}>* Ads not back-synced from amazon will not be selected 
                </Link>
          </div>
          <div className="master-ads-table-wrapper">
            <Stack className="master-ads-main-table">
              <TableRb tableHeads={dataHeads} shimmer={allLoaders?.mainLoader} noDataFound={(!allLoaders?.mainLoader && !tableDataToShow?.length && !initialLoad)}  table={tableBody}></TableRb>
            </Stack>
          </div>
          <div className="d-flex align-items-end gap-1 py-2 justify-content-end">
                <span className="text-nowrap">{lazyLoading ? 'Loading Next Page Records...' :`Showing ${currentPage} out of ${totalPages} Pages`}</span>
                <Pagination className="ma-custom-pagination" style={{marginBottom:0}}>
                    <Pagination.Prev disabled={!isPrev} onClick={() => {
                        handlePagination("prev")
                    }}/>
                    <Pagination.Next disabled={!isNext || lazyLoading} onClick={() => {
                        handlePagination("next")
                    }}/>
                </Pagination>
            </div>
        </Stack>
      </div>
    </div>
  );
};

export default MasterAdsMain;
