import React, { useRef, useState, useEffect } from "react";
import { Tab, Nav,Form } from "react-bootstrap";
// import CheckboxRb from '../../Buttons/checkbox/checkbox';
import { Trash } from "react-bootstrap-icons";
// import handleCss from '../../../constant/handleCss';
import SearchbarForEditCampaign from "../../../searchbarForEditCampaign";
import AddSpKeyword from "../AddSpKeyword";
import handleCss from "../../../../constant/handleCss";
import TableRb from "../../../Tables/tableRb";
import FilterkeywordModal from "../../campaignCreation/SpKeywordTargeting/FilterkeywordModal";
import CheckboxRb from "../../../Buttons/checkbox/checkbox";
import CreateCampaign from "../CreateCampaign";
import CreateAdGroup from "../CreateAdGroup";
import MasterTagetinngActionAlertMModal from "../../masterTargetinngActionnAlertMModal/masterTargetingActionnAlertModal";
import { useGlobalContext } from "../../../../context/global";
import SpBrandKeywordTable1 from "./SpBrandKeywordTable1";
import {useDispatch,useSelector} from 'react-redux'
import { campaignListActions } from "../../../../redux/slices/campaignList";
import modifyValueByPercentage from "../../../../helper/common/modifyValueByPercentage";
import EditBidModalForTargeting from "../../editBidModalForTargeting";
import { getCurrencySymbol } from "../../../../constant/defaultCurrencyConfigurations";

const SpBrandKeyword = ({ setShow, show }) => {
  // States
  const [page, setPage] = useState("");
  const [isText, setIsText] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [newArr, setNewArr] = useState(new Array());
  const [dynamicHeaders, setDynamicHeaders] = useState([
    "Keywords",
    "Match Type",
    "Sugg Bidd",
  ]);
  const [successContainer, setSuccessContainer] = useState({
    success: false,
    msg: "",
  });
  const [actionAlertModalVisibility,setActionAlertModalVisibility] = useState(false)
  const [bidModal,setBidModal] = useState(false)
  const dispatch = useDispatch()
  const actiontoEditCollectivebid = useSelector(
    (state) => state.campaignListData.actionEditCollectiveBidForMasterTargeting
  );
  const bulkEditBidModalVisibilty = useSelector(
    (state) => state.campaignListData.bulkEditBidModalVisibilty
  );
  const { success, msg } = successContainer;
  const {
    brandMatchTypes,
    setBrandMatchTypes,
    brandKeywordsToBeAdded,
    setBrandKeywordsToBeAdded,
    setAdGroupData,
    createCampaignErrors,
    dispatchErrors
  } = useGlobalContext();


  // Variables 
  const alertText = "Are you sure that you want to align suggested bid with added keywords?"
  const currencySymbol = getCurrencySymbol();

  // Refs
  const modalRefFirst = useRef();
  const modalRefSecond = useRef();
  const nameRef = useRef();

  // Effects
  useEffect(() => {
    let modalHandler = (e) => {
      if (!modalRefFirst.current.contains(e.target)) {
        setIsVisible(false);
      }
    };
    document.addEventListener("mousedown", modalHandler);
    return () => {
      document.removeEventListener("mousedown", modalHandler);
    };
  }, []);

  useEffect(() => {
    setTableHeaders();
    handleCss();
  }, [page]);

  useEffect(() => {
    setBrandMatchTypes(["Exact"]);
    setBrandKeywordsToBeAdded([]);
    return () => {
      setBrandMatchTypes(["Exact"])
    }
  },[])

  
  useEffect(() => {
    if(brandKeywordsToBeAdded?.length) {
      dispatchErrors({action:"setError",fieldName:"targeting",value:false})
    }
  },[brandKeywordsToBeAdded])

  // Methods


  const getData = (data) => {
    const {setAsValue,selectedAction,valuePercentage} = data
    setBrandKeywordsToBeAdded((prev) => {
      const copy = [...prev].map((keyword) => {
        keyword.userBid = selectedAction === "set_bid_as" ? setAsValue : modifyValueByPercentage(selectedAction,valuePercentage,Number(keyword.userBid))
        return keyword
      })
      return copy
    })
    setAdGroupData((prev) => {
      const copy = {...prev}
       copy["amz_keyword_targeting"] =  copy["amz_keyword_targeting"]?.map((keyword) => {
        const addedKeyword = brandKeywordsToBeAdded.find((addedKeyword) => addedKeyword.text === keyword.text)
        if(addedKeyword) {
          keyword.userBid = addedKeyword.userBid
        }
        return keyword
      })
      return copy 
    })
  }

  const bidChange = (input,word) => {
    setBrandKeywordsToBeAdded((prev)=>{
      let data = [...prev].map((keyword) => {
        if((keyword.text.toLowerCase() === word.text.toLowerCase()) && (keyword.match.toLowerCase() === word.match.toLowerCase())) {
          keyword.userBid = parseFloat(input.target.value)
        }
        return keyword
      })
      return data 
    })
    setAdGroupData((prev) => {
      let data = {...prev}
      let updatedData = data?.amz_keyword_targeting.map((keyword) => {
        if((keyword.text.toLowerCase() === word.text.toLowerCase()) && (keyword.match.toLowerCase() === word.match.toLowerCase())) {
          keyword.userBid = parseFloat(input.target.value)
        }
        return keyword
      })
      data.amz_keyword_targeting = updatedData
      return data
    }) 
  }

  const deleteAll = () => {
    setBrandKeywordsToBeAdded([]);
    setAdGroupData((current) => {
      const copy = { ...current };
      const existingKeywordsId = brandKeywordsToBeAdded.map(
        (keyword) => `${keyword.text}-${keyword.match}`
      );
      const filteredKeywords = copy["amz_keyword_targeting"].filter(
        (keyword) => {
          return !existingKeywordsId.includes(
            `${keyword.text}-${keyword.match}`
          );
        }
      );
      copy["amz_keyword_targeting"] = filteredKeywords;
      return copy;
    });
  };

  const alignWithSuggestedBidhandler = () => {
   dispatch(campaignListActions.showActionAlertModal())
   setActionAlertModalVisibility(true)
  }

  const editBidHandler = () => {
    dispatch(campaignListActions.showBulkEditBidModal())
    setBidModal(true)
  }

  const cancelBidModal = () => {
    setBidModal(false)
  }

  let tableBody = (
    <>
      <div className={` ${createCampaignErrors?.targeting ? "border border-danger" : ""} generic-main-table-body-container`}>
        <table className="sp-product-targetting-table">
          <thead className="main-table-head">
            <tr className="table-heading-style">
              <td colspan={4} className="table-upper">
                <div className="d-flex justify-content-between align-items-center">
                <div className="text-dark p-3">
                  {brandKeywordsToBeAdded && brandKeywordsToBeAdded.length}{" "}
                  Added
                </div>
                {
                  (brandKeywordsToBeAdded.length > 0) ?  (<div className="d-flex table-tab">
                  <button
                  className="alignn-with-sug-bid-box "
                  onClick={alignWithSuggestedBidhandler}
                >
                  Align with Suggested Bid
                  </button>
                  <button
                  className="bid-manipulate-box"
                  onClick={editBidHandler}
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                >
                  Adjust Bid
                   </button>
                </div>)
                : ""
                }
                <div className="float-right">
                  <button
                    type="button"
                    onClick={deleteAll}
                    className={
                      brandKeywordsToBeAdded &&
                      brandKeywordsToBeAdded.length !== 0
                        ? "remove-all-btn-dark cursor-pointer-global"
                        : "remove-all-btn"
                    }
                  >
                    Remove All
                  </button>
                </div>
                </div>
              
              </td>
            </tr>
            <tr>
              {dynamicHeaders.map((th, index) => {
                return <th className="border border-right-white">{th}</th>;
              })}
            </tr>
          </thead>
          <tbody className="table-body main-table-body">
            {brandKeywordsToBeAdded &&
              brandKeywordsToBeAdded.map((word, index) => {
                return (
                  <tr key={`${word?.text}-${word?.match}`} className="row-height">
                    <td className="tabledata-td">{word?.text}</td>
                    <td className="tabledata-td">{word?.match}</td>
                    <td className="tabledata-td">
                      <div className="d-flex flex-column w-max align-items-center">
                        <div>{currencySymbol}{word.bid}</div>
                        <div className="text-muted">
                        <div className="fs-10"><small className="">
                            ({currencySymbol}{word.lowest} - {currencySymbol}{word.heighest})
                          </small>
                        </div>
                        </div>
                      </div>
                    </td>
                    <td className="tabledata-td text-center">
                      <div className="d-flex gap-1 justify-content-between align-items-center" style={{width:'75px'}}>
                        <div className="w-100">
                            <Form.Control
                              size="sm"
                              className="w-100"
                              type="number"
                              step="0.1"
                              value={word?.userBid}
                              onChange={(e) => bidChange(e,word)}
                              placeholder="Enter your bid"
                            />
                        </div>
                        <div>
                          <span
                            className="text-left cursor-pointer d-block selected-icon-hover"
                            onClick={() => onDelete(word)}
                          >
                            <Trash
                              className="text-danger remove-item-btn"
                              height="12px"
                              width="12px"
                            />
                          </span>
                        </div>
                      </div>
                      </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );

  const setTableHeaders = () => {
    if (page === "third") {
      setDynamicHeaders(["All Categories", "Suggested Bid |||"]);
    } else {
      setDynamicHeaders(["Keywords", "Match Type", "Sugg Bidd" , "Edit Bid"]);
    }
  };

  // For First Table

  const checkAndRevert = (currentTypes, requestedTypes) => {
    // This function checks the current value and sets the new Value
    if (currentTypes.includes(requestedTypes)) {
      let newTypes = currentTypes?.filter((type) => type !== requestedTypes);
      if (typeof newTypes === "number") {
        newTypes = [];
      }
      setBrandMatchTypes(newTypes);
    } else {
      setBrandMatchTypes([...currentTypes, requestedTypes]);
    }
  };

  const handleChangeNow = (e) => {
    switch (e.target.value) {
      case "Exact":
        checkAndRevert(brandMatchTypes, e.target.value);
        break;
      case "Phrase":
        checkAndRevert(brandMatchTypes, e.target.value);
        break;
      case "Broad":
        checkAndRevert(brandMatchTypes, e.target.value);
        break;
      default:
        break;
    }
  };

  const handleChange = (e) => {
    if (e.target.checked === false) {
      if (newArr.includes(e.target.value)) {
        setNewArr(() => {
          return newArr.filter((item) => item !== e.target.value);
        });
      }
    }
    if (e.target.checked) {
      setNewArr([...newArr, e.target.value]);
    }
  };

  const handleName = (e) => {
    setIsText(e.target.value);
  };

  const onDelete = (id) => {
    const { text, match } = id;
    const matchingKey = `${text}-${match}`;
    let filteredKeywords = brandKeywordsToBeAdded.filter((keyword) => {
      let matchingKey2 = `${keyword.text}-${keyword.match}`;
      return matchingKey !== matchingKey2;
    });
    setBrandKeywordsToBeAdded(filteredKeywords);
    setAdGroupData((current) => {
      const copy = { ...current };
      const updatedArray = copy["amz_keyword_targeting"].filter((keyword) => {
        let matchingKey3 = `${keyword.text}-${keyword.match}`;
        return matchingKey !== matchingKey3;
      });
      copy["amz_keyword_targeting"] = updatedArray;
      return copy;
    });
  };

  const onYesClickHandler = () => {
    setBrandKeywordsToBeAdded((prev) => {
      const copy = [...prev]?.map((word) => {
        word.userBid = word?.bid
        return word
      }) 
      return copy
    })

    setAdGroupData((prev) => {
      const copy = {...prev}
      copy["amz_keyword_targeting"] = copy["amz_keyword_targeting"].map((keyword) => {
        brandKeywordsToBeAdded.forEach((addedKeyword) => {
          if(keyword.text === addedKeyword.text) {
            keyword.userBid = keyword.bid
          }
        })
        return keyword
      })
      return copy;
    })
  } 

  return (
    <>
    {bulkEditBidModalVisibilty && bidModal &&  <EditBidModalForTargeting show={bulkEditBidModalVisibilty} cancel={cancelBidModal} getData={getData} setBidAs={true}/>}
    {actionAlertModalVisibility && <MasterTagetinngActionAlertMModal onYesClick={onYesClickHandler} alertText={alertText}/>}
      <div>
        <span className="heading-font">Sponsored Product Brand Keyword</span>
      </div>
      <div className="">
        <CreateCampaign show={show} setShow={setShow} />
      </div>
      <div className="">
        <div className="sponsored-product-keyword-targeting">
          <CreateAdGroup />
          <div className="key-wrap">
            <span className="common-head">
              Keywords Targeting
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_1178_14743"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="21"
                  height="20"
                >
                  <rect
                    x="0.585693"
                    y="0.0175781"
                    width="20"
                    height="19.9643"
                    fill="#D9D9D9"
                  />
                </mask>
                <g mask="url(#mask0_1178_14743)">
                  <path
                    d="M10.5856 17.9022C9.48839 17.9022 8.45728 17.6943 7.49228 17.2783C6.52672 16.8624 5.68978 16.3009 4.98145 15.5939C4.27311 14.8868 3.71061 14.0513 3.29395 13.0875C2.87728 12.1242 2.66895 11.095 2.66895 9.9997C2.66895 8.90443 2.87728 7.87489 3.29395 6.91106C3.71061 5.94778 4.27311 5.11261 4.98145 4.40554C5.68978 3.69847 6.52672 3.13697 7.49228 2.72105C8.45728 2.30513 9.48839 2.09717 10.5856 2.09717C11.6828 2.09717 12.7142 2.30513 13.6798 2.72105C14.6448 3.13697 15.4814 3.69847 16.1898 4.40554C16.8981 5.11261 17.4606 5.94778 17.8773 6.91106C18.2939 7.87489 18.5023 8.90443 18.5023 9.9997C18.5023 11.095 18.2939 12.1242 17.8773 13.0875C17.4606 14.0513 16.8981 14.8868 16.1898 15.5939C15.4814 16.3009 14.6448 16.8624 13.6798 17.2783C12.7142 17.6943 11.6828 17.9022 10.5856 17.9022ZM10.5856 16.6545C12.4328 16.6545 14.0059 16.0065 15.3048 14.7104C16.6031 13.4139 17.2523 11.8436 17.2523 9.9997C17.2523 8.15577 16.6031 6.58553 15.3048 5.28896C14.0059 3.99294 12.4328 3.34494 10.5856 3.34494C8.73839 3.34494 7.16561 3.99294 5.86728 5.28896C4.56839 6.58553 3.91895 8.15577 3.91895 9.9997C3.91895 11.8436 4.56839 13.4139 5.86728 14.7104C7.16561 16.0065 8.73839 16.6545 10.5856 16.6545Z"
                    fill="#4D4184"
                  />
                  <path
                    d="M10.559 13.9509C10.7396 13.9509 10.889 13.8921 11.0073 13.7745C11.1251 13.6564 11.184 13.5072 11.184 13.327V9.77083C11.184 9.60446 11.1251 9.46221 11.0073 9.34409C10.889 9.22652 10.7396 9.16774 10.559 9.16774C10.3784 9.16774 10.2293 9.22652 10.1115 9.34409C9.99317 9.46221 9.934 9.61139 9.934 9.79162V13.3478C9.934 13.5141 9.99317 13.6564 10.1115 13.7745C10.2293 13.8921 10.3784 13.9509 10.559 13.9509ZM10.559 7.7536C10.7534 7.7536 10.9132 7.68761 11.0382 7.55562C11.1632 7.42419 11.2257 7.26143 11.2257 7.06733C11.2257 6.8871 11.1632 6.73099 11.0382 6.599C10.9132 6.46757 10.7534 6.40186 10.559 6.40186C10.3646 6.40186 10.2048 6.46757 10.0798 6.599C9.95483 6.73099 9.89233 6.8871 9.89233 7.06733C9.89233 7.26143 9.95483 7.42419 10.0798 7.55562C10.2048 7.68761 10.3646 7.7536 10.559 7.7536Z"
                    fill="#4D4184"
                  />
                </g>
              </svg>
            </span>
            <div className="tab-wrap">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav variant="pills" className="flex-row">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      className="primary-btn d-flex"
                      style={{ width: "177px" }}
                    >
                      <span className="search-keyword-text">
                        Search Keyword
                      </span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg"
                      >
                        <mask
                          id="mask0_1178_14756"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="20"
                          height="20"
                        >
                          <rect width="20" height="20" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_1178_14756)">
                          <path
                            d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                            fill="white"
                          />
                          <path
                            d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                            fill="white"
                          />
                        </g>
                      </svg>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="primary-btn ms-1 d-flex"
                      eventKey="second"
                    >
                      Enter list
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_1178_14752"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="20"
                          height="20"
                        >
                          <rect width="20" height="20" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_1178_14752)">
                          <path
                            d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                            fill="#4D4184"
                          />
                          <path
                            d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                            fill="#4D4184"
                          />
                        </g>
                      </svg>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="keyword-targeting-tab-box">
                          <div className="search-product">
                            <SearchbarForEditCampaign
                              keywords={"keywords"}
                              placeholder="Search Keywords"
                            ></SearchbarForEditCampaign>
                            <div
                              className="set-filter-modal"
                              ref={modalRefFirst}
                            >
                              <FilterkeywordModal
                                page={page}
                                isVisible={isVisible}
                                setIsVisible={setIsVisible}
                              />
                            </div>
                            <span
                              className={
                                isVisible
                                  ? "filter-active right-icon"
                                  : "right-icon"
                              }
                              onClick={() => setIsVisible(!isVisible)}
                            >
                              <svg
                                width="22"
                                height="16"
                                viewBox="0 0 22 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={
                                  isVisible ? "white-color-active" : ""
                                }
                              >
                                <path
                                  d="M12.8541 12.9987L12.8532 12.9979C12.6483 12.7875 12.387 12.6843 12.0886 12.6843H9.91169C9.61326 12.6843 9.35202 12.7875 9.1477 12.9983C8.94341 13.209 8.84537 13.4749 8.84537 13.7764C8.84537 14.0778 8.94341 14.3437 9.1477 14.5545C9.35202 14.7652 9.61326 14.8685 9.91169 14.8685H12.0886C12.387 14.8685 12.6483 14.7653 12.8532 14.5549L12.8541 14.554C13.0574 14.3432 13.1549 14.0775 13.1549 13.7764C13.1549 13.4753 13.0574 13.2095 12.8541 12.9987ZM20.4774 2.88577L20.4778 2.88534C20.673 2.67165 20.7739 2.4153 20.7739 2.12726C20.7739 1.82618 20.6765 1.56053 20.4727 1.35031C20.2679 1.13903 20.0064 1.03516 19.7076 1.03516H2.29264C1.99384 1.03516 1.73247 1.13906 1.52824 1.35073L1.70816 1.52431L1.52906 1.34988C1.32438 1.56004 1.22632 1.82583 1.22632 2.12726C1.22632 2.41555 1.32785 2.67197 1.52376 2.88555C1.72672 3.10765 1.98938 3.21937 2.29264 3.21937H19.7076C20.011 3.21937 20.2738 3.10761 20.4774 2.88577ZM17.2125 7.17971L17.2121 7.17928C17.0085 6.95744 16.7456 6.84568 16.4423 6.84568H5.55795C5.25471 6.84568 4.99207 6.95738 4.78912 7.17944C4.59318 7.39303 4.49162 7.64947 4.49162 7.93779C4.49162 8.23902 4.58955 8.50465 4.79396 8.71474C4.99813 8.92613 5.25934 9.02989 5.55795 9.02989H16.4423C16.7411 9.02989 17.0026 8.92602 17.2074 8.71474C17.4112 8.50452 17.5086 8.23887 17.5086 7.93779C17.5086 7.64975 17.4077 7.3934 17.2125 7.17971Z"
                                  fill={isVisible ? "#fff" : "#07A9D2"}
                                  stroke={isVisible ? "#fff" : "#07A9D2"}
                                  stroke-width="0.5"
                                />
                              </svg>
                            </span>
                          </div>
                          <div>
                            <span className="match-head">
                              Match Type
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <mask
                                  id="mask0_1178_14809"
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="20"
                                  height="20"
                                >
                                  <rect width="20" height="20" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_1178_14809)">
                                  <path
                                    d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                                    fill="#4D4184"
                                  />
                                  <path
                                    d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                                    fill="#4D4184"
                                  />
                                </g>
                              </svg>
                            </span>
                            <div className="checkbox-box">
                              <CheckboxRb
                                label={"Broad"}
                                value={"Broad"}
                                BrandKeyword={"BrandKeyword"}
                                handleChangeNow={handleChangeNow}
                              ></CheckboxRb>
                              <CheckboxRb
                                label={"Phrase"}
                                value={"Phrase"}
                                BrandKeyword={"BrandKeyword"}
                                handleChangeNow={handleChangeNow}
                              ></CheckboxRb>
                              <CheckboxRb
                                label={"Exact"}
                                value={"Exact"}
                                BrandKeyword={"BrandKeyword"}
                                index={2}
                                handleChangeNow={handleChangeNow}
                              ></CheckboxRb>
                            </div>
                            <div>
                              <div className="sp-product-targettig-outer-container">
                                <div className="table-body1">
                                  <SpBrandKeywordTable1 />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 keyword-targeting-tab-box sp-product-targettig-outer-container">
                        <div className={"table-body2"}>
                          <TableRb className="" table={tableBody}></TableRb>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <AddSpKeyword
                      modalRefSecond={modalRefSecond}
                      page={page}
                      isVisible={isVisible}
                      setIsVisible={setIsVisible}
                      handleChange={handleChange}
                      nameRef={nameRef}
                      handleName={handleName}
                      success={success}
                      msg={msg}
                      isText={isText}
                      // tableBody={tableBody}
                      Brand={"Brand"}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default SpBrandKeyword;
