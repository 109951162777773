import React, {useEffect, useState} from "react";
import { Modal, Button } from "react-bootstrap";
import RadioInputGroup from "../../components/radioInputGroup/radioInputGroup";
import externalLink from "../../assets/icons/external-link-blue.svg";


const RedirectPopup = (props) => {
  // const [viewType, setViewType] = useState(false);

  const handleRedirect = (event, item) => {
    event.stopPropagation();
    props.handleRedirect(item);
  };

  return (
    <Modal centered show={props.show} onHide={props.onClose} className="redirect-popup" >
      <Modal.Header  className="redirectModel" >
        {/* <Modal.Title>Choose Your Report Type</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-3">
          <div><h6>Click on report you would like to view</h6></div>
          <div className="d-flex gap-4">
          <div className="d-flex flex-column w-100">
              {props.pages.map(item => (
                <div key={item} onClick={(event) => handleRedirect(event,item)} style={{color:'#007ACE', cursor:'pointer'}} className="py-2 d-flex gap-2">
                  <div>{item}</div> <img className="" height="18" width="18" src={externalLink} />
                  </div>
              ))}
              {/* <RadioInputGroup
                state={viewType}
                setState={setViewType}
                name="viewType"
                radios={props.pages}
                required
              ></RadioInputGroup> */}
            </div>
            
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="px-0">
        <Button onClick={(event) => handleRedirect(event)} id="create-campaign-btn"  className="primary-btn width-100 btn btn-primary gap-1 p-0 m-0 justify-content-center"><div>Redirect </div> </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default RedirectPopup;
