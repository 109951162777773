import { PALE_COLORS_SERIES, commonOptions, useDrilldownCallback } from "./utils";
import { Line } from "react-chartjs-2";
import { useDeepCompareMemo } from "use-deep-compare";

export const AreaChartRenderer = ({
    resultSet,
    pivotConfig,
    onDrilldownRequested,
  }) => {
    const datasets = useDeepCompareMemo(
      () =>
        resultSet.series(pivotConfig).map((s, index) => ({
          label: s.title,
          data: s.series.map((r) => r.value),
          yValues: [s.key],
          pointRadius: 1,
          pointHoverRadius: 1,
          backgroundColor: PALE_COLORS_SERIES[index],
          borderWidth: 0,
          fill: true,
          tension: 0,
        })),
      [resultSet, pivotConfig]
    );
    const data = {
      labels: resultSet.categories(pivotConfig).map((c) => c.x),
      datasets,
    };
    const options = {
      ...commonOptions,
      scales: {
        ...commonOptions.scales,
        y: {
          stacked: true,
        },
      },
    };
    const getElementAtEvent = useDrilldownCallback({
      datasets: data.datasets,
      labels: data.labels,
      pivotConfig,
      onDrilldownRequested,
    });
    return (
      <Line
        type="area"
        data={data}
        options={options}
        getElementAtEvent={getElementAtEvent}
      />
    );
  };