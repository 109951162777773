import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./ModalReactB.css";
export default function ModalReactB(props) {
    return (
        <div className={props.className ? props.className : "modal-reactb-container"}>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={props.centered}
                bsPrefix={props.bsPrefix}
                dialogClassName={props.dialogClassName}
                modal right fade
                backdropClassName={props.backdropClassName}
                contentClassName={props.contentClassName}
            >
                <Modal.Header className={props.headerContainer}>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100'>
                        {props.heading}
                    </Modal.Title>
                </Modal.Header >
                <Modal.Body className="dashboard-layout">
                    {props.modalBodyRight}
                </Modal.Body>
                {/* <Modal.Footer>
                    {props.modalFooterData}
                </Modal.Footer> */}
            </Modal>
        </div>

    );
}
