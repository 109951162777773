
export default function weekToDays(weekNumbers) {
  const formattedDates = [];
  for (const weekNumber of weekNumbers) {
    const [week, year] = weekNumber.split(", ");
    const startDate = getDateFromWeek(week, year);
    const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);
    const formattedDate = formatDate(startDate) + " to " + formatDate(endDate);
    formattedDates.push(formattedDate);
  }
  return formattedDates;
}

function getDateFromWeek(week, year) {
  const januaryFirst = new Date(year, 0, 1);
  const daysOffset = (week - 1) * 7;
  const daysToFirstDay = januaryFirst.getDay() - 1;
  const firstWeekStart = new Date(year, 0, 1 + (daysOffset - daysToFirstDay));
  return new Date(firstWeekStart.getTime() + (6 * 24 * 60 * 60 * 1000));
}

function formatDate(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${padZero(day)}-${padZero(month)}-${year}`;
}

function padZero(num) {
  return num.toString().padStart(2, "0");
}
