export const initialCreateCampaignErrors = {
    name:false,
    portfolio:false,
    daily_budget:false,
    campaign_bidding_strategy:false,
    category:false,
    adgroup_name:false,
    amz_default_bid:false,
    ad_details:false,
    targeting:false
}

export const initialAdGroupData = {
    campaign_id: "",
    name: "",
    status: "ENABLED",
    source: "amazon",
    type: "default",
    platform_status: "ENABLED",
    account_id: `${JSON.parse(localStorage.getItem("accountId"))}`,
    amz_default_bid: `${JSON.parse(localStorage.getItem("defaultBid"))}` ?? "1" ,
    amz_manual_bid:{
      amz_loose_match_bid_adyogi:"",
      amz_close_match_bid_adyogi:"",
      amz_substitutes_bid_adyogi:"",
      amz_complements_bid_adyogi:""
    }
}