import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { GraphUp, GraphDown } from "react-bootstrap-icons";
import "./minicard.css";
import movingUp from "../assets/svgs/moving.svg";
import movingDown from "../assets/svgs/movingdown.svg";
import movingUpRed from "../assets/svgs/movingup-red.svg";
import movingDownGreen from "../assets/svgs/movingdown-green.svg";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencySymbol } from "../constant/defaultCurrencyConfigurations";

function Minicard({ title, value, color, subtitle, icon, className }) {
  const [sidebarVisibility, setSidebarVisibility] = useState(false);

  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );

  // Get currency_symbol 
    const currencySymbol = getCurrencySymbol()
  useEffect(() => {
    setSidebarVisibility(dashboardSideBarVisibiltyState);
  }, [dashboardSideBarVisibiltyState]);

  return (
    <div className={className}>
      <div
        className={
          sidebarVisibility
            ? "minicard-container-on-sidebar-open "
            : "minicard-container-on-sidebar-close"
        }
      >
        {/* <Card
          className={
            sidebarVisibility ? "card-onSidebarOpen" : "card-onSidebarClose"
          }
        >
          <Card.Body className="card-body">
            <Stack
              direction="horizontal"
              gap={2}
              className="card-col1-container"
            >
              <Card.Title
                style={{
                  textAlign: "left",
                  display: "inline",
                }}
              >
                If the values are in SPENDS , AOV , SALES Value ,CPM or CPC then show only title else append %  
                <h6>
                  {subtitle === "SPENDS" ||
                  subtitle === "AOV" ||
                  subtitle === "SALES VALUE" ||
                  subtitle === "CPM" ||
                  subtitle === "CPC"
                    ? ` ₹ ${title}`
                    : subtitle === "CTR" || subtitle === "CVR" ||  subtitle === "ACOS"
                    ? `${title}%`
                    : title}
                </h6>
              </Card.Title>
              <Card.Subtitle
                style={{
                  transform: "translate(0px,-4px)",
                  borderRadius: "24px",
                  padding: "4px 8px",
                  fontSize: "8px",
                  backgroundColor: `${color}`,
                }}
              >
                <Stack
                  direction="horizontal"
                  style={
                    icon === "up" ? { color: "#047857" } : { color: "#B91C1C" }
                  }
                >
                  {icon === "up" ? (
                    <GraphUp color="#047857" style={{ marginRight: "8px" }} />
                  ) : (
                    <GraphDown color="#B91C1C" style={{ marginRight: "8px" }} />
                  )}
                  {value}%
                </Stack>
              </Card.Subtitle>
            </Stack>
            <Card.Text
              style={{ textAlign: "left", marginTop: "-7px", fontSize: "10px" }}
            >
              {subtitle === "SALES VALUE" ? "REVENUE" : subtitle}
            </Card.Text>
          </Card.Body>
        </Card> */}
        <Card
          className={
            sidebarVisibility ? "card-onSidebarOpen" : "card-onSidebarClose"
          }
        >
          <Card.Body
            direction="horizontal"
            gap={2}
            className="card-col1-container"
          >
            <div className="col-1-container">
              <Card.Title
                style={{
                  textAlign: "left",
                  display: "inline",
                  marginBottom: "2px",
                }}
              >
                <h6 className="title-heading-primary">
                  {subtitle === "SALES VALUE" ? "REVENUE" : subtitle}
                </h6>
              </Card.Title>
              <div className="percentage-up-down-container">
                <span
                  className="percentage-desc"
                  // style={
                  //   icon === "up" ? { color: "#047857" } : { color: "#B91C1C" }
                  // }
                  style={
                    icon === "up" ? ((subtitle === "CPM" || subtitle === "CPC" || subtitle === "ACOS") ? { color: "#B91C1C" } : { color: "#047857" })  : ((subtitle === "CPM" || subtitle === "CPC" || subtitle === "ACOS") ? { color: "#047857" } : { color: "#B91C1C" })
                  }
                >
                  {value}%
                </span>
                <span
                  style={{
                    // transform: "translate(0px,-4px)",
                    borderRadius: "24px",
                    padding: "2px 8px",
                    fontSize: "8px",
                    height: "fit-content",
                    backgroundColor: `${color}`,
                  }}
                >
                  {
                    // <GraphDown color="#B91C1C" style={{ marginRight: "8px" }} />
                    <img
                      // src={icon === "up" ? movingUp : movingDown}
                      src={icon === "up" ? ((subtitle === "CPM" || subtitle === "CPC" || subtitle === "ACOS") ? movingUpRed : movingUp)  : ((subtitle === "CPM" || subtitle === "CPC" || subtitle === "ACOS") ? movingDownGreen : movingDown)}
                      alt="movingdown"
                    />
                  }
                </span>
              </div>
            </div>
            <Card.Text className="d-flex gap-2">
              <h3 className="metric-value">
                {subtitle === "SPENDS" ||
                subtitle === "AOV" ||
                subtitle === "SALES VALUE" ||
                subtitle === "CPM" ||
                subtitle === "CPC"
                  ? ` ${currencySymbol}${title}`
                  : subtitle === "CTR" ||
                    subtitle === "CVR" ||
                    subtitle === "ACOS"
                  ? `${title}%`
                  : title}
              </h3>
              {/* <h6 className="metric-subheading mt-1">
                {subtitle === "SPENDS" ||
                  subtitle === "IMPRESSIONS" ||
                  subtitle === "CLICKS" ||
                  subtitle === "SALES VALUE" ||
                  subtitle === "ORDERS" ? "TOTAL" : "AVG."
                  }
              </h6> */}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default Minicard;
