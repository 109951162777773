/**
 * @Note This Function will recieve the data (original Data and sort the array according to the buttonStatus Type and targeting type);
 * @params ButtonStatus (SelectedButton) - TargetingType-ArrayForFiltering
 */

export default function EnablePauseFilter(
  TargetingType,
  ArrayForFilter,
  selectedBtn
) {
  const statusButton =
    selectedBtn === "enabled-btn"
      ? "ENABLED"
      : selectedBtn === "PAUSED"
      ? "PAUSED"
      : null;
  let arrayTobeReturned = [];
  if(statusButton !== null) {
    arrayTobeReturned = ArrayForFilter.filter((adset,index) => {
        return index!==0 ?  adset[TargetingType][0].status === statusButton : false
    })
    return arrayTobeReturned
  }
  return ArrayForFilter;
}
