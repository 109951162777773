import React from "react";
import addIcon from '../../../assets/svgs/plus_box.svg';
import './newRule.css';

const NewRuleButtonn = ({ text, handleClick }) => {
  return (
    <button onClick={handleClick} className={"d-flex align-items-center add-new-rule-btn gap-3"}>
      <img src={addIcon} height={20} alt={"Add Condition"} />
      <span>{text}</span>
    </button>
  );
};

export default NewRuleButtonn;
