import React from 'react';
import './editBadge.css';

const EditBadge = ({text,isSelected,handleClick}) => {
  return (
    <div className={ isSelected ? 'edit-badge-container-selected' :  'edit-badge-container'}  onClick={() => handleClick(isSelected,text)}>
        <span>{text}</span>
    </div>
  )
}

export default EditBadge
