import speaker from '../../assets/svgs/speaker.svg' // Chip1 Image For Increasig Bid
import increaseBid from "../../assets/svgs/IncreaseBid.svg";
import moneyBag from '../../assets/svgs/moneyBag.svg';
import multiCubes from '../../assets/svgs/multiCubes.svg';
import stopLoss from '../../assets/svgs/stopLoss.svg'

export const defaultTemplateConfigs = {
    efficiency:[
        {
            mainImage:increaseBid,
            chip1Image:speaker,
            chip1Text:"Campaign",
            chip2Text:"ROAS-Based",
            ruleHeading:"Improve Efficiency",
            ruleDesc:"This strategy manages campaign based on the ROAS (return on ad spend) for the past three days.",
            chip1Color:"#07A9D2",
            chip2Color:"#E4E5E7"
        },
        {
            mainImage:moneyBag,
            chip1Image:speaker,
            chip1Text:"Campaign",
            chip2Text:"ROAS-Based",
            ruleHeading:"Scale Up",
            ruleDesc:"This strategy manages campaign based on the ROAS (return on ad spend) for the past three days.",
            chip1Color:"#07A9D2",
            chip2Color:"#E4E5E7"
        }
        , {
            mainImage:stopLoss,
            chip1Image:multiCubes,
            chip1Text:"Adset",
            chip2Text:"ROAS-Based",
            ruleHeading:"Enhance Branding",
            ruleDesc:"This strategy manages campaign based on the ROAS (return on ad spend) for the past three days.",
            chip1Color:"#07A9D2",
            chip2Color:"#E4E5E7"
        }
    ]
}