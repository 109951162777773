import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import ClearIcon from "../../../../assets/svgs/clearIcon.svg";

const InInputComponent = (props) => {
  const [selectedOptions, setSelectedOptions] = useState(props?.initialData);

  const validate = (inputValue, type) => {
    if (type === "int" || type === "facebookIntOperator") {
      const numericRegex = /^[0-9]+$/;
      if (numericRegex.test(inputValue)) {
        return true;
      } else {
        return false;
      }
    } else if (type === "string") {
      return true;
    } else {
      return true;
    }
  };

  const handleSelectChange = (selected, actionMeta) => {
    if (
      props.inOperatorMaxValues &&
      selected.length > props.inOperatorMaxValues
    ) {
      toast.error(
        `You can select maximum ${props.inOperatorMaxValues} values.`
      );
      return;
    }
    if (actionMeta.action === "create-option") {
      // Split the input value by comma
      const values = selected[selected.length - 1].value.split(",");

      // If more than one value is entered separated by comma
      if (values.length > 1) {
        // Remove the last entered value
        selected.pop();

        // Create new options from the comma-separated values
        const newOptions = values.map((value) => ({
          label: value.trim(),
          value: value.trim(),
        }));

        // Add the new options to the selected options
        selected = [...selected, ...newOptions];
      }
    }

    if (selected && selected.some((option) => !option.label.trim())) {
      // Don't update the state if there's an empty entry
      return;
    }

    setSelectedOptions(selected);

    if (props?.userAction) {
      props.userAction(true);
    }
  };

  useEffect(() => {
    if (!props?.initialData.length && props.removeSelected) {
      setSelectedOptions([]); // Clear selected options
    }
  }, [props?.removeSelected]);
  useEffect(() => {
    let val = [];
    selectedOptions.map((row) => {
      val.push(row?.value);
    });
    props?.handleConditionChange(props?.conditionIndex, "userInput", val);
  }, [selectedOptions]);

  const ClearIndicator = () => {
    return (
      <div onClick={handleClear}>
        <img alt="clear" src={ClearIcon} />
      </div>
    );
  };

  const handleClear = () => {
    setSelectedOptions([]); // Clear selected options
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      borderColor:
        props.shoeError && selectedOptions?.length === 0
          ? "red"
          : styles.borderColor,
      minHeight: "38px",
      height: "auto",
    }),
  };

  return (
    <div>
      <CreatableSelect
        isMulti
        onChange={handleSelectChange}
        value={selectedOptions}
        placeholder={props.placeHolder}
        formatCreateLabel={(inputValue) => `enter: ${inputValue}`}
        components={{ ClearIndicator, DropdownIndicator: null }}
        styles={customStyles}
        aria-invalid={"true"}
        isValidNewOption={(inputValue, selectValue, selectOptions) => {
          return validate(inputValue, props.validate);
        }}
      />
    </div>
  );
};

export default InInputComponent;
