import TrendGraphMaster from "../../components/Graphs/TrendGraphMaster";
import moment from "moment";
import LegendComponent from "../../components/legend/legend";

const renderMainGraph = (name, chartData) => {
  const dashboardSideBarVisibiltyState = false;
  const mainGraphs = {
    targeting_chart: (
      <div className="line-column-graph-main">
        <div
          className={
            dashboardSideBarVisibiltyState
              ? "line-column-graph-container-on-sidebar-open"
              : "line-column-graph-container"
          }
        >
          <div className="position-relative-container-master">
            <div className="line-column-graph-legends">
              <span className="line-column-graph-title">
                Targeting Spends v/s ROAS
              </span>
              <div className="d-flex gap-3">
                <LegendComponent
                  legendColor={"#1D216E"} //7078CC
                  legendText={"Keyword Targeting"}
                ></LegendComponent>
                <LegendComponent
                  legendColor={"#50A5B5"} //70BDCC
                  legendText={"Product Targeting"}
                ></LegendComponent>
                <LegendComponent
                  legendColor={"#DEC46A"} //E6DB7E
                  legendText={"Automatic Targeting"}
                ></LegendComponent>
                <LegendComponent
                  legendColor={"#81B550"} //9CCC70
                  legendText={"Audience Targeting"}
                ></LegendComponent>
                <span className="dotted-line">.......</span>
                ROAS
              </div>
            </div>
            {/* <div className="position-relative-container"> */}
            <TrendGraphMaster {...chartData} />
            {/* </div> */}
          </div>
          <div className="line-column-graph-foter">
            <div className="dates-description-graph">
              *Data shown of last {6} Weeks (
              {moment(moment().subtract(42, "days")._d, "DD-MM-YYYY").format(
                "Do MMMM, YYYY"
              )}{" "}
              to{" "}
              {moment(moment().subtract(1, "days")._d, "DD-MM-YYYY").format(
                "Do MMMM, YYYY"
              )}
              )
            </div>

            {/*Use Legend Component here  */}
          </div>
        </div>
      </div>
    ),
  };
  const defaultComponent = mainGraphs[name];
  return <>{defaultComponent}</>;
};

export { renderMainGraph };
