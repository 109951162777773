/**
 * @Params [mainState,stateUpdater,CB that will load more data for next page or offset,pagination,setPagination,limit]
 * @Note Use this hook for lazy loading purpose on different pages This function updates your pagination states with next page and offset use it accordingly in your function.
 */


import { useState,useRef } from "react";

export default function useInfiniteLoad(
  state,
  stateUpdater,
  cb,
  paginationAction,
  setPaginationAction,
  limit
) {
  const [lazyLoad, setLazyLoad] = useState(false);
  const infiniteRef = useRef(null);

  const handleScroll = async () => {
    const container = infiniteRef.current;
    let { currentPage, currentOffSet } = paginationAction;
    // Keep track of last scroll position
    const lastScrollTop = container.getAttribute("data-scroll-top") || 0;

    // Determine scroll direction
    const scrollDirection = container.scrollTop > lastScrollTop ? "down" : "up";

    // Update last scroll position
    container.setAttribute("data-scroll-top", container.scrollTop);

    if (
      scrollDirection === "down" &&
      Math.ceil(container.scrollTop + container.clientHeight) >=
        container.scrollHeight &&
      state?.length >= 50
    ) {
      currentOffSet = currentOffSet + limit;
      currentPage = currentOffSet / limit + 1;
      const pageAndOffset = {
        page:currentPage,
        offset:currentOffSet
      }
      if (!lazyLoad) {
        const moreData = await cb(pageAndOffset, setLazyLoad);
        stateUpdater([...state, ...moreData]);
        setPaginationAction({
          ...paginationAction,
          currentPage,
          currentOffSet,
        });
      }
    }
  };
  return [infiniteRef, lazyLoad, handleScroll];
}
