import { useEffect, useState } from "react";
import PieChart from "../pieChart/pieChart";
import SectionTitle from "../sectionTitle";
import { masterApi } from "../../apis/masterApi";
import  ReactApexChart  from "react-apexcharts";
import { Pie } from "react-chartjs-2";
import redirect from '../../constant/Redirect';
import { useNavigate } from "react-router-dom";


export default function PieChartsAll({portfolios}) {
  const [campaignSummryData, setCampaignSummryData] = useState({});
  const campaignData = [{campaign_type:"Null"},{campaign_type:"Retarget"},{campaign_type:"Prospect"}]
  const navigate = useNavigate();

  // async function campaignSummaryTypeApi() {
  //   const data = {
  //     level: "account",
  //     platform: "amazon",
  //     start_date: "01-07-2021",
  //     end_date: "17-08-2021",
  //     date_preset: "last_7d",
  //     fields: "spends,campaign_type",
  //     group_by: "campaign_type",
  //   }
  //   let result = await masterApi(data);
  //   if(redirect(result)) return navigate('/login')
    
  //   // Sorting the array to show in our format in Pie charts Null:Type,Prospect,Retarget
  //   //  Finding the index of the first array 
  //   let secondIndex = result.data.data.findIndex((item) => {
  //     return item?.campaign_type === "Null";
  //   })
  //   // Getting the element from that index
  //   let splicedPortionOfArray = result.data.data.splice(secondIndex,1);
  //   // Inserting the element in array.
  //   result.data.data.unshift(splicedPortionOfArray[0]);
  //   setCampaignSummryData(result.data);
  //   return result;
  // }

  // useEffect(() => {
  //   campaignSummaryTypeApi();
  // }, [])


  return (
    <div className="section-main-body">
      <SectionTitle sectionTitleHeading="Campaign Summary"></SectionTitle>
      <div className="pai-chart-section-container">
        <div className="pie-chart-contaner-app ">
          {campaignData ?
            campaignData.map((item,index) => {
              return <PieChart
                showColors={true}
                isShowSelect={true}
                isShowLegend={true}
                campaignData={item}
                id={index}
                portfolios={portfolios}
              ></PieChart>
            }) : ""}
        </div>
      </div>
    </div>
  );
}
