const updatePlaceholders = (fields) => {
  return fields.map((field) => {
    let placeholderText = "Enter a number (e.g., 25 for 25%)";
    return { ...field, placeholder: placeholderText };
  });
};

const commonOperators = {
  string: [
    {
      id: "equals",
      name: "Equals",
      multiple: false,
      dropdown: true,
      input: true,
    },
    {
      id: "notEquals",
      name: "Not Equals",
      multiple: false,
      dropdown: true,
      input: true,
    },
    { id: "equals", name: "IN", multiple: true, dropdown: true, input: true },
    {
      id: "notEquals",
      name: "Not IN",
      multiple: true,
      dropdown: true,
      input: true,
    },
    {
      id: "contains",
      name: "Contains",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "notContains",
      name: "Not Contains",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "startsWith",
      name: "Starts With",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "notStartsWith",
      name: "Not Starts With",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "set",
      name: "Exist",
      multiple: false,
      dropdown: false,
      input: false,
    },
    {
      id: "notSet",
      name: "Not Exist",
      multiple: false,
      dropdown: false,
      input: false,
    },
  ],
  int: [
    {
      id: "equals",
      name: "Equals",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "notEquals",
      name: "Not Equals",
      multiple: false,
      dropdown: false,
      input: true,
    },
    // { id: "equals", name: "IN",multiple:true },
    // { id: "notEquals", name: "Not In",multiple:true },
    {
      id: "gt",
      name: "Greater Than",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "gte",
      name: "Greater Than or Equal",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "lt",
      name: "Less Than",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "lte",
      name: "Less Than or Equal",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "set",
      name: "Exist",
      multiple: false,
      dropdown: false,
      input: false,
    },
    {
      id: "notSet",
      name: "Not Exist",
      multiple: false,
      dropdown: false,
      input: false,
    },
  ],
  date: [
    { id: "equals", name: "Equals", multiple: false },
    { id: "notEquals", name: "Not Equals", multiple: false },
    { id: "afterDate", name: "After Date", multiple: false },
    { id: "afterOrOnDate", name: "After or On Date", multiple: false },
    { id: "beforeDate", name: "Before Date", multiple: false },
    { id: "beforeOrOnDate", name: "Before or On Date", multiple: false },
  ],
  customOnlyContins: [
    {
      id: "contains",
      name: "Contains",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "notContains",
      name: "Not Contains",
      multiple: false,
      dropdown: false,
      input: true,
    },
  ],
  customOnlyContinsWithSelect: [
    {
      id: "contains",
      name: "Equals",
      multiple: false,
      dropdown: true,
      input: true,
    },
    {
      id: "notContains",
      name: "Not Equals",
      multiple: false,
      dropdown: true,
      input: true,
    },
  ],
};

export const FiltersMeasuresDataQuadrant = {
  operators: commonOperators,
  fields: [
    {
      name: "Spends",
      id: "VariantAnalysisTablePPMPlaceholder.cost",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "cost_key",
    },
    {
      name: "Spends (DRR)",
      id: "VariantAnalysisTablePPMPlaceholder.costDRR",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "cost_ddr_key",
    },
    {
      name: "Impressions",
      id: "VariantAnalysisTablePPMPlaceholder.impressions",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "impressions_key",
    },
    {
      name: "Impressions (DRR)",
      id: "VariantAnalysisTablePPMPlaceholder.impressionsDRR",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "impressions_ddr_key",
    },
    {
      name: "Clicks",
      id: "VariantAnalysisTablePPMPlaceholder.clicks",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "clicks_key",
    },
    {
      name: "Clicks (DRR)",
      id: "VariantAnalysisTablePPMPlaceholder.clicksDRR",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "clicks_ddr_key",
    },
    {
      name: "CTR",
      id: "VariantAnalysisTablePPMPlaceholder.ctr",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "ctr_key",
    },
    {
      name: "CTR (DRR)",
      id: "VariantAnalysisTablePPMPlaceholder.ctrDRR",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "ctr_ddr_key",
    },
    {
      name: "CPC",
      id: "VariantAnalysisTablePPMPlaceholder.cpc",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "cpc_key",
    },
    {
      name: "CPC (DRR)",
      id: "VariantAnalysisTablePPMPlaceholder.cpcDRR",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "cpc_ddr_key",
    },
    {
      name: "CPM",
      id: "VariantAnalysisTablePPMPlaceholder.cpm",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "cpm_key",
    },
    {
      name: "CPM (DRR)",
      id: "VariantAnalysisTablePPMPlaceholder.cpmDRR",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "cpm_ddr_key",
    },
    {
      name: "Conversions",
      id: "VariantAnalysisTablePPMPlaceholder.conversions14d",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "conversions14d_key",
    },
    {
      name: "Conversions (DRR)",
      id: "VariantAnalysisTablePPMPlaceholder.conversions14dDRR",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "conversions14d_ddr_key",
    },
    {
      name: "Conversion Rate",
      id: "VariantAnalysisTablePPMPlaceholder.conversion_rate",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "conversion_rate_key",
    },
    {
      name: "Conversion Rate (DRR)",
      id: "VariantAnalysisTablePPMPlaceholder.conversion_rateDRR",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "conversion_rate_ddr_key",
    },
    {
      name: "Revenue",
      id: "VariantAnalysisTablePPMPlaceholder.sales14d",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "sales14d_key",
    },
    {
      name: "Revenue (DRR)",
      id: "VariantAnalysisTablePPMPlaceholder.sales14dDRR",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "sales14d_ddr_key",
    },
    {
      name: "Average Order Value",
      id: "VariantAnalysisTablePPMPlaceholder.average_order_value",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "average_order_value_key",
    },
    {
      name: "Average Order Value (DRR)",
      id: "VariantAnalysisTablePPMPlaceholder.average_order_valueDRR",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "average_order_value_ddr_key",
    },
    {
      name: "ACOS",
      id: "VariantAnalysisTablePPMPlaceholder.acos",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "acos_key",
    },
    {
      name: "ACOS (DRR)",
      id: "VariantAnalysisTablePPMPlaceholder.acosDRR",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "acos_ddr_key",
    },
    {
      name: "ROAS",
      id: "VariantAnalysisTablePPMPlaceholder.roas",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "roas_key",
    },
    {
      name: "ROAS (DRR)",
      id: "VariantAnalysisTablePPMPlaceholder.roasDRR",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "roas_ddr_key",
    },
    {
      name: "Maximum Retail Price",
      id: "VariantAnalysisTablePPMPlaceholder.MaximumRetailPrice",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "maximum_retail_price_key",
    },
    {
      name: "BSR",
      id: "VariantAnalysisTablePPMPlaceholder.BSR",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "bsr_key",
    },
    {
      name: "Selling Price",
      id: "VariantAnalysisTablePPMPlaceholder.SalePrice",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "salePrice_key",
    },
    {
      name: "Available Inventory",
      id: "VariantAnalysisTablePPMPlaceholder.Quantity",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "Quantity_key",
    },
    {
      name: "Total ASP",
      id: "VariantAnalysisTablePPMPlaceholder.total_asp",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "total_asp_key",
    },
    {
      name: "Total ROAS",
      id: "VariantAnalysisTablePPMPlaceholder.troas",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "troas_key",
    },
    {
      name: "Total ACOS",
      id: "VariantAnalysisTablePPMPlaceholder.tacos",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "tacos_key",
    },
    {
      name: "Total Revenue",
      id: "VariantAnalysisTablePPMPlaceholder.trevnue",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "trevnue_key",
    },
    {
      name: "Total Units Sold",
      id: "VariantAnalysisTablePPMPlaceholder.tunits_sold",
      datatype: "int",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "tunits_sold_key",
    },
  ],
};

export const FiltersDimensionDataQuadrant = {
  operators: commonOperators,
  fields: [
    {
      name: "Product Name",
      id: "VariantAnalysisTablePPMPlaceholder.productName",
      datatype: "string",
      type: "default",
      placeholder: "enter product name",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "product_name_key",
    },
    {
      name: "ASIN",
      id: "VariantAnalysisTablePPMPlaceholder.asin",
      datatype: "string",
      type: "default",
      placeholder: "enter ASIN",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "asin_key",
    },
    {
      name: "Color",
      id: "VariantAnalysisTablePPMPlaceholder.color",
      datatype: "string",
      type: "default",
      placeholder: "enter color",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "color_key",
    },
    {
      name: "Gender",
      id: "VariantAnalysisTablePPMPlaceholder.gender",
      datatype: "string",
      type: "default",
      placeholder: "enter gender",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "gender_key",
    },
    {
      name: "Category",
      id: "VariantAnalysisTablePPMPlaceholder.category",
      datatype: "string",
      type: "default",
      placeholder: "enter category",
      dynamicOptions: false,
      autoComplete: true,
      source: "Adyogi",
      apiKey: "category_key",
    },
    // {
    //     name: "Category Name",
    //     id: "VariantAnalysisTablePPMPlaceholder.category",
    //     datatype: "string",
    //     type: "default",
    //     placeholder: "enter any value",
    //     dynamicOptions: true,
    //     optionsKey: "category_names_api_key",
    //     source: "BackendAll",
    //     optionsType: "Static",
    //     dataFromApi: true,
    //     apiKey: "category_names_api_key",
    // },
    // {
    //     name: "Campaign Name",
    //     id: "VariantAnalysisTablePPMPlaceholder.campaignName",
    //     datatype: "string",
    //     type: "default",
    //     placeholder: "enter any value",
    //     dynamicOptions: true,
    //     optionsKey: "campaign_names_api_key",
    //     source: "BackendAll",
    //     optionsType: "Static",
    //     dataFromApi: true,
    //     apiKey: "campaign_names_api_key",
    // },
    // {
    //     name: "Portfolio Name",
    //     id: "VariantAnalysisTablePPMPlaceholder.portfolioName",
    //     datatype: "string",
    //     type: "default",
    //     placeholder: "enter any value",
    //     dynamicOptions: true,
    //     optionsKey: "portfolio_names_api_key",
    //     source: "BackendAll",
    //     optionsType: "Static",
    //     dataFromApi: true,
    //     apiKey: "portfolio_names_api_key",
    // },
    // {
    //     name: "Product Set",
    //     id: "VariantAnalysisTablePPMPlaceholder.productSet",
    //     datatype: "string",
    //     type: "default",
    //     placeholder: "enter any value",
    //     dynamicOptions: true,
    //     optionsKey: "product_set_api_key",
    //     source: "BackendAll",
    //     optionsType: "Static",
    //     dataFromApi: true,
    //     apiKey: "product_set_api_key",
    // },
    // {
    //     name: "Campaign Type",
    //     id: "VariantAnalysisTablePPMPlaceholder.campaignType",
    //     datatype: "string",
    //     type: "default",
    //     placeholder: "enter any value",
    //     dynamicOptions: true,
    //     optionsKey: "Retarget,Prospect",
    //     optionsType: "Static",
    //     source: "Adyogi",
    //     apiKey: "campaignType_id_key",
    // },
    // {
    //     name: "Campaign Format",
    //     id: "VariantAnalysisTablePPMPlaceholder.campaignFormat",
    //     datatype: "string",
    //     type: "default",
    //     placeholder: "Select a format",
    //     dynamicOptions: true,
    //     optionsKey: "SD,SP,SB(V),SB",
    //     optionsType: "Static",
    //     source: "Adyogi",
    //     apiKey: "campaign_format_key",
    // },
    // {
    //     name: "Targeting Type",
    //     id: "VariantAnalysisTablePPMPlaceholder.targetingType",
    //     datatype: "string",
    //     type: "default",
    //     placeholder: "Select targeting type",
    //     dynamicOptions: true,
    //     optionsKey: "G.Keyword,C.Keyword,NB.Keyword,Audience,Automatic,NB.Product,B.Keyword,B.Audience,B.Product",
    //     optionsType: "Static",
    //     source: "Adyogi",
    //     apiKey: "targeting_type_key",
    // },
    // {
    //     name: "Ad Status",
    //     id: "VariantAnalysisTablePPMPlaceholder.adStatus",
    //     datatype: "string",
    //     type: "default",
    //     placeholder: "Select ad status",
    //     dynamicOptions: true,
    //     optionsKey: "enabled,paused",
    //     optionsType: "Static",
    //     source: "Adyogi",
    //     apiKey: "ad_status_key",
    // },
  ],
};

export const FiltersAmazonDataQuadrant = {
  operators: commonOperators,
  fields: [
    {
      name: "Category Name",
      id: "VariantAnalysisTablePPMPlaceholder.category",
      datatype: "string",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: true,
      optionsKey: "category_names_api_key",
      source: "BackendAll",
      optionsType: "Static",
      dataFromApi: true,
      apiKey: "category_names_api_key",
    },
    {
      name: "Campaign Name",
      id: "VariantAnalysisTablePPMPlaceholder.campaignName",
      datatype: "string",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: true,
      optionsKey: "campaign_names_api_key",
      source: "BackendAll",
      optionsType: "Static",
      dataFromApi: true,
      apiKey: "campaign_names_api_key",
    },
    {
      name: "Portfolio Name",
      id: "VariantAnalysisTablePPMPlaceholder.portfolioName",
      datatype: "string",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: true,
      optionsKey: "portfolio_names_api_key",
      source: "BackendAll",
      optionsType: "Static",
      dataFromApi: true,
      apiKey: "portfolio_names_api_key",
    },
    {
      name: "Product Set",
      id: "VariantAnalysisTablePPMPlaceholder.productSet",
      datatype: "string",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: true,
      optionsKey: "product_set_api_key",
      source: "BackendAll",
      optionsType: "Static",
      dataFromApi: true,
      apiKey: "product_set_api_key",
    },
    {
      name: "Campaign Type",
      id: "VariantAnalysisTablePPMPlaceholder.campaignType",
      datatype: "string",
      type: "default",
      placeholder: "enter any value",
      dynamicOptions: true,
      optionsKey: "Retarget,Prospect",
      optionsType: "Static",
      source: "Adyogi",
      apiKey: "campaignType_id_key",
    },
    {
      name: "Campaign Format",
      id: "VariantAnalysisTablePPMPlaceholder.campaignFormat",
      datatype: "string",
      type: "default",
      placeholder: "Select a format",
      dynamicOptions: true,
      optionsKey: "SD,SP,SB(V),SB",
      optionsType: "Static",
      source: "Adyogi",
      apiKey: "campaign_format_key",
    },
    {
      name: "Targeting Type",
      id: "VariantAnalysisTablePPMPlaceholder.targetingType",
      datatype: "string",
      type: "default",
      placeholder: "Select targeting type",
      dynamicOptions: true,
      optionsKey:
        "G.Keyword,C.Keyword,NB.Keyword,Audience,Automatic,NB.Product,B.Keyword,B.Audience,B.Product",
      optionsType: "Static",
      source: "Adyogi",
      apiKey: "targeting_type_key",
    },
    {
      name: "Ad Status",
      id: "VariantAnalysisTablePPMPlaceholder.adStatus",
      datatype: "string",
      type: "default",
      placeholder: "Select ad status",
      dynamicOptions: true,
      optionsKey: "enabled,paused",
      optionsType: "Static",
      source: "Adyogi",
      apiKey: "ad_status_key",
    },
  ],
};
