import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./overview.css";
import Minicard from "./minicard";
import Stack from "react-bootstrap/Stack";
import SectionTitle from "./sectionTitle";
import overviewSeiingIcon from "../assets/icons/overviewSeiingIcon.svg";
import OverviewSettingModal from "../components/modal/overviewSettingModal";
import Loader from "./loader";
import { masterApi } from "../apis/masterApi";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../redux/slices/dashboardSlice";
import { abbreviateNumber } from "../constant/abbrivateNumber";
import { useGlobalContext } from "../context/global";
import moment from "moment/moment";
import redirect from "../constant/Redirect";
import { encodeData } from "../helper/common/encodeData";

const Overview = (props) => {
  const { showTitleAs, noTopMargin, defaultCards , portfolios, portfoliosIds } = props;
  const { calendarDate, setCalendarDate,applyCalendar} = useGlobalContext();
  const navigate = useNavigate();
  const rangeHAndler = (e) => {
    props.setShowCustomDateBox(false);
  };
  const dispatch = useDispatch();

  const formatDateFn = (date) => {
    const selectedDate = new Date(date);
    const formattedDate =
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear();
    return (
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear()
    );
  };

  // useEffect(() => {
  //   formatDateFn(calendarDate[0].startDate);
  //   overviewDataAPI();
  // }, []);

  const dasboardOverviewCardSettingModal = useSelector(
    (state) => state.dashboardData.dasboardOverviewCardSettingModal
  );
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [selectedCardsFromSettings, setSelectedCardsFromSettings] = useState([
    {
      displayValue: "SPENDS",
      idValue: "spends",
    },
    {
      displayValue: "IMPRESSIONS",
      idValue: "impressions",
    },
    {
      displayValue: "CLICKS",
      idValue: "clicks",
    },
    {
      displayValue: "ORDERS",
      idValue: "orders",
    },
    {
      displayValue: "SALES VALUE",
      idValue: "sale_value",
    },
    {
      displayValue: "CTR",
      idValue: "ctr",
    },
  ]);
  const [modalShow, setModalShow] = useState(false);
  const [cardValues, setCardValues] = useState({});

  useEffect(() => {
    if (defaultCards&&defaultCards?.length > 0) {
      setSelectedCardsFromSettings(defaultCards)
    }
  }, [defaultCards])

  useEffect(() => {
    overviewDataAPI();
  }, [selectedCardsFromSettings, applyCalendar , portfolios]);//[selectedCardsFromSettings, calendarDate]

  useEffect(() => {
    try {
    //  if(!defaultCards) {
      const items = JSON.parse(localStorage.getItem("overviewSettings"));
      if (items?.length > 0) {
        setSelectedCardsFromSettings(items);
      }
// }

    } catch (e) {
      // console.warn(e);
    }
  }, []);

  async function overviewDataAPI() {
    const portfolioFiltering = [{field_name:'portfolio_name',operator:"IN",values:portfolios}]

    if (dasboardOverviewCardSettingModal) {
      setIsLoaderVisible(false);
    } else {
      setIsLoaderVisible(true);
    }
    try {
      // let checker = moment().date();
      // let anotherDate = moment(formatDateFn(calendarDate[0].startDate)).date()
      const data = {
        level: "account",
        platform: "amazon",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        fields:
          "clicks,impressions,orders,sale_value,cpm,ctr,cpc,cvr,aov,roas,acos,spends",
        time_increment: "all_days",
        comparison_data: "all",
        ...(portfolios.length && {filtering:encodeData(portfolioFiltering)})
      };
      const result = await masterApi(data);
      if (redirect(result)) return navigate("/login");
      let responseDataObject = result?.data?.data[0];
      let percentageChangeObject =
        result?.data?.comparison_data.percentage_change[0];

      let cardValuesTemp = {};
      for (var key in responseDataObject) {
        if (responseDataObject.hasOwnProperty(key)) {
          let roundedValue =
            key === "ctr" ||
            key === "cvr" ||
            key === "roas" ||
            key === "acos" ||
            key === "cpc" ||
            key === "cpm"
              ? responseDataObject[key].toFixed(2)
              : Math.round(responseDataObject[key]);
          let roundedPercentageChange = typeof percentageChangeObject[key] === "number" ? percentageChangeObject[key]?.toFixed(1) : "0"
          cardValuesTemp[key] = {
            value: roundedValue,
            percentageChange: roundedPercentageChange,
          };
        }
      }
      setCardValues(cardValuesTemp);

      setIsLoaderVisible(false);
      return result;
    } catch (e) {

      // console.warn("Error in  Targetting Default Low 10 Api :-", e);
      setIsLoaderVisible(false);
    }
  }


  function capitalizeLetter(string) {
    return string.toUpperCase();
  }
  const selectedCardElementsInModal = (selectedCards) => {
    setSelectedCardsFromSettings([...selectedCards]);
  };
  const overviewSettingBtnHandler = () => {
    setModalShow(true);
    dispatch(dashboardActions?.dasboardOverviewCardSettingModalShow());
  };

  let miniCardsSection = [];

  if (selectedCardsFromSettings?.length) {
    for (let i = 0; i < selectedCardsFromSettings.length; i++) {
      if (cardValues[selectedCardsFromSettings[i].idValue]) {
        if (selectedCardsFromSettings[i].idValue === "sale_value") {
          miniCardsSection.push(
            <Minicard
              title={
                cardValues[selectedCardsFromSettings[i].idValue].value
                  ? abbreviateNumber(
                    cardValues[selectedCardsFromSettings[i].idValue].value,
                    selectedCardsFromSettings[i].displayValue
                  )
                  : "--"
              }
              value={
                cardValues[selectedCardsFromSettings[i].idValue]
                  .percentageChange
                  ? abbreviateNumber(
                    cardValues[selectedCardsFromSettings[i].idValue]
                      ?.percentageChange,
                    selectedCardsFromSettings[i].displayValue
                  )
                  : "--"
              }
              subtitle={selectedCardsFromSettings[i].displayValue}
              // color={
              //   Math.sign(
              //     cardValues[selectedCardsFromSettings[i].idValue]
              //       .percentageChange
              //   ) === -1
              //     ? "#FFE9EA"
              //     : Math.sign(
              //       cardValues[selectedCardsFromSettings[i].idValue]
              //         .percentageChange
              //     ) > 0
              //       ? "#DBFFEE"
              //       : "#e0e0e0"
              // }
              color={
                Math.sign(cardValues[selectedCardsFromSettings[i].idValue].percentageChange) === -1
                  ? ((selectedCardsFromSettings[i].displayValue === "CPM" || selectedCardsFromSettings[i].displayValue === "CPC" || selectedCardsFromSettings[i].displayValue === "ACOS") ? "#DBFFEE" : "#FFE9EA")
                  : Math.sign(cardValues[selectedCardsFromSettings[i].idValue].percentageChange) > 0
                    ? ((selectedCardsFromSettings[i].displayValue === "CPM" || selectedCardsFromSettings[i].displayValue === "CPC" || selectedCardsFromSettings[i].displayValue === "ACOS") ? "#FFE9EA" : "#DBFFEE")
                    : "#e0e0e0"
              }
              icon={
                Math.sign(
                  cardValues[selectedCardsFromSettings[i].idValue]
                    .percentageChange
                ) === -1
                  ? "down"
                  : "up"
              }
              key={selectedCardsFromSettings[i]?.idValue}
            // key={selectedCardsFromSettings[i] + '-cardS'}
            // Need to integrate icon for flat rate
            />
          );
        } else {
          miniCardsSection.push(
            <Minicard
              title={
                cardValues[selectedCardsFromSettings[i].idValue].value
                  ? abbreviateNumber(
                    cardValues[selectedCardsFromSettings[i].idValue].value,
                    selectedCardsFromSettings[i].displayValue
                  )
                  : "--"
              }
              value={
                cardValues[selectedCardsFromSettings[i].idValue]
                  .percentageChange
                  ? abbreviateNumber(
                    cardValues[selectedCardsFromSettings[i].idValue]
                      .percentageChange,
                    selectedCardsFromSettings[i].displayValue
                  )
                  : "--"
              }
              subtitle={selectedCardsFromSettings[i].displayValue}
              // color={
              //   Math.sign(
              //     cardValues[selectedCardsFromSettings[i].idValue]
              //       .percentageChange
              //   ) === -1
              //     ? "#FFE9EA"
              //     : Math.sign(
              //       cardValues[selectedCardsFromSettings[i].idValue]
              //         .percentageChange
              //     ) > 0
              //       ? "#DBFFEE"
              //       : "#e0e0e0"
              // }
              color={
                Math.sign(cardValues[selectedCardsFromSettings[i].idValue].percentageChange) === -1
                  ? ((selectedCardsFromSettings[i].displayValue === "CPM" || selectedCardsFromSettings[i].displayValue === "CPC" || selectedCardsFromSettings[i].displayValue === "ACOS") ? "#DBFFEE" : "#FFE9EA")
                  : Math.sign(cardValues[selectedCardsFromSettings[i].idValue].percentageChange) > 0
                    ? ((selectedCardsFromSettings[i].displayValue === "CPM" || selectedCardsFromSettings[i].displayValue === "CPC" || selectedCardsFromSettings[i].displayValue === "ACOS") ? "#FFE9EA" : "#DBFFEE")
                    : "#e0e0e0"
              }
              icon={
                Math.sign(
                  cardValues[selectedCardsFromSettings[i].idValue]
                    .percentageChange
                ) === -1
                  ? "down"
                  : "up"
              }
              key={selectedCardsFromSettings[i].idValue + '-cardS'}
            // Need to integrate icon for flat rate
            />
          );
        }
      }
    }
  } else {
    miniCardsSection.push(<p key={"Title-Para"}>Select Minimum 6 cards to show</p>);
  }

  let cardBody = miniCardsSection;

  return (
    <div className="overview-main-body" onClick={rangeHAndler}
      style={{ marginTop: noTopMargin ? 0 : '' }}
    >
      <Loader visible={isLoaderVisible}></Loader>

      {dasboardOverviewCardSettingModal ? (
        <OverviewSettingModal
          selectedCardElementsInModal={selectedCardElementsInModal}
          show={dasboardOverviewCardSettingModal}
          defaultCards={defaultCards}
        ></OverviewSettingModal>
      ) : null}
      <Stack direction="horizontal" gap={1}>
        {showTitleAs && <SectionTitle sectionTitleHeading={showTitleAs}></SectionTitle>}
        {!showTitleAs && <SectionTitle sectionTitleHeading="Overview"></SectionTitle>}
        <div className="filter-icon-container-main">
          <img
            src={overviewSeiingIcon}
            onClick={overviewSettingBtnHandler}
            className="cursor-pointer-global"
            alt="overview-settings-tile"
          ></img>
        </div>
      </Stack>
      <div className="card-container">{cardBody}</div>
    </div>
  );
};

export default Overview;
