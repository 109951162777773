import React from 'react';
import './RuleContainerModal.css';
import PrimaryBtnRB from '../../Buttons/primaryBtnRB.js/primaryBtnRB';

const RuleContainerModal = ({text,handleYesClick,handleNoClick}) => {
  return (
    <div className='position-absolute rule-modal-container'>
        <div className="main-modal-container p-3">
                {text}
            <div className="d-flex gap-3 mt-3">
            <button
              type="button"
              className="primary-outline-btn btn btn-outline-primary px-5"
              onClick={handleNoClick}
            >
              No
            </button>
            <PrimaryBtnRB
              insideBtnText={"Yes"}
              pageClickOptionsHandler={handleYesClick}
            />
            </div>
        </div>
    </div>
  )
}

export default RuleContainerModal
