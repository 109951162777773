import React, { useState } from 'react';
import "./draftSectionCampaignListSection.css";
import { useDispatch, useSelector } from "react-redux";
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import SectionTitle from '../../components/sectionTitle';
import { Stack } from 'react-bootstrap';
import SecondaryBtnRb from '../../components/Buttons/secondaryBtnRb/secondaryBtnRb';
import { useGlobalContext } from "../../context/global";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.png";
import searchIcon from "../../assets/icons/search-icon-for-draft.png";
import backIcon from "../../assets/icons/back-arrow-icon.png";
import TableRb from '../../components/Tables/tableRb';
import sortIcon from "../../assets/icons/menu-icon.png"
import CheckboxRb from '../../components/Buttons/checkbox/checkbox';
import warnalertIcon from "../../assets/icons/warn-alert-icon.png";
import RadioButtonRb from '../../components/Buttons/radioButtonRb/radioButtonRb';
import { Link } from 'react-router-dom';

export default function DraftSectionCampaignListSection() {

  const [selectedBtn, setselectedBtn] = useState("campaign-btn");
  const dashboardSideBarVisibiltyState = useSelector((state) => state.dashboardData.dashboardSideBarVisibiltyState);
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const [isCampaignSortingShow, setIsCampaignSortingShow] = useState(false)
  const [selectedSortType, setselectedSortType] = useState("")
  const onButtonClickHandler = (e) => {
    setselectedBtn(e.target.id)
  }
  const { calendarDate, setCalendarDate, dateDifference, setDateDifference } = useGlobalContext();
  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  const onSortClickHandler = () => {
    setIsCampaignSortingShow(!isCampaignSortingShow)
  }

  let dummyDraftCampaignData = [
    {
      "campaign_name": "Re- Targeting Prospect Sponsored Product Keyword Targeting_ Tshirts",
      "format": "Sponsored Product",
      "budget": "₹44,358",
      isRejectedByAmz: false,

    }, {
      "campaign_name": "Re- Targeting Prospect Sponsored Product Keyword Targeting_ Tshirts",
      "format": "Sponsored Product",
      "budget": "₹44,358",
      isRejectedByAmz: false

    }, {
      "campaign_name": "Re- Targeting Prospect Sponsored Product Keyword Targeting_ Tshirts",
      "format": "Sponsored Product",
      "budget": "₹44,358",
      isRejectedByAmz: true

    }, {
      "campaign_name": "Re- Targeting Prospect Sponsored Product Keyword Targeting_ Tshirts",
      "format": "Sponsored Product",
      "budget": "₹44,358",
      isRejectedByAmz: false

    }, {
      "campaign_name": "Re- Targeting Prospect Sponsored Product Keyword Targeting_ Tshirts",
      "format": "Sponsored Product",
      "budget": "₹44,358",
      isRejectedByAmz: false

    }, {
      "campaign_name": "Re- Targeting Prospect Sponsored Product Keyword Targeting_ Tshirts",
      "format": "Sponsored Product",
      'budget': "₹44,358",
      isRejectedByAmz: true

    },
  ]

  let dummyDraftAdgroupData = [
    {
      "adgroup_data": "Automatic",
      "CAMPAIGN_NAME": "Re- Targeting Prospect Sponsored Product Keyword Targeting_ Tshirts",
      "FORMAT": "Sponsored Product",
      isRejectedByAmz: false
    }, {
      "adgroup_data": "Automatic",
      "CAMPAIGN_NAME": "Re- Targeting Prospect Sponsored Product Keyword Targeting_ Tshirts",
      "FORMAT": "Sponsored Product",
      isRejectedByAmz: false,
    }, {
      "adgroup_data": "Automatic",
      "CAMPAIGN_NAME": "Re- Targeting Prospect Sponsored Product Keyword Targeting_ Tshirts",
      "FORMAT": "Sponsored Product",
      isRejectedByAmz: true,
    }, {
      "adgroup_data": "Automatic",
      "CAMPAIGN_NAME": "Re- Targeting Prospect Sponsored Product Keyword Targeting_ Tshirts",
      "FORMAT": "Sponsored Product",
      isRejectedByAmz: false,
    }, {
      "adgroup_data": "Automatic",
      "CAMPAIGN_NAME": "Re- Targeting Prospect Sponsored Product Keyword Targeting_ Tshirts",
      "FORMAT": "Sponsored Product",
      isRejectedByAmz: false,
    }, {
      "adgroup_data": "Automatic",
      "CAMPAIGN_NAME": "Re- Targeting Prospect Sponsored Product Keyword Targeting_ Tshirts",
      "FORMAT": "Sponsored Product",
      isRejectedByAmz: true,
    },


  ]
  const tableBodyForCammpaignDrafts = <div className="draft-table-conntainer">
    <table>
      <thead className="draft-table-thead">
        <tr>
          <th className="draft-table-head"></th>
          <th className="draft-table-head">
            <div className="d-flex flex-row justify-content-between draft-campaign-name-conntainer">
              <div className="flex-grow-1">Campaign Name</div>
              <div>
                <img src={sortIcon} onClick={onSortClickHandler}></img>
                <div>
                  {
                    isCampaignSortingShow &&
                    <div className="sorting-campaign-mmainn-conntainer">
                      <div>
                        <div className='sort-box-title'>Sort Spends by</div>
                      </div>
                      <div className='draft-campaignn-sort-option-container'>
                        <RadioButtonRb name="campaign-sort" label="Rejected Campaigns"></RadioButtonRb>
                      </div>
                      <div className='draft-campaignn-sort-option-container'>
                        <RadioButtonRb name="campaign-sort" label="Drafted Campaigns"></RadioButtonRb>
                      </div>
                      <div className='draft-campaignn-sort-option-container'>
                        <RadioButtonRb name="campaign-sort" label="Both"></RadioButtonRb>
                      </div>

                    </div>
                  }
                </div>
              </div>
            </div>
          </th>
          <th className="draft-table-head">FORMAT</th>
          <th className="draft-table-head">BUDGET</th>
        </tr>
      </thead>
      <tbody>{


        dummyDraftCampaignData && dummyDraftCampaignData.map((data) => {
          return <tr>  <td className='draft-table-row'>
            <div className='d-flex justify-content-center'>
              <CheckboxRb></CheckboxRb>
            </div>

          </td>
            <td className='draft-table-row'>
              <div className="d-flex flex-row">
                <div className={data.isRejectedByAmz ? "" : "hide"}> <img src={warnalertIcon}></img></div>
                <div className={data.isRejectedByAmz ? "draft-campaign-name-text-with-alert" : "draft-campaign-name-text"}>{data.campaign_name}</div>
              </div>
            </td>
            <td className='draft-table-row'>{data.format}</td>
            <td className='draft-table-row'>{data.budget} </td>
          </tr>
        })




      }</tbody>
    </table>
  </div>

  const tableBodyForAdgroupData = <div className="draft-table-conntainer">
    <table>
      <thead className="draft-table-thead">
        <tr>

          <th className="draft-table-head">
            <div className="d-flex flex-row justify-content-between">
              <div className="flex-grow-1">AD GROUP NAME</div>
              <div>
                <img src={sortIcon}></img>
              </div>
            </div>
          </th>
          <th className="draft-table-head">CAMPAIGN NAME</th>
          <th className="draft-table-head">FORMAT</th>
        </tr>
      </thead>
      <tbody>{


        dummyDraftAdgroupData && dummyDraftAdgroupData.map((data) => {
          return <tr>  <td className='draft-table-row'>
            <div className=" adgroup-data-main-cotainer">
              <div className='d-flex justify-content-center '>
                <CheckboxRb className="" ></CheckboxRb>
              </div>
              <div className={data.isRejectedByAmz ? "" : "hide"}> <img src={warnalertIcon}></img></div>
              <div className="adgroup-name-text">
                {data.adgroup_data}
              </div>
            </div>
          </td>
            <td className='draft-table-row'>
              {data.CAMPAIGN_NAME}
            </td>
            <td className='draft-table-row'>{data.FORMAT}</td>

          </tr>
        })




      }</tbody>
    </table>
  </div>









  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }

      id="main-box"
    >
      <Stack gap={3}>
        <div className="section-heading">
          <Stack
            direction="horizontal"

            className="d-flex align-item-center"
          >
            <Link to={"/campaign-list"} className='back-arrow-padding'><img src={backIcon}></img></Link>
            <SectionTitle sectionTitleHeading="Drafts"></SectionTitle>
          </Stack>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex flex-row">
            <SecondaryBtnRb onClick={onButtonClickHandler} className={
              selectedBtn === "campaign-btn"
                ? "primary-active-btn draft-btn-width "
                : "primary-inactive-btn draft-btn-width"
            } secondaryBtnText="Campaigns" id="campaign-btn"></SecondaryBtnRb>
            <SecondaryBtnRb onClick={onButtonClickHandler} secondaryBtnText="Ad Group" id="ad-group-btn" className={
              selectedBtn === "ad-group-btn"
                ? "primary-active-btn mx-3 draft-btn-width"
                : "primary-inactive-btn mx-3 draft-btn-width"
            }></SecondaryBtnRb>
          </div>
          <div className="d-flex flex-row">


            <button
              className="date-container date-btn-campaignlist me-4"
              onClick={dateInputClickHandler}
            >
              <div className="d-flex justify-conntent-center">
                <span className="date-btn-text">
                  {dateDifference}
                </span>
              </div>
              <button className="btn-calender-img-holder">
                <span className="">
                  <img
                    className="dropdown-img-for-select"
                    src={selectSideIcon}
                  ></img>

                  <img
                    className="calender-icon-for-campaignlist"
                    src={CalenderIconForCampaignList}
                  ></img>
                </span>
              </button>
            </button>

            <button className="clear-draft-bt ">Clear Draft</button>
          </div>
        </div>
        <div>
          <div className="search-for-draft-contaier">

            <div className="draft-input-box">
              <input className='draft-search-input-text' placeholder='Search for Drafts'></input>
              <div className="search-icon-indraft">
                <img src={searchIcon}></img>
              </div>
            </div>

          </div>
        </div>
      </Stack>
      <div className="draft-cammpaign-list-table-data">
        <Stack className="draft-campaign-list-main-table">
          <TableRb table={selectedBtn === "campaign-btn" ? tableBodyForCammpaignDrafts : selectedBtn === "ad-group-btn" ? tableBodyForAdgroupData : ""}></TableRb>
        </Stack>
      </div>
    </div>
  )
}
