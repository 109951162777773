import React, { useState, useEffect } from 'react';
import "./productSets.css";
import { useSelector, useDispatch } from "react-redux";
import CustomDropDown from '../../components/DropDown/CustomDropDown';
import dropdownIcon from "../../assets/icons/downArrowForSplitButton.svg";
import { campaignListActions } from '../../redux/slices/campaignList';
import settingIcon from "../../assets/icons/overviewSeiingIcon.svg"
import FilterLogicModal from '../../components/modal/campaignListModals/filterLogicModal/filterLogicModal';
import { Stack } from "react-bootstrap";
import TableRb from '../../components/Tables/tableRb';
import Spinner from '../../components/spinners/spinner';
import ascendingIconSvg from "../../assets/svgs/acendingIconSvg.svg";
import descendingIconSvg from "../../assets/svgs/descendingIcon.svg";
import {masterApi} from '../../apis/masterApi'
import {encodeData} from '../../helper/common/encodeData'
import { formatDateFn } from '../../helper/common/formatDateForAPI';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { useGlobalContext } from '../../context/global';
import Loader from '../../components/loader';
import { Pencil } from 'react-bootstrap-icons';

const ProductSetsList = () => {

    const dashboardSideBarVisibiltyState = useSelector(
        (state) => state.dashboardData.dashboardSideBarVisibiltyState
    );
    const filterModalInCampaignListVisibilty = useSelector(
        (state) => state.campaignListData.filterModalInCampaignListVisibilty
    );
    const getUpdatedConfigHeadsForCampaignListSection = useSelector(
        (state) =>
          state.campaignListData.getUpdatedConfigHeadsForSmartAdsList
      );
    const appliedFilterData = useSelector((state) => state?.campaignListData?.appliedFilterData)
    const metricesWithTwoDecimalPlaces = [
        "cpm",
        "cpc",
        "roas",
        "acos",
        "ctr",
        "cvr",
    ];
    const [allFetchLoaders, setAllFetchLoaders] = useState({
        mainPageLoader: false,
        campaignStatsLoader: false,
        campaignAdsetsLoader: false,
        campaignAdsetStatsLoader: false,
    });
    const [dataHeads, setdataHeads] = useState([

        {
            "fieldName": "Product Set",
            "minRange": "-",
            "maxRange": "-",
            "id": "product_set",
            "isDefaultHead": true,
            "isHeadRightAlign": false,
            "isHeadWithIcon": false,
            "isAbbrivation": false
        },
        // {
        //     "fieldName": "Active Campaigns",
        //     "minRange": "-",
        //     "maxRange": "-",
        //     "id": "active_campaigns",
        //     // "isDefaultHead": true,
        //     "isHeadRightAlign": false,
        //     "isHeadWithIcon": "sort",
        //     "isAbbrivation": false,
        //     "isFindTaskActive": false,
        //     "status": false,
        //     "isSortFunctionalityNeededHere": true

        // },
        // {
        //     "fieldName": "Active AdGroups",
        //     "minRange": "-",
        //     "maxRange": "-",
        //     "id": "active_adgroups",
        //     // "isDefaultHead": true,
        //     "isHeadRightAlign": false,
        //     "isHeadWithIcon": "sort",
        //     "isAbbrivation": false,
        //     "isFindTaskActive": false,
        //     "status": false,
        //     "isSortFunctionalityNeededHere": true

        // },
        {
            "fieldName": "Refresh Frequency",
            "minRange": "-",
            "maxRange": "-",
            "id": "refresh_frequency",
            // "isDefaultHead": true,
            "isHeadRightAlign": false,
            "isHeadWithIcon": false,
            "isAbbrivation": false,
            "isFindTaskActive": false,
            "status": false,
            "isSortFunctionalityNeededHere": true

        },
        {
            "fieldName": "Type",
            "minRange": "-",
            "maxRange": "-",
            "id": "type",
            // "isDefaultHead": true,
            "isHeadRightAlign": false,
            "isHeadWithIcon": false,
            "isAbbrivation": false,
            "isSortFunctionalityNeededHere": false

        },

        {
            "fieldName": "Spends",
            "minRange": "-",
            "maxRange": "-",
            "id": "spends",
            // "isDefaultHead": true,
            "isHeadRightAlign": true,
            "isHeadWithIcon": "sort",
            "isAbbrivation": true,
            "isSortFunctionalityNeededHere": true

        },
        {
            "fieldName": "Revenue",
            "minRange": "-",
            "maxRange": "-",
            "id": "revenue",
            // "isDefaultHead": true,
            "isHeadRightAlign": true,
            "isHeadWithIcon": "sort",
            "isAbbrivation": true,
            "isSortFunctionalityNeededHere": true

        },
        {
            "fieldName": "Orders",
            "minRange": "-",
            "maxRange": "-",
            "id": "orders",
            // "isDefaultHead": true,
            "isHeadRightAlign": true,
            "isHeadWithIcon": "sort",
            "isAbbrivation": true,
            "isSortFunctionalityNeededHere": true

        },
        {
            "fieldName": "ROAS",
            "minRange": "-",
            "maxRange": "-",
            "id": "roas",
            // "isDefaultHead": true,
            "isHeadRightAlign": true,
            "isHeadWithIcon": "sort",
            "isAbbrivation": true,
            "isSortFunctionalityNeededHere": true
        },
    ]);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [minMaxFilterActivated, setMinMaxFilterActivated] = useState(false);
    const fakeDataForStats =   [
        {
            "clicks": 0,
            "impressions": 149,
            "orders": 0,
            "cpm": 0,
            "ctr": 0,
            "spends": 11,
            "sale_value": 0,
            "campaign_name": "SDPT | Deco01 & Mini7 | Conversions",
            "campaign_id": "119018735890223",
            "adset_id": "324394477510965"
        },
        {
            "clicks": 12,
            "impressions": 149,
            "orders": 0,
            "cpm": 0,
            "ctr": 0,
            "spends": 0,
            "sale_value": 0,
            "campaign_name": "SDPT | Deco01 & Mini7 | Conversions",
            "campaign_id": "119018735890223",
            "adset_id": "296326754400894"
        },
    ]
    const [productSets,setProductSets] = useState([])
    const [creatives,setCreatives] = useState([])
    const [adsets,setAdsets] = useState([])
    const [stats,setStats] = useState(fakeDataForStats)
    const [isSortBoxVisible, setIsSortBoxVisible] = useState(false);
    const [searchfilteredData, setsearchfilteredData] = useState([
        {
            "id": "6400345c3c2cb56a78765866",
            "product_set": "ADYG_Women_Kurtha",
            "spends": "200",
            "spends_delta": "10",
            "revenue": "101",
            "revenue_delta": "10",
            "roas": "21",
            "roas_delta": "10",
            "campaignStat":
                [
                    {
                        "active_campaigns": 6674,
                        "active_adgroups": 28,
                        "refresh_frequency": 4,
                        "type": "Dynamic",
                        "orders": 332,
                        "cpm": 6.315174840467556,
                        "ctr": 0.3287185983604458,
                        "cpc": 1.9211492358405757,
                        "cvr": 0.7042253521126761,
                        "aov": 453.31702127659577,
                        "roas": 1.6616998459648642,
                        "revenue": 60.17933999502486,
                        "spends": 12821.750000000002,
                        "sale_value": 21305.9,
                        "campaign_id": "228155303657618",
                        "spends_delta": "10",
                        "revenue_delta": "10",
                        "roas_delta": "10",
                    }
                ]
        },
        {
            "id": "6400345c3c2cb56a78765866",
            "product_set": "ADYG_Women_Silk_Kurtha",
            "spends": "200",
            "spends_delta": "10",
            "revenue": "101",
            "revenue_delta": "10",
            "roas": "21",
            "roas_delta": "10",
            "campaignStat":
                [
                    {
                        "active_campaigns": 274,
                        "active_adgroups": 18,
                        "refresh_frequency": 7,
                        "type": "Static",
                        "orders": 123,
                        "cpm": 6.315174840467556,
                        "ctr": 0.3287185983604458,
                        "cpc": 1.9211492358405757,
                        "cvr": 0.7042253521126761,
                        "aov": 453.31702127659577,
                        "roas": 1.6616998459648642,
                        "revenue": 60.17933999502486,
                        "spends": 12821.750000000002,
                        "sale_value": 21305.9,
                        "campaign_id": "228155303657618",
                        "spends_delta": "10",
                        "revenue_delta": "10",
                        "roas_delta": "10"
                    }
                ]
        },
        {
            "id": "6400345c3c2cb56a78765866",
            "product_set": "ADYG_Women",
            "spends": "50",
            "spends_delta": "5",
            "revenue": "12",
            "revenue_delta": "5",
            "roas": "22",
            "roas_delta": "5",
            "campaignStat": [
                {
                    "active_campaigns": 664,
                    "active_adgroups": 2308,
                    "refresh_frequency": 47,
                    "type": "Dynamic",
                    "clicks": 295,
                    "impressions": 84295,
                    "orders": 10,
                    "cpm": 7.383949225932736,
                    "ctr": 0.34996144492555903,
                    "cpc": 2.1099322033898305,
                    "cvr": 3.389830508474576,
                    "aov": 440.2030000000001,
                    "roas": 7.072329418569158,
                    "revenue": 14.139612860430297,
                    "spends": 622.43,
                    "sale_value": 4402.030000000001,
                    "campaign_id": "22704114208694",
                    "spends_delta": "5",
                    "revenue_delta": "5",
                    "roas_delta": "5"
                }
            ]
        },
        {
            "id": "6400345c3c2cb56a78765866",
            "product_set": "ADYG_Cotton_Kurta",
            "spends": "50",
            "spends_delta": "5",
            "revenue": "12",
            "revenue_delta": "5",
            "roas": "22",
            "roas_delta": "5",
            "campaignStat": [
                {
                    "active_campaigns": 64,
                    "active_adgroups": 238,
                    "refresh_frequency": 7,
                    "type": "Dynamic",
                    "clicks": 295,
                    "impressions": 84295,
                    "orders": 210,
                    "cpm": 7.383949225932736,
                    "ctr": 0.34996144492555903,
                    "cpc": 2.1099322033898305,
                    "cvr": 3.389830508474576,
                    "aov": 440.2030000000001,
                    "roas": 7.072329418569158,
                    "revenue": 14.139612860430297,
                    "spends": 7622.43,
                    "sale_value": 4402.030000000001,
                    "campaign_id": "22704114208694",
                    "spends_delta": "5",
                    "revenue_delta": "5",
                    "roas_delta": "5"
                }
            ]
        },
    ]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const {calendarDate,applyCalendar} = useGlobalContext()
    const onFilterIconhandler = () => {
        dispatch(campaignListActions.showFilterModalInCampaignList());
    };
    const dispatch = useDispatch();
    const [filters, setFilters] = useState({
        product_name: "",
        campaign_type: "All",
        product_set_type: 'Dynamic',
        campaign_objective: "Both",
        category: "",
        portfolio_id: "",
        campaign_id: "",
        adgroup_id: "",
        status: "",
        asin: "",
    });

    const navigate = useNavigate();

    const filterCampaigns = (e, type) => {
        setFilters({ ...filters, [type]: e.target.value });
    };

    const onDropdownImghandler = (type) => {
        console.log("@$# onDropdownImghandler")
    };

    const fetchMainDataOfCampaigns = async () => {
        // This function will fetch data one by one and set into respective stats.
        const client_id =  JSON.parse(localStorage.getItem("clientId"));
        try {
            // API Call:- All Product Sets
            setAllFetchLoaders({...allFetchLoaders,mainPageLoader:true})
            const urlForAllProductSets = `${process.env.REACT_APP_ADS_API}/client/${client_id}/product-set?source=amazon&categories`
            const allProductSets = await axios.get(urlForAllProductSets)
            const sortedProductSets = sortDataByCreationDate(allProductSets?.data,'desc','created_at')
            setProductSets([...sortedProductSets ?? []])
            // Id's For Passing in Next API Call
            const allProductSetsIds = allProductSets?.data?.map((productSet) => productSet?.id)
            // API Call :- All creatives
            const filteringForCreatives = [{"field_name": "product_set_id","operator": "IN","values":allProductSetsIds}]
            const dataForCreatives = {
                source:"amazon",
                type:"amazon_smart_creative",
                filtering: encodeData(filteringForCreatives)
            }
            const result = await masterApi(dataForCreatives,"Adset",false)
            setCreatives([...result?.data?.data ?? []])
            const allCreativesAdsetId = result?.data?.data?.map((data) => data?.adset_id)
            const filteringForAdsets = [{"field_name": "id","operator": "IN","values":allCreativesAdsetId}]
            const dataForPlatformIds = {
                source:"amazon",
                limit:5000,
                fields:"platform_id,id",
                filtering: encodeData(filteringForAdsets)
            }
            // API Call For All Adsets
            const allPlatformIds = await masterApi(dataForPlatformIds,"adsetList",false)
            const allIds = allPlatformIds?.data?.data?.map((platform) => Number(platform?.platform_id))
            setAdsets([...allPlatformIds?.data?.data ?? []])
            const filteringForStats = [ { "field_name": "adset_id", "operator": "IN", "values": [...allIds] }]
            const dataForGettingStats = {
                level:"adset",
                platform:"amazon",
                start_date:`${formatDateFn(calendarDate[0].startDate)}`,
                end_date:`${formatDateFn(calendarDate[0].endDate)}`,
                fields:'campaign_id,adset_id,spends,roas,clicks,campaign_name,orders,sale_value,impressions,cpc,cvr,aov,acos,cpm,orders,ctr',
                group_by:"adset_id",
                filtering:encodeData(filteringForStats)
            }
            // API call for Main Stats
            const mainStatsData = await masterApi(dataForGettingStats,"masterApi");
        //    const data =  consolidateData(allProductSets?.data,result?.data?.data,allPlatformIds?.data?.data,mainStatsData?.data?.data)
           setStats([...mainStatsData?.data?.data ?? []])
           setAllFetchLoaders({...allFetchLoaders,mainPageLoader:false})
        } catch (error) {
            console.log(error)
        }
    }
 
    function consolidateData (productSetList,creatives,adsets,stats) {
        const mainStats = [...productSetList]?.map((data) => {
            const {name,refresh_duration,id,created_at} = data
            const refreshFreq = refresh_duration.match(/\d+/g);
            const creative = creatives?.find((creativeEl) => creativeEl?.product_set_id === data?.id);
            const adset = adsets?.find((adset) => adset?.id === creative?.adset_id);
            const stat = stats?.find((stat) => stat?.adset_id === adset?.platform_id);
            let statsForEachObject =  {
                "refresh_frequency": refreshFreq,
                "type": "Dynamic",
            };
            if(stat) {
                statsForEachObject = {...statsForEachObject,...stat}
            }
            return  {
                "id": id,
                "product_set": name,
                "creation_date":created_at,
                "campaignStat":
                    [
                        statsForEachObject
                    ]
            }
        })
        const sortedMainStats = sortDataByCreationDate(mainStats,'desc')
        return sortedMainStats
    }

    const onSpendFilterClickHandler = (value, index) => {
        let tempTargetStat = JSON.stringify(searchfilteredData);
        tempTargetStat = JSON.parse(tempTargetStat);
        const mainValue = value === "REVENUE" ? "sale_value" : value;
        tempTargetStat = tempTargetStat.sort((a, b) => {
            let x = a?.campaignStat[0]?.[mainValue.toLowerCase()] ?? 0;
            let y = b?.campaignStat[0]?.[mainValue.toLowerCase()] ?? 0;
            return isSortBoxVisible ? y - x : x - y;
        });
        setsearchfilteredData(tempTargetStat);
        setIsSortBoxVisible(!isSortBoxVisible);
        setCurrentIndex(index);
    };

    const pageClickOptionsHandler = () => {
        console.log("@$#77  pageClickOptionsHandler")
    }


    useEffect(() => {
        if (searchfilteredData !== null) { onSpendFilterClickHandler('spends', 1) }
    }, [])


    useEffect(() => {
        fetchMainDataOfCampaigns()
    },[applyCalendar])

    function sortDataByCreationDate(data, order = 'asc',key='creation_date') {
        return data?.sort((a, b) => {
            const dateA = a[key] ? new Date(a[key]) : null;
            const dateB = b[key] ? new Date(b[key]) : null;
    
           
        if (!dateA && !dateB) {
            return 0;
        } else if (!dateA) {
            return 1; // dateA is null, so a should be pushed to the end
        } else if (!dateB) {
            return -1; // dateB is null, so b should be pushed to the end
        } else {
            return order === 'asc' ? dateA - dateB : dateB - dateA;
        }
        });
    }

    useEffect(() => {
        const mainData = consolidateData(productSets,creatives,adsets,stats)
        const filteredData = onInputRangeChangeHandler(mainData)
        
        setsearchfilteredData([...filteredData])
    },[stats,creatives,productSets,adsets,appliedFilterData])

    useEffect(() => {
        setdataHeads(getUpdatedConfigHeadsForCampaignListSection);
      }, [getUpdatedConfigHeadsForCampaignListSection]);

    useEffect(() => {
        dispatch(campaignListActions.appliedFilterData([]));
    }, []);

    const onInputRangeChangeHandler = (initialData) => {
        setMinMaxFilterActivated(false);
        let tempCampaigns = JSON.stringify(initialData);
        tempCampaigns = JSON.parse(tempCampaigns);
    
        let filteredCampaigns = [];
        let filterWithDashAsZero = [];
    
        for (let i = 0; i < appliedFilterData.length; i++) {
          let temp = JSON.parse(JSON.stringify(appliedFilterData[i]));
          temp.minRange = isNaN(temp.minRange) ? 0 : Number(temp.minRange);
          temp.maxRange =
            temp.maxRange == "" || isNaN(temp.maxRange)
              ? Infinity
              : Number(temp.maxRange);
    
          if (
            (temp.minRange != 0 && temp.maxRange != Infinity) ||
            (temp.minRange == 0 && temp.maxRange != Infinity)
          ) {
            filterWithDashAsZero.push(temp);
          } else if (
            (temp.minRange != 0 && temp.maxRange == Infinity) ||
            (temp.minRange != 0 && temp.maxRange == "")
          ) {
            filterWithDashAsZero.push(temp);
          }
        }
        for (let r = 0; r < tempCampaigns.length; r++) {
          let shouldPass = true;
          for (let i = 0; i < filterWithDashAsZero.length; i++) {
            let currentFilter = filterWithDashAsZero[i];
            if (
              (currentFilter.minRange != 0 && currentFilter.maxRange != Infinity) ||
              (currentFilter.minRange == 0 && currentFilter.maxRange != Infinity) ||
              (currentFilter.minRange != 0 && currentFilter.maxRange == Infinity)
            ) {
              setMinMaxFilterActivated(true);
              let currentCampaign = tempCampaigns[r];
              if (!currentCampaign.campaignStat.length) {
                shouldPass = false;
              }
              for (let m = 0; m < currentCampaign.campaignStat.length; m++) {
                if (
                  currentCampaign.campaignStat[m][currentFilter.id] &&
                  currentCampaign.campaignStat[m][currentFilter.id] !== null
                ) {
                  if (
                    currentCampaign.campaignStat[m][currentFilter.id] <
                    currentFilter.minRange ||
                    (currentFilter.maxRange !== Infinity &&
                      currentCampaign.campaignStat[m][currentFilter.id] >
                      currentFilter.maxRange)
                  ) {
                    shouldPass = false;
                  }
                } else {
                  shouldPass = false;
                }
              }
            } else {
              shouldPass = false;
            }
          }
          if (shouldPass) {
            filteredCampaigns.push(tempCampaigns[r]);
          }
        }
        return filteredCampaigns;
      };

    const handleRedirectForUpdate = (dataId,dataIndex) => {
        const url = `/create-product-set?update=true`;
        const data = productSets?.find((productSet) => productSet?.id === dataId) ?? productSets[dataIndex]
        navigate(url, { state: { data } });
    }

    let tableBody = (
        <div className="main-campaignlist-table-body-container smart-ads-list-table lsit-table-scrollbar">
            {searchfilteredData?.length ? (
                <table className="campaign-list-table" id='smart-ads-table'>
                    <thead className="fixed-table">
                        <tr className="main-table-row-fixed">
                            {dataHeads?.map((th, mainIndex) => {
                                return (
                                    <th
                                        className={
                                            th.isHeadRightAlign
                                                ? "campaig-list-head cl-end-align"
                                                : th.isHeadWithIcon === "sort"
                                                    ? "campaig-list-head active-filter-colmun"
                                                    : "campaig-list-head"
                                        }

                                        style={{
                                            zIndex: mainIndex === 0 ? 99 : ''
                                        }}
                                    >

                                        {th.isHeadWithIcon === "sort" ? (
                                            <div
                                                className={
                                                    !th.isAbbrivation
                                                        ? "d-flex flex-row align-item-center justify-content-end captital-first-letter"
                                                        : "d-flex flex-row align-item-center justify-content-end"
                                                }
                                            >
                                                <span
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    {!th.isAbbrivation
                                                        ? th.fieldName.toLowerCase()
                                                        : th.fieldName}
                                                </span>
                                                <span
                                                    className="spends-icon"
                                                    onClick={() =>
                                                        onSpendFilterClickHandler(th.id, mainIndex)
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            isSortBoxVisible && mainIndex === currentIndex
                                                                ? ascendingIconSvg
                                                                : descendingIconSvg
                                                        }
                                                        className={
                                                            mainIndex === currentIndex
                                                                ? "mt-spends-icon-activated"
                                                                : ""
                                                        }
                                                        alt={"FilterIcon"}
                                                    ></img>
                                                </span>
                                                <div
                                                    className={
                                                        isSortBoxVisible ? "sortBox-container" : "hide"
                                                    }
                                                ></div>
                                            </div>
                                        ) : (
                                            <span className="captital-first-letter"
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                {th.fieldName.toLowerCase()}
                                            </span>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    {searchfilteredData?.length ? (
                        <tbody className="another-tbody">
                            {searchfilteredData?.map((data, index1) => {
                                data = JSON.stringify(data);
                                data = JSON.parse(data);
                                for (let i = 0; i < dataHeads?.length; i++) {
                                    if (!dataHeads[i]?.isDefaultHead) {
                                        data[dataHeads[i]?.id] = data[dataHeads[i]?.id]
                                            ? data[dataHeads[i]?.id]
                                            : "-";
                                    }
                                }
                                return (
                                    <React.Fragment key={index1 + 1}>
                                        <tr key={`${data?.id}`}>
                                            <td className="first-column-sw-btn d-flex justify-content-between text-nowrap">
                                                <span>                                                {data?.product_set}
                                                </span>
                                                <Pencil size={15} onClick={() => {
                                                    handleRedirectForUpdate(data?.id,index1)
                                                }} className='cursor-pointer'/>
                                            </td>
                                            {data?.campaignStat?.length
                                                ? data["campaignStat"].map((campStatInst, indo) => {
                                                    // if (data.campaignStat) {

                                                    for (let i = 0; i < dataHeads.length; i++) {
                                                        if (!dataHeads[i].isDefaultHead) {
                                                            campStatInst[dataHeads[i].id] = campStatInst[
                                                                dataHeads[i].id
                                                            ]
                                                                ? dataHeads[i].id === "spends" ||
                                                                    dataHeads[i].id === "sale_value"
                                                                    ? ` ₹${Math.round(
                                                                        campStatInst[dataHeads[i].id]
                                                                    )}`
                                                                    : dataHeads[i].id === "type"
                                                                        ? `${campStatInst[dataHeads[i].id]}`
                                                                        :

                                                                        metricesWithTwoDecimalPlaces.includes(
                                                                            dataHeads[i].id
                                                                        )
                                                                            ? `${Number(
                                                                                campStatInst[dataHeads[i].id]
                                                                            ).toFixed(2)}`
                                                                            : Math.round(campStatInst[dataHeads[i].id])
                                                                : "-";
                                                        }
                                                    }
                                                    {
                                                        return dataHeads.map((currentDataHead, i) => {
                                                            if (!currentDataHead.isDefaultHead) {
                                                                return (
                                                                    // <td key={i + 2} className="td-with-loader" >
                                                                    <td
                                                                        key={i + 2}
                                                                        className={
                                                                            currentDataHead.isHeadRightAlign
                                                                                ? "cl-end-align td-with-loader"
                                                                                : " td-with-loader"
                                                                        }
                                                                    >
                                                                        {allFetchLoaders.campaignStatsLoader ? (
                                                                            <Spinner shimmer={true} />
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {campStatInst
                                                                            ? campStatInst[currentDataHead.id]
                                                                            : "-"}
                                                                    </td>
                                                                );
                                                            }
                                                        });
                                                    }
                                                })
                                                : dataHeads.map((currentDataHead, i) => {
                                                    if (!currentDataHead.isDefaultHead) {
                                                        return (
                                                            <td
                                                                key={i + 3}
                                                                className={
                                                                    currentDataHead.isHeadRightAlign
                                                                        ? "cl-end-align td-with-loader"
                                                                        : " td-with-loader"
                                                                }
                                                            >
                                                                {allFetchLoaders.campaignStatsLoader ? (
                                                                    <Spinner shimmer={true} />
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </td>
                                                        );
                                                    }
                                                })}
                                        </tr>
                                    </React.Fragment>
                                );
                            })}

                        </tbody>
                    ) : (
                        <div>
                            {/* <p>NO DATA FOUND</p> */}
                        </div>
                    )}
                </table>
            ) : (
                <div>
                    {/* <p>NO DATA FOUND</p> */}
                </div>
            )}
        </div>
    );

    useEffect(() => {
        setIsFilterVisible(filterModalInCampaignListVisibilty);
    }, [filterModalInCampaignListVisibilty]);

    return (
        <div
            className={
                dashboardSideBarVisibiltyState
                    ? "main-content-container-onopen-mode"
                    : "main-content-container-oncolapse-mode"
            }
            id="main-box"
        >
            <Loader visible={allFetchLoaders.mainPageLoader}/>
            <div className='toolkitWrapper'
                style={{ justifyContent: 'space-between' }}
            >
                <div className='toolkitWrapper'
                >
                    <CustomDropDown
                        title={"Product Set Type"}
                        data={["Static", "Dynamic", "Both"]}
                        filterCampaigns={filterCampaigns}
                        targettingValue={filters.product_set_type}
                        type={"product_set_type"}
                        src={dropdownIcon}
                        fontSize={"12px"}
                        onDropdownImghandler={onDropdownImghandler}
                    // data
                    />

                    <div className="filter-icon-container-main mt-3">
                        <div
                            className={
                                minMaxFilterActivated
                                    ? "activated-min-max-filter"
                                    : "hide"
                            }
                        ></div>
                        <img
                            src={settingIcon}
                            onClick={onFilterIconhandler}
                            style={{ cursor: "pointer" }}
                            alt={"settingsIcon"}
                            className={"settings-icon"}
                        ></img>
                    </div>
                </div>
                <div
                    className="nav-button-container"
                >
                    <Link to={"/create-product-set"}>
                        {" "}
                        <Button
                            insideBtnText="Create Portfolio"
                            portfolio={true}
                            id={"create-product-set"}
                            variant="primary"
                            className="primary-btn  width-100 createPortfolioBtn "

                        > Create New</Button>{" "}
                    </Link>
                </div>
            </div>
            <div>
                {
                    <FilterLogicModal
                        filterFor="smartAdsList"
                        show={isFilterVisible}
                    ></FilterLogicModal>
                }

                <div className="campaign-list-table-data">
                    <Stack className="campaign-list-main-table">
                        <TableRb table={tableBody} noDataFound={!searchfilteredData?.length && !allFetchLoaders?.mainPageLoader }></TableRb>
                    </Stack>
                </div>
            </div>
        </div>
    )
}

export default ProductSetsList