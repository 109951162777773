import FilterLogicModal from "../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRef } from "react";
import CalenderComponent from "../../components/calender/Calender";
import closeIcon from "../../assets/icons/close_icon.png";
import { useGlobalContext } from "../../context/global";
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.svg";
import Chart from "../../components/Reports/Chart";
import cubejs from "@cubejs-client/core";
import useToken from "../../helper/Reports/useToken";
import ReportFilters from "../../components/Reports/Filters";
import SectionTitle from "../../components/sectionTitle";
import previousDays from "../../helper/Reports/common";
import Loader from "../../components/loader";
import useInitialLoad from "../../hooks/useInitialLoad";
import Breadcrumb from '../../components/Breadcrumb/ReportsBreadCrumb';
import Canvas from '../../helper/Screenshot/canvas'
import downloadIcon from "../../assets/svgs/download.svg";
import {formatPieChartData,createQueryURL,dataQueryParser,getSearchParamsObject,objectoFiltering} from "../../components/Reports/Components/utils";
import {useSearchParams } from "react-router-dom";

const SearchTermReport = (props) => {
  
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [allLoaders, setAllLoaders] = useState({ childRowLoader: false });
  const [currentAsin, setCurrentAsin] = useState("");
  const [token, refresh] = useToken("");
  const { cubeToken, setCubeToken } = useGlobalContext();
  const { clientAccountId } = useGlobalContext();
  const cubejsApi = cubejs(cubeToken,{ apiUrl: process.env.REACT_APP_CUBE_JS_URL});
  const [tableView, setTableView] = useState("table");
  const [showFilters, setShowFilters] = useState(true);
  const [searchParams,setSearchParams] = useSearchParams({});
  const allParams = new URLSearchParams(window.location.search);
  const paramsObject = getSearchParamsObject(allParams);
  const previousFiltering = objectoFiltering(paramsObject,"AmazonSearchtermTable")
  const viewType = allParams.get('viewtype') ?? 'Performance View';
  const [currentViewType, setCurrentViewType] = useState(viewType);
  const isComparisonView = currentViewType === 'Comparison View';
  const { calendarDate, setCalendarDate, dateDifference, setDateDifference,applyCalendar, multiCalendarDate } = useGlobalContext();
  const formatDate = (date) => {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split("T")[0].toString();
  };
  let startDate = formatDate(multiCalendarDate.selection1.startDate);
  let endDate = formatDate(multiCalendarDate.selection1.endDate);
  let startDatePrevious = formatDate(
        multiCalendarDate.selection2.startDate
      );
  let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
  const cubeQueryTimeDimensionsFormatted = !isComparisonView ? [{dimension:"AmazonSearchtermTable.date",dateRange: [
    formatDate(calendarDate[0].startDate),
    formatDate(calendarDate[0].endDate),
  ]}] : [{dimension:"AmazonSearchtermTable.date",compareDateRange:[
    [startDate, endDate],
    [startDatePrevious, endDatePrevious],
  ]}]
  const [campaignType, setCampaignType] = useState([]);
  const [campaignFormat, setCampaignFormat] = useState([]);
  const [targetingType, setTargetingType] = useState([]);
  const [productSet, setProductSet] = useState([]);
  const [campaignStatus, setCampaignStatus] = useState([]);
  const [portfolioName, setPortfolioName] = useState([]);
  const [childrenOne, setChildrenOne] = useState({});
  const [childDataIndex, setChildDataIndex] = useState(0);
  const [initialLoad,setInitialLoad] = useInitialLoad()
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [paginationLimit, setPaginationLimit] = useState(250);
  const [paginationOffset, setPaginationOffset] = useState(0);
  const [searchValue, setSearchValue] = useState('')
  const [drrView, setDrrView] = useState("")
  const [summaryTotal, setSummaryTotal] = useState({})
  const [summaryComparisonTotal, setSummaryComparisonTotal] = useState({})

  const [cubeQueryMeasure, setCubeQueryMeasure] = useState([
    "AmazonSearchtermTable.cost",
    "AmazonSearchtermTable.costDRR",
    "AmazonSearchtermTable.impressions",
    "AmazonSearchtermTable.impressionsDRR",
    "AmazonSearchtermTable.clicks",
    "AmazonSearchtermTable.clicksDRR",
    "AmazonSearchtermTable.ctr",
    "AmazonSearchtermTable.ctrDRR",
    "AmazonSearchtermTable.cpc",
    "AmazonSearchtermTable.cpcDRR",
    "AmazonSearchtermTable.cpm",
    "AmazonSearchtermTable.cpmDRR",
    "AmazonSearchtermTable.conversions14d",
    "AmazonSearchtermTable.conversions14dDRR",
    "AmazonSearchtermTable.conversion_rate",
    "AmazonSearchtermTable.conversion_rateDRR",
    "AmazonSearchtermTable.sales14d",
    "AmazonSearchtermTable.sales14dDRR",
    "AmazonSearchtermTable.average_order_value",
    "AmazonSearchtermTable.average_order_valueDRR",
    "AmazonSearchtermTable.acos",
    "AmazonSearchtermTable.acosDRR",
    "AmazonSearchtermTable.roas",
    "AmazonSearchtermTable.roasDRR",
  ]);
  const [cubeQueryOrder, setCubeQueryOrder] = useState([
    ["AmazonSearchtermTable.cost", "desc"]
  ]);

  const [cubeChildRowQueryOrder, setCubeChildRowQueryOrder] = useState([
    ["AmazonSearchtermTable.cost", "desc"],
  ]);

  const [cubeQueryDimensions, setCubeQueryDimensions] = useState([
    "AmazonSearchtermTable.query",
  ]);

  const [cubeChildRowsQueryDimensions, setCubeChildRowsQueryDimensions] = useState([
    "AmazonSearchtermTable.query",
    "AmazonSearchtermTable.adTargetingType"
  ]);

  const [cubeQueryTimeDimensions, setCubeQueryTimeDimensions] = useState(cubeQueryTimeDimensionsFormatted);
  const [cubeQueryFilters, setCubeQueryFilters] = useState([
    {
      member: "AmazonSearchtermTable.profileId",
      operator: "equals",
      values: [clientAccountId],
    },
    ...previousFiltering
  ]);
  const [cubeChildRowsQueryFilters, setCubeChildRowsQueryFilters] = useState([
    {
      member: "AmazonSearchtermTable.profileId",
      operator: "equals",
      values: [clientAccountId],
    },
    ...previousFiltering
  ]);

  const [resultSet, setResultSet] = useState({});
  const [paginationResultSet, setPaginationResultSet] = useState({});
  const [chartFilters, setChartFilters] = useState({
    spend:"cost",
    revenue:"sales14d"
  })
  var spendResultSet = formatPieChartData(resultSet, chartFilters.spend,"AmazonSearchtermTable", "query");
  var revenueResultSet = formatPieChartData(resultSet, chartFilters.revenue,"AmazonSearchtermTable", "query");
  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );
  const enabledfilters = ["Campaign Type","Campaign Format","Targeting Type","Product Set","Campaign Status","Portfolio Name"]
  const isRangeFiltersApplied = cubeQueryFilters?.some((data) => {
    return (data?.operator?.toLowerCase() === 'gte' || data?.operator?.toLowerCase() === 'lte')
  })

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if(queryParams.get('start') &&  queryParams.get('end')) {
        setDate(queryParams.get('start'), queryParams.get('end'));
    }
    // const stateSetters = ['campaignFormat','targetingType','productSet','portfolioName','targetStatus','campaignType'];
    // stateSetters.forEach(value => {
    //     if(queryParams.get(value)) {
    //         filterCampaigns(queryParams.get(value).split(","), value)
    //     }
    //   });
  }, []);

  const setDate = (currentDate, previousDate) => {
    const dates = {
      startDate: new Date(currentDate),
      endDate: new Date(previousDate),
      key: "selection",
    };
    setDateDifference("Custom");
    setCalendarDate([dates]);
    setCubeQueryTimeDimensions((prev) => {
      let data = [...prev];
      data[0]["dateRange"] = [
        formatDate(dates.startDate),
        formatDate(dates.endDate),
      ];
      return data;
    });
  };

  useEffect(() => {
    if(currentViewType !== "Comparison View") {
        const startDate = formatDate(calendarDate[0].startDate);
        const endDate = formatDate(calendarDate[0].endDate);
        if (startDate !== cubeQueryTimeDimensions[0]["dateRange"][0] || endDate !== cubeQueryTimeDimensions[0]["dateRange"][1]) {
            setIsLoaderVisible(true)
            setCubeQueryTimeDimensions((prev) => {
                let data = [...prev];
                data[0]["dateRange"] = [startDate, endDate];
                return data;
            });
        }
    } 
}, [applyCalendar]);

useEffect(() => {
    if(currentViewType === "Comparison View") {
        let startDate = formatDate(multiCalendarDate.selection1.startDate);
        let endDate = formatDate(multiCalendarDate.selection1.endDate);
        let startDatePrevious = formatDate(multiCalendarDate.selection2.startDate);
        let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
        let apiDateRange = cubeQueryTimeDimensions[0]["compareDateRange"]
        if (startDate !== apiDateRange[0][0] || endDate !== apiDateRange[0][1] || startDatePrevious !== apiDateRange[1][0] || endDatePrevious !== apiDateRange[1][1]) {
            setIsLoaderVisible(true)
            setCubeQueryTimeDimensions((prev) => {
                let data = [...prev];
                data[0]["compareDateRange"] = [[startDate, endDate], [startDatePrevious, endDatePrevious]];
                return data;
            });
        } 
    }
},[applyCalendar])

  useEffect(() => {
    if (cubeToken) {
      cubejsApi.load({
            measures: cubeQueryMeasure,
            order: cubeQueryOrder,
            dimensions: cubeQueryDimensions,
            filters: cubeQueryFilters,
            timeDimensions: cubeQueryTimeDimensions,
            limit: paginationLimit,
            offset: (paginationOffset*paginationLimit), 
            total: true,
          },{
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((result) => {
          if(paginationOffset>0) {
            setPaginationResultSet(result)
          } else {
            setResultSet(result);
          }
          if(!isRangeFiltersApplied) { // Only remove when range filters are not applied if applied then it will be removed from different useEffect (summary calculation)
            setIsLoaderVisible(false)
          }
          setInitialLoad(false)
          if(currentViewType === 'Comparison View') {
            setTableView("ComparisonTable")
          } else {
              setTableView("table")
          }
        }).catch((error) => {
          setIsLoaderVisible(false)
          if(error.message === 'Invalid token') {
              setCubeToken(false)
              localStorage.setItem("cubeToken", null);
              refresh()
          }
        });;
    }
  }, [cubeQueryFilters,cubeQueryTimeDimensions, cubeToken,cubeQueryOrder,paginationOffset]);


  const calculateSummaryData =(data) => {
    let totalSummary = {}
    Object?.keys((data[0]))?.forEach((key) => {
      const totalMetric = data?.reduce((acc,curr) => {
        return acc + parseFloat(curr[key])
       },0)
       totalSummary[key?.split(".")?.pop()] = totalMetric
     });
    return totalSummary
  }


  useEffect(() => {
    if(cubeToken) {
      setIsLoaderVisible(true)
      const dimensions = isRangeFiltersApplied ? [
        "AmazonSearchtermTable.query",
      ] : []
      cubejsApi.load({
        measures: cubeQueryMeasure,
        order: cubeQueryOrder,
        filters: cubeQueryFilters,
        timeDimensions: cubeQueryTimeDimensions,
        dimensions,
        ...(isRangeFiltersApplied && {limit:50000})
      },{
        headers: {"Access-Control-Allow-Origin": true},
      }
    ).then((result) => {
      if(!isRangeFiltersApplied) {
        if(result?.loadResponse?.results[0]?.data[0]) {
          const modifiedObject = {};
          let originalObject = result?.loadResponse?.results[0]?.data[0]
          Object.keys(originalObject).forEach(key => {
            const newKey = key?.split(".")?.pop();
            modifiedObject[newKey] = originalObject[key];
          });
          setSummaryTotal(modifiedObject)
        }
        if(result?.loadResponse?.results[1]?.data[0]) {
          const modifiedObject = {};
          let originalObject = result?.loadResponse?.results[1]?.data[0]
          Object.keys(originalObject)?.forEach(key => {
            const newKey = key.split(".")?.pop();
            modifiedObject[newKey] = originalObject[key];
          });
          setSummaryComparisonTotal(modifiedObject)
        }
      } else if(isRangeFiltersApplied) {
        const results = result?.loadResponse?.results[0]?.data
        const comparisonResults = result?.loadResponse?.results[1]?.data
        if(results?.length) {
          const totalObj = calculateSummaryData(results)
          setIsLoaderVisible(false)
          setSummaryTotal(totalObj)
        }
        if(comparisonResults?.length) {
          const totalObj = calculateSummaryData(comparisonResults)
          setIsLoaderVisible(false)
          setSummaryComparisonTotal(totalObj)
        }
        setIsLoaderVisible(false)
      }
    }).catch((error) => {
      if(error.message === 'Invalid token') {
          setCubeToken(false)
          localStorage.setItem("cubeToken", null);
          refresh()
      }
    });;
    }
  },[cubeQueryFilters,cubeQueryTimeDimensions,cubeQueryOrder]) // Run summary calculation when the filters or date dimensions or order get's changed

  const filterCampaigns = (e, type) => {
    setChildrenOne({})
    setPaginationOffset(0)
    setIsLoaderVisible(true)
    const parsedQueryFilters = dataQueryParser(JSON.parse(JSON.stringify(cubeQueryFilters)),e,type,"AmazonSearchtermTable");
    const parsedParams = createQueryURL(parsedQueryFilters);
    parsedParams['viewtype'] = currentViewType;
    const previousParams = new URLSearchParams(window.location.search)
    if(previousParams.get('metricViewType')) {
      parsedParams['metricViewType'] = previousParams.get('metricViewType')
    }
    setSearchParams(parsedParams);
    setCubeQueryFilters((prev) => {
      let data = dataQueryParser(prev,e,type,"AmazonSearchtermTable");
      return data;
    });
    setCubeChildRowsQueryFilters((prev) => {
      let data = dataQueryParser(prev,e,type,"AmazonSearchtermTable")
      return data;
    });
    if (type === "campaignType") {
        setCampaignType(e);
      } else if (type === "campaignFormat") {
        setCampaignFormat(e);
      } else if (type === "targetingType") {
        setTargetingType(e);
      } else if (type === "productSet") {
        setProductSet(e);
      } else if (type === "campaignStatus") {
        setCampaignStatus(e);
      } else if (type === "portfolioName") {
        setPortfolioName(e);
      }
  };

  const expendChange = (data, type, index) => {
      let prevData = [...cubeChildRowsQueryFilters]
      prevData.forEach((x, i) => {
        if (x.member === 'AmazonSearchtermTable.query') {
          prevData.splice(i, 1);
        }
      });
      prevData.push({
          member: 'AmazonSearchtermTable.query',
          operator: "equals",
          values: [data],
        });
        
      setCubeChildRowsQueryFilters(prevData);
      setChildDataIndex(data)
  };

  useEffect(() => {
    let getdata = false
    let prevData = [...cubeChildRowsQueryFilters]
    prevData.forEach((x, i) => {
        if (x.member === 'AmazonSearchtermTable.query') {
            getdata = true
        }
      });
      if(getdata) {
        cubejsApi.load({
            measures: cubeQueryMeasure,
            order: cubeChildRowQueryOrder,
            dimensions: cubeChildRowsQueryDimensions,
            filters: cubeChildRowsQueryFilters,
            timeDimensions: cubeQueryTimeDimensions,
            limit: 1000,
          },{
            headers: {"Access-Control-Allow-Origin": true,},
          }
        ).then((childResultSet) => {
          replaceToStaticValues(childResultSet);
          setChildrenOne((prev) => { 
            return {...prev,[childDataIndex]: childResultSet}
          })
        }).catch((error) => {
          if(error.message === 'Invalid token') {
              setCubeToken(false)
              localStorage.setItem("cubeToken", null);
              refresh()
          }
        });
      }
    
  },[cubeChildRowsQueryFilters])

  function replaceToStaticValues(result) {
    return result.loadResponse.results[0].data.forEach((item) => {
        if (item['AmazonSearchtermTable.adTargetingType'] === "TARGETING_EXPRESSION") {
          item['AmazonSearchtermTable.adTargetingType'] = "PRODUCT";
        } else if (item['AmazonSearchtermTable.adTargetingType'] === "TARGETING_EXPRESSION_PREDEFINED") {
          item['AmazonSearchtermTable.adTargetingType'] = "AUTOMATIC";
        }
      });
  }

  const calenderIconRefForCampaignlist = useRef(null);

  const closeCalender = (e) => {
    if (
      calenderIconRefForCampaignlist.current &&
      showCustomDateBox &&
      !calenderIconRefForCampaignlist.current.contains(e.target)
    ) {
      setShowCustomDateBox(false);
    }
  };

  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeCalender);
    return () => {
      document.removeEventListener("mousedown", closeCalender);
    };
  }, [showCustomDateBox]);
 

  useEffect(() => {
    setIsFilterVisible(filterModalInCampaignListVisibilty);
  }, [filterModalInCampaignListVisibilty]);

  const handleComparisonView = (type) => {
    setChildrenOne({})
    if(type === 'Comparison View') {
        setPaginationOffset(0)
        setCubeQueryTimeDimensions([{
              dimension: "AmazonSearchtermTable.date",
              compareDateRange: [[startDate, endDate], [startDatePrevious, endDatePrevious]],
            },
          ]);
          setIsLoaderVisible(true)
    } else if(type !== 'Comparison View' && currentViewType === 'Comparison View') {
        setPaginationOffset(0)
        setCubeQueryTimeDimensions([{
            dimension: "AmazonSearchtermTable.date",
            dateRange: [formatDate(calendarDate[0].startDate), formatDate(calendarDate[0].endDate)],
          },
        ]);
        setIsLoaderVisible(true)
    }
    setCurrentViewType(type)
}




const sortData = (key, order) => {
  setPaginationOffset(0)
  setIsLoaderVisible(true)
  setCubeQueryOrder([
    [key, order]
  ]);

}

const pagination = (data) => {
  setPaginationOffset(paginationOffset+1)
}


function dataFilter(data) {
  setChildrenOne({})
  setPaginationOffset(0)
  setIsLoaderVisible(true)
  const table = "AmazonSearchtermTable.";
  let tempFilters = [...cubeQueryFilters];
  Object.entries(data).forEach(([key, value]) => {
    // tempFilters.forEach((x, i) => {
    //   if (x.member === `${table}${key}`) {
    //     tempFilters.splice(i, 1);
    //   }
    // });
    tempFilters = tempFilters?.filter((x) => {
      return x.member !== `${table}${key}`
    })
    if (value.min) {
      tempFilters.push({
        member: `${table}${key}`,
        operator: 'gte',
        values: [parseFloat(value.min)]
      });
    }
    if (value.max) {
      tempFilters.push({
        member: `${table}${key}`,
        operator: 'lte',
        values: [parseFloat(value.max)]
      });
    }
  })
  setCubeQueryFilters(tempFilters);
  setCubeChildRowsQueryFilters(tempFilters);
}

function handleSearch(data) {
  setPaginationOffset(0)
  setIsLoaderVisible(true)
  let prevData = [...cubeQueryFilters]
    prevData.forEach((x, i) => {
      if (x.member === cubeQueryDimensions[0]) {
        prevData.splice(i, 1);
      }
    });
    if(data.input) {
      prevData.push({
          member: cubeQueryDimensions[0],
          operator: data.select,
          values: [data.input],
        });
    }
    setShowFilters(data)
    setCubeQueryFilters(prevData);
    setCubeChildRowsQueryFilters(prevData);
}

const handleDownload = () => {
  Canvas({ name: 'search-term-report' });
};

const handleDrrView = (value, type) => {
  setDrrView(value)
  setChildrenOne({})
  if(value !== 'drr') {
      setChartFilters({spend:"cost",revenue:"sales14d"})
  } else {
      setChartFilters({spend:"costDRR",revenue:"sales14dDRR"})
  }
};
  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
      <Loader visible={initialLoad}></Loader>
        <div style={{marginLeft: "auto"}}>
        <button
          className="date-container date-btn-campaignlist d-none"
          onClick={dateInputClickHandler}
        >
          <div className="d-flex justify-conntent-center">
            <span className="date-btn-text">{dateDifference}</span>
          </div>
          <button className="btn-calender-img-holder">
            <span className="">
              <img
                className="dropdown-img-for-select"
                src={selectSideIcon}
                alt={"SelectSideIcon"}
              ></img>

              <img
                className="calender-icon-for-campaignlist"
                src={CalenderIconForCampaignList}
                alt={"CalendarIcon"}
              ></img>
            </span>
          </button>
        </button>
      </div>

      <div
        ref={calenderIconRefForCampaignlist}
        className={
          showCustomDateBox ? "calender-inclasslist-container" : "hide"
        }
      >
        <CalenderComponent  setShowCustomDateBox={setShowCustomDateBox}></CalenderComponent>
        <span className="calender-close-icon-campaignlist">
          <img
            onClick={() => setShowCustomDateBox(false)}
            src={closeIcon}
            alt={"CloseIcon"}
          ></img>
        </span>
      </div>
      
      <Breadcrumb />
      <SectionTitle sectionTitleHeading='Search Term Report'></SectionTitle>
      <div className="d-flex justify-content-between mt-4 mb-4 gap-4 position-relative" id="downloadChart" >
            <div onClick={handleDownload} className="downloadChartWrapper pie" id="downloadChartWrapper">
                    <img src={downloadIcon} className="cursor-pointer-global DownloadIcon" alt="overview-settings-tile" />
            </div>
        <div className="w-100 min-h-300 pie-container">
          <h4 className="sub-heading-pie f-20 p-4">Spends</h4>
          <Chart
            type="apexpie"
            resultSet={spendResultSet}
            classes={"marketing-reports"}
            display={"spends"}
          />
        </div>
        <div className="w-100 min-h-300 pie-container">
          <h4 className="sub-heading-pie f-20 p-4">Revenue</h4>
          <Chart
            type="apexpie"
            resultSet={revenueResultSet}
            classes={"marketing-reports"}
            display={"spends"}
          />
        </div>
      </div>

      <ReportFilters handleDropdown={filterCampaigns} enabledfilters={enabledfilters} />

      <div>
        {
          <FilterLogicModal
            filterFor="campaignlist"
            show={isFilterVisible}
          ></FilterLogicModal>
        }
      </div>
      <div className="">
          <Chart
            type={tableView}
            shimmerEffect={isLoaderVisible}
            resultSet={resultSet}
            classes={"sortByApi wraptext marketing-reports"}
            display={'campaign_format'}
            pageName={'searchTerm'}
            handleChange={expendChange}
            childrenOneData={childrenOne}
            childrenOneHeader={'Ad Targeting Type'}
            sortData={sortData}
            pagination={pagination}
            drrView={handleDrrView} drrViewType={drrView}
            FunnelViews={["Performance View","Funnel View","Comparison View","Custom View"]} handleComparisonView={handleComparisonView} CurrentViewType={currentViewType} page="expendable" dataFilter={dataFilter} 
            handleSearch={handleSearch}
            showFilter={showFilters}
            paginationResultSet={paginationResultSet}
            summaryTotal={summaryTotal}
            summaryComparisonTotal={summaryComparisonTotal}
          />
      </div>
    </div>
  );
};

export default SearchTermReport;