import { getChildRowsData } from "./getChildRowsData";

/**
 * @Note This method check if the child data is present or not if the it is present then it shows it otherwise calls API to get it.
 * @param {*} currentState
 * @param {*} parentBoxState
 * @param {*} currentFiltering
 * @returns Updated State according to  certain Conditions || State will be Array of Promises.
 */

export const selectAllcheckBoxes = (currentState, checkboxState,isGlobalView,isNonAdvertising) => {
  let updatedState = currentState.map((ParentAd) => {
    if (!ParentAd.isPlusIconDisabled && isGlobalView) {
      ParentAd.isTargetChecked = checkboxState;
      ParentAd.childRows = ParentAd.childRows.map((childAd) => {
        if(childAd.id) {
          childAd.isTargetChecked = checkboxState;
        }
        return childAd;
      });
    }
    if(!isGlobalView && ParentAd?.id) {
      ParentAd.isTargetChecked = checkboxState;
    }
    // If it is a Non Advertising Campaign then we select & in granular view.
    if(!isGlobalView && !ParentAd?.id && isNonAdvertising) {
      ParentAd.isTargetChecked = checkboxState;
    }
    return ParentAd;
  });
  return updatedState;
};
