import React, { useEffect } from "react";
import { useState } from "react";
import { masterApi } from "../../../apis/masterApi";
import { useGlobalContext } from "../../../context/global";
import { useDispatch } from "react-redux";
import { CampaignCreationActions } from "../../../redux/slices/CampaignCreation";
// import dropdownIcon from "../../../assets/icons/downArrowForSplitButton.png";
import CustomDropDown from "../../../components/DropDown/CustomDropDown";
const ChoosePortfolio = (props) => {
  const dispatch = useDispatch();
  const [portfolios, setPortfolios] = useState([]);
  const [choosePortfolio, setChoosePortfolio] = useState("All");
  const [choosePortfolioForWithoutLabelBtn, setChoosePortfolioForWithoutLabelBtn] = useState("Portfolios");
  const { portfolio, setCampaignData, campaignData } = useGlobalContext();
  const portfolioData = ["All"];
  const getPortfolios = async () => {
    const data = {
      source: "amazon",
    };
    const result = await masterApi(data, "getPortfolios", false);

    if (result?.data?.data) {
      setPortfolios(result?.data?.data);
    }
    dispatch(CampaignCreationActions?.getPortfolio(result?.data?.data));
  };

  const handleChange = (e) => {
    // props.setTargettingType(e);
    setCampaignData({ ...campaignData, amz_portfolio_id: e.target.value });
  };

  // const handleChange = (e) => {
  //   setCampaignData({ ...campaignData, "amz_portfolio_id": e.target.value })
  // }

  useEffect(() => {
    getPortfolios();
  }, [portfolio]);
  for (let i = 1; i < portfolios.length; i++) {
    portfolioData.push(portfolios[i].name);
  }

  return (
    <>
      <div
        className={props.className ? props.className : "select-drop"}
        style={{ overFlow: "scroll", color: "black" }}
      >
        {props.moreOptions ? (
          <>
            <CustomDropDown
              title={props.title}
              filterCampaigns={props.filterCampaigns}
              targettingValue={choosePortfolio}
              onDropdownImghandler={props.onDropdownImghandler}
              src={props.src}
              type={props.type}
              checkPortfolio={props.moreOptions}
              portfolios={portfolios}
              setChoosePortfolio={setChoosePortfolio}
            />
          </>
        ) : props.portfolioWithoutLabel ?
          <>
            <CustomDropDown
              title={props.title}
              filterCampaigns={props.filterCampaigns}
              targettingValue={choosePortfolioForWithoutLabelBtn}
              onDropdownImghandler={props.onDropdownImghandler}
              src={props.src}
              type={props.type}
              checkPortfolio={props.moreOptions}
              portfolios={portfolios}
              setChoosePortfolioForWithoutLabelBtn={setChoosePortfolioForWithoutLabelBtn}
              // setChoosePortfolio={setChoosePortfolio}
            />
          </> : (
            <select
              className="form-select"
              aria-label="Default select example"
              // onChange={props.onChange}
              onChange={handleChange}
            >
              {portfolios.map((portfolio, i) => {
                return i === 0 ? (
                  <option value="null">No Portfolio</option>
                ) : (
                  <option
                    key={portfolio.platform_id}
                    value={portfolio.platform_id}
                  >
                    {portfolio.name}
                  </option>
                );
              })}
            </select>
          )}{" "}
      </div>
    </>
  );
};

export default ChoosePortfolio;
