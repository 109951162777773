import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Toast.css";
import { useGlobalContext } from "../../context/global.js";

const Toast = (props) => {
  const { alert, setAlert, alertDetails } = useGlobalContext();
  const { position, autoDeleteTime, toast } = alertDetails;

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert(false);
    }, autoDeleteTime);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {alert && (
        <div className={`notification-container ${position}`}>
          <div
            className={`notification custom-toast ${position}`}
            style={{ backgroundColor: toast.backgroundColor }}
          >
            <button onClick={() => setAlert(false)}>X</button>
            {/* <div className="notification-image">
                                   <img src={toast.icon} alt="" />
                               </div> */}
            <div>
              <p className="notification-title">{toast.title}</p>
              <p className="notification-message">{toast.description}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Toast.propTypes = {
  position: PropTypes.string,
  autoDeleteTime: PropTypes.number,
};

export default Toast;
