import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import PrimaryBtnRB from "../Buttons/primaryBtnRB.js/primaryBtnRB";
import SecondaryBtnRb from "../Buttons/secondaryBtnRb/secondaryBtnRb";
import SectionTitle from "../sectionTitle";
import DataTable from "../Tables/table";
import TableRb from "../Tables/tableRb";
import axios from "axios";
import Loader from "../loader";
import { masterApi } from "../../apis/masterApi";
import "./productSummary.css";
import { useGlobalContext } from "../../context/global";
import redirect from "../../constant/Redirect";
import { useNavigate } from "react-router-dom";
import ProductNameTd from "../Tables/TableComponents/productNameTd";
import { encodeData } from "../../helper/common/encodeData";
const ProductSummary = ({portfolios,portfoliosIds}) => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [productSummaryLowStockProduct, setProductSummaryLowStockProduct] =
    useState([]);
  const [productSummaryZeroSpendProduct, setProductSummaryZeroSpendProduct] =
    useState([]);
  const [productSummaryActiveBtn, setProductSummaryactiveBtn] = useState(
    "product-summary-low-stock-product"
  );
  const { calendarDate, setCalendarDate,applyCalendar } = useGlobalContext();
  const navigate = useNavigate();
  const productSummaryLowStockProductTableHead = [
    "ASIN",
    "Product Name",
    "Last 30 Days Unit Sold",
    "Inventory Available",
    "Suggested Stock"
  ];

  const productSummaryZeroSpendProductTableHead = [
    "ASIN",
    "Product Name",
    "Last 30 Days Unit Sold",
    "Inventory Available"
  ];

  const requestDataForLowStockProducts =  {
    report_name: "low_stock_product_summary",
    level: "account",
    platform: "amazon",
    fields:
      "product_id,product_quantity,orders,spends,product_name,image_url",
    start_date: `${formatDateFn(calendarDate[0].startDate)}`,
    end_date: `${formatDateFn(calendarDate[0].endDate)}`,
    ...(portfolios.length && {portfolio_ids:portfoliosIds?.join(',')})
    // date_preset: "last_7d",
  };

  const portfolioFilterForZeroSpend = portfolios.length ? [{field_name:"portfolio_name",operator:"IN",values:portfolios}] : []

  const filteringForZeroSpendProducts = [
    {
      "field_name": "spends",
      "operator": "EQUALS",
      "value": 0
    },
    ...portfolioFilterForZeroSpend
  ]
  

  const requestDataForZeroSpendsProducts = {
    level: "account",
        platform: "amazon",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        // date_preset: "last_30d",
        fields:
          "product_id,product_quantity,orders,spends,product_name,image_url",
        time_increment: "all_days",
        group_by: "product_id",
        filtering:encodeData(filteringForZeroSpendProducts),
        order_by: "orders",
        limit: 5,
  }

  const requestDataForCurrentActiveState = productSummaryActiveBtn === "product-summary-low-stock-product" ? requestDataForLowStockProducts : requestDataForZeroSpendsProducts
  const dataToBeStateInRelevantState = productSummaryActiveBtn === "product-summary-low-stock-product" ? setProductSummaryLowStockProduct : setProductSummaryZeroSpendProduct

  function formatDateFn (date) {
    const selectedDate = new Date(date);
    const formattedDate =
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear();
    return (
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear()
    );
  };

  // useEffect(() => {
  //   formatDateFn(calendarDate[0].startDate);
  // }, []);

  async function productSummaryLowStockProductAPI() {
    setIsLoaderVisible(true);
    try {
      const data = {
        report_name: "low_stock_product_summary",
        level: "account",
        platform: "amazon",
        fields:
          "product_id,product_quantity,orders,spends,product_name,image_url",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        // date_preset: "last_7d",
      };
      const result = productSummaryActiveBtn === "product-summary-low-stock-product" ? await masterApi(requestDataForCurrentActiveState, "analytics") : await masterApi(requestDataForCurrentActiveState)
      if (redirect(result)) return navigate("/login");
      // setProductSummaryLowStockProduct(result.data.data);
      dataToBeStateInRelevantState(result?.data?.data)
      setIsLoaderVisible(false);
      return result;
    } catch (e) {
      setIsLoaderVisible(false);
    }
  }

  async function productSummaryZeroSpendProductAPI() {
    setIsLoaderVisible(true);
    try {
      const data = {
        level: "account",
        platform: "amazon",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        // date_preset: "last_30d",
        fields:
          "product_id,product_quantity,orders,spends,product_name,image_url",
        time_increment: "all_days",
        group_by: "product_id",
        filtering:
          "%5B%7B%22field_name%22%3A%22spends%22%2C%22operator%22%3A%22EQUALS%22%2C%22value%22%3A0%7D%5D",
        order_by: "orders",
        limit: 5,
      };
      const result = await masterApi(data);
      setProductSummaryZeroSpendProduct(result.data.data);
      setIsLoaderVisible(false);
      return result;
    } catch (e) {
      setIsLoaderVisible(false);
    }
  }

  useEffect(() => {
    productSummaryLowStockProductAPI();
    // productSummaryZeroSpendProductAPI();
  }, [productSummaryActiveBtn, applyCalendar , portfolios]);//[productSummaryActiveBtn, calendarDate]

  let productSummaryLowStockProductTableData = (
    <table className="w-100">
      <thead>
        <tr className="colspan-head">
          {productSummaryLowStockProductTableHead.map((th) => {
            return <th key={th}>{th} </th>;
          })}
        </tr>
      </thead>

      <tbody>
        {productSummaryLowStockProduct?.map((pslspData) => {
          return (
            <tr key={pslspData?.product_id}>
              <td>{pslspData?.product_id}</td>
              <td>
              <ProductNameTd
                product_name={pslspData?.product_name}
                image_url={pslspData?.image_url}
                asin={pslspData.product_id}
              />
              </td>
              <td>{pslspData?.orders}</td>
              <td>{pslspData?.quantity}</td>
              <td>{pslspData?.suggested_stock}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  let productSummaryZeroSpendProductTableData = (
    <table className="w-100">
      <thead>
        <tr className="colspan-head">
          {productSummaryZeroSpendProductTableHead.map((th) => {
            return <th key={th}>{th} </th>;
          })}
        </tr>
      </thead>

      <tbody>
        {productSummaryZeroSpendProduct?.map((pszspData) => {
          return (
            <tr key={pszspData?.product_id}>
              <td>{pszspData?.product_id}</td>
              <td>
              <ProductNameTd
                product_name={pszspData?.product_name}
                image_url={pszspData?.image_url}
                asin={pszspData.product_id}
              />
              </td>
              <td>{pszspData?.spends}</td>
              <td>{pszspData?.orders}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  const onButtonClickHandler = (e) => {
    setProductSummaryactiveBtn(e.target.id);
  };
  return (
    <div className="section-main-body">
      <Loader visible={isLoaderVisible}></Loader>
      <Stack className="prod-summary-section-container">
        <Stack direction="horizontal" gap={3}>
          <SectionTitle sectionTitleHeading="Product Summary"></SectionTitle>
        </Stack>
        <Stack direction="vertical" gap={3}>
          <Stack
            direction="horizontal"
            gap={3}
            className="product-sum-btn-container"
          >
            {/* <PrimaryBtnRB insideBtnText="Low Stock Products"></PrimaryBtnRB>
                    <SecondaryBtnRb secondaryBtnText="Zero Spend Products"></SecondaryBtnRb> */}
            <SecondaryBtnRb
              secondaryBtnText="Low Stock Products"
              className={
                productSummaryActiveBtn === "product-summary-low-stock-product"
                  ? "primary-active-btn"
                  : "primary-inactive-btn"
              }
              id="product-summary-low-stock-product"
              onClick={onButtonClickHandler}
            ></SecondaryBtnRb>
            <SecondaryBtnRb
              secondaryBtnText="Zero Spend Products"
              className={
                productSummaryActiveBtn === "product-summary-zero-spend-product"
                  ? "primary-active-btn"
                  : "primary-inactive-btn"
              }
              id="product-summary-zero-spend-product"
              onClick={onButtonClickHandler}
            ></SecondaryBtnRb>
          </Stack>
          <Stack direction="vertical">
            {/* <DataTable tableHeadRow={productSummaryTableHead} TableData={productSummarytableData}></DataTable> */}
            <div className="product-sum-table">
              <TableRb
                table={
                  productSummaryActiveBtn ===
                  "product-summary-low-stock-product"
                    ? productSummaryLowStockProductTableData
                    : productSummaryActiveBtn ===
                      "product-summary-zero-spend-product"
                    ? productSummaryZeroSpendProductTableData
                    : null
                }
              ></TableRb>
            </div>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default ProductSummary;
