import React, { useState } from "react";
import { Pagination } from "react-bootstrap";
const TableRowWithPagination = ({ rows, limit,colSpanLength }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastRow = currentPage * limit;
  const indexOfFirstRow = indexOfLastRow - limit;
  const currentRows = rows?.slice(indexOfFirstRow, indexOfLastRow) ?? []
  const {isNext,isPrev,totalPages} = getPaginationInfo(rows.length,limit,currentPage)
  function getPaginationInfo(totalNumber, limit, pageNumber) {
    // Calculate total number of pages
    const totalPages = Math.ceil(totalNumber / limit);
    // Determine if there's a next page
    const isNext = pageNumber < totalPages;
    // Determine if there's a previous page
    const isPrev = pageNumber > 1;
    return {
        isNext: isNext,
        isPrev: isPrev,
        totalPages: totalPages
    };
}
  const handlePagination = (type) => {
    if (type === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (
      type === "next" &&
      currentPage < Math.ceil(rows.length / limit)
    ) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <>
      {currentRows}
      <tr>
        <td colSpan={colSpanLength} >
            <div className="d-flex align-items-end gap-1 justify-content-center">
                <span className="text-nowrap">{`Showing ${currentPage} out of ${totalPages} Pages`}</span>
                <Pagination style={{marginBottom:0}}>
                    <Pagination.Prev disabled={!isPrev} onClick={() => {
                        handlePagination("prev")
                    }}/>
                    <Pagination.Next disabled={!isNext} onClick={() => {
                        handlePagination("next")
                    }}/>
                </Pagination>
            </div>
            </td>
      </tr>
    </>
  );
};
export default TableRowWithPagination;