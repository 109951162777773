import moment from "moment/moment";

const keyForEachDate = "selection";
const defaultDateRangesWithLables = [
  {
    label: "Today",
    // hasCustomRendering: true,
    range: () => ({
      startDate: new Date(),
      endDate: new Date(),
      // key: keyForEachDate,
    }),
    isSelected() {
      return true;
    },
  },
  {
    label: "Yesterday",
    // hasCustomRendering: true,
    range: () => ({
      startDate: moment().subtract(1, "days")._d,
      endDate: moment().subtract(1, "days")._d,
      // key: keyForEachDate,
    }),
    isSelected() {
      return true;
    },
  },
  {
    label: "Last 7 Days",
    // hasCustomRendering: true,
    range: () => ({
      startDate: moment().subtract(6, "days")._d,
      endDate: moment()._d,
      // key: keyForEachDate,
    }),
    isSelected() {
      return true;
    },
  },
  {
    label: "Last 14 Days",
    // hasCustomRendering: true,
    range: () => ({
      startDate: moment().subtract(13, "days")._d,
      endDate: moment()._d,
      // key:keyForEachDate
    }),
    isSelected() {
      return true;
    },
  },
  {
    label: "Last 30 Days",
    // hasCustomRendering: true,
    range: () => ({
      startDate: moment().subtract(29, "days")._d,
      endDate: moment()._d,
      // key:keyForEachDate
    }),
    isSelected() {
      return true;
    },
  },
  {
    label: "Last 90 Days",
    // hasCustomRendering: true,
    range: () => ({
      startDate: moment().subtract(89, "days")._d,
      endDate: moment()._d,
      // key:keyForEachDate
    }),
    isSelected() {
      return true;
    },
  },
  {
    label: "This Month",
    // hasCustomRendering: true,
    range: () => ({
      startDate: moment().startOf("month")._d,
      endDate: moment()._d,
      // key:keyForEachDate
    }),     
    isSelected() {
      return true;
    },
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: moment().subtract(1, "month").startOf("month")._d,
      endDate: moment().subtract(1, "month").endOf("month")._d,
      // key:keyForEachDate
    }),
    isSelected() {
      return true;
    },
  },
  {
    label: "This Year",
    range: () => ({
      startDate: moment().startOf("year")._d,
      endDate: moment()._d
      // key:keyForEachDate  
    }),
    isSelected() {  
      return true;
    },
  },
  {
    label: "Last Year",
    range: () => ({
      startDate: moment().subtract(1, "year").startOf("year")._d,
      endDate: moment().subtract(1, "year").endOf("year")._d,
      // key:keyForEachDate  
    }),
    isSelected() {  
      return true;
    },
  },
];

export default defaultDateRangesWithLables;