import "./editCampaign.css";
import { Stack } from "react-bootstrap";
import "./campaignList.css";
import TableRb from "../../components/Tables/tableRb";
import {v4 as uuidv4 } from 'uuid'
import SwitchBtn from "../../components/Buttons/switchBtn";
import AddProductModal from "../../components/modal/campaignListModals/AddProdudctForAds/AddProductModal";
import ascendingIconSvg from "../../assets/svgs/acendingIconSvg.svg";
import descendingIconSvg from "../../assets/svgs/descendingIcon.svg";
import React, { useState, useEffect } from "react";
import SectionTittle from "../../components/sectionTitle";
import SecondaryBtnRb from "../../components/Buttons/secondaryBtnRb/secondaryBtnRb";
import "./editProductOnEditAdBtn.css";
import settingOptionIcon from "../../assets/icons/overviewSeiingIcon.svg";
import SearchBarForCampaignListOnly from "../../components/searchBarForCampaignListOnly";
import {
  TOAST_DANGER,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_SUCCESS,
} from "../../constant/HardTypes";
import Loader from "../../components/loader";
import { masterApi } from "../../apis/masterApi";
import { useSelector, useDispatch } from "react-redux";
import { campaignListActions } from "../../redux/slices/campaignList";
import FilterLogicModal from "../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import { roundFigureNum } from "../../constant/roundFigureNum";
import { useGlobalContext } from "../../context/global";
import { useAuthHeader } from "react-auth-kit";
import filterBySelectedBtn from "../../helper/CampaignList/editAds/enablePauseFilter";
import { useSearchParams } from "react-router-dom";
import ProductNameTd from "../../components/Tables/TableComponents/productNameTd";
import MasterTagetinngActionAlertMModal from "../../components/modal/masterTargetinngActionnAlertMModal/masterTargetingActionnAlertModal";
import useHighlightList from '../../hooks/useHighlightList';
import { getCurrencySymbol } from "../../constant/defaultCurrencyConfigurations";
import CheckboxRb from '../../components/Buttons/checkbox/checkbox'
import { calculateACOS, calculateAOV, calculateCPC, calculateCPM, calculateCTR, calculateCVR } from "../../helper/CalculateMetrices/calculateMetrics";
import useTableListFeatures from "../../hooks/useTableListFeatures";
import dashIcon from '../../assets/svgs/minus_box.svg';
import {toast} from 'react-toastify';

const EditProductOnEditAdBtn = () => {
  const dispatch = useDispatch();
  const authHeader = useAuthHeader();
  const token = authHeader();
  const [minMaxFilterActivated, setMinMaxFilterActivated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isNewAdsetCreated, setIsNewAdsetCreated] = useState(false);
  const [modalToShow, setmodalToShow] = useState("");
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  // Three Main States
  const [ads, setAds] = useState([]);
  const [adsStats, setAdsStats] = useState([]);
  const [creatives, setCreatives] = useState([]);
  
  // If User is coming From Different Page (Ex: Master Ads Page) Then Show Set State to current Url ASin
  const initialTextState = params?.asin ?? ""
  const [text, setText] = useState(initialTextState);
  const [editAdsDataForTable, seteditAdsDataForTable] = useState([]);
  const [handleHighlightList] = useHighlightList(editAdsDataForTable,seteditAdsDataForTable)
  const {singleCheckBoxHandler,allCheckBoxHandler} = useTableListFeatures(seteditAdsDataForTable)
  const [currentIndex, setCurrentIndex] = useState(3);
  const [isSortBoxVisible, setIsSortBoxVisible] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [creativeType, setCreativeType] = useState("");
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedBtn, setselectedBtn] = useState("all-btn");
  const [totalEnabledCampaign, setTotalEnabledCampaign] = useState([]);
  const [totalPausedCampaign, setTotalPausedCampaign] = useState([]);
  const { calendarDate, showAlert,applyCalendar } = useGlobalContext();
  const [showAlertMsgOf, setShowAlertMsgOf] = useState("");
  const [alertModalCauseAction, setAlertModalCauseAction] = useState(null);
  const [refetchAds,setRefetchAds] = useState(false)
  const editBtnclickedCampaignDetailsForEditTargetingTable = useSelector(
    (state) =>
      state.campaignListData.editBtnclickedCampaignDetailsForEditTargetingTable
  );
  const editAdGroupSectionCurrentselectedBtnName = useSelector(
    (state) => state.campaignListData.editAdGroupSectionCurrentselectedBtnName
  );
  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );
  const appliedFilterData = useSelector(
    (state) => state.campaignListData.appliedFilterData
  );
  const getUpdatedConfigHeadsForEditAdsSection = useSelector(
    (state) => state.campaignListData.getUpdatedConfigHeadsForEditAdsSection
  );

  const alreadyAddedAsins = ads?.map((ad) => {
    return {
      asin:ad?.asin,
      status:ad?.status
    }
  })

  const [selectedAds,setSelectedAds] = useState([]);

  const currencySymbol = getCurrencySymbol();

  const [dataHeads, setdataHeads] = useState([]);
  const [sortingActiveKey,setSortingActiveKey] = useState("spends")

  // Variables
  const client_id = JSON.parse(localStorage.getItem("clientId"));

  const updateAdStatus = (adId, updatedSatusValue) => {
    var myHeaders = {
      "Content-Type": "application/merge-patch+json",
      Authorization: token,
    };
    var raw = JSON.stringify({
      status: updatedSatusValue,
    });
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://adyogiadsapi.adyogi.com/client/${client_id}/ads/${adId}?source=amazon`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          let filtering = `[{"field_name":"adset_id","operator":"EQUALS","value":"${params?.adsetId}"}]`;
          let dataForFiltering = encodeData(filtering);
          const dataForGetAdsDataRequestBody = {
            source: "amazon",
            filtering: dataForFiltering,
            start_date: `${formatDateFn(calendarDate[0].startDate)}`,
            end_date: `${formatDateFn(calendarDate[0].endDate)}`,
          };

          const GetAdsresult = masterApi(
            dataForGetAdsDataRequestBody,
            "getAdsForEditAdgroupTable",
            false
          )
            .then((adsResult) => {
              setAds(adsResult?.data?.data);
              // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
              //   backgroundColor: TOAST_SUCCESS,
              //   title: "Success",
              //   description: `Ad ${updatedSatusValue}  Successfully`,
              // });
              toast.success(`Ad ${updatedSatusValue}  Successfully`)
            })
            .catch((error) => {
              throw error.message;
            });
        }
      })
      .catch((error) => console.log("error", error));
  };

  // Get Updated Ads After 0.5 seconds and clear timeout // Tech Debt need to find another way.
  useEffect(() => {
  const id =  setTimeout(() => {
      getAds()
    },500)
    return () => {
      clearTimeout(id)
    }
  },[isNewAdsetCreated])


  const handleAPICall = () => {
    setIsNewAdsetCreated(!isNewAdsetCreated)
  }

  async function getAds () {
    try {
      setIsLoaderVisible(true)
      let filtering = `[{"field_name":"adset_id","operator":"EQUALS","value":"${params?.adsetId}"}]`;
      let dataForFiltering = encodeData(filtering);

      const dataForGetAdsDataRequestBody = {
        source: "amazon",
        filtering: dataForFiltering,
        fields: "product_name",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
      };
      const GetAdsresult = await masterApi(
        dataForGetAdsDataRequestBody,
        "getAdsForEditAdgroupTable",
        false
      );
      if(GetAdsresult?.statusCode!==200) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: `Error while fetching new updated ads!`,
        // });
        toast.error(`Error while fetching new updated ads!`)
      } else {
        setAds(GetAdsresult?.data?.data);
        setIsLoaderVisible(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getMainDataFoeEditProductTable() {
    try {
      let filtering = `[{"field_name":"adset_id","operator":"EQUALS","value":"${params?.adsetId}"}]`;
      let dataForFiltering = encodeData(filtering);

      const dataForGetAdsDataRequestBody = {
        source: "amazon",
        filtering: dataForFiltering,
        fields: "product_name",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
      };

      let dataForFilteringForGetAdsStats = `[{ "field_name": "campaign_id", "operator": "EQUALS", "value": ${params?.numericCampaignId}}, { "field_name": "adset_id", "operator": "IN", "values": [${params?.adsetPlatformId}] }]`;
      dataForFilteringForGetAdsStats = encodeData(
        dataForFilteringForGetAdsStats
      );
      const dataForGetAdsStatsDataRequestBody = {
        level: "adset",
        platform: "amazon",
        // date_preset: "last_7d",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        fields:
          "campaign_id,adset_id,ad_id,spends,roas,product_name,image_url,clicks,campaign_name,product_id,orders,sale_value,impressions,cpc,cvr,aov,acos,cpm,orders,ctr",
        group_by: "ad_id",
        filtering: dataForFilteringForGetAdsStats,
      };

      let dataForFilteringForCustomCreatives = `[{"field_name":"adset_id","operator":"EQUALS","value":"${params?.adsetId}"}]`;

      dataForFilteringForCustomCreatives = encodeData(
        dataForFilteringForCustomCreatives
      );

      const dataCustomCReativessDataRequestBody = {
        filtering: dataForFilteringForCustomCreatives,
      };

      setIsLoaderVisible(true);

      const GetAdsresult = await masterApi(
        dataForGetAdsDataRequestBody,
        "getAdsForEditAdgroupTable",
        false
      );
      setAds(GetAdsresult?.data?.data);

      const getAdsStatResult = await masterApi(
        dataForGetAdsStatsDataRequestBody
      );
      setAdsStats(getAdsStatResult?.data?.data);

      const customCreativesResult = await masterApi(
        dataCustomCReativessDataRequestBody,
        "customCreativesByFiltering",
        false
      );
      setCreatives(customCreativesResult?.data?.data);

      // consolidateEditProductTableData(
      //   GetAdsresult.data.data,
      //   getAdsStatResult.data.data,
      //   customCreativesResult.data.data
      // );

      // consolidateData(result.data.data, result1.data.data, result2.data.data, result3.data.data);
      setIsLoaderVisible(false);
    } catch (error) {
      setIsLoaderVisible(false);
    }
  }

  const consolidateEditProductTableData = (ads, adStats, customCreatives) => {
    for (let d = 0; d < customCreatives?.length; d++) {
      let type = customCreatives[d]?.type;
      setCreativeType(type);

      // break
    }
    for (let i = 0; i < ads.length; i++) {
      ads[i].stats = [];
      ads[i].creative = [];
      ads[i].isChecked = false
      for (let index = 0; index < adStats?.length; index++) {
        if (ads[i].platform_id == adStats[index].ad_id) {
          ads[i].product_name = adStats[index]?.product_name;
          ads[i].image_url = adStats[index]?.image_url;
          ads[i].stats.push(adStats[index]);
        }
      }
    }
    let totalSummaryObject

    if(ads.length>0) {
      const totalOrders  = calculateTotal(ads,"orders")
      const totalSpends = calculateTotal(ads,"spends")
      const totalRevenue = calculateTotal(ads,"sale_value")
      const totalClicks = calculateTotal(ads,"clicks")
      const totalImpressions = calculateTotal(ads,"impressions")
      totalSummaryObject = {
        "id": uuidv4(),
        "account_id": uuidv4(),
        "client_id": "",
        "type": "",
        "campaign_id": uuidv4(),
        "adset_id": uuidv4(),
        "creative_id": uuidv4(),
        "source": "",
        "status": "",
        "platform_status": "",
        "ad_platform_last_updated_at": "",
        "platform_id": uuidv4(),
        "asin": uuidv4(),
        "stats": [
            {
                "clicks": calculateTotal(ads,"clicks"),
                "impressions": calculateTotal(ads,"impressions"),
                "orders": calculateTotal(ads,"orders"),
                "cpm": calculateCPM(totalSpends,totalImpressions),
                "ctr": calculateCTR(totalClicks,totalImpressions),
                "cpc": calculateCPC(totalSpends,totalClicks),
                "cvr": calculateCVR(totalOrders,totalClicks),
                "roas": calculateTotal(ads,"sale_value")/calculateTotal(ads,"spends"),
                "spends": calculateTotal(ads,"spends"),
                "sale_value": calculateTotal(ads,"sale_value"),
                "aov":calculateAOV(totalRevenue,totalOrders),
                "acos":calculateACOS(totalSpends,totalImpressions),
                "campaign_name": "",
                "campaign_id": uuidv4(),
                "adset_id": uuidv4(),
                "ad_id": uuidv4(),
                "product_id": uuidv4(),
                "product_name": "",
                "image_url": ""
            }
        ],
        "creative": [],
        "product_name": "",
        "image_url": "",
        isSummaryRow:true
    }
    }
   


    return [totalSummaryObject,...ads]
    // setInitialEditAdsDataForTable(ads);
    // seteditAdsDataForTable(ads);
    // setFilteredAdsData(ads);
  };
  const calculateTotal = (array, key) => {
    return array.reduce((acc, arr) => {
      const { stats } = arr;
      if (stats.length === 0) {
        return acc;
      }
      return acc + (arr.stats[0][key] || 0);
    }, 0);
  };

  useEffect(() => {
    // Consolidate All Data
    const consolidatedData = [
      ...consolidateEditProductTableData(ads, adsStats, creatives),
    ];
    // Pass to User Search
    const dataAfterSearch = onSearch(text, consolidatedData);

    // Pass Data to Main Filters
    const dataAfterFilters = onInputRangeChangeHandler(dataAfterSearch);

    // Show Enable First
    const sortByEnableDisableData = sortByEnableDisable(dataAfterFilters);

    // Get Enabled and Paused Ads Count
    const pausedAds = dataAfterFilters?.filter((data) => {
      return data?.status === "PAUSED";
    });
    const enabledAds = dataAfterFilters?.filter((data) => {
      return data?.status === "ENABLED";
    });
    setTotalPausedCampaign(pausedAds);
    setTotalEnabledCampaign(enabledAds);
    const dataAfterButtonFilter = filterBySelectedBtn(
      sortByEnableDisableData,
      selectedBtn
    );
    const sortedData = onSortData(sortingActiveKey,dataAfterButtonFilter);
    seteditAdsDataForTable(sortedData??[]);
  }, [ads, adsStats, creatives, text, appliedFilterData, selectedBtn]);

  useEffect(() => {
    setIsFilterVisible(filterModalInCampaignListVisibilty);
  }, [filterModalInCampaignListVisibilty]);

  useEffect(() => {
    setdataHeads(getUpdatedConfigHeadsForEditAdsSection);
  }, [getUpdatedConfigHeadsForEditAdsSection]);

 
  const formatDateFn = (date) => {
    const selectedDate = new Date(date);
    const formattedDate =
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear();

    return (
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear()
    );
  };

  useEffect(() => {
    formatDateFn(calendarDate[0].startDate);
  }, []);

  const sortByEnableDisable = (adsArray) => {
    const sortedArray = adsArray.sort((a, b) => {
      let x = a.status === "ENABLED" && 0;
      let y = b.status === "PAUSED" && 1;
      return x - y;
    });
    return sortedArray;
  };

  const filterClickHandler = () => {
    dispatch(campaignListActions.showFilterModalInCampaignList());
  };
  const onButtonClickHandler = (e, type, value) => {
    setselectedBtn(e.target.id);
    // filterCampaigns(value, type)
  };

  const onInputRangeChangeHandler = (initialData) => {
    setMinMaxFilterActivated(false);
    let tempCampaigns = JSON.stringify(initialData);

    tempCampaigns = JSON.parse(tempCampaigns);

    let filteredCampaigns = [];
    let filterWithDashAsZero = [];

    for (let i = 0; i < appliedFilterData.length; i++) {
      let temp = JSON.parse(JSON.stringify(appliedFilterData[i]));
      temp.minRange = isNaN(temp.minRange) ? 0 : Number(temp.minRange);
      temp.maxRange =
        temp.maxRange == "" || isNaN(temp.maxRange)
          ? Infinity
          : Number(temp.maxRange);

      if (
        (temp.minRange != 0 && temp.maxRange != Infinity) ||
        (temp.minRange == 0 && temp.maxRange != Infinity)
      ) {
        setMinMaxFilterActivated(true);
        filterWithDashAsZero.push(temp);
      } else if (
        (temp.minRange != 0 && temp.maxRange == Infinity) ||
        (temp.minRange != 0 && temp.maxRange == "")
      ) {
        filterWithDashAsZero.push(temp);
      }
    }
    for (let r = 0; r < tempCampaigns.length; r++) {
      let shouldPass = true;
      for (let i = 0; i < filterWithDashAsZero.length; i++) {
        let currentFilter = filterWithDashAsZero[i];
        if (
          (currentFilter.minRange != 0 && currentFilter.maxRange != Infinity) ||
          (currentFilter.minRange == 0 && currentFilter.maxRange != Infinity) ||
          (currentFilter.minRange != 0 && currentFilter.maxRange == Infinity)
        ) {
          setMinMaxFilterActivated(true);
          let currentCampaign = tempCampaigns[r];
          if (!currentCampaign.stats.length) {
            shouldPass = false;
          }
          for (let m = 0; m < currentCampaign.stats.length; m++) {
            if (
              currentCampaign.stats[m][currentFilter.id] &&
              currentCampaign.stats[m][currentFilter.id] !== null &&
              currentCampaign.stats[m][currentFilter.id] !== "-"
            ) {
              if (
                currentCampaign.stats[m][currentFilter.id] <
                  currentFilter.minRange ||
                (currentFilter.maxRange !== Infinity &&
                  currentCampaign.stats[m][currentFilter.id] >
                    currentFilter.maxRange)
              ) {
                shouldPass = false;
              }
            } else {
              shouldPass = false;
            }
          }
        } else {
          shouldPass = false;
        }
      }
      if (shouldPass) {
        filteredCampaigns.push(tempCampaigns[r]);
      }
    }
    return filteredCampaigns;
  };
  const addKeywordHandler = () => {
    setIsVisible(!isVisible);
    setmodalToShow("addTargeting");
  };
 
  useEffect(() => {
    const filterSelectedAds = editAdsDataForTable?.filter(data => data?.isChecked && !data?.isSummaryRow)
    setSelectedAds([...filterSelectedAds])
  },[editAdsDataForTable])

  useEffect(() => {
    if (editAdGroupSectionCurrentselectedBtnName === "edit-ads-btn" || params?.editadspage)
      getMainDataFoeEditProductTable();
  }, [
    editBtnclickedCampaignDetailsForEditTargetingTable,
    editAdGroupSectionCurrentselectedBtnName,
    applyCalendar,
  ]);

 

  const encodeData = (data) => {
    // data = JSON.stringify(data);
    let encodedData = encodeURIComponent(data);
    return encodedData;
  };

  const addProductHandler = () => {
    setIsVisible(!isVisible);
  };

  const onChageAdsStatusToggle = (updatedValue, adsId) => {
    if (!updatedValue) {
      setShowAlertMsgOf("Are you sure you want to Pause Ad?");
      setAlertModalCauseAction({
        id: adsId,
        checkedStatus: "PAUSED",
        alertCauseActionType: "campaignStatus",
        fieldToUpdate: "PAUSED",
      });
      // updateAdStatus(adsId, "ENABLED");
    } else {
      setShowAlertMsgOf("Are you sure you want to Enable Ad?");
      setAlertModalCauseAction({
        id: adsId,
        checkedStatus: "ENABLED",
        alertCauseActionType: "campaignStatus",
        fieldToUpdate: "ENABLED",
      });
      // updateAdStatus(adsId, "PAUSED");
    }
    dispatch(campaignListActions.showActionAlertModal());
  };

  const onSpendFilterClickHandler = (value, index) => {
    let tempTargetStat = JSON.stringify(editAdsDataForTable);
    tempTargetStat = JSON.parse(tempTargetStat);
    const mainValue = value === "REVENUE" ? "sale_value" : value;
    tempTargetStat = tempTargetStat.sort((a, b) => {
      let x =
        a?.stats[0]?.[mainValue.toLowerCase()] === "-"
          ? 0
          : a?.stats[0]?.[mainValue.toLowerCase()] ?? 0;
      let y =
        b?.stats[0]?.[mainValue.toLowerCase()] === "-"
          ? 0
          : b?.stats[0]?.[mainValue.toLowerCase()] ?? 0;
      return isSortBoxVisible ? y - x : x - y;
    });
    seteditAdsDataForTable(tempTargetStat);
    setIsSortBoxVisible(!isSortBoxVisible);
    setCurrentIndex(index);
    setSortingActiveKey(mainValue?.toLowerCase())
  };

  const onSortData = (value , data) => {
    let tempTargetStat = JSON.stringify(data);
    tempTargetStat = JSON.parse(tempTargetStat);
    const mainValue = value === "REVENUE" ? "sale_value" : value;
    tempTargetStat = tempTargetStat.sort((a, b) => {
      let x =
        a?.stats[0]?.[mainValue.toLowerCase()] === "-"
          ? 0
          : a?.stats[0]?.[mainValue.toLowerCase()] ?? 0;
      let y =
        b?.stats[0]?.[mainValue.toLowerCase()] === "-"
          ? 0
          : b?.stats[0]?.[mainValue.toLowerCase()] ?? 0;
      return  y - x ;
    });
    return tempTargetStat
  };

  const getHeadClass = (th,mainIndex) => {
    let clsName = "";
    if(th.isHeadRightAlign){
        clsName = "pt-end-align"
    }else{
        clsName = "pt-text-left-align"
    }

    if(mainIndex === currentIndex){
        clsName += " selected"
    }
    return clsName;
}

// async function getAds () {
//   try {

//     const filtering = JSON.stringify([
//       {
//         field_name: "adset_id",
//         operator: "EQUALS",
//         value: `${params?.adsetId}`,
//       },
//     ]);

//     const dataForFiltering = encodeData(filtering);

//     const dataForGetAdsDataRequestBody = {
//       source: "amazon",
//       filtering: dataForFiltering,
//       start_date: formatDateFn(calendarDate[0].startDate),
//       end_date: formatDateFn(calendarDate[0].endDate),
//     };

//     const adsResult = await masterApi(
//       dataForGetAdsDataRequestBody,
//       "getAdsForEditAdgroupTable",
//       false
//     )
//     setAds(adsResult?.data?.data)
//   } catch (error) {
//     console.log(error)
//   }
// }

  let editProducttableBody = (
    <div className="edit-product-main-table">
      {editAdsDataForTable.length ? (
        <table>
          <thead>
            <tr>
              {dataHeads.map((th, mainIndex) => {
                return (
                  <th
                  className={getHeadClass(th,mainIndex)}
                  key={th.fieldName}
                  >
                    {th.isHeadWithIcon === "sort" ? (
                      <div
                        className={
                          !th.isAbbrivation
                            ? "d-flex flex-row align-item-center justify-content-end captital-first-letter"
                            : "d-flex flex-row align-item-center justify-content-end"
                        }
                      >
                        <span>
                          {!th.isAbbrivation
                            ? th.fieldName.toLowerCase()
                            : th.fieldName}
                        </span>
                        <span
                          className="spends-icon"
                          onClick={() =>
                            onSpendFilterClickHandler(th.fieldName, mainIndex)
                          }
                        >
                          <img
                            src={
                              isSortBoxVisible && mainIndex === currentIndex
                                ? ascendingIconSvg
                                : descendingIconSvg
                            }
                            className={
                              mainIndex === currentIndex
                                ? "spends-icon-activated"
                                : ""
                            }
                          ></img>
                        </span>
                        <div
                          className={
                            isSortBoxVisible ? "sortBox-container" : "hide"
                          }
                        ></div>
                      </div>
                    ) : th.fieldName === "PRODUCT TARGETING" ? (<>
                      <CheckboxRb onChange={(e) => allCheckBoxHandler(e.target.checked)}  label={"Product Name"}/>
                    </>) : (
                      <div
                        className={
                          th.fieldName.toLowerCase() === "suggested bid" ||
                          th.fieldName.toLowerCase() === "match type"
                            ? "captital-first-letter heads-with-two-words"
                            : "captital-first-letter"
                        }
                      >
                        {th.fieldName === "PRODUCT TARGETING"
                          ? "PRODUCT NAME".toLowerCase()
                          : th.fieldName === "ASIN's"
                          ? "ASIN's"
                          : th.fieldName.toLowerCase()}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {editAdsDataForTable.map((ads, index) => {
              return (
              ads.isSummaryRow && index === 0 ? (
                <tr key={index+"12"}>
                  <td className="edit-first-column">-</td>
                  <td className="fw-bold edit-second-column">Overall Summary</td>
                  <td className="fw-bold">-</td>
                  {ads.stats.length
                  ? ads["stats"].map((campStatInst, indo) => {
                      for (let i = 0; i < dataHeads.length; i++) {
                        if (!dataHeads[i].isDefaultHead) {
                          campStatInst[dataHeads[i].id] = campStatInst[
                            dataHeads[i].id
                          ]
                            ? campStatInst[dataHeads[i].id]
                            : "-";
                        }
                      }

                      {
                        return dataHeads.map((currentDataHead) => {
                          if (!currentDataHead.isDefaultHead) {
                            return (
                              <td
                                className={
                                  currentDataHead.isHeadRightAlign
                                    ? "pt-end-align fw-bold"
                                    : "pt-text-left-align fw-bold"
                                }
                              >
                                {campStatInst
                                  ? campStatInst[currentDataHead?.id] !== "-"
                                    ? currentDataHead?.id === "spends" ||
                                      currentDataHead?.id === "sale_value"
                                      ? `${currencySymbol}${Math.trunc(
                                          campStatInst[currentDataHead?.id]
                                        )}`
                                      : roundFigureNum(
                                          campStatInst[currentDataHead?.id]
                                        )
                                    : "-"
                                  : "-"}
                              </td>
                            );
                          }
                        });
                      }
                    })
                  : dataHeads.map((currentDataHead) => {
                      if (!currentDataHead.isDefaultHead) {
                        return (
                          <td
                            className={
                              currentDataHead.isHeadRightAlign
                                ? "pt-end-align fw-bold"
                                : "pt-text-left-align fw-bold"
                            }
                          >
                            -
                          </td>
                        );
                      }
                    })}
                </tr>
              ) : (
                <tr onClick={(e) => {
                  e.stopPropagation()
                  handleHighlightList(index)
                }} className={ads?.isHighlighted ? "cursor-pointer highlight":"cursor-pointer"} key={ads?.id}>
                <td className="edit-first-column">
                  {
                    <SwitchBtn
                      checked={
                        ads?.status && ads?.status === "ENABLED"
                          ? true
                          : ads?.status && ads?.status === "PAUSED"
                          ? false
                          : false
                      }
                      disabled={
                        creativeType === "amazon_smart_creative"
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        {
                          e.stopPropagation()
                          onChageAdsStatusToggle(e.target.checked, ads?.id)
                        }
                      }
                    ></SwitchBtn>
                  }
                </td>
                <td className="edit-second-column d-flex align-items-center">
                  <CheckboxRb onChange={(e) => {  
                    e.stopPropagation()
                    singleCheckBoxHandler(index)
                   }
                  } 
                checked={ads?.isChecked}/>
                  <ProductNameTd
                    image_url={ads?.image_url}
                    asin={ads?.asin}
                    product_name={ads.product_name}
                  />
                </td>
                <td>{ads.asin ? ads.asin : "-"}</td>
                {ads?.stats?.length
                  ? ads["stats"].map((campStatInst, indo) => {
                      for (let i = 0; i < dataHeads.length; i++) {
                        if (!dataHeads[i].isDefaultHead) {
                          campStatInst[dataHeads[i].id] = campStatInst[
                            dataHeads[i].id
                          ]
                            ? campStatInst[dataHeads[i].id]
                            : "-";
                        }
                      }

                      {
                        return dataHeads.map((currentDataHead) => {
                          if (!currentDataHead.isDefaultHead) {
                            return (
                              <td
                                className={
                                  currentDataHead.isHeadRightAlign
                                    ? "pt-end-align"
                                    : "pt-text-left-align"
                                }
                              >
                                {campStatInst
                                  ? campStatInst[currentDataHead?.id] !== "-"
                                    ? currentDataHead?.id === "spends" ||
                                      currentDataHead?.id === "sale_value"
                                      ? `${currencySymbol}${Math.trunc(
                                          campStatInst[currentDataHead?.id]
                                        )}`
                                      : roundFigureNum(
                                          campStatInst[currentDataHead?.id]
                                        )
                                    : "-"
                                  : "-"}
                              </td>
                            );
                          }
                        });
                      }
                    })
                  : dataHeads.map((currentDataHead) => {
                      if (!currentDataHead.isDefaultHead) {
                        return (
                          <td
                            className={
                              currentDataHead.isHeadRightAlign
                                ? "pt-end-align"
                                : "pt-text-left-align"
                            }
                          >
                            -
                          </td>
                        );
                      }
                    })}
              </tr>
              )
             
              );
            })}
          </tbody>
        </table>
      ) : (
       !isLoaderVisible && !editAdsDataForTable.length && <div>NO DATA FOUND</div>
      )}
    </div>
  );

  // Search Functionality

  const onSearch = (text, initialData) => {
    const filteredData = initialData.filter((data) => {
      if (text === "") {
        return data;
      } else {
        return data?.asin.toLowerCase().includes(text.toLowerCase());
      }
    });
    return filteredData;
  };

  const onChange = (e) => setText(e.target.value);

  const cancelIconInSearchInput = () => {
    setText("")
  }
  const onYesClickHandler = async () => {
    const actionType = alertModalCauseAction.alertCauseActionType
    if (alertModalCauseAction.alertCauseActionType === "campaignStatus") {
      updateAdStatus(
        alertModalCauseAction.id,
        alertModalCauseAction.fieldToUpdate
      );
    }
    // If there are bulk actions

    if (actionType === "CampaignEnable" || actionType === "CampaignPause") {
      try {
        const selectedAsins = selectedAds?.map(data => data?.asin);
        const status = actionType === "CampaignEnable" ? "enabled" : "paused";
        const data = {
          status: status,
          asins: selectedAsins,
        };
        
        const result = await masterApi(data, "uploadasinsstatus", false, "post");

        if (result?.statusCode !== 200) {
          // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //   backgroundColor: TOAST_DANGER,
          //   title: "Error",
          //   description: "Some Unknown Error Occurred",
          // });
          toast.error('Some Unknown Error Occurred')
        } else {
          setSelectedAds([])
          seteditAdsDataForTable((prev) => {
            return [...prev]?.map((data) => {
              data.isChecked = false
              return data
            })
          })
          // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //     backgroundColor: TOAST_SUCCESS,
          //     title: "Success",
          //     description: "Updated Successfully changes might take some minutes to reflect!",
          //   });
          toast.success('Updated Successfully changes might take some minutes to reflect!')
        }
      } catch (error) {
        throw error.message;
      }
    }
    dispatch(campaignListActions.hideActionAlertModal());
  };

  const NoClickHandler = () => {
    dispatch(campaignListActions.hideActionAlertModal());
  };

  const removeSelectedAll = () => {
    allCheckBoxHandler(false)
  }

  const mainBulkPauseHandler = (status) => {
    const actionType = status === "Enable" ? "CampaignEnable":"CampaignPause"
    setShowAlertMsgOf(`Are you sure you want to ${status} Selected Ads?`);
    setAlertModalCauseAction({
      alertCauseActionType: actionType,
    });
    dispatch(campaignListActions.showActionAlertModal());
  }

  return (
    <div className="edit-product-card-container d-flex flex-column ">
      <Loader visible={isLoaderVisible}></Loader>
      <div>
        { (
          <FilterLogicModal
            filterFor="edit-ads"
            show={isFilterVisible}
          ></FilterLogicModal>
        )}
      </div>
      <div>
        {
          <MasterTagetinngActionAlertMModal
            // onYesClick={actioAlertYesClickhandler}
            alertText={showAlertMsgOf}
            onYesClick={onYesClickHandler}
            onNoClick={NoClickHandler}
          ></MasterTagetinngActionAlertMModal>
        }
      </div>
      {/* <AddProductModal isVisible={isVisible} setIsVisible={setIsVisible}></AddProductModal> */}
      {isVisible && (
        <AddProductModal
          isVisible={isVisible}
          alreadyAddedAsins={alreadyAddedAsins}
          setIsVisible={setIsVisible}
          isNewAdsetCreated={isNewAdsetCreated}
          setIsNewAdsetCreated={handleAPICall}
        />
      )}
      <div className="edit-product-card-header">
        <div className="d-flex flex-row align-items-center justify-content-start flex-grow-1">
          <SectionTittle sectionTitleHeading="Edit Products"></SectionTittle>
        <div className="d-flex justify-content-center align-items-center px-3">
        <div
            className="filter-icon-container-main "
            onClick={filterClickHandler}
          >
            <div className="filter-icon-container-main">
              <div
                className={
                  minMaxFilterActivated ? "activated-min-max-filter" : "hide"
                }
              ></div>
              <img
                src={settingOptionIcon}
                className="cursor-pointer-global"
                alt="Settings"
              ></img>
            </div>
          </div>
        </div>
       
          <div
            className={
              selectedAds.length ? "et-filter-options-container-formasterTargeting filter-box-position" : "hide"
            }
          >
            <button className="selected-row-count mx-2">
            <img
              className="dash-icon"
              src={dashIcon}
              alt={"cancel"}
              onClick={removeSelectedAll}
            ></img>
            <span className="selected-text-with-count">
              {selectedAds && `${selectedAds?.length}  Selected`}
            </span>
          </button>
          <button
            className="alignn-with-sug-bid-box-selective "
            onClick={() => mainBulkPauseHandler("Enable")}
            data-toggle="modal"
              data-target="#exampleModalCenter"
          >
          Bulk Enable
          </button>
          <button
          onClick={() =>mainBulkPauseHandler("Pause")}
            className="bid-manipulate-box-selective border-radius"
          >
            <span> Bulk Pause</span>
          </button> 
          </div>
          <div
            className={
              creativeType === "amazon_custom_creative"
                ? "hide"
                : creativeType === "amazon_smart_creative"
                ? "smat-ads-not-editable-alert-container"
                : "hide"
            }
          >
            <div className="smat-ads-not-editable-alert-text">
              "These are smart ads you can't make changes."
            </div>
          </div>
        </div>
        <div className="serch-and-button-container">
          <div>
            <SearchBarForCampaignListOnly
              onChange={onChange}
              placeholder="Search for Products or ASIN’s"
              onClickCancel = {cancelIconInSearchInput}
              value={text}
              id="edit-ads-search-input"
              dynamicWidth="search-input-width-for-edit-campaignlist-section"
            ></SearchBarForCampaignListOnly>
          </div>
          <div className="add-keyword-btn">
            <button
              className={
                creativeType === "amazon_custom_creative"
                  ? "primary-active-btn "
                  : creativeType === "amazon_smart_creative"
                  ? "diabled-add-prodcut-btn"
                  : "primary-active-btn "
              }
              disabled={creativeType === "amazon_smart_creative"}
              onClick={addKeywordHandler}
            >
              Add Product
            </button>
          </div>
        </div>
      </div>
      <div className="edit-targeting-campaign-count-btn-container">
        <Stack direction="horizontal">
          <Stack className="d-flex flex-row">
            <div>
              <SecondaryBtnRb
                secondaryBtnText={`All (${
                  ads?.length
                    ? totalPausedCampaign.length + totalEnabledCampaign.length
                    : 0
                })`}
                className={
                  selectedBtn === "all-btn"
                    ? "primary-active-btn ennable-paused-btns-height"
                    : "primary-inactive-btn ennable-paused-btns-height"
                }
                id="all-btn"
                onClick={(e, type, value) =>
                  onButtonClickHandler(e, "status", "All")
                }
              ></SecondaryBtnRb>
            </div>
            <div>
              <SecondaryBtnRb
                secondaryBtnText={`Enabled (${
                  totalEnabledCampaign?.length
                    ? totalEnabledCampaign?.length
                    : 0
                })`}
                className={
                  selectedBtn === "ENABLED"
                    ? "primary-active-btn ennable-paused-btns-height"
                    : "primary-inactive-btn ennable-paused-btns-height"
                }
                id="ENABLED"
                onClick={(e, type, value) =>
                  onButtonClickHandler(e, "status", "ENABLED")
                }
              ></SecondaryBtnRb>
            </div>
            <div>
              <SecondaryBtnRb
                secondaryBtnText={`Paused (${
                  totalPausedCampaign?.length ? totalPausedCampaign?.length : 0
                })`}
                className={
                  selectedBtn === "PAUSED"
                    ? "primary-active-btn ennable-paused-btns-height"
                    : "primary-inactive-btn ennable-paused-btns-height"
                }
                id="PAUSED"
                value="PAUSED"
                onClick={(e, type, value) =>
                  onButtonClickHandler(e, "status", "PAUSED")
                }
              ></SecondaryBtnRb>
            </div>
          </Stack>
        </Stack>
      </div>
      <div className="product-targetting-table">
        <TableRb table={editProducttableBody}></TableRb>
      </div>
    </div>
  );
};

export default EditProductOnEditAdBtn;
