import React from "react";
import lessIcon from "../../assets/svgs/minus_box.svg";
import resumeIcon from '../../assets/icons/play_circle.png';
import pauseIcon from '../../assets/icons/pause_circle.svg';
import './selectionbar.css';


const OptionsMapper = {
    "Enable":resumeIcon,
    "Pause":pauseIcon
}

const ClassNameMapper = {
    "Enable":"bid-manipulate-box-selective",
    "Pause":"bid-manipulate-box-selective border-radius",
    "Align With Suggested Bid":"alignn-with-sug-bid-box-selective",
    "Adjust Bid":"bid-manipulate-box-selective"
}

const SelectionBar = ({ barOptions, handleClick, selectedCount, handleCancel }) => {
  return (
    <div
      className={
        selectedCount.length
          ? "selection-bar"
          : "hide"
      }
    >
      <button className="selected-row-count mx-2">
        <img
          className="dash-icon"
          src={lessIcon}
          onClick={() => handleCancel()}
          alt="less"
        ></img>
        <span className="selected-text-with-count">
          {selectedCount && `${selectedCount?.length}  Selected`}
        </span>
      </button>
      {selectedCount?.length && barOptions?.length
        ? barOptions?.map((option) => {
            const image = OptionsMapper[option] ?? false
            return (
              <button
                className={ClassNameMapper[option] ?? ""}
                onClick={() => handleClick(option)}
              >
                {option}
                {image && <img src={image}/>}
              </button>
            );
          })
        : null}
    </div>
  );
};

export default SelectionBar;
