import { masterApi } from "../../../apis/masterApi";


export const formatDateFn = (date) => {
    const selectedDate = new Date(date);
    const formattedDate =
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear();
    return (
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear()
    );
  };


export async function getChildRowsData(currentFiltering) {
    try {
      const result = await masterApi(currentFiltering, "masterAds", false);
      if(result?.data?.data  && result?.statusCode === 200) {
        return result?.data?.data
      } else {
        return {error:`Unknown Error Occured With StatusCode ${result?.statusCode}`}
      }
    } catch (error) {
      console.log(error);
    }
  }