import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import "./linecolumnGraph.css";
import ChartDataLabels from "chartjs-plugin-datalabels";

const TrendGraphMaster = ({
  LineData,
  XaxisData,
  ColumnData1,
  ColumnData2,
  ColumnData3,
  ColumnData4,
  productROAS,
  keywordROAS,
  automaticROAS,
  audienceROAS,
}) => {
  let delayed;
  const lineWidth = 1.5;
  const chartbarThickness = 36;
  const chartborderRadius = 8;
  ChartJS.register(...registerables, ChartDataLabels);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    layout: {
      padding: {
        left: 22,
        right: 24,
        top: 25,
        bottom: 20,
      },
    },
    animation: {
      duration: 0,
    },
    // animation: {
    //   y: {
    //     duration: 1000,
    //     from: 500
    //   },
    //   y1: {
    //     duration: 1000,
    //     from: 1000
    //   },
    //   x:{
    //     duration:0,
    //     from:0
    //   },
    //   onComplete: () => {
    //     delayed = true;
    //   },
    //   delay: (context) => {
    //     let delay = 0;
    //     if (context.type === 'data' && context.mode === 'default' && !delayed) {
    //       delay = context.dataIndex * 100 + context.datasetIndex * 1000;
    //     }
    //     return delay;
    //   },
    // },
    stacked: true,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        usePointStyle: true,
        bodySpacing: 4,
        padding: 14,
        intersect: true,
        callbacks: {
          title: function (items) {
            const roasData = items[0];
            const productData = items[1];
            const keywordData = items[2];
            const automaticData = items[3];
            const { dataIndex } = roasData;
            const productSpends =
              productData?.dataset?.data[dataIndex] === undefined
                ? 0
                : productData?.dataset?.data[dataIndex];
            const keywordSpends =
              keywordData?.dataset?.data[dataIndex] === undefined
                ? 0
                : keywordData?.dataset?.data[dataIndex];
            const automaticSpends =
              automaticData?.dataset?.data[dataIndex] === undefined
                ? 0
                : automaticData?.dataset?.data[dataIndex];
            const totalSpends = productSpends + keywordSpends + automaticSpends;
            return `Total Spends: ${totalSpends}`;
          },
          footer: function (items) {
            const { dataIndex } = items[0];
            const productROASIndividual = productROAS[dataIndex];
            const keywordROASIndividual = keywordROAS[dataIndex];
            const automatciROASIndividual = automaticROAS[dataIndex];
            const audienceROASIndividual = audienceROAS[dataIndex];
            return `(Product ${
              Number.isNaN(productROASIndividual) ? "0" : productROASIndividual
            },Keyword ${keywordROASIndividual},Automatic ${
              Number.isNaN(automatciROASIndividual)
                ? "0"
                : automatciROASIndividual
            },Audience ${
              Number.isNaN(audienceROASIndividual)
                ? "0"
                : audienceROASIndividual
            })`;
          },
        },
      },
      datalabels: {
        formatter: function (value, context) {
          let productDataset = Number.isNaN(
            context?.chart?.data?.datasets[1]?.data[context?.dataIndex]
          )
            ? 1
            : context?.chart?.data?.datasets[1]?.data[context?.dataIndex];
          let keywordDataset = Number.isNaN(
            context?.chart?.data?.datasets[2]?.data[context?.dataIndex]
          )
            ? 1
            : context?.chart?.data?.datasets[2]?.data[context?.dataIndex];
          let automaticDataset = Number.isNaN(
            context?.chart?.data?.datasets[3]?.data[context?.dataIndex]
          )
            ? 1
            : context?.chart?.data?.datasets[3]?.data[context?.dataIndex];
          let audienceDataset = Number.isNaN(
            context?.chart?.data?.datasets[4]?.data[context?.dataIndex]
          )
            ? 5
            : context?.chart?.data?.datasets[4]?.data[context?.dataIndex];
          audienceDataset = audienceDataset === undefined ? 0 : audienceDataset;
          productDataset = productDataset === undefined ? 0 : productDataset;
          automaticDataset =
            automaticDataset === undefined ? 0 : automaticDataset;
          keywordDataset = keywordDataset === undefined ? 0 : keywordDataset;
          const totalSpends =
            productDataset +
            keywordDataset +
            automaticDataset +
            audienceDataset;
          return Math.round((value / totalSpends) * 100) + "%";
        },
        color: "#fff",
        font: {
          weight: "light",
        },
      },
    },
    scales: {
      y: {
        display: true,
        beginAtZero: true,
        ticks: {
          callback: function (label, index, labels) {
            return label / 1000 + "k";
          },
        },
        scaleLabel: {
          display: true,
          labelString: "1k = 1000",
        },
      },
      y1: {
        display: true,
        position: "right",
        grid: {
          display: false,
        },

        ticks: {
          callback: function (label, index, labels) {
            return label.toFixed(1);
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  const labels = XaxisData && XaxisData;

  const data = {
    labels,
    datasets: [
      {
        label: "ROAS",
        type: "line",
        data: LineData && LineData,
        borderColor: "#767676",
        backgroundColor: "#767676",
        pointStyle: "circle",
        pointRadius: 3,
        yAxisID: "y1",
        borderDash: [6, 4],
        borderWidth: lineWidth,
        // datalabels: {
        //   display: false,
        // },
        datalabels: {
          display: false,
          formatter: (value) => {
            if (value === 0) {
              return "0"; // Display "0" instead of NaN
            }
            return value;
          },
        },
      },
      {
        label: "Product",
        type: "bar",
        data: ColumnData1 ?? [],
        stacked: true,
        order: 2,
        borderColor: "#50A5B5",//70BDCC
        barPercentage: 0.4,
        pointStyle: "circle",
        backgroundColor: "#50A5B5",//70BDCC
        hoverBackgroundColor:'#50A5B5',//70BDCC
        yAxisID: "y",
        stack: "combined",
        borderWidth: lineWidth,
        barThickness: chartbarThickness, 
        borderRadius: chartborderRadius,
        // datalabels: {
        //   color: "white",
        //   formatter: (value) => {
        //     if (value === 0) {
        //       return "0"; // Display "0" instead of NaN
        //     }
        //     return value;
        //   },
        // },
      },
      {
        label: "Keyword",
        type: "bar",
        data: ColumnData2 ?? [],
        stacked: true,
        order: 1,
        borderColor: "#1D216E",//7078CC
        barPercentage: 0.4,
        pointStyle: "circle",
        backgroundColor: "#1D216E",//7078CC
        hoverBackgroundColor:'#1D216E',//7078CC
        yAxisID: "y",
        stack: "combined",
        borderWidth: lineWidth,
        barThickness: chartbarThickness, 
        borderRadius: chartborderRadius,
        // datalabels: {
        //   color: "white",
        //   formatter: (value) => {
        //     if (value === 0) {
        //       return "0"; // Display "0" instead of NaN
        //     }
        //     return value;
        //   },
        // },
      },
      {
        label: "Automatic",
        type: "bar",
        data: ColumnData3 ?? [],
        stacked: true,
        order: 3,
        borderColor: "#DEC46A",//E6DB7E
        barPercentage: 0.4,
        pointStyle: "circle",
        backgroundColor: "#DEC46A",//E6DB7E
        hoverBackgroundColor:'#DEC46A',//E6DB7E
        yAxisID: "y",
        stack: "combined",
        borderWidth: lineWidth,
        barThickness: chartbarThickness, 
        borderRadius: chartborderRadius,
        // datalabels: {
        //   color: "white",
        //   formatter: (value) => {
        //     if (value === 0) {
        //       return "0"; // Display "0" instead of NaN
        //     }
        //     return value;
        //   },
        // },
      },
      {
        label: "Audience",
        type: "bar",
        data: ColumnData4 ?? [],
        stacked: true,
        order: 4,
        borderColor: "#81B550",//9CCC70
        barPercentage: 0.4,
        pointStyle: "circle",
        backgroundColor: "#81B550",//9CCC70
        hoverBackgroundColor:'#81B550',//9CCC70
        yAxisID: "y",
        stack: "combined",
        borderWidth: lineWidth,
        barThickness: chartbarThickness, 
        borderRadius: chartborderRadius,
        // datalabels: {
        //   color: "white",
        //   formatter: (value) => {
        //     if (value === 0) {
        //       return "0"; // Display "0" instead of NaN
        //     }
        //     return value;
        //   },
        // },
      },
    ],
  };

  return (
    <>
      <div className="chart-wrapper-div">
        <Bar options={options} height={70} data={data} />
      </div>
    </>
  );
};

export default TrendGraphMaster;
