import romanConverter from "./CommonLineGraph/romanConverter";
export default function parseData(data) {
  const [productSpends, keywordSpends, automaticSpends, audienceSpends] =
    data.reduce(
      (
        [productSpends, keywordSpends, automaticSpends, audienceSpends],
        mainData
      ) => {
        const roundedSpends = Math.round(mainData?.spends?.toFixed(2));
        if (mainData?.target_type?.includes("Product")) {
          productSpends.push(roundedSpends);
        } else if (mainData?.target_type?.includes("Keyword")) {
          keywordSpends.push(roundedSpends);
        } else if (mainData?.target_type?.includes("Automatic")) {
          automaticSpends.push(roundedSpends);
        } else if (mainData?.target_type?.includes("Audience")) {
          audienceSpends.push(roundedSpends);
        }
        return [productSpends, keywordSpends, automaticSpends, audienceSpends];
      },
      [[], [], [], []]
    );
  const roasObject = getROASByWeek(data);
  let datesData = [...new Set(data.map((mainData) => mainData?.week))].map(
    (date, index) => {
      let updatedIndex = index + 1;
      return `Week ${romanConverter(updatedIndex)}`;
    }
  );
  const objectToBeReturned = {
    LineData:roasObject?.totalRoasArray,
    XaxisData:datesData,
    ColumnData1:productSpends,
    ColumnData2:keywordSpends,
    ColumnData3:automaticSpends,
    ColumnData4:audienceSpends,
    productROAS:roasObject?.productROASArray,
    keywordROAS:roasObject?.keywordROASArray,
    automaticROAS:roasObject?.automaticROASArray,
    audienceROAS:roasObject?.audienceROASArray
  };

  return objectToBeReturned;
}

function getROASByWeek(campaigns) {
  const weeklyROAS = {};
  const productROAS = {};
  const keywordROAS = {};
  const automaticROAS = {};
  const audienceROAS = {};

  for (const campaign of campaigns) {
    if (!weeklyROAS[campaign.week]) {
      weeklyROAS[campaign.week] = {
        totalSpends: 0,
        totalSaleValue: 0,
      };
      productROAS[campaign.week] = {
        totalSpends: 0,
        totalSaleValue: 0,
      };
      keywordROAS[campaign.week] = {
        totalSpends: 0,
        totalSaleValue: 0,
      };
      automaticROAS[campaign.week] = {
        totalSpends: 0,
        totalSaleValue: 0,
      };
      audienceROAS[campaign.week] = {
        totalSpends: 0,
        totalSaleValue: 0,
      };
    }

    weeklyROAS[campaign.week].totalSpends += campaign.spends;
    weeklyROAS[campaign.week].totalSaleValue += campaign.sale_value;

    if (campaign?.target_type?.includes("Product")) {
      productROAS[campaign.week].totalSpends += campaign.spends;
      productROAS[campaign.week].totalSaleValue += campaign.sale_value;
    } else if (campaign?.target_type?.includes("Keyword")) {
      keywordROAS[campaign.week].totalSpends += campaign.spends;
      keywordROAS[campaign.week].totalSaleValue += campaign.sale_value;
    } else if (campaign?.target_type?.includes("Automatic")) {
      automaticROAS[campaign.week].totalSpends += campaign.spends;
      automaticROAS[campaign.week].totalSaleValue += campaign.sale_value;
    } else if (campaign?.target_type?.includes("Audience")) {
      audienceROAS[campaign.week].totalSpends += campaign.spends;
      audienceROAS[campaign.week].totalSaleValue += campaign.sale_value;
    }
  }
  const result = {
    totalRoasArray: [],
    productROASArray: [],
    keywordROASArray: [],
    automaticROASArray: [],
    audienceROASArray: [],
  };
  for (const week in weeklyROAS) {
    const totalROAS = Number(
      (weeklyROAS[week].totalSaleValue / weeklyROAS[week].totalSpends).toFixed(
        1
      )
    );
    const productROASRatio = Number(
      (
        productROAS[week].totalSaleValue / productROAS[week].totalSpends
      ).toFixed(2)
    );
    const keywordROASRatio = Number(
      (
        keywordROAS[week].totalSaleValue / keywordROAS[week].totalSpends
      ).toFixed(2)
    );
    const automaticROASRatio = Number(
      (
        automaticROAS[week].totalSaleValue / automaticROAS[week].totalSpends
      ).toFixed(2)
    );
    const audienceROASRatio = Number(
      (
        audienceROAS[week].totalSaleValue / audienceROAS[week].totalSpends
      ).toFixed(2)
    );
    const productROASValue = Number((totalROAS * productROASRatio).toFixed(1));
    const keywordROASValue = Number((totalROAS * keywordROASRatio).toFixed(1));
    const automaticROASValue = Number(
      (totalROAS * automaticROASRatio).toFixed(1)
    );
    const audienceROASValue = Number(
      (totalROAS * audienceROASRatio).toFixed(1)
    );

    result.totalRoasArray.push(totalROAS);
    result.productROASArray.push(productROASRatio);
    result.keywordROASArray.push(keywordROASRatio);
    result.automaticROASArray.push(automaticROASRatio);
    result.audienceROASArray.push(audienceROASRatio);
  }
  return result;
}
