const columnConfig = [
  {
    key: "{tableName}.asin",
    title: "Variant Analysis Table Asin",
    shortTitle: "Asin",
    type: "string",
    // isSingleColumn is used to show the column in single column in comparison download
    isSingleColumn:true,
    dataIndex: "{tableName}.asin",
  },
  {
    key: "{tableName}.parentSku",
    title: "Variant Analysis Table Asin",
    shortTitle: "Parent ASIN",
    type: "string",
    isSingleColumn:true,
    dataIndex: "{tableName}.parentSku",
  },
  {
    key: "{tableName}.broadCategory",
    title: "Variant Analysis Table Broad Category",
    shortTitle: "Broad Category",
    type: "string",
    isSingleColumn:true,
    dataIndex: "{tableName}.broadCategory",
  },
  {
    key: "{tableName}.category",
    title: "Variant Analysis Table Category",
    shortTitle: "Category",
    type: "string",
    isSingleColumn:true,
    dataIndex: "{tableName}.category",
  },
  {
    key: "{tableName}.productNameWithImage",
    title: "Variant Analysis Table product_detail",
    shortTitle: "product_detail",
    type: "string",
    isSingleColumn:true,
    meta: {
      image: true,
    },
    dataIndex: "{tableName}.productNameWithImage",
  },
  {
    key: "{tableName}.cost",
    type: "number",
    dataIndex: "{tableName}.cost",
    title: "Variant Analysis Table spends",
    shortTitle: "Spends",
  },
  {
    key: "{tableName}.costDRR",
    type: "number",
    dataIndex: "{tableName}.costDRR",
    title: "Variant Analysis Table spends (DRR)",
    shortTitle: "Spends (DRR)",
  },
  {
    key: "{tableName}.roas",
    type: "number",
    dataIndex: "{tableName}.roas",
    title: "Variant Analysis Table ROAS",
    shortTitle: "ROAS",
  },
  {
    key: "{tableName}.roasDRR",
    type: "number",
    dataIndex: "{tableName}.roasDRR",
    title: "Variant Analysis Table ROAS (DRR)",
    shortTitle: "ROAS (DRR)",
  },
  {
    key: "{tableName}.impressions",
    type: "number",
    dataIndex: "{tableName}.impressions",
    title: "Variant Analysis Table Impressions",
    shortTitle: "Impressions",
  },
  {
    key: "{tableName}.impressionsDRR",
    type: "number",
    dataIndex: "{tableName}.impressionsDRR",
    title: "Variant Analysis Table Impressions (DRR)",
    shortTitle: "Impressions (DRR)",
  },
  {
    key: "{tableName}.clicks",
    type: "number",
    dataIndex: "{tableName}.clicks",
    title: "Variant Analysis Table clicks",
    shortTitle: "clicks",
  },
  {
    key: "{tableName}.clicksDRR",
    type: "number",
    dataIndex: "{tableName}.clicksDRR",
    title: "Variant Analysis Table clicks (DRR)",
    shortTitle: "clicks (DRR)",
  },
  {
    key: "{tableName}.ctr",
    type: "number",
    format: "percent",
    dataIndex: "{tableName}.ctr",
    title: "Variant Analysis Table CTR",
    shortTitle: "CTR",
  },
  {
    key: "{tableName}.ctrDRR",
    type: "number",
    format: "percent",
    dataIndex: "{tableName}.ctrDRR",
    title: "Variant Analysis Table CTR (DRR)",
    shortTitle: "CTR (DRR)",
  },
  {
    key: "{tableName}.cpc",
    type: "number",
    dataIndex: "{tableName}.cpc",
    title: "Variant Analysis Table CPC",
    shortTitle: "CPC",
  },
  {
    key: "{tableName}.cpcDRR",
    type: "number",
    dataIndex: "{tableName}.cpcDRR",
    title: "Variant Analysis Table CPC (DRR)",
    shortTitle: "CPC (DRR)",
  },
  {
    key: "{tableName}.cpm",
    type: "number",
    dataIndex: "{tableName}.cpm",
    title: "Variant Analysis Table CPM",
    shortTitle: "CPM",
  },
  {
    key: "{tableName}.cpmDRR",
    type: "number",
    dataIndex: "{tableName}.cpmDRR",
    title: "Variant Analysis Table CPM (DRR)",
    shortTitle: "CPM (DRR)",
  },
  {
    key: "{tableName}.conversions14d",
    type: "number",
    dataIndex: "{tableName}.conversions14d",
    title: "Variant Analysis Table orders",
    shortTitle: "orders",
  },
  {
    key: "{tableName}.conversions14dDRR",
    type: "number",
    dataIndex: "{tableName}.conversions14dDRR",
    title: "Variant Analysis Table Orders (DRR)",
    shortTitle: "Orders (DRR)",
  },
  {
    key: "{tableName}.conversion_rate",
    type: "number",
    format: "percent",
    dataIndex: "{tableName}.conversion_rate",
    title: "Variant Analysis Table conversion_rate",
    shortTitle: "conversion_rate",
  },
  {
    key: "{tableName}.conversion_rateDRR",
    type: "number",
    format: "percent",
    dataIndex: "{tableName}.conversion_rateDRR",
    title: "Variant Analysis Table conversion_rate (DRR)",
    shortTitle: "conversion_rate (DRR)",
  },
  {
    key: "{tableName}.sales14d",
    type: "number",
    format: "currency",
    dataIndex: "{tableName}.sales14d",
    title: "Variant Analysis Table revenue",
    shortTitle: "Revenue",
  },
  {
    key: "{tableName}.sales14dDRR",
    type: "number",
    format: "currency",
    dataIndex: "{tableName}.sales14dDRR",
    title: "Variant Analysis Table revenue (DRR)",
    shortTitle: "Revenue (DRR)",
  },
  {
    key: "{tableName}.average_order_value",
    type: "number",
    dataIndex: "{tableName}.average_order_value",
    title: "Variant Analysis Table AOV",
    shortTitle: "AOV",
  },
  {
    key: "{tableName}.average_order_valueDRR",
    type: "number",
    dataIndex: "{tableName}.average_order_valueDRR",
    title: "Variant Analysis Table AOV (DRR)",
    shortTitle: "AOV (DRR)",
  },
  {
    key: "{tableName}.acos",
    type: "number",
    dataIndex: "{tableName}.acos",
    title: "Variant Analysis Table ACOS",
    shortTitle: "ACOS",
  },
  {
    key: "{tableName}.acosDRR",
    type: "number",
    dataIndex: "{tableName}.acosDRR",
    title: "Variant Analysis Table ACOS (DRR)",
    shortTitle: "ACOS (DRR)",
  },
  
  // previous columns
  {
    key: "{tableName}.obDate",
    title: "Client Daily Campaign Amazon Table Date",
    shortTitle: "Date",
    type: "time",
    dataIndex: "{tableName}.obDate",
  },
  {
    key: "{tableName}.BSR",
    type: "number",
    isSingleColumn:true,
    dataIndex: "{tableName}.BSR",
    title: "Best Seller Rank",
    shortTitle: "BSR",
  },
  {
    key: "{tableName}.MaximumRetailPrice",
    type: "number",
    isSingleColumn:true,
    dataIndex: "{tableName}.MaximumRetailPrice",
    title: "Maximum Retail Price",
    shortTitle: "MRP",
  },
  {
    key: "{tableName}.SalePrice",
    type: "number",
    isSingleColumn:true,
    dataIndex: "{tableName}.SalePrice",
    title: "Selling Price",
    shortTitle: "Selling Price",
  },
  {
    key: "{tableName}.Quantity",
    type: "number",
    isSingleColumn:true,
    dataIndex: "{tableName}.Quantity",
    title: "Available Inventory",
    shortTitle: "Available Inventory",
  },

  {
    key: "{tableName}.tacos",
    type: "number",
    dataIndex: "{tableName}.tacos",
    title: "Total Advertising Cost of Sales (TACOS)",
    shortTitle: "Total ACOS",
  },
  {
    key: "{tableName}.total_asp",
    type: "number",
    dataIndex: "{tableName}.total_asp",
    title: "Total ASP",
    shortTitle: "ASP",
  },
  {
    key: "{tableName}.trevnue",
    type: "number",
    dataIndex: "{tableName}.trevnue",
    title: "Total Revenue",
    shortTitle: "Total Revenue",
  },
  {
    key: "{tableName}.troas",
    type: "number",
    dataIndex: "{tableName}.troas",
    title: "Total ROAS",
    shortTitle: "Total ROAS",
  },
  {
    key: "{tableName}.tunits_sold",
    type: "number",
    dataIndex: "{tableName}.tunits_sold",
    title: "Total Units Sold",
    shortTitle: "Total Units Sold",
  },
];


export const getColumnsConfig = (tableName, visibleColumns,isComparisonApi=false) => {
  //first map then filter because we need to replace the tableName first and then filter the visible columns
  return columnConfig
    .map((column) => {
      return {
        ...column,
        key: column.key.replace("{tableName}", tableName),
        dataIndex: `${!column?.isSingleColumn && isComparisonApi ?"startDate-endDate,":""}${column.dataIndex.replace("{tableName}", tableName)}`,
      };
    })
    .filter((column) => {
      return visibleColumns.includes(column.key);
    });
};
