import {COLORS_SERIES, commonOptions, useDrilldownCallback, humanize} from './utils';
import { Line} from "react-chartjs-2";


export const LineChartRenderer = ({ resultSet, pivotConfig, onDrilldownRequested, displayType }) => {
    const datasets = Object.entries(resultSet.series).map(([key, values], index) => ({
      label: humanize(resultSet.label.split('_')[index]),
      data: values.reverse(),
      borderColor: COLORS_SERIES[index],
      backgroundColor: COLORS_SERIES[index],
      pointRadius: 2,
      tension: 0.4,
      pointHoverRadius: 1,
      borderWidth: 2,
      tickWidth: 1,
      fill: false,
      yAxisID: "y" + index,
    }));
    const data = {
      labels: resultSet.labels.reverse().map((c) => {
        if (displayType === "Daily" && Date.parse(c)) {
          return new Date(c).toLocaleDateString("en-US", {
            day: "numeric",
            year: "numeric",
            month: "long",
          });
        } else if (displayType === "Monthly" && Date.parse(c)) {
          return new Date(c).toLocaleDateString("en-US", { month: "long" });
        } else if (/,/.test(c)) {
          return c.split(",")[0];
        } else {
          return c;
        }
      }),
      datasets,
    };
    const getElementAtEvent = useDrilldownCallback({
      datasets: data.datasets,
      labels: data.labels,
      pivotConfig,
      onDrilldownRequested,
    });

    if (commonOptions.scales.hasOwnProperty("y1")) {
      if (datasets.length === 1) {
        delete commonOptions.scales.y1;
      }
    }

    const options = {
      ...commonOptions,
      maintainAspectRatio:false,
      plugins: {
        datalabels: { // This code is used to display data values
          display: false
         },
        legend: {
          position: "top",
          labels: {
            usePointStyle: true, // Use point style (circles) for legend items
            boxWidth:6,
            boxHeight:6,
            borderRadius : 0,
            fontSize:6
          },
        },
      },
    };
    
    const finaloptions = !resultSet.label.includes("Average") ? options : //Add label on both asix on Discount analysis graph 
      {...options,
        scales: {
          "x": {
            "ticks": {
              "autoSkip": true,
              "maxRotation": 0,
              "padding": 12,
              "minRotation": 0
            },
            title:{
              text:"Date",
              display:true,
              font: {
                weight: "bold" 
              }
            }
          },
          "y0": {
            "type": "linear",
            "display": true,
            "position": "left",
            title:{
              text:resultSet.chartLabel,
              display:true,
              font: {
                weight: "bold" 
              }
            }
          }
        }}
    
    return (
      <Line
        type="line"
        data={data}
        options={finaloptions}
        height="140px"
      />
    );
  };