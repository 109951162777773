import React, { useEffect, useState, useRef } from "react";
import { useDeepCompareMemo } from "use-deep-compare";

import {
  formatTableData,
  ComparisonViewFunel,ComparisonViewFunelDRR, getComparisonHeader,
  format, formatDecimal, getComparisonData,
  metricStrings, getProductWithImage, getPercentageChangeDiv, checkObject,
  getComparisonDataForWrapperApi
} from "./utils";

import numeral from 'numeral';

export const ChildTableComparisonRenderer = ({
    resultSet,
    pivotConfig,
    customClasses,
    percentageShow,
    displayType,
    props,
    viewType,
    page,
    hideImages,
    drrView
  }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [isPlusIconDisabled, setIsPlusIconDisabled] = useState([]);
    const [hiddenKeys, setHiddenKeys] = useState([]);
  
    const handleCellClick = (rowIndex) => {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.includes(rowIndex)
          ? prevSelectedRows.filter((row) => row !== rowIndex)
          : [...prevSelectedRows, rowIndex]
      );
    };
  
    const [tableColumnsCalculated, dataSourceCalculated] =
      useDeepCompareMemo(() => {
        const columns = resultSet.tableColumns(pivotConfig);
        return [
          columns,
          formatTableData(columns, resultSet.tablePivot(pivotConfig)),
        ];
      }, [resultSet, pivotConfig]);
    const comparisonHeader = getComparisonHeader(tableColumnsCalculated);
    var tempPage = page;
    if (page === "product-parent") {
      tempPage = "product-child";
    }
    const comparisonData =  resultSet?.originalApiData?.length ? getComparisonDataForWrapperApi(resultSet?.originalApiData):getComparisonData(resultSet, tempPage, "child")

    const [totals, setTotals] = useState({});
    const [tableColumns, setTableColumns] = useState(comparisonHeader);
    const [dataSource, setDataSource] = useState(comparisonData);
  
    useEffect(() => {
     
      let add = [];
      
      if (viewType === "Comparison View") {
        if(drrView === 'drr') {
          add = tableColumns.reduce((acc, item, index) => {
            if (ComparisonViewFunelDRR.includes(item.key.split(".").pop())) {
              acc.push(index);
            }
            return acc;
          }, []);
        } else {
          add = tableColumns.reduce((acc, item, index) => {
            if (ComparisonViewFunel.includes(item.key.split(".").pop())) {
              acc.push(index);
            }
            return acc;
          }, []);
        }
        setHiddenKeys((prev) => {
          add.push(
            tableColumns
              .map(function (o) {
                return o.shortTitle;
              })
              .indexOf("month_numeric")
          );
          return add;
        });
      } else {
        if (typeof props.handleComparisonView === "function") {
          props.handleComparisonView(viewType);
        }
      }
    }, [viewType]);
  
    useEffect(() => {
      setHiddenKeys((prev) => {
        let data = [...new Set(prev)];
        data.push(
          tableColumnsCalculated
            .map(function (o) {
              return o.shortTitle;
            })
            .indexOf("month_numeric")
        );
        return data;
      });
  
      setTableColumns(comparisonHeader);
      setDataSource(comparisonData);
    }, [resultSet]);
  
    var imageDataKeys = tableColumns.map(function (o, index) {
      if (o.meta?.image) {
        return index;
      }
    }).filter(function (item) {
      return item !== undefined;
    });

    function shuffleObjectKeys(originalObject, keyOrderArray) {
      const shuffledObject = {};
      const remainingKeys = { ...originalObject };
    
      keyOrderArray.forEach((key) => {
        // if (originalObject.hasOwnProperty(key)) {
          shuffledObject[key] = originalObject[key];
          delete remainingKeys[key];
        // }
      });
    
      // Add any remaining keys not present in the keyOrderArray
      Object.keys(remainingKeys).forEach((key) => {
        shuffledObject[key] = remainingKeys[key];
      });
    
      return shuffledObject;
    }
  
    var tableBody = (
      <>
        {tableColumns?.length && dataSource?.length ? (
          <>
            {dataSource?.map((tr, index1) => {
               const objectOrderKeys = tableColumns?.map((data) => data?.key);
               const modifiedObject = shuffleObjectKeys(tr,objectOrderKeys);
              return (
                <React.Fragment key={index1 + 1}>
                  <tr
                    index={index1 + "-row"}
                    onClick={() => handleCellClick(index1 + "childdata")}
                    className={
                      selectedRows.includes(index1 + "childdata")
                        ? "highlight cursor-pointer"
                        : "cursor-pointer childRows"
                    }
                  >
                    {Object.entries(modifiedObject).map(([datakey,td], columnindex) => (
                      <>
                        {hideImages && hiddenKeys.includes(columnindex) ? (
                          ""
                        ) : (
                          <td
                            onClick={() => handleCellClick(index1 + "childdata")}
                            index={columnindex + "-column"}
                            className={
                              columnindex === 0
                                ? "targetinng-second-column "
                                : hiddenKeys.includes(columnindex)
                                ? "d-none"
                                : ""
                            }
                          >
                            {columnindex === 0 ? (
                              <div className="d-flex gap-3 align-items-center justify-content-between">
                                <div>
                                  {typeof td === "object" && td !== null
                                    ? td.current.value
                                    : td === null
                                    ? ""
                                    : td}
                                </div>
                              </div>
                            ) : (page === "product-child" ||
                                page === "product-parent" ||
                                page === "expendable") &&
                              columnindex === 1 ? (
                                datakey?.split(".")?.pop() === "productNameWithImage" ? imageDataKeys.includes(columnindex) && (td !== null || td.current.value)  ?
                                  <div style={{minWidth:'250px'}}>{getProductWithImage(td?.current?.value ?? td ,hideImages)}</div>
                              :(
                                td.current.value
                              ) : td === null ? (
                                ""
                              ) : (
                                td
                              )
                            ) : hideImages ? (
                              <span className="">
                                 <> 
                                 {metricStrings.includes(datakey.split(".").pop()) && datakey.split(".").pop().includes("DRR")  ? numeral(checkObject(td, "current")).format(formatDecimal) : metricStrings.includes(datakey.split(".").pop()) ? numeral(checkObject(td, "current")).format(format) : checkObject(td, "current")}
                                  </>
                                 
                                {getPercentageChangeDiv(
                                  checkObject(td, "change"),
                                  tableColumns[columnindex].key,
                                  hideImages
                                )}
                              </span>
                            ) : (
                              <>
                                <div className="d-flex gap-3 align-items-center justify-content-between">
                                  <div>
                                     <> 
                                     {metricStrings.includes(datakey.split(".").pop()) && datakey.split(".").pop().includes("DRR")  ? numeral(checkObject(td, "current")).format(formatDecimal) : metricStrings.includes(datakey.split(".").pop()) ? numeral(checkObject(td, "current")).format(format) : checkObject(td, "current")}
                                      </>
                                  </div>
                                  <div>
                                    {getPercentageChangeDiv(
                                      checkObject(td, "change"),
                                      tableColumns[columnindex].key,
                                      hideImages
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </td>
                        )}
                        {columnindex > 0 ? (
                          page === "product-child" && columnindex === 1 ? (
                            ""
                          ) : page === "product-parent" && columnindex === 1 ? (
                            ""
                          ) : page === "expendable" && columnindex === 1 ? (
                            ""
                          ) : hideImages && hiddenKeys.includes(columnindex) ? (
                            ""
                          ) : (
                            <>
                              <td
                                onClick={() => handleCellClick(index1 + "data")}
                                index={columnindex + "-column"}
                                className={
                                  columnindex === 0
                                    ? "targetinng-second-column " + props.page
                                    : hiddenKeys.includes(columnindex)
                                    ? "d-none"
                                    : ""
                                }
                              >
                                 <> 
                                 {metricStrings.includes(datakey.split(".").pop()) && datakey.split(".").pop().includes("DRR")  ? numeral(checkObject(td, "previous")).format(formatDecimal) : metricStrings.includes(datakey.split(".").pop()) ? numeral(checkObject(td, "previous")).format(format) : checkObject(td, "previous")}
                                 </>
                              </td>
                            </>
                          )
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </tr>
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <div>
            <p>NO DATA FOUND</p>
          </div>
        )}
      </>
    );
    return tableBody;
  };