import React from "react";
import { generateIFrameReportUrl } from "../../helper/clientData/utils";
import { useGlobalContext } from "../../context/global";
import { useNavigate } from "react-router-dom";

const PlatformTab = ({ name, src, isDisabled }) => {
  const disabled = isDisabled ? "disable-pointer-events" : "";
  const { selectedPlatform, updatePlatform, clientId } = useGlobalContext();
  const isSelected = name?.toLowerCase() === selectedPlatform ? "selected" : "";
  const navigate = useNavigate();
  return (
    <>
      <div>
        <div
          className={`platform-tab-container ${isSelected} ${disabled}`}
          onClick={() => {
            updatePlatform(name?.toLowerCase());
            if (name?.toLowerCase() === "amazon") {
              navigate("/");
            } else {
              const url = generateIFrameReportUrl(
                name?.toLowerCase(),
                "Day",
                clientId
              );
              navigate("/reports?url=" + url);
            }
          }}
        >
          <div className="platform-tab">
            <div className="platform-tab-icon">
              <img src={src} alt="Amazon" />
            </div>
          </div>
        </div>
        {isSelected ? <div className="platform-tab-border"></div> : null}
      </div>
    </>
  );
};

export default PlatformTab;
