import { useState, useEffect } from 'react';

const useSyncedState = (initialState, key,stateExportKey,setStateExportKey) => {
  const storedState = sessionStorage.getItem(key)
  const [state, setState] = useState(storedState ? JSON.parse(storedState) : initialState);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === key) {
        const newState = JSON.parse(event.newValue);
        setState(newState);
      }
    };
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);

  function parseDateStrings(data) {
    if (Array.isArray(data)) {
      // Case: array of objects
      for (const obj of data || []) {
        if (obj && obj.hasOwnProperty('startDate') && obj.hasOwnProperty('endDate')) {
          obj.startDate = new Date(obj.startDate);
          obj.endDate = new Date(obj.endDate);
        }
      }
    } else if (typeof data === 'object' && data !== null) {
      // Case: object of objects
      for (const key in data) {
        const obj = data[key];
        if (obj && obj.hasOwnProperty('startDate') && obj.hasOwnProperty('endDate')) {
          obj.startDate = new Date(obj.startDate);
          obj.endDate = new Date(obj.endDate);
        }
      }
    }
  
    return data;
  }
  

  const setSyncedState = (newState) => {
    setState(newState);
    sessionStorage.setItem(key, JSON.stringify(newState));
  };

  const parsedState = typeof state === 'object' ? parseDateStrings(state) : state

  return {[stateExportKey]:parsedState,[setStateExportKey]:setSyncedState};
};

export default useSyncedState;
