import React, { useRef, useState } from "react";
import "./automationslogs.css";
import { Spinner, Stack } from "react-bootstrap";
import SectionTitle from "../../components/sectionTitle";
// Components
import TableRb from "../../components/Tables/tableRb";
import { formatDate } from "../../components/Reports/Components/utils";
import { useGlobalContext } from "../../context/global";
import cubejs from "@cubejs-client/core";
import useToken from "../../helper/Reports/useToken";
// Constants
import {dataHeadsForAutomationLogs} from '../../constant/dataHeadsForAutomationsLogs'
import { useEffect } from "react";
import useHighlightList from "../../hooks/useHighlightList";
import { masterApi } from "../../apis/masterApi";
import useInitialLoad from "../../hooks/useInitialLoad";
import {
  TOAST_DANGER,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_SUCCESS,
} from "../../constant/HardTypes";
import moment from "moment/moment";
import { Tooltip as ReactTooltip } from "react-tooltip";
import LazyLoadSpinner from "../../components/spinners/LazyLoadSpinner";
import ModalRb from "../../components/modal/ModalRb";
import MultiSelect from "../../components/DropDown/Multiselect";
import downloadXls from "../../assets/svgs/download.svg";
import loader from "../../assets/svgs/button-loader.svg";

import createDataForCSV from "../../helper/Automations/createDataForCSV";
import { downloadCsv } from "../Optimizer/utils";
import automationLogsFilterParser from "../../helper/Automations/automationLogsFilterParser";
import SearchBarForCampaignListOnly from "../../components/searchBarForCampaignListOnly";
import AddIcon from "../../assets/svgs/plus_box.svg";
import MinusIcon from "../../assets/svgs/minus_box.svg";
import CustomDropDown from "../../components/DropDown/CustomDropDown";
import ShimmerTable from "../../components/Tables/ShimmerTable";
import TableRowWithPagination from "../../components/Tables/TableRowWithPagination";
const AutomationsLogs = () => {
  const { showAlert, applyCalendar, calendarDate, cubeToken, setCubeToken, clientId } = useGlobalContext();
  const [token, refresh] = useToken("");
  const cubejsApi = cubejs(cubeToken, {
    apiUrl: process.env.REACT_APP_CUBE_JS_URL,
  });
  const limit = 100;
  const tableRefScroll = useRef(null);
  const [offset,setOffset] = useState(0);
  const [lazyLoading,setLazyLoading] = useState(false);
  const [automationLogs, setAutomationLogs] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const allCampaigns = campaignData?.map((campaign) => campaign?.name);
  const [initialLoad, setInitialLoad] = useInitialLoad();
  const [allLoaders, setAllLoaders] = useState({ mainLoader: false,reportLoader:false });
  const tableName = "rule_entity_tracking";
  const [isVisible,setIsVisible] = useState(false);
  const [campaignsToShow,setCampaignsToShow] = useState([]);
  const [filters,setFilters] = useState({
    campaigns:[],
    adsets:[],
    status:[],
    ruleName:['']
  })
  const [adsetData,setAdsetData] = useState([]);
  const restCubeFilters = automationLogsFilterParser(filters,campaignData,adsetData); // Using function to parse cube filters whenever the filters will change we will get the new filters.
  const allAdsets = adsetData?.map((adset) => adset?.name);

  const parentRowCubeMeasures = [
    "rule_entity_tracking.rule_name",
    "rule_tracking.parent_rule_config",
    "rule_entity_tracking.decision",
    "rule_entity_tracking.extra_info_json",
  ]
  const childRowCubeMeasures =     [
    "rule_entity_tracking.rule_name",
    "rule_entity_tracking.rule_task_name",
    "rule_entity_tracking.rule_config",
    "rule_tracking.parent_rule_config",
    "rule_entity_tracking.created_at",
    "rule_entity_tracking.entity_id",
    "rule_entity_tracking.message",
    "rule_entity_tracking.decision",
    "rule_entity_tracking.parent_entity_name",
    "rule_entity_tracking.entity_name",
    "rule_entity_tracking.decision_status",
    "rule_entity_tracking.campaignId",
    "rule_entity_tracking.extra_info_json",
    "rule_entity_tracking.adGroupId",
  ]

  const parentRowCubeDimensions = [
    "rule_entity_tracking.rule_name_config",
    // "rule_entity_tracking.track_id",
  ]
  const [cubeQueryOrder, setCubeQueryOrder] = useState([
    ["rule_entity_tracking.created_at", "desc"],
  ]);
  const [cubeQueryTimeDimensions, setCubeQueryTimeDimensions] = useState([
    {
      dimension: "rule_entity_tracking.time",
      dateRange: [
        formatDate(calendarDate[0].startDate),
        formatDate(calendarDate[0].endDate),
      ],
    },
  ]);

  const [viewType,setViewType] = useState("Global");
  const isGlobal = viewType === "Global"
  const initialData = []

  const cubeQueryMeasure = isGlobal ? parentRowCubeMeasures : childRowCubeMeasures;
  const cubeQueryDimensions = isGlobal ? parentRowCubeDimensions : [];
  const [currentLoadingIndex,setCurrentLoadingIndex] = useState(0);
  const [mainRawData,setMainRawData] = useState(initialData);
  const [handleHighlightList] = useHighlightList(mainRawData,setMainRawData);
  const cubeQueryFilters = [
    {
      member: "rule_entity_tracking.client_id",
      operator: "equals",
      values: [clientId],
    },
    {
      "member": "rule_entity_tracking.type",
      "operator": "equals",
      "values": [
        "AMAZON_Bid_Optimisation_Rule"
      ]
    },
    {
      "member": "rule_entity_tracking.change_recommended",
      "operator": "equals",
      "values": [
        "1"
      ]
    },
    ...restCubeFilters
  ]

  if(isGlobal) {
    cubeQueryFilters.push(  {
      "member": "rule_entity_tracking.rule_message",
      "operator": "equals",
      "values": [
        "RULE_IS_EXECUTED"
      ]
    })
  }
//   const [cubeQueryFilters, setCubeQueryFilters] = useState(
//     [
//     {
//       member: "rule_entity_tracking.client_id",
//       operator: "equals",
//       values: [clientId],
//     },
//     {
//       "member": "rule_entity_tracking.type",
//       "operator": "equals",
//       "values": [
//         "AMAZON_Bid_Optimisation_Rule"
//       ]
//     },
//     {
//       "member": "rule_entity_tracking.change_recommended",
//       "operator": "equals",
//       "values": [
//         "1"
//       ]
//     },
//     ...restCubeFilters
//   ]
// );
  useEffect(() => {
        const startDate = formatDate(calendarDate[0].startDate);
        const endDate = formatDate(calendarDate[0].endDate);
        if (startDate !== cubeQueryTimeDimensions[0]["dateRange"][0] || endDate !== cubeQueryTimeDimensions[0]["dateRange"][1]) {
          setAllLoaders({ ...allLoaders, mainLoader: true });
            setCubeQueryTimeDimensions((prev) => {
                let data = [...prev];
                data[0]["dateRange"] = [startDate, endDate];
                return data;
            });
            setOffset(0)
        }
}, [applyCalendar]);
  useEffect(() => {
    //table data
    if (cubeToken && campaignData && campaignData.length > 0) {
      setAllLoaders({ allLoaders, mainLoader: true });
      cubejsApi.load({
            measures: cubeQueryMeasure,
            order: cubeQueryOrder,
            dimensions:cubeQueryDimensions,
            filters: cubeQueryFilters,
            offset:0,
            timeDimensions: cubeQueryTimeDimensions,
            limit: limit,
          },{
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((resultSet) => {
          let resultData = isGlobal ? resultSet.loadResponse.results[0].data?.map((data) => {
            return data
          }): resultSet.loadResponse.results[0].data.map(
            (data, index) => {
              if(data['rule_tracking.parent_rule_config'] !== null) {
                return {
                  ...data,
                  [`${tableName}.campaign_name`]: getCampaignNames(getCampaignIds(JSON.parse(data['rule_tracking.parent_rule_config']))),
                  [`${tableName}.campaign_single_name`]: campaignData?.find((item) => item?.id === JSON?.parse(data['rule_entity_tracking.campaignId']))?.name ?? "-",
                };
              } else {
                return {
                  ...data,
                  [`${tableName}.campaign_name`]: "",
                  [`${tableName}.campaign_single_name`]: campaignData?.find((item) => item?.id === JSON?.parse(data['rule_entity_tracking.campaignId']))?.name ?? "-",
                };
              }
              
            }
          );
          setMainRawData(resultData)
          setAutomationLogs(resultData)
          setAllLoaders({ allLoaders, mainLoader: false });
          setInitialLoad(false);
        })
        .catch((error) => {
          if (error.message === "Invalid token") {
            setCubeToken(false);
            localStorage.setItem("cubeToken", null);
            refresh();
          }
        });
    }
  }, [cubeQueryTimeDimensions, cubeToken, cubeQueryOrder, campaignData,filters,viewType]);

  useEffect(() => {
    setOffset(0)
  },[filters,viewType])

  function getCampaignIds(data) {
    let campaignIds = [];
    if (data.entityFilter && data.entityFilter.entityFilterRule && data.entityFilter.entityFilterRule.filters) {
      const filters = data.entityFilter.entityFilterRule.filters;
      for (const filter of filters) {
        if (filter.field === "Campaign_Id") {
          campaignIds = filter.value || [];
          break; // Assuming there is only one filter with "Campaign_Id"
        }
      }
    }
    return campaignIds
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        setAllLoaders({ ...allLoaders, mainLoader: true });
        const dataBidValues = {
          limit: 5000,
          source: "amazon",
          fields:'id,platform_id,name'
        };
        const campData = await masterApi(dataBidValues, "campaignList", false);
        setCampaignData(campData?.data?.data);
        if (campData?.statusCode !== 200) {
          showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
            backgroundColor: TOAST_DANGER,
            title: "Error",
            description: `Some Unknown Error Occurred While Fetching Campaigns`,
          });
        }
        setAllLoaders({ ...allLoaders, mainLoader: false });
      } catch (error) {
        setAllLoaders({ ...allLoaders, mainLoader: false });
      }
    };
    // Call the async function
    fetchData();
  }, []);
  const getCampaignNames = (campaignIds) => {
    const names = campaignIds.map((campaignId) => {
      const campaign = campaignData.find((item) => item.id == campaignId);
      return campaign ? campaign.name : null;
    });
    return names.filter(Boolean)
  };
  function getCommaSepratedValue (data) {
    
    let formattedValues = JSON.parse(data)?.scheduleConfig?.frequencyValues.map(day => day.charAt(0).toUpperCase() + day.slice(1))
    if(formattedValues) {
      return formattedValues.join(', ')
    } else {
      // console.log(JSON.parse(data))
      return JSON.parse(data)?.scheduleConfig?.frequencyValues
    }
    
  }
  const targetingsMAPPER = {
    QUERY_HIGH_REL_MATCHES: "close match",
    QUERY_BROAD_REL_MATCHES: "loose match",
    ASIN_ACCESSORY_RELATED: "complements",
    ASIN_SUBSTITUTE_RELATED: "substitutes",
  };

  function replaceText(text) {
    let updatedText = text;
  
    Object.keys(targetingsMAPPER).forEach(key => {
      if (text?.includes(key)) {
        const mappedValue = targetingsMAPPER[key];
        updatedText = updatedText.replace(key, mappedValue);
      }
    });
  
    return updatedText;
  }

  useEffect(() => {
    getAdsetList();
  },[])

  async function getAdsetList () {
    try {
      const data = {
        source:'amazon',
        limit:5000,
        fields:'id,name,platform_id'
      }
      const result = await masterApi(data,"adsetList",false)
      if(result?.data?.data) {
        setAdsetData(result?.data?.data ?? [])
      }
    } catch (error) {
      console.log(error,"Error")
    }
  }

const handleCampaignShow = (data) => {
  setIsVisible(true);
  setCampaignsToShow(data)
}

  const showOnlyRestrictedText = (trimTill, data) => {
    const slicedData = data?.slice(0, trimTill) ?? []
    return (
      <>
        <span title={"Click to see all campaigns"} onClick={() => {
          handleCampaignShow(data)
        }}  data-tooltip-id={"campaign-names"}>{slicedData ? slicedData?.join(",") : '-'}</span>
      </>
    );
  };

  
  const loadMoreData = async (offset) => {
    return new Promise((resolve,reject) => {
        try {
          cubejsApi.load({
            measures: cubeQueryMeasure,
            order: cubeQueryOrder,
            filters: cubeQueryFilters,
            offset:offset,
            timeDimensions: cubeQueryTimeDimensions,
            limit: limit,
          },{
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((resultSet) => {
          let resultData = isGlobal ? resultSet.loadResponse.results[0].data : resultSet.loadResponse.results[0].data.map(
            (data, index) => {
              if(data['rule_tracking.parent_rule_config'] !== null) {
                return {
                  ...data,
                  [`${tableName}.campaign_name`]: getCampaignNames(getCampaignIds(JSON.parse(data['rule_tracking.parent_rule_config']))),
                  [`${tableName}.campaign_single_name`]: campaignData?.find((item) => item?.id === JSON?.parse(data['rule_entity_tracking.campaignId']))?.name ?? "-",
                };
              } else {
                return {
                  ...data,
                  [`${tableName}.campaign_name`]: "",
                  [`${tableName}.campaign_single_name`]: campaignData?.find((item) => item?.id === JSON?.parse(data['rule_entity_tracking.campaignId']))?.name ?? "-",
                };
              }
              
            }
          );
          resolve(resultData)
        })
        .catch((error) => {
          if (error.message === "Invalid token") {
            setCubeToken(false);
            localStorage.setItem("cubeToken", null);
            refresh();
          }
        });
        } catch (error) {
          console.log(error)
          reject(error)
        }
    }) 
  };

  const handleScroll = async () => {
    if (tableRefScroll.current) {
      const { scrollTop, scrollHeight, clientHeight } = tableRefScroll.current;
      const lastScrollTop = tableRefScroll.current.getAttribute("data-scroll-top") || 0;
      // Determine scroll direction
      const scrollDirection = tableRefScroll.current.scrollTop > lastScrollTop ? "down" : "up";
      tableRefScroll.current.setAttribute("data-scroll-top", scrollTop);
      if (scrollTop === 0) {
      } else if ((scrollTop + clientHeight >= scrollHeight - 50) && lazyLoading===false && scrollDirection==="down" && mainRawData?.length >=100) {
          const newOffset = offset+limit
          setLazyLoading(true)
          const data = await loadMoreData(newOffset);
          if(data?.length) {
            setMainRawData([...mainRawData,...data]);
            setLazyLoading(false)
            setOffset(newOffset)
          } else {
            setLazyLoading(false)
          }

      }
    }
  };

  const handleDownloadReport = () => { 
    setAllLoaders({ ...allLoaders, reportLoader: true });
    if (cubeToken && campaignData && campaignData.length > 0) {
      // setAllLoaders({ allLoaders, mainLoader: true });
      cubejsApi?.load({
            measures: childRowCubeMeasures,
            order: cubeQueryOrder,
            filters: cubeQueryFilters,
            timeDimensions: cubeQueryTimeDimensions,
            limit:5000
          },{
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((resultSet) => {
          setAllLoaders({ ...allLoaders, reportLoader: false });
          let resultData = resultSet.loadResponse.results[0].data.map(
            (data, index) => {
              if(data['rule_tracking.parent_rule_config'] !== null) {
                return {
                  ...data,
                  [`${tableName}.campaign_name`]: getCampaignNames(getCampaignIds(JSON.parse(data['rule_tracking.parent_rule_config']))),
                  [`${tableName}.campaign_single_name`]: campaignData?.find((item) => item?.id === JSON?.parse(data['rule_entity_tracking.campaignId']))?.name ?? "-",
                };
              } else {
                return {
                  ...data,
                  [`${tableName}.campaign_name`]: "",
                  [`${tableName}.campaign_single_name`]: campaignData?.find((item) => item?.id === JSON?.parse(data['rule_entity_tracking.campaignId']))?.name ?? "-",
                };
              }
              
            }
          );
          const dataHeadsForReport = dataHeadsForAutomationLogs?.filter((dataHead) => dataHead?.id !== 'campaign_name')
          const {headers,excelBodyRow} = createDataForCSV(dataHeadsForReport,resultData)
          setAllLoaders({ ...allLoaders, reportLoader: false });
          downloadCsv(headers,excelBodyRow)
          // setAutomationLogs(resultData)
          // setAllLoaders({ allLoaders, mainLoader: false });
          // setInitialLoad(false);
        })
        .catch((error) => {
          if (error.message === "Invalid token") {
            setCubeToken(false);
            setAllLoaders({ ...allLoaders, reportLoader: false });
            localStorage.setItem("cubeToken", null);
            refresh();
          }
        });
    }
  }

  const renderTableCell = (data, dataHead) => {
    if (!dataHead?.isRestColumn) {
      const columnId = dataHead.id;
        if (columnId === 'rule_config') {
          if(JSON.parse(data['rule_entity_tracking.rule_config'])?.ruleActionConfig?.entityActionConfig?.percentageChange === undefined) {
            return JSON.parse(data['rule_entity_tracking.rule_config'])?.ruleActionConfig?.entityActionConfig?.actionType 
          } else {
            return JSON.parse(data['rule_entity_tracking.rule_config'])?.ruleActionConfig?.entityActionConfig?.actionType + " by "+ JSON.parse(data[`${tableName}.${dataHead.id}`])?.ruleActionConfig?.entityActionConfig?.percentageChange + "%"
          }
        } else if (columnId === 'parent_rule_config') {
          return getCommaSepratedValue((data['rule_tracking.parent_rule_config']))
        } else if (columnId === 'lookback_window') {
          return JSON.parse(data[`${tableName}.rule_config`])?.entityMetricFilter?.timePeriod 
        } else if (columnId === 'created_at') {
          return moment.utc(data[`${tableName}.${dataHead.id}`]).local().format("HH:mm:ss DD-MM-YYYY")
        } else if (columnId === 'decision_old' && JSON.parse(data[`${tableName}.decision`]).extraInfo && JSON.parse(data[`${tableName}.decision`]).extraInfo.OldBid !== undefined) {
          return JSON.parse(data[`${tableName}.decision`]).extraInfo.OldBid?.toFixed(2) ?? '-'
        } else if (columnId === 'decision_new' && JSON.parse(data[`${tableName}.decision`]).extraInfo && JSON.parse(data[`${tableName}.decision`]).extraInfo.NewBid !== undefined) {
          return JSON.parse(data[`${tableName}.decision`]).extraInfo?.NewBid?.toFixed(2) ?? '-'
        } else if (columnId === 'decision_status') {
          if(JSON.parse(data[`${tableName}.decision`]).decisionApplied === true) {
            return "True"
          } else {
            return "False"
          }
        } else if (columnId === 'entity_name') {
           return replaceText(data[`${tableName}.${columnId}`]) ?? "-"
        } else if (columnId === "campaign_name") {
          return showOnlyRestrictedText(4,data[`${tableName}.${columnId}`]) ?? "-"
        } else {
          // Default case for other columns
          return data[`${tableName}.${columnId}`] || '-'
        }
    }}
  const renderTableFormattedCell = (data, index) => {
    return <td>{renderTableCell(data, index)}</td>
  }
  const renderTableRow = (data, index , isChildRow) => {
      return (<tr key={index} className={`${data?.isHighlighted ? 'highlight' :'cursor-pointer'} ${isChildRow ? 'child-rows' :'' } cursor-pointer`}  onClick={() => handleHighlightList(index)}>
      {dataHeadsForAutomationLogs?.map((dataHead) =>  renderTableFormattedCell(data, dataHead))}
    </tr>)
  };

  const filterHeads = [
    {
      fieldName:'Campaign Name'
    }
  ]
  const renderMainTableBody =(data) => {
    return (
      <>
          <div className="main-campaignlist-table-body-container automations-main-table-wrappe automation-filter-table-body">
    {data?.length ? (
      <>
        <table className="automations-list-table">
          <thead className="fixed-table">
            <tr >
              {filterHeads?.map((th) => {
                return <th style={{position:'sticky',top:0}} key={th.fieldName}>{th.fieldName}</th>;
              })}
            </tr>
          </thead>
          <tbody className="another-body">
            {data?.map((name) => {
              return (
                <tr key={name}>
                  <td>{name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    ) : null}
  </div>
      </>
    )
  
  }
  const mainModalBodyToShow = (
    <div className="d-flex gap-5">
    {campaignsToShow.length ? (
      <>
         <div className="automations-table-wrapper">
         <small className="text-center">{`Total Campaigns:- (${campaignsToShow?.length})`}</small>  
                  <div className="automations-main-table">
                    <TableRb table={renderMainTableBody(campaignsToShow)} />
                  </div>
          </div>
      </>
    ) : (
      <h2>No Data To Show</h2>
    )}
  </div>
  )
  const handleFiltersChange = (key,value) => {
    setFilters((prev) => {
      const copy = {...prev};
      copy[key]  = value
      return copy
    })
  }
  const onSearchBarHandler = (e) => {
    if (e.target.value === "" || e.key === "Enter") {
      setFilters({ ...filters, ruleName: [e.target.value] });
    }
  };

  const getRows = async (cubeData) => {
    return new Promise((resolve,reject) => {
      try {
        cubejsApi.load(cubeData,{
          headers: {
            "Access-Control-Allow-Origin": true,
          },
        }
      )
      .then((resultSet) => {
        let resultData = resultSet.loadResponse.results[0].data
        resolve(resultData)
      })
      .catch((error) => {
        if (error.message === "Invalid token") {
          setCubeToken(false);
          localStorage.setItem("cubeToken", null);
          refresh();
        }
      });
      } catch (error) {
        console.log(error)
        reject(error)
      
    }
  })}


  const removeCubeName = (data, cubeName,alternateCubeName) => {
    let transformedItem = {};
    Object.keys(data).forEach(key => {
        let newKey = key.replace(cubeName, '');
        if(key === newKey) {
         newKey = key.replace(alternateCubeName, '');
        }
        const value = data[key];
        transformedItem[newKey] = value;
    });
    return transformedItem;
};

  const filteredRowsData = isGlobal ?  mainRawData?.map((data) => {
    return removeCubeName(data, "rule_entity_tracking.","rule_tracking.");
  }) : mainRawData
  
  async function getChildRows (data) {
    const childRows = await getRows(data)
    return childRows
  }

  const expandRow = async (parentRow,index) => {
    const parsedMainRawData = JSON.parse(JSON.stringify(mainRawData))
    const newFilters = [...cubeQueryFilters,{member: "rule_entity_tracking.rule_name",operator: "equals",values: [parentRow['rule_name']]}]
    const cubeData = {
      measures: childRowCubeMeasures,
      order: cubeQueryOrder,
      filters: newFilters,
      timeDimensions: cubeQueryTimeDimensions,
    }
    parsedMainRawData[index].isPlusIconDisabled = !parsedMainRawData[index].isPlusIconDisabled
    if(!parsedMainRawData[index].child_rows?.length && parsedMainRawData[index].isPlusIconDisabled) {
      setAllLoaders({ ...allLoaders, childRowLoader: true });
      setCurrentLoadingIndex(index)
      let childRows = await getChildRows(cubeData)
      childRows = childRows?.map((data) => {
        if(data['rule_tracking.parent_rule_config'] !== null) {
          return {
            ...data,
            [`${tableName}.campaign_name`]: getCampaignNames(getCampaignIds(JSON.parse(data['rule_tracking.parent_rule_config']))),
            [`${tableName}.campaign_single_name`]: campaignData?.find((item) => item?.id === JSON?.parse(data['rule_entity_tracking.campaignId']))?.name ?? "-",
          };
        } else {
          return {
            ...data,
            [`${tableName}.campaign_name`]: "",
            [`${tableName}.campaign_single_name`]: campaignData?.find((item) => item?.id === JSON?.parse(data['rule_entity_tracking.campaignId']))?.name ?? "-",
          };
        }
      })
      parsedMainRawData[index].child_rows = childRows
      setAllLoaders({ ...allLoaders, childRowLoader: false });
    } 
    setMainRawData(parsedMainRawData)

    }

  const renderChildRow = (child_row,index,isInnerRow) => {
    return renderTableRow(child_row,index,isInnerRow)
  }

  const parentRowFormatter = (data,key) => {
    if(key==="parent_rule_config") {
      return getCommaSepratedValue(data[key])
    }

    if(key==="campaign_name") {
      return getCampaignNames(getCampaignIds(JSON.parse(data['parent_rule_config'])))?.length ?? 0
    }
    if(key==="count") {
      return JSON.parse(data['parent_rule_config'])?.extraInfo?.targeting_count ?? 0
    }
    return data[key] ?? '-'
  }

  const renderParentRow = (parentRow,index) => {
    return (
      <>
      <tr className={`${parentRow?.isHighlighted ? 'highlight' : ''} cursor-pointer`} onClick={(e) => {
        e.stopPropagation()
        handleHighlightList(index)
      }}>
        <td className="ads-td-second">
          <div className="main-parent-table-product-container d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <span>{parentRow?.rule_name ?? '-'}</span>
            </div>
            <div
              className={
                parentRow.isPlusIconDisabled
                  ? "plus-icon-sec-container d-flex align-items-center"
                  : "plus-icon-sec-container-when-clicked d-flex align-items-center"
              }
            >
              <div className="product-child-row-button cursor-pointer-global d-flex justify-content-end">
                <div className="td-with-loader d-flex align-items-center">
                  {allLoaders.childRowLoader && currentLoadingIndex === index ? (
                    <Spinner/>
                  ) : (
                    <img
                      src={!parentRow.isPlusIconDisabled ? AddIcon : MinusIcon}
                      alt=""
                      onClick={(e) => {
                        e.stopPropagation()
                        expandRow(parentRow , index);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </td>
        {dataHeadsForAutomationLogs?.map((currentDataHead, i) => {
          if (!currentDataHead.isDefaultHead) {
            return (
              <td key={i + 11} className="stats-data-col">
                {parentRowFormatter(parentRow,currentDataHead.id)}
              </td>
            );
          }
        })}
      </tr>
      {
        allLoaders?.childRowLoader && !parentRow?.child_rows?.length && index === currentLoadingIndex ? <ShimmerTable rows={3} hideHeaders={true} tableHeaders={dataHeadsForAutomationLogs}/> :null
      }
      {
        parentRow?.isPlusIconDisabled && parentRow?.child_rows?.length ? <TableRowWithPagination rows={parentRow?.child_rows?.map((data,index) => {
          return renderChildRow(data,index,true)
        })} limit={20} colSpanLength={dataHeadsForAutomationLogs?.length}/>  : null
      }
      </>
    );
  };



  const tableBody = (
    <>
      <div className="main-campaignlist-table-body-container automations-logs-main-table-wrapper" ref={tableRefScroll} onScroll={handleScroll}>
        {automationLogs?.length ? (
          <>
            <table  className="automations-table">
              <thead className="fixed-table">
                <tr className="main-table-row-fixed">
                  {dataHeadsForAutomationLogs?.map((dataHead) => {
                    return (
                      <th
                        key={dataHead?.fieldName}
                        className={"targeting-table-heads"}
                      >
                        {dataHead?.fieldName}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="another-body">{filteredRowsData?.map((data,index) => {
                return isGlobal ? renderParentRow(data,index) : renderChildRow(data,index,false)
              })}</tbody>
            </table>
            {lazyLoading && (
                      <div className="lazy-loading-container d-flex p-2 justify-content-center align-items-center w-100">
                        <LazyLoadSpinner
                          text={`Loading ${limit} More Logs...`}
                        />
                      </div>
                    )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  return (
    <div className={"main-content-container-oncolapse-mode"}>
      <ModalRb
        dialogClassName={"automation-filter-modal"}
        Modalheading={"Campaigns"}
        show={isVisible}
        ModalMainBody={mainModalBodyToShow}
        onHide={() => {
          setIsVisible(false)
        }}
      />
      <div className="campaign-list-container overflow-hidden">
        <Stack>
          <div className="d-flex justify-content-between">
            <SectionTitle sectionTitleHeading={"Automation Logs"} />
            {allLoaders?.reportLoader ? (
        <>
          <button
            disabled={allLoaders?.reportLoader ? true : false}
            className={
              "primary-inactive-btn apply-filter d-flex align-items-center"
            }
          >
            <div className="d-flex gap-2 align-items-center">
              <img
                src={loader}
                className="cursor-pointer-global filterIcon"
                alt="overview-settings-tile"
              ></img>
              <div>Downloading</div>
            </div>
          </button>
        </>
      ) : (
        <>
          <button
            className={
              "primary-inactive-btn apply-filter d-flex align-items-center"
            }
            disabled={isGlobal}
            onClick={handleDownloadReport}
            title={isGlobal ? "(Only Granular View Supported)" :"Max 5000 Records will be downloaded "}
          >
            <div className="d-flex gap-2 align-items-center">
              {" "}
              <img
                src={downloadXls}
                className="cursor-pointer-global filterIcon"
                alt="overview-settings-tile"
              ></img>{" "}
              <div>Download</div>
            </div>
          </button>
        </>
      )}
          </div>
          <div className="d-flex gap-3 align-items-center">
            <div style={{width:'50%'}}>
              <small className="text-muted" style={{fontSize:'12px'}}>Search For Rule Name</small>
                <SearchBarForCampaignListOnly placeholder={'Enter Rule Name & Hit Enter'} onKeyUp={onSearchBarHandler} isCancelIconNotVisible={true}
 />
            </div>
            <div style={{width:'100%'}} className="d-flex gap-3 align-items-center">
            <CustomDropDown
                          title={"View Type"}
                          data={[
                            "Global",
                            "Granular"
                          ]}
                          targettingValue={viewType}
                          filterCampaigns={(type, value) =>
                         { setAllLoaders({...allLoaders, mainLoader: true})
                          setViewType(type.target.value)
                        setMainRawData([])}
                          }
                          type={"field_name"}
                        />
              <MultiSelect externalDivClasses={'w-100'} classes={'automation-filters-dropdown'} title={'Campaigns'} isSearch={true} placeHolder={'Search For Campaigns...'} onApply={ (e) => {
                handleFiltersChange('campaigns',e)
              }} options={allCampaigns ?? []} defaultSelected={filters?.campaigns}/>
              <MultiSelect externalDivClasses={'w-100'} classes={'automation-filters-dropdown'} title={'Adset'} isSearch={true} placeHolder={'Search For Adsets...'}  options={allAdsets} onApply={(e) => {
                handleFiltersChange('adsets',e)
              }} defaultSelected={filters?.adsets}/>
              <MultiSelect externalDivClasses={'w-100'}    classes={'automation-filters-dropdown'} onApply={ (e) => {
                handleFiltersChange('status',e)
              }} title={'Status'} options={["true","false"]} defaultSelected={filters?.status}/>
              </div>
          </div>
          <div className="automation-logs-table-wrapper">
            <Stack className="automation-logs-main-table">
              <TableRb table={tableBody} tableHeads={dataHeadsForAutomationLogs} shimmer={allLoaders?.mainLoader} noDataFound={
                  !allLoaders?.mainLoader && !initialLoad && !automationLogs?.length
                } />
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
};
export default AutomationsLogs;