export default function removeDuplicateObjectsFromArray (array,keyBased=true,keyName=false) {
    const seen = new Set();
    return array.filter(item => {
      const keyValue = keyBased ? item[keyName] : JSON.stringify(item);
      if (seen.has(keyValue)) {
        return false;
      } else {
        seen.add(keyValue);
        return true;
      }
    });
}