import "../../pages/campaignList/campaignList.css";
import { Stack } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { useGlobalContext } from "../../context/global";
import dropdownIcon from "../../assets/icons/downArrowForSplitButton.svg";

const CustomDropDown = (props) => {
  const{autoWidth} = props;
  const { customClasses } = useState(props.classes ?? '');
  const closeRef = useRef(null);
  const { dropdownBtnOptionsVisibilty, setDropdownBtnOptionsVisibilty } = useGlobalContext();

  const [isOpen, setIsOpen] = useState(false);
  const closeOutSide = (e) => {
    if (isOpen && closeRef.current && !closeRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", closeOutSide);
    return () => {
      document.addEventListener("mousedown", closeOutSide);
    };
  }, [isOpen]);

  //  Close All drop down whenever click out side end

  // Whenever Click Drop Down second time then close drop down
  const handelClick = (e) => {
    // Stop Bubbling on parent element when being called from a child element
    e.stopPropagation();
    setDropdownBtnOptionsVisibilty(false);
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div ref={closeRef}>
      <Stack  className={"cursor-pointer-global"}>
        {!props.noTitle && <label className="filter-options" htmlFor="campaign-type">
          {props.title}
        </label>}
        <div onClick={handelClick} id={props?.id ? props.id : ""}> 
          <div className={`select-button-container select-div ${props.classes ? ` ${props.classes}` : ''}`}
          style={{width:autoWidth? 'auto':''}}
          >
            <button
              id="campaign-type"
              className="selected-opt-show-btn"
              value={props.targettingValue === ""?"All":props.targettingValue}
              // onClick={(e) => {
              //   props.filterCampaigns(e, props.type);
              // }}
            >
              { (props.targettingValue === ""?"All":props.targettingValue)}
            </button>
            <div className="img-container"
            // style={{backgroundColor: props.btnBgColor,borderRadius: props.borderRadius}}
             style={{backgroundColor: '#fff',borderRadius: props.borderRadius}} // MS-255 extra line in dropdown
            >
              <img
                className="dropdown-img-for-select"      
                // onClick={handelClick}
                src={props.src ?? dropdownIcon}
                alt={"dropdown"}
              ></img>
            </div>
            {isOpen && (
            <div className={isOpen ? "select-option-container "  + props.classes : "hide"}
            style={{zIndex:props.menuZindex}}
            >
              {!props.checkPortfolio && !props.portfolios
                ? props?.data?.map((season, index) => {
                 return (
                  <>
                   { !props?.noSubhead ?
                    <button
                      key={index}
                      className="select-opt"
                      value={season}
                      onClick={(e) => {
                        props.filterCampaigns(e, props.type);
                        setIsOpen((prevState) => !prevState);
                        e.stopPropagation()

                      }}
                      style={{fontSize:"12px"}}
                    >
                      {!props.comingSoon ? season : <> <span style={{fontSize:"12px"}}> {season} - <span className="text-danger">(Coming Soon!)</span> </span> </>} 
                      {/* {season} */}
                    </button>
                    :<>
                      <h6 className="p-1">{season?.subheading}</h6>
                      <hr className="m-0"/>
                      {
                        season?.subHeadData?.map((subHead,subIndex) => {
                          return  <button
                          key={subIndex}
                          className="select-opt"
                          value={subHead}
                          onClick={(e) => {
                            props.filterCampaigns(e, props.type,season?.subheading);
                            setIsOpen((prevState) => !prevState);
                            e.stopPropagation()
    
                          }}
                          style={{fontSize:"12px"}}
                        >
                          {!props.comingSoon ? subHead : <> <span style={{fontSize:"12px"}}> {subHead} - <span className="text-danger">(Coming Soon!)</span> </span> </>} 
                          {/* {season} */}
                        </button>
                        })
                      }
                    </>}
                  </>
                 ) 
              }) 
                : props.portfolios?.map((portfolio, i) => {
                    return i === 0 ? (
                      <button
                        key={portfolio.id + i}
                        className="select-opt"
                        value={props.checkPortfolio ? "All" : props.portfolios ?  "All Portfolios": null }
                        onClick={(e) => {
                          setIsOpen((prevState) => !prevState);
                          props.setChoosePortfolioForWithoutLabelBtn(props.checkPortfolio ? "All" : props.portfolios ?  "All Portfolios": null)
                          props.filterCampaigns(e, props.type);
                          e.stopPropagation()
                        }}
                      >
                        {props.checkPortfolio ? "All" : props.portfolios ?  "All Portfolios": null }
                      </button>
                    ) :
                    
                    
                    (
                      <button
                        key={portfolio.id + i}
                        className="select-opt"
                        value={portfolio.id}
                        onClick={(e) => {
                          props.filterCampaigns(e, props.type);
                          props.setChoosePortfolioForWithoutLabelBtn(portfolio.name)
                          setIsOpen((prevState) => !prevState);
                          e.stopPropagation()
                        }}
                      >
                        {portfolio.name}
                      </button>
                    );
                  })}
            </div>
          )}
          </div>
        
        </div>
      </Stack>
    </div>
  );
};

export default CustomDropDown;
