import "./PerformanceOverview.css";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";
import CalenderComponent from "../../components/calender/Calender";
import closeIcon from "../../assets/icons/close_icon.png";
import { useGlobalContext } from "../../context/global";
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.svg";
import Chart from "../../components/Reports/Chart";
import cubejs from "@cubejs-client/core";
import useToken from "../../helper/Reports/useToken";
import ReportFilters from "../../components/Reports/Filters";
import SectionTitle from "../../components/sectionTitle";
import Loader from "../../components/loader";
import RadioInputGroup from "../../components/radioInputGroup/radioInputGroup";
import useInitialLoad from '../../hooks/useInitialLoad'
import Breadcrumb from '../../components/Breadcrumb/ReportsBreadCrumb';
import Canvas from '../../helper/Screenshot/canvas'
import downloadIcon from "../../assets/svgs/download.svg";
import defaultDateRangesWithLables from "../../constant/calendarDefaultConfigurations";
import { useSearchParams } from "react-router-dom";
import { createQueryURL,dataQueryParser,getSearchParamsObject,objectoFiltering,getSpecificDataFormat } from "../../components/Reports/Components/utils";

const PerformanceOverview = (props) => {
  const [token, refresh] = useToken("");
  const [initialLoad,setInitialLoad] = useInitialLoad()
  const previousParams = new URLSearchParams(window.location.search)
  const previousGroupType = previousParams.get('performanceGroupType') ? previousParams.get('performanceGroupType') : "Daily"

  const { cubeToken, setCubeToken } = useGlobalContext();
  const { dateDifference } = useGlobalContext();
  const [searchParams,setSearchParams] = useSearchParams({});
  const formatDate = (date) => {
    const offset = date?.getTimezoneOffset();
    date = new Date(date?.getTime() - offset * 60 * 1000);
    return date?.toISOString()?.split("T")[0]?.toString();
  };
  const { calendarDate, setCalendarDate,setOldCalendarData, setDateDifference,setOldDateDifference,applyCalendar } = useGlobalContext();
  const { clientAccountId } = useGlobalContext();
  const cubejsApi = cubejs(cubeToken, {
    apiUrl: process.env.REACT_APP_CUBE_JS_URL,
  });

  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );

  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );

  // Checking the previous Group type and order , dimensions according to that.
  const initialCubeQueryOrder = previousGroupType === "Daily" ? { "ClientDailyCampaignAmazonTable.obDate": "desc" } : previousGroupType === "Weekly" ?[
    ["ClientDailyCampaignAmazonTable.obDateWeek", "desc"],
    ["ClientDailyCampaignAmazonTable.obDate", "desc"],
  ] :{
    "ClientDailyCampaignAmazonTable.obDateMonth": "desc",
  }

  const initialCubeQueryDimensions = previousGroupType === "Daily" ?["ClientDailyCampaignAmazonTable.obDate"] : previousGroupType === "Weekly" ? ["ClientDailyCampaignAmazonTable.obDateWeek"] : [
    "ClientDailyCampaignAmazonTable.obDateMonth",
  ]

  const initialFilterKey = previousGroupType === "Daily" ? "obDate" : previousGroupType === "Weekly" ? "obDateWeek" :"obDateMonth"

  const [campaignType, setCampaignType] = useState([]);
  const [campaignFormat, setCampaignFormat] = useState([]);
  const [targetingType, setTargetingType] = useState([]);
  const [productSet, setProductSet] = useState([]);
  const [campaignStatus, setCampaignStatus] = useState([]);
  const [portfolioName, setPortfolioName] = useState([]);
  const [filterKey, setfilterKey] = useState(initialFilterKey);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [cubeQueryMeasure, setCubeQueryMeasure] = useState([
    "ClientDailyCampaignAmazonTable.cost",
    "ClientDailyCampaignAmazonTable.impressions",
    "ClientDailyCampaignAmazonTable.clicks",
    "ClientDailyCampaignAmazonTable.ctr",
    "ClientDailyCampaignAmazonTable.cpc",
    "ClientDailyCampaignAmazonTable.cpm",
    "ClientDailyCampaignAmazonTable.conversions14d",
    "ClientDailyCampaignAmazonTable.conversion_rate",
    "ClientDailyCampaignAmazonTable.sales14d",
    "ClientDailyCampaignAmazonTable.average_order_value",
    "ClientDailyCampaignAmazonTable.acos",
    "ClientDailyCampaignAmazonTable.roas",
  ]);

  const [cubeQueryOrder, setCubeQueryOrder] = useState(initialCubeQueryOrder);

  const [cubeQueryDimensions, setCubeQueryDimensions] = useState(initialCubeQueryDimensions);
  const allParams = new URLSearchParams(window.location.search);
  const paramsObject = getSearchParamsObject(allParams);
  const previousFiltering = objectoFiltering(paramsObject,"ClientDailyCampaignAmazonTable")
  const viewType = allParams.get('viewtype') ?? 'Performance View';
  const [cubeQueryTimeDimensions, setCubeQueryTimeDimensions] = useState([
    {
      dimension: "ClientDailyCampaignAmazonTable.obDate",
      dateRange: [
        formatDate(calendarDate[0].startDate),
        formatDate(calendarDate[0].endDate),
      ],
    },
  ]);
  const initialCubeQueryFilters = [
    {
      member: "ClientDailyCampaignAmazonTable.profileId",
      operator: "equals",
      values: [clientAccountId],
    },
    ...previousFiltering
  ]
  const [cubeQueryFilters, setCubeQueryFilters] = useState(initialCubeQueryFilters);
  const manualRef = useRef(null)

  const [resultSet, setResultSet] = useState({});
  const enabledfilters = [
    "Campaign Type",
    "Campaign Format",
    "Targeting Type",
    "Product Set",
    "Campaign Status",
    "Portfolio Name",
  ];
  useEffect(() => {
    if(formatDate(calendarDate[0].startDate) === cubeQueryTimeDimensions[0]["dateRange"][0] && formatDate(calendarDate[0].endDate) === cubeQueryTimeDimensions[0]["dateRange"][1] ) {

    } else {
      setCubeQueryTimeDimensions((prev) => {
        let data = [...prev];
        data[0]["dateRange"] = [formatDate(calendarDate[0].startDate),formatDate(calendarDate[0].endDate)];
        return data;
      });
    }
    setIsLoaderVisible(true)
  }, [applyCalendar]);

  useEffect(() => {
    //table datan
    if (cubeToken) {
      cubejsApi
        .load(
          {
            measures: cubeQueryMeasure,
            order: cubeQueryOrder,
            dimensions: cubeQueryDimensions,
            filters: cubeQueryFilters,
            timeDimensions: cubeQueryTimeDimensions,
            timezone: "Asia/Kolkata",
            limit: 500,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((resultSet) => {
          setResultSet(resultSet);
          setIsLoaderVisible(false)
          setInitialLoad(false)
        }).catch((error) => {
          if(error.message === 'Invalid token') {
              setCubeToken(false)
              localStorage.setItem("cubeToken", null);
              refresh()
          }
        });
    }
  }, [cubeQueryFilters,cubeQueryTimeDimensions,cubeToken]);

  const [showCustomDateBox, setShowCustomDateBox] = useState(false);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [overviewType, setOverviewType] = useState(previousGroupType);
  

  const calenderIconRefForCampaignlist = useRef(null);

  const closeCalender = (e) => {
    if (
      calenderIconRefForCampaignlist.current &&
      showCustomDateBox &&
      !calenderIconRefForCampaignlist.current.contains(e.target)
    ) {
      setShowCustomDateBox(false);
    }
  };

  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeCalender);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeCalender);
    };
  }, [showCustomDateBox]);



  function filterCampaigns(e, type) {
    setIsLoaderVisible(true)
    const parsedQueryFilters = dataQueryParser(JSON.parse(JSON.stringify(cubeQueryFilters)),e,type,"ClientDailyCampaignAmazonTable");
    const parsedParams = createQueryURL(parsedQueryFilters);
    const searchParams = new URLSearchParams(window.location.search)
    if(searchParams.get('metricViewType')) {
      parsedParams['metricViewType'] = searchParams.get('metricViewType')
    }
    if(searchParams.get("performanceGroupType")) {
      parsedParams['performanceGroupType'] = searchParams.get('performanceGroupType')
    }
    parsedParams['viewtype'] = viewType
    setCubeQueryFilters((prev) => {
      const parsedData = dataQueryParser(prev,e,type,"ClientDailyCampaignAmazonTable")
      return parsedData
    });
    setSearchParams(parsedParams)
    if (type === "campaignType") {
      setCampaignType(e);
    } else if (type === "campaignFormat") {
      setCampaignFormat(e);
    } else if (type === "targetingType") {
      setTargetingType(e);
    } else if (type === "productSet") {
      setProductSet(e);
    } else if (type === "campaignStatus") {
      setCampaignStatus(e);
    } else if (type === "portfolioName") {
      setPortfolioName(e);
    }
  }

  const getDate = (type) => {
    const yesterday = new Date(new Date());
    yesterday.setDate(yesterday?.getDate());
    // } // Commenting out as using persistent state (dates) // Not removing later if we want to keep it we'll restore it.
    // If it is first render then take the calendar dates incase of duplicate and normal cases too from the calendar dates and if the group type changes then pickup from the current date.
    let currentDate = manualRef.current!==null ?  (type === "Daily" ? yesterday : new Date()) : calendarDate[0].endDate
    let previousDate = manualRef.current!==null ?  new Date() : calendarDate[0].startDate;
    if(manualRef.current!==null) {
      if (type === "Daily") {
        previousDate.setDate(previousDate.getDate() - 6);
      } else if (type === "Weekly") {
        previousDate.setMonth(previousDate.getMonth() - 2);
      } else if (type === "Monthly") {
        previousDate.setMonth(previousDate.getMonth() - 5);
      }
    }
    manualRef.current = "Not Null"; // Just changing the current Ref.
    setDate(currentDate, previousDate);
  };


  
  const areDatesOnSameDay = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };
  const getSelectedPresetLabel = (range) => {
    for (let i = 0; i < defaultDateRangesWithLables.length; i++) {
      const staticRange = defaultDateRangesWithLables[i].range();
      if (
        // range.startDate.toString() === staticRange.startDate.toString() && range.endDate.toString() === staticRange.endDate.toString()
        areDatesOnSameDay(range.startDate,staticRange.startDate) && areDatesOnSameDay(range.endDate,staticRange.endDate)
      ) {
        return defaultDateRangesWithLables[i].label;
      }
    }

    const formattedStartDate = getSpecificDataFormat(range.startDate)
    const formattedEndDate = getSpecificDataFormat(range.endDate)
    // return "Custom";
    return formattedStartDate !== formattedEndDate ? formattedStartDate +' - '+formattedEndDate : formattedStartDate;
  };

  const setDate = (currentDate, previousDate) => {
    const dates = {
      endDate: currentDate,
      startDate: previousDate,
      key: "selection",
    };
    const dateLabel = getSelectedPresetLabel(dates);
    setDateDifference(dateLabel);
    setOldDateDifference(dateLabel)
    setCalendarDate([dates]);
    setOldCalendarData([dates])
    setCubeQueryTimeDimensions((prev) => {
      let data = [...prev];
      data[0]["dateRange"] = [
        formatDate(dates?.startDate),
        formatDate(dates?.endDate),
      ];
      return data;
    });
  };

  const filterOverview = (e, type) => {
    getDate(e.target.value);
    setIsLoaderVisible(true)
    if (e.target.value === "Daily") {
      setfilterKey("obDate");
      setCubeQueryDimensions(["ClientDailyCampaignAmazonTable.obDate"]);
      setCubeQueryOrder({ "ClientDailyCampaignAmazonTable.obDate": "desc" });
    } else if (e.target.value === "Weekly") {
      setfilterKey("obDateWeek");
      setCubeQueryOrder([
        ["ClientDailyCampaignAmazonTable.obDateWeek", "desc"],
        ["ClientDailyCampaignAmazonTable.obDate", "desc"],
      ]);
      setCubeQueryDimensions(["ClientDailyCampaignAmazonTable.obDateWeek"]);
    } else if (e.target.value === "Monthly") {
      setfilterKey("obDateMonth");
      setCubeQueryOrder({
        "ClientDailyCampaignAmazonTable.obDateMonth": "desc",
      });
      setCubeQueryDimensions([
        "ClientDailyCampaignAmazonTable.obDateMonth",
      ]);
    }
    setOverviewType(e.target.value);
  };

  useEffect(() => {
    getDate(overviewType);
    setIsLoaderVisible(true)
    if (overviewType === "Daily") {
      setfilterKey("obDate");
      setCubeQueryDimensions(["ClientDailyCampaignAmazonTable.obDate"]);
      setCubeQueryOrder({ "ClientDailyCampaignAmazonTable.obDate": "desc" });
    } else if (overviewType === "Weekly") {
      setfilterKey("obDateWeek");
      setCubeQueryOrder([
        ["ClientDailyCampaignAmazonTable.obDateWeek", "desc"],
        ["ClientDailyCampaignAmazonTable.obDate", "desc"],
      ]);
      setCubeQueryDimensions(["ClientDailyCampaignAmazonTable.obDateWeek"]);
    } else if (overviewType === "Monthly") {
      setfilterKey("obDateMonth");
      setCubeQueryOrder({
        "ClientDailyCampaignAmazonTable.obDateMonth": "desc",
      });
      setCubeQueryDimensions([
        "ClientDailyCampaignAmazonTable.obDateMonth",
      ]);
    }
  }, [overviewType]);


    useEffect(() => {
      // Adding the groupBy Paramater also in the URL
      const queryParams = new URLSearchParams(window.location.search)
      if (queryParams.get('performanceGroupType')) {
        // 'viewtype' parameter exists, update its value to 'newType'
        queryParams.set('performanceGroupType', overviewType);
      } else {
        // 'viewtype' parameter doesn't exist, add it with a default value of 'defaultValue'
        queryParams.set('performanceGroupType', overviewType);
      }    
      // Update the browser URL
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.replaceState(null, null, newUrl);  
    },[overviewType])

  useEffect(() => {
    setIsFilterVisible(filterModalInCampaignListVisibilty);
  }, [filterModalInCampaignListVisibilty]);

  const redirectChange = (date, type, index) => {
    let url = "/detailed-category-report";
    var startDate = "";
    var endDate = "";
    if (type === "Monthly") {
      const [year, month, monthName] = date.split(" ");
      const monthIndex = new Date(
        Date.parse(monthName + " 1, 2012")
      ).getMonth();
      // Create Date objects for first and last days of month
      const firstDay = new Date(year, monthIndex, 1);
      const lastDay = new Date(year, monthIndex + 1, 0);
      startDate = firstDay.toLocaleDateString("en-CA");
      endDate = lastDay.toLocaleDateString("en-CA");
    } else if (type === "Weekly") {
      startDate = date.split(" to ")[0];
      endDate = date.split(" to ")[1];
    } else if (type === "Daily") {
      startDate = endDate = new Date(date).toLocaleDateString("en-CA");
    }
    const queryParams = [
      ["start", startDate],
      ["end", endDate],
      ["campaignType", campaignType],
      ["campaignFormat", campaignFormat],
      ["targetingType", targetingType],
      ["productSet", productSet],
      ["campaignStatus", campaignStatus],
      ["portfolioName", portfolioName],
    ];
    const queryString = queryParams
      .filter(([key, value]) => value.length > 0)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    url += `?${queryString}`;
    // Open URL in new tab
    window.open(url);
  };
  var spendsRevenueResultSet = formatPieChartData(
    resultSet,
    "Spends_Revenue",
    "cost",
    "sales14d"
  );
  var ctrConversionResultSet = formatPieChartData(
    resultSet,
    "CTR_Conversion Rate",
    "ctr",
    "conversion_rate"
  );

  function formatPieChartData(result, type, measure1, measure2) {
    if (Object.keys(result).length > 0) {
      let cubeResult = JSON.parse(JSON.stringify(result));
      let returnResultLabel = [];
      let resultObj = {};
      let returnResultSeries1 = [];
      let returnResultSeries2 = [];
      let data = cubeResult?.loadResponse?.results[0]?.data;
      data.map((value, index) => {
        if (value["ClientDailyCampaignAmazonTable." + filterKey] == null) {
          returnResultLabel.push("NULL");
        } else {
          returnResultLabel.push(
            value["ClientDailyCampaignAmazonTable." + filterKey]
          );
        }
        returnResultSeries1.push(
          value["ClientDailyCampaignAmazonTable." + measure1]
        );
        returnResultSeries2.push(
          value["ClientDailyCampaignAmazonTable." + measure2]
        );
      });
      resultObj[measure1] = returnResultSeries1;
      resultObj[measure2] = returnResultSeries2;
      return { labels: returnResultLabel, series: resultObj, label: type };
    } else {
    }
  } 
  function dataFilter(data) {
    setIsLoaderVisible(true)
    const table = "ClientDailyCampaignAmazonTable.";
    let tempFilters = [...cubeQueryFilters];
    Object.entries(data).forEach(([key, value]) => {
      tempFilters.forEach((x, i) => {
        if (x.member === `${table}${key}`) {
          tempFilters.splice(i, 1);
        }
      });
      if (value.min) {
        tempFilters.push({
          member: `${table}${key}`,
          operator: 'gte',
          values: [parseFloat(value.min)]
        });
      }
      if (value.max) {
        tempFilters.push({
          member: `${table}${key}`,
          operator: 'lte',
          values: [parseFloat(value.max)]
        });
      }
    })
    setCubeQueryFilters(tempFilters);
  }
  const handleDownload = () => {
    Canvas({ name: 'performance-overview' });
  };

  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
      <Loader visible={initialLoad}></Loader>
      <div
        className="topDropdownWrapper"
        // className="d-flex justify-content-end align-item-end"
      >
        {/* <Stack gap={4}>
          <CustomDropDown
            // title={"Campaign Status"}
            filterCampaigns={filterOverview}
            targettingValue={overviewType}
            onDropdownImghandler={onDropdownImghandler}
            src={dropdownIcon}
            btnBgColor=""
            borderRadius="0px 3px 3px 0px"
            menuZindex={101}
            type={"objective"}
            autoWidth={false}
            classes="reporting-select"
            data={["Daily", "Weekly", "Monthly"]}
          />
        </Stack> */}
        <button
          className="date-container date-btn-campaignlist d-none"
          onClick={dateInputClickHandler}
        >
          <div className="d-flex justify-conntent-center">
            <span className="date-btn-text">{dateDifference}</span>
          </div>
          <button className="btn-calender-img-holder">
            <span className="">
              <img
                className="dropdown-img-for-select"
                src={selectSideIcon}
                alt={"SelectSideIcon"}
              ></img>

              <img
                className="calender-icon-for-campaignlist"
                src={CalenderIconForCampaignList}
                alt={"CalendarIcon"}
              ></img>
            </span>
          </button>
        </button>
      </div>

      <div
        ref={calenderIconRefForCampaignlist}
        className={
          showCustomDateBox ? "calender-inclasslist-container" : "hide"
        }
      >
        <CalenderComponent setShowCustomDateBox={setShowCustomDateBox}></CalenderComponent>
        <span className="calender-close-icon-campaignlist">
          <img
            onClick={() => setShowCustomDateBox(false)}
            src={closeIcon}
            alt={"CloseIcon"}
          ></img>
        </span>
      </div>
      <Breadcrumb />
      <div className="d-flex justify-content-between">
        <SectionTitle sectionTitleHeading='Account Overview'></SectionTitle>
        <div  className="ml-auto">
                <RadioInputGroup
                  state={overviewType}
                  setState={setOverviewType}
                  name="timeType"
                  radios={["Daily", "Weekly", "Monthly"]}
                  required
                ></RadioInputGroup>
        </div>
      </div>

      <div className="d-flex justify-content-between mt-4 mb-4 gap-4 position-relative" id="downloadChart" >
      <div onClick={handleDownload} className="downloadChartWrapper" id="downloadChartWrapper">
            <img src={downloadIcon} className="cursor-pointer-global DownloadIcon" alt="overview-settings-tile" />
      </div>
        <div className="w-100 min-w-200 min-h-300 position-relative pie-container mx-auto p-4 mt-4">
          <Chart
            type="line"
            resultSet={spendsRevenueResultSet}
            classes={"marketing-reports"}
            display={overviewType}
          />
        </div>
        <div className="w-100 min-w-200 min-h-300 position-relative pie-container mx-auto p-4 mt-4">
          <Chart
            type="line"
            resultSet={ctrConversionResultSet}
            classes={"marketing-reports"}
            display={overviewType}
          />
        </div>
      </div>
      <p className="titleText"> Performance Metrices</p>

      {/* filters  */}
      <ReportFilters
        handleDropdown={filterCampaigns}
        enabledfilters={enabledfilters}
      />

      <div className="">
          <Chart
            type="table"
            resultSet={resultSet}
            classes={"marketing-reports"}
            percentageShow={false}
            handleChange={redirectChange}
            display={overviewType}
            page="performance-overview"
            dataFilter={dataFilter}
            shimmerEffect={isLoaderVisible}
            CurrentViewType={viewType}
          />
      </div>
    </div>
  );
};

export default PerformanceOverview;
