import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TableRb from "../../../Tables/tableRb";
import SearchbarForEditCampaign from "../../../searchbarForEditCampaign";
import AddProductButton from "../../../../assets/svgs/plus_box.svg";
import csvIcon from '../../../../assets/icons/csv-upload.svg'
import { useGlobalContext } from "../../../../context/global";
import { useAuthHeader } from "react-auth-kit";
import Spinner from "../../../spinners/spinner";
import { TOAST_DANGER, TOAST_POSITION_TOP_RIGHT } from "../../../../constant/HardTypes";
import Papa from 'papaparse';
import {toast} from 'react-toastify';
const AsinsTable = (prop) => {
  const [isVisible, setIsVisible] = useState(false);
  const [asinsLoadingState, setAsinsLoadingState] = useState({
    status: false,
    isSpinnerRequired: false,
  });
  const SPProductTargetingProductData = useSelector(
    (state) => state.CampaignCreationData.SPProductTargetingProductData
  );
  const {
    spAsins,
    setSpAsins,
    spWholeCategories,
    setSpWholeCategories,
    sdAsins,
    setSdAsins,
    sdWholeCategories,
    setSdWholeCategories,
    sdBrandWholeCategories,
    setsdBrandWholeCategories,
    sdBrandAsins,
    setSdBrandAsins,
    spBrandAsins,
    setSpBrandAsins,
    spBrandWholeCategories,
    setspBrandWholeCategories,
    adGroupData,
    setAdGroupData,
    setAdSetData,
    adSetData,
    setManuallyAddedAsins,
    manuallyAddedAsins,
    selectedCategory,
    editAsins,
    setEditAsins,
    editWholeCategories,
    setEditWholeCategories,
    showAlert,
    spSelectedSuggestedCategories,
    dispatchErrors,
    sdSelectedSuggestedCategories,
    sdBrandSelectedSuggestedCategories,
    spBrandSelectedSuggestedCategories
  } = useGlobalContext();
  const [asinsValue, setAsinsValue] = useState("");
  const authHeader = useAuthHeader();
  const token = authHeader();
  const {amz_default_bid} = adGroupData
 
  const clientId = JSON.parse(localStorage.getItem("clientId"));
  const default_bid = JSON.parse(localStorage.getItem("defaultBid"));

  const getBidRecommendation = async (dataPassed) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(dataPassed),
    };
    try {
      setAsinsLoadingState({status:true,isSpinnerRequired:true})
      const response = await fetch(
        `https://adyogiadsapi.adyogi.com/client/${clientId}:fetch-bid-recommendation?source=amazon`,
        options
      );
      const result = await response.json();
      if(response?.status ===200 && result?.data?.length>0) {
        setAsinsLoadingState({status:false,isSpinnerRequired:false})
        return result?.data;
      } else {
        setAsinsLoadingState({status:false,isSpinnerRequired:false})
        return false
      }
    } catch (error) {
      setAsinsLoadingState({status:false,isSpinnerRequired:false})
      console.log(error);
    }
  };

  useEffect(() => {
    setSdAsins([]);
    setSdWholeCategories([]);
    setSdBrandAsins([]);
    setsdBrandWholeCategories([]);
    setSpAsins([]);
    setSpBrandAsins([]);
    setSpWholeCategories([]);
    setspBrandWholeCategories([])
    setEditAsins([]);
    setManuallyAddedAsins([]);
    setEditWholeCategories([]);
    setAsinsValue("");
  },[])

  //This Effect is to clear targeting errors.
  useEffect(() => {
    const isTargetingPresent = spAsins?.length || spBrandAsins?.length || sdBrandAsins?.length || sdAsins?.length  || spSelectedSuggestedCategories?.length ||   sdSelectedSuggestedCategories?.length || spBrandSelectedSuggestedCategories?.length || sdBrandSelectedSuggestedCategories?.length

    if(isTargetingPresent) {
      dispatchErrors({action:"setError",fieldName:"targeting",value:false})
    }
  },[spAsins,spBrandAsins,sdBrandAsins,sdAsins,spSelectedSuggestedCategories,sdSelectedSuggestedCategories, sdBrandSelectedSuggestedCategories , spBrandSelectedSuggestedCategories])

  const handleAsins = async (word) => {
    const removeWhiteSpaces = word.replace(/\s/g, "");

    if (removeWhiteSpaces !== "") {
      const arrayOfWords = removeWhiteSpaces.split(",");

      const targeting_clauses_array = arrayOfWords.map((asin) => {
        return {
          expressions: [
            {
              type: "asinSameAs",
              value: `${asin}`,
            },
          ],
        };
      });

      if (prop.ManuallyAsins) {
        const splittedKeywords = removeWhiteSpaces.split(",");
        const modiefiedAsins = splittedKeywords.map((asin) => {
          return { name: "", id: asin, image_url: "", status: "ENABLED" };
        });
        // Checking if there are any duplicates in the existing array
        let duplicatesArray = modiefiedAsins.filter(function (item) {
          for (var i = 0, len = manuallyAddedAsins.length; i < len; i++) {
            if (
              manuallyAddedAsins[i].id.toLowerCase() === item.id.toLowerCase()
            ) {
              return false;
            }
          }
          return true;
        });
        let arrayToBeSet;

        if (prop.updateProducts) {
          // If the call is For Update Products Set
          setManuallyAddedAsins((current) => {
            if (current.length > 0) {
              arrayToBeSet = current.concat(duplicatesArray);
              return current.concat(duplicatesArray);
            } else {
              arrayToBeSet = duplicatesArray;
              return duplicatesArray;
            }
          });
          setAsinsValue("");
        } else {
          // If the call is for creating new creatives.
          setManuallyAddedAsins((current) => {
            if (current.length > 0) {
              arrayToBeSet = current.concat(duplicatesArray);
              return current.concat(duplicatesArray);
            } else {
              arrayToBeSet = duplicatesArray;
              return duplicatesArray;
            }
          });
          setAdSetData({ ...adSetData, products: arrayToBeSet });
          setAsinsValue("");
        }
      }

      const dataToBePassed = {
        type: "SD_CATEGORY",
        categories: selectedCategory,
        targeting_clauses: targeting_clauses_array,
      };

      const dataToBePassedForUpdate = {
        type: "SD_CATEGORY",
        adset_id: prop.amz_category ,
        targeting_clauses: targeting_clauses_array,
      };

      const bidRecommendations = await getBidRecommendation(
        prop.update ? dataToBePassedForUpdate : dataToBePassed
      );
      if(bidRecommendations === false) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: "Not able to fetch suggested categories",
        // });
        toast.error('Unknown error occurred while fetching suggested categories')
      }

      const arrayOfAsins = bidRecommendations?.map((bid) => {
        const { recommended_suggested_bid } = bid;
        if (
          prop.SPProduct ||
          prop.SPBrandProduct ||
          prop.campaignType === "SP-Product"
        ) {
          return {
            status: "ENABLED",
            expression: [
              {
                type: "product",
                value: bid?.targeting_clauses?.expressions[0]?.value,
              },
            ],
            bid:amz_default_bid ?? default_bid
          };
        } else {
          return {
            status: "ENABLED",
            expression: [
              {
                type: "product",
                value: bid?.targeting_clauses?.expressions[0]?.value,
              },
            ],
            bid: recommended_suggested_bid ?? default_bid,
          };
        }
      });

      if (prop.SDProduct) {
        setSdAsins([...sdAsins, ...arrayOfAsins]);
        setSdWholeCategories([...sdWholeCategories, ...arrayOfAsins]);
        setAdGroupData((current) => {
          const copy = { ...current };
          if ("amz_product_targeting" in copy) {
            copy["amz_product_targeting"] = [
              ...sdWholeCategories,
              ...arrayOfAsins,
            ];
          } else {
            copy["amz_product_targeting"] = [
              ...sdWholeCategories,
              ...arrayOfAsins
            ];
          }
          return copy;
        });
        setAsinsValue("");
      }

      if (prop.update) {
        setEditAsins([...editAsins, ...arrayOfAsins]);
        setEditWholeCategories([...editWholeCategories, ...arrayOfAsins]);
        setAsinsValue("");
      }

      if (prop.SPProduct) {
        setSpAsins([...spAsins, ...arrayOfAsins]);
        setSpWholeCategories([...spWholeCategories, ...arrayOfAsins]);
        setAdGroupData((current) => {
          const copy = { ...current };
          if ("amz_product_targeting" in copy) {
            copy["amz_product_targeting"] = [
              ...spWholeCategories,
              ...arrayOfAsins,
            ];
          } else {
            copy["amz_product_targeting"] = [
              ...spWholeCategories,
              ...arrayOfAsins
            ];
          }
          return copy;
        });
        setAsinsValue("");
      }

      if (prop.SDBrandProduct) {
        setSdBrandAsins([...sdBrandAsins, ...arrayOfAsins]);
        setsdBrandWholeCategories([...sdBrandWholeCategories, ...arrayOfAsins]);
        setAdGroupData((current) => {
          const copy = { ...current };
          if ("amz_product_targeting" in copy) {
            copy["amz_product_targeting"] = [
              ...sdBrandWholeCategories,
              ...arrayOfAsins,
            ];
          } else {
            copy["amz_product_targeting"] = [
              ...sdBrandWholeCategories,
              ...arrayOfAsins
            ];
          }
          return copy;
        });
        setAsinsValue("");
      }

      if (prop.SPBrandProduct) {
        setSpBrandAsins([...spBrandAsins, ...arrayOfAsins]);
        setspBrandWholeCategories([...spBrandWholeCategories, ...arrayOfAsins]);
        setAdGroupData((current) => {
          const copy = { ...current };
          if ("amz_product_targeting" in copy) {
            copy["amz_product_targeting"] = [
              ...spBrandWholeCategories,
              ...arrayOfAsins,
            ];
          } else {
            copy["amz_product_targeting"] = [
              ...spBrandWholeCategories,
              ...arrayOfAsins
            ];
          }
          return copy;
        });
        setAsinsValue("");
      }
    } else {
      alert("Please Enter Something");
    }
  };

  const openUploader = () => {
    const input = document.querySelector("#asin-upload-input");
    input.value = null
    input.click()
  }

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        let asinValues = results.data.map((data, index) => {
          return Object.values(data)[0]
        });
        setAsinsValue(asinValues.toString())
      },
    });
  }

  let tableBody = (
    <>
      <div className="generic-main-table-body-container asian-table-div">
        <table className="asian-table">
          <thead className="main-table-head">
            <tr className="table-heading-style-asins">
              <td colspan={3} className="table-upper">
                <div className="float-left right-icon p-1 search-product">
                <span
              className={"right-icon"}
              onClick={openUploader}
            >
               <input
                      type="file"
                      name="file"
                      accept=".csv"
                      style={{ display: "none" }}
                      onInput={changeHandler}
                      id={"asin-upload-input"}
                    />
              <img alt="upload-keywords" className="cursor-pointer-global" src={csvIcon} height={20} width={20}/>
            </span>
            {/* <div className="float-right" style={{ marginRight: "20px" }}> */}
                  <button
                    type="button"
                    // onClick={handelClick}
                    className={
                      asinsValue.length > 0 && !asinsLoadingState.status ? "add-all-btn-dark" : "add-all-btn"
                    }
                    disabled={asinsLoadingState.status}
                    onClick={() => handleAsins(asinsValue)}
                  >
                    {
                      (asinsLoadingState.status && asinsLoadingState.isSpinnerRequired) ? (
                        <>
                        <Spinner shape={"border"} size={"sm"}/>
                        Adding...
                        </>
                      ): ("Add All")
                    }
                  </button>
                {/* </div> */}
                </div>
               
              </td>
            </tr>
            <tr></tr>
          </thead>

          <div className="p-2">
            <textarea
              name=""
              id="manual-search"
              cols="30"
              value={asinsValue}
              onChange={(e) => setAsinsValue(e.target.value.replace(/\s/g, ""))}
              className="w-100 textarea-add-targeting create-campaign-outline"
              placeholder="Enter Asin's and seperate each Asin with comma,"
              rows="20"
              style={{ paddingLeft: "20px", paddingTop: "20px" }}
            ></textarea>
          </div>
        </table>
      </div>
    </>
  );

  return (
    <div className="sp-product-targettig-outer-container">
      <div className="asian-body1">
        <TableRb table={tableBody}></TableRb>
      </div>
    </div>
  );
};


export default AsinsTable;
