import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import "@fontsource/source-sans-pro/400.css"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Defaults to weight 400
// import "@fontsource/poppins/400.css";
// import "@fontsource/montserrat/500.css"; // Specify weight
// import "@fontsource/noto-sans/400.css"; // Specify weight
// import "@fontsource/lato/400.css"; // Specify weight
// import "@fontsource/noto-sans/400.css"; // Specify weight
// import "@fontsource/ibm-plex-mono/400.css"; // Specify weight
// import "@fontsource/rosario/400.css"; // Specify weight
// import "@fontsource/hind-siliguri/400.css"; // Specify weight

import 'react-toastify/dist/ReactToastify.css';
import Header from "./components/header/header";
// import Layout from "./components/layout";
import CampaignList from "./pages/campaignList/campaignList";
import KeywordTargeting from "./components/modal/campaignCreation/SpKeywordTargeting/SpKeywordTargeting";
import "./components/layout.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
// import Loader from "./components/loader";
// import AddProductModal from "./components/modal/campaignListModals/addProduct/addProductModal";
// import SpAutomatic from "./pages/SP_Automatic/SpAutomatic";
// import Sp_AdDetails from "./pages/SP_Automatic/Sp_AdDetails";
// import AuthNav from "./pages/Authentication/AuthNav";
import LogIn from "./pages/Authentication/LogIn";
import SignUp from "./pages/Authentication/SignUp";
// import Otp from "./pages/Authentication/Otp";
// import SPTargeting from "./pages/SP_Targeting/SPTargeting";
import PortfoliCreationModal from "./components/modal/campaignCreation/PortfoliCreationModal";
import { useGlobalContext } from "./context/global";
// import LoginDetails from "./pages/Authentication/LoginDetails";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HomeDashboard from "./pages/homeDashboard";
import Sidebar from "./components/sidebar";
import EditCampaignMainPage from "./pages/campaignList/editCampaignMainPage";
// import Alert from "./components/Alert/Alert.jsx";
// import AddTargetingModal from "./components/modal/campaignListModals/addTargeting/addTargetingModal";
import Toast from "./components/ToastAlert/Toast";
import Reports from "./components/Reports/Reports";
import DraftSectionCampaignListSection from "./pages/campaignList/draftSectionCampaignListSection";
import { RequireAuth } from "react-auth-kit";
import NotFound from "./pages/Notfound/NotFound";
import MasterAdsMain from "./pages/campaignList/masterAdsList/MasterAdsMain";
// import UnderDevelopment from "./components/UnderDevelopment/UnderDevelopment";
import MasterTargetingList from "./pages/campaignList/masterTargetinngList/MastertargetinngList";
import PortfolioReport from "./pages/portfolioReport/portfolioReport";
import PlacementFormatReport from "./pages/placementFormatReport/PlacementFormatReport";
import TargetingGroupReport from "./pages/targetingGroupReport/TargetingGroupReport";
import CampaignFormatReport from "./pages/campaignFormatReport/CampaignFormatReport";
import CampaignReport from "./pages/CampaignReport/CampaignReport";
import AdGroupReport from "./pages/adGroupReport/AdGroupReport";
import TargetingReport from "./pages/targetingReport/TargetingReport";
import MatchTypeReport from "./pages/matchTypeReport/MatchTypeReport";
import SearchTermsReport from "./pages/seachTermsReport/SearchTermsReport";
import BroadCategoryReport from "./pages/broadCategoryReport/BroadCategoryReport";
import DetailedCategoryReport from "./pages/detailedCategoryReport/DetailedCategoryReport";
import ProductReport from "./pages/productReport/ProductReport";
import ReportsDashboard from "./pages/reportsDashboard/ReportsDashboard";
import TargetingGroupComparison from "./pages/comparisonView/targetingGroupComparison/TargetingGroupComparison";
import PortfolioComparison from "./pages/comparisonView/portfolioComparison/PortfolioComparison";
import CampaignComparison from "./pages/comparisonView/campaignComparison/CampaignComparison";
import AdGroupComparison from "./pages/comparisonView/adGroupComparison/AdGroupComparison";
import TargetingComparison from "./pages/comparisonView/targetingComparison/TargetingComparison";
import CategoryComparison from "./pages/comparisonView/categoryComparison/CategoryComparison";
import ProductComparison from "./pages/comparisonView/productComparison/ProductComparison";
import PerformanceOverview from "./pages/performanceOverview/PerformanceOverview";
import AnalyticsReports from "./pages/analyticsSubmenu/AnalyticsReports";
import MarketingStream from "./pages/MarketingStream/marketing_stream";
import ProductSetsList from "./pages/productSets/ProductSetsList";
import CreateProductSet from "./pages/productSets/CreateProductSet";
import OptimiseBid from "./pages/bidOptimiser/OptimiseBid";
import Uploader from "./components/Upload/Uploader";
import MasterSearchTerm from "./pages/campaignList/masterSearchTermList/MasterSearchTerm";
import DiscountAnalysis from "./pages/discountAnalysis/DiscountAnalysis";
import PriceHistory from "./pages/priceHistory/PriceHistory";
import React from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { ToastContainer } from "react-toastify";
import FallbackMain from './components/fallback/fallbackMain';
import AutomationsTemplate from "./pages/Automations/AutomationsTemplate";
import CreateAutomation from "./pages/Automations/createAutomations/CreateAutomation";
import AutomationsList from "./pages/Automations/AutomationsList/AutomationsList";
import AutomationsLogs from './pages/Automations/AutomationsLogs'
import WhileLabel from './constant/whiteLabel.json';
import AdjustBid from "./pages/AdjustBid/AdjustBid";
import Optimizer from "./pages/Optimizer/Optimizer";
import HistoryLogs from "./pages/historyLogs/HistoryLogs";
import UnderMaintenancePage from './maintainence';
import QuadrantView from "./pages/QuadrantView/QuadrantView";



export default function App({ props }) {
  const { show, setShow, alertDetails, alert, reportData,modalFor,clientId,config, setConfig,allClientsData,selectedPlatform } = useGlobalContext();
  if(WhileLabel[window.location.hostname]) {
    setConfig(WhileLabel[window.location.hostname])
  } else {
    setConfig(WhileLabel["default"])
  }
  const isFlipkartorMyntraClient = selectedPlatform!=='amazon'
  useEffect(()=>{
    if (config) {
      document.title = config.title;
      const faviconLink = document.createElement('link');
      faviconLink.rel = 'icon';
      faviconLink.type = 'image/png';
      faviconLink.href = "/logo/"+config.logo;
      document.head.appendChild(faviconLink);

      // Create and append the <meta> for description
      const descriptionMeta = document.createElement('meta');
      descriptionMeta.name = 'description';
      descriptionMeta.content = config.description;
      document.head.appendChild(descriptionMeta);

      // Create and append the <link> for apple-touch-icon
      const appleTouchIconLink = document.createElement('link');
      appleTouchIconLink.rel = 'apple-touch-icon';
      appleTouchIconLink.href = "/logo/"+config.logo;
      document.head.appendChild(appleTouchIconLink);
    }
  },[config])
  const [sidebarState, setSidebarState] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const matchedURL = location.pathname.match(/^(.*[^\/])\/?$/)
  const locationForHeaderAndSideBar = matchedURL ? matchedURL[1] : location?.pathname

  // Special Routes
  const routesWithNoHeaders = ["/login", "/client-details"];
  const routesWithNoSideBars = ["/login", "/client-details"];
  const routesWithNoLoginReq = ["/login", "/create-portfolio", "*"];

  //Route-Component-mapper
  const routeComponentMapper = {
    "/": <div className={isFlipkartorMyntraClient ? 'disable-pointer-events':''}><HomeDashboard key={clientId}/></div>,
    "/campaign-list": <div><CampaignList /></div>,
    "/create-campaign": <div><KeywordTargeting /></div>,
    "/login": <div><LogIn /></div>,
    // "/client-details": <div><LoginDetails /></div>,
    "/create-portfolio": <div className="layout-container"><PortfoliCreationModal show={show} setShow={setShow} /></div>,
    "/edit-adgroup": <div><KeywordTargeting /></div>,
    "/edit-adset-targeting": <div><EditCampaignMainPage /></div>,
    "/reports": <div><Reports reportData={reportData} /></div>,
    "/marketing-stream": <div><MarketingStream /></div>,
    // "/targeting": <div><UnderDevelopment /></div>,
    "/targeting": <div><MasterTargetingList /></div>,
    "/campaign-list/drafts": <div><DraftSectionCampaignListSection /></div>,
    "/portfolio-report": <div><PortfolioReport /></div>,
    "/placement-format-report": <div> <PlacementFormatReport /></div>,
    "/targeting-group-report": <div><TargetingGroupReport /></div>,
    "/campaign-format-report": <div><CampaignFormatReport /></div>,
    "/campaign-report": <div><CampaignReport /></div>,
    "/ad-group-report": <div><AdGroupReport /></div>,
    "/targeting-report": <div><TargetingReport /></div>,
    "/match-type-report": <div><MatchTypeReport /></div>,
    "/search-terms-report": <div><SearchTermsReport /></div>,
    "/broad-category-report":<div><BroadCategoryReport /></div>,
    "/detailed-category-report":<div><DetailedCategoryReport /></div>,
    "/product-analytics":<div><ProductReport /></div>,
    "/reports-dashboard":<div><ReportsDashboard /></div>,
    "/portfolio-comparison-report":<div><PortfolioComparison /></div>,
    "/targeting-group-comparison-report":<div><TargetingGroupComparison /></div>,
    "/master-ads":<div><MasterAdsMain/></div>,
    "/campaign-comparison":<div><CampaignComparison /></div>,
    "/adgroup-comparison":<div><AdGroupComparison /></div>,
    "/targeting-comparison":<div><TargetingComparison /></div>,
    "/category-comparison":<div><CategoryComparison /></div>,
    "/product-comparison":<div><ProductComparison /></div>,
    "/account-overview":<div><PerformanceOverview /></div>,
    "/product-set-list":<div><ProductSetsList /></div>,
    "/create-product-set":<div><CreateProductSet /></div>,
    "/catalog-analytics":<div><AnalyticsReports type={'catalog'}/></div>,
    "/campaign-analytics":<div><AnalyticsReports type={'campaign'}/></div>,
    '/setup':<Uploader/>,
    "/search-term":<div><MasterSearchTerm/></div>,
    "/bid-optimiser":<div><OptimiseBid/></div>,
    "/discount-analysis":<div><DiscountAnalysis/></div>,
    "/price-history":<div><PriceHistory/></div>,
    "/automations/choose-template":<div><AutomationsTemplate/></div>,
    "/automations/create-rule":<div><CreateAutomation key={location.pathname}/></div>,
    "/automations/update-rule":<div><CreateAutomation key={location.pathname}/></div>,
    "/automations":<div><AutomationsList/></div>,
    "/bid-adjust":<div><AdjustBid/></div>,
    "/automations/logs":<div><AutomationsLogs/></div>,
    "/optimizer/:reportType":<div><Optimizer/></div>,
    "/history-logs":<div><HistoryLogs/></div>,
    "/quadrant-view":<div><QuadrantView/></div>

  }

  const hasHeader = () => {
    const path = location.pathname;
    const hasMatchingRoute = Object.keys(routeComponentMapper).includes(path) ||
      Object.keys(routeComponentMapper).some((pathUrl) => path.startsWith(pathUrl));
    return hasMatchingRoute && !routesWithNoHeaders.includes(path);
  };
  
  const hasSidebar = () => {
    const path = location.pathname;
    const hasMatchingRoute = Object.keys(routeComponentMapper).includes(path) ||
      Object.keys(routeComponentMapper).some((pathUrl) => path.startsWith(pathUrl));
    return hasMatchingRoute && !routesWithNoSideBars.includes(path);
  };
  
  const header = hasHeader() ? (
    <div className="sidebar-container">
      <Header></Header>
    </div>
  ) : null;
  
  const sideBar = hasSidebar() ? (
    <div className="sidebar-container">
      <Sidebar></Sidebar>
    </div>
  ) : null;

  // const header =
  //   !routesWithNoHeaders.includes(location.pathname) &&
  //     Object.keys(routeComponentMapper).includes(location.pathname) ? (
  //     <div>
  //       <Header></Header>
  //     </div>
  //   ) : null;
  // const sideBar =
  //   !routesWithNoSideBars.includes(location.pathname) &&
  //     Object.keys(routeComponentMapper).includes(location.pathname) ? (
  //     <div className={"sidebar-container"}>
  //       <Sidebar></Sidebar>
  //     </div>
  //   ) : null;

  const routes = [];
  Object.keys(routeComponentMapper).map((pathUrl) => {
    let component = routesWithNoLoginReq.includes(pathUrl) ? (
      routeComponentMapper[pathUrl]
    ) : (
      <RequireAuth loginPath="/login">
        {routeComponentMapper[pathUrl]}
      </RequireAuth>
    );
    routes.push(<Route path={pathUrl} element={component} />);
  });

  //Fallback route
  routes.push(<Route path="*" element={<NotFound />} />);

  // routes.push(
  //   <Route
  //     path="/edit-adset-targeting"
  //     element={
  //       <RequireAuth loginPath="/login">
  //         {<EditCampaignMainPage />}
  //       </RequireAuth>
  //     }
  //   />  
  // );  
  return (
    <>
    <Provider config={{ ...JSON.parse(process.env.REACT_APP_ROLLBAR_CONFIG), checkIgnore: function (isUncaught, args, payload) { return payload?.request?.url?.includes('localhost') ?? false; } }}>
      <ErrorBoundary fallbackUI={({ error, resetError }) => <FallbackMain />} extra={(error, info) => info.componentStack.includes('Experimental') ? { experiment: true } : {} }>
      {header}
      {sideBar}
      <ToastContainer position="top-right"
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      className={'toast-custom-className'}
      theme="colored">
     </ToastContainer>
      <div className="layout-container">
        {
          show &&   <PortfoliCreationModal ModalFor={modalFor} show={show} setShow={setShow} />

        }
        {alert && <Toast />}
      </div>
      <Routes>{routes}</Routes>
      </ErrorBoundary>
    </Provider>
    {/* <UnderMaintenancePage /> */}
    </>
  );
}

// const isAuthenticated = () => {
//   const token = localStorage.getItem("logintoken");
//   if (token !== null) {
//     return true;
//   } else {
//     return false;
//   }
// };
// const ProtectedRoute = ({ children }) => {
//   if (!isAuthenticated()) {
//     return <Navigate to={"/login"} />;
//   } else {
//     return children;
//   }
// };
{
  /* <Route
path="/"
element={
  <RequireAuth loginPath="/login">
    <div>
      <HomeDashboard />
    </div>
  </RequireAuth>
}
/>
<Route
path="/campaign-list"
element={
  <RequireAuth loginPath="/login">
    <div>
      <CampaignList />
    </div>
  </RequireAuth>
}
/>
<Route
path="/create-campaign"
element={
  <RequireAuth loginPath="/login">
    <div>
      <KeywordTargeting />
    </div>
  </RequireAuth>
}
/>
<Route path="/login" element={<LogIn />} />
<Route
path="/client-details"
element={
  <RequireAuth loginPath="/login">
    <div>
      <LoginDetails />
    </div>
  </RequireAuth>
}
/>
<Route
path="/create-portfolio"
element={
  <div className="layout-container">
    <PortfoliCreationModal show={show} setShow={setShow} />
  </div>
}
/>
<Route
path="/edit-adgroup"
element={
  <RequireAuth loginPath="/login">
    <div>
      <KeywordTargeting />
    </div>
  </RequireAuth>
}
/>
<Route
path="/edit-adset-targeting"
element={
  <RequireAuth loginPath="/login">
    <div>
      <EditCampaignMainPage />
    </div>
  </RequireAuth>
}
/>
<Route
path="/reports"
element={
  <RequireAuth loginPath="/login">
    <div>
      <Reports reportData={reportData} />
    </div>
  </RequireAuth>
}
/>
<Route
path="/targeting"
element={
  <RequireAuth loginPath="/login">
    <div>
      <MasterTargetingkList />
    </div>
  </RequireAuth>
}
/>
<Route
path="campaign-list/drafts"
element={
  <RequireAuth loginPath="/login">
    <div>
      <DraftSectionCampaignListSection />
    </div>
  </RequireAuth>
}
/>
<Route path="*" element={<NotFound />} /> */
}
