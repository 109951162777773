import React from "react";
import searchicon from '../../assets/images/searchicon.png'

const PageStatus = ({ type, msg }) => {
    const status = <>   <span
    className="d-flex justify-content-center"
    style={{ marginTop: 30 }}
  >
    <img src={searchicon} alt="search-icon" />
  </span>
  <span className="d-block" style={{marginTop: 20 }}>
    <strong>
      {msg}
    </strong>
  </span> </>
  return (
    <div>
        {status}
    </div>
  );
};

export default PageStatus;