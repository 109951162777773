import React, { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import SectionTitle from "../../components/sectionTitle";
import { Stack } from "react-bootstrap";
import Loader from "../../components/loader";
import { masterApi } from "../../apis/masterApi";
import TableRb from "../../components/Tables/tableRb";
import { useEffect, useMemo } from "react";
import LazyLoadSpinner from "../../components/spinners/LazyLoadSpinner";
import moment from "moment/moment";
import './historyLogs.css';
import { Tooltip as ReactToolTip } from "react-tooltip";
import { IoInformationCircleOutline } from "react-icons/io5";



const HistoryLogs = () => {
  // Redux Imports
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  // States
  const [rawNotificationsData, setRawNotificationsData] = useState([]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [adgroups, setAdgroups] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [pagination, setPagination] = useState(0);
  const [lazyLoading,setLazyLoading] = useState(false);
  const tableRef = useRef(null);
  // Variables
  const tableHeaders = [
    { fieldName: "Change Type", id: "entity_change" },
    { fieldName: "Campaign", id: "campaign_id" },
    { fieldName: "Ad Group", id: "adset_id" },
    { fieldName: "Email", id: "email" },
    { fieldName: "Old Value", id: "from_value" },
    { fieldName: "New Value", id: "to_value" },
    { fieldName: "Date & Time", id: "created_at" },
    { fieldName: "Status",id:"message" }, 
  ];
  // Data we will show on table
  const notificationsDataToShow = useMemo(
    () => modifyData(rawNotificationsData),
    [rawNotificationsData, campaigns, adgroups]
  );

 function timeFormatter (dateString) {
    const date = moment(dateString);
    return date.format('DD MMMM hh:mm A');
}
  // Effects
  useEffect(() => {
    getNotifications();
    getCampaigns();
    getAdgroups();
  }, []);
  // Methods
  const getNotifications = async () => {
    const data = { source: "amazon", limit: 50, page: 0 };
    setIsLoaderVisible(true);
    try {
      const result = await masterApi(data, "historyLogs", false);
      if (result?.data) {
        const filteredData = result?.data?.filter((data) => {
          return !data?.entity_change?.includes('product')
        })
        setRawNotificationsData(filteredData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCampaigns = async () => {
    const data = { source: "amazon",limit:5000,fields:"name,id" };
    try {
      setIsLoaderVisible(true);
      const result = await masterApi(data, "campaignList", false);
      if (result?.data) {
        setCampaigns(result?.data?.data);
      }
      setIsLoaderVisible(false)
    } catch (error) {
      console.log(error);
      setIsLoaderVisible(false);
    }
  };
  const getAdgroups = async () => {
    const data = { source: "amazon",limit:15000,fields:"name,id" };
    try {
      setIsLoaderVisible(true);
      const result = await masterApi(data, "adsetList", false);
      if (result?.data) {
        setAdgroups(result?.data?.data);
      }
      setIsLoaderVisible(false)
    } catch (error) {
      console.log(error);
      setIsLoaderVisible(false);
    }
  };
  function modifyData(notificationsData) {
    const returnedData = [...notificationsData].map((data) => {
      let modifiedCampaign = campaigns?.find(
        (campaign) => campaign?.id === data?.campaign_id
      );
      let modifiedAdgroup = adgroups?.find(
        (adgroup) => adgroup?.id === data?.adset_id
      );
      return {
        ...data,
        campaign_id: modifiedCampaign?.name ?? "-",
        adset_id: modifiedAdgroup?.name ?? "-",
      };
    });
    return returnedData?.length > 0 ? returnedData : [];
  }
  async function loadMoreData (page) {
    const data = { source: "amazon", limit: 50, page: page };
    try {
      const result = await masterApi(data, "historyLogs", false);
      if (result?.data) {
        const filteredData = result?.data?.filter((data) => {
          return !data?.entity_change?.includes('product')
        })
        return filteredData
      }
    } catch (error) {
      console.log(error);
    }
  }
 async function handleScroll () {
  const container = tableRef.current;
    let  currentPage = pagination
    // Keep track of last scroll position
    const lastScrollTop = container.getAttribute("data-scroll-top") || 0;
    // Determine scroll direction
    const scrollDirection = container.scrollTop > lastScrollTop ? "down" : "up";
    // Update last scroll position
    container.setAttribute("data-scroll-top", container.scrollTop);
    if (
      scrollDirection === "down" &&
      Math.ceil(container.scrollTop + container.clientHeight) >=
        container.scrollHeight - 50 && !lazyLoading
    ) {
      currentPage = currentPage+1
      if (!lazyLoading) {
        setLazyLoading(true)
        const moreData = await loadMoreData(currentPage);
        setLazyLoading(false)
        setRawNotificationsData([...rawNotificationsData, ...moreData]);
        setPagination((prev) => prev + 1)
      }
    }
 }
  // TableBody
  let tableBody = (
    <>
      <div className="main-campaignlist-table-body-container history-logs" ref={tableRef}
        onScroll={handleScroll}>
        {rawNotificationsData?.length ? (
          <>
          
          <table className="history-logs-table">
            <thead className="fixed-table">
              <tr className="main-table-row-fixed">
                {tableHeaders?.map((th, mainIndex) => {
                  return (
                    <>
                      <th className="campaig-list-head text-nowrap">
                        <span className="text-capitalize">{th.fieldName}</span>
                      </th>
                    </>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {notificationsDataToShow?.length ? (
                notificationsDataToShow?.map((data, index) => {
                  data = JSON.parse(JSON.stringify(data));
                  const isError = (data?.hasOwnProperty('error_message') && data?.error_message!==null && data?.error_message!=="")
                  return (
                    <>
                      <tr>
                        {tableHeaders?.map((header, index) => {
                          return (
                            <td className={`${header?.id !== "message" ? 'text-nowrap' : ''} ${index===0 ? 'history-logs-first-td' : index===1 ? 'history-logs-second-td' : ''}`}>
                              <span>
                                {header?.id === "created_at"
                                  ? timeFormatter(data[header?.id])
                                  : header?.id === 'message' ? <>
                                  {
                                    isError ? (<>
                                    {
                                      data['message']
                                    }
                                    <ReactToolTip id={[data['error_message']+index]} style={{zIndex:90}}/>
                                    <span data-tooltip-id={[data['error_message']+index]} data-tooltip-content={data['error_message']} className="cursor-pointer mx-1"><IoInformationCircleOutline  color="red" size={15}/></span>
                                    </> ): data['message']
                                  }
                                  </> : (header?.id === "from_value" || header?.id === "to_value") ? <>
                                  <span title={data[header?.id]?.slice(70,data[header?.id]?.length)}>{  
                                   data[header?.id]?.slice(0,70)
                                  }</span>   
                                  </> : data[header?.id]}
                              </span>
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  );
                })
              ) : (
                <>No data Found</>
              )}
            </tbody>
          </table>
          {lazyLoading && (
              <div className="lazy-loading-container d-flex p-5 justify-content-center align-items-center w-100">
                <LazyLoadSpinner text={"Loading 50 More Logs..."} />
              </div>
            )}
          </>
        ) : (
          <div className="ndf-text-container">
            <p>NO DATA FOUND</p>
          </div>
        )}
      </div>
    </>
  );
  return (
    <div>
      <div
        className={
          dashboardSideBarVisibiltyState
            ? "main-content-container-onopen-mode"
            : "main-content-container-oncolapse-mode"
        }
        id="main-box"
      >
        <div className="campaign-list-container">
          <Loader visible={isLoaderVisible}></Loader>
          <Stack>
            <div className="section-heading cl-bottom-margin">
              <Stack
                direction="horizontal"
                gap={4}
                className="d-flex align-item-center"
              >
                <SectionTitle sectionTitleHeading="History Logs"></SectionTitle>
              </Stack>
            </div>
            <div className="campaign-list-table-data">
              <Stack className="campaign-list-main-table">
                <TableRb noDataFound={!isLoaderVisible && !notificationsDataToShow?.length} table={tableBody} tableHeads={tableHeaders} shimmer={isLoaderVisible}></TableRb>
              </Stack>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
};
export default HistoryLogs;
