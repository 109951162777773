import { onRowExpandHandler } from "./rowExpandHandler";
import { masterApi } from "../../../apis/masterApi";
import { getChildRowsData } from "./getChildRowsData";

/**
 * @Note This Method will trigger the behaviour of the Of the Parent CheckBoxes.
 * @param {*} sentIndex
 * @param {*} currentState
 * @param {*} currentFiltering
 * @param {*} mainIndex
 * @returns New Updated State with Conditions
 */

export const parentRowCheckBoxHandler = async (
  currentState,
  asin,
  childRowFiltering
) => {
  let updatedState;
  // Check If Child Rows Already there
  const exists = checkChildRowExists(currentState, asin);
  if (exists) {
    updatedState = checkUncheckAllBoxes(currentState, asin);
    return updatedState;
  } else {
    const childRowsData = await getChildRowsData(childRowFiltering);
    if(childRowsData?.error) {
      return childRowsData
    } else {
      const updatedChildRows = childRowsData?.map((ad) => {
          ad.isTargetChecked = false;
        return ad;
      });
      updatedState = currentState.map((ad) => {
        if (ad?.asin === asin) {
          ad.childRows = updatedChildRows;
        }
        return ad;
      });
      updatedState = checkUncheckAllBoxes(updatedState, asin);
      return updatedState;
    }
   
  }
};

function checkChildRowExists(currentState, asin) {
  const existsOrNot =
    currentState.filter((ad) => ad.asin === asin)[0]?.childRows?.length > 0;
  return existsOrNot;
}

function checkUncheckAllBoxes(currentState, asin) {
  const updatedState = currentState.map((ad) => {
    if (ad.asin === asin) {
      if (ad.isPlusIconDisabled === true) {
        ad.isPlusIconDisabled = false;
      }
      ad.isTargetChecked = !ad.isTargetChecked;
      ad.childRows = ad.childRows?.map((childAd) => {
        if(childAd?.id) {
          childAd.isTargetChecked = ad.isTargetChecked;
        }
        return childAd;
      });
    }
    return ad;
  });
  return updatedState;
}
