import React from "react";
import Button from "react-bootstrap/Button";
import "./secondaryBtnRb.css";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FaInfoCircle } from 'react-icons/fa';

const SecondaryBtnRb = (props) => {
  const btnText = props.secondaryBtnText.toLowerCase();
  return (
    <>
      {props?.infoRequired ? (
        <ReactTooltip
          id={props.tooltipId}
          style={{ zIndex: "999999", fontSize: "12px", padding: "8px" }}
          effect="solid"
          place="top"
          variant="dark"
        />
      ) : null}
      <div
        className={
          btnText === "reset" || btnText === "cancel"
            ? ""
            : "data-toggle-buttons"
        }
      >
        <button
          className={props.className ? props.className : "secondary-btn"}
          disabled={props.disabled}
          title={props.disabled && props.disabledText}
          onClick={props.onClick}
          id={props.id}
        >
          {props.secondaryBtnText}
          {props?.img ? <img src={props?.img}/> : null}
          {props?.infoRequired ? (
            <div
              data-tooltip-id={props?.tooltipId ? props?.tooltipId : ""}
              data-tooltip-html={
                props?.infoRequired ? props.tooltipcontent : ""
              }
            >
              {props?.infoRequired ? <FaInfoCircle height={20} /> : null}
            </div>
          ) : null}
        </button>
      </div>
    </>
  );
}

export default SecondaryBtnRb;
