import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import "./sidebar.css";
import softwarePartner from "../assets/images/Amazon_Software_Partner.png";
import advancedPartner from "../assets/images/Amazon_Advanced_partner.png";

import inactiveCampaignIcon from "../assets/icons/active_campaign_icon.svg";
import activeCampaignIcon from "../assets/icons/campaign_fill_icon.svg";
import activeCreateIcon from "../assets/icons/create-plus-icon.svg";
import activDashobardIcon from "../assets/icons/dashboard_icon.svg";
import inactivDashobardIcon from "../assets/icons/dashboard_line_icon.svg";
import activeAutomationIcon from "../assets/icons/automation_fill_icon.svg";
import inactiveAutomationIcon from "../assets/icons/automation_line_icon.svg";
import activeSetupIcon from "../assets/icons/active_setup_configuration_icon.svg";
import arrowUpIcon from "../assets/icons/arrow_up_icon.svg";
import arrowDownIcon from "../assets/icons/down_arrow.svg";
import { dashboardActions } from "../redux/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import optimizationIcon from "../assets/icons/optimization.svg";
import { useGlobalContext } from "../context/global";

import performanceIcon from "../assets/icons/performance_icon.svg";
import catalogIcon from "../assets/icons/catalog_icon.svg";
import campaignAnalyticsIcon from "../assets/icons/campaign_icon.svg";

import globalAdsIcon from "../assets/icons/global_ads_icon.svg";
import globalSearchIcon from "../assets/icons/global_search_icon.svg";
import globalTargetingIcon from "../assets/icons/global_targeting_icon.svg";
import camaignListIcon from "../assets/icons/campaign_list_icon.svg";
import { PageVersionsAccessSetting } from "../constant/settings/userRouteAccess";
import useUserDetails from "../helper/userData/useUserDetails";
import automationListIcon from '../assets/icons/automationListIcon.svg';
import automationLogsIcon from '../assets/icons/automationsLogsIcon.svg';
import automationRulesIcon from '../assets/icons/createRuleAutomation.svg';
import createCamaignIcon from "../assets/icons/create_campaign_icon.svg";
import createPortfolioIcon from "../assets/icons/create_portfolio_icon.svg";
import { FlipkartMyntraDataKeys, generateIFrameReportUrl } from "../helper/clientData/utils";
import { encodeData } from "../helper/common/encodeData";
import { PPM_CONFIG_CLIENT_WISE } from "../constant/settings/ppmClientsAccess";

function Sidebar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeBtn, setActiveBtn] = useState("dashboard");
  const [sublistBtnActive, setsublistBtnActive] = useState("");
  const [activeList, setActiveList] = useState("")
  const [open, setOpen] = useState(false);
  const location = useLocation();
  
  const {smart_ads} = PageVersionsAccessSetting.beta
  const [userDetails]  = useUserDetails()
  const isMultiuser = userDetails?.user?.multiUser === "yes"
  const {clientId} = useGlobalContext()

  const {
    setShow,
    setModalFor,
    config,
    selectedPlatform
  } = useGlobalContext();

//  const isPPMClient = PPM_CONFIG_CLIENT_WISE[selectedPlatform?.toLowerCase()]?.VISIBLE_CLIENTS?.includes(clientId)
 const isPPMClient = true; //it should be visible for all clients

 const flipkartPPMDetailsObj = 
  {
    id: "ppm",
    text: <span>PPM</span>,
    icon: activeAutomationIcon,
    inactiveIcon: activeAutomationIcon,
    link: "/ppm",
    subItems:[
      {
        // id: `reports?url=${PPM_CONFIG_CLIENT_WISE[selectedPlatform?.toLowerCase()]?.CLIENTS_REPORT_URLS[`${clientId}_FSN`] ?? ''}`,
        id: `reports?url=https://lookerstudio.google.com/embed/u/0/reporting/7d4df993-543b-4fd9-b21c-7149614e2145/page/SGdEE?params=%7B%22ds0.parse_id%22%3A%20%22${clientId}%22%2C%22ds19.parse_id%22%3A%20%22${clientId}%22%7D`,
        icon: createPortfolioIcon,
        name: "FSN",
        text: "",
        link: `reports?url=https://lookerstudio.google.com/embed/u/0/reporting/7d4df993-543b-4fd9-b21c-7149614e2145/page/SGdEE?params=%7B%22ds0.parse_id%22%3A%20%22${clientId}%22%2C%22ds19.parse_id%22%3A%20%22${clientId}%22%7D`
      },
      {
        id: `reports?url=https://lookerstudio.google.com/embed/u/0/reporting/7d4df993-543b-4fd9-b21c-7149614e2145/page/p_z6f2izctld?params=%7B%22ds0.parse_id%22%3A%20%22${clientId}%22%2C%22ds19.parse_id%22%3A%20%22${clientId}%22%7D`,
        icon: createPortfolioIcon,
        name: "SKU",
        text: "",
        link: `reports?url=https://lookerstudio.google.com/embed/u/0/reporting/7d4df993-543b-4fd9-b21c-7149614e2145/page/p_z6f2izctld?params=%7B%22ds0.parse_id%22%3A%20%22${clientId}%22%2C%22ds19.parse_id%22%3A%20%22${clientId}%22%7D`
      },
    ]
  
 }


 const myntraPPMDetailsObj = 
  {
    id: "ppm",
    text: <span>PPM</span>,
    icon: activeAutomationIcon,
    inactiveIcon: activeAutomationIcon,
    link: "/ppm",
    subItems:[
      {
        id: `reports?url=https://lookerstudio.google.com/embed/u/0/reporting/62b8b9f0-cdd1-4f65-89d4-f7176c19477d/page/9liEE?params=%7B%22ds0.parse_id%22%3A%20%22${clientId}%22%7D`,
        icon: createPortfolioIcon,
        name: "Product Analysis",
        text: "",
        link: `reports?url=https://lookerstudio.google.com/embed/u/0/reporting/62b8b9f0-cdd1-4f65-89d4-f7176c19477d/page/9liEE?params=%7B%22ds0.parse_id%22%3A%20%22${clientId}%22%7D`
      },
      {
        id: `reports?url=https://lookerstudio.google.com/embed/u/0/reporting/62b8b9f0-cdd1-4f65-89d4-f7176c19477d/page/p_p47nlretld?params=%7B%22ds0.parse_id%22%3A%20%22${clientId}%22%7D`,
        icon: createPortfolioIcon,
        name: "Category Analsyis",
        text: "",
        link: `reports?url=https://lookerstudio.google.com/embed/u/0/reporting/62b8b9f0-cdd1-4f65-89d4-f7176c19477d/page/p_p47nlretld?params=%7B%22ds0.parse_id%22%3A%20%22${clientId}%22%7D`
      },
    ]
  
 }

 const finalPPMDetailsObj = selectedPlatform?.toLowerCase() === 'flipkart' ? flipkartPPMDetailsObj : myntraPPMDetailsObj

  const amazonBtnsData =  [
    {
      id: "create",
      // text: "Create",
      icon: activeCreateIcon,
      inactiveIcon: activeCreateIcon,
      link: "/create-campaign",
      subItems: [
         {
          id: "create-product-set",
          icon: createPortfolioIcon,
          name: "Create Smart Product Set",
          text: "Create category specific product sets with automation rules based on performance conditionals and product attributes.",
          link: "create-product-set",
        },
      ],
    },
    {
      id: "dashboard",
      text: "Dashboard",
      icon: activDashobardIcon,
      inactiveIcon: inactivDashobardIcon,
      link: "/",
      subItems: [],
    },
    {
      id: "product-performence",
      text: "Product performance",
      icon: activeCampaignIcon,
      inactiveIcon: inactiveCampaignIcon,
      subItems: [
        // { id: "create-product-set",icon:createPortfolioIcon, name:"Create Product Set", text: "Create category specific product sets with automation rules based on performance conditionals and product attributes." },
        {
          id: "product-set-list",
          icon: globalSearchIcon,
          name: "Smart Product Set",
          text: "Get insights and monitor performance metrics for  smart product sets.",
          link:"product-set-list"
        },
        {
          id: "product-analytics",
          icon: catalogIcon,
          name: "Product Analytics",
          text: "Monitor and assess the performance of Individual categories and Products in terms of their visibility, Click through rates, conversions etc. It enables advertisers to identify top-performing categories and products and those that may require improvement.",
          link:"product-analytics"
        },
        {
          id: "master-ads",
          icon: globalAdsIcon,
          name: "Product Ads",
          text: "Seamlessly enable or disable ads individually or in bulk, ensuring optimal campaign performance.",
          link:"master-ads"
        },
        {
          id: "discount-analysis",
          icon: globalSearchIcon,
          name: "Discount Analysis",
          text: "Gain valuable insights into the effectiveness and impact of different discount slabs on revenue generation and units sold on basis of the duration selected.",
          link: "discount-analysis",
        },
        {
          id: "quadrant-view",
          icon: globalSearchIcon,
          name: "Quadrant View",
          text: "Gain valuable insights into the effectiveness and impact of products using quadrant view.",
          link: "quadrant-view",
        },
      ],
    },
    {
      id: "optimizer",
      text: "Optimizer",
      icon: activeAutomationIcon,
      inactiveIcon: inactiveAutomationIcon,
      subItems: [
        {
          id: "campaign-list",
          icon: camaignListIcon,
          name: "Campaigns",
          text: "Optimize campaign budgets, enable or disable campaigns, fine-tune bid strategies and update ad groups to optimize your advertising spend.",
          link: "campaign-list",
        },
        {
          id: "optimizer/targeting",
          icon: createPortfolioIcon,
          name: "Targeting",
          text: "Create category specific product sets with automation rules based on performance conditionals and product attributes.",
          link: "optimizer/targeting",
        },
        // { id: "targeting",icon:globalTargetingIcon, name:"Targeting", text: "Monitor performance metrics, make adjustments to bidding strategies, and easily enable or disable targetings individually or in Bulk." },
        {
          id: "bid-adjust",
          icon: globalSearchIcon,
          name: "Placement Bids",
          text: "Get insights and monitor performance metrics for  smart product sets.",
          link: "bid-adjust",
        },
        {
          id: "search-term",
          icon: globalSearchIcon,
          name: "Search Term",
          text: "Leverage our recommendations for keyword and product targetings to optimize your ad campaigns and drive better results. Take control of your targeting strategy by adding search terms to negative targetings at the campaign or account level.",
          link: "search-term",
        },
      ],
    },
    {
      id: "automations",
      text: "Automations",
      icon: activeSetupIcon,
      inactiveIcon: activeSetupIcon,
      subItems: [
        {
          id: "automations/create-rule",
          icon: automationRulesIcon,
          name: "Configure Rules",
          text: "Optimize campaign budgets, enable or disable campaigns, fine-tune bid strategies and update ad groups to optimize your advertising spend.",
          link:"automations/create-rule?ruleFor=Improve Efficiency"
        },
        {
          id: "automations",
          icon: automationListIcon,
          name: "Automation List",
          text: "Create category specific product sets with automation rules based on performance conditionals and product attributes.",
          link:"automations"
        },
        {
          id: "automations/logs",
          icon: automationLogsIcon,
          name: "Automation Logs",
          text: "Get insights and monitor performance metrics for  smart product sets.",
          link:"automations/logs"
        },
      ],
      // link: "/performance-overview",
    },
    {
      id: "account-overview",
      text: "Analytics",
      icon: optimizationIcon,
      inactiveIcon: optimizationIcon,
      subItems: [  {
        id: "account-overview",
        icon: automationRulesIcon,
        name: "Account overview",
        text: "Optimize campaign budgets, enable or disable campaigns, fine-tune bid strategies and update ad groups to optimize your advertising spend.",
        link: "account-overview",
      },
      // {
      //   id: "marketing-stream",
      //   icon: automationListIcon,
      //   name: "Marketing Stream",
      //   text: "Create category specific product sets with automation rules based on performance conditionals and product attributes.",
      //   link: "marketing-stream",
      // },
    ],
      // link: "/performance-overview",
    },
  ];
  const flipkartData = [
    {
      id: "overview",
      text: "Overview",
      icon: activDashobardIcon,
      inactiveIcon: inactivDashobardIcon,
      link: "/reports",
      subItems: [
         {
          id: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Day',clientId)}`,
          icon: createPortfolioIcon,
          name: "Day",
          text: "",
          link: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Day',clientId)}`,
        },
        {
          id: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Week',clientId)}`,
          icon: createPortfolioIcon,
          name: "Week",
          text: "",
          link: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Week',clientId)}`,
        },
        {
          id: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Month',clientId)}`,
          icon: createPortfolioIcon,
          name: "Month",
          text: "",
          link: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Month',clientId)}`,
        },
      ],
    },
    {
      id: "granular-analysis",
      text: <span>Granular <br/> Analysis </span>,
      icon: optimizationIcon,
      inactiveIcon: optimizationIcon,
      link: "/reports",
      subItems: [
         {
          id: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Campaign',clientId)}`,
          icon: createPortfolioIcon,
          name: "Campaign",
          text: "",
          link: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Campaign',clientId)}`
        },
        {
          id: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Targeting',clientId)}`,
          icon: createPortfolioIcon,
          name: "Targeting",
          text: "",
          link: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Targeting',clientId)}`
        },
        {
          id: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'FSN Report',clientId)}`,
          icon: createPortfolioIcon,
          name: "FSN Report",
          text: "",
          link: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'FSN Report',clientId)}`,
        },
        {
          id: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Search Term',clientId)}`,
          icon: createPortfolioIcon,
          name: "Search Term",
          text: "",
          link: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Search Term',clientId)}`,
        },
        {
          id: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Placement',clientId)}`,
          icon: createPortfolioIcon,
          name: "Placement ",
          text: "",
          link: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Placement',clientId)}`,
        },
      ],
    },
  ]
  const myntraData = [
    {
      id: "overview",
      text: "Overview",
      icon: activDashobardIcon,
      inactiveIcon: inactivDashobardIcon,
      link: "/reports",
      subItems: [
         {
          id: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Day',clientId)}`,
          icon: createPortfolioIcon,
          name: "Day",
          text: "",
          link: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Day',clientId)}`,
        },
        {
          id: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Week',clientId)}`,
          icon: createPortfolioIcon,
          name: "Week",
          text: "",
          link: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Week',clientId)}`,
        },
        {
          id: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Month',clientId)}`,
          icon: createPortfolioIcon,
          name: "Month",
          text: "",
          link: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Month',clientId)}`,
        },
      ],
    },
    {
      id: "granular-analysis",
      text: <span>Granular <br/> Analysis </span>,
      icon: optimizationIcon,
      inactiveIcon: optimizationIcon,
      link: "/reports",
      subItems: [
         {
          id: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Campaign',clientId)}`,
          icon: createPortfolioIcon,
          name: "Campaign",
          text: "",
          link: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Campaign',clientId)}`
        },
        {
          id: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Product',clientId)}`,
          icon: createPortfolioIcon,
          name: "Product",
          text: "",
          link: `reports?url=${generateIFrameReportUrl(selectedPlatform?.toLowerCase(),'Product',clientId)}`
        },
      ],
    },
  ]

  const buttonsData = selectedPlatform?.includes("amazon") ? amazonBtnsData : selectedPlatform === 'flipkart' ? flipkartData : myntraData


  if(isPPMClient && selectedPlatform?.toLowerCase()!=='amazon') { 
    buttonsData.push(finalPPMDetailsObj)
  }

  const allSubMenuItems = buttonsData
  .filter((button) => button.subItems)
  .map((button) => button.subItems)
  .flat()
  .map((item) => item.id);


  const URL_MAPPER = {
    "/automations":"automations",
    "/automations/create-rule":"automations/create-rule",
    "/automations/logs":"automations/logs"
  }


function getSubMenu(url, isAnalytics) {
  if (isAnalytics) {
    if (url.includes("product-analytics")) {
      return "product-analytics";
    } else if (url.includes("campaign-analytics")) {
      return "campaign-analytics";
    } else {
      return "account-overview";
    }
  } else {
    const match = URL_MAPPER[url] ?? allSubMenuItems.find((item) => url.includes(item) );
    return match;
  }
}
function isAnalyticsMenu(url) {
  return url.includes("product-analytics") || url.includes("campaign-analytics");
}

useEffect(() => {
  let isAnalytics = false;
  let activeButton = null;

  for (const button of buttonsData) {
    if (  button.link ===  location.pathname ) {
      activeButton = button.id;
      break;
    } else if (button.subItems) {
      const subButton = button.subItems.find((subItem) =>{
        return selectedPlatform === 'amazon' ? '/'+subItem.id === location.pathname : window.location.href  === subItem.link;
        });
      if (subButton) {
        activeButton = button.id;
        break;
      }
    }
  }

  if (activeButton) {
    setActiveBtn(activeButton);
    isAnalytics = isAnalyticsMenu(location.pathname);
    const overviewData = buttonsData?.find((button) => {
      return button.id === "overview";
    }) ?? {}
    const overviewItem = overviewData.subItems?.find((subItem) => {
      return window.location.href?.includes(subItem.link);
    })?.name ?? ""


    const PPMData = buttonsData?.find((button) => {
      return button.id === "ppm";
    })

    const PPMItem = PPMData?.subItems?.find((subItem) => { 
      return window.location.href?.includes(subItem.link);
    }) ?? ""

   

    let granularAnalysis = location.pathname.includes('reports') && selectedPlatform!=='amazon' && !['Day','Week','Month','PPM','SKU']?.includes(overviewItem) && !PPMItem
    
    let isPPMAnalysis = location.pathname.includes('reports') && selectedPlatform!=='amazon' && !['Day','Week','Month']?.includes(PPMItem) && !overviewItem

    
    if(isPPMAnalysis) {
      setActiveBtn('ppm')
    }

    
    if(granularAnalysis) {
      setActiveBtn('granular-analysis')
    }
    if (isAnalytics) {
      setActiveBtn("analytics");
    }
    if (location.pathname !== "" && location.pathname !== "/") {
      setsublistBtnActive(getSubMenu( selectedPlatform === 'amazon' ?  location.pathname : window.location.href, isAnalytics));
    }
  }
}, [location, sublistBtnActive, open]);

  // Effects
  const closeRef = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (activeList && closeRef.current && !closeRef.current.contains(e.target)) {
        setActiveList(false)
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    document.addEventListener("mouseleave", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
      document.removeEventListener("mouseleave", checkIfClickedOutside);
    };
  }, [activeList]);


  //this will handle onclick of options in sidebar & pass data to parent
  const onButtonClick = (e, type) => {
    if(selectedPlatform === 'amazon') {
      setActiveList(false)
      setActiveBtn(type)
      setsublistBtnActive(type)
      const activeButtonData = buttonsData.find(button => button.id === type);
        if (activeButtonData && activeButtonData.subItems.length === 0) {
          if(activeButtonData?.link) {
            navigate(`${activeButtonData.link}`);
          } 
        }
    } 
   
  };

  const onCreateUpArrowHandler = (id) => {
    setActiveList(id)
  };

  // const onAutomationArrowHandler = () => {
  //   setCampaignDownListActive(false);
  //   setCompViewListActive(false);
  //   setReportListActive(false);
  //   setAnalyticsListActive(false);
  //   setCreateDownListActive(false);
  //   setAutomationListActive(true)
  // }

  const noSubMenuExist = () => {
    setActiveList(false)
  }

  const onAnalyticsUpArrowHandler = (id) => {
    setActiveList(id)
  };

  const submenuHandler = (e, type) => {
    setActiveList(false)
    if (type === 'create-portfolio') {
      e.preventDefault();
      setShow(true);
      setModalFor("create-portfolio")
    } else {
      navigate(`./${type}`);
      setsublistBtnActive(type);
    }
  };

  

  return (
    <div className="d-flex sidebar-section-main-container" ref={closeRef} onMouseLeave={noSubMenuExist}>
      <div className="sildebar-container-oncolapse-mode">
        <Link to={"/"} className={selectedPlatform?.toLowerCase()!=='amazon' ? 'disable-pointer-events':''}>
          <div className="logo-img" onClick={(e) => onButtonClick(e, "dashboard")}>
          {config && <img src={config.name==="Adyogi" ? "/logo/"+config.logo : "/logo/"+config.logo_white} alt="Logo" style={{width: config.name === 'Adyogi' ? '45px' : '80%'}} />}
            
          </div>
        </Link>
        {/* <div className="h-100"> */}
          <div className="d-flex flex-column sidebar-main-container">
            <div className="justify-content-between sidebar-all-btn-main-container gap-2">
            {buttonsData.map((button,index) => (
              <>
              {
                button.link === "/" ? <Link to={'/'}>
                    <div className="d-flex justify-content-center btn-container position-relative align-items-center" onMouseLeave={noSubMenuExist} key={index}>
              <button
                id={button.id}
                className={
                  activeBtn === button.id
                    ? "selected-btn-oncolapse-mode"
                    : "not-selected-btn-oncolapse-mode"
                }
                onClick={(e) => onButtonClick(e, button.id)}
              >
              {button?.subItems?.length > 0 &&
                    <div
                      className={
                        activeList === button.id
                          ? "camp-drow-down-op sub-drow-down-op"
                          : "hide"
                      }
                      id={button?.id + "-link"}
                    >
                      <div className="sub-menu-absulate">
                        
                       {button.subItems.map((item,itemIndex) => (<>
                        <Link to={`/${item.link ?? item.id}`} key={itemIndex}>
                          {/* <div className={sublistBtnActive === item.id ? "sub-bg-white selected" : "sub-bg-white"}
                            id={item.id} onClick={(e) => { submenuHandler(e, item.id) }}>
                            <div className="d-flex align-items-center mb-12">
                              <div className="sub-icon"><img src={item.icon} alt=""></img></div>
                              <div className="sub-main-tit"><u>{item.name}</u></div>
                            </div>
                            <div className="sub-main-desc">
                            {item.text}
                            </div>
                          </div> */}
                          {/* <div> */}
                            <div className={`main-menu-item ${item.id===sublistBtnActive ? "active-sub-link" :""}`}>
                             <span>{item.name}</span> 
                              </div>
                          {/* </div> */}
                        {/* <div className="sub-main-tit"><u>{item.name}</u></div> */}
                        </Link>
                      </>
                      
                      ))} 
                      
                      </div>
                    </div> 
                    }   
                <span className={button.id==='create' ? "create-item" : ""} onMouseEnter={()=>onCreateUpArrowHandler(button.id)} >
                  <img
                    src={
                      open && activeBtn == button.id
                        ? button.icon
                        : button.inactiveIcon
                    }
                    alt={button.id}
                  ></img>
                    {button?.text && <span> {button.text}</span> }
                </span>
                <Collapse
                  in={open}
                  dimension="width"
                  className="btn-text-container"
                >
                  <div>
                    <div
                      className="cam-drop-icon-text-container"
                      id={button.id}
                      onClick={() => onAnalyticsUpArrowHandler(button.id)}
                    >
                      {/* <span
                        className={
                          activeBtn === button.id ||
                            sublistBtnActive === "create-campaign"
                            ? "selected-btn-text active"
                            : "sidebar-btn-btn-text"
                        }
                      >
                        {button?.text}
                      </span>
                      <span
                        className="arrow-icon-down-for-campaign"
                        id="product-performance"
                      >
                        <img
                          className={
                            activeBtn === "analytics"
                              ? "drop-arrow-on-selected"
                              : "drop-arrow-on-not-selected"
                          }
                          alt="icon"
                          src={activeList === button.id ? arrowUpIcon : arrowDownIcon}
                        ></img>
                      </span> */}
                    </div>
                  
                  </div>
                
                </Collapse>
              </button>
            </div>
                </Link> :   
                <div className="d-flex justify-content-center btn-container position-relative align-items-center" onMouseLeave={noSubMenuExist} key={index}>
              <button
                id={button.id}
                className={
                  activeBtn === button.id
                    ? "selected-btn-oncolapse-mode"
                    : "not-selected-btn-oncolapse-mode"
                }
                onClick={(e) => onButtonClick(e, button.id)}
              >
              {button?.subItems?.length > 0 &&
                    <div
                      className={
                        activeList === button.id
                          ? "camp-drow-down-op sub-drow-down-op"
                          : "hide"
                      }
                      id={button?.id + "-link"}
                    >
                      <div className="sub-menu-absulate">
                        
                       {button.subItems.map((item,itemIndex) => (<>
                        <Link to={`/${item.link ?? item.id}`} key={itemIndex}>
                          {/* <div className={sublistBtnActive === item.id ? "sub-bg-white selected" : "sub-bg-white"}
                            id={item.id} onClick={(e) => { submenuHandler(e, item.id) }}>
                            <div className="d-flex align-items-center mb-12">
                              <div className="sub-icon"><img src={item.icon} alt=""></img></div>
                              <div className="sub-main-tit"><u>{item.name}</u></div>
                            </div>
                            <div className="sub-main-desc">
                            {item.text}
                            </div>
                          </div> */}
                          {/* <div> */}
                            <div className={`main-menu-item ${item.id===sublistBtnActive ? "active-sub-link" :""}`}>
                             <span>{item.name}</span> 
                              </div>
                          {/* </div> */}
                        {/* <div className="sub-main-tit"><u>{item.name}</u></div> */}
                        </Link>
                      </>
                      
                      ))} 
                      
                      </div>
                    </div> 
                    }   
                <span className={button.id === 'create' ? "create-item" : ""} onMouseEnter={()=>onCreateUpArrowHandler(button.id)} >
                  <img
                    src={
                      open && activeBtn == button.id
                        ? button.icon
                        : button.inactiveIcon
                    }
                    alt={button.id}
                  ></img>
                    {button?.text && <span> {button.text}</span> }
                </span>
                <Collapse
                  in={open}
                  dimension="width"
                  className="btn-text-container"
                >
                  <div>
                    <div
                      className="cam-drop-icon-text-container"
                      id={button.id}
                      onClick={() => onAnalyticsUpArrowHandler(button.id)}
                    >
                      {/* <span
                        className={
                          activeBtn === button.id ||
                            sublistBtnActive === "create-campaign"
                            ? "selected-btn-text active"
                            : "sidebar-btn-btn-text"
                        }
                      >
                        {button?.text}
                      </span>
                      <span
                        className="arrow-icon-down-for-campaign"
                        id="product-performance"
                      >
                        <img
                          className={
                            activeBtn === "analytics"
                              ? "drop-arrow-on-selected"
                              : "drop-arrow-on-not-selected"
                          }
                          alt="icon"
                          src={activeList === button.id ? arrowUpIcon : arrowDownIcon}
                        ></img>
                      </span> */}
                    </div>
                  
                  </div>
                
                </Collapse>
              </button>
            </div>
              }   
            </>
            ))}
            </div>
            {config && config.name==="Adyogi" && selectedPlatform?.toLowerCase() === "amazon" &&
              <div className="d-flex flex-column gap-2 pb-2 justify-content-center  btn-container align-items-center">
              <div className="">
                <img src={advancedPartner} alt="advancedPartner" style={{width:"60px", height:"50px"}}/> 
                </div>
                <div className="">
                <img src={softwarePartner} alt="softwarePartner" style={{width:"60px", height:"50px"}}/> 
                </div>
              </div>
            }   
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
