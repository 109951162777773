export const leakingConvertingHeads = {
  leaking: [
    {
      field_name: "Spends",
      field_key: "spends",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "ROAS",
      field_key: "roas",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "CPC",
      field_key: "cpc",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "Orders",
      field_key: "orders",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "Revenue",
      field_key: "sale_value",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "Impressions",
      field_key: "impressions",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "Clicks",
      field_key: "clicks",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "CPM",
      field_key: "cpm",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "CTR",
      field_key: "ctr",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "CVR",
      field_key: "cvr",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "AOV",
      field_key: "aov",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "ACOS",
      field_key: "acos",
      minValue: "-",
      maxValue: "-",
    },
  ],
  converting: [
    {
      field_name: "Spends",
      field_key: "spends",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "ROAS",
      field_key: "roas",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "CPC",
      field_key: "cpc",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "Orders",
      field_key: "orders",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "Revenue",
      field_key: "sale_value",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "Impressions",
      field_key: "impressions",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "Clicks",
      field_key: "clicks",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "CPM",
      field_key: "cpm",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "CTR",
      field_key: "ctr",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "CVR",
      field_key: "cvr",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "AOV",
      field_key: "aov",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "ACOS",
      field_key: "acos",
      minValue: "-",
      maxValue: "-",
    },
  ],
};
