import Pusher from 'pusher-js'
//PUSHER INSTANCE
var pusherInstance = undefined;

const configurationPusher = JSON.parse(process.env.REACT_APP_PUSHER_CONFIG)

try {
   if (typeof window !== 'undefined') { 
       pusherInstance = new Pusher(configurationPusher.key, {
           cluster: configurationPusher.cluster
       });
   }
} catch (err) {
   console.log("Error occurred while creating pusher instance!");
}


export default pusherInstance;