/**
 * 
 * @param {*} date in ISO String Format
 * @returns Formatted Data in DD-MM-YY Format
 */
import moment from "moment";

export const formatDateFn = (date,format=false) => {
    const selectedDate = new Date(date);
    const formattedDate =
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear();
    const formattedDateInDifferentFormat = moment(selectedDate).format("YYYY-MM-DD");
    return !format ? formattedDate : formattedDateInDifferentFormat;
};