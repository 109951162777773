import axios from "axios";

export function stripHtml(htmlString) {
  const doc = new DOMParser().parseFromString(htmlString, "text/html");
  return doc.body.textContent || "";
}
const fetchOptionsFromApi = async (apiKey, clientId) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "",
    headers: {},
  };

  if (apiKey === "portfolio_names_api_key") {
    config.url = `${process.env.REACT_APP_ADS_API}/client/${clientId}/portfolios?source=amazon&fields=name,platform_id&limit=10000`;
    const response = await axios.request(config);
    return response.data.data.map((item) => {
      return { value: item.name, label: item.name };
    });
  }

  if (apiKey === "campaign_names_api_key") {
    config.url = `${process.env.REACT_APP_ADS_API}/client/${clientId}/campaigns?source=amazon&fields=name&limit=100000`;
    const response = await axios.request(config);
    return response.data.data.map((item) => {
      return { value: item.name, label: item.name };
    });
  }

  if (apiKey === "category_names_api_key") {
    config.url = `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/categories?source=amazon&fields=name`;
    const response = await axios.request(config);
    return response.data.data.map((item) => {
      return { value: item.name, label: item.name };
    });
  }

  if (apiKey === "product_set_api_key") {
    config.url = `${process.env.REACT_APP_ADS_API}/client/${clientId}:product-set?source=amazon`;
    const response = await axios.request(config);
    return response.data.data
      .map((item) => {
        return { value: item.product_set, label: item.product_set };
      })
      .filter(Boolean); // Filter out any undefined values
  }

  return []; // Return empty array if no API key matches
};

const processField = async (field, clientId, categories) => {
  if (field?.dynamicOptions && !field.optionsType && !field?.dataFromApi) {
    let data = categories[field.optionsKey] ?? [];
    return { ...field, optionData: data };
  } else if (field?.dataFromApi === true) {
    try {
      let data = await fetchOptionsFromApi(field?.apiKey, clientId);
      return { ...field, optionData: data };
    } catch (error) {
      console.error(error);
      return { ...field, optionData: [] }; // Return field with empty options in case of error
    }
  } else if (field?.optionsType === "Static" && field?.optionsKey) {
    // This assumes that the static values are stored in `field.optionsKey` as a comma-separated string.
    const staticValues = field.optionsKey.split(",");
    const optionData = staticValues.map((value) => ({ value, label: value }));
    return { ...field, optionData: optionData };
  } else {
    return { ...field, optionData: [] };
  }
};

export const processFields = async (fields, clientId, categories) => {
  const promises = fields.map((field) =>
    processField(field, clientId, categories)
  );
  const results = await Promise.all(promises);
  return results;
};
