// export function abbreviateNumber(value, title) {
//   var newValue = value;
//   // 2 Decimal Places on these titles in overview section
//   const titles = ["CTR", "CVR", "CPM", "CPC", "ROAS", "ACOS"];
//   if (value >= 1000) {
//     var suffixes = ["", "K", "M", "B", "T"];
//     var suffixNum = Math.floor(("" + value).length / 3);
//     var shortValue = "";
//     for (var precision = 2; precision >= 1; precision--) {
//       shortValue = parseFloat(
//         (suffixNum != 0
//           ? value / Math.pow(1000, suffixNum)
//           : value
//         ).toPrecision(precision)
//       );
//       var dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
//       if (dotLessShortValue.length <= 2) {
//         break;
//       }
//     }
//     if (shortValue % 1 != 0)
//       shortValue = titles.includes(title)
//         ? shortValue.toFixed(2)
//         : shortValue.toFixed(1);
//     newValue = shortValue + suffixes[suffixNum];
//     return newValue;
//   } else {
//     newValue = titles.includes(title) ? Number(newValue).toFixed(2) : newValue;
//     return newValue;
//   }
// }

// New Function
export function abbreviateNumber(value, title) {
  var newValue = value;
  // 2 Decimal Places on these titles in overview section
  const titles = ["CTR", "CVR", "CPM", "CPC", "ROAS", "ACOS"];
  if (value >= 1000) {
    var suffixNum = Math.floor(("" + value).length / 3);
    newValue = titles.includes(title)
      ? value.toLocaleString("en-IN", {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })
      : value.toLocaleString("en-IN");
    return newValue;
  } else {
    newValue = titles.includes(title)
      ? Number(newValue).toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : newValue;
    return newValue;
  }
}






