import React, { useState, useEffect } from "react";
import overviewSeiingIcon from "../../assets/svgs/filter.svg";
import { Button, Stack } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CheckboxRb from "../Buttons/checkbox/checkbox";
import { useDetectClickOutside } from "react-detect-click-outside";
import useToggleFilterModal from "../../hooks/useToggleFilterModal";
import "./dynamicFilterModal.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

const FilterToggleModal = ({
  isToggle,
  isInput,
  isDynamicIcon,
  dynamicIcon,
  modalName,
  partitionRequired,
  resetApplyButton,
  handleToggle,
  handleInputValueChange,
  handleApply,
  firstColumns,
  secondColumns,
  allColumns,
  column1Heading,
  metrics,
  recieveData,
  column2Heading,
  buttonHeading,
  alignment,
  buttonClassNames,
  id,
  columnsWidth,
  onApply,
  viewType,
  resetButtonRequired,
  isToolTipRequired
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useDetectClickOutside({ onTriggered: closeFilters });
  const { toggleModalItem, inputValueChange,toggleAll } = useToggleFilterModal([
    ...allColumns,
  ]);
  const buttonIcon = isDynamicIcon ? dynamicIcon : overviewSeiingIcon;
  const firstAllChecked = firstColumns?.every((column) => column?.isEnabled)
  const secondAllChecked = secondColumns?.every((column) => column?.isEnabled)
  function overviewSettingBtnHandler() {
    setIsVisible(!isVisible);
  }

  function switchButtonHandler(key,status) {
    const newData = toggleModalItem(key,status);
    recieveData(newData);
  }

  function inputOnChangeHandler(data) {
    const newData = inputValueChange(data);
    recieveData(newData);
  }

  function toggleAllColumns (state,allColumns) {
    const newData = toggleAll(state,allColumns)
    recieveData(newData)
  }

  function applyFilter() {
    const dataToBeSentInFilters = allColumns?.filter((data) => data?.isMetric);
    onApply(dataToBeSentInFilters);
    setIsVisible(false)
  }

  function resetFilter() {}

  function closeFilters() {
    setIsVisible(false);
  }

  const tableFilterBody = (
    <>
      <div className="campaign-list-table-data">
        <h6 className="text-center">{column1Heading} </h6>
        <Stack className="campaign-list-main-table">
          <div className="d-flex px-2">
            <CheckboxRb
              className="reports-toggle"
              checked={firstAllChecked}
              label={""}
              id={"all"}
              onChange={(e) => toggleAllColumns(firstAllChecked,firstColumns)}
            ></CheckboxRb>
              <span className="mt-2">Show All</span>
          </div>
          <div className={" reports-filter-table-wrapper filter"}>
            {firstColumns?.length ? (
              <table
                className={
                  "table table reports-filter-table table-sm table-responsive"
                }
              >
                <thead className="fixed-table">
                  <tr className="text-start">
                    {/* <th className={""}></th> */}
                    {/* <th className={""}>Action</th>
                    <th>Metric</th>
                    <th>Min</th>
                    <th>Max</th> */}
                    {metrics?.map((metric) => {
                      return <td key={metric}>{metric}</td>;
                    })}
                  </tr>
                  <tr></tr>
                </thead>
                <DragDropContext>
                  <Droppable droppableId="list-container">
                    {(provided) => (
                      <tbody
                        className="another-tbody list-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ overflowY: "auto" }}
                      >
                        {firstColumns?.map((column, index) => (
                          <Draggable
                            key={column?.key}
                            draggableId={column?.key}
                            index={index}
                            isDragDisabled={false}
                          >
                            {(provided) => (
                              <tr
                                className="cursor-pointer"
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                {/* <td className={"hide"}>
                                  <img
                                    src={dragIcon}
                                    className="filterIcon"
                                    alt="filter"
                                  />
                                </td> */}
                                {isToggle ? (
                                  <td className={""}>
                                    {
                                      <CheckboxRb
                                        className="reports-toggle"
                                        checked={column?.isEnabled}
                                        label={""}
                                        id={column?.key}
                                        onChange={(e) =>
                                          switchButtonHandler(column?.key,column?.isEnabled)
                                        }
                                      ></CheckboxRb>
                                    }
                                  </td>
                                ) : null}

                                <td className="text-start">{column?.name}</td>
                                {isInput ? (
                                  <>
                                    {" "}
                                    <td>
                                      <div className="d-flex gap-2">
                                        <div className="d-flex justify-center actionn-text">
                                          <input
                                            className="form-control-sm"
                                            type="number"
                                            inputMode="number"
                                            disabled={false}
                                            value={column?.minValue}
                                            onChange={(e) =>
                                              inputValueChange({
                                                key: column?.key,
                                                valueKey: column?.minValue,
                                                value: e.target.value,
                                              })
                                            }
                                            // id={key.name + "minValue"}
                                          ></input>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex gap-1">
                                        <div className="d-flex justify-center actionn-text">
                                          <input
                                            className="form-control-sm"
                                            type="number"
                                            inputMode="number"
                                            disabled={false}
                                            value={column?.maxValue}
                                            onChange={(e) =>
                                              inputValueChange({
                                                key: column?.key,
                                                valueKey: column?.minValue,
                                                value: e.target.value,
                                              })
                                            }
                                            // id={key.field_name + "maxValue"}
                                          ></input>
                                        </div>
                                      </div>
                                    </td>
                                  </>
                                ) : null}
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </table>
            ) : (
              <div></div>
            )}
          </div>
        </Stack>
      </div>
    </>
  );

  const tableFilterBody2 = (
    <>
      <div className="campaign-list-table-data">
        <h6 className="text-center">{column2Heading} </h6>
        <Stack className="campaign-list-main-table">
        <div className="d-flex justify-content-between p-2">
            <CheckboxRb
              className="reports-toggle"
              checked={secondAllChecked}
              label={""}
              id={"all"}
              onChange={(e) => toggleAllColumns(secondAllChecked,secondColumns)}
            ></CheckboxRb>
            <span className="mt-2">Show All</span>
          </div>
          <div className={" reports-filter-table-wrapper filter"}>
            {secondColumns?.length ? (
              <table
                className={
                  "table table reports-filter-table table-sm table-responsive"
                }
              >
                <thead className="fixed-table">
                  <tr className="text-start">
                    {/* <th className={""}></th> */}
                    {/* <th className={""}>Action</th>
                    <th>Metric</th>
                    <th>Min</th>
                    <th>Max</th> */}
                    {metrics?.map((metric) => {
                      return <td key={metric}><span >{metric}</span></td>;
                    })}
                  </tr>
                  <tr></tr>
                </thead>
                <DragDropContext>
                  <Droppable droppableId="list-container">
                    {(provided) => (
                      <tbody
                        className="another-tbody list-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ overflowY: "auto" }}
                      >
                        {secondColumns?.map((column, index) => (
                          <Draggable
                            key={column?.key}
                            draggableId={column?.key}
                            index={index}
                            isDragDisabled={false}
                          >
                            {(provided) => (
                              <tr
                                className="cursor-pointer"
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                {/* <td className={"hide"}>
                                  <img
                                    src={dragIcon}
                                    className="filterIcon"
                                    alt="filter"
                                  />
                                </td> */}
                                {isToggle ? (
                                  <td className={""}>
                                    {
                                      <CheckboxRb
                                        className="reports-toggle"
                                        checked={column?.isEnabled}
                                        label={""}
                                        id={column?.key}
                                        onChange={(e) =>
                                          switchButtonHandler(column?.key)
                                        }
                                      ></CheckboxRb>
                                    }
                                  </td>
                                ) : null}

                                <td className="text-start">{column?.name}</td>
                                {isInput ? (
                                  <>
                                    {" "}
                                    <td>
                                      <div className="d-flex gap-2">
                                        <div className="d-flex justify-center actionn-text">
                                          <input
                                            className="form-control-sm"
                                            type="number"
                                            inputMode="number"
                                            disabled={false}
                                            value={column?.minValue}
                                            onChange={(e) =>
                                              inputValueChange({
                                                key: column?.key,
                                                valueKey: column?.minValue,
                                                value: e.target.value,
                                              })
                                            }
                                            // id={key.name + "minValue"}
                                          ></input>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex gap-1">
                                        <div className="d-flex justify-center actionn-text">
                                          <input
                                            className="form-control-sm"
                                            type="number"
                                            inputMode="number"
                                            disabled={false}
                                            value={column?.maxValue}
                                            onChange={(e) =>
                                              inputValueChange({
                                                key: column?.key,
                                                valueKey: column?.minValue,
                                                value: e.target.value,
                                              })
                                            }
                                            // id={key.field_name + "maxValue"}
                                          ></input>
                                        </div>
                                      </div>
                                    </td>
                                  </>
                                ) : null}
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </table>
            ) : (
              <div></div>
            )}
          </div>
        </Stack>
      </div>
    </>
  );

  return (
    <>
      <div className="position-relative" ref={ref} id={id ? id : ""}>
      { isToolTipRequired ?  <ReactTooltip
          id="toggle-tooltip"
          style={{ zIndex: "999999", fontSize: "12px", padding: "8px" }}
          effect="solid"
          place="top"
          variant="dark"
        /> : null}
        <button
          className={`primary-inactive-btn apply-filter d-flex align-items-center justify-content-end ${buttonClassNames  ? buttonClassNames : ""}`}
          data-tooltip-content={' Toggle (Hide/Unhide) Columns'}
          data-tooltip-id="toggle-tooltip"
          onClick={overviewSettingBtnHandler}
        >
          <div className="d-flex gap-2 align-items-center">
            {isDynamicIcon ? (
              dynamicIcon
            ) : (
              <img
                src={buttonIcon}
                className="cursor-pointer-global filterIcon"
                alt="overview-settings-tile"
              ></img>
            )}
            {
              buttonHeading &&  <div>{buttonHeading}</div>
            }
           
          </div>
        </button>
        {isVisible && (
          <div
            className={`dynamic-filter-modal-container ${
              alignment === "left" ? "left-align" : "right-align"
            }`}
          >
            <div className="hide"></div>
            <div className="shadow-lg p-1 mb-5 rounded tooltip-content">
              <div className="d-flex justify-content-between align-items-center py-3 px-3">
                <div className="text-left">{modalName}</div>
                <div
                  className={
                    partitionRequired
                      ? "d-flex gap-2 align-items-center"
                      : "d-flex gap-2 align-items-center ml-10"
                  }
                >
                  {resetApplyButton && (
                    <>
                  {   resetButtonRequired && <button
                        size="sm"
                        className="primary-inactive-btn"
                        onClick={resetFilter}
                      >
                        Reset
                      </button>}
                      <Button
                        size="sm"
                        className="primary-active-btn"
                        onClick={applyFilter}
                      >
                        Apply
                      </Button>
                    </>
                  )}
                </div>
              </div>
              <div className={partitionRequired ? "d-flex gap-3" : ""}>
                <div className="bg-white py-2 rounded" style={{width:columnsWidth ? columnsWidth : "auto"}}>{tableFilterBody}</div>
                {partitionRequired && (
                  <div className="bg-white py-2 rounded" style={{width:"300px"}}>
                    {tableFilterBody2}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FilterToggleModal;
