

const calculateCTR = (clicks,impressions) => {
    return (clicks/impressions)*100 
}

const calculateCPM = (spends,impressions) => {
    return (spends/impressions)*1000 
}

const calculateCPC = (spends,clicks) => {
    return spends/clicks 
}

const calculateCVR = (Orders,clicks) => {
    return (Orders/clicks)*100 
}

const calculateAOV = (Revenue,Orders) => {
    return (Revenue/Orders) 
}

const calculateROAS = (Revenue,Spends) => {
    return Revenue/Spends 
}

const calculateACOS = (spends,revenue) => {
    return (spends/revenue) * 100 
}

export {
    calculateACOS,
    calculateAOV,
    calculateCPC,
    calculateCPM,
    calculateCTR,
    calculateCVR,
    calculateROAS,
}