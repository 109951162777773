import html2canvas from 'html2canvas';
import React, { useState, useEffect } from 'react';


export default function Canvas ({name})  {
  const element = document.getElementById('downloadChartWrapper');

      element.style.display = 'none';
 
    
    const chartContainer = document.getElementById('downloadChart');
    html2canvas(chartContainer).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = name+'.png';
      link.click();
      element.style.display = 'block';
    });
  return null
    
  };