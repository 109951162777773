const defaultDataHeads = [
    {
        fieldName: "Status",
        id: "campaign_name",
        isDefaultHead: true,
        isAbbrivation: false,
    },
    {
        fieldName: "Rule Name",
        id: "rule_name",
        isDefaultHead: true,
        isAbbrivation: false,
    },
    {
        fieldName: "Campaign Names",
        id: "edit",
        isDefaultHead: true,
        isAbbrivation: false,
    },
    {
        fieldName:"Frequency",
        id:'frequency_values',
    },
    {
        fieldName:"Start Date",
        id:'start_date',
    },
    {
        fieldName:"End Date",
        id:'end_date',
    },

]

export default defaultDataHeads