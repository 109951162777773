/**
 * This function will take filters and will return filtered Array 
 * @param {*} productAttribFilters
 * @returns Object With API Filtering Params {attributeFilters}
 */
// Need to work on this function for AND and IN  Operators *** Scale ***
export default function automationDataFilterParser(
    productAttribFilters,
  ) {
    // Filter out all the values which are not part of the mapping then map filtered with filterMapper
    const attributeFilters = [...productAttribFilters]
      ?.filter((filter) => {
        const { field_name, operator, value } = filter;
        return field_name?.length > 1 && operator !== "-" && (parseInt(value) >= 1 || parseInt(value) === 0) && parseInt(value) !== NaN
      })
      ?.map((filter) => {
        return mapPerformanceFilter(filter);
      });
    return attributeFilters
  }
  function mapPerformanceFilter(filter) {
    const modifiedFieldName = PerformanceFieldMapper(filter.field_name)
    return {
      field_name: modifiedFieldName,
      operator: operatorMapper(filter.operator),
      value: parseFloat(filter.value),
    };
  }
  function PerformanceFieldMapper (fieldName) {
    switch (fieldName) {
      case "Revenue":
        return "sale_value"
      case "Top Selling":
        return "top_selling"
      case "Top Conversion":
        return "top_conversion"
      case "Top ROAS":
        return "top_roas"
      default:
       return fieldName?.toLowerCase()
    }
  }
  function operatorMapper(key) {
    switch (key) {
      case ">":
        return "GREATER_THAN";
      case "<":
        return "LESS_THAN";
      case "=":
        return "EQUALS";
      case ">=":
        return "GREATER_THAN_EQUALS_TO"
      case "<=":
        return "LESS_THAN_EQUALS_TO"
      case "contains":
        return "CONTAINS";
      case "not contains":
        return "NOT_CONTAINS";
      default:
        return key;
    }
  }