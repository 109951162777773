import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./TooltipRb.css";
export default function TooltipRb(props) {
  /* 
   @Note this component take below list of props :
   props.tooltipText - this can be text which u want to show in tooltip
   props.placement = Sets the direction the Tooltip is positioned towards. (value can be - 'top', 'right', 'bottom', 'left')
   props.children = wrap the element inside this component on which you want to show tooltip on
   props.id = An html id attribute, necessary for accessibility
   props.className = give pass className  props to this component & use following method to chages its css
   tooltip,tooltip > div.tooltip-inner,tooltip.show ,tooltip > div.arrow::before
    background-color: red; */



  //this will activate tooltip
  const tooltip = <Tooltip id={props.id}>{props.tooltipText}</Tooltip>;

  return (
    <div
      className={
        props.className
          ? props.className + "tooltip-container"
          : "tooltip-container"
      }
    >
      <OverlayTrigger placement={props.placement} overlay={tooltip}>
        {props.children}
      </OverlayTrigger>
    </div>
  );
}
