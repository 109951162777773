export const enablePauseSingleChildAd = (currentState,childAdId,status,viewType) => {
    const updatedState = currentState.map((parentAd) => {
      if(viewType) {
        parentAd?.childRows?.map((childAd) => {
          if(childAd.id === childAdId) {
            childAd.status = status
            childAd.isTargetChecked = false
          }
          return childAd
        })
      }
      if(childAdId === parentAd.id) { 
        parentAd.status = status
        parentAd.isTargetChecked = false
      }
       return parentAd
     })
     return updatedState;
}