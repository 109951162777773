import React, { useState, useEffect } from "react";
// import Buttons from "react-bootstrap/Button";
// Button
import "./primaryBtnRB.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import { useGlobalContext } from "../../../context/global";
import { masterApi } from "../../../apis/masterApi";
import redirect from "../../../constant/Redirect";
import { useNavigate } from "react-router-dom";
import {
  TOAST_SUCCESS,
  TOAST_DANGER,
  TOAST_POSITION_TOP_RIGHT,
} from "../../../constant/HardTypes";
import { initialAdGroupData, initialCreateCampaignErrors } from "../../../constant/states/campaignCreation";
import {toast} from 'react-toastify';
import CustomToast from "../../ToastAlert/CustomToast/CustomToast";
const PrimaryBtnRB = (props) => {
  // States

  const navigate = useNavigate();
  const { showAlert } = useGlobalContext();
  const [buttonDisable, setButtonDisable] = useState("some");
  const [showAlertPop, setShowAlert] = useState([]);
  const loaderNotVisibleOnTheseAPI = ["fetching-keywords", "Adding-keywords"];

  const {currency_code ,country_code} = JSON.parse(localStorage.getItem("reference"));

  // API Integration For campaign Creation

  const {
    campaignData,
    setCampaignData,
    adGroupData,
    setAdGroupData,
    adSetData,
    setAdSetData,
    show,
    setShow,
    keywords,
    setKeywords,
    setBrandKeywordsToBeAdded,
    setKeywordsToBeShow,
    setSelectedProducts,
    setSpAsins,
    setSdAsins,
    setManuallyAddedAsins,
    setIsLoaderVisible,
    setSpSelectedSuggestedCategories,
    setAdsetAsins,
    setSpWholeCategories,
    setSdSelectedSuggestedCategories,
    setSdWholeCategories,
    setPage,
    setSpBrandSelectedSuggestedCategories,
    setSpBrandAsins,
    setSdBrandSelectedSuggestedCategories,
    setSdBrandAsins,
    setsdBrandWholeCategories,
    setspBrandWholeCategories,
    setBrandMatchTypes,
    setBrandKeywordsToBeShow,
    setmanualBrandMatchTypes,
    setManualMatchTypes,
    setKeywordsTobeAdded,
    buttonStatus,
    setButtonStatus,
    setModalFor,
    productSetData,
    createCampaignErrors,
    dispatchErrors
  } = useGlobalContext();
  const {RULE_1,RULE_2,RULE_3} = productSetData

  function clearEveryState() {
    setKeywords([]);
    setBrandKeywordsToBeAdded([]);
    setKeywordsTobeAdded([]);
    setKeywordsToBeShow([]);
    setSelectedProducts([]);
    setSpAsins([]);
    setSdAsins([]);
    setManuallyAddedAsins([]);
    setAdsetAsins([]);
    setSpSelectedSuggestedCategories([]);
    setSpWholeCategories([]);
    setSdSelectedSuggestedCategories([]);
    setSdWholeCategories([]);
    setPage("");
    setSpBrandSelectedSuggestedCategories([]);
    setSpBrandAsins([]);
    setSdBrandSelectedSuggestedCategories([]);
    setSdBrandAsins([]);
    setsdBrandWholeCategories([]);
    setspBrandWholeCategories([]);
    setBrandMatchTypes(["Exact"]);
    setBrandKeywordsToBeShow([]);
    setmanualBrandMatchTypes([]);
    setManualMatchTypes([]);
    setAdGroupData(initialAdGroupData)
  }

  // Checking if campaign type is automatic and if all the targeting group value is null



  const integrateApi = async () => {
    /**
     * @Note This function does the promise chaining 3 API's are being called here to create campaign adgroup and adset and every API returns ID to be passed in next API params.
     *
     */
    try {
      const data = campaignData;
      setIsLoaderVisible(true);
      setButtonStatus({ status: true, text: "Processing..." });
      const result = await masterApi(
        data,
        "SPAutomaticcampaign",
        false,
        "post"
      );
      if (redirect(result)) return navigate("/login");
      const data1 = addGroupData(result?.data?.id);
      data1["campaign_id"] = result?.data?.id;
      if("amz_keyword_targeting" in data1) {
        data1['amz_keyword_targeting'] =  data1['amz_keyword_targeting'].map(function(keyword) {
          keyword["bid"] = keyword["userBid"]
          delete(keyword.lowest)
          delete(keyword.heighest)
          delete(keyword.userBid)
            return keyword;
        });
      }
      // If targeting Type is Automatic and all bids are empty then delete amz_manual_bid key
      if(data["amz_targeting_type"] === "Automatic") {
        if("amz_manual_bid" in data1) {
          const allKeysEmpty = Object.values(data1["amz_manual_bid"]).every(value => value.trim() === "");
          if(allKeysEmpty) {
            delete data1["amz_manual_bid"]
          }
        } 
      } else {
          if(data1["amz_default_bid"] === "") {
            const defaultBid = `${JSON.parse(localStorage.getItem("defaultBid"))}`;
            data1["amz_default_bid"] = defaultBid
            setAdGroupData({...adGroupData,amz_default_bid:defaultBid})
          }
          if("amz_manual_bid" in data1) {
              delete data1["amz_manual_bid"]
          } 
      }
      const result1 = await masterApi(
        data1,
        "Adgroup",
        false,
        "post",
        result?.data?.id
      );
      if (redirect(result1)) return navigate("/login");
      const data2 = adSetDataSet(result1?.data?.id);
      data2["adset_id"] = result1?.data?.id;
      const result2 = await masterApi(
        data2,
        "Adset",
        false,
        "post",
        result?.data?.id
      );
      if (redirect(result2)) return navigate("/login");
      if (
        result?.statusCode !== 200 ||
        result1?.statusCode !== 200 ||
        result2?.statusCode !== 200
      ) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: "Error While Creating Campaign",
        // });
        toast.error('Error While Creating Campaign');
        setButtonStatus({ status: false, text: "" });
        setIsLoaderVisible(false);
      } else {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_SUCCESS,

        //   title: "Success",

        //   description: "Campaign has been created successfully",
        // });
        toast.success('Campaign has been created successfully!')
        setButtonStatus({ status: false, text: "" });
        clearEveryState();
        setIsLoaderVisible(false);
        navigate("/campaign-list");
      }
    } catch (error) {
      console.log(error);
    }
  };

  function addGroupData(id) {
    setAdGroupData({ ...adGroupData, campaign_id: id });
    return adGroupData;
  }

  function adSetDataSet(id) {
    setAdSetData({ ...adSetData, adset_id: id });
    return adSetData;
  }

  const onPortFolioClick = (e) => {
    e.preventDefault();
    setShow(true);
    setModalFor("create-portfolio")
  };

  function validateData(campaignData, adGroupData, adSetData) {
    const pattern = /(SD_|SP_).+/;
    let checker = pattern.test(campaignData.name) && pattern.test(adGroupData.name) && parseFloat(campaignData?.daily_budget) >= 100 && adSetData.type !== "";
    const errorsData = {...initialCreateCampaignErrors}
    const isInternationalClient = currency_code!=="INR" && country_code!=="IN"
    let requiredFields = [];
    
    if (!pattern.test(campaignData.name)) {
      requiredFields.push("Campaign Name");
      errorsData.name = true
    }
    
    if (!pattern.test(adGroupData.name)) {
      requiredFields.push("Adgroup Name");
      errorsData.adgroup_name = true
    }
    
    if (parseFloat(campaignData?.daily_budget) < 100 && !isInternationalClient) {
      requiredFields.push("Daily Budget Should be more than 100");
      errorsData.daily_budget = true
    }

    if((parseFloat(campaignData?.daily_budget) <=0 || campaignData?.daily_budget==="") && isInternationalClient) {
      requiredFields.push("Daily Budget Should be more than zero")
      errorsData.daily_budget = true
    }
    
    if (adSetData.type === "") {
      requiredFields.push("Ad Details");
      errorsData.ad_details = true
    }
    
    if (campaignData.bidding && campaignData.bidding.bidding_strategy === "") {
      requiredFields.push("Campaign Bidding Strategy");
      checker = false;
      errorsData.campaign_bidding_strategy = true
    }





    if (adGroupData?.amz_keyword_targeting && adGroupData.amz_keyword_targeting.length === 0) {
      requiredFields.push("Add Keyword Targeting");
      checker = false;
      errorsData.targeting = true
    }
    
    if (adGroupData?.amz_product_targeting && adGroupData.amz_product_targeting.length === 0) {
      requiredFields.push("Add Product Targeting");
      checker = false;
      errorsData.targeting = true
    }
    
    if (adGroupData?.amz_audience_targeting && adGroupData.amz_audience_targeting.length === 0) {
      requiredFields.push("Add Audience Targeting");
      checker = false;
      errorsData.targeting = true
    }
    

      

    
    if (adSetData?.products && adSetData.products.length === 0) {
      if(adSetData?.validation_type === 'amazon_custom_creative_asin'){
        requiredFields.push("Enter at least one ASIN");
        checker = false;
      }else if(adSetData?.validation_type === 'amazon_custom_creative'){
        requiredFields.push("Select at least one Product");
        checker = false;
      }else{
        requiredFields.push("Add Products");
        checker = false;
      }
      
    }
    // Check If Smart Ads Products added is greater than 3 or not
    const arrayForSmartAds = ["TOP_SELLING","INSTOCK","DISCOUNTED"]
    if(arrayForSmartAds.includes(adSetData?.product_set_type)) {
      const currentSelectedType = adSetData?.product_set_type
      const currentSelectedProductCount = currentSelectedType === "TOP_SELLING" ? RULE_1 : currentSelectedType === "INSTOCK" ? RULE_2 : RULE_3
      if(currentSelectedProductCount<3) {
        requiredFields.push("Selected Products Count should be 3 or more than 3")
        // showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: `Products Count should be 3 or more than 3`,
        // });
        toast.error('Products Count should be 3 or more than 3')
        checker = false
      }
    }else{
      if(adSetData?.validation_type === 'amazon_smart_creative'){
        requiredFields.push("Select at least one smart logic")
        checker = false
      }
    }

    if(adSetData?.product_set_type ===null) {
      requiredFields.push("Please Choose Smart Ads")
      checker=false
    }

    // Checking For the Manual Bids & Automatic Campaigns Data

   if(adGroupData?.amz_default_bid === "") {
      requiredFields.push("Default Bid");
      checker = false
      errorsData.amz_default_bid = true
   }
  
   if(checker) {
    dispatchErrors({action:"resetEveryError"})
   } else {
    dispatchErrors({action:"submitErrors",value:{...errorsData}})
   }
    return {
      checker: checker,
      requiredFields: requiredFields
    };
  }


  function onButtonClick(e) {
    if (props.page) {
    let {checker,requiredFields} =  validateData(campaignData,adGroupData,adSetData)
      if(checker) {
        integrateApi();
      } else {
        const fields = ["Please Fill Mandatory Fields:-",...requiredFields]
        toast.error(<CustomToast toastDetails={fields}/>)
      }
    }
    if (props.newCampaignId) {
        newAdGroupAPICall();
    }
    props.pageClickOptionsHandler(e.target.name);
  }

  const newAdGroupAPICall = async () => {
    try {
      setIsLoaderVisible(true);
      setButtonStatus({ status: true, text: "Processing..." });
      const data1 = addGroupData(props.newCampaignId);
      data1["campaign_id"] = props.newCampaignId;
      if("amz_keyword_targeting" in data1) {
        data1['amz_keyword_targeting'] =  data1['amz_keyword_targeting'].map(function(keyword) {
          keyword["bid"] = keyword["userBid"]
          delete(keyword.lowest)
          delete(keyword.heighest)
          delete(keyword.userBid)
            return keyword;
        });
      }
      if(props.adGroupType!=="SP-Auto")
      if("amz_manual_bid" in data1) {
        delete data1["amz_manual_bid"]
    } 
      const result1 = await masterApi(
        data1,
        "Adgroup",
        false,
        "post",
        props.newCampaignId
      );
      if (redirect(result1)) return navigate("/login");
      const data2 = adSetDataSet(result1?.data?.id);
      data2["adset_id"] = result1?.data?.id;
      const result2 = await masterApi(
        data2,
        "Adset",
        false,
        "post",
        props.newCampaignId
      );
      if (redirect(result2)) return navigate("/login");
      if (result1?.statusCode !== 200 || result2?.statusCode !== 200) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
        //   backgroundColor: TOAST_DANGER,

        //   title: "Success",

        //   description: "Error While Creating New AdGroup",
        // });
        toast.error('Error While Creating New AdGroup')
        setButtonStatus({ status: false, text: "" });
        setIsLoaderVisible(false);
        // alert("Error While Creating New AdGroup");
      } else {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
        //   backgroundColor: TOAST_SUCCESS,

        //   title: "Success",

        //   description: "Adgroup Has been created Successfully",
        // });
        toast.success('Adgroup Has been created Successfully')
        setButtonStatus({ status: false, text: "" });
        clearEveryState();
        setIsLoaderVisible(false);
        props.setAdGroupModalVisible(false);
        navigate("/campaign-list");
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  function createNewAdGroup() {
    newAdGroupAPICall();
  }

  return (
    <div
      className={
        props.className ? props.className : "primary-bn-container-main"
      }
    >
      <Button
        className={"primary-btn " + props.className}
        id={props.id}
        variant="primary"
        name="create"
        onClick={
          !props.portfolio
            ? onButtonClick
            : props.newCampaignId
            ? createNewAdGroup
            : onPortFolioClick
        }
        disabled={
          (buttonStatus.status &&
            !loaderNotVisibleOnTheseAPI.includes(buttonStatus.text)) ||
          (props.className === "width-100" && buttonDisable === "")
            ? true
            : false
        }
      >
        {buttonStatus.status &&
        !loaderNotVisibleOnTheseAPI.includes(buttonStatus.text)
          ? buttonStatus.text
          : props.insideBtnText}
      </Button>
    </div>
  );
};

export default PrimaryBtnRB;
