
/**
 * @Note Use this hook when you want to use the highlight function on the particular list
 * @Usage This hook will take the list state and state updater and will return a function that will take an index of highlighted item to be updated.  
 * @param {\} state 
 * @param {*} updateState 
 * @returns Updated state with highlighted list items
 */


export default function useHighlightList(state, updateState) {
  const handleHighlightList = (index) => {
    const data = structuredClone(state);
    data[index].isHighlighted = !data[index].isHighlighted;
    updateState([...data]);
  };

  return [handleHighlightList];
}
