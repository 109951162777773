/**
 * @Note It will Filter the paaed data based on the appliedFilters.
 * @param {*} passedData 
 * @param {*} appliedFilterData 
 * @returns FilteredData Data According to the filters. 
 */

export const onInputRangeChangeHandler = (passedData=[],appliedFilterData=[], setMinMaxFilterActivated) => {
  setMinMaxFilterActivated(false)
    let tempCampaigns = JSON.stringify(passedData);
    tempCampaigns = JSON.parse(tempCampaigns);
    let filteredCampaigns = [];
    let filterWithDashAsZero = [];

    for (let i = 0; i < appliedFilterData?.length; i++) {
      let temp = JSON.parse(JSON.stringify(appliedFilterData[i]));
      temp.minRange = isNaN(temp.minRange) ? 0 : Number(temp.minRange);
      temp.maxRange =
        temp.maxRange == "" || isNaN(temp.maxRange)
          ? Infinity
          : Number(temp.maxRange);

      if (
        (temp.minRange != 0 && temp.maxRange != Infinity) ||
        (temp.minRange == 0 && temp.maxRange != Infinity)
      ) {
        setMinMaxFilterActivated(true)
        filterWithDashAsZero.push(temp);
      } else if (
        (temp.minRange != 0 && temp.maxRange == Infinity) ||
        (temp.minRange != 0 && temp.maxRange == "")
      ) {
        setMinMaxFilterActivated(true)
        filterWithDashAsZero.push(temp);
      }
    }

    for (let r = 0; r < tempCampaigns.length; r++) {
      let shouldPass = true;

      for (let i = 0; i < filterWithDashAsZero.length; i++) {
        let currentFilter = filterWithDashAsZero[i];

        if (
          (currentFilter.minRange != 0 && currentFilter.maxRange != Infinity) ||
          (currentFilter.minRange == 0 && currentFilter.maxRange != Infinity) ||
          (currentFilter.minRange != 0 && currentFilter.maxRange == Infinity)
        ) {
          let currentCampaign = tempCampaigns[r];
          if (
            currentCampaign[currentFilter.id] != undefined &&
            currentCampaign[currentFilter.id] !== null
          ) {
            if (
              currentCampaign[currentFilter.id] < currentFilter.minRange ||
              (currentFilter.maxRange !== Infinity &&
                currentCampaign[currentFilter.id] > currentFilter.maxRange)
            ) {
              shouldPass = false;
            }
          } else {
            shouldPass = false;
          }
        } else {
          shouldPass = false;
        }
      }
      if (shouldPass) {
        filteredCampaigns.push(tempCampaigns[r]);
      }
      // setAdsetTargetStatDataForTable(filteredCampaigns);
      // This is the data  which we will show after the campaigns will get filtered
      // setFilteredAdsetData(filteredCampaigns);
      // setCopyOfAdsetTargetStatDataForTable(filteredCampaigns);
    }
    return filteredCampaigns;
  };