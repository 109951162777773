import ReactApexChart from "react-apexcharts";
import { COLORS_SERIES } from "./utils";

export const ApexPieChartRenderer = ({ resultSet }) => {
  return (
    <ReactApexChart
      type="pie"
      height="80%"
      series={resultSet.series}
      options={{
        labels: resultSet.label,
        chart: { fontFamily: "Inter" },
        colors: COLORS_SERIES,
        dataLabels: {
          enabled: true,
          style: { colors: ["#FFF"], fontWeight: 400 },
          dropShadow: { enabled: false },
        },
        states: { hover: { filter: "none", value: 0 } },
        tooltip: { enabled: true },
        noData: { text: "No Data to display" },
        legend: {
          position: "right",
          fontFamily: "Inter",
          fontWeight: 400,
          labels: { colors: COLORS_SERIES },
          itemMargin: { horizontal: 5, vertical: 0 },
        },
      }}
    />
  );
};
