import React from "react";
import { useGlobalContext } from "../../../context/global";
import ChoosePortfolio from "./ChoosePortfolio";
import RadioButtonRb from "../../Buttons/radioButtonRb/radioButtonRb";
import { useState } from "react";
import { useEffect } from "react";
import { getCurrencySymbol } from "../../../constant/defaultCurrencyConfigurations";

const CreateCampaign = (props) => {
  const { campaignData, setCampaignData,campaigns,dispatchErrors,createCampaignErrors } = useGlobalContext();
  const { type, name } = campaignData;
  const [fixedText, setFixedText] = useState("");
  const [fixedError,setFixedError] = useState(false)
  const { show, setShow } = useGlobalContext();
  
  const { categories, setCategories, campaignType, selectedCategory } =
    useGlobalContext();
  const [error, setError] = useState({
    status: false,
    message: "Please Enter",
  });

  const {currency_code ,country_code} = JSON.parse(localStorage.getItem("reference"));
  const isInternationalClient = currency_code!=="INR" && country_code!=="IN"

  const currencySymbol = getCurrencySymbol();

  const errorMessageForDailyBudget =  isInternationalClient?"Daily Budget Cannot Be Empty Or Zero":"Daily Budget Should be 100 or More Than 100"

  const handleChange = (e) => {
    let valu = e.target.value;
    if (!Number(valu) && valu!=="") {
      return;
    } else {
      // Check For Domestic Client
      if(e.target.value<100 && !isInternationalClient) {
        dispatchErrors({action:"setError",fieldName:"daily_budget",value:true});
      } 
      // Check For Internation Client
      else if((e.target.value<=0 || e.target.value==="") && isInternationalClient) {
        dispatchErrors({action:"setError",fieldName:"daily_budget",value:true});
      } 
      // Pass
      else {
        dispatchErrors({action:"setError",fieldName:"daily_budget",value:false});
      }
      setCampaignData({ ...campaignData, [e.target.name]: e.target.value });
    }
  };


  const focusChange = (e) => {
    if (name !== "") {
      return;
    }

    // First We will set fixedText and then will fix the main campaignData text
    setFixedText(
      type === "SP-Auto" && campaignType === "prospect"
        ? `Prospect_Automatic_SP_`
        : type === "SP-Keyword" && campaignType === "prospect"
        ? `Prospect_Keyword_SP_`
        : type === "SP-Product" && campaignType === "prospect"
        ? `Prospect_Product_SP_`
        : type === "SD-Product" && campaignType === "prospect"
        ? `Prospect_Product_SD_`
        : type === "SP-Keyword" && campaignType === "retargeting"
        ? `Retarget_Keyword_SP_`
        : type === "SP-Product" && campaignType === "retargeting"
        ? `Retarget_Product_SP_`
        : type === "SD-Product" && campaignType === "retargeting"
        ? `Retarget_Product_SD_`
        : type === "SD-Audience" && campaignType === "retargeting"
        ? `Retarget_Audience_SD_`
        : ""
    );
    setCampaignData({
      ...campaignData,
      name:
        type === "SP-Auto" && campaignType === "prospect"
          ? `Prospect_Automatic_SP_`
          : type === "SP-Keyword" && campaignType === "prospect"
          ? `Prospect_Keyword_SP_`
          : type === "SP-Product" && campaignType === "prospect"
          ? `Prospect_Product_SP_`
          : type === "SD-Product" && campaignType === "prospect"
          ? `Prospect_Product_SD_`
          : type === "SP-Keyword" && campaignType === "retargeting"
          ? `Retarget_Keyword_SP_`
          : type === "SP-Product" && campaignType === "retargeting"
          ? `Retarget_Product_SP_`
          : type === "SD-Product" && campaignType === "retargeting"
          ? `Retarget_Product_SD_`
          : type === "SD-Audience" && campaignType === "retargeting"
          ? `Retarget_Audience_SD_`
          : "",
    });
  };
  const campaignRadioChange = (e) => {
    const mainBtn = e.target.closest(".redioButton-box");
    const allBtn = mainBtn.querySelectorAll(".redioButton");
    allBtn.forEach((item) => {
      item.classList.remove("active");
    });
    const add = e.target.closest(".redioButton");
    add.classList.add("active");
    add.children[0].children[0].children[0].checked = true;
  };

  return (
    <>
      <div className="sponsored-product-keyword-targeting">
        <div className="campaign">
          <span className="common-head">Campaign Details</span>
        </div>
        <div className="row row-wrapper">
          <div className="col-lg-6">
            <label className="common-label">
              Campaign Name
              <span className="error-message">
                {createCampaignErrors?.name ? "*" : ""}
              </span>
            </label>

            <input
              type="text"
              style={{ paddingLeft: "10px"}}
              className={` ${createCampaignErrors?.name ? 'border border-danger' :''} create-campaign-outline`}
              autoComplete="off"
              // Placeholders are changing basis on campaign type and campaign propspect and retargeting type
              placeholder={
                type === "SP-Auto" && campaignType === "prospect"
                  ? `Prospect_Automatic_SP_`
                  : type === "SP-Keyword" && campaignType === "prospect"
                  ? `Prospect_Keyword_SP_`
                  : type === "SP-Product" && campaignType === "prospect"
                  ? `Prospect_Product_SP_`
                  : type === "SD-Product" && campaignType === "prospect"
                  ? `Prospect_Product_SD_`
                  : type === "SP-Keyword" && campaignType === "retargeting"
                  ? `Retarget_Keyword_SP_`
                  : type === "SP-Product" && campaignType === "retargeting"
                  ? `Retarget_Product_SP_`
                  : type === "SD-Product" && campaignType === "retargeting"
                  ? `Retarget_Product_SD_`
                  : type === "SD-Audience" && campaignType === "retargeting"
                  ? `Retarget_Audience_SD_`
                  : ""
              }
              name="name"
              value={name}
              onChange={(e) => {
                const input = e.target.value;
                e.target.value = fixedText + input.substr(fixedText.length);
                if(campaigns?.includes(e.target.value.toLowerCase())) {
                  setFixedError(true)
                  dispatchErrors({action:"setError",fieldName:"name",value:true});
                  setCampaignData({ ...campaignData, name: e.target.value });
                } else {
                  setFixedError(false)
                  dispatchErrors({action:"setError",fieldName:"name",value:false});
                  setCampaignData({ ...campaignData, name: e.target.value });
                }
              }}
              onFocus={() => focusChange("Campaign Name")}
            ></input>
           {fixedError && <small className="text-danger">Error:-Campaign Name Already Exists!</small> }  
          </div>
          <div className="col-lg-6">
            <label className="portfolio-label">
              Choose Portfolio
                {/* <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_1178_14679"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                  >
                    <rect width="20" height="20" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_1178_14679)">
                    <path
                      d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                      fill="#4D4184"
                    />
                    <path
                      d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                      fill="#4D4184"
                    />
                  </g>
                </svg> */}
            </label>
            <ChoosePortfolio />
          </div>
          <div className="col-lg-6">
            <label className="common-label">
              Daily Budget
              <span className="error-message">
                {createCampaignErrors.daily_budget ? "*" : ""}
              </span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_1178_14679"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <rect width="20" height="20" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_1178_14679)">
                  <path
                    d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                    fill="#4D4184"
                  />
                  <path
                    d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                    fill="#4D4184"
                  />
                </g>
              </svg>
            </label>
            <input
              type="number"
              placeholder={`${currencySymbol} 1000`}
              
              name="daily_budget"
              onChange={handleChange}
              onFocus={handleChange}
              id="automatic-daily-bd-inp"
              className={` ${createCampaignErrors?.daily_budget ? 'border border-danger' :''} create-campaign-outline`}
            ></input>
            {createCampaignErrors?.daily_budget && <small className="text-danger">{`Error:-${errorMessageForDailyBudget}`}</small>}
          </div>
        </div>
        <div className={props.SD ? "d-none" : "redioButton-box"}>
          <h6 className="campaign-biding">Campaign Biding Strategy</h6>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="redioButton"
                onClick={(e) => {
                  campaignRadioChange(e);
                  setCampaignData({
                    ...campaignData,
                    bidding: {
                      bidding_strategy: "autoForSales",
                      bidding_value: 100,
                    },
                  });
                }}
              >
                <RadioButtonRb
                  name="select-Campaign-Details"
                  label={
                    <Leavle
                      heading="Up and Down"
                      para="We will raise your bids (by a maximum of 100%) in real time when your ad may be more likely to convert to a sale, and lower your bids when less likely to convert to a sale.
                      "
                    />
                  }
                ></RadioButtonRb>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="redioButton h-100"
                onClick={(e) => {
                  campaignRadioChange(e);
                  setCampaignData({
                    ...campaignData,
                    bidding: {
                      bidding_strategy: "legacyForSales",
                      bidding_value: 100,
                    },
                  });
                }}
              >
                <RadioButtonRb
                  name="select-Campaign-Details"
                  label={
                    <Leavle
                      heading="Down"
                      para="Amazon will decrease your bids based on the purchase probability."
                    />
                  }
                ></RadioButtonRb>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Leavle = ({ heading, para }) => {
  return (
    <>
      <span className="leavle-hover leavle-heading">{heading}</span>
      <br />
      {para && <span className="leavle-hover leavle-para">{para}</span>}
    </>
  );
};

export default CreateCampaign;
