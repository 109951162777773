export default function previousDays(dates) {
  const dateObjects = dates.map((date) => new Date(date));

  const diffDays =
    (dateObjects[1] - dateObjects[0]) / (1000 * 60 * 60 * 24) + 1;
  const previousDates = dateObjects.map((date) => {
    const previousDate = new Date(date);
    previousDate.setDate(previousDate.getDate() - diffDays);
    return previousDate.toISOString().split("T")[0];
  });

  return previousDates;
}

export function previousDaysDifference(dates) {
  const dateObjects = dates.map((date) => new Date(date));

  // Calculate the difference in days between the two input dates
  return (dateObjects[1] - dateObjects[0]) / (1000 * 60 * 60 * 24) + 1;
}

export  function calculateDateDifference(input) {
  const { startDate, endDate, key } = input;

  // Convert date strings to Date objects
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  // Calculate the difference in milliseconds
  const dateDifference = endDateObj - startDateObj;

  // Create new startDate and endDate by subtracting the date difference
  const newStartDate = new Date(startDateObj.getTime() - dateDifference);
  const newEndDate = new Date(endDateObj.getTime() - dateDifference);

  // Format the new dates as ISO strings
  

  // Return the result
  return {
    newStartDate,
    newEndDate,
    key,
  };
}


export const getSpecificDataFormat = (date) => {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear().toString().slice(-2);
  return `${day} ${month} ${year}`;
};
