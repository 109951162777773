import { createSlice } from "@reduxjs/toolkit";

const initialGenralDataState = {
    selectedDateRange: {
        
    },
    clientId: "aiekYz4hQy"


}

const genralDataSlice = createSlice({
    name: "initialGenralDataState",
    initialState: initialGenralDataState,


    reducers: {
        selectedDateRange(state, actions) {
            state.selectedDateRange = actions.payload;
        },
        selectedClientId(state, actions) {
            state.clientId = actions.payload;
        }

    },
});

//For Actions
export const genralDataActions = genralDataSlice.actions;

export default genralDataSlice;



// dispatch(genralDataActions.selectedDateRange({
//    startDate : ,
//    end date : ,
//   }))


// const dateData = useSelector((state) => state.genralDataData.selectedDateRange);