/**
 * @Note This function will convert the states into API payload format data. This is for ruleTasks.
 */

import automationDataFilterParser from "./automationDataFilterParser";

export default function automationDataParser(automationRules, ruleName,capData={}) {
  let ruleTasks = [];
  Object.entries(automationRules)?.map((automationRuleType) => {
    const currentCase = automationRuleType[0];
    // Commenting out as there is no need for this as of now if require will uncomment
    // const currentType =
    //   currentCase === "increaseCase"
    //     ? "BidIncreaseType"
    //     : currentCase === "decreaseCase"
    //     ? "BidDecreaseType"
    //     : "BidPauseType";
    const actionType =
      currentCase === "increaseCase" ? "INCREASE_BID" : currentCase === "decreaseCase" ? "DECREASE_BID": "PAUSE";
    const { rules } = automationRuleType[1];
    for (let i = 0; i < rules.length; i++) {
      const { rule_duration, filters, isDisabled } = rules[i];
      const isMatchTypeSelected = rules[i]?.isMatchTypeSelected;
      const matchTypeValues = rules[i]?.match_type_values;
      // Time Period For API remove last character which is "s" in Days
      const timePeriod = `Last_${rule_duration?.replace(/ /g, "_")}`?.slice(
        0,
        -1
      )?.toUpperCase(); 
      const bid =
        currentCase === "increaseCase"
          ? rules[i]["increase_bid"]
          : rules[i]["decrease_bid"];
      const rulesMetricData = automationDataFilterParser(filters);
      let condition = true;
      if (!rulesMetricData?.length) {
        condition = false;
      }
      if (!currentCase.includes("pauseCase") && !bid?.length && bid <= 0) {
        condition = false;
      }
      if (isDisabled) {
        condition = false;
      }
      if(isMatchTypeSelected){ 
        const isValidData = Object.values(matchTypeValues).some((value) => value?.length);
        if (!isValidData) {
          condition = false;
        }
      }
      if (condition) {
        const taskCount = ruleTasks?.length + 1;
        ruleTasks.push({
          rule_task_name: `${ruleName}_Task${taskCount}`,
          entity_metric_filter: {
            type: "AND_ONLY",
            time_period: timePeriod,
            filters: rulesMetricData,
          },
          rule_action_config: {
            entity_action_config: {
              action_type: actionType,
              apply_decision_flag: true,
              ...((currentCase !== "pauseCase" && currentCase?.includes("increase") && capData?.increase?.disabled && capData?.increase?.value>0) && {
                threshold:parseFloat(capData?.increase?.value)
              }),
              ...(currentCase !== "pauseCase" && {
                percentage_change: parseFloat(bid),
              }),
              ...((currentCase !== "pauseCase" && currentCase?.includes("decrease") && capData?.decrease?.disabled && capData?.decrease?.value>0) && {
                threshold:parseFloat(capData?.decrease?.value)
              }),
              ...((isMatchTypeSelected && currentCase !== "pauseCase") && {
                entity_type_wise_percentage_change_and_threshold:{
                  ...((matchTypeValues?.broad?.length) && {
                    broad:{
                      percentage_change: parseFloat(matchTypeValues?.broad),
                      ...((currentCase !== "pauseCase" && currentCase?.includes("decrease") && capData?.decrease?.disabled && capData?.decrease?.value>0) && {
                        threshold:parseFloat(capData?.decrease?.value)
                      }),
                      ...((currentCase !== "pauseCase" && currentCase?.includes("increase") && capData?.increase?.disabled && capData?.increase?.value>0) && {
                        threshold:parseFloat(capData?.increase?.value)
                      }),
                    },
                    
                  }),
                  ...((matchTypeValues?.phrase?.length) && {
                    phrase:{
                      percentage_change: parseFloat(matchTypeValues?.phrase),
                      ...((currentCase !== "pauseCase" && currentCase?.includes("decrease") && capData?.decrease?.disabled && capData?.decrease?.value>0) && {
                        threshold:parseFloat(capData?.decrease?.value)
                      }),
                      ...((currentCase !== "pauseCase" && currentCase?.includes("increase") && capData?.increase?.disabled && capData?.increase?.value>0) && {
                        threshold:parseFloat(capData?.increase?.value)
                      }),
                    }
                  }),
                  ...((matchTypeValues?.exact?.length) && {
                    exact:{
                      percentage_change: parseFloat(matchTypeValues?.exact),
                      ...((currentCase !== "pauseCase" && currentCase?.includes("decrease") && capData?.decrease?.disabled && capData?.decrease?.value>0) && {
                        threshold:parseFloat(capData?.decrease?.value)
                      }),
                      ...((currentCase !== "pauseCase" && currentCase?.includes("increase") && capData?.increase?.disabled && capData?.increase?.value>0) && {
                        threshold:parseFloat(capData?.increase?.value)
                      }),
                    }
                  }),
                }
              
              })
            }
          },
        });
      }
    }
  });
  return ruleTasks;
}
