export default function groupByTypeAndAdsetId(data) {
    const grouped = {};
    
    data.forEach(item => {
        const key = `${item?.type}_${item?.adset_id}`;

        if (!grouped[key]) {
            grouped[key] = {
                type: item.type,
                campaign_id: item.campaign_id,
                adset_id: item.adset_id,
            };

            if (item.type === "NEGATIVE_KEYWORD") {
                grouped[key].amz_negative_keyword_targeting = [...item.amz_negative_keyword_targeting];
            }

            if (item.type === "NEGATIVE_PRODUCT") {
                grouped[key].amz_negative_product_targeting = [...item.amz_negative_product_targeting];
            }
        } else {
            if (item.type === "NEGATIVE_KEYWORD") {
                if (!grouped[key].hasOwnProperty('amz_negative_keyword_targeting')) {
                    grouped[key].amz_negative_keyword_targeting = [];
                }
                grouped[key].amz_negative_keyword_targeting.push(...item.amz_negative_keyword_targeting);
            }

            if (item.type === "NEGATIVE_PRODUCT") {
                if (!grouped[key].hasOwnProperty('amz_negative_product_targeting')) {
                    grouped[key].amz_negative_product_targeting = [];
                }
                grouped[key].amz_negative_product_targeting.push(...item.amz_negative_product_targeting);
            }
        }
    });

    return Object.values(grouped);
}