import React from "react";
import searchIcon from "../assets/icons/search_icon.svg";
import "./searchBarForCampaignListOnly.css";
import { useSelector } from "react-redux";
// import cancelIcon from "../../assets/icons/cancelIcon.png";
import crossIcon from "../assets/icons/close_icon.png";
import { Search } from "react-bootstrap-icons";
import { useState } from "react";
const SearchBarForCampaignListOnly = (props) => {
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );

  const [isHideCrosIcon,setIsHideCorsIcon] = useState(true)

  const onClearClick = () => {
    props.onClickCancel();
    document.getElementById(props.id).value = "";
  };

  const onChangeText = (e) => {
    if(e.target.value === ""){
      setIsHideCorsIcon(true);
    }else{
      setIsHideCorsIcon(false);
    }
    if(props.onChange) {
      props.onChange(e)
    } 
  }

  return (
    <div
      className={
        props.classname
          ? props.classname + "search-bar-container-subclass"
          : "search-bar-container-subclass"
      }
    >
      <div className= { props.dynamicWidth ? `${props.dynamicWidth} search-bar-container-for-edit-campaign `: "search-bar-container-for-edit-campaign"} >
        <span
          className="search-icon-container"
        >
          <img src={searchIcon}></img>
          {/* <Search color="#4D4184" className="search-icon" /> */}
        </span>
        <span className="seacrh-input-container-for-edit-adset">
          <input
            type="text"
            className={
              !dashboardSideBarVisibiltyState
                ? "seacrh-input-field"
                : "seacrh-input-field open"
            }
             onKeyDown={props.onKeyDown}
            placeholder={props.placeholder}
            value={props.value}
            // onChange={props.onChange}
            onChange={onChangeText}
            defaultValue={props.defaultValue}
            id={props.id}
            onKeyUp={props.onKeyUp}
            ref={props?.ref}
          />
          <span  className={props.isCancelIconNotVisible || isHideCrosIcon ? "hide" : "search-close-icon-mt-container"}>
            <img
              className="search-close-icon-mt cursor-pointer-global"
              src={crossIcon}
              // onClick={props.onClickCancel}
              onClick={onClearClick}
              
            ></img>
          </span>
        </span>
      </div>
    </div>
  );
};

export default SearchBarForCampaignListOnly;
