import { rule } from "postcss";
import { summaryMapping } from "../../components/Reports/Components/utils";
import numeral from "numeral";

export function createQuadrants() {
  const quadrants = {
    id: "quadrants",
    beforeDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { left, top, right, bottom },
      } = chart;
      ctx.fillStyle = "#fff";
      // Calculate midpoints
      const midX = (left + right) / 2;
      const midY = (top + bottom) / 2;

      // Fill each quadrant with the specified color
      ctx.save();
      ctx.fillStyle = options.topLeft;
      ctx.fillRect(left, top, midX - left, midY - top);
      ctx.fillStyle = options.topRight;
      ctx.fillRect(midX, top, right - midX, midY - top);
      ctx.fillStyle = options.bottomRight;
      ctx.fillRect(midX, midY, right - midX, bottom - midY);
      ctx.fillStyle = options.bottomLeft;
      ctx.fillRect(left, midY, midX - left, bottom - midY);
      ctx.restore();
    },
  };
  return quadrants;
}

export function validateInputData(data) {
  return data?.every((condition) => {
    return (
      condition?.filterName?.toLowerCase() !== "" && condition?.value !== ""
    );
  });
}

export const operatorMapper = {
  x: {
    1: "gte",
    2: "lt",
    3: "lt",
    4: "gte",
  },
  y: {
    1: "gte",
    2: "gte",
    3: "lt",
    4: "lt",
  },
};

export const format = "0,0"; // 10,000
export const formatDecimal = "0,0.00"; // 10,000.00

export const quadrantAxisMetrics = [
  "ROAS",
  "Orders",
  "Revenue",
  "Conv. Rate",
  "Spends",
  "Clicks",
  "Impressions",
  "CTR",
  "CPM",
  "ACOS",
  "CPC",
  "AOV",
  "Selling Price",
  "Available Inventory",
  "Impressions",
"Average Order Value",
"Maximum Retail Price",
"BSR",
"Total ASP",
"Total ROAS",
"Total ACOS",
"Total Revenue",
"Total Units Sold",
];

export const metricsMapper = {
  ACOS: "acos",
  CPC: "cpc",
  CTR: "ctr",
  Spends: "cost",
  Revenue: "sales14d",
  Clicks: "clicks",
  ROAS: "roas",
  "Conv. Rate": "conversion_rate",
  AOV: "average_order_value",
  "Selling Price": "SalePrice",
  "Available Inventory": "Quantity",
  Impressions: "impressions",
  CPM: "cpm",
  Orders: "conversions14d",
  Impressions: "impressions",
  "Average Order Value": "average_order_value",
  "Maximum Retail Price": "MaximumRetailPrice",
  BSR: "BSR",
  "Total ASP": "total_asp",
  "Total ROAS": "troas",
  "Total ACOS": "tacos",
  "Total Revenue": "trevnue",
  "Total Units Sold": "tunits_sold",
};

export const getDivideData = (val1, val2) => {
  return parseFloat(val2) !== 0 ? val1 / val2 : 0;
};

export const getCalculatedValue = (key, totals) => {
  if (summaryMapping[key]) {
    const { numeratorKey, denominatorKey, multiplier, symbol, decimal } =
      summaryMapping[key];
    let numerator = parseFloat(
      getDivideData(totals[numeratorKey], totals[denominatorKey])
    );
    const result = isNaN(numerator) ? "-" : numerator * (multiplier || 1);
    let resultValue = parseFloat(result);
    if (decimal === 0) {
      resultValue = numeral(resultValue).format(format);
    } else {
      resultValue = numeral(resultValue).format(formatDecimal);
    }
    return resultValue + (symbol ? "%" : "");
  } else {
    return isNaN(totals[key]) ? "-" : numeral(totals[key]).format(format);
  }
};

export function createImage(url) {
  const img = new Image();
  img.src = url;
  img.style.filter = "grayscale(100%)";
  return img;
}

const CATALOG_API_URL = process.env.REACT_APP_CATALOG_API;
const ADS_API_URL = process.env.REACT_APP_ADS_API;

const CATEGORY_NAMES_API_URL =
  CATALOG_API_URL + "/client/${clientId}/categories?source=amazon&fields=name";

const CAMPAIGN_NAMES_API_URL =
  ADS_API_URL + "/client/${clientId}/campaigns?source=amazon&fields=name";

const PRODUCT_SET_API_URL =
  ADS_API_URL + "/client/${clientId}:product-set?source=amazon";

const PORTFOLIO_API_URL =
  ADS_API_URL + "/client/${clientId}/portfolios?source=amazon&fields=name";

export const initialFilterConfigs = [
  {
    name: "Category Names",
    default: [],
    multi: true,
    operator: "IN",
    options: [],
    key: "category_name",
    api: {
      url: CATEGORY_NAMES_API_URL,
      key: "name",
    },
    memberkey: "category",
    id: "quadrant-view-category-names",
  },
  {
    name: "Campaign Name",
    default: [],
    multi: true,
    operator: "IN",
    options: [],
    key: "campaign_name",
    api: {
      url: CAMPAIGN_NAMES_API_URL,
      key: "name",
    },
    memberkey: "campaignName",
    id: "quadrant-view-campaign-names",
  },
  {
    name: "Campaign Format",
    default: [],
    multi: true,
    operator: "IN",
    options: ["SD", "SP", "SB(V)", "SB"],
    key: "campaign_format",
    id: "quadrant-campaign-format",
    memberkey: "campaignFormat",
  },
  {
    name: "Campaign Type",
    default: [],
    multi: true,
    operator: "IN",
    options: ["Retarget", "Prospect"],
    key: "campaign_type",
    id: "quadrant-campaign-type",
    memberkey: "campaignType",
  },
  {
    name: "Targeting Type",
    default: [],
    multi: true,
    operator: "IN",
    options: [
      "G.Keyword",
      "C.Keyword",
      "NB.Keyword",
      "Audience",
      "Automatic",
      "NB.Product",
      "B.Keyword",
      "B.Audience",
      "B.Product",
    ],
    key: "targeting_type",
    id: "quadrant-targeting-type",
    memberkey: "targetingType",
  },
  {
    name: "Ad Status",
    default: [],
    multi: true,
    operator: "IN",
    options: ["enabled", "paused"],
    key: "ad_status",
    id: "quadrant-ad-status",
    memberkey: "adStatus",
  },
  {
    name: "Portfolio Name",
    default: [],
    multi: true,
    operator: "IN",
    options: [],
    key: "name",
    api: {
      url: PORTFOLIO_API_URL,
      key: "name",
    },
    memberkey: "portfolioName",
    id: "quadrant-view-portfolio-names",
  },
  {
    name: "Product Set",
    default: [],
    multi: true,
    operator: "IN",
    options: [],
    key: "product_set",
    api: {
      url: PRODUCT_SET_API_URL,
      key: "product_set",
    },
    memberkey: "productSet",
    id: "targeting-product-set",
  },
];

const nodatafoundObj = {
  acos: "0.00",
  cpc: "0.00",
  ctr: "0%",
  cost: "0",
  sales14d: "0",
  clicks: "0",
  roas: "0.00",
  conversion_rate: "0%",
  average_order_value: "0",
  impressions: "0",
  cpm: "0.00",
  conversions14d: "0",
  total_count: 0,
  no_data: true,
};

export const generateSummaryData = (data) => {
  const totals = data.reduce((acc, curr) => {
    Object.keys(curr).forEach((key) => {
      if (acc[key] === undefined) {
        acc[key] = 0;
      }
      acc[key] += curr[key];
    });
    return acc;
  }, {});
  const modifiedTotals = {};
  Object.keys(totals).forEach((key) => {
    const modifiedKey = key?.split(".")?.pop();
    modifiedTotals[modifiedKey] = totals[key];
  });
  let calculatedSummary = {};
  Object.keys(totals).forEach((key) => {
    const modifiedKey = key.split(".")?.pop();
    calculatedSummary[modifiedKey] = getCalculatedValue(
      modifiedKey,
      modifiedTotals
    );
  });
  calculatedSummary["total_count"] = data?.length ?? 0;
  // If there is no data then add manually no data found object to show on UI
  if (
    Object.keys(calculatedSummary).length === 1 &&
    calculatedSummary["total_count"] === 0
  ) {
    calculatedSummary = nodatafoundObj;
  }
  return calculatedSummary;
};

const getOperator = (quadrantNumber, axis) => {
  return operatorMapper[axis][quadrantNumber];
};

// Function to generate quadrant filters dynamically
export const generateQuadrantFilter = (
  xKeyFilter,
  yKeyFilter,
  xAxisStartValue,
  yAxisStartValue,
  quadrantNumber
) => {
  const quadrantFilter = {
    measures: {
      type: "AND_RULE",
      and: [
        {
          type: "AND_RULE",
          and: [
            {
              type: "RULE_ITEM",
              member: `VariantAnalysisTablePPMPlaceholder.${metricsMapper[xKeyFilter]}`,
              operator: getOperator(quadrantNumber, "x"),
              values: [xAxisStartValue],
            },
            {
              type: "RULE_ITEM",
              member: `VariantAnalysisTablePPMPlaceholder.${metricsMapper[yKeyFilter]}`,
              operator: getOperator(quadrantNumber, "y"),
              values: [yAxisStartValue],
            },
          ],
        },
      ],
    },
  };
  return quadrantFilter;
};

export const mergeCustomOutputToQuadrantFilters = (
  metricsCustomOutput,
  dimensionCustomOutput,
  extraFilterCustomOutput,
  quadrantFilters,
  clientAccountId
) => {
  const mergedFilters = {};


  // Merge quadrant filters into measures
  if (quadrantFilters && quadrantFilters.measures) {
    mergedFilters.measures = quadrantFilters.measures;
  }

  // Merge metrics custom output with existing rules
  if (metricsCustomOutput) {
    if (metricsCustomOutput.and && metricsCustomOutput.and.length > 0) {
      if (!mergedFilters.measures) {
        mergedFilters.measures = {
          type: "AND_RULE",
          and: metricsCustomOutput.and,
        };
      } else {
        mergedFilters.measures.and.push(...metricsCustomOutput.and);
      }
    } else if (metricsCustomOutput.or && metricsCustomOutput.or.length > 0) {
      if (!mergedFilters.measures) {
        mergedFilters.measures = {
          type: "OR_RULE",
          or: metricsCustomOutput.or,
        };
      } else {
        mergedFilters.measures.and.push({
          type: "AND_RULE",
          and: metricsCustomOutput.or,
        });
      }
    }
  }

  // Merge dimension custom output with existing rules
  if (dimensionCustomOutput) {
    if (dimensionCustomOutput.and && dimensionCustomOutput.and.length > 0) {
      mergedFilters.dimensions = {
        type: "AND_RULE",
        and: dimensionCustomOutput.and,
      };
     
    } else if (
      dimensionCustomOutput.or &&
      dimensionCustomOutput.or.length > 0
    ) {
      mergedFilters.dimensions = {
        type: "OR_RULE",
        or: dimensionCustomOutput.or,
      };
    } 
   
  }

  // Merge extra filter custom output with existing rules
  if (extraFilterCustomOutput) {
    if (extraFilterCustomOutput.and && extraFilterCustomOutput.and.length > 0) {
      mergedFilters.campaignAmazon = {
        type: "AND_RULE",
        and: extraFilterCustomOutput.and,
      };
    } else if (
      extraFilterCustomOutput.or &&
      extraFilterCustomOutput.or.length > 0
    ) {
      mergedFilters.campaignAmazon = {
        type: "OR_RULE",
        or: extraFilterCustomOutput.or,
      };
    }
  }

  return mergedFilters;
};
export const mergeCustomOutputToAPIFiltersFormat = (
  metricsCustomOutput,
  dimensionCustomOutput,
  extraFilterCustomOutput,
) => {
  const mergedFilters = {};

  // Merge metrics custom output with existing rules
  if (metricsCustomOutput) {
    if (metricsCustomOutput.and && metricsCustomOutput.and.length > 0) {
      if (!mergedFilters.measures) {
        mergedFilters.measures = {
          type: "AND_RULE",
          and: metricsCustomOutput.and,
        };
      } else {
        mergedFilters.measures.and.push(...metricsCustomOutput.and);
      }
    } else if (metricsCustomOutput.or && metricsCustomOutput.or.length > 0) {
      if (!mergedFilters.measures) {
        mergedFilters.measures = {
          type: "OR_RULE",
          or: metricsCustomOutput.or,
        };
      } else {
        mergedFilters.measures.and.push({
          type: "AND_RULE",
          and: metricsCustomOutput.or,
        });
      }
    }
  }

  // Merge dimension custom output with existing rules
  if (dimensionCustomOutput) {
    if (dimensionCustomOutput.and && dimensionCustomOutput.and.length > 0) {
      mergedFilters.dimensions = {
        type: "AND_RULE",
        and: dimensionCustomOutput.and,
      };
     
    } else if (
      dimensionCustomOutput.or &&
      dimensionCustomOutput.or.length > 0
    ) {
      mergedFilters.dimensions = {
        type: "OR_RULE",
        or: dimensionCustomOutput.or,
      };
    } 
   
  }

  // Merge extra filter custom output with existing rules
  if (extraFilterCustomOutput) {
    if (extraFilterCustomOutput.and && extraFilterCustomOutput.and.length > 0) {
      mergedFilters.campaignAmazon = {
        type: "AND_RULE",
        and: extraFilterCustomOutput.and,
      };
    } else if (
      extraFilterCustomOutput.or &&
      extraFilterCustomOutput.or.length > 0
    ) {
      mergedFilters.campaignAmazon = {
        type: "OR_RULE",
        or: extraFilterCustomOutput.or,
      };
    }
  }

  return mergedFilters;
};

export const reverseMergeAPIFiltersToFilterModuleFormat = (mergedFilters) => {
  const filterModuleFilters = {
    metrics: { custom_output: null },
    dimension: { custom_output: null },
    campaignAmazon: { custom_output: null },
  };

  // Extract metrics custom output from merged filters
  if (mergedFilters.measures) {
    const measures = mergedFilters.measures;
    if (measures.type === "AND_RULE") {
      filterModuleFilters.metrics.custom_output = {type:"AND_RULE", and: measures.and || [] };
    } else if (measures.type === "OR_RULE") {
      filterModuleFilters.metrics.custom_output = {type:"OR_RULE", or: measures.or || [] };
    }
  }

  // Extract dimension custom output from merged filters
  if (mergedFilters.dimensions) {
    const dimensions = mergedFilters.dimensions;
    if (dimensions.type === "AND_RULE") {
      filterModuleFilters.dimension.custom_output = {type:"AND_RULE", and: dimensions.and || [] };
    } else if (dimensions.type === "OR_RULE") {
      filterModuleFilters.dimension.custom_output = {type:"OR_RULE", or: dimensions.or || [] };
    }
  }

  // Extract extra filter custom output from merged filters
  if (mergedFilters.campaignAmazon) {
    const campaignAmazon = mergedFilters.campaignAmazon;
    if (campaignAmazon.type === "AND_RULE") {
      filterModuleFilters.campaignAmazon.custom_output = {type:"AND_RULE", and: campaignAmazon.and || [] };
    } else if (campaignAmazon.type === "OR_RULE") {
      filterModuleFilters.campaignAmazon.custom_output = {type:"OR_RULE", or: campaignAmazon.or || [] };
    }
  }

  return filterModuleFilters;
};

// Example usage:
// const filterModuleFilters = reverseMergeAPIFiltersToFilterModuleFormat(filters);


// default dimensions with profileId using arguments
export const defaultDimensions = (profileId, otherRuleItems = []) => {
  if (!Array.isArray(otherRuleItems) || otherRuleItems.length === 0) {
    otherRuleItems = [];
  }

  const ruleItems = [
    profileId && {
      type: "RULE_ITEM",
      member: "VariantAnalysisTablePPMPlaceholder.profileId",
      operator: "equals",
      values: [profileId],
    },
    ...otherRuleItems,
  ].filter(Boolean); // Filter out falsy values (e.g., `false`, `undefined`, or `null`)

  return ruleItems;
};

// filters ={
//   dimensions:{
//     "type": "AND_RULE",
//     "and": [
//         {
//             "type": "OR_RULE",
//             "or": [
//                 {
//                     "type": "RULE_ITEM",
//                     "member": "product_sku",
//                     "operator": "notEquals",
//                     "values": [
//                         "ww"
//                     ]
//                 },
//                 {
//                     "type": "RULE_ITEM",
//                     "member": "variant_id",
//                     "operator": "gt",
//                     "values": [
//                         "9"
//                     ]
//                 }
//             ]
//         },
//         {
//             "type": "AND_RULE",
//             "and": [
//                 {
//                     "type": "RULE_ITEM",
//                     "member": "product_category_list",
//                     "operator": "notContains",
//                     "values": [
//                         "9"
//                     ]
//                 },
//                 {
//                     "type": "RULE_ITEM",
//                     "member": "variants_count",
//                     "operator": "lte",
//                     "values": [
//                         "99"
//                     ]
//                 }
//             ]
//         },
//         // add more default dimensions here (third level)
  
//     ]
//   }
// }

// default rule_item injector function for filters
export const ruleItemInjector = (filters, key, defaultRuleItems) => {
    // Template structure for the rule to be injected
    const parsedTemplate = {
        type: "AND_RULE",
        and: defaultRuleItems, // defaultRuleItems is an array of rule items
    };

    // Check if the key exists and is a valid structure
    if (filters?.[key]) {
        if (filters?.[key]?.type === "AND_RULE" && filters?.[key]?.and) {
            // Append the new rule to the existing `and` rules
            filters[key].and = [...filters[key].and, parsedTemplate];
        } else if (filters?.[key].type === "OR_RULE" && filters?.[key]?.or) {
            // Append the new rule to the existing `or` rules
            filters[key].or = [...filters[key].or, parsedTemplate];
        } else {
            // If the key exists but doesn't have valid `and` or `or` rules
            filters[key] = {
                type: "AND_RULE",
                and: [parsedTemplate], // Wrap the existing structure and the new rule
            };
        }
    } else {
        // If the key doesn't exist, create it with the default template
        filters[key] = {type:"AND_RULE", and: [parsedTemplate]};
    }

    return filters;
};


export const generateContent = (
  totalCount,
  xFilterName,
  xFilterValue,
  yFilterName,
  yFilterValue,
  selectedAdditionalMetrics
) => {
  const content = [
    // `No. of Products: ${totalCount ?? 0}`,
    `${xFilterName}: ${xFilterValue}`,
    `${yFilterName}: ${yFilterValue}`,
  ];
  // if (additionalFilterName !== "") {
  //   content.push(`${additionalFilterName}: ${additionalFilterValue}`);
  // }
  if (selectedAdditionalMetrics?.length > 0) {
    selectedAdditionalMetrics.forEach((metric) => {
      content.push(`${metric.label}: ${metric.value}`);
    });
  }
  return content;
};
