// import "../campaignList.css";
// import "./masterAdsMain.css";
import React from "react";
import { useState, useEffect, useRef } from "react";
import "./AnalyticsReports.css";
import infoIcon from "../../assets/icons/info_icon.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Accordion from "../../components/Accordion/Accordion";
import AnalyticsList from "./AnalyticsList.json";
import TooltipRb from "../../components/Tooltip/TooltipRb";
import Breadcrumb from "../../components/Breadcrumb/ReportsBreadCrumb";

const AnalyticsReports = ({ type }) => {
  const location = useLocation();
  const { catalog, campaign } = AnalyticsList;

  const [pageName, setPageName] = useState(
    type === "catalog" ? "Product Analytics" : "Campaign Analytics"
  );
  const [reportsList, setReportsList] = useState(
    type === "catalog" ? catalog : campaign
  );

  // const reportImgObj = {'targetingReport':targetingReport,'searchTermReport':searchTermReport,'targetingGroupReport':targetingGroupReport,'portfolioReport':portfolioReport,
  //                       'productReport':productReport,'campaignReport':campaignReport,'placementFormatReport':placementFormatReport,'detailedCategoryReport':detailedCategoryReport,
  //                     'matchTypeReport':matchTypeReport,'campaignFormatReport':campaignFormatReport}

  useEffect(() => {
    setPageName(
      type === "catalog" ? "Product Analytics" : "Campaign Analytics"
    );
    setReportsList(type === "catalog" ? catalog : campaign);
  }, [type]);

  const [isExpanded, setIsExpanded] = useState(null);

  const toggleAccordion = (index) => {
    setIsExpanded((prev) => (prev === index ? null : index));
  };

  return (
    <div className="main-content-container-oncolapse-mode">
      <div className="campaign-list-container overflowyauto">
        <Breadcrumb />
        <div className="page-main-tit">{pageName}</div>
        <div className="d-flex  justify-content-evenly flex-wrap mb-4">
          {reportsList.map((item, index) => (
            <div className="d-flex align-self-stretch">
              <div
                className="d-flex flex-column m-2 align-self-stretch"
                key={index}
              >
                <div
                  className="white-box-shedow cursor-pointer flex-grow-1 d-flex"
                  onClick={() => {
                    window.open(item.url);
                  }}
                >
                  <div className="d-flex flex-column gap-3 flex-grow-1">
                    <div className="page-sub-tit d-flex">{item.name}</div>
                    <div className="">
                      {item.img_path !== "" ? (
                        <div className="">
                          <img
                            src={item.img_path}
                            alt={item.name}
                            className="responsive-image reportPageImages"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="page-list-desc h-100">
                      {item.description}
                    </div>
                    <button className="view-report-btn">View Report</button>
                  </div>
                </div>
                <div className="top-shedow position-relative">
                  <div className="white-box-shedow ">
                    <div className="accodian-main-box ">
                      <div
                        onClick={() => toggleAccordion(index)}
                        className="accordian-tit"
                      >
                        <h3>Highlights</h3>
                        <span
                          className={isExpanded === index ? "rotated" : ""}
                        ></span>
                      </div>
                      {isExpanded === index && (
                        <ul className="white-box-shedow highlightBox position-absolute w-100">
                          {item.highlight.map((item, index) => (
                            <li key={index}> {item}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnalyticsReports;
