import moment from "moment";

export const  multiCalendarDateRange = {
    selection1: {
      startDate: moment().subtract(7, "days")._d,
      endDate: moment().subtract(1,"days")._d,
      key: "selection1",
    },
    selection2: {
        startDate: moment().subtract(14, "days")._d,
        endDate: moment().subtract(8, "days")._d,
        key: "selection2",
    }
}