import { COLORS_SERIES, PALE_COLORS_SERIES } from "./utils";
import { Line } from "react-chartjs-2";

export const CustomLineChartRenderer = ({ resultSet }) => {
  const options = {
    responsive: true,
    plugins: {
      datalabels: { // This code is used to display data values
        display: false
       },
      legend: {
        position: "top",
        labels: {
          usePointStyle: true, // Use point style (circles) for legend items
          boxWidth:6,
          boxHeight:6,
          borderRadius : 0,
          fontSize:6
        },
      },
    },
  };
  const labels = resultSet.labels;
  const color =
    COLORS_SERIES[
      Math.floor(Math.random() * (PALE_COLORS_SERIES.length + 0.999999))
    ];
  const data = {
    labels,
    datasets: [
      {
        label: resultSet.label,
        data: resultSet.series,
        borderColor: color,
        backgroundColor: color,
      },
    ],
  };

  return <Line type="line" data={data} options={options} />;
};
