import Form from 'react-bootstrap/Form';
import "./switchBtn.css";

function SwitchBtn(props) {

  return <div className={props.className ? props.className : ""}>
    <Form className={props.className ? props.className + " switch-btn-container" : "switch-btn-container"}>
      <Form.Check className={`form-switch form-check ${props.classNameForFormSwitch ? props.classNameForFormSwitch:``}`} >
        <div className="label-container">
          <label className="form-check-label" htmlFor={props.htmlFor}>{props.label}</label>
        </div>
        <div className="input-container">
          <input className="form-check-input" type="checkbox" defaultChecked={props.defaultChecked} checked={props?.checked} role="switch" id={props.id} name={props.name} onChange={props.onChange} disabled={props.disabled}></input>
        </div>
      </Form.Check>
    </Form>
  </div>


}

export default SwitchBtn;