import React from "react";
import { RotatingLines } from "react-loader-spinner";

const LazyLoadSpinner = ({visible,text}) => {
  const LoaderColor = "#C7664E";

  return (
    <div className="d-flex align-items-center justify-content-center gap-2">
      <RotatingLines
        strokeColor={LoaderColor}
        strokeWidth="3"
        animationDuration="0.50"
        width="50"
        visible={true}
      />
      <p  className="lazy-load-text mt-1">{text}</p>
    </div>
  );
};

export default LazyLoadSpinner;
