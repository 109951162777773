import { useState, useEffect, useRef } from "react";
import "./csvUpload.css";
import { useDispatch, useSelector } from "react-redux";
import { campaignListActions } from "../../../../redux/slices/campaignList.js";
import Modal from "react-bootstrap/Modal";
import closeIcon from "../../../../assets/svgs/close.svg";
import csvIcon from "../../../../assets/icons/csv-upload.svg";
import upload_file_black from "../../../../assets/icons/upload_file_black.svg";
import gmarkIcon from "../../../../assets/icons/Mask.svg";
import Papa from "papaparse";
import { masterApi } from "../../../../apis/masterApi";
import {
  TOAST_SUCCESS,
  TOAST_DANGER,
  TOAST_POSITION_TOP_RIGHT,
} from "../../../../constant/HardTypes";
import { useGlobalContext } from "../../../../context/global";
import MasterTagetinngActionAlertMModal from "../../../../components/modal/masterTargetinngActionnAlertMModal/masterTargetingActionnAlertModal";
import {toast} from 'react-toastify';
import {allConfirmationButtonsIds} from '../../../../constant/confirmActions.js';

export default function CSVUploadModal(props) {
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const { showAlert } = useGlobalContext();
  const clientId = JSON.parse(localStorage.getItem("clientId"));

  const [selectedOption, setSelectedOption] = useState('Enable');
  const [uploadedDataList, setUplodedDataList] = useState([]);

  const [showAlertMsgOf, setShowAlertMsgOf] = useState("");
  const confirmationId = allConfirmationButtonsIds['product-ads'][showAlertMsgOf] ?? 'bulk-enable-pause-ads-csv'
  

  const [fileName, setFileName] = useState("")

  const [isHovered, setIsHovered] = useState(false);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleClick = () => {
    const inputElement = document.createElement("input");
    inputElement.type = "file";
    inputElement.accept = ".csv";
    inputElement.style.display = "none";

    inputElement.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        handleFileUpload(file);
      }
    };

    document.body.appendChild(inputElement);
    inputElement.click();
    document.body.removeChild(inputElement);
  };

  const handleFileUpload = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setFileName(file.name);
        let asinValues = results.data.map((data, index) => {
          return Object.values(data)[0];
        });
        setUplodedDataList(asinValues);
      },
    });
  };


  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setFileName(event.target.files[0].name)
        let asinValues = results.data.map((data, index) => {
          return Object.values(data)[0]
        });
        setUplodedDataList(asinValues)
        hiddenFileInput.current.value = "";
      },
    });
  };

  const confirmClick = () => {
    if (uploadedDataList.length > 0) {
      dispatch(campaignListActions.showActionAlertModal());
      setShowAlertMsgOf("Are you sure you want to " + (selectedOption) + " uploaded asins?");
    } else {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
      //   backgroundColor: TOAST_DANGER,
      //   title: "Error",
      //   description: "No data to upload",
      // });
      toast.error('No data to upload')
    }
  }

  const saveClick = async () => {
    dispatch(campaignListActions.hideActionAlertModal())
    const data = {
      status: selectedOption === 'Enable' ? 'enabled' : 'paused',
      asins: uploadedDataList
    };
    const result = await masterApi(data, "uploadasinsstatus", false, "post");
    if (result?.statusCode !== 200) {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
      //   backgroundColor: TOAST_DANGER,
      //   title: "Success",
      //   description: "Error While upload asin",
      // });
      toast.error('Error While Uploading asins')
    } else {
      dispatch(campaignListActions.hideCsvUploadInGlobalAd());
      // showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
      //   backgroundColor: TOAST_SUCCESS,
      //   title: "Success",
      //   description: "Upload data successfully",
      // });
      toast.success('Data uploaded successfully!')
    }
  };

  const NoClickHandler = async () => {
    dispatch(campaignListActions.hideActionAlertModal())
  };

  const handleClose = () => {
    dispatch(campaignListActions.hideCsvUploadInGlobalAd());
  };

  const csvUploadModalInGlobalAd = useSelector(
    (state) => state.campaignListData.csvUploadModalInGlobalAd
  );

  const actionAlertModalVisibilty = useSelector(
    (state) => state.campaignListData.actionAlertModalVisibilty
  );

  useEffect(() => {
    setUplodedDataList([])
    setShowAlertMsgOf("")
    setFileName("")
    dispatch(campaignListActions.hideActionAlertModal());
  }, [csvUploadModalInGlobalAd])

  let tableBody2 = (
    <>
      <div>
        {showAlertMsgOf !== "" &&
          <MasterTagetinngActionAlertMModal
            // onYesClick={actioAlertYesClickhandler}
            alertText={showAlertMsgOf}
            alertInfo={"*SB ads not supported at the moment"}
            onYesClick={saveClick}
            onNoClick={NoClickHandler}
            confirmationId={confirmationId ?? props?.confirmationId}
          ></MasterTagetinngActionAlertMModal>
        }
      </div>

      <div style={{ display: actionAlertModalVisibilty ? 'none' : 'block' }}>
        <div className="p-4 pb-0">
          <div className="d-flex justify-content-between align-items-baseline">
            {/* <center>
              {" "}
              <h4 style={{ marginTop: "0px" }}>Bulk Actions</h4>
            </center>
            <img style={{ cursor: 'pointer' }} onClick={handleClose} src={closeIcon}></img> */}
          </div>
          <div className="csv-upload-modal-two-box-container" >
            <div>
              <div style={{ marginBottom: '1.5rem' }}>
                <div className="row">
                  <div className="col-md-6" style={{ textAlign: "left" }}>
                    <label style={{ marginLeft: '0rem' }}>
                      <input
                        type="radio"
                        value="Enable"
                        style={{ marginRight: '10px' }}
                        checked={selectedOption === 'Enable'}
                        onChange={handleOptionChange}
                      />
                      Bulk Enable
                    </label>
                  </div>
                  <div className="col-md-6 text-left" style={{ textAlign: "left" }}>
                    <label style={{ marginLeft: '0rem', paddingRight: '1rem' }}>
                      <input
                        type="radio"
                        value="Pause"
                        style={{ marginRight: '10px' }}
                        checked={selectedOption === 'Pause'}
                        onChange={handleOptionChange}
                      />
                      Bulk Pause
                    </label>
                  </div>
                </div>



              </div>
              {
                fileName === "" &&
                <>
                  <div className="brd_upload_icn">
                    <label onClick={handleClick}>
                      <img src={upload_file_black} alt="upload" style={{ height: '26px', width: '26px', marginRight: "10px" }} />
                      <span>Upload CSV</span>
                    </label>

                    <input
                      type="file"
                      name="file"
                      accept=".csv"
                      ref={hiddenFileInput}
                      // style={{ display: "none" }}
                      style={{ position: "absolute", left: "-9999px" }}
                      onChange={changeHandler}
                    />
                  </div>
                </>
              }
              {fileName !== "" &&
                <div className="upload_data_box">
                  <img src={gmarkIcon} alt="upload" style={{ height: '20px', width: '20px', marginRight: "10px" }} />
                  <img src={csvIcon} alt="upload" style={{ height: '32px', width: '32px', marginRight: "10px" }} />
                  <span className="limited_txt">{fileName}</span>
                  <a className="link" style={{ cursor: 'pointer' }} onClick={handleClick}>
                    <>Change</>
                  </a>
                </div>
              }
              <label>
                {uploadedDataList.length > 0 && (
                  <>
                    {uploadedDataList.length} ASIN{uploadedDataList.length > 1 ? 's' : ''} are uploaded successfully!
                  </>
                )}
              </label>
            </div>

          </div>


        </div>
        <div className="box_shedow_special"></div>
        <div className="d-flex justify-content-between gap-10 p-4 pt-0">
          <div className="d-flex">
            {/* <button
                type="button"
                className="primary-btn btn btn-primary px-4"
                onClick={selectAllHandler}
              >
                Select All
              </button> */}
          </div>
          <div className="d-flex">
            <button
              type="button"
              onClick={handleClose}
              className="primary-outline-btn btn btn-outline-primary px-5 mx-2"
            >
              Cancel
            </button>
            <button
              type="button"
              id={props?.confirmationId}
              className="primary-btn btn btn-primary px-5"
              onClick={confirmClick}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="csv-upload-modal">
      <div className="filetr-modal-main-container">
        <Modal
          show={props.show}
          // dialogClassName="modal-90w"
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          // contentClassName="csv-upload-content-class"
          contentClassName="main-modal-className-for-csv-upload"
          bsPrefix="modal"
          dialogClassName="csv-upload-dialog"
        >
          <Modal.Body>{tableBody2}</Modal.Body>
        </Modal>
      </div>
    </div>

  );
}
