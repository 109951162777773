import { createSlice } from "@reduxjs/toolkit";

const initialCampaignListtate = {
  getCampaignListCampaigns: [],
  editBtnclickedCampaignDetailsForEditTargetingTable: [],
  // actiontoEditCollectivebid: {},
  actionEditCollectiveBidForMasterTargeting: {},
  bulkEditBidModalVisibilty: false,
  targetingTypeForEditTargetSection: "",
  actionAlertModalVisibilty: false,
  getUpdatedConfigHeadsForCampaignListSection: [
    {
      fieldName: "STATUS",
      minRange: "-",
      maxRange: "-",
      id: "status",
      isDefaultHead: true,
      isAbbrivation: false, 
    },
    {
      fieldName: "NAME",
      minRange: "-",
      maxRange: "-",
      id: "name",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "Categories",
      minRange: "-",
      maxRange: "-",
      id: "categories",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "TARGETING",
      minRange: "-",
      maxRange: "-",
      id: "targeting",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "PORTFOLIO",
      minRange: "-",
      maxRange: 44358,
      id: "portfolio",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "BUDGET",
      minRange: "-",
      maxRange: "-",
      id: "budget",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "BID STARETEGY",
      minRange: "-",
      maxRange: "-",
      id: "bid-strategy",
      isDefaultHead: true,
      isAbbrivation: false,
    },
    {
      fieldName: "SPENDS",
      minRange: 0,
      maxRange: 0,
      id: "spends",
      status: false,
      isHeadRightAlign : true,
      isHeadWithIcon : "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "DBR",
      minRange: 0,
      maxRange: 0,
      id: "dbr",
      status: false,
      isHeadRightAlign : true,
      isHeadWithIcon : "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "Avg. BR",
      minRange: 0,
      maxRange: 0,
      id: "campaign_burn_rate",
      status: false,
      isHeadRightAlign : true,
      isHeadWithIcon : "sort",
      isAbbrivation: true,
    },
    {
      fieldName: "ORDERS",
      minRange: 0,
      maxRange: 0,
      id: "orders",
      status: false,
      isHeadRightAlign : true,
      isHeadWithIcon : "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "REVENUE",
      minRange: 0,
      maxRange: 0,
      id: "sale_value",
      status: false,
      isHeadRightAlign : true,
      isHeadWithIcon : "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "ROAS",
      minRange: 0,
      maxRange: 0,
      id: "roas",
      status: false,
      isHeadRightAlign : true,
      isHeadWithIcon : "sort",
      isAbbrivation: true,
    },
  ],
  getUpdatedConfigHeadsForEditAdsetTargetListSection: [
    {
      fieldName: "ACTION",
      minRange: "-",
      maxRange: "-",
      id: "status",
      isDefaultHead: true,
      isHeadRightAlign : false,
      isAbbrivation: false,
 
    },
    {
      fieldName: "TARGETING",
      minRange: "-",
      maxRange: "-",
      id: "name",
      isDefaultHead: true,
      isHeadRightAlign : false,
      isAbbrivation: false,
    },
    {
      fieldName: "Match Type",
      minRange: "-",
      maxRange: "-",
      id: "targetting",
      isDefaultHead: true,
      isHeadRightAlign : false,
      isAbbrivation: false,
    },
    {
      fieldName: "SUGGESTED BID",
      minRange: "-",
      maxRange: "-",
      id: "portfolio",
      isDefaultHead: true,
      isHeadRightAlign : false,
      isAbbrivation: false,
    },
    {
      fieldName: "CURRENT BID",
      minRange: "-",
      maxRange: "-",
      id: "budget",
      isDefaultHead: true,
      isHeadRightAlign : false,
      isAbbrivation: false,
    },
    {
      fieldName: "SPENDS",
      minRange: 0,
      maxRange: 0,
      id: "spends",
      status: false,
      isHeadRightAlign : true,
      isHeadWithIcon : "sort",
      isAbbrivation: false,


    },
    {
      fieldName: "ORDERS",
      minRange: 0,
      maxRange: 0,
      id: "orders",
      status: false,
      isHeadRightAlign : true,
      isHeadWithIcon : "sort",
      isAbbrivation: false,

    },
    {
      fieldName: "REVENUE",
      minRange: 0,
      maxRange: 0,
      id: "sale_value",
      status: false,
      isHeadRightAlign : true,
      isHeadWithIcon : "sort",
      isAbbrivation: false,
  
    },
    {
      fieldName: "ROAS",
      minRange: 0,
      maxRange: 0,
      id: "roas",
      status: false,
      isHeadRightAlign : true,
      isHeadWithIcon : "sort",
      isAbbrivation: true,
    },
  ],
  getUpdatedConfigHeadsForEditAdsetProductTargetListSection: [
    {
      fieldName: "ACTION",
      minRange: "-",
      maxRange: "-",
      id: "status",
      isDefaultHead: true,
      isHeadRightAlign : false,
      isAbbrivation: false,
 

    },
    {
      fieldName: "PRODUCT",
      minRange: "-",
      maxRange: "-",
      id: "name",
      isDefaultHead: true,
      isHeadRightAlign : false,
    },
    {
      fieldName: "ASIN",
      minRange: "-",
      maxRange: "-",
      id: "asian",
      isDefaultHead: true,
      isHeadRightAlign : false,
      isAbbrivation: true,
    },
    {
      fieldName: "SUGGESTED BID",
      minRange: "-",
      maxRange: "-",
      id: "suggested_bid",
      isDefaultHead: true,
      isHeadRightAlign : false,
      isAbbrivation: false,
    },
    {
      fieldName: "CURRENT BID",
      minRange: "-",
      maxRange: "-",
      id: "current-bid",
      isDefaultHead: true,
      isHeadRightAlign : false,
      isAbbrivation: false,
    },
    {
      fieldName: "SPENDS",
      minRange: 0,
      maxRange: 0,
      id: "spends",
      status: false,
      isHeadRightAlign : true,
      isAbbrivation: false,
      isHeadWithIcon : "sort",
    },
    {
      fieldName: "ORDERS",
      minRange: 0,
      maxRange: 0,
      id: "orders",
      status: false,
      isHeadRightAlign : true,
      isAbbrivation: false,
      isHeadWithIcon : "sort",
    },
    {
      fieldName: "REVENUE",
      minRange: 0,
      maxRange: 0,
      id: "sale_value",
      status: false,
      isHeadRightAlign : true,
      isAbbrivation: false,
      isHeadWithIcon : "sort",
    },
    {
      fieldName: "ROAS",
      minRange: 0,
      maxRange: 0,
      id: "roas",
      status: false,
      isHeadRightAlign : true,
      isAbbrivation: true,
      isHeadWithIcon : "sort",
    },
  ],
  getUpdatedConfigHeadsForEditAdsSection: [
    {
      fieldName: "ACTION",
      minRange: "-",
      maxRange: "-",
      id: "status",
      isDefaultHead: true,
      isHeadRightAlign : false,
      isAbbrivation: false,
    },
    {
      fieldName: "PRODUCT TARGETING",
      minRange: "-",
      maxRange: "-",
      id: "name",
      isDefaultHead: true,
      isHeadRightAlign : false,
      isAbbrivation: false,
    },
    {
      fieldName: "ASIN's",
      minRange: "-",
      maxRange: "-",
      id: "targetting",
      isDefaultHead: true,
      isHeadRightAlign : false,
      isAbbrivation: false,
    },
    {
      fieldName: "SPENDS",
      minRange: 0,
      maxRange: 0,
      id: "spends",
      status: false,
      isHeadRightAlign : true,
      isAbbrivation: false,
      isHeadWithIcon : "sort",
    },
    {
      fieldName: "ORDERS",
      minRange: 0,
      maxRange: 0,
      id: "orders",
      status: false,
      isHeadRightAlign : true,
      isAbbrivation: false,
      isHeadWithIcon : "sort",
    },
    {
      fieldName: "REVENUE",
      minRange: 0,
      maxRange: 0,
      id: "sale_value",
      status: false,
      isHeadRightAlign : true,
      isAbbrivation: false,
      isHeadWithIcon : "sort",
    },
    {
      fieldName: "ROAS",
      minRange: 0,
      maxRange: 0,
      id: "roas",
      status: false,
      isHeadRightAlign : true,
      isAbbrivation: true,
      isHeadWithIcon : "sort",
    },
  ],
  getUpdatedConfigHeadsForEditSearchTermListSection: [
    {
      fieldName: "NEGATIVE TARGETING",
      minRange: "-",
      maxRange: "-",
      id: "status",
      isDefaultHead: true,
      isHeadRightAlign : false,
      isAbbrivation: false,
    },
    {
      fieldName: "TARGETING",
      minRange: "-",
      maxRange: "-",
      id: "name",
      isDefaultHead: true,
      isHeadRightAlign : false,
      isAbbrivation: false,
    },
    // {
    //     fieldName: "Match Type",
    //     minRange: '-',
    //     maxRange: '-',
    //     id: "targetting",
    //     isDefaultHead: true
    // },
    {
      fieldName: "SPENDS",
      minRange: 0,
      maxRange: 0,
      id: "spends",
      status: false,
      isHeadRightAlign : true,
      isHeadWithIcon : "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "ORDERS",
      minRange: 0,
      maxRange: 0,
      id: "orders",
      status: false,
      isHeadRightAlign : true,
      isHeadWithIcon : "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "REVENUE",
      minRange: 0,
      maxRange: 0,
      id: "sale_value",
      status: false,
      isHeadRightAlign : true,
      isHeadWithIcon : "sort",
      isAbbrivation: false,
    },
    {
      fieldName: "ROAS",
      minRange: 0,
      maxRange: 0,
      id: "roas",
      status: false,
      isHeadRightAlign : true,
      isHeadWithIcon : "sort",
      isAbbrivation: true,
    },
  ],
  getUpdatedConfigHeadsForMasterTargetingListSection: [
    {
      fieldName: "ACTION",
      minRange: "-",
      maxRange: "-",
      id: "status",
      isDefaultHead: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "TARGETING SELECTED",
      minRange: "-",
      maxRange: "-",
      id: "name",
      isDefaultHead: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "TARGETING TYPE",
      minRange: "-",
      maxRange: "-",
      id: "targetinng_type",
      isDefaultHead: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "PORTFOLIO",
      minRange: "-",
      maxRange: "-",
      id: "mt_portfolio",
      isDefaultHead: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "CAMPAIGNS",
      minRange: "-",
      maxRange: "-",
      id: "mt_campaign_name",
      isDefaultHead: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "AD GROUP",
      minRange: "-",
      maxRange: "-",
      isDefaultHead: true,
      id: "mt_adgroup_name",
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "SUGG BID",
      minRange: "-",
      maxRange: "-",
      id: "mt_sugg_bid",
      isDefaultHead: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "CURRENT BID",
      minRange: "-",
      maxRange: "-",
      id: "mt_currennt_bid",
      isDefaultHead: true,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "SPENDS",
      minRange: 0,
      maxRange: 0,
      id: "spends",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: false,
      isRsSymbolNeeded: true,
    },
    {
      fieldName: "ORDERS",
      minRange: 0,
      maxRange: 0,
      id: "orders",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: false,
    },
    {
      fieldName: "REVENUE",
      minRange: 0,
      maxRange: 0,
      id: "sale_value",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: false,
      isRsSymbolNeeded: true,
    },
    {
      fieldName: "ROAS",
      minRange: 0,
      maxRange: 0,
      id: "roas",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isSortingClicked : false,
      isTwoDecimalPlacesNeeded:true

    },
  ],
  getUpdatedConfigHeadsForMasterAdsSection:[
    {
      fieldName: "ACTION",
      minRange: "-",
      maxRange: "-",
      id: "status",
      isDefaultHead: true,
      isSortingClicked : false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Product",
      minRange: "-",
      maxRange: "-",
      id: "product_name",
      isDefaultHead: true,
      isSortingClicked : false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "ASIN",
      minRange: "-",
      maxRange: "-",
      id: "asin",
      isDefaultHead: true,
      isSortingClicked : false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Portfolio",
      minRange: "-",
      maxRange: "-",
      id: "portfolio",
      isDefaultHead: true,
      isSortingClicked : false,
      isFindTaskActive: false,
      isSearchNeededHere:true,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Campaign",
      minRange: "-",
      maxRange: "-",
      id: "mt_campaign_name",
      isDefaultHead: true,
      isSortingClicked : null,
      isFindTaskActive: false,
      isSearchNeededHere:true,
      isSortFunctionalityNeededHere: false,

    },
    {
      fieldName: "Ad Group",
      minRange: "-",
      maxRange: "-",
      isDefaultHead: true,
      id: "mt_adgroup_name",
      isSortingClicked : null,
      isFindTaskActive: false,
      isSearchNeededHere:true,
      isSortFunctionalityNeededHere: false,
      
    },
    {
      fieldName: "Category",
      minRange: "-",
      maxRange: "-",
      isDefaultHead: true,
      id: "mt_category_name",
      isSortingClicked : null,
      isFindTaskActive: false,
      isSearchNeededHere:true,
      isSortFunctionalityNeededHere: false,
      
    },
    {
      fieldName: "SPENDS",
      minRange: 0,
      maxRange: 0,
      id: "spends",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isSortingClicked : false,

    },
    {
      fieldName: "ORDERS",
      minRange: 0,
      maxRange: 0,
      id: "orders",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isSortingClicked : false,

    },
    {
      fieldName: "REVENUE",
      minRange: 0,
      maxRange: 0,
      id: "sale_value",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isSortingClicked : false,

    },
    {
      fieldName: "ROAS",
      minRange: 0,
      maxRange: 0,
      id: "roas",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: true,
    },
  ],
  getUpdatedConfigHeadsForMasterSearchTermSection:[
    {
      fieldName: "Search Term",
      minRange: "-",
      maxRange: "-",
      id: "search_term",
      isDefaultHead: true,
      isSortingClicked : false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Targeting",
      minRange: "-",
      maxRange: "-",
      id: "text",
      isDefaultHead: true,
      isSortingClicked : false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Targeting Type",
      minRange: "-",
      maxRange: "-",
      id: "targeting",
      isDefaultHead: true,
      isSortingClicked : false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Match Type",
      minRange: "-",
      maxRange: "-",
      id: "match_type",
      isDefaultHead: true,
      isSortingClicked : false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Portfolio",
      minRange: "-",
      maxRange: "-",
      id: "portfolio",
      isDefaultHead: true,
      isSortingClicked : false,
      isFindTaskActive: false,
      isSearchNeededHere:true,
      isSortFunctionalityNeededHere: false,
    },
    {
      fieldName: "Campaign",
      minRange: "-",
      maxRange: "-",
      id: "mt_campaign_name",
      isDefaultHead: true,
      isSortingClicked : null,
      isFindTaskActive: false,
      isSearchNeededHere:true,
      isSortFunctionalityNeededHere: false,

    },
    {
      fieldName: "Ad Group",
      minRange: "-",
      maxRange: "-",
      isDefaultHead: true,
      id: "mt_adgroup_name",
      isSortingClicked : null,
      isFindTaskActive: false,
      isSearchNeededHere:true,
      isSortFunctionalityNeededHere: false,
      
    },
    {
      fieldName: "SPENDS",
      minRange: 0,
      maxRange: 0,
      id: "spends",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isSortingClicked : false,

    },
    {
      fieldName: "ORDERS",
      minRange: 0,
      maxRange: 0,
      id: "orders",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isSortingClicked : false,

    },
    {
      fieldName: "REVENUE",
      minRange: 0,
      maxRange: 0,
      id: "sale_value",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isSortingClicked : false,

    },
    {
      fieldName: "ROAS",
      minRange: 0,
      maxRange: 0,
      id: "roas",
      status: false,
      isFindTaskActive: false,
      isSortFunctionalityNeededHere: true,
      isTwoDecimalPlacesNeeded: true,
    },
  ],
  getUpdatedConfigHeadsForSmartAdsList:[
    {
      "fieldName": "Product Set",
      "minRange": "-",
      "maxRange": "-",
      "id": "product_set",
      "isDefaultHead": true,
      "isHeadRightAlign": false,
      "isHeadWithIcon": false,
      "isAbbrivation": false
  },
  // {
  //     "fieldName": "Active Campaigns",
  //     "minRange": "-",
  //     "maxRange": "-",
  //     "id": "active_campaigns",
  //     // "isDefaultHead": true,
  //     "isHeadRightAlign": false,
  //     "isHeadWithIcon": "sort",
  //     "isAbbrivation": false,
  //     "isFindTaskActive": false,
  //     "status": false,
  //     "isSortFunctionalityNeededHere": true

  // },
  // {
  //     "fieldName": "Active AdGroups",
  //     "minRange": "-",
  //     "maxRange": "-",
  //     "id": "active_adgroups",
  //     // "isDefaultHead": true,
  //     "isHeadRightAlign": false,
  //     "isHeadWithIcon": "sort",
  //     "isAbbrivation": false,
  //     "isFindTaskActive": false,
  //     "status": false,
  //     "isSortFunctionalityNeededHere": true

  // },
  {
      "fieldName": "Refresh Frequency",
      "minRange": "-",
      "maxRange": "-",
      "id": "refresh_frequency",
      // "isDefaultHead": true,
      "isHeadRightAlign": false,
      "isHeadWithIcon": false,
      "isAbbrivation": false,
      "isFindTaskActive": false,
      "status": false,
      "isSortFunctionalityNeededHere": true

  },
  {
      "fieldName": "Type",
      "minRange": "-",
      "maxRange": "-",
      "id": "type",
      // "isDefaultHead": true,
      "isHeadRightAlign": false,
      "isHeadWithIcon": false,
      "isAbbrivation": false,
      "isSortFunctionalityNeededHere": false

  },

  {
      "fieldName": "Spends",
      "minRange": "-",
      "maxRange": "-",
      "id": "spends",
      // "isDefaultHead": true,
      "isHeadRightAlign": true,
      "isHeadWithIcon": "sort",
      "isAbbrivation": true,
      "isSortFunctionalityNeededHere": true

  },
  {
      "fieldName": "Revenue",
      "minRange": "-",
      "maxRange": "-",
      "id": "revenue",
      // "isDefaultHead": true,
      "isHeadRightAlign": true,
      "isHeadWithIcon": "sort",
      "isAbbrivation": true,
      "isSortFunctionalityNeededHere": true

  },
  {
      "fieldName": "Orders",
      "minRange": "-",
      "maxRange": "-",
      "id": "orders",
      // "isDefaultHead": true,
      "isHeadRightAlign": true,
      "isHeadWithIcon": "sort",
      "isAbbrivation": true,
      "isSortFunctionalityNeededHere": true

  },
  {
      "fieldName": "ROAS",
      "minRange": "-",
      "maxRange": "-",
      "id": "roas",
      // "isDefaultHead": true,
      "isHeadRightAlign": true,
      "isHeadWithIcon": "sort",
      "isAbbrivation": true,
      "isSortFunctionalityNeededHere": true
  },
  ],

  editCampaignMainPageVisibilty: false,
  appliedFilterData: [],
  filterModalInCampaignListVisibilty: false,
  csvUploadModalInGlobalAd:false,
  // targetCheckedForBulkEdit: [],
  checkedTargetDataForBulEdit: [],
  editAdGroupSectionCurrentselectedBtnName: "",
  getSelectedAdsetCreativesData: {},
};
const campaignListSlice = createSlice({
  name: "CampaignListDataHandle",
  initialState: initialCampaignListtate,
  reducers: {
    getCampaignListCampaigns(state, action) {
      state.getCampaignListCampaigns = action.payload;
    },
    editBtnclickedCampaignDetailsForEditTargetingTable(state, action) {
      state.editBtnclickedCampaignDetailsForEditTargetingTable = action.payload;
    },
    hideEditCmapignMainPageOnGobackBtnClikced(state, action) {
      state.editCampaignMainPageVisibilty = false;
    },
    ShowEditCmapignMainPageOnGobackBtnClikced(state, action) {
      state.editCampaignMainPageVisibilty = true;
    },
    hideFilterModalInCampaignList(state, action) {
      state.filterModalInCampaignListVisibilty = !state.filterModalInCampaignListVisibilty;
    },
    showFilterModalInCampaignList(state, action) {
      state.filterModalInCampaignListVisibilty = true;
    },

    hideCsvUploadInGlobalAd(state, action) {
      state.csvUploadModalInGlobalAd = !state.csvUploadModalInGlobalAd;
    },
    showUploadInGlobalAd(state, action) {
      state.csvUploadModalInGlobalAd = true;
    },

    // getUpdatedConfigHeadsForCampaignListSection(state, action) {
    //     for (let index = 0; index < action.payload.length; index++) {
    //         state.getUpdatedConfigHeadsForCampaignListSection.push([index])

    //     }
    getUpdatedConfigHeadsForCampaignListSection(state, action) {
      state.getUpdatedConfigHeadsForCampaignListSection = action.payload;
    },
    appliedFilterData(state, action) {
      state.appliedFilterData = action.payload;
    },
    getUpdatedConfigHeadsForEditAdsetTargetListSection(state, action) {
      state.getUpdatedConfigHeadsForEditAdsetTargetListSection = action.payload;
    },
    getUpdatedConfigHeadsForEditAdsetProductTargetListSection(state, action) {
      state.getUpdatedConfigHeadsForEditAdsetProductTargetListSection =
        action.payload;
    },
    getUpdatedConfigHeadsForMasterTargetingListSection(state, action) {
      state.getUpdatedConfigHeadsForMasterTargetingListSection = action.payload;
    },
    getUpdatedConfigHeadsForEditAdsSection(state, action) {
      state.getUpdatedConfigHeadsForEditAdsSection = action.payload;
    },
    getUpdatedConfigHeadsForEditSearchTermListSection(state, action) {
      state.getUpdatedConfigHeadsForEditSearchTermListSection = action.payload;
    },
    getUpdatedConfigHeadsForMasterAdsSection(state,action) {
      state.getUpdatedConfigHeadsForMasterAdsSection = action.payload;
    },
    getUpdatedConfigHeadsForMasterSearchTermSection(state,action) {
      state.getUpdatedConfigHeadsForMasterSearchTermSection = action.payload
    },
    getUpdatedConfigHeadsForSmartAdsList(state,action)  {
      state.getUpdatedConfigHeadsForSmartAdsList = action.payload
    },
    updateConfigHeadsStatus(state,action) {
      const {payload} = action
      const prevData = state.getUpdatedConfigHeadsForMasterSearchTermSection
      let updatedData = [];
      const prevDataIds = prevData?.map((data) => data?.id);
       payload?.forEach((head) => {
        if(!prevDataIds?.includes(head.field_key)) {
          updatedData.push({
            fieldName: head?.field_name,
            minRange: "-",
            maxRange: "-",
            id: head?.field_key,
            isDefaultHead: false,
            status: false,
            isFindTaskActive: false,
            isSortFunctionalityNeededHere: true,
            isSortingClicked : false,
            })
        }
      })
      state.getUpdatedConfigHeadsForMasterSearchTermSection = [...prevData,...updatedData]
    },
    removeConfigHeadStatus(state,action) {
      const {payload} = action;
      const prevData = state.getUpdatedConfigHeadsForMasterSearchTermSection;
      const updatedData = prevData?.filter((data) => {
        let condition = false
        payload.forEach((payLoadData) => {
          if(payLoadData?.field_key === data?.id && data?.isDefaultHead===false) {
            condition = true
          }
        })
        return !condition
      })
      state.getUpdatedConfigHeadsForMasterSearchTermSection = updatedData
    },
    // getUpdatedConfigHeadsForEditAdsetProductTargetListSection(state,action){
    //   state.getUpdatedConfigHeadsForEditAdsetProductTargetListSection = action.payload
    // },
    // getUpdatedConfigHeadsForMasterTargetingListSection(state, action) {
    //   state.getUpdatedConfigHeadsForMasterTargetingListSection = action.payload;
    // },
    // actiontoEditCollectivebid(state, action) {
    //   state.actiontoEditCollectivebid = action.payload;
    // },
    actionEditCollectiveBidForMasterTargeting(state, action) {
      state.actionEditCollectiveBidForMasterTargeting = action.payload;
    },
    // targetCheckedForBulkEdit(state, action) {
    //   state.targetCheckedForBulkEdit = action.payload;
    // },
    checkedTargetDataForBulEdit(state, action) {
      state.checkedTargetDataForBulEdit = action.payload;
    },
    targetingTypeForEditTargetSection(state, action) {
      state.targetingTypeForEditTargetSection = action.payload;
    },
    editAdGroupSectionCurrentselectedBtnName(state, action) {
      state.editAdGroupSectionCurrentselectedBtnName = action.payload;
    },
    getSelectedAdsetCreativesData(state, action) {
      state.getSelectedAdsetCreativesData = action.payload;
    },
    showBulkEditBidModal(state, action) {
      state.bulkEditBidModalVisibilty = true;
    },
    hideBulkeditBidModal(state, action) {
      state.bulkEditBidModalVisibilty = false;
    },
    showActionAlertModal(state, action) {
      state.actionAlertModalVisibilty = true;
    },
    hideActionAlertModal(state, action) {
      state.actionAlertModalVisibilty = false;
    },
  },
});

//For Actions
export const campaignListActions = campaignListSlice.actions;

export default campaignListSlice;

// const manualUploadedPoiImgae = useSelector((state) => state.poiDetailsData.poiImage);
// dispatch(poaDetailsDataAction.poaFrontImageData({
//     poaFrontImage: uploadedPoaFrontImg
//   }))
