export default async function pullUploadData (clientId) {
    const API_URL = `${process.env.REACT_APP_ADS_API}/client/${clientId}:pull-amazon-client-catalog-entities?source=amazon&type=amazon_ad_account`;
    const options = {
        method:"POST"
    }
    try {
        const response = await fetch(API_URL,options)
        const result = response?.status === 200
        return result
    } catch (error) {
        return error;
    }
}