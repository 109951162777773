export const ATTRIBUTE_CONDITIONS_MAPPER = {
    "maximum_retail_price": "Product MRP",
    "sale_price": "Product SP",
    "discount_percent": "Product Discount",
    "star_rating": "Product Star rating",
    "reviews": "Product Reviews",
    "name": "Product Name",
    "size": "Product - Size",
    "color": "Product - Color",
    "launch_date": "Launched Date",
    "gender":"Gender",
    "ad_status":"Products not advertised",
    "best_selling_rank":"BSR",
    "top_best_sellers_rank":"Top BSR",
    "cut_size_percent":"Cut Size *"
}

export const GENERAL_OPERATORS_MAPPER = {
    "EQUALS": "=",
    "NOT_EQUALS": "!=",
    "GREATER_THAN": ">",
    "LESS_THAN": "<",
    "GREATER_THAN_OR_EQUAL": ">=",
    "LESS_THAN_OR_EQUAL": "<=",
    "CONTAINS": "contains",
    "not contains": "NOT_CONTAINS",
}

export const PERFORMANCE_CONDTIONS_MAPPER = {
    "sale_value":"Revenue",
    "top_sale_value":"Top Selling",
    "top_conversion_rate":'Top Conversion',
    "top_roas":"Top ROAS",
    "quantity":"Available Inventory",
    "total_revenue":"Total Revenue",
    "tacos":"TACOS",
    "troas":"TROAS",
    "total_asp":"ASP",
    "total_units_sold":"Total Units Sold",
    "roas":"ROAS",
    "conversion_rate":"Conversion Rate",
    "ctr":"CTR"
}

export const PRODUCT_SET_DURATION_MAPPER = {
    "last_14_days":"14 days",
    "last_7_days":"7 days",
    "last_3_days":"3 days",
    "last_1_day":"1 day",
}
export const PRODUCT_SET_REFRESH_DURATION = { 
    "1_days":"1 Day",
    "3_days":"3 Days",
    "7_days":"7 Days",
    "14_days":"14 Days",
    "21_days":"21 Days",
}
