/**
 * This function takes two arguments as param1 and param2 and returns filteredArray
 * @params = [Param1: arraytoFilter, Param2: comparisonArray]
 * @returns FilteredArray
 */

export default function removeDuplicateObjectsFromArray(param1, param2) {
  const keywordsArray = param1?.filter((keywordData) => {
    const keyword = param2?.find(
      (addedData) =>
        keywordData?.value?.trim()?.toLowerCase() === addedData?.text?.trim().toLowerCase() &&
        keywordData?.type?.trim()?.toLowerCase() === addedData?.match?.trim().toLowerCase()
    );
    if (keyword) {
      return false;
    } else {
      return true;
    }
  });
  return keywordsArray;
}
