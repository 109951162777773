export const initialDataHeads = [
  {
    fieldName: "Target",
    minRange: null,
    maxRange: null,
    id: "targeting",
    isDefaultHead: true,
    isAbbrivation: false,
  },
  {
    fieldName: "Target Type",
    minRange: null,
    maxRange: null,
    id: "adyogi_targeting_type",
    isDefaultHead: true,
    isAbbrivation: false,
  },
  // {
  //   fieldName: "Match Type",
  //   minRange: "-",
  //   maxRange: "-",
  //   id: "Match_Type",
  //   isDefaultHead: true,
  //   isAbbrivation: false,
  // },
  // {
  //   fieldName: "Suggested Bid",
  //   minRange: null,
  //   maxRange: null,
  //   id: "suggested_bid",
  //   isDefaultHead: true,
  //   isAbbrivation: false,
  // },
  {
    fieldName: "Current Bid",
    minRange: null,
    maxRange: null,
    id: "current_bid",
    isDefaultHead: true,
    isAbbrivation: false,
  },
  {
    fieldName: "Spends",
    minRange: 0,
    maxRange: 0,
    id: "spends",
    isDefaultHead: true,
    isSortingRequired:true,
    isAbbrivation: false,
  },
  {
    fieldName: "CPC",
    minRange: 0,
    maxRange: 0,
    id: "cpc",
    isDefaultHead: true,
    isSortingRequired:true,
    isHeadRightAlign: true,
    isHeadWithIcon: "sort",
    isAbbrivation: false,
  },
  {
    fieldName: "Orders",
    minRange: 0,
    maxRange: 0,
    id: "orders",
    status: false,
    isSortingRequired:true,
    isHeadRightAlign: true,
    isHeadWithIcon: "sort",
    isAbbrivation: false,
  },
  {
    fieldName: "ROAS",
    minRange: 0,
    maxRange: 0,
    id: "roas",
    status: false,
    isSortingRequired:true,
    isHeadRightAlign: true,
    isHeadWithIcon: "sort",
    isAbbrivation: true,
  },
  {
    fieldName: "Ad Group",
    minRange: null,
    maxRange: null,
    id: "adgroup_name",
    status: false,
    isHeadRightAlign: true,
    isRestColumn:true,
    isHeadWithIcon: "sort",
    isAbbrivation: true,
  },
  {
    fieldName: "Campaign Name",
    minRange: null,
    maxRange: null,
    id: "campaign_name",
    status: false,
    isHeadRightAlign: true,
    isAbbrivation: true,
    isRestColumn:true,
    isClickable:true
  },
  {
    fieldName: "Campaign Type",
    minRange: null,
    maxRange: null,
    id: "campaign_type",
    status: false,
    isHeadRightAlign: true,
    isAbbrivation: true,
    isRestColumn:true,
  },
  {
    fieldName: "Campaign Budget",
    minRange: null,
    maxRange: null,
    id: "budget",
    status: false,
    isHeadRightAlign: true,
    isAbbrivation: true,
    isRestColumn:true,
  },
  {
    fieldName: "Avg. BR",
    minRange: null,
    maxRange: null,
    id: "avg_br",
    status: false,
    isHeadRightAlign: true,
    isAbbrivation: true,
    isRestColumn:true,
  },
  {
    fieldName: "DBR",
    minRange: null,
    maxRange: null,
    id: "dbr",
    status: false,
    isHeadRightAlign: true,
    isAbbrivation: true,
    isRestColumn:true,
  },
  {
    fieldName: "TOSP Bids",
    minRange: null,
    maxRange: null,
    id: "tosp_bids",
    status: false,
    isHeadRightAlign: true,
    isAbbrivation: true,
    isRestColumn:true,
  },
  {
    fieldName: "PDP Bids",
    minRange: null,
    maxRange: null,
    id: "pdp_bids",
    status: false,
    isHeadRightAlign: true,
    isAbbrivation: true,
    isRestColumn:true,
  },
  {
    fieldName: "Bid Strategy",
    minRange: null,
    maxRange: null,
    id: "strategy",
    status: false,
    isHeadRightAlign: true,
    isAbbrivation: true,
    isRestColumn:true,
  },
  {
    fieldName: "Portfolio",
    minRange: null,
    maxRange: null,
    id: "portfolio_name",
    status: false,
    isHeadRightAlign: true,
    isAbbrivation: true,
    isRestColumn:true,
  },
  
];
