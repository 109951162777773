import FilterLogicModal from "../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import "./productReport.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRef } from "react";
import CalenderComponent from "../../components/calender/Calender";
import closeIcon from "../../assets/icons/close_icon.png";
import { useGlobalContext } from "../../context/global";
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.svg";
import Chart from "../../components/Reports/Chart";
import cubejs from "@cubejs-client/core";
import useToken from "../../helper/Reports/useToken";
import RadioInputGroup from "../../components/radioInputGroup/radioInputGroup";
import ReportFilters from "../../components/Reports/Filters";
import SectionTitle from "../../components/sectionTitle";
import previousDays from "../../helper/Reports/common";
import Loader from "../../components/loader";
import useInitialLoad from "../../hooks/useInitialLoad";
import Breadcrumb from "../../components/Breadcrumb/ReportsBreadCrumb";
import Canvas from "../../helper/Screenshot/canvas";
import downloadIcon from "../../assets/svgs/download.svg";
import { createQueryURL, formatPieChartData , objectoFiltering,getSearchParamsObject,formatDate, getComparisonFormatTimeDimension } from "../../components/Reports/Components/utils";
import {useSearchParams } from "react-router-dom";
import CustomDropDown from '../../components/DropDown/CustomDropDown';
import dropdownIcon from "../../assets/icons/downArrowForSplitButton.svg";
import { Pagination } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { createComparisonCubeLoadResponse, createCubeLoadResponse } from "../../helper/widget";
import { defaultDimensions, mergeCustomOutputToAPIFiltersFormat, reverseMergeAPIFiltersToFilterModuleFormat, ruleItemInjector } from "../../helper/Quadrant/quadrantHelper";
import FilterWidget from "../../components/Filter/Widget";
import { FiltersAmazonDataQuadrant, FiltersDimensionDataQuadrant, FiltersMeasuresDataQuadrant } from "../../components/Filter/FilterData/quadrantFilter";


const ProductReport = (props) => {
  const {
    calendarDate,
    setCalendarDate,
    dateDifference,
    setDateDifference,
    applyCalendar,
    multiCalendarDate,
    clientId,
  } = useGlobalContext();
  
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const [tableView, setTableView] = useState("table");
  const allParams = new URLSearchParams(window.location.search);
  const paramsObject = getSearchParamsObject(allParams);
  let urlQuery = paramsObject?.urlQuery;
  const viewTYPE =allParams.get('viewtype') ?? 'Performance View'

  // decode the urlQuery and parse it into json
  // const previousFiltering = objectoFiltering(paramsObject,"VariantAnalysisTablePPMPlaceholder")
  const [currentViewType, setCurrentViewType] = useState(viewTYPE);
  const isComparisonView = currentViewType === 'Comparison View';

  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const [token, refresh] = useToken("");
  const { cubeToken, setCubeToken } = useGlobalContext();
  const [searchParams,setSearchParams] = useSearchParams({});
  let startDate = formatDate(multiCalendarDate.selection1.startDate);
  let endDate = formatDate(multiCalendarDate.selection1.endDate);
  let startDatePrevious = formatDate(
        multiCalendarDate.selection2.startDate
      );
  let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
  const cubeQueryTimeDimensionsFormatted = !isComparisonView ? [{dimension:"VariantAnalysisTablePPMPlaceholder.obDate",dateRange: [
    formatDate(calendarDate[0].startDate),
    formatDate(calendarDate[0].endDate),
  ]}] : [{dimension:"VariantAnalysisTablePPMPlaceholder.obDate",compareDateRange:[
    [startDate, endDate],
    [startDatePrevious, endDatePrevious],
  ]}]

  const { clientAccountId } = useGlobalContext();

  const cubejsApi = cubejs(cubeToken, {
    apiUrl: process.env.REACT_APP_CUBE_JS_URL,
  });

  const [showFilters, setShowFilters] = useState(true);
  const [campaignType, setCampaignType] = useState([]);
  const [campaignFormat, setCampaignFormat] = useState([]);
  const [targetingType, setTargetingType] = useState([]);
  const [productSet, setProductSet] = useState([]);
  const [adStatus, setAdStatus] = useState([]);
  const [portfolioName, setPortfolioName] = useState([]);
  const [categoryName, setCategoryName] = useState([]);
  const [campaignName, setCampaignName] = useState([]);
  const [childrenOne, setChildrenOne] = useState({});
  const [childDataIndex, setChildDataIndex] = useState(0);
  const [initialLoad, setInitialLoad] = useInitialLoad();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [summaryData, setSummaryData] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [lazyLoading, setLazyLoading] = useState(false);
  const [filterModuleFilters, setFilterModuleFilters] = useState({});
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [complexFiltersCount, setComplexFiltersCount] = useState(0);

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const groupByOptions = ["Product","Detailed category","Broad category"];
  const initialGroupByFilter = new URLSearchParams(window.location.search)?.get('reportType') ?? 'Product';
  const [groupByFilter,setGroupByFilter] = useState(initialGroupByFilter);
  const initialviewType = groupByFilter?.includes("Product") ? "Child Asin" : null 
  const [viewType, setViewType] = useState(initialviewType);
 
  const [cubeQueryMeasure, setCubeQueryMeasure] = useState([
    "VariantAnalysisTablePPMPlaceholder.cost",
    "VariantAnalysisTablePPMPlaceholder.costDRR",
    "VariantAnalysisTablePPMPlaceholder.SalePrice",
    "VariantAnalysisTablePPMPlaceholder.Quantity",
    "VariantAnalysisTablePPMPlaceholder.impressions",
    "VariantAnalysisTablePPMPlaceholder.impressionsDRR",
    "VariantAnalysisTablePPMPlaceholder.clicks",
    "VariantAnalysisTablePPMPlaceholder.clicksDRR",
    "VariantAnalysisTablePPMPlaceholder.ctr",
    "VariantAnalysisTablePPMPlaceholder.ctrDRR",
    "VariantAnalysisTablePPMPlaceholder.cpc",
    "VariantAnalysisTablePPMPlaceholder.cpcDRR",
    "VariantAnalysisTablePPMPlaceholder.cpm",
    "VariantAnalysisTablePPMPlaceholder.cpmDRR",
    "VariantAnalysisTablePPMPlaceholder.conversions14d",
    "VariantAnalysisTablePPMPlaceholder.conversions14dDRR",
    "VariantAnalysisTablePPMPlaceholder.conversion_rate",
    "VariantAnalysisTablePPMPlaceholder.conversion_rateDRR",
    "VariantAnalysisTablePPMPlaceholder.sales14d",
    "VariantAnalysisTablePPMPlaceholder.sales14dDRR",
    "VariantAnalysisTablePPMPlaceholder.average_order_value",
    "VariantAnalysisTablePPMPlaceholder.average_order_valueDRR",
    "VariantAnalysisTablePPMPlaceholder.acos",
    "VariantAnalysisTablePPMPlaceholder.acosDRR",
    "VariantAnalysisTablePPMPlaceholder.roas",
    "VariantAnalysisTablePPMPlaceholder.roasDRR",
    "VariantAnalysisTablePPMPlaceholder.MaximumRetailPrice",
    "VariantAnalysisTablePPMPlaceholder.BSR",
    "VariantAnalysisTablePPMPlaceholder.total_asp",
    "VariantAnalysisTablePPMPlaceholder.troas",
    "VariantAnalysisTablePPMPlaceholder.tacos",
    "VariantAnalysisTablePPMPlaceholder.trevnue",
    "VariantAnalysisTablePPMPlaceholder.tunits_sold",
  ]);

  
  const initialCubeQueryDimensions = groupByFilter?.includes("Broad") ? [
    "VariantAnalysisTablePPMPlaceholder.broadCategory",
  ] : groupByFilter?.includes("Detailed") ? [
    "VariantAnalysisTablePPMPlaceholder.category",
  ] : [
    "VariantAnalysisTablePPMPlaceholder.asin",
    "VariantAnalysisTablePPMPlaceholder.productNameWithImage",
  ]
  const isSubstringPresent = (string,subStr) => {
    return string?.includes(subStr)
   }
  
 
   const initialChildDimensions = isSubstringPresent(groupByFilter,"Broad") ? [
     "VariantAnalysisTablePPMPlaceholder.broadCategory",
     "VariantAnalysisTablePPMPlaceholder.category"
   ] : isSubstringPresent(groupByFilter,"Product") ? ["VariantAnalysisTablePPMPlaceholder.asin",
   "VariantAnalysisTablePPMPlaceholder.productNameWithImage"] : [
     "VariantAnalysisTablePPMPlaceholder.category",
   ]

   const excludeSBCampaignsFiltering = {type: "RULE_ITEM","member":"VariantAnalysisTablePPMPlaceholder.campaignFormat","operator":"notEquals","values":["SB","SB(V)"]}
  // const initialCubeQueryFilters = (isSubstringPresent(groupByFilter,"Broad") || isSubstringPresent(groupByFilter,"Detailed")) ? [{
  //   member: "VariantAnalysisTablePPMPlaceholder.profileId",
  //   operator: "equals",
  //   values: [clientAccountId],
  // },...previousFiltering,excludeSBCampaignsFiltering] : [
  //   {
  //     and: [
  //       {
  //         member: "VariantAnalysisTablePPMPlaceholder.profileId",
  //         operator: "equals",
  //         values: [clientAccountId],
  //       },
  //       ...previousFiltering,
  //       excludeSBCampaignsFiltering
  //     ],
  //   },
  // ]

  const initialChartFilters = isSubstringPresent(groupByFilter,"Broad") ? {
    spend: "cost",
    dimension:"broadCategory",
    revenue:"sales14d"
  } : isSubstringPresent(groupByFilter,"Detailed") ? {
    spend: "cost",
    dimension:"category",
    revenue:"sales14d"
  } : {
    spend: "cost",
    dimension:"asin",
    revenue:"sales14d"
  }

  const [cubeQueryOrder, setCubeQueryOrder] = useState([
    ["VariantAnalysisTablePPMPlaceholder.cost", "desc"],
    ["VariantAnalysisTablePPMPlaceholder.parentSku", "desc"],
  ]);

  const [cubeChildRowQueryOrder, setCubeChildRowQueryOrder] = useState([
    ["VariantAnalysisTablePPMPlaceholder.cost", "desc"],
  ]);

  const [cubeQueryDimensions, setCubeQueryDimensions] = useState(initialCubeQueryDimensions);

  const [cubeChildRowsQueryDimensions, setCubeChildRowsQueryDimensions] =
    useState(initialChildDimensions);

  const [cubeQueryTimeDimensions, setCubeQueryTimeDimensions] = useState(cubeQueryTimeDimensionsFormatted);
  // const [cubeQueryFilters, setCubeQueryFilters] = useState(initialCubeQueryFilters);
  // const [cubeChildRowsQueryFilters, setCubeChildRowsQueryFilters] = useState(initialCubeQueryFilters);
  const [cubeChildExpendableRuleItems, setCubeChildExpendableRuleItems] = useState(
    // SAMPLE DATA FORMAT
    [
    //   {
    //     type: "RULE_ITEM",
    //     member: "VariantAnalysisTablePPMPlaceholder.parentSku",
    //     operator: "equals",
    //     values: ["BDISO284N2"],
    //   },
    ]
  );

  const [resultSet, setResultSet] = useState({});
  const [chartFilters, setChartFilters] = useState(initialChartFilters);
  var spendResultSet = formatPieChartData(
    resultSet,
    chartFilters.spend,
    "VariantAnalysisTablePPMPlaceholder",
    chartFilters.dimension
  );
  var revenueResultSet = formatPieChartData(
    resultSet,
    chartFilters.revenue,
    "VariantAnalysisTablePPMPlaceholder",
    chartFilters.dimension
  );

  const [drrView, setDrrView] = useState("");

  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("start") && queryParams.get("end")) {
      setDate(queryParams.get("start"), queryParams.get("end"));
    }
  }, []);

  const setDate = (currentDate, previousDate) => {
    const dates = {
      startDate: new Date(currentDate),
      endDate: new Date(previousDate),
      key: "selection",
    };
    setDateDifference("Custom");
    setCalendarDate([dates]);
    setCubeQueryTimeDimensions((prev) => {
      let data = [...prev];
      data[0]["dateRange"] = [
        formatDate(dates.startDate),
        formatDate(dates.endDate),
      ];
      return data;
    });
  };

  const handleFilterOutput = (filters, type) => {
    setCurrentPage(1);
    if(filters?.dimension?.data?.length === 0 && filters?.metrics?.data?.length === 0 && filters?.campaignAmazon?.data?.length === 0){
      setIsFilterApplied(false);
    }else{
      setIsFilterApplied(true);
    }
    handleDataFetch(filters);
    setFilterModuleFilters(filters);
  };


  useEffect(() => {
    if (currentViewType !== "Comparison View") {
      const startDate = formatDate(calendarDate[0].startDate);
      const endDate = formatDate(calendarDate[0].endDate);
      if (
        startDate !== cubeQueryTimeDimensions[0]["dateRange"][0] ||
        endDate !== cubeQueryTimeDimensions[0]["dateRange"][1]
      ) {
        setIsLoaderVisible(true);
        setCubeQueryTimeDimensions((prev) => {
          let data = [...prev];
          data[0]["dateRange"] = [startDate, endDate];
          return data;
        });
      }
    }
  }, [applyCalendar]);

  useEffect(() => {
    if (currentViewType === "Comparison View") {
      let startDate = formatDate(multiCalendarDate.selection1.startDate);
      let endDate = formatDate(multiCalendarDate.selection1.endDate);
      let startDatePrevious = formatDate(
        multiCalendarDate.selection2.startDate
      );
      let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
      let apiDateRange = cubeQueryTimeDimensions[0]["compareDateRange"];
      if (
        startDate !== apiDateRange[0][0] ||
        endDate !== apiDateRange[0][1] ||
        startDatePrevious !== apiDateRange[1][0] ||
        endDatePrevious !== apiDateRange[1][1]
      ) {
        setIsLoaderVisible(true);
        setCubeQueryTimeDimensions((prev) => {
          let data = [...prev];
          data[0]["compareDateRange"] = [
            [startDate, endDate],
            [startDatePrevious, endDatePrevious],
          ];
          return data;
        });
      }
    }
  }, [applyCalendar]);

  function checkSBFiltering (filtersData) {
   return filtersData.find((data) => data?.member === "VariantAnalysisTablePPMPlaceholder.campaignFormat" && data?.operator === 'notEquals')
  }

  async function getProductReportData(filter,comparisonApi) {
    try {
      setIsLoaderVisible(true);
      let url = "";
      let SummaryUrl = "";
     if(comparisonApi){
       url = `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/products:comparison?source=amazon`;
       SummaryUrl = `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/products:comparison-summary?source=amazon`;
    }else{
       url = `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/products:insights?source=amazon`;
       SummaryUrl = `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/products:insights-summary?source=amazon`;
      }

      let tempExtraFilters = filter?.campaignAmazon;
      let extraFilters = {
        type: "amazon",
        amazon_campaigns_filter: tempExtraFilters,
      };
      const queryData = {
        dimensions: cubeQueryDimensions,
        filters: filter,
        extra_filters: extraFilters,
        measures: cubeQueryMeasure,
        order: cubeQueryOrder,
        timeDimensions: comparisonApi?getComparisonFormatTimeDimension(cubeQueryTimeDimensions):cubeQueryTimeDimensions,
        offset: (currentPage - 1) * itemsPerPage,
        limit:itemsPerPage,
      };
      const dataToPost = {
        query: queryData,
      };
      const summaryData = await axios.post(SummaryUrl, dataToPost);
      if(summaryData?.status === 200){
        var totalCount = summaryData?.data?.total_count;
        setSummaryData(summaryData?.data);
      }
      const data = await axios.post(url, dataToPost);
      if (data?.status === 200) {
        let parsedCubeFormatData = {};
        if(comparisonApi){
          parsedCubeFormatData = createComparisonCubeLoadResponse({measures:cubeQueryMeasure,dimensions:cubeQueryDimensions,order:cubeQueryOrder,filters:filter, data:data?.data?.data,total:totalCount,summary:summaryData?.data,timeDimensions:cubeQueryTimeDimensions,dataToPost:dataToPost});
        }else{
          parsedCubeFormatData = createCubeLoadResponse({measures:cubeQueryMeasure,dimensions:cubeQueryDimensions,order:cubeQueryOrder,filters:filter, data:data?.data?.data,total:totalCount,summary:summaryData?.data,timeDimensions:cubeQueryTimeDimensions,dataToPost:dataToPost});
        }
        return parsedCubeFormatData;
      }
    } catch (error) {
      toast.error("unknown error occurred" + JSON.stringify(error));
      console.log(error);
    }finally {
      setIsLoaderVisible(false);
    }
  }

  const handleDataFetch = (moduleFilters) => {
    const filters = mergeCustomOutputToAPIFiltersFormat(
      moduleFilters?.metrics?.custom_output,
      moduleFilters?.dimension?.custom_output,
      moduleFilters?.campaignAmazon?.custom_output,
    );
    
    let tempFilter = JSON.parse(JSON.stringify(filters));
    let updatedFiltersWithDimensions = ruleItemInjector(tempFilter,"dimensions", defaultDimensions(clientAccountId));
    let updatedFilters = ruleItemInjector(updatedFiltersWithDimensions,"campaignAmazon", defaultDimensions("",[excludeSBCampaignsFiltering]));
    setIsLoaderVisible(true);
    setInitialLoad(true);

      getProductReportData(updatedFilters,currentViewType === "Comparison View").then((resultSet) => {
      setResultSet((prev)=>{
        return resultSet;
      });
      setIsLoaderVisible(false);
      setInitialLoad(false);
      setTotalPages(Math.ceil(resultSet?.loadResponses?.[0]?.total / itemsPerPage) ?? 1);
      if (currentViewType === "Comparison View") {
        setTableView("ComparisonTable");
      } else {
        setTableView("table");
      }
    });
  };

  useEffect(() => {
    if(urlQuery){
      handleQueryChange(urlQuery);
    }else{
      handleDataFetch(filterModuleFilters);
    }
  }, [
    cubeQueryMeasure,
    cubeQueryDimensions,
    cubeQueryTimeDimensions,
    cubeToken,
    urlQuery,
    currentPage
  ]);

  const expendChange = (data, type, index) => {
    if(groupByFilter === "Product") {
      if (type === "campaign_format_child" || type === "link") {
        let url = "/master-ads?asinId=" + data;
        window.open(url);
      } else {
        if(groupByFilter === "Product") {

          setCubeChildExpendableRuleItems(
            [
              {
                type: "RULE_ITEM",
                member: "VariantAnalysisTablePPMPlaceholder.parentSku",
                operator: "equals",
                values: [data],
              },
            ]
          )
        } 
        // setCubeChildRowsQueryFilters(prevData);
        setChildDataIndex(data);
      }
    } else if (groupByFilter?.includes("Broad")) {
      setChildDataIndex(data);
      setCubeChildExpendableRuleItems(
        [
          {
            type: "RULE_ITEM",
            member: "VariantAnalysisTablePPMPlaceholder.broadCategory",
            operator: "equals",
            values: [data],
          },
        ]
      )
      
    } else if(groupByFilter?.includes("Detailed")) {
      let url = "/product-analytics";
      const filters = mergeCustomOutputToAPIFiltersFormat(
        filterModuleFilters?.metrics?.custom_output,
        filterModuleFilters?.dimension?.custom_output,
        filterModuleFilters?.campaignAmazon?.custom_output,
      );
      let categoryRule =[

            {
              type: "RULE_ITEM",
              member: "VariantAnalysisTablePPMPlaceholder.category",
              operator: "equals",
              values: [data],
            },
    ]
    let updatedFiltersWithDimensions = ruleItemInjector(filters,"dimensions", defaultDimensions("",categoryRule));

    const queryParams = [
      ["start", formatDate(calendarDate[0].startDate)],
      ["end", formatDate(calendarDate[0].endDate)],
      ["viewtype",currentViewType]
    ];
    const queryString = queryParams
      .filter(([key, value]) => value !== "All")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    url += `?${queryString}&urlQuery=${encodeURIComponent(JSON.stringify(updatedFiltersWithDimensions))}`;
    // Open URL in new tab

    window.open(url);
    }
  };

  async function getChildProductReportData(filter,comparisonApi) {
    try {
      let url = "";
      if(comparisonApi){
        url = `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/products:comparison?source=amazon`;
      }else{
         url = `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/products:insights?source=amazon`;
        }
      let tempExtraFilters = filter?.campaignAmazon;
      let extraFilters = {
        type: "amazon",
        amazon_campaigns_filter: tempExtraFilters,
      };

      const queryData = {
        dimensions: cubeChildRowsQueryDimensions,
        filters: filter,
        extra_filters: extraFilters,
        measures: cubeQueryMeasure,
        order: cubeChildRowQueryOrder,
        timeDimensions: comparisonApi?getComparisonFormatTimeDimension(cubeQueryTimeDimensions):cubeQueryTimeDimensions,
        limit:10000,
      };
      const dataToPost = {
        query: queryData,
      };
      const data = await axios.post(url, dataToPost);
      if (data?.status === 200) {
        let parsedCubeFormatData = {};
        if(comparisonApi){
          parsedCubeFormatData = createComparisonCubeLoadResponse({measures:cubeQueryMeasure,dimensions:cubeChildRowsQueryDimensions,order:cubeChildRowQueryOrder,filters:filter, data:data?.data?.data,timeDimensions:getComparisonFormatTimeDimension(cubeQueryTimeDimensions)});
        }else{
          parsedCubeFormatData = createCubeLoadResponse({measures:cubeQueryMeasure,dimensions:cubeChildRowsQueryDimensions,order:cubeChildRowQueryOrder,filters:filter, data:data?.data?.data,timeDimensions:cubeQueryTimeDimensions});
        }
        return parsedCubeFormatData;
      }
    } catch (error) {
      toast.error("unknown error occurred" + JSON.stringify(error));
      console.log(error);
    }finally {
      setIsLoaderVisible(false);
        setInitialLoad(false);
    }
  }



  useEffect(() => {
    let getData = false;
    if(groupByFilter?.includes("Product")) {
      if(cubeChildExpendableRuleItems?.length > 0){
        getData = true;
      }
    } else if (groupByFilter?.includes("Broad")) {
      if(cubeChildExpendableRuleItems?.length > 0){
        getData = true;
      }
    }
    // to fetch child rows data

    if (getData) {
      const filters = mergeCustomOutputToAPIFiltersFormat(
        filterModuleFilters?.metrics?.custom_output,
        filterModuleFilters?.dimension?.custom_output,
        filterModuleFilters?.campaignAmazon?.custom_output,
      );
      let tempFilter = JSON.parse(JSON.stringify(filters));
      let updatedFiltersWithDimensions = ruleItemInjector(tempFilter,"dimensions", defaultDimensions(clientAccountId,cubeChildExpendableRuleItems));
      let updatedFilters = ruleItemInjector(updatedFiltersWithDimensions,"campaignAmazon", defaultDimensions("",[excludeSBCampaignsFiltering]));

      getChildProductReportData(updatedFilters,currentViewType === "Comparison View").then((resultSet) => {
        setChildrenOne((prev) => {
          return { ...prev, [childDataIndex]: resultSet };
        });
        setIsLoaderVisible(false);
        setInitialLoad(false);
      });
    }
  }, [cubeChildExpendableRuleItems]);

  const calenderIconRefForCampaignlist = useRef(null);

  const closeCalender = (e) => {
    if (
      calenderIconRefForCampaignlist.current &&
      showCustomDateBox &&
      !calenderIconRefForCampaignlist.current.contains(e.target)
    ) {
      setShowCustomDateBox(false);
    }
  };

  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeCalender);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeCalender);
    };
  }, [showCustomDateBox]);


  const onDropdownImghandler = (type) => {
    // console.log("@$# onDropdownImghandler");
  };

  useEffect(() => {
    if(viewType!==null) {
      setIsLoaderVisible(true);
      // setResultSet({})
      // TODO: fix and recheck this logic 
      // let prevData = [...cubeQueryFilters];
      // prevData[0]["and"].forEach((x, i) => {
      //   if (x.operator === "contains") {
      //     prevData[0]["and"].splice(i, 1);
      //   }
      // });
      // let is_same_prev =
      //   cubeQueryFilters.length == prevData.length &&
      //   cubeQueryFilters.every(function (element, index) {
      //     return element === prevData[index];
      //   });
      // if (!is_same_prev) {
      //   setCubeQueryFilters(prevData);
      // }
      if (viewType === "Parent Asin") {
        let newCubeDimention = ["VariantAnalysisTablePPMPlaceholder.parentSku"];
        let is_same =
          cubeQueryDimensions.length == newCubeDimention.length &&
          cubeQueryDimensions.every(function (element, index) {
            return element === newCubeDimention[index];
          });
        if (!is_same) {
          setCubeQueryDimensions(newCubeDimention);
        }
      } else {
        let newCubeDimention = [
          "VariantAnalysisTablePPMPlaceholder.asin",
          "VariantAnalysisTablePPMPlaceholder.productNameWithImage",
        ];
        let is_same =
          cubeQueryDimensions.length === newCubeDimention.length &&
          cubeQueryDimensions.every(function (element, index) {
            return element === newCubeDimention[index];
          });
        if (!is_same) {
          setCubeQueryDimensions(newCubeDimention);
        }
      }
      setChartFilters((prev) => {
        prev.dimension = viewType === "Parent Asin" ? "parentSku" : "asin";
        return prev;
      });
    }
  }, [viewType]);

  useEffect(() => {
    setIsFilterVisible(filterModalInCampaignListVisibilty);
  }, [filterModalInCampaignListVisibilty]);

  useEffect(() => {
    if(groupByFilter) {
      const queryParams = new URLSearchParams(window.location.search)
     queryParams?.set("reportType",groupByFilter)
     queryParams?.set("viewtype",currentViewType)
     const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
     window.history.replaceState(null, null, newUrl);
    }
  },[groupByFilter,currentViewType])

  const handleComparisonView = (type) => {
    setChildrenOne({});
    if (type === "Comparison View") {
      setCubeQueryTimeDimensions([
        {
          dimension: "VariantAnalysisTablePPMPlaceholder.obDate",
          compareDateRange: [
            [
              startDate,
              endDate,
            ],
            [
              startDatePrevious,
              endDatePrevious,
            ],
          ],
        },
      ]);
      setIsLoaderVisible(true);
    } else if (
      type !== "Comparison View" &&
      currentViewType === "Comparison View"
    ) {
      setCubeQueryTimeDimensions([
        {
          dimension: "VariantAnalysisTablePPMPlaceholder.obDate",
          dateRange: [
            formatDate(calendarDate[0].startDate),
            formatDate(calendarDate[0].endDate),
          ],
        },
      ]);
      setIsLoaderVisible(true);
    }
    setCurrentViewType(type);
  };



  // function handleSearch(data) {
  //   let prevData = JSON.parse(JSON.stringify(cubeQueryFilters));
  //   setIsLoaderVisible(true)
  //  if(groupByFilter === "Product") {
  //    // Remove existing "or" conditions in the first "and" condition
  //    prevData[0].and = prevData[0].and.filter(
  //      (condition) => !("or" in condition)
  //    );

  //    // Create a new "or" condition
  //    let newOrCondition = {
  //      or: cubeQueryDimensions.map((member) => ({
  //        member,
  //        operator: data.select,
  //        values: [data.input],
  //      })),
  //    }

  //    // Add the new "or" condition to the first "and" condition
  //    prevData[0].and.push(newOrCondition);
  //  } else if (groupByFilter !== "Product") {
  //   prevData.forEach((x, i) => {
  //     if (x.member === cubeQueryDimensions[0]) {
  //       prevData.splice(i, 1);
  //     }
  //   });
  //   if(data.input) {
  //     prevData.push({
  //         member: cubeQueryDimensions[0],
  //         operator: data.select,
  //         values: [data.input],
  //     });
  //   }
  //  }
  // // Update the state with the modified cubeQueryFilters
  // setCubeQueryFilters(prevData);
  // setCubeChildRowsQueryFilters(prevData);
  // }


  const handlePagination = async (type) => {
    setCurrentPage((prevPage) => {
      if (type === "next" && prevPage < totalPages) {
        return prevPage + 1;
      } else if (type === "prev" && prevPage > 1) {
        return prevPage - 1;
      }
      return prevPage; // No change if trying to go out of bounds
    });
  };

  const handleDownload = () => {
    Canvas({ name: "product-analytics" });
  };

  const handleDrrView = (value, type) => {
    setDrrView(value);
    setChildrenOne({});
    if (value !== "drr") {
      setChartFilters((prev) => {
        prev.spend = "cost";
        prev.revenue = "sales14d";
        return prev;
      });
    } else {
      setChartFilters((prev) => {
        prev.spend = "costDRR";
        prev.revenue = "sales14dDRR";
        return prev;
      });
    }
  };

  function filterOutFilters (type,data) {
    // Filter out existing filters from the current states
   const mainData = type === "Product" ? [...data][0]["and"]?.filter((mainData,index) => {
      return mainData.member !== "VariantAnalysisTablePPMPlaceholder.parentSku"
   }).concat([...data][1]?.hasOwnProperty(["and"]) ? [...data][1]["and"] : [] ) : type?.includes("Broad") ? [...data]?.filter((mainData,mainIndex) => {
      return mainData.member !== "VariantAnalysisTablePPMPlaceholder.broadCategory"
   }) : data
   return mainData
  }
 

 
  const handleGroupByChange = (e) => {
    if(e.target.value!==groupByFilter) {
      // reset expandable rule items
      setCubeChildExpendableRuleItems([]);

      // Change Filters According to groupTypes
      if(e.target.value?.includes("Broad")) {
        setIsLoaderVisible(true)
        setCubeQueryDimensions([
          "VariantAnalysisTablePPMPlaceholder.broadCategory",
        ])
        setCubeChildRowsQueryDimensions([
          "VariantAnalysisTablePPMPlaceholder.broadCategory",
          "VariantAnalysisTablePPMPlaceholder.category"
        ])
        // const prevDataParentFilters = [...cubeQueryFilters]
        // const parentQueryFilters = filterOutFilters(groupByFilter,prevDataParentFilters);
        // const childQueryFilters = filterOutFilters(groupByFilter,cubeChildRowsQueryFilters);
        // setCubeChildRowsQueryFilters(childQueryFilters)
        // setCubeQueryFilters(parentQueryFilters)
        setViewType(null);
        setGroupByFilter(e.target.value)
        setChartFilters((prev) => {
          prev.spend = "cost"
          prev.dimension = "broadCategory";
          prev.revenue = "sales14d"
          return prev
        })
      } else if(e.target.value?.includes("Detailed")) {
        setIsLoaderVisible(true)
        // const filteredFilters = filterOutFilters(groupByFilter,cubeQueryFilters);
        // setCubeQueryFilters(filteredFilters)
        setCubeQueryDimensions([
          "VariantAnalysisTablePPMPlaceholder.category",
        ])
        setViewType(null);
        setGroupByFilter(e.target.value)
        setChartFilters((prev) => {
          prev.spend = "cost"
          prev.dimension = "category";
          prev.revenue = "sales14d"
          return prev
        })

      } else {
       setViewType("Child Asin")
      //  const filteredFilters = filterOutFilters(groupByFilter,cubeQueryFilters);
       // Take out normal and range Filters and send it cubeQueryFilters
      //  const rangeFilters = filteredFilters?.filter((data) => data?.operator?.includes("gte") || data?.operator?.includes("lte"))
      //  const normalFilters = filteredFilters?.filter((data) => !data?.operator?.includes("gte") && !data?.operator?.includes("lte"))
      //  setCubeQueryFilters([{and:normalFilters},{and:rangeFilters}])
      //  setCubeChildRowsQueryFilters([{and:normalFilters},{and:rangeFilters}])
       setCubeQueryDimensions(["VariantAnalysisTablePPMPlaceholder.asin",
       "VariantAnalysisTablePPMPlaceholder.productNameWithImage"])
       setCubeChildRowsQueryDimensions(["VariantAnalysisTablePPMPlaceholder.asin",
       "VariantAnalysisTablePPMPlaceholder.productNameWithImage"])
       setGroupByFilter(e.target.value)
       setChartFilters((prev) => {
        prev.spend = "cost";
        prev.dimension = "asin";
        prev.revenue = "sales14d";
        return prev
      })

      }
    }
  }

  function removeQueryParamAndSetUrl(key) {
    // Get the current URL
    const urlObj = new URL(window.location.href);

    // Delete the specific query parameter
    urlObj.searchParams.delete(key);
    // Update the browser's URL without reloading the page
    window.history.replaceState({}, document.title, urlObj.toString());
  }


  const handleQueryChange = (urlQuery) => {
    const urlQueryFilter= JSON.parse(decodeURIComponent(urlQuery ? urlQuery : "{}"));
    let filterCustomFormat = reverseMergeAPIFiltersToFilterModuleFormat(urlQueryFilter);
    if(filterCustomFormat?.metrics?.custom_output || filterCustomFormat?.dimension?.custom_output || filterCustomFormat?.campaignAmazon?.custom_output) {
      setIsFilterApplied(true);
    }
    // handleDataFetch(filterCustomFormat);
    setFilterModuleFilters((prev)=>{
      return JSON.parse(JSON.stringify(filterCustomFormat));
    })
    removeQueryParamAndSetUrl("urlQuery");
     return filterCustomFormat;
  }

  // useEffect(() => {
  //   const urlQueryFilter= JSON.parse(decodeURIComponent(urlQuery ? urlQuery : "{}"));
  //   let filterCustomFormat = reverseMergeAPIFiltersToFilterModuleFormat(urlQueryFilter);
  //   if(filterCustomFormat?.metrics?.custom_output || filterCustomFormat?.dimension?.custom_output || filterCustomFormat?.campaignAmazon?.custom_output) {
  //     setIsFilterApplied(true);
  //   }
  //   // handleDataFetch(filterCustomFormat);
  //   setFilterModuleFilters((prev)=>{
  //     return JSON.parse(JSON.stringify(filterCustomFormat));
  //   })
  // }, [urlQuery]);

  const pageDisplayType = (viewType==="Child Asin" ? "product-child" : viewType === "Parent Asin" ? "product-parent" : groupByFilter?.includes("Broad") ? "expendable" : "category");
  const pageDisplayFormat = (viewType==="Child Asin" ? "campaign-format_child" : viewType === "Parent Asin" ? "campaign_format" : groupByFilter?.includes("Broad") ? "campaign_format" : "");
  const childrenOneHeader = viewType!==null ? "Product Name" : "Category"
  const reportHeading = groupByFilter?.includes("Broad") ? "Broad Category Report" : groupByFilter?.includes("Detailed") ? "Detailed Category Report":"Product Report"

  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
      <Loader visible={initialLoad}></Loader>

      <div
        style={{
          marginLeft: "auto",
        }}
      >
        <button
          className="date-container date-btn-campaignlist d-none"
          onClick={dateInputClickHandler}
        >
          <div className="d-flex justify-conntent-center">
            <span className="date-btn-text">{dateDifference}</span>
          </div>
          <button className="btn-calender-img-holder">
            <span className="">
              <img
                className="dropdown-img-for-select"
                src={selectSideIcon}
                alt={"SelectSideIcon"}
              ></img>

              <img
                className="calender-icon-for-campaignlist"
                src={CalenderIconForCampaignList}
                alt={"CalendarIcon"}
              ></img>
            </span>
          </button>
        </button>
      </div>

      <div
        ref={calenderIconRefForCampaignlist}
        className={
          showCustomDateBox ? "calender-inclasslist-container" : "hide"
        }
      >
        <CalenderComponent
          setShowCustomDateBox={setShowCustomDateBox}
        ></CalenderComponent>
        <span className="calender-close-icon-campaignlist">
          <img
            onClick={() => setShowCustomDateBox(false)}
            src={closeIcon}
            alt={"CloseIcon"}
          ></img>
        </span>
      </div>

      <Breadcrumb />
      <SectionTitle sectionTitleHeading={reportHeading}></SectionTitle>

      <div
        className="d-flex justify-content-between mt-4 mb-4 gap-4 position-relative"
        id="downloadChart"
      >
        <div
          onClick={handleDownload}
          className="downloadChartWrapper pie"
          id="downloadChartWrapper"
        >
          <img
            src={downloadIcon}
            className="cursor-pointer-global DownloadIcon"
            alt="overview-settings-tile"
          />
        </div>
        <div className="w-100 min-h-300 pie-container">
          <h4 className="sub-heading-pie f-20 p-4">Spends</h4>
          <Chart
            type="apexpie"
            resultSet={spendResultSet}
            classes={"marketing-reports"}
            display={"spends"}
          />
        </div>
        <div className="w-100 min-h-300 pie-container">
          <h4 className="sub-heading-pie f-20 p-4">Revenue</h4>
          <Chart
            type="apexpie"
            resultSet={revenueResultSet}
            classes={"marketing-reports"}
            display={"spends"}
          />
        </div>
      </div>
 

      <div className="d-flex w-100 align-items-end justify-items-between pt-3 productradiobutton">
      <CustomDropDown data={groupByOptions} classes={'product-report-group-by-filter'} filterCampaigns={handleGroupByChange} targettingValue={groupByFilter} src={dropdownIcon} title={"Group Data By"}/>

        <div className="w-100 d-flex flex-column gap-2">
   {    groupByFilter === "Product" ?   <RadioInputGroup
            state={viewType}
            setState={setViewType}
            name="viewType"
            radios={["Parent Asin", "Child Asin"]}
            required
          ></RadioInputGroup> : null}
           <small className="paginationtext">*SB Data Not Present</small>
        </div>
      </div>
      <div>
        {         
          <Chart
          key="table_render"
            type={tableView}
            resultSet={resultSet}
            classes={`noFilters ${viewType!==null ? "wraptext" : ""} marketing-reports`}
            display={pageDisplayFormat}
            showCustomFilterBtn={true}
            isFilterApplied={isFilterApplied}
            handleCustomBtnClick={() => setShowFilterModal(true)}
            handleChange={expendChange}
            childrenOneData={childrenOne}
            shimmerEffect={isLoaderVisible}
            childrenOneHeader={childrenOneHeader}
            FunnelViews={[
              "Performance View",
              "Funnel View",
              "Comparison View",
              "Custom View",
            ]}
            handleComparisonView={handleComparisonView}
            CurrentViewType={currentViewType}
            page={pageDisplayType}
            dataFilter={()=>{}}
            drrView={handleDrrView}
            drrViewType={drrView}
            handleSearch={()=>{}}
            showFilter={false}
            groupByView={groupByFilter}
          />
      //    : viewType === "Child Asin" ? (
      //     <Chart
      //       type={tableView}
      //       resultSet={resultSet}
      //       shimmerEffect={isLoaderVisible}
      //       classes={"wraptext marketing-reports"}
      //       display={"campaign_format_child"}
      //       handleChange={expendChange}
      //       childrenOneHeader={"Product Name"}
      //       FunnelViews={[
      //         "Performance View",
      //         "Funnel View",
      //         "Comparison View",
      //         "Custom View",
      //       ]}
      //       handleComparisonView={handleComparisonView}
      //       CurrentViewType={currentViewType}
      //       page="product-child"
      //       dataFilter={dataFilter}
      //       drrView={handleDrrView}
      //       drrViewType={drrView}
      //       handleSearch={handleSearch}
      //       showFilter={showFilters}
      //       key={currentViewType}            
      //     />
      //   ):<Chart
      //   type={tableView}
      //   resultSet={resultSet}
      //   classes={"marketing-reports"}
      //   display={groupByFilter?.includes('Broad') ? 'campaign_format' : undefined}
      //   page={groupByFilter?.includes('Broad') ? 'expendable':'category'}
      //   handleChange={expendChange}
      //   childrenOneData={childrenOne}
      //   shimmerEffect={isLoaderVisible}
      //   childrenOneHeader={'Category'}
      //   FunnelViews={["Performance View","Funnel View","Comparison View","Custom View"]} 
      //   handleComparisonView={handleComparisonView} CurrentViewType={currentViewType} 
      //   dataFilter={dataFilter}
      //   drrView={handleDrrView} drrViewType={drrView}
      //   handleSearch={handleSearch}
      //   showFilter={showFilters}
      //   key={groupByFilter}
      // />
      }
      <div className="d-flex align-items-end gap-1 py-2 justify-content-end">
                <span className="text-nowrap">{lazyLoading ? 'Loading Next Page Records...' :`Showing ${currentPage} out of ${totalPages} Pages`}</span>
                <Pagination className="ma-custom-pagination" style={{marginBottom:0}}>
                    <Pagination.Prev disabled={currentPage === 1} onClick={() => {
                        handlePagination("prev")
                    }}/>
                    <Pagination.Next disabled={currentPage === totalPages || lazyLoading} onClick={() => {
                        handlePagination("next")
                    }}/>
                </Pagination>
            </div>
      </div>
       {/*filter start==================================== */}
       <FilterWidget
        key="product_analytics_page"
        config={{
          campaignAmazon: FiltersAmazonDataQuadrant,
          measure: FiltersMeasuresDataQuadrant,
          dimension: FiltersDimensionDataQuadrant,
        }}
        campaignFilter={true}
        filters={filterModuleFilters}
        showPopupModal={showFilterModal}
        setShowPopupModal={setShowFilterModal}
        output={handleFilterOutput}
        prefixMember={""}
        type="popup"
        sidebar={true}
        default={"metrics"}
        page={"overlay"}
        isApplied={() => {}}
        summary={"always"}
        narrowFilters={false}
        summaryOutput={(e) => {}}
        calloutTitle="Select your filters"
        calloutContent="+ Add Filter"
        // calloutDescription="Add new attributes at campaign level to determine campaign funnel"
      />

      {/*filter end======================================= */}
    </div>
  );
};

export default ProductReport;
