export const enablePauseBulkChildAd = (currentState,status,isGlobalView) => {
    const updatedState = currentState.map((parentAd) => {
      if(isGlobalView) {
        parentAd?.childRows?.map((childAd) => {
          if(childAd.isTargetChecked && childAd?.id) {
            childAd.status = status
            childAd.isTargetChecked = false
          }
          return childAd
        })
      }
      if(!isGlobalView && parentAd?.id && parentAd.isTargetChecked) {
        parentAd.status = status
        parentAd.isTargetChecked = false
      }
       return parentAd
     })
     return updatedState
}
