/**
 * 
 * @param {*} status 
 * @param {*} initalArray 
 * @returns Will return sorted array according to the campaign status. Enabled or Paused
 */

export default function enablePauseFilter (status,initalArray) {
    if(status === "ENABLED" || status === "PAUSED") {
        const arrayToBeReturned = initalArray.filter((campaign) => {
            return campaign.status === status
        });
        return arrayToBeReturned
    }
    return initalArray;
}
