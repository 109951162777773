import { createSlice } from "@reduxjs/toolkit";

const initialDashboardState = {
    dasboardLayoutSettingModal: false,
    dashboardLayoutSequencedata:[],
    dashboardSideBarVisibiltyState: false,
    dasboardOverviewCardSettingModal: false,
    sidebarButtonClickedName: ""
    // dashboardLAyoutFilterSettingModal: false,
}
const dashboardSlice = createSlice({
    name: "dashboardDataHandle",
    initialState: initialDashboardState,
    
    reducers: {
        
        dasboardLayoutSettingModalShow(state) {
            
            state.dasboardLayoutSettingModal = true;
        },
        dasboardLayoutSettingModalHide(state) {
            state.dasboardLayoutSettingModal = false
        },
        dasboardOverviewCardSettingModalHide(state) {
            state.dasboardOverviewCardSettingModal = false
        },
        dasboardOverviewCardSettingModalShow(state) {
            state.dasboardOverviewCardSettingModal = true
        },
        dashboardLayoutSequencedata(state, actions) {
            state.dashboardLayoutSequencedata = actions.payload;
        },
        dashboardSideBarVisibiltyStateShow(state) {
            state.dashboardSideBarVisibiltyState = true
        },
        dashboardSideBarVisibiltyStateHide(state) {
            state.dashboardSideBarVisibiltyState = false

        },sidebarButtonClickedName(state, actions) {
            state.sidebarButtonClickedName = actions.payload

        }

        // uploadedDocsData(state, action) {
        //     state.poaDocumentName = action.payload.poaDocumentName;
        //     state.poiImage = action.payload.poiImage;
        //     state.poaFrontImage = action.payload.poaFrontImage;
        //     state.poaBackImage = action.payload.poaBackImage;
        // },

    },
});

//For Actions
export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice;


// const manualUploadedPoiImgae = useSelector((state) => state.poiDetailsData.poiImage);
// dispatch(poaDetailsDataAction.poaFrontImageData({
//     poaFrontImage: uploadedPoaFrontImg
//   }))