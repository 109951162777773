export const FILTER_CHIPS_MAPPER = {
    "master-ads":{
        "maximum_retail_price":"MRP",
        "sale_price":"SP",
        "discount_percentage":"Discount %",
        "total_units_sold":"Total Units Sold",
        "total_asp":"ASP",
        "organic_sales_percentage":"Organic Sales %",
        "troas":"TROAS",
        "tacos":"TACOS",
        "total_revenue":"Total Revenue",
        "quantity":"Available Inventory"
    }
}