import { getColumnsConfig } from "../components/Filter/FilterData/columnConfig";

export const  reverseTransformToDesiredFormat = (customOutput, config) => {
    let idCounter = 0; // Simple counter to generate unique IDs.
  
    function getCurrentDateTimeString() {
      idCounter += 1; 
      return `${new Date().getTime()}${idCounter}`;
    }
  
    // Function to process rules recursively and transform them into the desired format.
    function processRules(rules, operator) {
      const transformedGroups = [];
      rules.forEach(rule => {
        if (rule.type === "RULE_ITEM") {
          const fieldConfig = config?.fields?.find(field => rule.member === field.id);
          let isMultiple = rule?.values?.length > 1 ? true : false;
          const operatorConfig = config.operators[fieldConfig?.datatype]?.find(op => op.id === rule.operator && (!["equals","notEquals"].includes(op.id)  || op.multiple === isMultiple));
          const transformedRule = {
            id: getCurrentDateTimeString(),
            category: fieldConfig?.name ?? rule.member,
            filter: operatorConfig?.name ?? rule.operator,
            userInput: rule.values,
          };
  
          // Group rules by operator.
          if (transformedGroups.length > 0 && transformedGroups[transformedGroups.length - 1].operator === operator) {
            transformedGroups[transformedGroups.length - 1].data.push(transformedRule);
          } else {
            // New group for different operators.
            transformedGroups.push({
              id: getCurrentDateTimeString(),
              data: [transformedRule],
              operator: operator,
            });
          }
        } else if (rule.type === "AND_RULE" || rule.type === "OR_RULE") {
          // Process nested rules recursively.
          const nestedRules = processRules(rule[rule.type === "AND_RULE" ? "and" : "or"], rule.type === "AND_RULE" ? "AND" : "OR");
          transformedGroups.push(...nestedRules);
        }
      });
  
      return transformedGroups;
    }
  
    // Determine the top-level operator from the customOutput.type.
    const topLevelOperator = customOutput?.type === "OR_RULE" ? "OR" : "AND";
    if(customOutput?.type) {
      return processRules(customOutput[topLevelOperator.toLowerCase()] || [customOutput], topLevelOperator);
    } else {
      return customOutput
    } 
  }


  export const createCubeLoadResponse = ({ measures, dimensions, order, filters, data,total,summary, timeDimensions ,dataToPost}) => {
    const query = {
      measures: measures || [],
      order: order || [],
      dimensions: dimensions || [],
      filters: filters || [],
      timeDimensions: timeDimensions || [],
      limit: 50,
      offset: 0,
      total: true,
      timezone: "UTC",
      rowLimit: 50,
      queryType: "regularQuery",
    };
  
    const baseResponse = {
      query,
      data: data || [],
      lastRefreshTime: new Date().toISOString(),
      annotation: {},
      dataSource: "default",
      dbType: "bigquery",
      extDbType: "cubestore",
      external: false,
      slowQuery: false,
      total: total || 0,
      summary: summary || false,
    };
  
    const resultSet = {
      loadResponse: {
        queryType: "regularQuery",
        results: [baseResponse],
        pivotQuery: query,
        slowQuery: false,
      },
      queryType: "regularQuery",
      isWrapperApi: true,
      loadResponses: [baseResponse],
      options: {},
      backwardCompatibleData: [[]],
      
      // Integrated tableColumns Method
      tableColumns(pivotConfig) {
        const firstRow = this.loadResponses[0]?.data?.[0] ?? {};
        const dimensions = this.loadResponses[0]?.query?.dimensions || [];
        let visibleColumns = Object.keys(firstRow);
        // include dimensions in visible columns if not present
        dimensions.forEach((dimension) => {
          if (!visibleColumns.includes(dimension)) {
            visibleColumns.push(dimension);
          }
        });
        
        let allColumnWithMetaData = getColumnsConfig(visibleColumns?.[0]?.split(".")?.[0],visibleColumns);  // Get columns config based on the first column name
        return allColumnWithMetaData
      },
  
      // Integrated tablePivot Method
      tablePivot(pivotConfig) {
        return this.loadResponses[0]?.data ?? [];
      },
    };
  
    return resultSet;
  };

  function mergeCurrentAndComparisonData(data, dataType) {
    let mergedData = [];
    
    data.forEach((item) => {
        let mergedItem = {};
        
        Object.keys(item).forEach((key) => {
            if (key === dataType && typeof item[key] === 'object') {
                // Merge the specified dataType object into the mergedItem
                mergedItem = { ...mergedItem, ...item[key] };
            } else if (typeof item[key] !== 'object') {
                // Directly copy non-object fields to the mergedItem
                mergedItem[key] = item[key];
            }
        });
        
        mergedData.push(mergedItem); // Add the processed item to the mergedData array
    });
    
    return mergedData;
}


  export const createComparisonCubeLoadResponse = ({ measures, dimensions, order, filters, data,total,summary, timeDimensions,dataToPost }) => {
    const query = {
      measures: measures || [],
      order: order || [],
      dimensions: dimensions || [],
      filters: filters || [],
      timeDimensions: timeDimensions || [],
      limit: 50,
      offset: 0,
      total: true,
      timezone: "UTC",
      rowLimit: 50,
      queryType: "regularQuery",
    };
  
    const baseResponse1 = {
      query,
      data: mergeCurrentAndComparisonData(data,"current_data") || [],
      lastRefreshTime: new Date().toISOString(),
      annotation: {},
      dataSource: "default",
      dbType: "bigquery",
      extDbType: "cubestore",
      external: false,
      slowQuery: false,
      total: total || 0,
      summary:summary? {current:mergeCurrentAndComparisonData([summary],"current_data")[0],previous:mergeCurrentAndComparisonData([summary],"comparison_data")[0]} :false,
    };
    const baseResponse2 = {
      query,
      data: mergeCurrentAndComparisonData(data,"comparison_data") || [],
      lastRefreshTime: new Date().toISOString(),
      annotation: {},
      dataSource: "default",
      dbType: "bigquery",
      extDbType: "cubestore",
      external: false,
      slowQuery: false,
      total: total || 0,
      summary:summary ? {current:mergeCurrentAndComparisonData([summary],"current_data")[0],previous:mergeCurrentAndComparisonData([summary],"comparison_data")[0]} : false,
    };
  
    const resultSet = {
      loadResponse: {
        queryType: "regularQuery",
        results: [baseResponse1,baseResponse2],
        pivotQuery: query,
        slowQuery: false,
      },
      originalApiData: data,
      isWrapperApi: true,
      queryType: "regularQuery",
      loadResponses: [baseResponse1,baseResponse2],
      options: {},
      backwardCompatibleData: [[]],
      
      // Integrated tableColumns Method
      tableColumns(pivotConfig) {
        const firstRow = this.loadResponses[0]?.data?.[0] ?? {};
        const dimensions = this.loadResponses[0]?.query?.dimensions || [];
        let visibleColumns = Object.keys(firstRow);
        // include dimensions in visible columns if not present
        dimensions.forEach((dimension) => {
          if (!visibleColumns.includes(dimension)) {
            visibleColumns.push(dimension);
          }
        });
        let allColumnWithMetaData = getColumnsConfig(visibleColumns?.[0]?.split(".")?.[0],visibleColumns,true);  // Get columns config based on the first column name
        return allColumnWithMetaData
      },
  
      // Integrated tablePivot Method
      tablePivot(pivotConfig) {
        return this.loadResponses[0]?.data ?? [];
      },
    };
  
    return resultSet;
  };
  