import React from "react";
import { useState } from "react";
import { useGlobalContext } from "../../../../context/global";
import TableRb from "../../../Tables/tableRb";
import AddKeywordButton from "../../../../assets/svgs/plus_box.svg";
import { useEffect } from "react";
import axios from "axios";
import { useAuthHeader } from "react-auth-kit";

const SpBrandKeywordTable1 = () => {
  const {
    brandMatchTypes,
    brandKeywordsToBeShow,
    setBrandKeywordsToBeShow,
    brandKeywordsToBeAdded,
    setBrandKeywordsToBeAdded,
    brandKeywordsToBeFormatted,
    adGroupData,
    setAdGroupData,
    clientData,
    selectedCategory,
  } = useGlobalContext();

  const [dynamicHeaders, setDynamicHeaders] = useState([
    "Keywords",
    "Match Type",
  ]);
  const [brandKeywords, setBrandKeywords] = useState(
    JSON.parse(localStorage.getItem("brandKeywords")) ?? []
  );
  const authHeader = useAuthHeader();
  const token = authHeader();
  const { brand_keywords } = clientData;
  const {amz_default_bid} = adGroupData

  const clientId = JSON.parse(localStorage.getItem("clientId"));
  const default_bid = JSON.parse(localStorage.getItem("defaultBid"));

  useEffect(() => {
    generateKeywordsWithType(brandKeywords, brandMatchTypes);
  }, [brandMatchTypes]);

  const getSuggestedBidForKeywords = async (dataPassed) => {
    const url = `https://adyogiadsapi.adyogi.com/client/${clientId}:fetch-bid-recommendation?source=amazon`;
    const data = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(dataPassed),
    };
    try {
      const response = await fetch(url, data);
      const result = await response.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const generateKeywordsWithType = (currentWords, currentTypes) => {
    let oneMoreArray = [];
    let newArray = currentWords?.map((word) => {
      return currentTypes?.map((type) => {
        oneMoreArray.push({ text: word?.keyword, match: type });
      });
    });
    setBrandKeywordsToBeShow(oneMoreArray);
  };

  const addAll = async () => {
    // Format the words to for API Call
    const arrayOfFormattedKeywords = brandKeywordsToBeShow.map((keyword) => {
      return { type: `${keyword.match.toUpperCase()}`, value: keyword.text };
    });

    // Data for API Call

    const keywordsPosted = {
      "type": "SP_KEYWORD",
    "categories": selectedCategory,
    "keywords": arrayOfFormattedKeywords,
    "bidding_strategy": "LEGACY_FOR_SALES"
    }

    // API Call will be made here

    // API  Result

    const result = await getSuggestedBidForKeywords(keywordsPosted);

    let arrayToBeSet = [];
    arrayOfFormattedKeywords.map((keyword) => {
      for (let i = 0; i < result?.data.length; i++) {
        if (keyword.value === result?.data[i].keyword) {
          arrayToBeSet.push({
            match: keyword.type.toLowerCase(),
            userBid: amz_default_bid?? default_bid,
            bid: result?.data[i].recommended_suggested_bid ?? default_bid,
            text: keyword.value,
            status: "ENABLED",
            lowest: result?.data[i].upper_suggested_bid ?? default_bid,
            heighest: result?.data[i].lower_suggested_bid ?? default_bid,
          });
        }
      }
    });

    let arrayTObeUpdated = arrayToBeSet.filter((word) => {
      let keywordKey1 = `${word.text}-${word.match}`;
      for (let i = 0; i < brandKeywordsToBeAdded.length; i++) {
        let keywordKey2 = `${brandKeywordsToBeAdded[i].text}-${brandKeywordsToBeAdded[i].match}`;
        if (keywordKey1 === keywordKey2) {
          return false;
        }
      }
      return true;
    });

    // Final Data After removing the Duplication.
    const finalData = arrayTObeUpdated.filter(
      (thing, index, self) =>
        index ===
        self.findIndex((t) => t.text === thing.text && t.match === thing.match)
    );

    setBrandKeywordsToBeAdded((current) => {
      if (current.length > 0) {
        setAdGroupData({
          ...adGroupData,
          amz_keyword_targeting: current.concat(finalData),
        });
        return current.concat(finalData);
      } else {
        setAdGroupData({ ...adGroupData, amz_keyword_targeting: finalData });
        return finalData;
      }
    });
    setBrandKeywordsToBeShow([]);
  };

  const handleAdd = async (id) => {
    // In This Method We're checking on which page we're at For Adset Details and Adding keywords in another Table Logic is Dependent on the

    const { text, match } = id;
    const formattedWord = {
      type: `${match.toUpperCase()}`,
      value: `${text}`,
    };

    const addedword = brandKeywordsToBeAdded.filter((keyword) => {
      return keyword.text === text && keyword.match === match;
    });
    if (addedword.length === 0) {
      setBrandKeywordsToBeAdded([...brandKeywordsToBeFormatted, formattedWord]);
      // getSuggestedBidForKeywords(keywordsPosted);
      // Set up for making an API Call from here
        const formattedKeywords = [];
        formattedKeywords.push(formattedWord);
        const keywordsPosted = {
          "type": "SP_KEYWORD",
        "categories": selectedCategory,
        "keywords": formattedKeywords,
        "bidding_strategy": "LEGACY_FOR_SALES"
        }

      // API Call will be made here
      const result = await getSuggestedBidForKeywords(keywordsPosted);

      const {
        recommended_suggested_bid,
        lower_suggested_bid,
        upper_suggested_bid,
      } = result?.data[0];

      setBrandKeywordsToBeAdded([
        ...brandKeywordsToBeAdded,
        {
          text,
          match: match.toLowerCase(),
          bid: recommended_suggested_bid ?? default_bid,
          userBid: amz_default_bid ?? default_bid,
          status: "ENABLED",
          lowest: lower_suggested_bid ?? default_bid,
          heighest: upper_suggested_bid ?? default_bid,
        },
      ]);
      setAdGroupData({
        ...adGroupData,
        amz_keyword_targeting: [
          ...brandKeywordsToBeAdded,
          {
            text,
            match: match.toLowerCase(),
            bid: amz_default_bid ?? default_bid,
            status: "ENABLED",
          },
        ],
      });
      setBrandKeywordsToBeShow((current) => {
        return brandKeywordsToBeShow.filter((keyword) => {
          const keywordKey = `${keyword.text}-${keyword.match}`;
          const keyPair = `${text}-${match}`;
          return keyPair !== keywordKey;
        });
      });
    } else {
      alert(`The word is already added`);
    }
  };

  const tableBody = (
    <>
      <div className="generic-main-table-body-container">
        <table className="sp-product-targetting-table">
          <thead className="main-table-head">
            <tr className="table-heading-style">
              <td colspan={3} className="table-upper">
                <div className="float-left text-dark p-3">
                  {brandKeywordsToBeShow && brandKeywordsToBeShow.length} Added
                </div>
                
                <div className="float-right">
                  <button
                    type="button"
                    onClick={addAll}
                    className={
                      brandKeywordsToBeShow &&
                      brandKeywordsToBeShow.length !== 0
                        ? "add-all-btn-dark"
                        : "add-all-btn"
                    }
                  >
                    Add All
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              {dynamicHeaders.map((th, index) => {
                if (index === dynamicHeaders.length - 1) {
                  return (
                    <th
                      className="border border-right-white"
                      onClick={() => {
                        // setIsVisible(true)
                      }}
                    >
                      {th}
                    </th>
                  );
                }
                return <th className="border border-right-white">{th}</th>;
              })}
            </tr>
          </thead>
          <tbody className="table-body main-table-body">
            {brandKeywordsToBeShow &&
              brandKeywordsToBeShow.map((word, index) => {
                return (
                  <tr key={`${word.text}-${word.match}`} className="row-height">
                    <td className="tabledata-td">{word.text}</td>
                    <td className="tabledata-td">
                      <div className="d-flex gap-2 align-items-center">
                        <span>{word.match} </span>
                        <span onClick={() => handleAdd(word)} className={"cursor-pointer-global selected-icon-hover"}>
                          <img src={AddKeywordButton} alt="keyword add" />
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );

  return (
    <>
      <TableRb table={tableBody} />
    </>
  );
};

export default SpBrandKeywordTable1;
