import React, { useEffect, useState } from 'react';
import ModalReactB from './ModalReactB';
import "./overviewSettingModal.css";
import Form from 'react-bootstrap/Form';
import SwitchBtn from '../Buttons/switchBtn';
import ModalRb from './ModalRb';
// import closeIcon from "../../assets/icons/close_icon.png"
import closeIcon from "../../assets/svgs/close.svg";
import SectionTitle from '../sectionTitle';
import { useDispatch } from "react-redux";
import { dashboardActions } from '../../redux/slices/dashboardSlice';
const OverviewSettingModal = (props) => {
    const { defaultCards } = props;
    const dispatch = useDispatch();
    const [show, setShow] = useState(true);
    const [clickedModalClosedwithNoMinimumCardSelecte, setclickedModalClosedwithNoMinimumCardSelecte] = useState(false);
    const resetArrayDefaultCards = [{
        displayValue: "SPENDS",
        idValue: "spends"
    }, {
        displayValue: "IMPRESSIONS",
        idValue: "impressions"
    }, {
        displayValue: "CLICKS",
        idValue: "clicks"
    }, {
        displayValue: "ORDERS",
        idValue: "orders"
    }, {
        displayValue: "SALES VALUE",
        idValue: "sale_value"
    }, {
        displayValue: "CTR",
        idValue: "ctr"
    },]

    const [selectedCards, setSelectedCards] = useState(defaultCards ? defaultCards : [{
        displayValue: "SPENDS",
        idValue: "spends"
    }, {
        displayValue: "IMPRESSIONS",
        idValue: "impressions"
    }, {
        displayValue: "CLICKS",
        idValue: "clicks"
    }, {
        displayValue: "ORDERS",
        idValue: "orders"
    }, {
        displayValue: "SALES VALUE",
        idValue: "sale_value"
    }, {
        displayValue: "CTR",
        idValue: "ctr"
    },])
    const [isMinimumSixCardSelected, setIsMinimumSixCardSelected] = useState(true);
    // const [disableAllWidgetBtn, setDisableAllWidgetBtn] = useState(false);
    const [widgetBtnChecked, setwidgetBtnChecked] = useState(false);
    // const removeElement = (array, eleme) => {
    //     const index = array.indexOf(eleme);
    //     if (index > -1) {
    //         array.splice(index, 1);
    //         return array
    //     }
    // }

    useEffect(() => {
        if (defaultCards && defaultCards.length > 0) {
            setSelectedCards(defaultCards)
        }
    }, [defaultCards])


    useEffect(() => {
        props.selectedCardElementsInModal(selectedCards);
    }, [selectedCards])

    let modalHeading = [{
        displayValue: "SPENDS",
        idValue: "spends"
    }, {
        displayValue: "IMPRESSIONS",
        idValue: "impressions"
    }, {
        displayValue: "CLICKS",
        idValue: "clicks"
    }, {
        displayValue: "ORDERS",
        idValue: "orders"
    }, {
        displayValue: "SALES VALUE",
        idValue: "sale_value"
    }, {
        displayValue: "CTR",
        idValue: "ctr"
    }, {
        displayValue: "CVR",
        idValue: "cvr"
    }, {
        displayValue: "CPM",
        idValue: "cpm"
    }, {
        displayValue: "AOV",
        idValue: "aov"
    }, {
        displayValue: "CPC",
        idValue: "cpc"
    }, {
        displayValue: "ROAS",
        idValue: "roas"
    }, {
        displayValue: "ACOS",
        idValue: "acos"
    }, {
        displayValue: "",
        idValue: "all-widgets"
    },
    ]
    useEffect(() => {
        setwidgetBtnChecked(prevState => prevState)
    }, [])

    useEffect(() => {
        try {
            // if (!defaultCards) {
                const items = JSON.parse(localStorage.getItem("overviewSettings"));
                // const items = JSON.parse(localStorage.getItem("overviewSettings"));

                if (items.length > 0 ) {
                    setSelectedCards(items);
                }
            // }
        }
        catch (e) {
            console.warn(e);
        }
    }, [])

    useEffect(() => {
        if (selectedCards.length > 0) {
            localStorage.setItem("overviewSettings", JSON.stringify(selectedCards));
        }
        if (selectedCards.length >= 6 || (defaultCards && defaultCards.length <= selectedCards.length)) {
            setIsMinimumSixCardSelected(true)
        } else {
            setIsMinimumSixCardSelected(false)
        }

    }, [selectedCards])

    const onClickHandler = (event) => {
        setclickedModalClosedwithNoMinimumCardSelecte(false)
        let selecElement = [...selectedCards];

        if (event.target.checked) {

            if (event.target.id === "all-widgets") {

                selecElement = [];
                modalHeading.map((Arrayobj) => {
                    selecElement.push(Arrayobj);
                    // if (Arrayobj.idValue === event.target.id) {
                    //     selecElement.push(Arrayobj);
                    // }

                })
                // setDisableAllWidgetBtn(true);
                setwidgetBtnChecked(true)

            }
            else {
                modalHeading.map((Arrayobj) => {
                    if (Arrayobj.idValue === event.target.id) {
                        selecElement.push(Arrayobj);
                    }

                })
            }
        }
        else if (event.target.id === "all-widgets") {
            selecElement = [];
            selecElement = resetArrayDefaultCards;

        }
        else {
            const test = selecElement.splice(selecElement.findIndex(a => a.idValue === event.target.id), 1);

            // [...selecElement].map((obj) => {
            //     if (obj.idValue === event.target.id) {
            //         let newEle = removeElement(...selecElement, obj)


            //         selecElement = newEle;
            //     }
            // })
            // let newEle = removeElement([...selecElement], event.target.id)

            // setDisableAllWidgetBtn(false);
            setwidgetBtnChecked(false)
        }
        setSelectedCards(selecElement);
        props.selectedCardElementsInModal(selecElement);

        // modalHeading.map((el) => {
        //     if (el.idValue === event.target.id) {
        //         setSelectedCards([]);
        //         if (event.target.checked) {
        //             selectedCards.push(el.idValue);
        //             setSelectedCards(selectedCards);
        //         } else if (event.target.checked === false) {
        //             var index = selectedCards.indexOf(el.idValue);
        //             if (index !== -1) {
        //                 let test = selectedCards.splice(index, 1);
        //                 setSelectedCards(selectedCards)
        //             }
        //         }
        //         props.selectedCardElementsInModal(selectedCards);
        //     }
        // })
    };


    const closeBtnHandler = () => {
        if (selectedCards.length >= 6 || (defaultCards && (selectedCards.length >= defaultCards.length))) {
            setIsMinimumSixCardSelected(true)
            dispatch(dashboardActions?.dasboardOverviewCardSettingModalHide());
        } else {
            setclickedModalClosedwithNoMinimumCardSelecte(true)
            setIsMinimumSixCardSelected(false)
        }
    }
    let modalHeadingBody = <div className="overview-setting-head" >
        <div >
            <div className='d-flex flex-row align-items-center justify-content-between'>
                <SectionTitle sectionTitleHeading="Overview"></SectionTitle>

                <div className='overview-set-modal-close-icon-div'>
                    <img className="overview-set-modal-close-icon-container" src={closeIcon} onClick={closeBtnHandler}></img>
                </div>
            </div>
            <p className="small-grey-text text-start">Select the primary widgets</p>
        </div>


    </div>

    let mdalBodyJSX =

        <div className="overview-seeting-modal widgets-options">
            {/* <div className="overview-head">
                <div>
                    <p>Overview</p>
                    <p className="small-grey-text">Select the primary widgets</p>
                </div>


            </div> */}
            {/* <div className="dash-layout-close-icon-container">
                <img src={closeIcon} ></img>
            </div> */}

            <div className="d-flex flex-column pt-3 overview-modal-dialogue">
                <div className="instruction-container border-bottom w-100">
                    <div className={isMinimumSixCardSelected ? "instruction-heading-when-min-six-card-selected" : clickedModalClosedwithNoMinimumCardSelecte ? "instruction-heading-when-min-six-card-not-selected-and-clicked-closed" : "instruction-heading-when-min-six-card-not-selected"}>Minimum {defaultCards ? defaultCards.length : 6} widgets required</div >
                    <div>{modalHeading.map((hd, i) => {
                        if (hd.idValue === "all-widgets")
                            return <SwitchBtn
                                className="overview-instruction-set-switch-btn" label="All Widgets" id="all-widgets" htmlFor="all-widgets" onChange={onClickHandler} checked={(selectedCards.find((e) => e.idValue === hd.idValue) ? true : false)}>

                            </SwitchBtn>
                    })
                    } </div>

                </div>
                <div className="d-flex flex-column align-item-center w-100"  >
                    {
                        modalHeading.map((hd, i) => {
                            if (i === (modalHeading.length - 1)) {
                                return
                            } if (i === (modalHeading.length - 2)) {
                                return <div className=" pb-2 w-100" key={hd + i}>
                                    <SwitchBtn className="overview-set-switch-btn" checked={(selectedCards.find((e) => e.idValue === hd.idValue) ? true : false)} label={hd.displayValue} id={hd.idValue} onChange={onClickHandler} ></SwitchBtn>
                                </div>
                            } else {
                                return <div className=" pb-2 border-bottom w-100" key={hd + i}>
                                    <SwitchBtn className="overview-set-switch-btn" checked={(selectedCards.find((e) => e.idValue === hd.idValue) ? true : false)} label={hd.displayValue} id={hd.idValue} onChange={onClickHandler} ></SwitchBtn>
                                </div>
                            }

                        })
                    }

                </div>
            </div>
        </div>

    return <div >{

        <ModalRb
            show={props.show}
            ModalMainBody={mdalBodyJSX}
            centered={true}
            className="modal-H"
            contentClassName="overviwSettingModal"
            bsPrefix="modal-header"
            modalBody="modal-body"
            // onHide={props.onHide}
            Modalheading={modalHeadingBody}
            hideCloseIcon={true}
        // dialogClassName="overviewDialogue"
        // bsPrefix={props.modalHeader}
        />
    }</div>;
}



export default OverviewSettingModal;