import { useNavigate } from "react-router-dom"

/**
 *@Note This function will check whether the user is authorized or not and if user is not authorized it clears the old stored token and redirects the user to login ( Login route we need to handle manually)
 * @param {*} data ( API Result)
 * @returns true if response contains 401 else false 
 */

// This function will check whether the user is authorized or not and if user is not authorized it clears the old stored token and redirects the user to login ( Login route we need to handle manually)
const redirect = (data) => {
    if (data?.statusCode === 401) {
        localStorage.removeItem('logintoken')
        return true
    }
    return false
}

export default redirect