import React from "react";
import styles from "./CalloutCard.module.css";
import CalloutFilterIcon from "../../../../assets/icons/and_filter_icon.png";

const CalloutCard = ({ title, children, primaryAction }) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.textContainer}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.description}>{children}</p>
          {primaryAction && (
            <button
              className={"primary-active-btn"}
              onClick={primaryAction.onClick}
            >
              {primaryAction.content}
            </button>
          )}
        </div>
        <div className={styles.iconContainer}>
          {/* Replace with your icon or image */}
          <img src={CalloutFilterIcon} alt="Icon" className={styles.icon} />
        </div>
      </div>
    </div>
  );
};

export default CalloutCard;
