import "./radioInputGroup.css";

export default function RadioInputGroup(props) {
  const handleChange = (event) => {
    props.setState(event.target.attributes.datavalue.value);
    if(props.handleChange) {
      props.handleChange(event.target.attributes.datavalue.value)
    }
  };

  return (
    <>
      <div className="input-box ">
        <div className="btnGroup">
          {props.radios &&
            props.radios.map((radio, index) => {
              if (props.selected) {
                if (props.selected === radio) {
                  return (
                    <div key={props.name + index} className="">
                      <input
                        type="radio"
                        onChange={handleChange}
                        datavalue={radio}
                        id={radio}
                        name={props.name}
                        defaultChecked={true}
                      />
                      <label
                        className={
                          index === 0
                            ? "radioBtn radius-left"
                            : index === props.radios.length
                              ? "radioBtn radius-right"
                              : "radioBtn"
                        }
                        htmlFor={radio}
                      >
                        {radio}
                      </label>
                    </div>
                  );
                } else {
                  return (
                    <div key={props.name + index} className={""}>
                      <input
                        datavalue={radio}
                        onChange={handleChange}
                        type="radio"
                        id={radio}
                        name={props.name}
                        disabled
                      />
                      <label
                        className={
                          index === 0
                            ? "radioBtn radius-left"
                            : index === props.radios.length
                              ? "radioBtn radius-right"
                              : "radioBtn"
                        }
                        htmlFor={radio}
                      >
                        {radio}
                      </label>
                    </div>
                  );
                }
              } else {
                return (
                  <div key={props.name + index} className="">
                    <input
                      checked={radio === props.state ? true : false}
                      datavalue={radio}
                      onChange={handleChange}
                      type="radio"
                      id={radio}
                      name={props.name}
                    />
                    <label
                      className={
                        index === 0
                          ? "radioBtn radius-left"
                          : index + 1 === props.radios.length
                            ? "radioBtn radius-right"
                            : "radioBtn"
                      }
                      htmlFor={radio}
                    >
                      {" "}
                      {radio}
                    </label>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </>
  );
}
