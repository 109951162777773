export default function automationLogsFilterParser (filters,campaignData,adsetData) {
    const KEYS_MAPPER = {
        campaigns: 'rule_entity_tracking.rule_campaignId',
        adsets: 'rule_entity_tracking.rule_adGroupId',
        status:'rule_entity_tracking.rule_decision_status',
        ruleName:'rule_entity_tracking.rule_name',
    }
    const valueMapperFunc = (selectedData,data,key) => {
        switch (key) {
            case "campaigns":
                return selectedData?.map((item) => data?.find((campaign) => campaign?.name === item)?.id);  
            case "adsets":
                    return selectedData?.map((item) => data?.find((campaign) => campaign?.name === item)?.id);              
            default:
                return selectedData;
        }
    }
    return Object?.entries(filters)?.reduce((acc,[key, value]) => {
        const operator = ['campaigns','adsets'].includes(key) ? 'equals' : 'contains';
        if(value?.length && key!=='ruleName') {
            acc?.push( {
                member: KEYS_MAPPER[key],
                operator:operator,
                values:valueMapperFunc(value,key==='campaigns' ? campaignData : adsetData,key)
            })
        } 
        if(key==='ruleName' && value[0]!=='' && value?.length) { 
            acc?.push( {
                member: KEYS_MAPPER[key],
                operator:operator,
                values:valueMapperFunc(value,key==='campaigns' ? campaignData : adsetData,key)
            }
        )
        }
        return acc; 
    },[])
}