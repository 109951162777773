import { masterApi } from "../../../../apis/masterApi";
import parseData from "../../../Graphs/parseData";
import moment from "moment";
import {formatDateFn} from '../../../../helper/common/formatDateForAPI'

export async function getMasterTargetingGraphData() {
  try {
    const data = {
      level: "account",
      platform: "amazon",
      start_date: `${formatDateFn(moment().subtract(42, "days")._d)}`,
      end_date: `${formatDateFn(moment().subtract(1, "days")._d)}`,
      fields: `spends,target_type,sale_value,week `,
      sorting_order: "asc",
      time_increment: "week",
      order_by: "week",
      group_by: "target_type",
      // filtering: window.encodeURI(`[{"field_name":"campaign_type","operator":"IN","values":["${campaignData?.campaign_type}"]}]`),
    };
    const result = await masterApi(data);
    const returnedData = parseData(result?.data?.data);
    return returnedData
  } catch (error) {
    return error
  }
}
