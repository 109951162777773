import React from "react";
import { useState, useCallback } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TemplateCard from "../../components/Automations/TemplateCard/TemplateCard";
import PrimaryBtnRB from "../../components/Buttons/primaryBtnRB.js/primaryBtnRB";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import SectionTitle from "../../components/sectionTitle";
import { defaultTemplateConfigs } from "../../constant/Automations/defaultTemplateConfigs";

const AutomationsTemplate = () => {
  const TabsList = ["Bidding", "Budget"];
  const [activeKey, setActiveKey] = useState("Bidding");
  const { efficiency } = defaultTemplateConfigs;
  const navigate = useNavigate();

  const chooseCampaignClick = (data) => {
    navigate(`/automations/create-rule?ruleFor=${data}`)
  }

  // This will set the active Tab
  const handleActiveTab = useCallback(
    (tab) => {
      if (tab !== activeKey) {
        setActiveKey(tab);
      }
    },
    [activeKey]
  );

  return (
    <div className="main-content-container-oncolapse-mode">
      <div className="d-flex justify-content-between align-items-center w-100">
        <SectionTitle sectionTitleHeading={"Automation Templated Rules"} />
      </div>
      {/* <div className="tabs-container border-bottom mt-5">
        <CustomTabs
          tabsList={TabsList}
          handleActiveTab={handleActiveTab}
          activeTab={activeKey}
          isIconRequired={true}
        />
      </div> */}
      <hr />
      <div className="row mt-4">
        <div className="col-lg-3">
          <SectionTitle sectionTitleHeading={"Bid Automations"} />
        </div>
        {efficiency?.map((data) => {
          return (
            <div className="col-lg-3" key={data?.ruleHeading}>
              <TemplateCard {...data} chooseTemplateClick={chooseCampaignClick}/>
            </div>
          );
        })}
      </div>
      <hr></hr>
      <div className="row mt-4">
        <div className="col-lg-3">
          <SectionTitle sectionTitleHeading={"Campaign Automations"} isComingSoon={true} />
        </div>
        {efficiency?.map((data) => {
          return (
            <div className="col-lg-3" key={data?.ruleHeading}>
              <TemplateCard {...data} />
            </div>
          );
        })}
      </div>
      <hr></hr>
      <div className="row mt-4">
        <div className="col-lg-3">
          <SectionTitle
            sectionTitleHeading={"Enhance Branding"}
            isComingSoon={true}
          />
        </div>
        {efficiency?.map((data) => {
          return (
            <div className="col-lg-3" key={data?.ruleHeading}>
              <TemplateCard {...data} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AutomationsTemplate;
