import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./breadcrumb.css";
import { Link } from "react-router-dom";
import { masterApi } from "../../apis/masterApi";
import moment from "moment/moment";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import info from '../../assets/svgs/info.svg'

function CustomBreadcrumb({ items }) {
  var iterationLevel = 0;

  function checkStatus(json) {
    return json.some(item => item.status === true);
  }
  const [lastfetched, setLastfetched] = useState("")
  useEffect(()=> {
    getCursorData()
  })
  async function getCursorData() {
    const cursorInput = {
      cursor_purpose: "AMAZON_STATS_HOURLY",
      source: "amazon",
    };
    const cursor =  await masterApi(cursorInput, "cursor", false);
    let cursorData = cursor?.data?.data.filter(item => item.last_fetch_status === 'SUCCESS')
    if (cursorData.length > 0) {
      const dataPulledDate = cursorData[0].data_pulled_date;
      if(dataPulledDate) {
        setLastfetched(moment.utc(dataPulledDate).local().format("Do MMM YYYY, h:mm a"))
      }
    } else {
        console.log("No data with status SUCCESS found.");
    }
  }
  function getChildData(item, index) {
    return (
      <>
        <div className="d-flex" key={index}>
          {item.hasOwnProperty("href") ? (
            <Link to={item.href}>
              <div className="breadcrumbItem">{item.label}</div>
            </Link>
          ) : (
            <div className="breadcrumbItem">{item.label}</div>
          )}
        </div>
        {item.pages && item.pages.length > 0 && checkStatus(item.pages) ? (
          <>
            <Dropdown size="sm" className="breadcrumbDropdownWrapper">
              <Dropdown.Toggle
                className="breadcrumbItem seperator mx-1 position-relative"
                variant="info"
                id="dropdown-basic"
              >
                &gt;
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {item.pages.map((page, pageIndex) => (
                  <>
                    <Dropdown.Item href={page?.href}>
                      {page.label}
                    </Dropdown.Item>
                  </>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <div className="d-none">{(iterationLevel = ++iterationLevel)}</div>
            {
              item.pages.map((page, pageIndex) => (
                <>{page.status ? getChildData(page) : ""}</>
              ))}
          </>
        ) : (
          ""
        )}
      </>
    );
  }
  return (
    <div className="d-flex mb-2 pb-2 gap-3">
      <div className="d-flex">{items.map((item, index) => (
        <>{getChildData(item, index)}</>
      ))} </div>
      {lastfetched && (
        <>
          <div
            className="text-muted text-small"
            data-tooltip-id="my-tooltip" 
            data-tooltip-content="The displayed time is based on your system's time zone, and there might be a data discrepancy of up to 45 minutes"
          >
            <small>Data last updated at {lastfetched} <img src={info} alt="*" /></small>
          </div>
          <ReactTooltip id="my-tooltip" style={{zIndex:"999999", fontSize:"12px", padding:"8px"}} effect="solid" place="top" variant="dark" />
        </>
      )}
    </div>
  );
}
export default CustomBreadcrumb;
