import { useEffect, useState } from "react";
import { useGlobalContext } from "../../context/global";
import {
  TOAST_POSITION_TOP_RIGHT,
  TOAST_SUCCESS,
} from "../../constant/HardTypes";
import pusherInstance from "../../services/pusher";
import {toast} from 'react-toastify';

export default function Services(props) {
  const { showAlert, clientId } = useGlobalContext();
  const [focusedTab, setFocusedTab] = useState(true);

  function subscribeToPusher(channelId) {
    try {
      const channel1 = pusherInstance.subscribe(channelId);
      channel1.bind("my-event", function (data) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
        //   backgroundColor: TOAST_SUCCESS,
        //   title: "Success",
        //   description: data,
        // });
        toast.success(`${data}`)
      });
    } catch (error) {
      console.log("Error Occured While Subscribing to Pusher" + error);
    }
  }

  function unsubscribeFromPusher(channelId) {
    try {
      pusherInstance.unsubscribe(channelId);
    } catch (error) {
      console.log("Error occured while un-subscribing from pusher channel!" + error);
    }
  }

  useEffect(() => {
    const handleFocus = () => {
      setFocusedTab(true);
    };

    const handleBlur = () => {
      setFocusedTab(false);
    };

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus",handleFocus);
      window.removeEventListener("blur",handleBlur);
    };
  },[]);

  useEffect(() => {
    // Subscribe To Pusher
    if (focusedTab) {
      subscribeToPusher(clientId);
    } 
    else {
        // Unsubscribe From Pusher
      unsubscribeFromPusher(clientId);
    }
    return () => {
        // Clean Up subscription 
      unsubscribeFromPusher(clientId);
    };
  }, [clientId, focusedTab]);

  

  return props.children;
}
