function deepCompare(obj1, obj2) {
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
          const nestedArrayChanges = deepArrayCompare(obj1[key], obj2[key]);
          if (nestedArrayChanges) {
            return true;
          }
        } else if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
          const nestedChanges = deepCompare(obj1[key], obj2[key]);
          if (nestedChanges) {
            return true;
          }
        } else if (obj1[key] !== obj2[key]) {
          return true;
        }
      }
    }
    return false;
  }

  // This function will recursively iterate through array and will check for nested array and objects comparison
function deepArrayCompare(firstArray, secondArray) {
    if (firstArray.length !== secondArray.length) {
      return true;
    }
  
    for (let i = 0; i < firstArray.length; i++) {
      if (Array.isArray(firstArray[i]) && Array.isArray(secondArray[i])) {
        const nestedArrayChanges = deepArrayCompare(firstArray[i], secondArray[i]);
        if (nestedArrayChanges) {
          return true;
        }
      } else if (typeof firstArray[i] === 'object' && typeof secondArray[i] === 'object') {
        const nestedChanges = deepCompare(firstArray[i], secondArray[i]);
        if (nestedChanges) {
          return true;
        }
      } else if (firstArray[i] !== secondArray[i]) {
        return true;
      }
    }
  
    return false;
}

export default function productsetDataChangesChecker (oldObject,newObject) {
    const changes = {};
  
    for (const key in oldObject) {
      if (oldObject.hasOwnProperty(key) && newObject.hasOwnProperty(key)) {
        if (Array.isArray(oldObject[key]) && Array.isArray(newObject[key])) {
          const nestedArrayChanges = deepArrayCompare(oldObject[key], newObject[key]);
          if (nestedArrayChanges) {
            changes[key] = newObject[key]
          }
        } else if (typeof oldObject[key] === 'object' && typeof newObject[key] === 'object' && oldObject[key] !== null && newObject[key] !== null) {
          const nestedChanges = deepCompare(oldObject[key], newObject[key]);
          if (nestedChanges) {
            changes[key] = newObject[key]
          }
        } else if (oldObject[key] !== newObject[key]) {
          changes[key] = newObject[key]
        }
      }
    }
    return changes;
}