export const allConfirmationButtonsIds = {
 "campaign-list":{
    "Are you sure you want to PAUSE Campaign":"pause-campaign-confirm",
    "Are you sure you want to ENABLED Campaign":"enable-campaign-confirm",
    "Are you sure you want to Change Budget":"update-budget-confirm",
    "Are you sure you want to Change Campaign Name":"update-campaign-name-confirm",
    "Are you sure you want to Paused adset":"pause-adset-confirm",
    "Are you sure you want to Enable adset":"enable-adset-confirm",
    "Are you sure you want to Update Adset Name":"update-adset-name-confirm",
    "Are you sure you want to Change Bidding Strategy":"change-bid-strategy-confirm"
 },
 "Targeting":{
   "Are you sure that you want to Pause this?":"pause-targeting-confirm",
   "Are you sure that you want to update this?":"change-current-bid-confirm",
   "Are you sure that you want to pause selected targetings?":"bulk-pause-targeting-confirm",
   "Are you sure that you want to enable selected targetings?":"bulk-enable-targeting-confirm"
 },
 "placement-bid-optimiser":{
  "Are you sure that you want to update current bid?":"placement-current-bid-update-confirm"
 },
 "product-ads":{
  "Are you sure you want to Enable the selected Ads?":"bulk-enable-ads-confirm",
  "Are you sure you want to Pause the selected Ads?":"bulk-pause-ads-confirm",
  "Are you sure you want to Enable uploaded asins?":"bulk-enable-ads-csv-confirm",
  "Are you sure you want to Pause uploaded asins?":"bulk-pause-ads-csv-confirm"
 }
}