import "../discountAnalysis/DiscountAnalysis.css";
import { Stack } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";
import { useGlobalContext } from "../../context/global";
import cubejs from "@cubejs-client/core";
import useToken from "../../helper/Reports/useToken";
import SectionTitle from "../../components/sectionTitle";
import Loader from "../../components/loader";
import TableRb from "../../components/Tables/tableRb";
import { useDeepCompareMemo } from "use-deep-compare";
import {
  humanize,formatTableData,sortArrayOfObjectsByLabel
} from "../../components/Reports/Components/utils";
import Spinner from 'react-bootstrap/Spinner';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import movingUp from "../../assets/svgs/arrow_upward_green.svg";
import movingDown from "../../assets/svgs/arrow_downward_red.svg";
import Breadcrumb from '../../components/Breadcrumb/ReportsBreadCrumb';

const useCategoryData = (resultSet) => {
  const [tableColumns, dataSource] = useDeepCompareMemo(() => {
    const columns = resultSet ? resultSet.tableColumns({}) : [];
    return [
      columns,
      formatTableData(columns, resultSet ? resultSet.tablePivot({}) : []),
    ];
  }, [resultSet]);

  return { tableColumns, dataSource };
};

const PriceHistory = (props) => {
  const [token, refresh] = useToken("");
  const { cubeToken, setCubeToken } = useGlobalContext();

  const { clientAccountId } = useGlobalContext();
  const cubejsApi = cubejs(cubeToken, {
    apiUrl: process.env.REACT_APP_CUBE_JS_URL,
  });

  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );

  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );

  const [table, setTable] = useState("amazon_competitive_price_tracker_table");


  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [cubeQueryMeasure, setCubeQueryMeasure] = useState([
    "amazon_competitive_price_tracker_table.latest_price",
    "amazon_competitive_price_tracker_table.latest_price_1d",
    "amazon_competitive_price_tracker_table.latest_price_2d",
    "amazon_competitive_price_tracker_table.latest_price_3d",
    "amazon_competitive_price_tracker_table.latest_price_4d",
    "amazon_competitive_price_tracker_table.latest_price_5d",
    "amazon_competitive_price_tracker_table.latest_price_6d",
    "amazon_competitive_price_tracker_table.latest_price_7d"
  ]);

  const [cubeQueryOrder, setCubeQueryOrder] = useState([["amazon_competitive_price_tracker_table.latest_price","desc"],["amazon_competitive_price_tracker_table.latest_price_1h","desc"],["amazon_competitive_price_tracker_table.latest_price_2h","desc"],["amazon_competitive_price_tracker_table.latest_price_12h","desc"],["amazon_competitive_price_tracker_table.latest_price_24h","desc"],["amazon_competitive_price_tracker_table.asin","desc"]]);

  const [cubeQueryDimensions, setCubeQueryDimensions] = useState([
    "amazon_competitive_price_tracker_table.asin",
    "amazon_competitive_price_tracker_table.item_name"
  ]);

  const [cubeQueryFilters, setCubeQueryFilters] = useState([
    {
      member: "amazon_competitive_price_tracker_table.profile_id",
      operator: "equals",
      values: [clientAccountId],
    },
  ]);


  const [resultSet, setResultSet] = useState();

  useEffect(() => {
    //table data
    if (cubeToken) {
      cubejsApi.load({
            measures: cubeQueryMeasure,
            order: cubeQueryOrder,
            dimensions: cubeQueryDimensions,
            filters: cubeQueryFilters,
            timeDimensions: [],
            timezone: "Asia/Kolkata",
            limit: 100,
            renewQuery:true
          },
          {
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((result) => {
            setResultSet(result);
        }).catch((error) => {
          if(error.message === 'Invalid token') {
              setCubeToken(false)
              localStorage.setItem("cubeToken", null);
              refresh()
          }
        });
  }
  }, [cubeToken,cubeQueryDimensions]);
  
 

  const [showCustomDateBox, setShowCustomDateBox] = useState(false);

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const calenderIconRefForCampaignlist = useRef(null);

  const closeCalender = (e) => {
    if (
      calenderIconRefForCampaignlist.current &&
      showCustomDateBox &&
      !calenderIconRefForCampaignlist.current.contains(e.target)
    ) {
      setShowCustomDateBox(false);
    }
  };

  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
  };


  const { tableColumns, dataSource } = useCategoryData(resultSet);
  useEffect(() => {
    document.addEventListener("mousedown", closeCalender);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeCalender);
    };
  }, [showCustomDateBox]);


  useEffect(() => {
    setIsFilterVisible(filterModalInCampaignListVisibilty);
  }, [filterModalInCampaignListVisibilty]);


  const getDiscountDataList = (discount_data) => {
    let final_arr = [];
    discount_data.forEach(obj => {
      Object.keys(obj).forEach(dateKey => {
        obj[dateKey].forEach(item => {
          const label = item.label;
          const data = [
            { date: dateKey, revenue: item.revenue, revenue_per: item.revenue_per, unit_sold: item.units_sold }
          ];

          const existingCategory = final_arr.find(item => item.discount_category.label === label);
          if (existingCategory) {
            existingCategory.discount_category.data.push(...data);
          } else {
            final_arr.push({
              discount_category: {
                label: label,
                data: data
              }
            });
          }
        });
      });
    });
    return final_arr;
  }
  function getPercentageChange(oldValue, newValue) {
    const percentageChange = (((newValue - oldValue) / oldValue) * 100).toFixed(
      1
    );
    return percentageChange;
  }
  const getPercentageChangeDiv = (value) => {
    if (isNaN(value)) { 
        return ""; 
      } else if (!isFinite(value)) { 
        return ""; 
      } 
    const color = value >= 0 ? "#047857" : "#B91C1C";
    const img = value >= 0 ? movingUp : movingDown;
  
    return (
      <div className="percentage-up-down-container reports">
        <span className="percentage-desc" style={{ color }}>
          {Math.abs(value)}%
        </span>
        <span className="percentage-icon">
          <img src={img} alt="movingdown" />
        </span>
      </div>
    );
  };
  
  const getTdValue = (td, columnindex, data) => {
    if(columnindex<2) {
        return <td>{td}</td>
    } else {
        const nextValue = parseFloat(data[columnindex + 1]);
        let percentageChange = 0;
        if(!isNaN(nextValue)) {
            percentageChange = getPercentageChange(nextValue,td)
        }
        return <>
            <td style={Math.abs(percentageChange) >= 10 ? {backgroundColor: '#FF8989',color:"#CC0000"} : {}}>
                <div className="d-flex justify-content-between gap-3 align-items-center">
                    <div>{td}</div>
                    {!isNaN(nextValue) && <div>{getPercentageChangeDiv(percentageChange)}</div>}
                </div>
            </td>
        </>
    }
  }

  const categoryTableBody = (
    <>
      <div className="campaign-list-table-data">
        <Stack className="campaign-list-main-table">
          <div
            className={
              "main-targeting-table-body-container listinng-table d-hide"
            }
          >
            {dataSource?.length ?
            <table className="mt-table d-hide">
              <thead className="fixed-table category-fix-table">
                <tr className="main-table-row-fixed category-header-fix">
                {tableColumns?.map((row, mainIndex) => {
                      return (
                        <>
                        <th>{humanize(row.shortTitle)}</th>
                        </>
                      );
                    })}
                    <th style={{minWidth:'150px'}}>Price Trend</th>
                </tr>
              </thead>
              <tbody className="another-tbody">
                
              {dataSource?.map((tr, index1) => {
                return (
                  <React.Fragment key={index1 + 1}>
                    <tr
                      key={index1 + "-row"}
                    >
                      {Object.values(tr).map((td, columnindex) => {
                        return getTdValue(td, columnindex, Object.values(tr))
                      })}
                      <td style={{padding:'4px ! important'}}>
                      <Sparklines data={Object.values(tr).filter(value => {return !isNaN(parseInt(value));})}>
                        <SparklinesLine style={{ strokeWidth: 4, stroke: "#166184" }} />
                    </Sparklines>
                      </td>
                      </tr>
                      </React.Fragment>
                      )
              })}
                
              </tbody>
            </table>
            :
              <div
              className="d-flex w-100 h-50 justify-content-center align-items-center flex-column gap-1"
              style={{ minHeight: "200px" }}
            >
              <div>
                <Spinner animation="border" role="status" variant="loader-variant">
                </Spinner>
              </div>
              <div className="text-loader-variant">Loading...</div>
            </div>
            }
          </div>
        </Stack>
      </div>
    </>
  )

  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
      <Loader visible={isLoaderVisible}></Loader>      

      <Breadcrumb />
      <SectionTitle sectionTitleHeading='Price History'></SectionTitle>

     

      <div className="">
        <div className="campaign-list-table-data mb-3">
          <Stack className="campaign-list-main-table">
            <TableRb table={categoryTableBody}></TableRb>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default PriceHistory;
