import Form from 'react-bootstrap/Form';
import { useGlobalContext } from '../../../context/global';
import "./checkbox.css";
function CheckboxRb(props) {
    const { matchTypes, setMatchTypes } = useGlobalContext();

    const isChild = props.className && props.className.includes('child') ? true : false;
    const hoverClass = !isChild ? (props.checked ? `innput-container-cb  checkbox-check-bg` : `innput-container-cb`) : `innput-container-cb ${props.classes ? props.classes : ``}`
    return (
        <div className="checkbox-rb-container "  title={props?.title ?? ''} >
            <Form className='main-container-cb'>
                <div className={hoverClass}>
                <Form.Check
                    className={props.BD ? "form-check checkbox-xl" : props.className ? `form-check ${props.className}` : "form-check"}
                    inline
                    // label={props.label}
                    name={props.name}
                    value={props.value}
                    type={"checkbox"}
                    id={props.id}
                    bsSwitchPrefix="form-switch"
                    onChange={props.BrandKeyword ? props.handleChangeNow : props.SPKeyword ? props.handleChange : props.onChange}
                    checked={props.checked}
                    defaultChecked={props.index === 1 || props.index === 2 ? true : false}
                    disabled={props.disabled}
                />
                </div>
                <div className= {props.inputLabelClass ? `${props.inputLabelClass} + 'innput-label-cb'` : 'innput-label-cb'}>
                <label htmlFor={props.id}>{props.label}</label>
                </div>
            </Form>
        </div>
    );
}

{/* <Form>
<Form.Check
    className={props.BD ? "form-check checkbox-xl" : props.className ? `form-check ${props.className}` : "form-check"}
    inline
    label={props.label}
    name={props.name}
    value={props.value}
    type={"checkbox"}
    id={props.id}
    bsSwitchPrefix="form-switch"
    onChange={props.BrandKeyword ? props.handleChangeNow : props.SPKeyword ? props.handleChange : props.onChange}
    checked={props.checked}
    defaultChecked={props.index === 1 || props.index === 2 ? true : false}
    disabled={props.disabled}
/>
</Form> */}
export default CheckboxRb;