import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import SplitButton from 'react-bootstrap/SplitButton';
import "./splitDropdownBtn.css";

const SplitDropdownBtn = (props) => {
    return <div className={props.className ? props.className : "splibtn-container"}>
        {/* <InputGroup className="mb-3"> */}

        <SplitButton
            variant="outline-secondary"
            title={props.btnTitle}
            id={props.id}
            drop={props.drop ? props.drop : "down"}
            label={props.label}
            toggleLabel={"Toggle dropdown"}

        >
            {props.options ? props.options.map((op,index) => {
                return <div> <Dropdown.Item key={index+1}>{op.name?op.name:op}</Dropdown.Item></div>
            })
                :
                <div>
                    <Dropdown.Item href="#">Another action</Dropdown.Item>
                    <Dropdown.Item href="#">Something else here</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="#">Separated link</Dropdown.Item>
                </div>
            }  </SplitButton>
        {/* <Form.Control aria-label="Text input with dropdown button" /> */}
        {/* </InputGroup> */}
    </div>;
}

export default SplitDropdownBtn;