import React from "react";
import "./linecolumnGraph.css";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  registerables
} from "chart.js";

import ChartDataLabels from 'chartjs-plugin-datalabels';

const LineColumnGraph = ({
  LineData,
  ColumnData1,
  ColumnData2,
  ColumnData3,
  ColumnData4,
  XaxisData,
}) => {
  const lineWidth = 1.5;
  const chartbarThickness = 36;
  const chartborderRadius = 8;
  // let series = [
  //   {
  //     name: "Prospect",
  //     type: "column",
  //     data: ColumnData2 ?? [],
  //     stacked: true,
  //     borderRadius: {
  //       topLeft: 10,
  //       topRight: 10,
  //     },
  //   },
  //   {
  //     name: "ROAS",
  //     type: "line",
  //     data: LineData ?? [],
  //   },
  //   {
  //     name: "Retarget",
  //     type: "column",
  //     data: ColumnData1 ?? [],
  //     stacked: true,
  //     // stackType: "100%"
  //   },
  // ];

  // function sumTwoArrays(arr1, arr2) {
  //   if (arr1?.length !== arr2?.length)
  //     throw new Error("Array Must be of same length");
  //   return arr1?.map((val, index) => parseFloat(val) + parseFloat(arr2[index]));
  // }

  // const max =
  //   ColumnData1 &&
  //   ColumnData2 &&
  //   Math.max(...sumTwoArrays(ColumnData1, ColumnData2));

  // const prospectRoasArray = ColumnData3 && ColumnData3
  // const retargetRoasArray = ColumnData4 && ColumnData4

  // const options = {
  //   chart: {
  //     height: 300,
  //     stacked: true,
  //     type: "line",
  //     toolbar: {
  //       show: false,
  //     },
  //     stroke: {
  //       show: true,
  //       width: [5, 7, 5],
  //       curve: "stepline",
  //       dashArray: [0, 8, 0],
  //     },
  //   },
  //   tooltip: {
  //     enabled: true,
  //     followCursor:false,
  //     fixed:{
  //       enabled:false,
  //       position:"topRight",
  //       offsetX:0,
  //       offsetY:15
  //     },
  //     custom:function({series, seriesIndex, dataPointIndex, w,}) {
  //       console.log(series,seriesIndex,dataPointIndex,w)
  //       const prospectSpends = series[0][dataPointIndex];
  //       const retargetingSpends = series[2][dataPointIndex];
  //       const totalSpends = prospectSpends + retargetingSpends
  //       console.log(prospectRoasArray,max);
  //       const prospectRoas = prospectRoasArray[dataPointIndex];
  //       const retargetRoas = retargetRoasArray[dataPointIndex]
  //       const totalRoas = series[1][dataPointIndex].toFixed(1) + "X"

  //       return `<div class="arrow_box-line-column">
  //         <div class="information-wrapper-with-legends">
  //         <div class="legends-container">
  //           <div class="legend-container-item legend-prospect"></div>
  //           <div class="legend-container-item legend-retargeting"></div>
  //           <div class="legend-container-item legend-roas"></div>
  //         </div>
  //         <div class="only-metrices-container">
  //           <span class="total-spends">Total Spends: ${totalSpends}</span>
  //           <span class="prospect-spends">Prospect Spend: ${prospectSpends}</span>
  //           <span class="retargeting-spends">Retargeting Spend: ${retargetingSpends}</span>
  //           <span class="aggregated-roas">Aggregated ROAS: ${totalRoas}</span>
  //           <span class="divided-roas">(Prospect ${prospectRoas}X,ReTargeting ${retargetRoas}X)</span>
  //         </div>
  //         </div>
  //       </div>`
  //     },
  //     x: {
  //       show: false,
  //     },
  //     y:{
  //       formatter:undefined
  //     }
  //   },
  //   // stroke: {
  //   // 	width: [0, 4],
  //   // },
  //   title: {
  //     text: undefined,
  //   },
  //   dataLabels: {
  //     enabled: false,
  //     dataEnabledonSeries: [2],
  //   },
  //   labels: XaxisData ?? [],
  //   plotOptions: {
  //     bar: {
  //       columnWidth: "25%",
  //       endingShape: "rounded",
  //       borderRadius: 8,
  //       dataLabels: {
  //         enabled: true,
  //       },
  //     },
  //   },
  //   xaxis: {
  //     type: "category",
  //     labels: {
  //       style: {
  //         cssClass: "x-axis-label-underline",
  //       },
  //     },
  //   },
  //   colors: ["#A397DB", "#97DB9A", "#8DEAFE"],
  //   legend: {
  //     show: false,
  //   },
  //   yaxis: [
  //     {
  //       title: {
  //         text: "Spends",
  //       },
  //       max: max && (max)*1.2,
  //       labels: {
  //         formatter: function (num) {
  //           num = num.toString().replace(/[^0-9.]/g, "");
  //           if (num < 1000) {
  //             return num;
  //           }
  //           let si = [
  //             { v: 1e3, s: "K" },
  //             { v: 1e6, s: "M" },
  //             { v: 1e9, s: "B" },
  //             { v: 1e12, s: "T" },
  //             { v: 1e15, s: "P" },
  //             { v: 1e18, s: "E" },
  //           ];
  //           let index;
  //           for (index = si.length - 1; index > 0; index--) {
  //             if (num >= si[index].v) {
  //               break;
  //             }
  //           }
  //           return (
  //             (num / si[index].v)
  //               .toFixed(0)
  //               .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s
  //           );
  //         },
  //       },
  //     },
  //     {
  // 	seriesName:"ROAS",
  //       opposite: true,
  //       title: {
  //         text: "Roas",
  //       },
  //       labels: {
  //         formatter: function (val) {
  //           return val.toFixed(0) + "X";
  //         },
  //       },
  //     },
  //   ],
  // };

  // function sumTwoArrays(arr1, arr2) {
  //   if (arr1?.length !== arr2?.length)
  //     throw new Error("Array Must be of same length");
  //   return arr1?.map((val, index) => parseFloat(val) + parseFloat(arr2[index]));
  // }

  // const max =
  //   ColumnData1 &&
  //   ColumnData2 &&
  //   Math.max(...sumTwoArrays(ColumnData1, ColumnData2));

  const prospectRoasArray = ColumnData3 && ColumnData3;
  const retargetRoasArray = ColumnData4 && ColumnData4;

  // ChartJS.register(
  //   CategoryScale,
  //   LinearScale,
  //   PointElement,
  //   LineElement,
  //   BarElement,
  //   Title,
  //   Tooltip,
  //   Legend,
  // );
  ChartJS.register(...registerables,ChartDataLabels);
 
  let delayed;
  const options = {
    responsive: true,
    maintainAspectRatio:false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    layout: {
      padding: {
          left: 22,
          right: 24,
          top: 25 ,
          bottom: 20
      }
    },
    animation:{
      duration:0
    },
  //  animation:{
  //         y: {
  //           duration: 1000,
  //           from: 500
  //         },
  //         y1: {
  //           duration: 1000,
  //           from: 1000
  //         },
  //         x:{
  //           duration:0,
  //           from:0
  //         },
  //   },
    // animation: {
    //   // onComplete: () => {
    //   //   delayed = true;
    //   // },
    //   // delay: (context) => {
    //   //   let delay = 0;
    //   //   if (context.type === 'data' && context.mode === 'default' && !delayed) {
    //   //     delay = context.dataIndex * 300 + context.datasetIndex * 1000;
    //   //   }
    //   //   return delay;
    //   // },
    // },

    stacked: true,
    plugins: {
      title: {
        display: false,
        text: "Chart.js Line Chart - Multi Axis",
      },
      legend: {
        display: false,
      },
      tooltip: {
        usePointStyle: true,
        bodySpacing:4,
        padding:14,
        intersect:true,
        callbacks: {
          title: function (items) {
            const roasData = items[0];
            const prospectData = items[1];
            const retargetData = items[2];
            const { dataIndex } = roasData;
            const prospectSpends = prospectData?.dataset?.data[dataIndex];
            const retargetSpends = retargetData?.dataset?.data[dataIndex];
            const totalSpends = prospectSpends + retargetSpends;
            return "Total Spends: " + totalSpends;
          },
          footer: function (items) {
            const { dataIndex } = items[0];
            const prospectRoas = prospectRoasArray[dataIndex];
            const retargetRoas = retargetRoasArray[dataIndex];
            return `(Prospect ${prospectRoas}X,ReTargeting ${retargetRoas}X)`
          },
        },
      },
      datalabels:{
        formatter:function(value,context){
          const prospectSpends = context.chart.data.datasets[1].data[context.dataIndex]
          const retargetSpends = context.chart.data.datasets[2].data[context.dataIndex]
          const totalSpends = prospectSpends + retargetSpends;
          return  Math.round(value/totalSpends*100) + "%"
        },
        color: '#fff',
        font: {
          weight: 'light'
        }
      },
    },
 
    scales: {
      y: {
        display: true,
        beginAtZero: true,
        ticks: {
          callback: function (label, index, labels) {
            return label / 1000 + "k";
          },
        },
        scaleLabel: {
          display: true,
          labelString: "1k = 1000",
        }, 
      },
      y1: {
        display: true,
        position: "right",
        grid: {
          display:false
        },
        
        ticks: {
          callback: function (label, index, labels) {
            return label.toFixed(1);
          },
        },
      },
      x:{
        grid:{
          display:false
        }
      }
    },
  };

  const labels = XaxisData && XaxisData;

  const data = {
    labels,
    datasets: [
      {
        label: "ROAS",
        type: "line",
        data: LineData && LineData,
        borderColor: "#5B5B5B",
        backgroundColor: "#5B5B5B",
        pointStyle: "circle",
        pointRadius:3,
        yAxisID: "y1",
        borderDash: [6, 4],
        borderWidth:1.5,
        datalabels: {
          display: true,
          formatter: (value) => {
            if (value === 0) {
              return "0"; // Display "0" instead of NaN
            }
            return value;
          },
        },
      },
      {
        label: "Prospect",
        type: "bar",
        data: ColumnData2 ?? [],
        stacked: true,
        order: 1,
        borderColor: "#1D216E",//7078CC
        barPercentage: 0.4,
        pointStyle: "circle",
        backgroundColor: "#1D216E",//7078CC
        hoverBackgroundColor:"#1D216E",//7078CC
        yAxisID: "y",
        stack: "combined",
        borderWidth: lineWidth,
        barThickness: chartbarThickness, 
        borderRadius: chartborderRadius,
        // datalabels: {
        //   display: true,
        //   color: "white",
        //   formatter: (value) => {
        //     if (value === 0) {
        //       return "0"; // Display "0" instead of NaN
        //     }
        //     return value;
        //   },
        // },
        // datalabels:{
        //   display:true
        // }
      },
      {
        label: "Retarget",
        type: "bar",
        stacked: true,
        data: ColumnData1 ?? [],
        order: 2,
        borderColor: "#50A5B5",//70BDCC
        barPercentage: 0.4,
        pointStyle: "circle",
        backgroundColor: "#50A5B5",//70BDCC
        hoverBackgroundColor:"#50A5B5",//70BDCC
        yAxisID: "y",
        stack: "combined",
        borderWidth: lineWidth,
        barThickness: chartbarThickness, 
        borderRadius: chartborderRadius,
        // datalabels: {
        //   display: true,
        //   color: "white",
        //   formatter: (value) => {
        //     if (value === 0) {
        //       return "0"; // Display "0" instead of NaN
        //     }
        //     return value;
        //   },
        // },
      },
    ],
  };

  return (
    <>
      {/* <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          height={320}  
          width={"100%"}
        />
      </div> */}
      <div className="chart-wrapper-div">
      <Bar options={options} height={70} data={data} />
      </div>
    </>
  );
};

// const LineColumnGraph = ({ LineData, ColumnData1, ColumnData2, XaxisData }) => {

//   const series = [
//     {
//       name: "Retarget",
//       type: "column",
//       data: ColumnData2?? [],
//       stacked: true,
//       stackType: "100%"
//     },
//     {
//       name: "Prospect",
//       type: "column",
//       data: [ColumnData1 && [...ColumnData1],ColumnData1 && [...ColumnData1]] ?? [],
//       stacked: true,
//       stackType: "100%"
//     },
//     {
//       name: "ROAS",
//       type: "line",
//       data: LineData ?? [],
//     },
//   ];

//   const options = {
//     chart: {
//       height: 350,
//       stacked: true,
//       type: "line",
//       toolbar: {
//         show: false
//       }
//     },
//     tooltip: {
//       enabled: true,
//     },
//     stroke: {
//       width: [0, 4],
//     },
//     title: {
//       text: "Trend Graph",
//     },
//     dataLabels: {
//       enabled: true,
//     },
//     labels: XaxisData ?? [],
//     plotOptions: {
//       column: {
//         stacking: 'normal',
//         dataLabels: {
//           enabled: true,
//           formatter: function(val, opts) {
//             return (val / opts.w.globals.stackedSeriesTotals[opts.seriesIndex] * 100).toFixed(0) + "%";
//           },
//           style: {
//             fontSize: '12px',
//             fontWeight: 'bold',
//             colors: ['#fff']
//           }
//         }
//       }
//     },
//     xaxis: {
//       type: "category",
//     },
//     colors: ["#C2F3FE", "#D5C2FF", "#403d51"],
//     legend: {
//       show: false
//     },
//     yaxis: [
//       {
//         title: {
//           text: "Spends",
//         },
//       },
//       {
//         opposite: true,
//         title: {
//           text: "Roas",
//         },
//       },

//     ],
//   };

//   return (
//     <>
//       <ReactApexChart
//         options={options}
//         series={series}
//         height={350}
//         width={"100%"}
//       />
//     </>
//   );
// };

export default LineColumnGraph;
