import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ThemeProvider from "react-bootstrap/ThemeProvider";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { AuthProvider } from "react-auth-kit";
import { GlobalContextProvider } from "./context/global";
import RouteAccessWrapper from "./components/wrappers/routeAccessWrapper";
import Services from "./components/wrappers/services";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider
    authType={"cookie"}
    authName={"_auth"}
    cookieDomain={window.location.hostname}
    cookieSecure={window.location.protocol === "https:"}
  >
  
    <Router>
      <Provider store={store}>
        <GlobalContextProvider>
          {/* <ReportContextProvider> */}
          <HelmetProvider>
            <RouteAccessWrapper>
              <Services>
                <App />
              </Services>
            </RouteAccessWrapper>
          </HelmetProvider>
          {/* </ReportContextProvider> */}
        </GlobalContextProvider>
      </Provider>
    </Router>
  </AuthProvider>
);

reportWebVitals();