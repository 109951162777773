import React from "react";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../context/global";
import MasterTargetingSearchSelectModal from "../masterTargetinngActionnAlertMModal/search&select/MasterTargetingSearchSelectModal";
import downArrow from "../../../assets/icons/downArrowForSplitButton.svg";
import dropDownTrigger from '../../../assets/svgs/dropdowntrigger.svg'
import info from '../../../assets/svgs/info.svg'
import { TOAST_DANGER, TOAST_POSITION_TOP_RIGHT } from "../../../constant/HardTypes";
import {toast} from 'react-toastify';

const CreateAdGroup = (props) => {
  const {
    adGroupData,
    setAdGroupData,
    categories,
    setCategories,
    setSelectedCategory,
    selectedCategory,
    campaignType,
    campaignData,
    setCampaignData,
    clientId,
    setAdSetData,
    adSetData,
    showAlert,
    dispatchErrors,
    createCampaignErrors
  } = useGlobalContext();

  const { type } = campaignData;
  const { name ,amz_manual_bid} = adGroupData;
  let targeting_objective;
  let dataType;

  // While Creating new Data Group
  if (props.data) {
    targeting_objective = props.data.targeting_objective;
    dataType = props.data.type;
  }
  const [fixedText, setFixedText] = useState("");
  const [dropDownVisible, setdropDownVisible] = useState({
    searchSortModal: false,
  });
  const [isAllBidValueEmpty,setIsAllBidValueEmpty] = useState(false)
  const [isSomeBidValueEmpty,setIsSomeBidValueEmpty] = useState(false)

  // Variables From Derived State
  const derievedCategory =
    typeof selectedCategory === "object" &&
    Array.isArray(selectedCategory) &&
    selectedCategory.length > 1
      ? "MultiCategory":
    typeof selectedCategory === "object" && Array.isArray(selectedCategory) &&
    selectedCategory.length === 1 ? selectedCategory[0] 
      : selectedCategory;

  // Methods

  const handleChange = (e) => {
    setAdGroupData({ ...adGroupData, [e.target.name]: e.target.value });
  };

  const handleInputChange = (e) => {
    let value = e.target.value
    value =  value ? value.replace(/[^0-9.]/g, '') : '';
    if (value === '') {
      setAdGroupData({...adGroupData,amz_manual_bid:{
        ...amz_manual_bid,
        [e.target.name]:""
      }})
    } else {
      const decimalRegex = /^\d*\.?\d*$/;  
      if (decimalRegex.test(value)) {
        setAdGroupData({...adGroupData,amz_manual_bid:{
          ...amz_manual_bid,
          [e.target.name]:value
        }})
      }
    }
  }

  const focusChange = (categoryChange) => {
    // if(name!=="") {
    //   return
    // }

    // If we are creating new adgroup from campaign List then we're passing update prop and with current campaign data which we're destructuring from the above props.data props., Otherwise we are checking the current campaign type which we're creating from the above

    if (props.update) {
      setFixedText(
        dataType === "SP-Auto" &&
          targeting_objective.toLowerCase() === "prospect"
          ? `Automatic_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
          : dataType === "SP-Keyword" &&
            targeting_objective.toLowerCase() === "prospect"
          ? `Keyword_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
          : dataType === "SP-Product" &&
            targeting_objective.toLowerCase() === "prospect"
          ? `Product_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
          : dataType === "SD-Product" &&
            targeting_objective.toLowerCase() === "prospect"
          ? `Product_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
          : dataType === "SP-Keyword" &&
            targeting_objective.toLowerCase() === "retargeting"
          ? `Keyword_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
          : dataType === "SP-Product" &&
            targeting_objective.toLowerCase() === "retargeting"
          ? `Product_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
          : dataType === "SD-Product" &&
            targeting_objective.toLowerCase() === "retargeting"
          ? `Product_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
          : dataType === "SD-Audience" &&
            targeting_objective.toLowerCase() === "retargeting"
          ? `Audience_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
          : ""
      );

      setAdGroupData({
        ...adGroupData,
        name:
          dataType === "SP-Auto" &&
          targeting_objective.toLowerCase() === "prospect"
            ? `Automatic_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
            : dataType === "SP-Keyword" &&
              targeting_objective.toLowerCase() === "prospect"
            ? `Keyword_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
            : dataType === "SP-Product" &&
              targeting_objective.toLowerCase() === "prospect"
            ? `Product_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
            : dataType === "SD-Product" &&
              targeting_objective.toLowerCase() === "prospect"
            ? `Product_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
            : dataType === "SP-Keyword" &&
              targeting_objective.toLowerCase() === "retargeting"
            ? `Keyword_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
            : dataType === "SP-Product" &&
              targeting_objective.toLowerCase() === "retargeting"
            ? `Product_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
            : dataType === "SD-Product" &&
              targeting_objective.toLowerCase() === "retargeting"
            ? `Product_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
            : dataType === "SD-Audience" &&
              targeting_objective.toLowerCase() === "retargeting"
            ? `Audience_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
            : "",
      });
      return false;
    }

    if (categoryChange === "categoryChange") {
      setFixedText(
        type === "SP-Auto" && campaignType === "prospect"
          ? `Automatic_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
          : type === "SP-Keyword" && campaignType === "prospect"
          ? `Keyword_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
          : type === "SP-Product" && campaignType === "prospect"
          ? `Product_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
          : type === "SD-Product" && campaignType === "prospect"
          ? `Product_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
          : type === "SP-Keyword" && campaignType === "retargeting"
          ? `Keyword_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
          : type === "SP-Product" && campaignType === "retargeting"
          ? `Product_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
          : type === "SD-Product" && campaignType === "retargeting"
          ? `Product_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
          : type === "SD-Audience" && campaignType === "retargeting"
          ? `Audience_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
          : ""
      );

      setAdGroupData({
        ...adGroupData,
        name:
          type === "SP-Auto" && campaignType === "prospect"
            ? `Automatic_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
            : type === "SP-Keyword" && campaignType === "prospect"
            ? `Keyword_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
            : type === "SP-Product" && campaignType === "prospect"
            ? `Product_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
            : type === "SD-Product" && campaignType === "prospect"
            ? `Product_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
            : type === "SP-Keyword" && campaignType === "retargeting"
            ? `Keyword_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
            : type === "SP-Product" && campaignType === "retargeting"
            ? `Product_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
            : type === "SD-Product" && campaignType === "retargeting"
            ? `Product_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
            : type === "SD-Audience" && campaignType === "retargeting"
            ? `Audience_${derievedCategory.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
            : "",
      });
      return false;
    } else {
      setFixedText(
        type === "SP-Auto" && campaignType === "prospect"
          ? `Automatic_${derievedCategory?.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
          : type === "SP-Keyword" && campaignType === "prospect"
          ? `Keyword_${derievedCategory?.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
          : type === "SP-Product" && campaignType === "prospect"
          ? `Product_${derievedCategory?.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
          : type === "SD-Product" && campaignType === "prospect"
          ? `Product_${derievedCategory?.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
          : type === "SP-Keyword" && campaignType === "retargeting"
          ? `Keyword_${derievedCategory?.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
          : type === "SP-Product" && campaignType === "retargeting"
          ? `Product_${derievedCategory?.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
          : type === "SD-Product" && campaignType === "retargeting"
          ? `Product_${derievedCategory?.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
          : type === "SD-Audience" && campaignType === "retargeting"
          ? `Audience_${derievedCategory?.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
          : ""
      );

      setAdGroupData({
        ...adGroupData,
        name:
          type === "SP-Auto" && campaignType === "prospect"
            ? `Automatic_${derievedCategory?.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
            : type === "SP-Keyword" && campaignType === "prospect"
            ? `Keyword_${derievedCategory?.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
            : type === "SP-Product" && campaignType === "prospect"
            ? `Product_${derievedCategory?.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
            : type === "SD-Product" && campaignType === "prospect"
            ? `Product_${derievedCategory?.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
            : type === "SP-Keyword" && campaignType === "retargeting"
            ? `Keyword_${derievedCategory?.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
            : type === "SP-Product" && campaignType === "retargeting"
            ? `Product_${derievedCategory?.replace(/[^a-zA-Z0-9 ]/g, "")}_SP_`
            : type === "SD-Product" && campaignType === "retargeting"
            ? `Product_${derievedCategory?.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
            : type === "SD-Audience" && campaignType === "retargeting"
            ? `Audience_${derievedCategory?.replace(/[^a-zA-Z0-9 ]/g, "")}_SD_`
            : "",
      });
      return false;
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        `https://catalogapi.adyogi.com/client/${clientId}/categories?source=amazon&fields=name&limit=1000`
      );
      const result = await response.json();

      if(response?.status!==200) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: "Some Unknown Error Occured",
        // });
        toast.error('Some Unknown Error Occured')
      }
      if(result?.data?.length>0) {
        setCategories(result?.data ?? []);
        setSelectedCategory([result?.data[0]?.name]);
        setFixedText(result?.data[0].name);
      } else if(result?.data?.length === 0) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: "Categories Not Found",
        // });
        toast.error('Categories Not Found')
      }
     
    } catch (error) {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_DANGER,  
      //   title: "Error",
      //   description: `Some Unknown Error Occured`,  
      // });
      toast.error('Some unknown error occured')

    }
  };

  const handleDropDownModal = (e) => {
    e.preventDefault();
    setdropDownVisible({
      ...dropDownVisible,
      searchSortModal: !dropDownVisible.searchSortModal,
    });
  };

  const applyHandler = (data) => {
    const selectedCategoriesData = data?.map((value) => value?.name);
    if(data?.length>0) {
      setSelectedCategory(selectedCategoriesData);
    } 
    setAdSetData((current) => {
      const copy = { ...current };
      if ("product_set" in copy) {
        copy["product_set"] = selectedCategoriesData
      }
      return copy;
    });
  };

  const handleDefaultBidChange = (e) => {
    let value = e.target.value
    value =  value ? value.replace(/[^0-9.]/g, '') : '';
    if (value === '') {
      dispatchErrors({action:"setError",fieldName:"amz_default_bid",value:true})
      setAdGroupData({...adGroupData,amz_default_bid:""})
    } else {
      const decimalRegex = /^\d*\.?\d*$/;  
      if (decimalRegex.test(value)) {
        dispatchErrors({action:"setError",fieldName:"amz_default_bid",value:false})
        setAdGroupData({...adGroupData,amz_default_bid:value})
      }
    }
  }

  // Effects

  useEffect(() => {
      fetchCategories();
  }, [campaignType]); 


  useEffect(() => {
    focusChange("categoryChange");
  }, [selectedCategory]);

  useEffect(() => {
    if(props.automatic) {
      const default_bid = adGroupData?.amz_default_bid
      setAdGroupData({...adGroupData,amz_manual_bid:{
        ...amz_manual_bid,
        amz_close_match_bid_adyogi:default_bid,
        amz_loose_match_bid_adyogi:default_bid,
        amz_substitutes_bid_adyogi:default_bid,
        amz_complements_bid_adyogi:default_bid
      }})
    }
  },[adGroupData?.amz_default_bid])

  useEffect(() => {
    if(props.automatic) {
      const allKeysFilled = Object.values(amz_manual_bid).every(value => value.trim() !== "");

      const allKeysEmpty = Object.values(amz_manual_bid).every(value => value.trim() === "");
      
      if (allKeysFilled || allKeysEmpty) {
       setIsAllBidValueEmpty(false)
      } else {
      setIsAllBidValueEmpty(true)
      }
     
    }
  },[adGroupData])

  return (
    <>
      <div>
        <span className="common-head">Create Ad Groups </span>
      </div>
      <div className="row row-wrapper">
        <div className="col-lg-4">
          <label className="common-label">
            Choose Category
            <span className="error-message">{name === "" ? " *" : ""}</span>
            <img src={dropDownTrigger} alt={"choose category"}/>
          </label>
          <div
            className="select-button-container w-100 select-div"
            onClick={handleDropDownModal}
          >
            <button className="selected-opt-show-btn">
              {derievedCategory}
            </button>
            <button className="img-container">
              <img
                src={downArrow}
                className={"dropdown-img-for-select"}
                alt="DropDown For Select"
              />
            </button>
          </div>
          <div className="modal-select-search-wrapper position-relative">
            <MasterTargetingSearchSelectModal
              dynamicWidth={true}
              defaultSelected={true}
              defaultSelectedValue={[selectedCategory]}
              findClickedColumnName={"Category"}
              isVisible={dropDownVisible.searchSortModal}
              currentFilters={dropDownVisible}
              initialData={categories}
              cancelFilter={setdropDownVisible}
              getUpdatedDrowDownFilters={applyHandler}
            />
          </div>
          {/* <div className="select-drop position-relative"> */}
          {/* <select
              class="form-select"
              aria-label="Default select example"
              name="amz_category"
              onChange={(e) => {
                setSelectedCategory(e.target.value);
                setAdSetData((current) => {
                  const copy = { ...current };
                  if ("product_set" in copy) {
                    copy["product_set"] = e.target.value;
                  }
                  return copy;
                });
              }}
            >
              {categories.map((category, index) => {
                return (
                  <option value={category.name} key={index+category?.name}>
                    {category.name}
                  </option>
                );
              })}
            </select> */}
        </div>
        {/* </div> */}
        <div className="col-lg-4">
          <label className="common-label">
            Ad group Name{" "}
            <span className="error-message">{createCampaignErrors?.adgroup_name ? "*" : ""}</span>
          </label>
          <input
            type="text"
            style={{ paddingLeft: "10px" }}
            className={`${createCampaignErrors?.adgroup_name ? 'border border-danger':''} create-campaign-outline`}
            placeholder={
              type === "SP-Auto" && campaignType === "prospect"
                ? `Automatic_${selectedCategory}_SP_`
                : type === "SP-Keyword" && campaignType === "prospect"
                ? `Keyword_${selectedCategory}_SP_`
                : type === "SP-Product" && campaignType === "prospect"
                ? `Product_${selectedCategory}_SP_`
                : type === "SD-Product" && campaignType === "prospect"
                ? `Product_${selectedCategory}_SD_`
                : type === "SP-Keyword" && campaignType === "retargeting"
                ? `Keyword_${selectedCategory}_SP_`
                : type === "SP-Product" && campaignType === "retargeting"
                ? `Product_${selectedCategory}_SP_`
                : type === "SD-Product" && campaignType === "retargeting"
                ? `Product_${selectedCategory}_SD_`
                : type === "SD-Audience" && campaignType === "retargeting"
                ? `Audience_${selectedCategory}_SD_`
                : ""
            }
            name="name"
            value={name}
            onChange={(e) => {
              const input = e.target.value;
              e.target.value = fixedText + input.substr(fixedText.length);
              const pattern = /(SD_|SP_).+/;
              if(!pattern.test(e.target.value)) {
                dispatchErrors({action:"setError",fieldName:"adgroup_name",value:true})
              } else {
                dispatchErrors({action:"setError",fieldName:"adgroup_name",value:false})
              }
              setAdGroupData({ ...adGroupData, name: e.target.value });
            }}
            onFocus={focusChange}
          ></input>
          {createCampaignErrors?.adgroup_name && <small className="text-danger">Error:- Please Enter Something After Prefilled Text!</small>}
        </div>
        <div className="col-lg-4">
          <label className="common-label">
           {"Default Bid"} 
            <img src={info} alt={"Info"}/>
          </label>
          <input
            className={`${createCampaignErrors?.amz_default_bid ? `border border-danger`:``} "input-placeholder"`}
            type="text"
            placeholder="Suggested Bid"
            name="amz_default_bid"
            value={adGroupData?.amz_default_bid}
            onChange={handleDefaultBidChange}
          ></input>
          {createCampaignErrors?.amz_default_bid && <small className="text-danger">Error:- Default Bid Cannot Be Empty!</small>}
        </div>
      </div>
      {props.automatic && (
        <>
        <div className="row row-wrapper mt-4">
            <div className="col-lg-6 create-ad-group-input">
              <div className="row row-wrapper">
                <div className="col-lg-6">
                <label className="fw-bold">Targeting Groups <img src={info} alt={"info"}/></label>
              </div>
              <div className="col-lg-6">
                <label className="fw-bold">Bid <img src={info} alt={"info"}/></label>
              </div>
              <div className="col-lg-6">
                <span htmlFor="close-match" className="adgroup-bid">Close Match <img src={info} alt={"info"}/></span>
              </div>
              <div className="col-lg-6">
                <input type={"text"} id={'automatic-bid-input-close-match'} value={amz_manual_bid?.amz_close_match_bid_adyogi} name={"amz_close_match_bid_adyogi"} onChange={handleInputChange} placeholder={"Close match"}/>
              </div>
              <div className="col-lg-6">
                <label className="adgroup-bid">Loose Match <img src={info} alt={"info"}/></label>
              </div>
              <div className="col-lg-6">
                <input type={"text"} id={'automatic-bid-input-loose-match'} value={amz_manual_bid?.amz_loose_match_bid_adyogi} name={"amz_loose_match_bid_adyogi"} onChange={handleInputChange} placeholder={"Loose match"}/>
              </div>
              <div className="col-lg-6">
                <label className="adgroup-bid">Substitutes <img src={info} alt={"info"}/></label>
              </div>
              <div className="col-lg-6">
                <input type={"text"} id={'automatic-bid-input-substitutes'} value={amz_manual_bid?.amz_substitutes_bid_adyogi} name={"amz_substitutes_bid_adyogi"} onChange={handleInputChange} placeholder={"Substitutes"}/>
              </div>
              <div className="col-lg-6">
                <label className="adgroup-bid">Complements <img src={info} alt={"info"}/></label>
              </div>
              <div className="col-lg-6">
                <input type={"text"} id={'automatic-bid-input-complements'} value={amz_manual_bid?.amz_complements_bid_adyogi} name={"amz_complements_bid_adyogi"} onChange={handleInputChange} placeholder={"Complements"}/>
              </div>
              </div>
            </div>
            { isAllBidValueEmpty &&  <span className="text-danger">*Fill all 4 values or don't fill any.</span>}
        </div>
    
        </>
      )}
    </>
  );
};

export default CreateAdGroup;
