import React from "react";
import { useGlobalContext } from "../../../../context/global";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FilterkeywordModal from "../../campaignCreation/SpKeywordTargeting/FilterkeywordModal";
import { Nav, Tab } from "react-bootstrap";
import TableRb from "../../../Tables/tableRb";
import { Trash } from "react-bootstrap-icons";
import ProductImage1 from "../../../../assets/images/SP_ProductImage/Product1.png";
import "../SP_Product_Targeting/SpProductTargeting.css";
import { Form } from "react-bootstrap";
import { campaignListActions } from "../../../../redux/slices/campaignList";
import EditBidModalForTargeting from "../../../modal/editBidModalForTargeting";
import modifyValueByPercentage from "../../../../helper/common/modifyValueByPercentage";

const AsinTableBrandProduct = () => {
  const {
    spBrandAsins,
    setSpBrandAsins,
    setspBrandWholeCategories,
    setAdGroupData,
    createCampaignErrors
  } = useGlobalContext();

  const [bidModal, setBidModal] = useState(false);
  const dispatch = useDispatch();

  const bulkEditBidModalVisibilty = useSelector(
    (state) => state.campaignListData.bulkEditBidModalVisibilty
  );

  const deleteAllAsins = () => {
    setSpBrandAsins([]);
    setspBrandWholeCategories((current) => {
      return current.filter((category) => {
        return category.expression[0].type !== "product";
      });
    });
    setAdGroupData((current) => {
      const copy = { ...current };
      let updatedArray = copy["amz_product_targeting"].filter((asin) => {
        return asin.expression[0].type !== "product";
      });
      copy["amz_product_targeting"] = updatedArray;
      return copy;
    });
  };

  const bidChangeOfAsin = (input, product) => {
    setSpBrandAsins((prev) => {
      let data = [...prev].map((selectedProduct) => {
        if (
          selectedProduct.expression[0].value?.toLowerCase() ===
          product.expression[0].value?.toLowerCase()
        ) {
          selectedProduct.bid = parseFloat(input.target.value);
        }
        return selectedProduct;
      });
      return data;
    });
    setspBrandWholeCategories((prev) => {
      let data = [...prev].map((selectedProduct) => {
        if (
          selectedProduct.expression[0].value?.toLowerCase() ===
          product.expression[0].value?.toLowerCase()
        ) {
          selectedProduct.bid = parseFloat(input.target.value);
        }
        return selectedProduct;
      });
      return data;
    });
    setAdGroupData((prev) => {
      let data = { ...prev };
      let updatedData = data.amz_product_targeting.map((selectedProduct) => {
        if (
          selectedProduct?.expression[0]?.value?.toLowerCase() ===
          product?.expression[0]?.value?.toLowerCase()
        ) {
          selectedProduct.bid = parseFloat(input.target.value);
        }
        return selectedProduct;
      });
      data.amz_product_targeting = updatedData;
      return data;
    });
  };

  const onDelete = (id) => {
    setSpBrandAsins((current) => {
      return current.filter((asin) => {
        return asin.expression[0].value !== id;
      });
    });
    setspBrandWholeCategories((current) => {
      return current.filter((asin) => {
        return asin.expression[0].value !== id;
      });
    });
    setAdGroupData((current) => {
      const copy = { ...current };
      let updatedArray = copy["amz_product_targeting"].filter((asin) => {
        return asin.expression[0]?.value !== id;
      });
      return copy;
    });
  };

  const cancelHandler = () => {
    setBidModal(false);
  };

  const editBidHandler = () => {
    dispatch(campaignListActions.showBulkEditBidModal());
    setBidModal(true);
  };

  const getData = (data) => {
    const { setAsValue, selectedAction, valuePercentage } = data;
    setSpBrandAsins((prev) => {
      const copy = [...prev].map((product) => {
        product.bid =
          selectedAction === "set_bid_as"
            ? setAsValue
            : modifyValueByPercentage(
                selectedAction,
                valuePercentage,
                Number(product.bid)
              );
        return product;
      });
      return copy;
    });
    setAdGroupData((prev) => {
      const copy = { ...prev };
      copy["amz_product_targeting"] = copy["amz_product_targeting"]?.map(
        (product) => {
          const addedProduct = spBrandAsins.find(
            (addedProduct) =>
              product?.expression[0]?.value === addedProduct?.category_id
          );
          if (addedProduct) {
            product.bid = addedProduct.bid
          }
          return product;
        }
      );
      return copy;
    });
  };

  const tableBodyOfAsins = (
    <>
      <div className={`${createCampaignErrors?.targeting ? "border border-danger" : ""} generic-main-table-body-container`}>
        <table className="sp-product-targetting-table table-bordered">
          <thead className="main-table-head">
            <tr className="table-heading-style">
              <td colspan={3} className="table-upper">
                <div className="d-flex justify-content-between align-items-center">
                <div className="float-left p-3 text-black">{`${spBrandAsins.length} Added`}</div>
                {
                  spBrandAsins.length > 0 ? (
                    <div>
                  <button
                  className="bid-manipulate-box"
                  onClick={editBidHandler}
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                >
                  Adjust Bid
                   </button>
                    </div>
                  )
                  :""
                }
                <div className="float-right">
                  <button
                    type="button"
                    onClick={deleteAllAsins}
                    className={
                      spBrandAsins.length > 0
                        ? "remove-all-btn-dark"
                        : "remove-all-btn"
                    }
                  >
                    Remove All
                  </button>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <th className="border border-right-white product-table-first">
                <span className="table-header-text mx-3">All Categories</span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_1178_14756"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                  >
                    <rect width="20" height="20" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_1178_14756)">
                    <path
                      d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                      fill="black"
                    />
                    <path
                      d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                      fill="black"
                    />
                  </g>
                </svg>
              </th>
              <th id="product-table-header">
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="table-header-text mx-3">
                      Suggested Bid
                    </span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_1178_14756"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                      >
                        <rect width="20" height="20" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_1178_14756)">
                        <path
                          d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                          fill="black"
                        />
                        <path
                          d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </th>
              <th className="border border-right-white">Edit Bid</th>
            </tr>
          </thead>
          <tbody className="table-body main-table-body">
            {spBrandAsins &&
              spBrandAsins.map((category, index) => {
                // let category_range = category.bid;
                return (
                  <>
                    <tr
                      key={category.expression[0].value}
                      className="row-height"
                    >
                      <td className="tabledata-tb">
                        <Product
                          image={ProductImage1}
                          Name={category.expression[0].value}
                          // Desc={category_range}
                        />
                      </td>
                      <td className="tabledata-tb bids-parent-container">
                        <div className="bids-container">
                          {/* <span>{product.name}</span>
                      <span>{product.bidRange}</span>
                      <span>{product.name}</span> */}
                        </div>
                      </td>
                      <td>
                        <div
                          className="d-flex gap-1 justify-content-between align-items-center"
                          style={{ width: "75px" }}
                        >
                          <div className="w-100">
                            <Form.Control
                              size="sm"
                              className="w-100"
                              type="number"
                              step="0.1"
                              value={category.bid}
                              defaultValue={0}
                              onChange={(e) => bidChangeOfAsin(e, category)}
                              placeholder="Enter your bid"
                            />
                          </div>
                          <div>
                            <span
                              className="text-left cursor-pointer-global d-block selected-icon-hover"
                              // onClick={() => onDelete(product)}
                            >
                              <Trash
                                className="text-danger remove-item-btn"
                                height="12px"
                                width="12px"
                                onClick={() =>
                                  onDelete(category.expression[0].value)
                                }
                              />
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
          {/* })} */}
        </table>
      </div>
    </>
  );

  return (
    <>
      {bulkEditBidModalVisibilty && bidModal && (
        <EditBidModalForTargeting
          getData={getData}
          show={bulkEditBidModalVisibilty}
          cancel={cancelHandler}
          setBidAs={true}
        />
      )}
      <TableRb table={tableBodyOfAsins} />
    </>
  );
};

const Product = ({ image, Name, Desc }) => {
  return (
    <>
      <div className="product-container">
        <div className="product-img-container">
          <img src={image} alt="product" />
        </div>
        <div className="product-description-container">
          <span className="category-heading">Category</span>
          <span className="product-name">{Name && Name}</span>
          <span className="product-desc">Product:{Desc}</span>
        </div>
      </div>
    </>
  );
};

export default AsinTableBrandProduct;
