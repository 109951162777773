import React from "react";
import './spineer.css';

/**
 *
 * @param {*} props
 * @Note Send Props You can send props in this way:- size("sm","md","lg") shape("border","grow")
 * @returns Loader According to the props.
 */

const Spinner = (props) => {
  const size = props.size;
  const shape = props.shape;

  return (
    <>
      {props.shimmer ? (
        <>
        {
            props.shimmerShape === "box" ? <>
                <div className="shimmerBG box"></div>
            </>  :  <div className="shimmerBG title-line end">
            </div>
        }    
        </>
      ) : (
        <div
          className={`spinner-${shape} spinner-${shape}-${size}`}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </>
  );
};

export default Spinner;
