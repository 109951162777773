import { COLORS_SERIES, PALE_COLORS_SERIES } from "./utils";
import { Bar } from "react-chartjs-2";

export const CustomBarChartRenderer = ({ resultSet }) => {
  const options = {
    responsive: true,
    plugins: {
      datalabels: { // This code is used to display data values
        display: false
       },
      legend: {
        position: "top",
        labels: {
          usePointStyle: true, // Use point style (circles) for legend items
          boxWidth:6,
          boxHeight:6,
          borderRadius : 0,
          fontSize:6
        },
      },
    },
  };
  const labels = resultSet.labels;
  let key = Math.floor(Math.random() * 6)
  const color = COLORS_SERIES[resultSet.key];
  const colorLine = PALE_COLORS_SERIES[resultSet.key];

  const data = {
    labels,
    datasets: [
      
      {
        label: resultSet.label+' today',
        data: resultSet.line,
        borderColor: color,
        backgroundColor: color,
        fill: false,
        type: "line",
        pointRadius: 3,
        cubicInterpolationMode: "monotone", // Use "monotone" for smoother, less pointed lines
      },
      {
        label: resultSet.label,
        data: resultSet.series,
        borderColor: colorLine,
        backgroundColor: colorLine,
        type:"bar"
      }
    ],
  };
  return <><Bar type="bar" data={data} options={options} /></>;
};
