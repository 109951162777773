import { romalNumerals } from "../../../constant/romanNumerals";
export default function romanConverter (num) {
   let roman = "",
    i;
  for (i in romalNumerals) {
    while (num >= romalNumerals[i]) {
      roman += i;
      num -= romalNumerals[i];
    }
  }
  return roman;
}