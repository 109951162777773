import "../../pages/campaignList/campaignList.css";
import { Stack } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import CheckboxRb from "../Buttons/checkbox/checkbox";
import SecondaryBtnRb from "../Buttons/secondaryBtnRb/secondaryBtnRb";
import "./MultiSelectCustomDropDown.css"
import dashIcon from "../../assets/icons/dash-icon.png"
import lessIcon from "../../assets/svgs/minus_box.svg";
import { useGlobalContext } from "../../context/global";
const MultiSelectCustomDropDown = ({
	title,
	targettingValue,
	onDropdownImghandler,
	src,
	status,
	data,
	onChange,
	onApplyClickHandler,
	fieldName,
	selectedOption,
	onCloseOutSideDropDownList,
	showCheckedAppliedFilteringWhenOPen,
	allSeletHandler,
	onDashIconHandlerToremoveAllCheckedOption,
	checkedItemCount,

}) => {

	const [
		targetingTypeSelectButtonOptionsVisibilty,
		setTargetingTypeSelectButtonOptionsVisibilty,
	] = useState(false);

	const { dropdownBtnOptionsVisibilty, setDropdownBtnOptionsVisibilty } = useGlobalContext();
	const [blurDropdown, setBlurDropdown] = useState(false);
	const closeRefMultiSelect = useRef(null);

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if ((closeRefMultiSelect.current && !closeRefMultiSelect.current.contains(event.target)) && targetingTypeSelectButtonOptionsVisibilty) {
				setTargetingTypeSelectButtonOptionsVisibilty(false);
				setBlurDropdown(false);
			}
		};
		window.addEventListener("mousedown", handleOutsideClick);
		return () => window.removeEventListener("mousedown", handleOutsideClick);
	}, [targetingTypeSelectButtonOptionsVisibilty]);

	useEffect(() => {
		if(!dropdownBtnOptionsVisibilty){
			setTargetingTypeSelectButtonOptionsVisibilty(false);
			setBlurDropdown(false);
			setDropdownBtnOptionsVisibilty(true);
		}
	}, [dropdownBtnOptionsVisibilty]);


	const applyBtnHandler = () => {
		onApplyClickHandler(selectedOption)
		setTargetingTypeSelectButtonOptionsVisibilty(
			!targetingTypeSelectButtonOptionsVisibilty
		);
	}

	const onDropDownMainBtnClickHandler = (e) => {
		setTargetingTypeSelectButtonOptionsVisibilty(!targetingTypeSelectButtonOptionsVisibilty);
		showCheckedAppliedFilteringWhenOPen && showCheckedAppliedFilteringWhenOPen(e)

	}

	const onCancelBtnhandler = () => {
		onCloseOutSideDropDownList && onCloseOutSideDropDownList()
		setTargetingTypeSelectButtonOptionsVisibilty(
			!targetingTypeSelectButtonOptionsVisibilty
		);
	}

	const dashIconHandlerRemoveChecks = (e, targettingValue, fieldName) => {
		e.stopPropagation()	
		onDashIconHandlerToremoveAllCheckedOption && onDashIconHandlerToremoveAllCheckedOption(e, targettingValue, fieldName)
	}

	const handleSelectAllOption = (e) => {
		e.stopPropagation()
		allSeletHandler(e)
	}

	return (
		<div ref={closeRefMultiSelect}>
			<Stack
				className="d-flex flex-col"
			>
				<label className="filter-options" htmlFor="campaign-type">
					{title ?? title}
				</label>

				<div>
					<div className="select-button-container select-div"

						onClick={onDropDownMainBtnClickHandler}
					>
						<button
							id="campaign-type"
							className="selected-opt-show-btn"
							value={targettingValue}
						>
							{targettingValue}
							<div className={checkedItemCount  ? "count-of-selcted-in-main-button-container" : "hide"}>{checkedItemCount}</div>
						</button>
						<button className="img-container">
							{" "}
							<img
								className="dropdown-img-for-select"
								// onClick={() => onDropdownImghandler(status)}
								src={src}
							></img>{" "}
						</button>
					</div>
					{!blurDropdown && (
						<div className={
							targetingTypeSelectButtonOptionsVisibilty
								? "cd-select-option-container"
								: "hide"
						}>
							<div className="select-opt">{
								selectedOption.length ?
									<div className="d-flex minus-icon-container-width-checked--count	 selected-option-count-text">
										<span>
											<img src={lessIcon} onClick={(e)=>dashIconHandlerRemoveChecks(e, targettingValue, fieldName)}></img>
										</span>
										<span className="px-2 cursor-pointer-global" onClick={(e) => dashIconHandlerRemoveChecks(e, targettingValue, fieldName)}>{selectedOption.length} selected </span>
									</div> :

									<CheckboxRb inputLabelClass="selected-option-count-text" id={targettingValue} label={"Select All"} onChange={(e) => handleSelectAllOption(e)}></CheckboxRb>
							}</div>
							<div className="options-contaoner-cd lsit-table-scrollbar"
 >
								{
									data.map((season, index) => (

										<div>{


											season.buttonName === targettingValue && season.fieldName === fieldName &&
											<button
												key={index}
												className="select-opt"
											>

												{/* {season} */}
												<div>
													{

														<CheckboxRb
															inputLabelClass="dd-opt-label"
															label={season.name}
															onChange={onChange}
															// checked={checked}
															checked={season.isDataChecked ? true : false}
															value={season.name}
															id={`${season.fieldName} ${season.name}`}
														>

														</CheckboxRb>
													}
												</div>

											</button>

										}

										</div>
									))

								}

							</div>
							<div className="custom-drop-down-saerch-box-footer ">

								<SecondaryBtnRb
									secondaryBtnText="Cancel"
									className="primary-inactive-btn custom-drop-down-footer-btn"
									onClick={onCancelBtnhandler}
								></SecondaryBtnRb>
								<SecondaryBtnRb
									secondaryBtnText="Apply"
									className="primary-active-btn custom-drop-down-footer-btn"
									onClick={applyBtnHandler}
								></SecondaryBtnRb>
							</div>
						</div>
					)}
				</div>
			</Stack>
		</div>
	);
};

export default MultiSelectCustomDropDown;
