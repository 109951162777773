import React, { useEffect, useMemo } from "react";
import { useState, useRef } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { campaignListActions } from "../../redux/slices/campaignList";

// Stylesheet
import "./optimisebid.css";

// Assets
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.svg";
import closeIcon from "../../assets/icons/close_icon.png";
import upGreen from "../../assets/svgs/arrow_upward_green.svg";
import downRed from "../../assets/svgs/arrow_downward_red.svg";
import editIcon from "../../assets/icons/edit.svg";
import checkCircle from "../../assets/icons/check_circle.png";
import ascendingIcon from "../../assets/svgs/acendingIconSvg.svg";
import descendingIcon from "../../assets/svgs/descendingIcon.svg";
import dragIcon from "../../assets/svgs/filter-bars-black.svg";
import dropdownIcon from "../../assets/icons/downArrowForSplitButton.svg";
import lessIcon from "../../assets/svgs/minus_box.svg";


// Helpers || API Functions
import { masterApi } from "../../apis/masterApi";
import { useGlobalContext } from "../../context/global";
import {
  TOAST_DANGER,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_SUCCESS,
} from "../../constant/HardTypes";
import { initialDataHeads } from "../../constant/dataHeadsForGlobalBidsOptimisation";
import { multiCalendarDateRange } from "../../constant/defaultMultiRangeDate";
import { formatDateFn } from "../../helper/common/formatDateForAPI";
import { encodeData } from "../../helper/common/encodeData";
import { v4 as uuidV4 } from "uuid";

// Components
import Loader from "../../components/loader";
import { Stack } from "react-bootstrap";
import SectionTitle from "../../components/sectionTitle";
import SearchBarForCampaignListOnly from "../../components/searchBarForCampaignListOnly";
import CalenderComponent from "../../components/calender/Calender";
import { Link } from "react-router-dom";
import MultiSelect from "../../components/DropDown/Multiselect";
import TableRb from "../../components/Tables/tableRb";
import MasterTagetinngActionAlertMModal from "../../components/modal/masterTargetinngActionnAlertMModal/masterTargetingActionnAlertModal";
import FilterChips from "../../components/FilterChips/FilterChips";
import DynamicFilterModal from "../../components/modal/DynamicFilterModal";
import LazyLoadSpinner from "../../components/spinners/LazyLoadSpinner";
import CustomDropDown from "../../components/DropDown/CustomDropDown";
import EditBidModalForTargeting from "../../components/modal/editBidModalForTargeting";
import CheckboxRb from '../../components/Buttons/checkbox/checkbox'
import { useCallback } from "react";
import useInitialLoad from '../../hooks/useInitialLoad'
import modifyValueByPercentage from "../../helper/common/modifyValueByPercentage";
import useHighlightList from "../../hooks/useHighlightList";
import Breadcrumb from '../../components/Breadcrumb/ReportsBreadCrumb';
import {toast}  from 'react-toastify';

const OptimiseBid = () => {
  // States || Global Contexts || Redux Imports || Global Variables
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const bulkEditBidModalVisibilty = useSelector(
    (state) => state.campaignListData.bulkEditBidModalVisibilty
  );
  const { showAlert, multiCalendarDate, applyCalendar,setMultiCalendarDate } = useGlobalContext();
  const { selection1, selection2 } = multiCalendarDate;
  const { startDate: start_date_first, endDate: end_date_first } = selection1;
  const { startDate: start_date_second, endDate: end_date_second } = selection2;
  const dispatch = useDispatch();
  const [initialLoad,setInitialLoad] = useInitialLoad()
  const [allLoaders, setAllLoaders] = useState({ mainLoader: false });
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const [actionModalText,setActionModalText] = useState("")
  const [campaignsStats, setCampaignStats] = useState([]);
  const [currentCampaignStats,setCurrentCampaignStats] = useState([]);
  const [currentRowCount,setCurrentRowCount] = useState(0)
  const [dataHeads, setDataHeads] = useState(initialDataHeads);
  const doubleColumnHeaders = ["spends", "cpc", "orders", "roas"];
  const [filters, setFilters] = useState({
    targeting: "",
  });
  const [selectedDropDownFilters, setSelectedDropDownFilters] = useState({
    portfolios: [],
    campaign_type: [],
    campaigns: [],
    targeting_type: [],
    adgroup: [],
    bid_strategy: "",
    campaign_objective: [],
  });
  const [searchFiltersState, setSearchFiltersState] = useState({
    portfolios: [],
    campaigns: [],
    adGroups: [],
    targetingType: [
      "Exact",
      "Broad",
      "Phrase",
      "Automatic",
      "Audience",
      "Product",
    ],
    campaignType: ["SP", "SD","SB"],
    bid_strategy: "All",
    campaign_objective: ["Prospect", "Retargeting"],
  });
  const [tableDataToShow, setTableDataToShow] = useState([]);
  const [handleHighlightList] = useHighlightList(tableDataToShow,setTableDataToShow)
  const [selectedTargeting,setSelectedTargeting] = useState([]);
  const [currentEditingIndex, setCurrentEditingIndex] = useState(null);
  const [statFilters, setStatFilters] = useState([]);
  const [paginationAction, setPaginationAction] = useState({
    currentPage: 1,
    currentOffSet: 0,
  });
  const [lazyLoading, setLazyLoading] = useState(false);
  const [activeCancelStatFilterField,setActiveCancelStatFilterField] = useState("")
  const [reloadMoreData,setReloadMoreData] = useState(false)
  const [sortBy, setSortBy] = useState({ sort_by: "spends", state: "desc" });

  // Variables For DropDowns
  const campaignsData = searchFiltersState?.campaigns?.map((campaign) => campaign?.name) ?? [] // If No data comes just put empty array
  const adgroupData = searchFiltersState?.adGroups?.map((adgroup) => adgroup?.name) ?? []
  const portfoliosData = searchFiltersState?.portfolios?.map((portfolio) => portfolio?.name) ?? []

  // Chip Filters Variable
  let dataForChips = useMemo(() => generateChipFilters(selectedDropDownFilters,statFilters),[selectedDropDownFilters,statFilters]) ;
  const limit = 2000;
  const [visibleTableData, setVisibleTableData] = useState([]);
  const [startIdx, setStartIdx] = useState(0);
  const [pageSize] = useState(60);

  useEffect(() => {
    loadMoreData();
  }, [reloadMoreData]);

  // Refs
  const masterAdsCalendarIconRef = useRef(null);
  const tableRef = useRef(null);

  // Effects

  // Get All dropdowns dynamic data(

  useEffect(() => {
    setMultiCalendarDate(multiCalendarDateRange)
  },[])

  useEffect(() => {
    fetchDataForDropDowns();
  }, []);

  useEffect(() => {
    const targetings = tableDataToShow?.filter((targeting) => targeting?.isChecked)
    setSelectedTargeting(targetings)
  },[tableDataToShow])

  useEffect(() => {
    setPaginationAction({...paginationAction,currentOffSet:0,currentPage:1})
  },[selectedDropDownFilters,filters,statFilters,sortBy])

  useEffect(() => {
    getOptimiseBidsRows();
  }, [applyCalendar, selectedDropDownFilters, filters, statFilters, sortBy]);

  useEffect(() => {
    getRowsCount()
  },[applyCalendar,selectedDropDownFilters,filters,statFilters,sortBy]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        masterAdsCalendarIconRef &&
        masterAdsCalendarIconRef.current &&
        !masterAdsCalendarIconRef.current.contains(e.target)
      ) {
        setShowCustomDateBox(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showCustomDateBox]);

  // Methods

  async function getOptimiseBidsRows() {
    setStartIdx(0)
    setVisibleTableData([])
    let filtering = generateFilteringArray(
      selectedDropDownFilters,
      searchFiltersState
    );
    if (filters?.targeting !== "") {
      filtering = filtering.concat([
        {
          field_name: "targeting",
          operator: "EQUALS",
          value: filters?.targeting,
        },
      ]);
    }
    if (statFilters?.length > 0) {
      filtering = filtering.concat([...statFilters]);
    }
    try {
      setAllLoaders({ ...allLoaders, mainLoader: true });
      const dataForAPI = {
        source: "amazon",
        order_by: sortBy.sort_by,
        sorting_order: sortBy.state,
        duration_one_start_date: formatDateFn(start_date_first),
        duration_one_end_date: formatDateFn(end_date_first),
        duration_two_start_date: formatDateFn(start_date_second),
        duration_two_end_date: formatDateFn(end_date_second),
        limit: limit,
        suggested_bid_required:false,
        master_row:false,
        offset: 0,
        filtering: encodeData(filtering),
      };
      const startDate = new Date();
      const startDateInstance = new Date(startDate);
      startDateInstance.setDate(startDate.getDate() - 6).toString();
      const dataForCampaignStat = {
        level: "account",
        platform: "amazon",
        start_date: `${formatDateFn(startDateInstance)}`,
        end_date: `${formatDateFn(new Date())}`,
        fields: "campaign_id,campaign_burn_rate",
        group_by: "campaign_id",
        limit: 5000,
      };
      const dataForTodayCampaignStat = {
        level: "account",
        platform: "amazon",
        start_date: `${formatDateFn(new Date())}`,
        end_date: `${formatDateFn(new Date())}`,
        fields: "campaign_id,campaign_burn_rate",
        group_by: "campaign_id",
        limit: 5000,
      };
      const result = await masterApi(dataForAPI, "getOptimisedBids", false);
      const campaignStats = await masterApi(dataForCampaignStat);
      const todayCampStats = await masterApi(dataForTodayCampaignStat)
      if (campaignStats?.statusCode !== 200) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: `Some Unknown Error Occurred While Fetching Average Burn Rate`,
        // });
        toast.error('Some Unknown Error Occurred While Fetching Average Burn Rate')
      }
      if (todayCampStats?.statusCode !== 200) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: `Some Unknown Error Occurred While Fetching Average Burn Rate`,
        // });
        toast.error('Some Unknown Error Occurred While Fetching Burn Rate')
      }
      if (result?.statusCode !== 200) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: `Some Unknown Error Occurred while Fetching Global Bids`,
        // });
        toast.error('Some Unknown Error Occurred while Fetching Global Bids')
      }
      if (result?.data?.data) {
        const { data } = result?.data;
        const { data: statsData } = campaignStats?.data;
        const {data:todayStats} = todayCampStats?.data
        setCampaignStats([...statsData ?? []]);
        setCurrentCampaignStats([...todayStats ?? []])
        const dataToBeSet = addComparisonData(data, statsData,todayStats);
        setTableDataToShow([...dataToBeSet]);
        setReloadMoreData(!reloadMoreData)
      }
      setAllLoaders({ ...allLoaders, mainLoader: false });
      setInitialLoad(false)
    } catch (error) {
      setAllLoaders({ ...allLoaders, mainLoader: false });
      setInitialLoad(false)
    }
  }

  async function getRowsCount() {
    let filtering = generateFilteringArray(
      selectedDropDownFilters,
      searchFiltersState
    );
    if (filters?.targeting !== "") {
      filtering = filtering.concat([
        {
          field_name: "targeting",
          operator: "EQUALS",
          value: filters?.targeting,
        },
      ]);
    }
    if (statFilters?.length > 0) {
      filtering = filtering.concat([...statFilters]);
    }

    try {
        setAllLoaders({ ...allLoaders, mainLoader: true });
        const dataForAPI = {
          source: "amazon",
          order_by: sortBy.sort_by,
          sorting_order: sortBy.state,
          duration_one_start_date: formatDateFn(start_date_first),
          master_row:false,
          duration_one_end_date: formatDateFn(end_date_first),
          duration_two_start_date: formatDateFn(start_date_second),
          duration_two_end_date: formatDateFn(end_date_second),
          row_count:true,
          filtering: encodeData(filtering),
        };

        const result =  await masterApi(dataForAPI, "getOptimisedBids", false);
        if(result?.data?.data?.length) {
          const {row_count} = result?.data?.data[0]
          setCurrentRowCount(row_count)
        }
        if (result?.statusCode !== 200) {
          // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //   backgroundColor: TOAST_DANGER,
          //   title: "Error",
          //   description: `Some Unknown Error Occurred While Fetching All Rows Count`,
          // });
          toast.error('Some Unknown Error Occurred While Fetching All Rows Count')
        }
    } catch (error) {
     console.log(error)
    }
  }

  function addComparisonData(data, stats , todayStats) {
    // Iterate over each object in the data array
    return data?.map((item) => {
      const { comp_spends, comp_roas, comp_cpc, comp_orders, campaign_id } =
        item;

      // Calculate the percentage differences

      const campaign = stats?.find(
        (currentCampaign) =>
          parseInt(currentCampaign?.campaign_id) === campaign_id
      );

      const todayCampaign = todayStats?.find(
        (currentCampaign) =>
          parseInt(currentCampaign?.campaign_id) === campaign_id
      );
      // Add the comparison data as new keys in the object
      return {
        ...item,
        current_bid_copy: item?.current_bid,
        comparison_spends: `${comp_spends?.toFixed(2) ?? "N/A"}%`,
        comparison_roas: `${comp_roas?.toFixed(2) ?? "N/A"}%`,
        comparison_cpc: `${comp_cpc?.toFixed(2) ?? "N/A"}%`,
        comparison_orders: `${comp_orders?.toFixed(2) ?? "N/A"}%`,
        avg_br: `${campaign?.campaign_burn_rate?.toFixed(2) ?? "N/A"}`,
        dbr:`${todayCampaign?.campaign_burn_rate?.toFixed(2) ?? "N/A"}`,
        isEditable: false,
        isChecked:false
      };
    });
  }

  //  ****** Methods For SearchBar **********

  const onSearchBarHandler = (e) => {
    if (e.target.value === "" || e.key === "Enter") {
      setFilters({ ...filters, targeting: e.target.value });
    }
  };

  // ********* Methods For Date handler

  const dateInputClickHandler = () => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  // ************** Methods For Filters ****************
  const generateFilteringArray = (selectedFilters, filters) => {
    /**
     * This function will generate the API Filtering Array
     * @{*} Params:- selectedFilters,AllAvailable Filters Data , Both should be object
     * @{*} Returns:- returns Filtering Array For API Call
     */

    const inOperatorKeys = [
      "adyogi_targeting_type",
      "campaign_type",
      "ad_group_id",
      "campaign_objective",
      "portfolio_id",
      "campaign_id",
    ];
    const emptyArrayCases = ["All"];
    const modificationArraysKeys = ["adgroup", "campaigns", "portfolios"];
    const FilteringArray = Object.entries(selectedFilters).flatMap(
      ([key, value]) => {
        if (value?.length < 1 || emptyArrayCases.includes(value)) return [];
        const keyToBeSent = keyMapper(key);
        const operator = inOperatorKeys.includes(keyToBeSent) ? "IN" : "EQUALS";
        const valueToBeSentInAPI = modificationArraysKeys.includes(key)
          ? (key === "adgroup"
              ? filters?.adGroups
              : key === "campaigns"
              ? filters?.campaigns
              : filters?.portfolios
            )
              ?.filter((data) => {
                return key === "adgroup"
                  ? selectedFilters?.adgroup?.includes(data?.name)
                  : key === "campaigns"
                  ? selectedFilters?.campaigns?.includes(data?.name)
                  : selectedFilters?.portfolios?.includes(data?.name);
              })
              ?.map((data) => data?.platform_id)
          : key === "targeting_type"
          ? value?.map((data) => valueMapperTargetingType(data))
          : key === "bid_strategy"
          ? value === "Down"
            ? "legacyForSales"
            : "autoForSales"
          :
          key === "campaign_objective" ? value?.map((data) => {
            if(data === "Retargeting") return "Retarget"
            return data
          })
          : value;
        return [
          {
            field_name: keyToBeSent,
            operator: operator,
            [inOperatorKeys.includes(keyToBeSent) ? "values" : "value"]:
              valueToBeSentInAPI,
          },
        ];
      }
    );
    return FilteringArray;
  };

  function keyMapper(id) {
    switch (id) {
      case "portolios":
        return "portfolio_id";
      case "adgroup":
        return "ad_group_id";
      case "targeting_type":
        return "adyogi_targeting_type";
      case "portfolios":
        return "portfolio_id";
      case "campaigns":
        return "campaign_id";
      case "bid_strategy":
        return "strategy";
      default:
        return id;
    }
  }

  function valueMapperTargetingType(val) {
    switch (val) {
      case "Exact":
        return `Keyword ${val} Targeting`;
      case "Phrase":
        return `Keyword ${val} Targeting`;
      case "Broad":
        return `Keyword ${val} Targeting`;
      case "Product":
        return "Product Targeting";
      case "Audience":
        return "Audience Targeting";
      default:
        return val;
    }
  }

  // *************** Function to generate Chip Filters ***************


  function fieldNameMapperForChips  (name) {
    switch (name) {
      case "targeting_type":
        return "Targeting Type";
      case "campaign_type":
        return "Campaign Type"   
      case "bid_strategy":
        return "Bid Strategy" 
      case "campaign_objective":
        return "Campaign Objective"
      default:
        return name
    }
  }


  function generateChipFilters(filters,dataForStatFilters) {
    const commonFilters =  Object.entries(filters).flatMap(([key, value]) => {
      if (value.length < 1 || value === "All") return [];
      return {
        fieldNameOfSelectedBtn: fieldNameMapperForChips(key),
        field_name: key,
        checkedValue: !Array.isArray(value) ? [value] : value,
      };
    });

    //this will create array witch merged duplicate key in single onject
    const newArray = [];
    dataForStatFilters.forEach((obj) => {
      let newObj = {
        fieldNameOfSelectedBtn: obj.fieldNameOfSelectedBtn,
        checkedValue: obj.value,
        operator: obj?.operator,
      };
      let foundIndex = newArray.findIndex(
        (x) => x.field_name === obj.field_name
      );
      if (foundIndex === -1) {
        newArray.push({
          field_name: obj.field_name,
          values: [newObj],
          fieldNameOfSelectedBtn: obj.fieldNameOfSelectedBtn,
        });
      } else {
        newArray[foundIndex].values.push(newObj);
      }
    });
    let listForChip = [];
    let nonnAbbrivaationFieldName = [
      "spends",
      "impressions",
      "clicks",
      "orders",
      "sale_value",
    ];
    newArray?.map((currObj) => {
      let minValueForCurrentObj = 0;
      let maxValueForCurrentObj = "Infinity";
      currObj.values.map((currentDaata) => {
       
        if (currentDaata?.operator === "LESS_THAN_EQUAL_TO") {
          // Checking the case for -Infinity
          const checkIfNegative = (currentDaata?.checkedValue < 0 && currentDaata?.operator!=="GREATER_THAN_EQUAL_TO" && currObj?.values?.length !== 2) ? true:false
          minValueForCurrentObj = checkIfNegative ? "-Infinity" : minValueForCurrentObj
          maxValueForCurrentObj = currentDaata.checkedValue;
        }
        if (currentDaata?.operator === "GREATER_THAN_EQUAL_TO") {
          minValueForCurrentObj = currentDaata.checkedValue;
        }
      });
      listForChip.push({
        field_name: currObj.field_name.toLowerCase(),
        fieldNameOfSelectedBtn:
          currObj.field_name === "sale_value"
            ? "Revenue"
            : nonnAbbrivaationFieldName.includes(currObj.field_name)
              ? currObj.field_name.replace(/^\w/, (c) => c.toUpperCase())
              : convertComparisonFieldsForChips(currObj?.field_name),
        minValue: minValueForCurrentObj,
        maxValue: maxValueForCurrentObj,
      });
    });
    const allChipFiltersData = commonFilters.concat(listForChip);
    return allChipFiltersData

  }

  function convertComparisonFieldsForChips (type) {
    const outputString = type.replace(/.*?_/, '');
    return outputString + "%"
    // currObj.field_name.replace(/_/g,' ').toUpperCase()

  }


   const onCancelClickRemoveFilteringHandlerr = useCallback(function (type) {
    setSelectedDropDownFilters((prev) => {
      return {
          ...prev,
          [type]: type === "bid_strategy" ? "All" : [],
      }
    });
    if(statFilters?.length) {
      setActiveCancelStatFilterField(type)
      setStatFilters((prev) => {
        return [...prev].filter((data) => data.field_name !== type)
      })
      setMainColumns((prev) => {
        return {
          ...prev,
          columns:[...prev.columns].map((data) => {
            if(data.field_name?.toLowerCase() === type) {
              data.maxValue = ""
              data.minValue = ""
            }
            return {...data}
          }),
          percentageColumns:[...prev.percentageColumns].map((data) => {
            if(data.field_name?.toLowerCase() === type) {
              data.maxValue = ""
              data.minValue = ""
            }
            return {...data}
          })
        }
      })
    }
  },[selectedDropDownFilters,statFilters]) 

 const  onResetButtonClick = useCallback(function () {
  setSelectedDropDownFilters({
    ...{
      portfolios: [],
      campaign_type: [],
      campaigns: [],
      targeting_type: [],
      adgroup: [],
      bid_strategy: "All",
      campaign_objective: [],
    },
  });
  setStatFilters([]);
  setActiveCancelStatFilterField("reset");
  setMainColumns((prev) => {
    return {
      ...prev,
      columns:[...prev.columns].map((data) => {
          data.maxValue = ""
          data.minValue = ""
        return {...data}
      }),
      percentageColumns:[...prev.percentageColumns].map((data) => {
          data.maxValue = ""
          data.minValue = ""
        return {...data}
      })
    }
  })
},[selectedDropDownFilters]) 

  // ********** Methods For All DropDown Filters Change *********
  const handleDropDownsFilterChange = (e, type) => {
    setSelectedDropDownFilters({ ...selectedDropDownFilters, [type]: e });
  };

  const filterCampaigns = (e, type) => {
    setSelectedDropDownFilters({
      ...selectedDropDownFilters,
      [type]: e.target.value,
    });
  };

  // *********** Methods For Fetching Data

  async function fetchDataForDropDowns() {
    // For Campaigns
    const dataForCampaign = {
      source: "amazon",
      fields: "id,name,platform_id",
    };

    const dataForAdgroup = {
      source: "amazon",
      fields: "id,name,platform_id",
    };

    const dataForPortfolio = {
      source: "amazon",
      fields: "id,name,platform_id",
    };

    try {
      const campaignsResponse = await masterApi(
        dataForCampaign,
        "campaignList",
        false
      );
      const adgroupResponse = await masterApi(
        dataForAdgroup,
        "adsetList",
        false
      );
      const portfolioResponse = await masterApi(
        dataForPortfolio,
        "getPortfolios",
        false
      );
      const campaigns = campaignsResponse?.data?.data;
      const adgroups = adgroupResponse?.data?.data;
      const portfolios = portfolioResponse?.data?.data;
      if (
        campaignsResponse?.statusCode !== 200 ||
        adgroupResponse?.statusCode !== 200 ||
        portfolioResponse.statusCode !== 200
      ) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: `Some Unknown Error Occurred while Fetching Filters Data`,
        // });
        toast.error('Some Unknown Error Occurred while Fetching Filters Data')
      }
      setSearchFiltersState({
        ...searchFiltersState,
        campaigns: campaigns,
        adGroups: adgroups,
        portfolios,
      });
    } catch (error) {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_DANGER,
      //   title: "Error",
      //   description: `Some Unknown Error Occurred while Fetching Filters Data`,
      // });
      toast.error('Some Unknown Error Occurred while Fetching Filters Data')
    }
  }
  // ***************** All Methods For Table ********************

  // ***************** Methods For Scrolling on Table ***************
  const loadMoreData = () => {
    if (tableDataToShow.length > 0) {
      const newData = tableDataToShow.slice(startIdx, startIdx + pageSize);
      setStartIdx(startIdx + pageSize);
  
      setVisibleTableData((prevData) => {
        const updatedData = [...prevData, ...newData];
        if(updatedData.length > currentRowCount) {
          return prevData
        } else {
        const uniqueData = getUniqueRecords(updatedData);

        const truncatedData = uniqueData.slice(-300);
        
        if (updatedData.length >= 300) {
          const container = tableRef.current;
          const newScrollTop = container.scrollHeight - container.clientHeight;
          container.scrollTop = newScrollTop;
        }
  
        return truncatedData;
      }
      });
    }
  };

  const handleScroll = async () => {
    const container = tableRef.current;
    const { scrollTop, scrollHeight, clientHeight } = container;
    let { currentPage, currentOffSet } = paginationAction;
  
    if (scrollTop === 0 && startIdx >= pageSize) {
      setStartIdx(startIdx - pageSize);
      const newData = tableDataToShow.slice(startIdx - pageSize, startIdx);
      let iterationCounter = 0
      if(visibleTableData.length >= 300) {
        while (iterationCounter < 5) {
          setStartIdx(startIdx - pageSize);
          const newData = tableDataToShow.slice(startIdx - pageSize, startIdx);
          const updatedData = [...newData, ...visibleTableData];
          const uniqueData = getUniqueRecords(updatedData);
      
          if (hasNewRecords(newData, visibleTableData)) {
            const truncatedData = uniqueData.slice(0, 300);
            container.scrollTop = 46 * newData.length;
            setVisibleTableData(truncatedData);
            iterationCounter = 10;
            break; // Exit the loop if the condition is satisfied
          } else {
            container.scrollTop = 46 * 5;
            setVisibleTableData(visibleTableData);
            iterationCounter++;
          }
        }
      } else {
        setVisibleTableData((prevData) => {
          const updatedData = [...newData, ...prevData];
          if(updatedData.length > currentRowCount) {
            return prevData
          } else {
          const uniqueData = getUniqueRecords(updatedData);
          if (hasNewRecords(newData, prevData)) {
            const truncatedData = uniqueData.slice(0, 300);
            container.scrollTop = 46 * newData.length; // or adjust as needed
            return truncatedData;
          } else {
            // No new records, avoid recursive call
            container.scrollTop = 46 * 5;
            return prevData;
          }
        }
        });
      }
      
    } else if (scrollTop + clientHeight >= scrollHeight - 100) {
      if (startIdx < tableDataToShow.length) {
        loadMoreData();
      } else if (
        startIdx + pageSize >= tableDataToShow.length &&
        tableDataToShow.length < currentRowCount
      ) {
        currentOffSet = currentOffSet + limit;
        currentPage = currentOffSet / limit + 1;
  
        if (!lazyLoading) {
          const moreData = await loadMoreDataAPI(currentOffSet);
          setTableDataToShow([...tableDataToShow, ...moreData]);
          setReloadMoreData(!reloadMoreData)
          setPaginationAction({
            ...paginationAction,
            currentPage,
            currentOffSet,
          });
        }
      }
    }
  };
  
  const getUniqueRecords = (data) => {
    const uniqueSet = [...new Set(data)];
    return uniqueSet
  };

  const hasNewRecords = (newData, prevData) => {
    const uniqueSet = new Set(prevData.map((record) => record.targeting));
    return newData.some((record) => !uniqueSet.has(record.targeting));
  };
  

  // const handleScroll = async () => {
  //   const container = tableRef.current;
  //   let { currentPage, currentOffSet } = paginationAction;
  //   // Keep track of last scroll position
  //   const lastScrollTop = container.getAttribute("data-scroll-top") || 0;

  //   // Determine scroll direction
  //   const scrollDirection = container.scrollTop > lastScrollTop ? "down" : "up";

  //   // Update last scroll position
  //   container.setAttribute("data-scroll-top", container.scrollTop);

  //   if (
  //     scrollDirection === "down" &&
  //     Math.ceil(container.scrollTop + container.clientHeight) >=
  //       container.scrollHeight &&
  //     tableDataToShow?.length >= 50 && currentRowCount!==tableDataToShow?.length
  //   ) {
  //     currentOffSet = currentOffSet + limit;
  //     currentPage = currentOffSet / limit + 1;

  //     if (!lazyLoading) {
  //       const moreData = await loadMoreData(currentOffSet);
  //       setTableDataToShow([...tableDataToShow, ...moreData]);
  //       setPaginationAction({
  //         ...paginationAction,
  //         currentPage,
  //         currentOffSet,
  //       });
  //     }
  //   }
  // };

  async function loadMoreDataAPI(currentOffSet) {
    try {
      setLazyLoading(true);
      let filtering = generateFilteringArray(
        selectedDropDownFilters,
        searchFiltersState
      );
      if (filters?.targeting !== "") {
        filtering = filtering.concat([
          {
            field_name: "targeting",
            operator: "EQUALS",
            value: filters?.targeting,
          },
        ]);
      }
      if (statFilters?.length > 0) {
        filtering = filtering.concat([...statFilters]);
      }
      try {
        const dataForAPI = {
          source: "amazon",
          order_by: sortBy.sort_by,
          sorting_order: sortBy.state,
          duration_one_start_date: formatDateFn(start_date_first),
          duration_one_end_date: formatDateFn(end_date_first),
          duration_two_start_date: formatDateFn(start_date_second),
          duration_two_end_date: formatDateFn(end_date_second),
          limit: limit,
          offset: currentOffSet,
          master_row:false,
          suggested_bid_required:false,
          filtering: encodeData(filtering),
        };
        const result = await masterApi(dataForAPI, "getOptimisedBids", false);
        if (result?.statusCode !== 200) {
          // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //   backgroundColor: TOAST_DANGER,
          //   title: "Error",
          //   description: `Some Unknown Error Occurred while Fetching More Bids`,
          // });
          toast.error('Some Unknown Error Occurred while Fetching More Bids')
        }
        if (result?.data?.data) {
          const { data } = result?.data;
          const dataToBeSet = addComparisonData(data, campaignsStats,currentCampaignStats);
          setLazyLoading(false);
          return dataToBeSet;
        }
        setLazyLoading(false);
      } catch (error) {
        setAllLoaders({ ...allLoaders, mainLoader: false });
      }
      setLazyLoading(false);
    } catch (error) {
      setLazyLoading(false);
    }
  }

  // ******************** Methods For Sorting **************

  const onSort = (key, state) => {
    let convertedData = JSON.parse(JSON.stringify(dataHeads));
    convertedData = convertedData.map((head) => {
      if (head.id === key) {
        head.isSortingRequired = !head.isSortingRequired;
      } else {
        head.isSortingClicked = false;
      }
      return head;
    });
    setSortBy({ ...sortBy, sort_by: key, state: state ? "asc" : "desc" });
    setDataHeads(convertedData);
  };

  // ******************* Methods For Editing Table ******************

  const onEditCurrentBid = (index) => {
    // const data = structuredClone(tableDataToShow);
    const visibleData = structuredClone(visibleTableData)
    // data[index].isEditable = true;
    visibleData[index].isEditable = true;
    setVisibleTableData([...visibleData])
    // setTableDataToShow([...data]);
  };

  const onBidChange = (e, index) => {
    // const data = structuredClone(tableDataToShow);
    const visibleData = structuredClone(visibleTableData);
    // data[index].current_bid = e.target.value;
    visibleData[index].current_bid = e.target.value;
    setVisibleTableData([...visibleData])
    // setTableDataToShow([...data]);
  };

  const onUpdateBid = (index) => {
    // using index recieved to send further informations and do actions
    setActionModalText("Are you sure that you want to update current bid?")
    setCurrentEditingIndex(index);
    dispatch(campaignListActions.showActionAlertModal());
  };


  const onYesClick = async () => {
    const isCurrent = actionModalText.includes("current") ? true :false
    const data = structuredClone( isCurrent ? visibleTableData : tableDataToShow);
    if(isCurrent) {
      data[currentEditingIndex].isEditable = false;
      data[currentEditingIndex].targeting_expression.target[0].bid = Number(data[currentEditingIndex]?.current_bid)
      const {targeting_expression} = data[currentEditingIndex]
      try {
        const response = await updateTargetingDataOnServer([targeting_expression])
        if(response?.status !==200) {
          // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //   backgroundColor: TOAST_DANGER,
          //   title: "Error",
          //   description: `Some Unknown Error Occured while updating current Bid`,
          // });
          toast.error('Some Unknown Error Occured while updating current Bid')
          data[currentEditingIndex].current_bid = data[currentEditingIndex]?.current_bid_copy
          data[currentEditingIndex].targeting_expression.target[0].bid = Number(data[currentEditingIndex]?.current_bid_copy)
          setVisibleTableData([...data]);
        } else {
          // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //   backgroundColor: TOAST_SUCCESS,
          //   title: "Success",
          //   description: `Bid has been updated successfully!`,
          // });
          toast.success('Bid has been updated successfully!')
          data[currentEditingIndex].current_bid_copy = data[currentEditingIndex]?.current_bid
          setVisibleTableData([...data]);
        }
      } catch (error) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: `Some Unknown Error Occured while updating current Bid`,
        // });
        toast.error('Some Unknown Error Occured while updating current Bid')
      }
    } 
    else {
      const updatedData = data?.map((dataInstance) => {
       const targeting = selectedTargeting?.find((targeting) => targeting?.target_id === dataInstance?.target_id )
       if(targeting?.target_id === dataInstance?.target_id) {
        dataInstance.current_bid = dataInstance.suggested_bid
        dataInstance.current_bid_copy = dataInstance.suggested_bid
       }
       dataInstance.isChecked = false
       return dataInstance
      })
      const targetingArrayToBeSentForUpdate = selectedTargeting?.map((targeting) => {
        const {targeting_expression} = targeting
        targeting_expression.target[0].bid = Number(targeting?.suggested_bid)
        return {
          ...targeting_expression
        }
      })
      try {
        const response = await updateTargetingDataOnServer(targetingArrayToBeSentForUpdate)
        if(response?.status !==200) {
          // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //   backgroundColor: TOAST_DANGER,
          //   title: "Error",
          //   description: `Some Unknown Error Occured While Updating Bids`,
          // });
          toast.error('Some Unknown Error Occured While Updating Bids')
        } else {
          // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //   backgroundColor: TOAST_SUCCESS,
          //   title: "Success",
          //   description: `Bid has been updated successfully!`,
          // });
          toast.success('Bid has been updated successfully!')
          setTableDataToShow([...updatedData]);
        }
      } catch (error) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: `Some Unknown Error Occured while updating current Bid`,
        // });
        toast.error('Some Unknown Error Occured while updating current Bid')
      }
    }
   
  };

  const updateTargetingDataOnServer = async (updateDataArray) => {
    const myHeaders = {
      "Content-Type": "application/json",
    };
    const dataToUpdate = updateDataArray;
    const raw = JSON.stringify(dataToUpdate);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setAllLoaders({ allLoaders, mainLoader: true });
    try {
      const client_id = JSON.parse(localStorage.getItem("clientId"));
      const response = await fetch(
        `https://adyogiadsapi.adyogi.com/client/${client_id}:update-amazon-bulk-target?source=amazon`,
        requestOptions
      );
      setAllLoaders({allLoaders,mainLoader:false})
      return response
    } catch (error) {
      console.log(error)
      setAllLoaders({allLoaders,mainLoader:false})
    }

  };

  const onNoClick = () => {
    dispatch(campaignListActions.hideActionAlertModal());
    const data = structuredClone(tableDataToShow);
    data[currentEditingIndex].current_bid =
      data[currentEditingIndex]?.current_bid_copy;
    data[currentEditingIndex].isEditable = false;
    setTableDataToShow([...data]);
  };

  const redirectToCampaignList = (campName) => {
    if (campName) {
      window.open(
        `/campaign-list?campName=${campName}`,
        "_blank",
        "noreferrer"
      );
    }
  };

  const delegateEventsHandler = (e) => {
    if(e.target.className === "clickable-text-color") {
      redirectToCampaignList(e.target.innerText)
    }
  }

  function isCurrentBidPresent (obj) {
    return obj?.hasOwnProperty('current_bid') && obj.current_bid !== null && obj.current_bid!=="" && obj.current_bid!==undefined
  }

  // Table Body


  let tableBody = (
    <>
      <div
        className={lazyLoading
           ?"main-campaignlist-table-body-container overflow-x-hidden optimise-bids-main-table-wrapper":"main-campaignlist-table-body-container optimise-bids-main-table-wrapper"}
        ref={tableRef}
        onScroll={handleScroll}
      >
        {tableDataToShow?.length ? (
          <>
            <table className="optimise-bids-table" onClick={delegateEventsHandler}>
              <thead className="fixed-table">
                <tr className="main-table-row-fixed">
                  {dataHeads?.map((th) => {
                    return (
                      <th
                        key={th.fieldName}
                        className={"targeting-table-heads"}
                        colSpan={doubleColumnHeaders.includes(th.id) && 2}
                      >
                        {doubleColumnHeaders.includes(th.id) ? (
                          <>
                            <div className="d-flex gap-1 align-items-center">
                              <div
                                className={
                                  th.id === sortBy.sort_by
                                    ? "mt-spends-icon-activated"
                                    : ""
                                }
                              >
                                {th.fieldName}
                              </div>
                              <div
                                className="cursor-pointer-global text-success"
                                onClick={() =>
                                  onSort(th.id, th.isSortingRequired)
                                }
                              >
                                <img
                                  alt="sort-icon"
                                  className={
                                    th.id === sortBy.sort_by
                                      ? "mt-spends-icon-activated"
                                      : ""
                                  }
                                  src={
                                    th.isSortingRequired
                                      ? descendingIcon
                                      : ascendingIcon
                                  }
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          th.fieldName === "Target" ? <>
                           <div className="targeting-selected-head captital-first-letter">
                           <span className="">
                          <div className="parent-targeting-checkbox d-flex align-items-center">
                            <CheckboxRb
                              inputLabelClass="check-colum-head"
                              // label={th.fieldName.toLowerCase()}
                              // checked={mainTargetingSelected}
                              onChange={(e) => allCheckBoxHandler(e.target.checked)}
                            ></CheckboxRb>
                            {th.fieldName.toLowerCase()}
                          </div>
                        </span>
                        {/* <span className="stat-heads-with-icon">{th.fieldName}</span> */}
                      </div>
                          </> : <>{th.fieldName}</>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="another-body">
                {
                  <>
                    <tr>
                      {dataHeads?.map((th) => {
                        const dynamicKey = uuidV4();
                        return doubleColumnHeaders.includes(th.id) ? (
                          <React.Fragment key={dynamicKey}>
                            <td className="border-start-none">Current</td>
                            <td className="border-start-none">Previous</td>
                          </React.Fragment>
                        ) : (
                          <td className="p-0" key={dynamicKey}></td>
                        );
                      })}
                    </tr>

                    {visibleTableData?.map((data, index) => (
                      
                    <React.Fragment key={data.id}>
                      {(() => {
                        const slicedTargeting =
                          data?.targeting?.length > 30
                            ? data?.targeting?.slice(0, 25) + "..."
                            : data?.targeting;
                      return (
                        <>
                          <tr key={`${data?.targeting}${index}`} className={data?.isHighlighted ? "cursor-pointer highlight" : "cursor-pointer"} onClick={() => handleHighlightList(index)}>
                          <td title={data?.targeting} style={{ minWidth: "200px" }}>
                            <div className="parent-targeting-checkbox d-flex align-items-center gap-1">
                              <CheckboxRb
                                inputLabelClass="check-colum-head"
                                checked={data?.isChecked}
                                disabled={!isCampaignTypeNotSB(data)}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  singleCheckBoxHandler(index);
                                }}
                              ></CheckboxRb>
                              {slicedTargeting}
                            </div>
                          </td>
                            <td>{data?.adyogi_targeting_type}</td>
                            {/* <td>-</td> */}
                            {/* <td>{data?.suggested_bid}</td> */}
                            <td>
                              <div className="d-flex justify-content-evenly align-items-center budget-td-container">
                                <input
                                  type="text"
                                  className={
                                    !data?.isEditable
                                      ? "budget-value"
                                      : "budget-value-edit"
                                  }
                                  disabled={!data?.isEditable || !data?.targeting_expression || !data?.targeting_expression}
                                  onChange={(e) => {
                                    e.stopPropagation()
                                    onBidChange(e, index)
                                  }}
                                  value={data?.current_bid}
                                ></input>
                                <span
                                  className={`edit-budget-icon-container ${(!isCurrentBidPresent(data) || !isCampaignTypeNotSB(data)) ? "disable-pointer-events" : ""}`}
                                  onClick={(e) =>
                                    {
                                      e.stopPropagation()
                                      !data?.isEditable
                                      ? onEditCurrentBid(index)
                                      : onUpdateBid(index)
                                    }
                                   
                                  }
                                >
                                  <img
                                    src={
                                      !data?.isEditable ? editIcon : checkCircle
                                    }
                                    alt={"edit-update"}
                                  ></img>
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-3 justify-content-between align-items-center">
                                <span>{data?.spends?.toFixed(2)}</span>
                                <div className="percentage-up-down-container">
                                  <span>
                                    {" "}
                                    <img
                                      alt="up-down"
                                      className={data?.comparison_spends?.includes("N/A") ? "hide":""}
                                      height={10}
                                      src={
                                        data?.comparison_spends?.includes("-")
                                          ? downRed
                                          : upGreen
                                      }
                                    />
                                  </span>
                                  <span className="figure-comparison">
                                    {data?.comparison_spends?.replace("-", "") ?? "N/A"}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-3 justify-content-between">
                                <span>{data?.dd_spends?.toFixed(2)}</span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-3 justify-content-between align-items-center">
                                <span>{data?.cpc?.toFixed(2)}</span>
                                <div className="percentage-up-down-container">
                                  <span>
                                    {" "}
                                    <img
                                      alt="up-down"
                                      className={data?.comparison_cpc?.includes("N/A") ? "hide":""}
                                      height={10}
                                      src={
                                        data?.comparison_cpc?.includes("-")
                                          ? downRed
                                          : upGreen
                                      }
                                    />
                                  </span>
                                  <span className="figure-comparison">
                                    {data?.comparison_cpc?.replace("-", "") ?? "N/A"}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-3 justify-content-between">
                                <span>{data?.dd_cpc?.toFixed(2)}</span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-3 justify-content-between align-items-center">
                                <span>{data?.orders?.toFixed(2)}</span>
                                <div className="percentage-up-down-container">
                                  <span>
                                    {" "}
                                    <img
                                      alt="up-down"
                                      className={data?.comparison_orders?.includes("N/A") ? "hide":""}
                                      height={10}
                                      src={
                                        data?.comparison_orders?.includes("-")
                                          ? downRed
                                          : upGreen
                                      }
                                    />
                                  </span>
                                  <span className="figure-comparison">
                                    {data?.comparison_orders?.replace("-", "") ?? "N/A"}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-3 justify-content-between">
                                <span>{data?.dd_orders?.toFixed(2)}</span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-3 justify-content-between align-items-center">
                                <span>{data?.roas?.toFixed(2)}</span>
                                <div className="percentage-up-down-container">
                                  <span>
                                    {" "}
                                    <img
                                      alt="up-down"
                                      height={10}
                                      className={data?.comparison_roas?.includes("N/A") ? "hide":""}
                                      src={
                                        data?.comparison_roas?.includes("-")
                                          ? downRed
                                          : upGreen
                                      }
                                    />
                                  </span>
                                  <span className="figure-comparison">
                                    {data?.comparison_roas?.replace("-", "") ?? "N/A"}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-3 justify-content-between">
                                <span>{data?.dd_roas?.toFixed(2)}</span>
                              </div>
                            </td>
                            {dataHeads?.map((dataHead) => {
                              const dynamicKey = uuidV4();
                              if (dataHead?.isRestColumn) {
                                return (
                                  <>
                                    <td key={dynamicKey} className={dataHead.id === "campaign_name" ? "clickable-text-color":""}>
                                      {data[dataHead.id] === "autoForSales"
                                        ? "Up and Down"
                                        : data[dataHead.id] === "legacyForSales"
                                        ? "Down"
                                        : data[dataHead.id]}
                                    </td>
                                  </>
                                );
                              }
                            })}
                          </tr>
                        </>
                      );
                    })()}
                    </React.Fragment>
                    ))}
                  </>
                }
              </tbody>
            </table>
            {lazyLoading && (
              <div className="lazy-loading-container d-flex p-5 justify-content-center align-items-center w-100">
                <LazyLoadSpinner text={`Loading ${limit} More Records...`} />
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  // Select one checkbox method 
  
  const singleCheckBoxHandler = (index) => {
    const data = structuredClone(tableDataToShow);
    const visibleData = structuredClone(visibleTableData)
    if(isCurrentBidPresent(data[index]) && isCampaignTypeNotSB(data[index])) {
      data[index].isChecked = !data[index].isChecked
      visibleData[index].isChecked = !visibleData[index].isChecked  
    setTableDataToShow([...data])
    setVisibleTableData([...visibleData])
    }
  }

  function isCampaignTypeNotSB (obj) {
    return obj.campaign_type !== "SB"
  }

  // Select All Checkbox Method 

  const allCheckBoxHandler = (checked) => {
    const data = structuredClone(tableDataToShow)
    const updatedData = data?.map((dataInstance) => {
      if(isCurrentBidPresent(dataInstance) && isCampaignTypeNotSB(dataInstance)) {
        dataInstance.isChecked = checked
      }
      return dataInstance
    })
    setVisibleTableData((prev) => {
      return [...prev]?.map((data) => {
        if(isCurrentBidPresent(data) && isCampaignTypeNotSB(data)) {
          data.isChecked = checked;
        }
        return data
      })
    })
    setTableDataToShow([...updatedData])
  }

  // Remove all Selected Checkboxes

  const removeAllSelectedTargetings = () => {
    const data = structuredClone(tableDataToShow);
    const dataVisible = structuredClone(visibleTableData)
    const updatedData = data?.map((dataInstance) => {
      dataInstance.isChecked = false
      return dataInstance
    })
    const updatedVisibleData = dataVisible?.map((dataInstance) => {
      dataInstance.isChecked = false
      return dataInstance
    });
    setVisibleTableData([...updatedVisibleData])
    setTableDataToShow([...updatedData]);
  }

  // Align with suggestedBid Handler 

  const alignWithSuggestedBidhandler = () => {
    setActionModalText("Are you sure that you want to align suggested bids?")
    dispatch(campaignListActions.showActionAlertModal());
  }

  const editBidHandler = () => {
    dispatch(campaignListActions.showBulkEditBidModal());
  }

  const bidUpdationDataHandler = async (passedData) => {
    const {selectedAction,valuePercentage,setAsValue} = passedData
    const data = structuredClone(tableDataToShow)
    const updatedData = data?.map((targeting) => {
      const campaign = selectedTargeting?.find((targetInstance) => targetInstance?.target_id === targeting?.target_id)
      if(campaign?.target_id === targeting?.target_id) {
        const {current_bid} = campaign
        const modifiedValue = selectedAction === "set_bid_as" ? Number(setAsValue):modifyValueByPercentage(selectedAction,valuePercentage,current_bid)
        targeting.current_bid = modifiedValue
        targeting.current_bid_copy = modifiedValue
      }
      targeting.isChecked = false
      return targeting
    })

    const updatedTargetingArrayData = selectedTargeting?.map((targeting) => {
      const {current_bid,targeting_expression} = targeting
      const modifiedValue = selectedAction === "set_bid_as" ? Number(setAsValue):modifyValueByPercentage(selectedAction,valuePercentage,current_bid)
      targeting_expression.target[0].bid = modifiedValue
      return {
        ...targeting_expression
      }
    })
    try {
      const response = await updateTargetingDataOnServer(updatedTargetingArrayData)
      if(response?.status !==200) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: `Some Unknown Error Occured While Updating Bids`,
        // });
        toast.error('Some Unknown Error Occured While Updating Bids')
      } else {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_SUCCESS,
        //   title: "Success",
        //   description: `Bids has been updated successfully!`,
        // });
        toast.success('Bids has been updated successfully!')
        setTableDataToShow([...updatedData]);
      }
    } catch (error) {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_DANGER,
      //   title: "Error",
      //   description: `Some Unknown Error Occured while updating current Bid`,
      // });
      toast.error('Some Unknown Error Occured while updating current Bid')
    }
  }


  // FilterModal

  // Filter Modal Methods
  const columns = [
    {
      field_name: "Spends",
      field_key: "spends",
      minValue: "",
      maxValue: "",
    },
    {
      field_name: "ROAS",
      field_key: "roas",
      minValue: "",
      maxValue: "",
    },
    {
      field_name: "CPC",
      field_key: "cpc",
      minValue: "",
      maxValue: "",
    },
    {
      field_name: "Orders",
      field_key: "orders",
      minValue: "",
      maxValue: "",
    },
  ];


  
  const percentageColumns = [
    {
      field_name: "Spends",
      field_key: "comp_spends",
      minValue: "",
      maxValue: "",
    },
    {
      field_name: "ROAS",
      field_key: "comp_roas",
      minValue: "",
      maxValue: "",
    },
    {
      field_name: "CPC",
      field_key: "comp_cpc",
      minValue: "",
      maxValue: "",
    },
    {
      field_name: "Orders",
      field_key: "comp_orders",
      minValue: "",
      maxValue: "",
    },
  ];

  const [mainColumns,setMainColumns] = useState({columns:columns,percentageColumns:percentageColumns})




  const getAppliedFilters = (appliedFilters) => {
    const {normalFilters,percentageFilters} = appliedFilters;
    setMainColumns({columns:normalFilters,percentageColumns:percentageFilters})
    const dataWithFilters = appliedFilters?.normalFilters?.concat(
      appliedFilters?.percentageFilters
    );
    const data = transformData(dataWithFilters);
    setStatFilters([...data]);
  };

  function transformData(input) {
    const output = [];
    for (let i = 0; i < input?.length; i++) {
      const obj = input[i];

      // Check if the object has non-empty and non-zero values for minRange or maxRange
      if (
        (obj.minValue !== "" && obj.minValue !== 0 && obj.minValue !== "-") ||
        (obj.maxValue !== "" && obj.maxValue !== 0 && obj.maxValue !== "-")
      ) {
        // Add a new object to the output array with "GREATER_THAN" operator and the "minRange" value, if minRange is not empty or zero
        if (obj.minValue !== "" && obj.minValue !== 0 && obj.minValue !== "-") {
          output.push({
            field_name: obj?.field_key.toLowerCase(),
            operator: "GREATER_THAN_EQUAL_TO",
            value: obj.minValue.toString(),
          });
        }

        // Add a new object to the output array with "LESSER_THAN" operator and the "maxRange" value, if maxRange is not empty or zero
        if (obj.maxValue !== "" && obj.maxValue !== 0 && obj.maxValue !== "-") {
          output.push({
            field_name: obj?.field_key.toLowerCase(),
            operator: "LESS_THAN_EQUAL_TO",
            value: obj.maxValue.toString(),
          });
        }
      }
    }
    return output;
  }

  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box-master-ads"
    >
      <div className="campaign-list-container overflow-hidden">
        <MasterTagetinngActionAlertMModal
          alertText={actionModalText}
          onYesClick={onYesClick}
          onNoClick={onNoClick}
        />
         <EditBidModalForTargeting
            show={bulkEditBidModalVisibilty}
            setBidAs={true}
            getData={bidUpdationDataHandler}
          ></EditBidModalForTargeting>
        <Loader visible={initialLoad} />
        <div
          className={
            selectedTargeting.length
              ? "et-filter-options-container-formasterTargeting"
              : "hide"
          }
          id={"bulk-action-box-bid-optimise"}
        >
          <button className="selected-row-count mx-2">
            <img
              className="dash-icon"
              src={lessIcon}
              onClick={removeAllSelectedTargetings}
              alt={"dash-icon"}
            ></img>
            <span className="selected-text-with-count">
              {selectedTargeting && `${selectedTargeting?.length}  Selected`}
              {/* <div>{checkedAdGroupData.map((ad) => {})}</div> */}
            </span>
          </button>
          <button
            className="alignn-with-sug-bid-box-selective "
            onClick={alignWithSuggestedBidhandler}
          >
            Align with Suggested Bid
          </button>
          <button
            className="bid-manipulate-box-selective"
            onClick={editBidHandler}
            data-toggle="modal"
            data-target="#exampleModalCenter"
          >
            Adjust Bid
          </button>
        </div>
        <Stack>
          <Breadcrumb />
          <Stack direction="horizontal" className="d-flex align-item-center">
            <SectionTitle sectionTitleHeading={"Global Bid Optimiser"} />
          </Stack>
          <div className="mt-config-row bottom-margin mt-3">
            <Stack className="mt-configs-btn-container d-flex flex-row">
              <Stack
                direction="horizontal"
                className="list-pages-search-input-container mb-1"
              >
                <Stack
                  direction="vertical"
                  className="justify-content-end align-item-end"
                >
                  <SearchBarForCampaignListOnly
                    placeholder="Search for Targetings"
                    value={filters?.campaign}
                    searchBarForListPage={true}
                    onKeyUp={onSearchBarHandler}
                    isCancelIconNotVisible={true}
                    id="ma-global-targeting-list"
                    className="mt-custom-search-bar"
                    dynamicWidth="search-input-width-for-listing-pages"
                  ></SearchBarForCampaignListOnly>
                </Stack>
              </Stack>
              <Stack
                direction="horizontal"
                className="d-flex justify-content-center flex-wrap"
              >
                <Stack
                  className="master-ads-calendar-container mb-1"
                  direction="horizontal"
                >
                  <Stack className="d-flex justify-content-end align-item-end">
                    <button
                      className="date-container date-btn-campaignlist"
                      onClick={dateInputClickHandler}
                    >
                      <div className="d-flex justify-conntent-center">
                        <span className="date-btn-text">{"Custom"}</span>
                      </div>
                      <div className="btn-calender-img-holder">
                        <span className="">
                          <img
                            className="dropdown-img-for-select"
                            src={selectSideIcon}
                            alt={"SelecteSideIcon"}
                          ></img>

                          <img
                            className="calender-icon-for-campaignlist"
                            src={CalenderIconForCampaignList}
                            alt={"CalendarIconForCampaignList"}
                          ></img>
                        </span>
                      </div>
                    </button>
                  </Stack>
                  <div
                    ref={masterAdsCalendarIconRef}
                    className={
                      showCustomDateBox
                        ? "calender-masterads-container"
                        : "hide"
                    }
                  >
                    {showCustomDateBox && (
                      <CalenderComponent
                        setShowCustomDateBox={setShowCustomDateBox}
                        multiRange={true}
                      ></CalenderComponent>
                    )}
                    <span className="calender-close-icon-campaignlist">
                      <img
                        onClick={() => setShowCustomDateBox(false)}
                        src={closeIcon}
                        alt={"closeIcon"}
                      ></img>
                    </span>
                  </div>
                </Stack>
              </Stack>
            </Stack>
          </div>

          <Stack>
            <Stack
              direction="horizontal"
              className="d-flex align-item-center justify-content-between gap-3"
            >
              <Stack gap={4}>
                <MultiSelect
                  title={"Campaign Name"}
                  options={campaignsData}
                  defaultSelected={selectedDropDownFilters?.campaigns}
                  isSearch={true}
                  placeHolder={"Search Campaigns"}
                  onApply={(e) => handleDropDownsFilterChange(e, "campaigns")}
                  label={"Campaign Name"}
                />
              </Stack>
              <Stack gap={4}>
                <MultiSelect
                  title={"Targeting Type"}
                  options={searchFiltersState?.targetingType}
                  defaultSelected={selectedDropDownFilters?.targeting_type}
                  isSearch={true}
                  placeHolder={"Search Targeting"}
                  onApply={(e) =>
                    handleDropDownsFilterChange(e, "targeting_type")
                  }
                  label={"Targeting Type"}
                />
              </Stack>
              <Stack gap={4}>
                <MultiSelect
                  title={"Campaign Type"}
                  options={searchFiltersState.campaignType}
                  defaultSelected={selectedDropDownFilters?.campaign_type}
                  isSearch={true}
                  placeHolder={"Search Campaign"}
                  onApply={(e) =>
                    handleDropDownsFilterChange(e, "campaign_type")
                  }
                  label={"Campaign Type"}
                />
              </Stack>
              <Stack gap={4}>
                <MultiSelect
                  title={"Adgroup"}
                  options={adgroupData}
                  defaultSelected={selectedDropDownFilters?.adgroup}
                  isSearch={true}
                  placeHolder={"Search Adgroups"}
                  onApply={(e) => {
                    handleDropDownsFilterChange(e, "adgroup");
                  }}
                  label={"Adgroup"}
                />
              </Stack>
              <Stack gap={4}>
                <MultiSelect
                  title={"Portfolio Name"}
                  options={portfoliosData}
                  defaultSelected={selectedDropDownFilters?.portfolios}
                  isSearch={true}
                  placeHolder={"Search Portfolios"}
                  onApply={(e) => {
                    handleDropDownsFilterChange(e, "portfolios");
                  }}
                  label={"Portfolio Name"}
                />
              </Stack>
              <CustomDropDown
                title={"Bid Strategy"}
                data={["All", "Down", "Up and  Down"]}
                filterCampaigns={filterCampaigns}
                targettingValue={selectedDropDownFilters?.bid_strategy}
                type={"bid_strategy"}
                src={dropdownIcon}
                label={"Bid Strategy"}
              />
              <Stack gap={4}>
                <MultiSelect
                  title={"Campaign Objective"}
                  options={searchFiltersState?.campaign_objective}
                  defaultSelected={selectedDropDownFilters?.campaign_objective}
                  isSearch={true}
                  placeHolder={"Search Objective"}
                  onApply={(e) => {
                    handleDropDownsFilterChange(e, "campaign_objective");
                  }}
                  label={"Campaign Objective"}
                />
              </Stack>
            </Stack>
          </Stack>
          <div className="mt-2">
            <DynamicFilterModal
              columns={mainColumns.columns}
              column1Heading={"Filter By Metric"}
              column2Heading={"Filter By Comparison %"}
              cancelFilterFor={activeCancelStatFilterField}
              percentageColumnRequired={true}
              saveFilter={true}
              percentageColumns={mainColumns.percentageColumns}
              getAppliedFilters={getAppliedFilters}
              buttonHeading={"Filters"}
              alignment={"left"}
            />
          </div>
          <Stack className={dataForChips.length ? "pt-3 " : "hide"}>
            <FilterChips
              filterChipList={dataForChips}
              onCancelClickRemoveFilteringHandler={
                onCancelClickRemoveFilteringHandlerr
              }
              isResetButtonNeeded={true}
              onResetButtonClick={onResetButtonClick}
            ></FilterChips>
          </Stack>
          <div className="d-flex justify-content-end w-100 mt-2">
            <Link
              to={"#"}
              className={"text-muted"}
              style={{ fontSize: "12px" }}
            >
              * {`${tableDataToShow?.length} / ${currentRowCount} Rows`}
            </Link>
          </div>
          <div className="d-flex justify-content-end w-100 mt-2">
            <Link
              to={"#"}
              className={"text-muted"}
              style={{ fontSize: "12px" }}
            >
              * SB optimisation not supported
            </Link>
          </div>
          <div className="optimise-bids-table-wrapper">
            <Stack className="optimise-bids-main-table">
              <TableRb tableHeads={dataHeads} noDataFound={!allLoaders?.mainLoader && !initialLoad && !tableDataToShow?.length} shimmer={allLoaders?.mainLoader} table={tableBody}></TableRb>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default OptimiseBid;
