import React, { useState , useEffect , useRef } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import "../Breadcrumb/breadcrumb.css";
import SecondaryBtnRb from "../Buttons/secondaryBtnRb/secondaryBtnRb";
import networkBarIcon from '../../assets/svgs/networkbar.svg'

const ReportsNavigator = ({ data }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const closeRef = useRef(null)
  
  var iterationLevel = 0;

  function checkStatus(json) {
    return json.some((item) => item.status === true);
  }

  function redirectToNewPage(page) {
    window.open(page.href, "_blank");
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showDropDown && closeRef.current && !closeRef.current.contains(e.target)) {
        setShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showDropDown]);

  function getChildData(item, index) {
    return (
      <>
        <div className="position-relative" ref={closeRef}>
          <div className="d-flex" key={index}>
            {item.hasOwnProperty("href") ? (
              <SecondaryBtnRb
                img={networkBarIcon}
                className="primary-active-btn custom-bread-crumb-btn"
                onClick={handleToggleDropdown}
                secondaryBtnText={"View more Insights"}
              />
            ) : (
              <SecondaryBtnRb
                img={networkBarIcon}
                className="primary-active-btn custom-bread-crumb-btn"
                onClick={handleToggleDropdown}
                secondaryBtnText={"View more Insights"}
              />
            )}
          </div>
          {item.pages &&
          item.pages.length > 0 &&
          checkStatus(item.pages) &&
          showDropDown ? (
            <>
              {
                <div className="custom-breadcrumboptions-container position-absolute" >
                  {item?.pages.map((item,index,itemArr) => {
                    return (
                      <div
                        key={item.label} 
                        className={`dropdown-item ${index+1 === itemArr?.length ? "without-border":""}`}
                        value={item.label}
                        onClick={(e) => {
                          e.stopPropagation();
                          redirectToNewPage(item)
                        }}
                      >
                        {item.label}
                      </div>
                    );
                  })}
                </div>
              }

              <div className="d-none">
                {(iterationLevel = ++iterationLevel)}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }

  function handleToggleDropdown() {
    setShowDropDown(!showDropDown);
  }
  return (
    <div>
      <div className="d-flex mb-2 pb-2 gap-3">
        <div className="d-flex">
          {data?.map((item, index) => {
            return getChildData(item, index);
          })}
        </div>
      </div>
    </div>
  );
};

export default ReportsNavigator;
